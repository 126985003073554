/**
 * *****************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2022 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Adobe and its suppliers, if any. The intellectual and technical concepts
 * contained herein are proprietary to Adobe and its suppliers and are
 * protected by all applicable intellectual property laws, including trade
 * secret and copyright laws. Dissemination of this information or reproduction
 * of this material is strictly forbidden unless prior written permission is
 * obtained from Adobe.
 * *****************************************************************************
 */

import { Action, configureStore, ThunkAction } from '@reduxjs/toolkit';

import assetsReducer from '../__DEPRECATED_DO_NOT_USE_OR_YOU_WILL_BE_FIRED/redux/assets/asset.slice';
import filterReducer from '../__DEPRECATED_DO_NOT_USE_OR_YOU_WILL_BE_FIRED/redux/filter/filter.slice';
import metadataReducer from '__DEPRECATED_DO_NOT_USE_OR_YOU_WILL_BE_FIRED/redux/metadata/metadata.slice';

import DEPRECATEDtemplatesReducer from './__DEPRECATED_templates/templates.slice';
import alertReducer from './alert/alert.slice';
import clientCacheReducer from './clientCache/clientCache.slice';
import explorerReducer from './explorer/explorer.slice';
import headerReducer from './header/header.slice';
import queryModelReducer from './queryModel/queryModel.slice';
import screenReducer from './screen/screen.slice';
import tempoReducer from './tempo/tempo.slice';
import userReducer from './user/user.slice';
import workflowActionsReducer from './workflowActions/workflowActions.slice';

export function observeStore(store: any, select: any, onChange: any) {
  let currentState: any = select(store.getState());

  function handleChange() {
    const nextState = select(store.getState());

    if (nextState !== currentState) {
      currentState = nextState;
      onChange(currentState);
    }
  }

  const unsubscribe = store.subscribe(handleChange);

  return unsubscribe;
}

export const reducers = {
  DEPRECATEDtemplates: DEPRECATEDtemplatesReducer,
  alert: alertReducer,
  assets: assetsReducer,
  clientCache: clientCacheReducer,
  explorer: explorerReducer,
  filters: filterReducer,
  header: headerReducer,
  metadata: metadataReducer,
  workflowActions: workflowActionsReducer,
  queryModel: queryModelReducer,
  screen: screenReducer,
  tempo: tempoReducer,
  user: userReducer,
};

export const store = configureStore({
  reducer: reducers,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      // Ignoring prevents warnings when non-serializable values (ie. dates) are in actions or state, but it might lead to time travel or persistance bugs
      serializableCheck: {
        ignoredActions: [
          'alerts/addAlert',
          'alerts/removeAlert',
          'assets/updateAssetsData',
          'explorer/setSelectedAssetPaths',
          'tempo/setOwnersMap',
          'workflowActions/addAssetsToCurrentJob',
          'workflowActions/addJobToQueue',
          'workflowActions/clearQueue',
          'workflowActions/hideToast',
          'workflowActions/markAssetForCurrentAsFulfilled',
          'workflowActions/markAssetForCurrentAsRejected',
          'workflowActions/markCurrentAsFulfilled',
          'workflowActions/markCurrentAsRejected',
          'workflowActions/markCurrentAsRejectedAndResolved',
          'workflowActions/markToastAsHidden',
          'workflowActions/skipJobInQueue',
          'workflowActions/startNextJob',
        ],
        ignoredPaths: ['explorer.selectedAssetPaths', 'workflowActions.queue'],
      },
    }),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
