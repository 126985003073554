/**
 * *****************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2022 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Adobe and its suppliers, if any. The intellectual and technical concepts
 * contained herein are proprietary to Adobe and its suppliers and are
 * protected by all applicable intellectual property laws, including trade
 * secret and copyright laws. Dissemination of this information or reproduction
 * of this material is strictly forbidden unless prior written permission is
 * obtained from Adobe.
 * *****************************************************************************
 */

import React, { ReactChild, ReactElement } from 'react';

import { Flex, View } from '@adobe/react-spectrum';

import FormLabel from './FormLabel';

type OptionsFormGroupProps = {
  children: ReactChild | ReactChild[];
  title: string;
  isWrapping?: boolean;
};

export default function OptionsFormGroup({
  children,
  title,
  isWrapping,
}: OptionsFormGroupProps): ReactElement {
  return (
    <>
      <View marginTop="size-250">
        <FormLabel>{title}</FormLabel>
      </View>
      <Flex
        marginTop="size-50"
        marginBottom="size-350"
        gap="size-100"
        wrap={isWrapping ? 'wrap' : 'nowrap'}
      >
        <>{children}</>
      </Flex>
    </>
  );
}

OptionsFormGroup.defaultProps = {
  isWrapping: false,
};
