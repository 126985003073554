/**
 * *****************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2022 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Adobe and its suppliers, if any. The intellectual and technical concepts
 * contained herein are proprietary to Adobe and its suppliers and are
 * protected by all applicable intellectual property laws, including trade
 * secret and copyright laws. Dissemination of this information or reproduction
 * of this material is strictly forbidden unless prior written permission is
 * obtained from Adobe.
 * *****************************************************************************
 */

import React, {
  FormEvent,
  ReactChild,
  ReactElement,
  useCallback,
  useState,
} from 'react';

import {
  Button,
  ButtonGroup,
  Content,
  Dialog,
  DialogContainer,
  Divider,
  Form,
  Heading,
  ProgressCircle,
} from '@adobe/react-spectrum';

export type FormDialogProps = {
  children: ReactChild | ReactChild[];
  create?: string;
  isDisabled: boolean;
  isOpen: boolean;
  onClose: () => void;
  onCreate: () => Promise<void>;
  title?: string;
};

export default function FormDialog({
  children,
  create,
  isDisabled,
  isOpen,
  onClose,
  onCreate,
  title,
}: FormDialogProps): ReactElement {
  const [isConfirming, setIsConfirming] = useState<boolean>(false);
  const [isClosing, setIsClosing] = useState<boolean>(false);
  const handleClose = useCallback((): void => {
    setIsClosing(true);
    onClose();
    setIsClosing(false);
  }, [onClose, setIsClosing]);
  const handleCreate = useCallback(
    async (event?: FormEvent<Element>): Promise<void> => {
      if (event) {
        event.preventDefault();
      }

      setIsConfirming(true);

      try {
        await onCreate();
        setIsConfirming(false);
      } catch (error) {
        setIsConfirming(false);
      }
    },
    [onCreate, setIsConfirming],
  );

  return (
    <DialogContainer onDismiss={handleClose}>
      {isOpen && (
        <Dialog>
          <Heading>{title}</Heading>
          <Divider />
          <Content>
            <Form onSubmit={handleCreate}>
              <>{children}</>
            </Form>
          </Content>
          <ButtonGroup isDisabled={isClosing || isConfirming}>
            <Button onPress={handleClose} variant="secondary">
              Cancel
            </Button>
            <Button
              isDisabled={isDisabled || isConfirming}
              onPress={() => handleCreate()}
              variant="cta"
            >
              {isConfirming ? (
                <ProgressCircle
                  aria-label="loading"
                  isIndeterminate
                  marginEnd="size-100"
                  size="S"
                />
              ) : null}
              {create}
            </Button>
          </ButtonGroup>
        </Dialog>
      )}
    </DialogContainer>
  );
}

FormDialog.defaultProps = {
  create: 'Create',
  title: 'Create',
};
