/**
 * *****************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2022 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Adobe and its suppliers, if any. The intellectual and technical concepts
 * contained herein are proprietary to Adobe and its suppliers and are
 * protected by all applicable intellectual property laws, including trade
 * secret and copyright laws. Dissemination of this information or reproduction
 * of this material is strictly forbidden unless prior written permission is
 * obtained from Adobe.
 * *****************************************************************************
 */

export enum ZoomLevel {
  ExtraSmall = 1,
  Small = 2,
  Medium = 3,
  Large = 4,
  ExtraLarge = 5,
}

export const zoomLevelToSizeMapping = {
  [ZoomLevel.ExtraSmall]: '--spectrum-global-dimension-size-1200',
  [ZoomLevel.Small]: '--spectrum-global-dimension-size-1700',
  [ZoomLevel.Medium]: '--spectrum-global-dimension-size-2000',
  [ZoomLevel.Large]: '--spectrum-global-dimension-size-2400',
  [ZoomLevel.ExtraLarge]: '--spectrum-global-dimension-size-3000',
};
