/**
 * *****************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2022 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Adobe and its suppliers, if any. The intellectual and technical concepts
 * contained herein are proprietary to Adobe and its suppliers and are
 * protected by all applicable intellectual property laws, including trade
 * secret and copyright laws. Dissemination of this information or reproduction
 * of this material is strictly forbidden unless prior written permission is
 * obtained from Adobe.
 * *****************************************************************************
 */

import { TemplateAsset } from '__DEPRECATED_DO_NOT_USE_OR_YOU_WILL_BE_FIRED/controllers/api/SparkSearchTypes';
import {
  FilterOption,
  Filters,
  FilterState,
  FilterTab,
} from '__DEPRECATED_DO_NOT_USE_OR_YOU_WILL_BE_FIRED/redux/filter/filter.slice';

import { AppThunk } from 'redux/store';

import { FilterProp, OrderValue, SearchParams, SearchSchema } from './types';

export const FILTER_PROPERTIES_MAP: { [key: string]: string } = {
  tasks: 'tasks',
  categories: 'categories',
  owner: 'owner.id',
  id: 'id',
  premium: 'premium',
  status: 'status',
  locales: 'locales',
  type: 'type',
};

export const ORDER_VALUE_MAP: { [key: string]: OrderValue | undefined } = {
  '-created': { prop: 'created', isDescending: true },
  created: { prop: 'created', isDescending: false },
  '-remixCount': { prop: 'remixCount', isDescending: true },
  remixCount: { prop: 'remixCount', isDescending: false },
  '-createDate': { prop: 'createDate', isDescending: true },
  createDate: { prop: 'createDate', isDescending: false },
};

const UNSPECIFIED_SCHEMA = SearchSchema.template;

class SearchParamsBuilder {
  query: string; // user inputted string
  limit: number;
  order: OrderValue;
  params: SearchParams;
  static BATCH_REQUEST_LIMIT = 20;
  constructor(
    schema: SearchSchema = SearchSchema.template,
    query: string = '*',
    limit: number = 100,
    order?: string,
    aggs?: string,
    aggsLimit?: number,
  ) {
    this.query = query;
    this.limit = limit;

    if (order && ORDER_VALUE_MAP[order]) {
      this.order = ORDER_VALUE_MAP[order] as OrderValue;
    } else {
      this.order = ORDER_VALUE_MAP['-created'] as OrderValue;
    }

    /*
     * qLoc: the locale of the inputted query string (q)
     * For now, Tempo will only support searching in English
     */
    this.params = {
      q: query,
      filters: SearchParamsBuilder.buildFilters({ filters: {}, schema }),
      limit: this.limit,
      qLoc: 'en-US',
      schema,
    };

    if (order) {
      this.params.orderBy = order;
    }

    if (aggs) {
      this.params.aggs = aggs;
    }

    if (aggsLimit) {
      this.params.aggsLimit = aggsLimit;
    }
  }
  /**
   * Use the Global State to modify the filters, query and order.
   * Provides the option to apply default filters that should not be saved to
   * the this.params instance variable.
   */
  applyFilterState(
    tab: FilterTab,
    defaultFilters: Filters = {},
  ): AppThunk<SearchParams> {
    return (dispatch, getState) => {
      const filterStates = getState().filters;
      const { contentType } = filterStates;
      // get filter state depending on contentType and tab
      const filterState: FilterState = filterStates[contentType][tab];
      const { filters } = filterState;
      const { query } = filterState;
      const { order } = filterState;

      this.setFilters({
        ...defaultFilters,
        ...filters,
      });
      this.updateQuery(query);
      this.updateOrder(order);

      return this.params;
    };
  }
  /**
   * Replace this.params.filters with new filters
   * @param filters
   */
  setFilters(filters: Filters) {
    this.params = {
      ...this.params,
      filters: SearchParamsBuilder.buildFilters({
        filters,
        schema: this.params.schema,
      }),
    };

    return this.params;
  }
  static buildFilters({
    filters,
    schema,
  }: {
    filters: Filters;
    schema: SearchSchema;
  }): string | null {
    const segments: string[] = [];
    const propsMap: { [key: string]: string } =
      schema === SearchSchema.template ? FILTER_PROPERTIES_MAP : {};

    for (const prop of Object.keys(filters)) {
      const propSegments: string[] = [];
      const filterProp: string = propsMap[prop] || prop;
      const options: FilterOption[] | undefined = filters[prop as FilterProp];

      if (options?.length) {
        let values: string[] = [...options.map((option) => option.value)];

        // check for 'none' to add a NOT segment
        if (values.indexOf('none') >= 0) {
          propSegments.push(`(NOT _exists_:${filterProp})`);

          // remove 'none' since we've already created a segment
          values = values.filter((item: string) => item !== 'none');
        }

        /*
         * join all other values into their own segment by using 'OR'
         * for now, we hard-code 'OR' according to initial Tempo requirements
         */
        if (values.length > 0) {
          values = values.map((v: any, index) => {
            if (v === '*') return v;

            // add double quotes around the value for an exact match
            if (options[index].isExactMatch) {
              return `"${v}"`;
            }

            return `${v}`;
          });
          const joinValue = values.join(' OR ');

          // ex. tasks:("flyers" OR "banners")
          propSegments.push(`${filterProp}:(${joinValue})`);
        }
      }

      if (propSegments.length > 0) {
        segments.push(propSegments.join(' OR '));
      }
    }

    return segments.join(' AND ') || null;
  }
  updateOrder(order: string): SearchParams {
    this.order =
      ORDER_VALUE_MAP[order] ?? (ORDER_VALUE_MAP['-created'] as OrderValue);
    this.params.orderBy = order || '-created';

    return this.params;
  }
  updateQuery(query: string): SearchParams {
    if (query === '*' || !query) {
      this.query = '*';
    } else {
      this.query = `"${query}"`;
    }

    this.params.q = this.query;

    return this.params;
  }
  updateAggs(aggs: string[], limit?: number) {
    this.params = {
      ...this.params,
      aggs: aggs.join(','),
    };

    if (limit) {
      this.params.aggsLimit = limit;
    }

    return this.params;
  }
  /**
   * Determines if a template asset matches the specified filters
   * @param asset - asset to determine filter match
   * @param filters
   *
   * not needed for platform assets
   */
  static isTemplateFilterMatch(
    asset: TemplateAsset,
    filters: Filters,
  ): boolean {
    for (const prop of Object.keys(filters)) {
      let assetValue = asset[prop];
      const filterOptions = filters[prop as FilterProp];

      if (prop === 'owner') {
        assetValue = asset.owner.id;
      }

      if (Array.isArray(filterOptions)) {
        // treat empty queryValues a match
        if (filterOptions.length === 0) {
          // eslint-disable-next-line no-continue
          continue;
        }

        // assetValue must be an empty array when matching 'none'
        if (
          filterOptions.find((option: FilterOption) => option.value === 'none')
        ) {
          if (assetValue.length !== 0) {
            return false;
          }

          // eslint-disable-next-line no-continue
          continue;
        }

        /*
         * assetValue must include at least one value from queryValue
         * A * matches with any value in assetValue
         */
        const includesValue = filterOptions.some((option: FilterOption) => {
          if (option.value === '*') return true;

          if (typeof option.value !== 'string' || !option.value.endsWith('*')) {
            if (Array.isArray(assetValue)) {
              return (
                assetValue.includes(option.value) ||
                assetValue.includes(option.value.toLowerCase())
              );
            }

            return assetValue === option.value;
          }

          const matchVal = option.value.split('*')[0];

          if (Array.isArray(assetValue)) {
            return !!assetValue.find((str) => str.startsWith(matchVal));
          }

          return assetValue.startsWith(matchVal);
        });

        if (!includesValue) {
          return false;
        }
      }
    }

    return true;
  }
  static isStatusMatch(
    asset: TemplateAsset,
    currentStatus?: FilterOption[],
  ): boolean {
    let statusValue: string | undefined;

    if (currentStatus && currentStatus.length > 0) {
      statusValue = currentStatus[0].value;
    }

    return !statusValue || statusValue === '*' || statusValue === asset.status;
  }
  static templates = new SearchParamsBuilder(
    SearchSchema.template,
    '*',
    100,
    '-created',
    'tasks,categories',
    50,
  );
  static assets = new SearchParamsBuilder(
    SearchSchema.asset,
    '*',
    100,
    '-createDate',
    'topics,directory',
    100,
  );
}

export const FETCH_ALL_OWNERS_PARAMS = new SearchParamsBuilder(
  UNSPECIFIED_SCHEMA,
  '*',
  0,
  'created',
  'owner.id',
  100,
).params;
// todo: how does this work? where does the id go?
export const getFetchByIdParams = (schema: SearchSchema /* id: string */) =>
  new SearchParamsBuilder(schema, '*', 1).params;

export default SearchParamsBuilder;
