/**
 * *****************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2022 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Adobe and its suppliers, if any. The intellectual and technical concepts
 * contained herein are proprietary to Adobe and its suppliers and are
 * protected by all applicable intellectual property laws, including trade
 * secret and copyright laws. Dissemination of this information or reproduction
 * of this material is strictly forbidden unless prior written permission is
 * obtained from Adobe.
 * *****************************************************************************
 */

import React, { CSSProperties, ReactChild, ReactElement } from 'react';

import { Text } from '@adobe/react-spectrum';

type FormLabelProps = {
  children?: ReactChild;
};

export default function FormLabel({ children }: FormLabelProps): ReactElement {
  const labelStyle: CSSProperties = {
    fontSize: 'var(--spectrum-global-dimension-font-size-150)',
    fontWeight: 'bold',
    lineHeight: 'var(--spectrum-global-dimension-font-size-150)',
    textTransform: 'capitalize',
  };

  return <Text UNSAFE_style={labelStyle}>{children}</Text>;
}

FormLabel.defaultProps = {
  children: undefined,
};
