/**
 * *****************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2022 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Adobe and its suppliers, if any. The intellectual and technical concepts
 * contained herein are proprietary to Adobe and its suppliers and are
 * protected by all applicable intellectual property laws, including trade
 * secret and copyright laws. Dissemination of this information or reproduction
 * of this material is strictly forbidden unless prior written permission is
 * obtained from Adobe.
 * *****************************************************************************
 */

import React, { FormEvent, Key, useCallback, useState } from 'react';

import {
  ActionGroup,
  Form,
  Item,
  TextField,
  View,
} from '@adobe/react-spectrum';
import Close from '@spectrum-icons/workflow/Close';

import { AssetMetadataFormFieldProps } from './AssetMetadataFormFieldProps';
import FormLabel from './FormLabel';

import styles from './AssetMetadataFormField.module.scss';

export default function GenericTagFormField({
  label,
  isReadOnly,
  isDisabled,
  onChange,
  textLabel,
  value: values,
}: AssetMetadataFormFieldProps<string[] | undefined>) {
  const [fieldValue, setFieldValue] = useState<string>('');
  const handleAdd = useCallback(
    (event: FormEvent) => {
      event.preventDefault();

      onChange([...(values ?? []), fieldValue]);
      setFieldValue('');
    },
    [fieldValue, onChange, values],
  );
  const handleRemove = useCallback(
    (key: Key) => {
      onChange(values?.filter((value) => value !== key));
    },
    [onChange, values],
  );

  return (
    <>
      <View marginTop="size-250">
        <FormLabel>{label}</FormLabel>
      </View>
      <Form onSubmit={handleAdd}>
        <TextField
          aria-label={textLabel ?? (typeof label === 'string' ? label : '')}
          isDisabled={isReadOnly || isDisabled}
          onChange={setFieldValue}
          value={fieldValue}
          width="100%"
        />
      </Form>
      <ActionGroup
        items={values?.map((value) => ({
          label: value,
          value,
        }))}
        isDisabled={isReadOnly || isDisabled}
        onAction={handleRemove}
        UNSAFE_className={styles.genericMultiPickerFormField}
      >
        {(item) => (
          <Item key={item.value}>
            {item.label}
            <Close size="XS" UNSAFE_style={{ marginLeft: '4px' }} />
          </Item>
        )}
      </ActionGroup>
    </>
  );
}
