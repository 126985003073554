/**
 * *****************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2022 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Adobe and its suppliers, if any. The intellectual and technical concepts
 * contained herein are proprietary to Adobe and its suppliers and are
 * protected by all applicable intellectual property laws, including trade
 * secret and copyright laws. Dissemination of this information or reproduction
 * of this material is strictly forbidden unless prior written permission is
 * obtained from Adobe.
 * *****************************************************************************
 */

import { getAssetStatus } from 'hooks/useAssetStatus';
import ApplicationMetadata, {
  AttributionMetadata,
  AudioMetadata,
  CreatorMetadata,
} from 'model/acp/ApplicationMetadata';
import { ApplicationMetadataKey } from 'model/acp/ApplicationMetadataKey';
import { Entitlement } from 'model/acp/Entitlement';
import LocalizedTitle from 'model/acp/LocalizedTitle';
import RepoMetadata from 'model/acp/RepoMetadata';
import { RepoMetadataKey } from 'model/acp/RepoMetadataKey';
import { ValidationFieldErrors } from 'model/errors/ValidationError';
import { Language, TranslatedLanguages } from 'model/Language';
import {
  AssetMetadataModel,
  AssetMetadataModelKey,
} from 'model/metadata/AssetMetadataModel';
import { FileMetadataModel } from 'model/metadata/FileMetadataModel';

import {
  mapApplicableFiltersFromApplicationMetadata,
  mapApplicationMetadataFromApplicableFilters,
} from './assetMetadata';
import { mapTranslationsToDCTitle } from './title';

export function validate(
  fileMetadataModel: AssetMetadataModel,
): ValidationFieldErrors | undefined {
  const errors: ValidationFieldErrors = {};

  if (fileMetadataModel[AssetMetadataModelKey.Name].length === 0) {
    errors[AssetMetadataModelKey.Name] = 'Required';
  }

  if (Object.keys(errors).length === 0) {
    return undefined;
  }

  return errors;
}

/*
 * TODO (starkey) / 2022-03-29
 * This multi-type support for creators is to support invalid assets from
 * a previous metadata version that Tempo mistakenly saved. It should be
 * removed when we are confident no bad assets exist.
 */
function mapToCreators(
  creatorMetadata: (CreatorMetadata | string)[],
): string[] {
  return creatorMetadata.map((creator) => {
    if (typeof creator === 'string') {
      return creator;
    }

    return creator.name;
  });
}

export function mapToFileMetadata(
  applicationMetadata: ApplicationMetadata,
  repoMetadata: RepoMetadata,
): FileMetadataModel {
  const formModelTitleMap = new Map<Language, string>([
    [
      Language.Default,
      new LocalizedTitle(applicationMetadata, Language.Default).toString(),
    ],
  ]);
  Object.values(TranslatedLanguages).forEach((language: Language) => {
    const existingLocalizedTitle = new LocalizedTitle(
      applicationMetadata,
      language,
    ).toString();

    if (existingLocalizedTitle) {
      formModelTitleMap.set(language, existingLocalizedTitle);
    }
  });

  const applicableRegions = applicationMetadata[
    ApplicationMetadataKey.ApplicableRegions
  ] ?? [''];

  return {
    [AssetMetadataModelKey.Animated]:
      applicationMetadata[ApplicationMetadataKey.Animated] ?? false,
    [AssetMetadataModelKey.ApplicableFilters]:
      mapApplicableFiltersFromApplicationMetadata(applicationMetadata),
    [AssetMetadataModelKey.ApplicableRegions]: applicableRegions[0],
    [AssetMetadataModelKey.AssetId]: repoMetadata[RepoMetadataKey.AssetId],
    [AssetMetadataModelKey.CreatedBy]: repoMetadata[RepoMetadataKey.CreatedBy],
    [AssetMetadataModelKey.Hero]:
      applicationMetadata[ApplicationMetadataKey.Hero] ?? false,
    [AssetMetadataModelKey.Language]:
      applicationMetadata[ApplicationMetadataKey.Language],
    [AssetMetadataModelKey.LicensingCategory]:
      applicationMetadata[ApplicationMetadataKey.LicensingCategory] ??
      Entitlement.Free,
    [AssetMetadataModelKey.Name]: repoMetadata[RepoMetadataKey.Name],
    [AssetMetadataModelKey.Priority]:
      applicationMetadata[ApplicationMetadataKey.Priority],
    [AssetMetadataModelKey.PublishedAssetId]:
      applicationMetadata[ApplicationMetadataKey.PublishedAssetId],
    [AssetMetadataModelKey.PublishedHash]:
      applicationMetadata[ApplicationMetadataKey.PublishedHash],
    [AssetMetadataModelKey.PublishedVersion]:
      applicationMetadata[ApplicationMetadataKey.PublishedVersion],
    [AssetMetadataModelKey.Status]: getAssetStatus(
      applicationMetadata,
      repoMetadata,
    ),
    [AssetMetadataModelKey.Tasks]:
      applicationMetadata[ApplicationMetadataKey.Tasks] ?? [],
    [AssetMetadataModelKey.Title]: formModelTitleMap,
    [AssetMetadataModelKey.Topics]:
      applicationMetadata[ApplicationMetadataKey.DCSubject] ?? [],
    [AssetMetadataModelKey.Traits]:
      applicationMetadata[ApplicationMetadataKey.Traits] ?? [],
    // Audio
    [AssetMetadataModelKey.Album]:
      applicationMetadata[ApplicationMetadataKey.Audio]?.[
        ApplicationMetadataKey.Album
      ] ?? '',
    [AssetMetadataModelKey.BPM]:
      applicationMetadata[ApplicationMetadataKey.Audio]?.[
        ApplicationMetadataKey.BPM
      ],
    [AssetMetadataModelKey.Creators]: mapToCreators(
      applicationMetadata[ApplicationMetadataKey.Attribution]?.[
        ApplicationMetadataKey.Creators
      ] ?? [],
    ),
    [AssetMetadataModelKey.Genres]:
      applicationMetadata[ApplicationMetadataKey.Audio]?.[
        ApplicationMetadataKey.Genres
      ] ?? [],
    [AssetMetadataModelKey.IsInstrumental]:
      applicationMetadata[ApplicationMetadataKey.Audio]?.[
        ApplicationMetadataKey.IsInstrumental
      ] ?? false,
    // Attribution
    [AssetMetadataModelKey.SubmittedBy]:
      applicationMetadata[ApplicationMetadataKey.Attribution]?.[
        ApplicationMetadataKey.SubmittedBy
      ] ??
      repoMetadata[RepoMetadataKey.CreatedBy] ??
      '',
    [AssetMetadataModelKey.Vendor]:
      applicationMetadata[ApplicationMetadataKey.Attribution]?.[
        ApplicationMetadataKey.Vendor
      ] ?? '',
  };
}

export function mapFromCreatorMetadata(
  creatorMetadata: (CreatorMetadata | string)[],
): CreatorMetadata[] {
  return creatorMetadata.map((creator) => {
    if (typeof creator === 'string') {
      return {
        name: creator,
      };
    }

    return creator;
  });
}

function mapFromAttributionMetadata(
  attributionMetadataModel: FileMetadataModel,
): AttributionMetadata | undefined {
  const hasVendor = attributionMetadataModel[AssetMetadataModelKey.Vendor];
  const hasCreators =
    attributionMetadataModel[AssetMetadataModelKey.Creators].length !== 0;

  if (!hasVendor && !hasCreators) {
    return undefined;
  }

  return {
    [ApplicationMetadataKey.Creators]: hasCreators
      ? mapFromCreatorMetadata(
          attributionMetadataModel[AssetMetadataModelKey.Creators],
        )
      : undefined,
    [ApplicationMetadataKey.SubmittedBy]:
      attributionMetadataModel[AssetMetadataModelKey.SubmittedBy],
    [ApplicationMetadataKey.Vendor]:
      attributionMetadataModel[AssetMetadataModelKey.Vendor],
  };
}

function mapFromAudioMetadata(
  audioMetadataModel: FileMetadataModel,
): AudioMetadata | undefined {
  const hasAlbum =
    audioMetadataModel[AssetMetadataModelKey.Album] &&
    audioMetadataModel[AssetMetadataModelKey.Album].length !== 0;
  const hasBPM = audioMetadataModel[AssetMetadataModelKey.BPM] !== undefined;
  const hasGenres =
    audioMetadataModel[AssetMetadataModelKey.Genres].length !== 0;
  const hasInstrumental =
    audioMetadataModel[AssetMetadataModelKey.IsInstrumental];

  if (!hasAlbum && !hasBPM && !hasGenres && !hasInstrumental) {
    return undefined;
  }

  return {
    [ApplicationMetadataKey.Album]: hasAlbum
      ? audioMetadataModel[AssetMetadataModelKey.Album]
      : undefined,
    [ApplicationMetadataKey.BPM]: hasBPM
      ? audioMetadataModel[AssetMetadataModelKey.BPM]
      : undefined,
    [ApplicationMetadataKey.Genres]: hasGenres
      ? audioMetadataModel[AssetMetadataModelKey.Genres]
      : undefined,
    [ApplicationMetadataKey.IsInstrumental]:
      audioMetadataModel[AssetMetadataModelKey.IsInstrumental],
  };
}

export function mapFromFileMetadata(
  fileMetadataModel: FileMetadataModel,
  applicationMetadata?: ApplicationMetadata,
): ApplicationMetadata {
  const languageMap = fileMetadataModel[AssetMetadataModelKey.Title];
  const applicableRegions =
    fileMetadataModel[AssetMetadataModelKey.ApplicableRegions];
  const updatedApplicationMetadata: ApplicationMetadata = {
    ...applicationMetadata,
    [ApplicationMetadataKey.Animated]:
      fileMetadataModel[AssetMetadataModelKey.Animated],
    [ApplicationMetadataKey.ApplicableFilters]:
      mapApplicationMetadataFromApplicableFilters(
        fileMetadataModel[ApplicationMetadataKey.ApplicableFilters],
      ),
    [ApplicationMetadataKey.ApplicableRegions]: applicableRegions
      ? [applicableRegions]
      : undefined,
    [ApplicationMetadataKey.DCSubject]:
      fileMetadataModel[AssetMetadataModelKey.Topics],
    [ApplicationMetadataKey.DCTitle]: mapTranslationsToDCTitle(languageMap),
    [ApplicationMetadataKey.Hero]:
      fileMetadataModel[AssetMetadataModelKey.Hero],
    [ApplicationMetadataKey.Language]:
      fileMetadataModel[AssetMetadataModelKey.Language],
    [ApplicationMetadataKey.LicensingCategory]:
      fileMetadataModel[AssetMetadataModelKey.LicensingCategory],
    [ApplicationMetadataKey.Priority]:
      fileMetadataModel[AssetMetadataModelKey.Priority],
    [ApplicationMetadataKey.PublishedAssetId]:
      fileMetadataModel[AssetMetadataModelKey.PublishedAssetId],
    [ApplicationMetadataKey.PublishedHash]:
      fileMetadataModel[AssetMetadataModelKey.PublishedHash],
    [ApplicationMetadataKey.PublishedVersion]:
      fileMetadataModel[AssetMetadataModelKey.PublishedVersion],
    [ApplicationMetadataKey.Tasks]:
      fileMetadataModel[AssetMetadataModelKey.Tasks],
    [ApplicationMetadataKey.Traits]:
      fileMetadataModel[AssetMetadataModelKey.Traits],
    // Audio
    [ApplicationMetadataKey.Audio]: mapFromAudioMetadata(fileMetadataModel),
    // Attribution
    [ApplicationMetadataKey.Attribution]:
      mapFromAttributionMetadata(fileMetadataModel),
  };

  return updatedApplicationMetadata;
}
