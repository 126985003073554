/**
 * *****************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2022 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Adobe and its suppliers, if any. The intellectual and technical concepts
 * contained herein are proprietary to Adobe and its suppliers and are
 * protected by all applicable intellectual property laws, including trade
 * secret and copyright laws. Dissemination of this information or reproduction
 * of this material is strictly forbidden unless prior written permission is
 * obtained from Adobe.
 * *****************************************************************************
 */

import React, { ReactChild, ReactElement } from 'react';

import { Flex, View } from '@adobe/react-spectrum';

import styles from './BaseCard.module.scss';

type BaseRenditionProps = {
  children: ReactChild | ReactChild[];
  isPremium: boolean;
  size?: 'S' | 'M';
};

export default function BaseRendition({
  children,
  isPremium,
  size = 'M',
}: BaseRenditionProps): ReactElement {
  return (
    <View
      UNSAFE_className={`${styles.rendition} ${
        size === 'S' ? styles.short : ''
      }`}
      position="relative"
    >
      <View
        position="absolute"
        top="size-0"
        bottom="size-0"
        left="size-0"
        right="size-0"
      >
        <Flex
          alignItems="center"
          height="100%"
          justifyContent="center"
          maxHeight="100%"
          maxWidth="100%"
          width="100%"
        >
          <>{children}</>
        </Flex>
      </View>

      {isPremium ? <div className={styles.premiumCrown} /> : null}
    </View>
  );
}

BaseRendition.defaultProps = {
  size: 'M',
};
