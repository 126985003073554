/**
 * *****************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2022 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Adobe and its suppliers, if any. The intellectual and technical concepts
 * contained herein are proprietary to Adobe and its suppliers and are
 * protected by all applicable intellectual property laws, including trade
 * secret and copyright laws. Dissemination of this information or reproduction
 * of this material is strictly forbidden unless prior written permission is
 * obtained from Adobe.
 * *****************************************************************************
 */

import React, { ReactElement, useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Flex, View } from '@adobe/react-spectrum';
import CheckmarkCircle from '@spectrum-icons/workflow/CheckmarkCircle';
import CloseCircle from '@spectrum-icons/workflow/CloseCircle';
import Comment from '@spectrum-icons/workflow/Comment';
import Delete from '@spectrum-icons/workflow/Delete';
import FolderAddTo from '@spectrum-icons/workflow/FolderAddTo';
import Info from '@spectrum-icons/workflow/Info';
import InfoOutline from '@spectrum-icons/workflow/InfoOutline';
import PublishCheck from '@spectrum-icons/workflow/PublishCheck';
import PublishRemove from '@spectrum-icons/workflow/PublishRemove';
import Rename from '@spectrum-icons/workflow/Rename';

import ConfirmationDialog from 'components/Dialogs/ConfirmationDialog';
import FolderDialog from 'components/Dialogs/FolderDialog';
import { RenameAssetFormDialog } from 'components/Dialogs/FormDialog';
import useAssetWorkflowActions from 'hooks/useAssetWorkflowActions';
import useConstant from 'hooks/useConstant';
import useCurrentAssetByPath from 'hooks/useCurrentAssetByPath';
import RepoMetadata from 'model/acp/RepoMetadata';
import { RepoMetadataKey } from 'model/acp/RepoMetadataKey';
import { DocumentRailPane } from 'model/DocumentRailPane';
import { setDetailRailPane } from 'redux/explorer/explorer.slice';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { queueJob } from 'redux/workflowActions/workflowActions.slice';
import { getParentPath, isCollection, isDirectory } from 'utils/assets';

import RailElement, { RailElementVariant } from './RailElement';

import styles from './DocumentActionRail.module.scss';

enum ConfirmationAction {
  ApproveDialog,
  DeleteDialog,
  MoveDialog,
  RenameDialog,
}

type DetailActionRailProps = {
  path: string;
  repoMetadata: RepoMetadata;
  hasStagedChanges: boolean;
  onOpenForm: () => void;
  isPanelCollapsed: boolean;
};

export default function DetailActionRail({
  hasStagedChanges,
  path,
  repoMetadata,
  onOpenForm,
  isPanelCollapsed,
}: DetailActionRailProps): ReactElement {
  const { acpBasePath } = useConstant();
  const { currentAssetByPath } = useCurrentAssetByPath();
  const [openDialog, setOpenDialog] = useState<ConfirmationAction>();
  const hideDialog = useCallback(
    () => setOpenDialog(undefined),
    [setOpenDialog],
  );
  const assetWorkflowActions = useAssetWorkflowActions();
  const { detailRailPane } = useAppSelector((state) => state.explorer);
  const dispatch = useAppDispatch();
  // Popover details
  const openAssetDetails = useCallback(() => {
    onOpenForm();
    dispatch(setDetailRailPane(DocumentRailPane.Details));
  }, [dispatch, onOpenForm]);
  const openComments = useCallback(() => {
    onOpenForm();
    dispatch(setDetailRailPane(DocumentRailPane.Comments));
  }, [dispatch, onOpenForm]);
  // Collection creation
  const navigate = useNavigate();
  // Folder creation
  const isCollectionDetail = isCollection(repoMetadata);
  const doPublish = useCallback(
    async () =>
      dispatch(
        queueJob({
          message: `Publishing asset "${repoMetadata[RepoMetadataKey.Name]}"`,
          action: async (): Promise<string> => {
            try {
              await assetWorkflowActions.publish(path);

              return `Published "${repoMetadata[RepoMetadataKey.Name]}"`;
            } catch (error) {
              if ((error as Error).message === 'Failed') {
                throw new Error(
                  `Failed to publish "${repoMetadata[RepoMetadataKey.Name]}"`,
                );
              }

              throw new Error(
                `Failed to publish "${repoMetadata[RepoMetadataKey.Name]}": ${
                  (error as Error).message
                }`,
              );
            }
          },
          options: {
            isIndeterminate: true,
          },
        }),
      ),
    [assetWorkflowActions, dispatch, path, repoMetadata],
  );
  const doApprove = useCallback(
    (targetDestination: string) => {
      hideDialog();

      dispatch(
        queueJob({
          message: `Approving asset "${
            repoMetadata[RepoMetadataKey.Name]
          }" to "${targetDestination}"`,
          action: async (): Promise<string> => {
            try {
              let newPath = targetDestination.startsWith(acpBasePath.assets)
                ? targetDestination.slice(acpBasePath.assets.length)
                : targetDestination;

              newPath += `/${repoMetadata[RepoMetadataKey.Name]}?view=detail`;

              await assetWorkflowActions.approve(targetDestination, true)(path);
              navigate(newPath);

              return `Approved "${
                repoMetadata[RepoMetadataKey.Name]
              }" to ${targetDestination}`;
            } catch (error) {
              if ((error as Error).message === 'Failed') {
                throw new Error(
                  `Failed to approve "${repoMetadata[RepoMetadataKey.Name]}"`,
                );
              }

              throw new Error(
                `Failed to approve "${repoMetadata[RepoMetadataKey.Name]}": ${
                  (error as Error).message
                }`,
              );
            }
          },
          options: {
            isIndeterminate: true,
          },
        }),
      );
    },
    [
      acpBasePath,
      assetWorkflowActions,
      dispatch,
      path,
      repoMetadata,
      hideDialog,
      navigate,
    ],
  );
  const doDelete = useCallback(() => {
    hideDialog();

    dispatch(
      queueJob({
        message: `Deleting ${path}`,
        action: async (): Promise<string> => {
          try {
            await assetWorkflowActions.delete(path);
            navigate(`${getParentPath(path, {})}?view=card`, {
              replace: true,
            });

            return `Deleted "${path}"`;
          } catch (error) {
            if ((error as Error).message === 'Failed') {
              throw new Error(
                `Failed to delete "${repoMetadata[RepoMetadataKey.Name]}"`,
              );
            }

            throw new Error(
              `Failed to delete "${repoMetadata[RepoMetadataKey.Name]}": ${
                (error as Error).message
              }`,
            );
          }
        },
        options: {
          isIndeterminate: true,
        },
      }),
    );
  }, [
    assetWorkflowActions,
    dispatch,
    hideDialog,
    navigate,
    path,
    repoMetadata,
  ]);
  const doMove = useCallback(
    (targetDestination: string) => {
      hideDialog();

      dispatch(
        queueJob({
          message: `Moving asset "${
            repoMetadata[RepoMetadataKey.Name]
          }" to "${targetDestination}"`,
          action: async (): Promise<string> => {
            try {
              let newPath = targetDestination.startsWith(acpBasePath.assets)
                ? targetDestination.slice(acpBasePath.assets.length)
                : targetDestination;

              newPath += `/${encodeURIComponent(
                repoMetadata[RepoMetadataKey.Name],
              )}`;

              await assetWorkflowActions.move(path, {
                targetDestination,
                redirect: newPath,
              });

              return `Moved "${
                repoMetadata[RepoMetadataKey.Name]
              }" to ${targetDestination}`;
            } catch (error) {
              if ((error as Error).message === 'Failed') {
                throw new Error(
                  `Failed to move "${repoMetadata[RepoMetadataKey.Name]}"`,
                );
              }

              throw new Error(
                `Failed to move "${repoMetadata[RepoMetadataKey.Name]}": ${
                  (error as Error).message
                }`,
              );
            }
          },
          options: {
            isIndeterminate: true,
          },
        }),
      );
    },
    [
      acpBasePath,
      assetWorkflowActions,
      dispatch,
      path,
      repoMetadata,
      hideDialog,
    ],
  );
  const doRename = useCallback(
    async (filename: string) => {
      hideDialog();

      dispatch(
        queueJob({
          message: `Renaming asset "${
            repoMetadata[RepoMetadataKey.Name]
          }" to "${filename}"`,
          action: async (): Promise<string> => {
            try {
              let newPath = repoMetadata[RepoMetadataKey.Path].startsWith(
                acpBasePath.assets,
              )
                ? repoMetadata[RepoMetadataKey.Path].slice(
                    acpBasePath.assets.length,
                  )
                : repoMetadata[RepoMetadataKey.Path];

              newPath = newPath.split('/').slice(0, -1).join('/');
              newPath += `/${filename}?view=detail`;

              await assetWorkflowActions.rename(path, filename, newPath);

              return `Renamed asset to "${filename}"`;
            } catch (error) {
              if ((error as Error).message === 'Failed') {
                throw new Error(
                  `Failed to rename "${repoMetadata[RepoMetadataKey.Name]}"`,
                );
              }

              throw new Error(
                `Failed to rename "${repoMetadata[RepoMetadataKey.Name]}": ${
                  (error as Error).message
                }`,
              );
            }
          },
          options: {
            isIndeterminate: true,
          },
        }),
      );
    },
    [
      acpBasePath.assets,
      assetWorkflowActions,
      dispatch,
      hideDialog,
      path,
      repoMetadata,
    ],
  );
  const doReject = useCallback(
    async () =>
      dispatch(
        queueJob({
          message: `Rejecting asset "${repoMetadata[RepoMetadataKey.Name]}"`,
          action: async (): Promise<string> => {
            try {
              await assetWorkflowActions.reject(path);

              return `Rejected "${repoMetadata[RepoMetadataKey.Name]}"`;
            } catch (error) {
              if ((error as Error).message === 'Failed') {
                throw new Error(
                  `Failed to reject "${repoMetadata[RepoMetadataKey.Name]}"`,
                );
              }

              throw new Error(
                `Failed to reject "${repoMetadata[RepoMetadataKey.Name]}": ${
                  (error as Error).message
                }`,
              );
            }
          },
          options: {
            isIndeterminate: true,
          },
        }),
      ),
    [assetWorkflowActions, dispatch, path, repoMetadata],
  );
  const doUnpublish = useCallback(
    async () =>
      dispatch(
        queueJob({
          message: `Unpublishing asset "${repoMetadata[RepoMetadataKey.Name]}"`,
          action: async (): Promise<string> => {
            try {
              await assetWorkflowActions.unpublish(path);

              return `Unpublished "${repoMetadata[RepoMetadataKey.Name]}"`;
            } catch (error) {
              if ((error as Error).message === 'Failed') {
                throw new Error(
                  `Failed to unpublish "${repoMetadata[RepoMetadataKey.Name]}"`,
                );
              }

              throw new Error(
                `Failed to unpublish "${repoMetadata[RepoMetadataKey.Name]}": ${
                  (error as Error).message
                }`,
              );
            }
          },
          options: {
            isIndeterminate: true,
          },
        }),
      ),
    [assetWorkflowActions, dispatch, path, repoMetadata],
  );

  return (
    <>
      <div className={styles.actionRail}>
        <Flex gap="size-100" direction="column">
          <RailElement
            key="details"
            action={openAssetDetails}
            icon={
              !isPanelCollapsed ? <Info size="S" /> : <InfoOutline size="S" />
            }
            isActive={
              !isPanelCollapsed && detailRailPane === DocumentRailPane.Details
            }
            isDisabled={
              currentAssetByPath === acpBasePath.author ||
              currentAssetByPath === acpBasePath.submit
            }
            label={`${isCollectionDetail ? 'Collection' : 'Asset'} Details`}
          />
          <RailElement
            key="comments"
            action={openComments}
            icon={<Comment />}
            isActive={
              !isPanelCollapsed && detailRailPane === DocumentRailPane.Comments
            }
            isDisabled={
              currentAssetByPath === acpBasePath.author ||
              currentAssetByPath === acpBasePath.submit
            }
            label="Comments"
          />
          <View borderTopColor="gray-400" borderTopWidth="thin" />
          {currentAssetByPath.includes(acpBasePath.submit) && (
            <>
              <RailElement
                key="Approve"
                action={() => setOpenDialog(ConfirmationAction.ApproveDialog)}
                icon={<CheckmarkCircle />}
                variant={RailElementVariant.Positive}
                isDisabled={hasStagedChanges}
                label="Approve"
              />
              <RailElement
                key="Reject"
                action={doReject}
                icon={<CloseCircle />}
                variant={RailElementVariant.Negative}
                isDisabled={hasStagedChanges}
                label="Reject"
              />
            </>
          )}
          {currentAssetByPath.includes(acpBasePath.author) && (
            <>
              <RailElement
                key="Unublish"
                action={doUnpublish}
                icon={<PublishRemove />}
                variant={RailElementVariant.Negative}
                isDisabled={hasStagedChanges}
                label="Unpublish"
              />
              <RailElement
                key="Publish"
                action={doPublish}
                icon={<PublishCheck />}
                variant={RailElementVariant.Positive}
                isDisabled={hasStagedChanges}
                label="Publish"
              />
            </>
          )}
          <RailElement
            key="Move"
            action={() => setOpenDialog(ConfirmationAction.MoveDialog)}
            icon={<FolderAddTo />}
            label="Move"
          />
          <RailElement
            key="Delete"
            action={() => setOpenDialog(ConfirmationAction.DeleteDialog)}
            icon={<Delete />}
            label="Delete"
          />
          <RailElement
            key="Rename"
            action={() => setOpenDialog(ConfirmationAction.RenameDialog)}
            icon={<Rename />}
            label="Rename"
          />
        </Flex>
      </div>
      <ConfirmationDialog
        isOpen={openDialog === ConfirmationAction.DeleteDialog}
        onClose={() => setOpenDialog(undefined)}
        onConfirm={doDelete}
        title="Are you sure?"
        confirm="Delete"
        content={
          <>
            Deletion will unpublish this asset and then remove it from storage.
          </>
        }
      />

      <FolderDialog
        invalidDestinations={[path.split('/').slice(0, -1).join('/')]}
        invalidDestinationDirectories={
          isDirectory(repoMetadata) ? [path] : undefined
        }
        initialDirectory={
          openDialog === ConfirmationAction.MoveDialog
            ? path.split('/').slice(0, -1).join('/')
            : acpBasePath.author
        }
        isOpen={
          openDialog === ConfirmationAction.ApproveDialog ||
          openDialog === ConfirmationAction.MoveDialog
        }
        onClose={hideDialog}
        onConfirm={
          openDialog === ConfirmationAction.MoveDialog ? doMove : doApprove
        }
        title={
          openDialog === ConfirmationAction.ApproveDialog
            ? 'Select Destination'
            : undefined
        }
      />
      <RenameAssetFormDialog
        filename={repoMetadata[RepoMetadataKey.Name]}
        isFolder={isDirectory(repoMetadata)}
        isLoading={false}
        isOpen={openDialog === ConfirmationAction.RenameDialog}
        onClose={hideDialog}
        onRename={doRename}
      />
    </>
  );
}
