/**
 * *****************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2022 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Adobe and its suppliers, if any. The intellectual and technical concepts
 * contained herein are proprietary to Adobe and its suppliers and are
 * protected by all applicable intellectual property laws, including trade
 * secret and copyright laws. Dissemination of this information or reproduction
 * of this material is strictly forbidden unless prior written permission is
 * obtained from Adobe.
 * *****************************************************************************
 */

import React, { ReactElement } from 'react';

import { View } from '@adobe/react-spectrum';
import FolderOutline from '@spectrum-icons/workflow/FolderOutline';

import styles from './BaseCard.module.scss';

type FolderRenditionProps = {
  isPremium: boolean;
  size: 'S' | 'M' | 'L';
};

export default function FolderRendition({
  isPremium,
  size,
}: FolderRenditionProps): ReactElement {
  return (
    <View UNSAFE_className="spectrum-Card-preview" position="relative">
      <FolderOutline UNSAFE_className={styles.icon} size={size} />

      {isPremium ? (
        <div className={`${styles.premiumCrown} ${styles.icon}`} />
      ) : null}
    </View>
  );
}
