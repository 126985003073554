/**
 * *****************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2022 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Adobe and its suppliers, if any. The intellectual and technical concepts
 * contained herein are proprietary to Adobe and its suppliers and are
 * protected by all applicable intellectual property laws, including trade
 * secret and copyright laws. Dissemination of this information or reproduction
 * of this material is strictly forbidden unless prior written permission is
 * obtained from Adobe.
 * *****************************************************************************
 */

import React, { ReactElement, useEffect } from 'react';
import { Helmet } from 'react-helmet';

import { Divider, Flex, View } from '@adobe/react-spectrum';

import useRepoMetadata from 'hooks/acp/useRepoMetadata';
import useCurrentAssetByPath from 'hooks/useCurrentAssetByPath';
import useWorkflow from 'hooks/useWorkflow';
import { RepoMetadataKey } from 'model/acp/RepoMetadataKey';
import { AssetActionDomain } from 'model/app/AssetActionDomain';
import { DocumentView } from 'model/DocumentView';
import { setDocumentView } from 'redux/explorer/explorer.slice';
import { useAppDispatch } from 'redux/hooks';
import { isCollection } from 'utils/assets';

import CollectionDetailView from './CollectionDetailView';
import FileDetailView from './FileDetailView';
import LoadingDetailView from './LoadingDetailView';

export default function DetailView(): ReactElement {
  const { currentAssetByPath } = useCurrentAssetByPath();
  const dispatch = useAppDispatch();
  const { results: repoMetadata } = useRepoMetadata({
    path: currentAssetByPath,
  });
  const { selectableAssets } = useWorkflow({
    actionDomain: AssetActionDomain.Single,
    actionContext: 'file',
  });

  useEffect(() => {
    if (
      repoMetadata &&
      !selectableAssets.has(repoMetadata[RepoMetadataKey.AssetClass])
    ) {
      dispatch(setDocumentView(DocumentView.Card));
    }
  }, [dispatch, repoMetadata, selectableAssets]);

  let DetailComponent: () => ReactElement;

  if (!repoMetadata) {
    DetailComponent = LoadingDetailView;
  } else if (isCollection(repoMetadata)) {
    DetailComponent = CollectionDetailView;
  } else {
    DetailComponent = FileDetailView;
  }

  return (
    <>
      <Helmet>
        {repoMetadata ? (
          <title>{repoMetadata[RepoMetadataKey.Name]} | Tempo</title>
        ) : null}
      </Helmet>
      <Divider marginTop="size-150" size="S" />
      <View flex height="100%" marginStart="size-400" minHeight="size-0">
        <Flex gap="size-200" height="100%" minHeight="size-0" width="100%">
          <DetailComponent />
        </Flex>
      </View>
    </>
  );
}
