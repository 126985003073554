/**
 * *****************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2022 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Adobe and its suppliers, if any. The intellectual and technical concepts
 * contained herein are proprietary to Adobe and its suppliers and are
 * protected by all applicable intellectual property laws, including trade
 * secret and copyright laws. Dissemination of this information or reproduction
 * of this material is strictly forbidden unless prior written permission is
 * obtained from Adobe.
 * *****************************************************************************
 */

import React, { ReactElement } from 'react';

import { Image, View } from '@adobe/react-spectrum';
import { DimensionValue } from '@react-types/shared';

type AvatarProps = {
  onPress?: () => void;
  src: string | undefined;
  size?: DimensionValue;
};

export default function Avatar({
  onPress,
  size,
  src,
}: AvatarProps): ReactElement {
  const avatar = (
    <View
      UNSAFE_style={{
        borderRadius: '50%',
        overflow: 'hidden',
      }}
      alignSelf="flex-start"
      backgroundColor={src ? undefined : 'gray-200'}
      flexShrink={0}
      height={size}
      width={size}
    >
      {src ? <Image src={src} alt="User Profile Picture" /> : null}
    </View>
  );

  if (!onPress) {
    return avatar;
  }

  return (
    <button className="spectrum-Tool" type="button" onClick={onPress}>
      {avatar}
    </button>
  );
}

Avatar.defaultProps = {
  onPress: null,
  size: 'size-350',
};
