/**
 * *****************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2022 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Adobe and its suppliers, if any. The intellectual and technical concepts
 * contained herein are proprietary to Adobe and its suppliers and are
 * protected by all applicable intellectual property laws, including trade
 * secret and copyright laws. Dissemination of this information or reproduction
 * of this material is strictly forbidden unless prior written permission is
 * obtained from Adobe.
 * *****************************************************************************
 */

import React, {
  CSSProperties,
  ReactElement,
  RefObject,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';

import styles from './index.module.scss';

type Props = {
  children?: ReactElement;
  style?: CSSProperties;
};

export default function ScrollPane({
  children,
  style = {},
}: Props): ReactElement {
  const ref = useRef<HTMLDivElement>();
  const [isScrolledToTop, setIsScrolledToTop] = useState<boolean>(true);
  const [isScrolledToBottom, setIsScrolledToBottom] = useState<boolean>(true);
  const [height, setHeight] = useState<string>('100%');
  const onScroll = useCallback((): void => {
    if (ref.current) {
      const { scrollTop, clientHeight, scrollHeight } = ref.current;
      setIsScrolledToTop(!scrollTop);
      setIsScrolledToBottom(clientHeight + scrollTop === scrollHeight);
    }
  }, [setIsScrolledToBottom, setIsScrolledToTop]);
  const onResize = useCallback((): void => {
    if (!style.height) {
      if (ref.current) {
        const { offsetTop, offsetParent } = ref.current;

        if (offsetParent?.clientHeight) {
          setHeight(`${offsetParent.clientHeight - offsetTop}px`);
        }
      }
    }

    onScroll();
  }, [setHeight, onScroll, style]);

  useEffect(() => {
    const currentRef = ref.current;

    if (currentRef) {
      currentRef.addEventListener('scroll', (): void => onScroll());
      onResize();
      window.addEventListener('resize', onResize);
    }

    return (): void => {
      if (currentRef) {
        currentRef.removeEventListener('scroll', (): void => onScroll());
        window.removeEventListener('resize', onResize);
      }
    };
  }, [ref, onScroll, children, onResize]);

  return (
    <div
      ref={ref as RefObject<HTMLDivElement>}
      style={{
        overflowY: 'auto',
        width: '100%',
        height,
        position: 'relative',
        ...style,
      }}
    >
      {children && (
        <>
          <div
            className={`${styles.scrollShadow} ${styles.scrollShadowTop} ${
              !isScrolledToTop ? styles.scrollShadowActive : ''
            }`}
          />
          <div>{children}</div>
          <div
            className={`${styles.scrollShadow} ${styles.scrollShadowBottom} ${
              !isScrolledToBottom ? styles.scrollShadowActive : ''
            }`}
          />
        </>
      )}
    </div>
  );
}

ScrollPane.defaultProps = {
  children: undefined,
  style: {},
};
