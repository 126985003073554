/**
 * *****************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2022 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Adobe and its suppliers, if any. The intellectual and technical concepts
 * contained herein are proprietary to Adobe and its suppliers and are
 * protected by all applicable intellectual property laws, including trade
 * secret and copyright laws. Dissemination of this information or reproduction
 * of this material is strictly forbidden unless prior written permission is
 * obtained from Adobe.
 * *****************************************************************************
 */

import React, { ReactElement } from 'react';

import { View } from '@adobe/react-spectrum';

import useApplicationMetadata from 'hooks/acp/useApplicationMetadata';
import useRepoMetadata from 'hooks/acp/useRepoMetadata';
import { getAssetStatus } from 'hooks/useAssetStatus';
import useWorkflow from 'hooks/useWorkflow';
import { ApplicationMetadataKey } from 'model/acp/ApplicationMetadataKey';
import { AssetClass } from 'model/acp/AssetClass';
import { Entitlement } from 'model/acp/Entitlement';
import LocalizedTitle from 'model/acp/LocalizedTitle';
import { RepoMetadataKey } from 'model/acp/RepoMetadataKey';
import { AssetActionDomain } from 'model/app/AssetActionDomain';
import Constant from 'model/constant/Constant';
import { ZoomLevel } from 'model/ZoomLevel';

import BaseCard from './BaseCard';
import { CardAction } from './BaseCardQuickActions';
import BaseRendition from './BaseRendition';
import CollectionRendition from './CollectionRendition';
import FileRendition from './FileRendition';
import FolderRendition from './FolderRendition';

type CardProps = {
  quickActions?: CardAction[];
  isActive?: boolean;
  isSelected?: boolean;
  kind: 'file' | 'collection' | 'folder';
  onAction?: (action: string) => void;
  onClick: () => void;
  onSelect: () => void;
  path: string;
  zoomLevel: ZoomLevel;
};

export default function PathCard({
  quickActions,
  isActive = false,
  isSelected = false,
  kind,
  onAction,
  onClick,
  onSelect,
  path,
  zoomLevel,
}: CardProps): ReactElement {
  const { acpBasePath } = new Constant();
  // Metadata
  const { results: repoMetadata } = useRepoMetadata({ path });
  const { results: applicationMetadata } = useApplicationMetadata({ path });
  const { selectableAssets } = useWorkflow({
    actionDomain: AssetActionDomain.Bulk,
    actionContext: 'file',
  });
  const isPremium =
    applicationMetadata?.[ApplicationMetadataKey.LicensingCategory] ===
    Entitlement.Premium;
  const shouldShowStatus =
    kind === 'folder'
      ? repoMetadata?.[RepoMetadataKey.Path].startsWith(acpBasePath.author)
      : true;
  let metadata: (string | undefined)[][] = [];
  let canBeSelected: boolean = true;
  let rendition: JSX.Element | undefined;

  if (kind === 'collection') {
    canBeSelected = selectableAssets.has(AssetClass.File);
    metadata = [
      [
        'Title',
        applicationMetadata
          ? new LocalizedTitle(applicationMetadata).toString() || '–'
          : undefined,
      ],
    ];
    rendition = (
      <View marginBottom="size-150">
        <BaseRendition isPremium={isPremium} size="S">
          <CollectionRendition />
        </BaseRendition>
      </View>
    );
  } else if (kind === 'file') {
    canBeSelected = selectableAssets.has(AssetClass.File);
    metadata = [
      [
        'Title',
        applicationMetadata
          ? new LocalizedTitle(applicationMetadata).toString() || '\u2013'
          : undefined,
      ],
      [
        'Tasks',
        applicationMetadata
          ? applicationMetadata[ApplicationMetadataKey.Tasks]?.join(', ') ||
            '\u2013'
          : undefined,
      ],
      [
        'Topics',
        applicationMetadata
          ? applicationMetadata[ApplicationMetadataKey.DCSubject]?.join(', ') ||
            '\u2013'
          : undefined,
      ],
      [
        'Traits',
        applicationMetadata
          ? applicationMetadata[ApplicationMetadataKey.Traits]?.join(', ') ||
            '\u2013'
          : undefined,
      ],
    ];
    rendition = (
      <BaseRendition isPremium={isPremium}>
        <FileRendition isPremium={isPremium} repoMetadata={repoMetadata} />
      </BaseRendition>
    );
  } else {
    canBeSelected = selectableAssets.has(AssetClass.Directory);

    if (zoomLevel === ZoomLevel.Medium) {
      rendition = <FolderRendition isPremium={isPremium} size="S" />;
    } else if (zoomLevel > ZoomLevel.Medium) {
      rendition = <FolderRendition isPremium={isPremium} size="L" />;
    }
  }

  return (
    <BaseCard
      canBeSelected={canBeSelected}
      isActive={isActive && canBeSelected}
      isInactive={isActive && !canBeSelected}
      isMinimal={kind === 'folder'}
      isSelected={isSelected}
      onAction={onAction}
      onClick={onClick}
      onSelect={onSelect}
      metadata={metadata}
      shouldShowStatus={shouldShowStatus}
      modifiedDate={repoMetadata?.[RepoMetadataKey.ModifyDate]}
      quickActions={quickActions}
      rendition={rendition}
      size={zoomLevel}
      status={getAssetStatus(applicationMetadata, repoMetadata)}
      title={repoMetadata ? repoMetadata[RepoMetadataKey.Name] : undefined}
    />
  );
}

PathCard.defaultProps = {
  isActive: false,
  isSelected: false,
  onAction: undefined,
  quickActions: undefined,
};
