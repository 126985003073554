/**
 * *****************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2022 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Adobe and its suppliers, if any. The intellectual and technical concepts
 * contained herein are proprietary to Adobe and its suppliers and are
 * protected by all applicable intellectual property laws, including trade
 * secret and copyright laws. Dissemination of this information or reproduction
 * of this material is strictly forbidden unless prior written permission is
 * obtained from Adobe.
 * *****************************************************************************
 */

import { getAssetStatus } from 'hooks/useAssetStatus';
import ApplicationMetadata from 'model/acp/ApplicationMetadata';
import { ApplicationMetadataKey } from 'model/acp/ApplicationMetadataKey';
import LocalizedTitle from 'model/acp/LocalizedTitle';
import RepoMetadata from 'model/acp/RepoMetadata';
import { RepoMetadataKey } from 'model/acp/RepoMetadataKey';
import { ValidationFieldErrors } from 'model/errors/ValidationError';
import { Language, TranslatedLanguages } from 'model/Language';
import { AssetMetadataModelKey } from 'model/metadata/AssetMetadataModel';
import { CollectionMetadataModel } from 'model/metadata/CollectionMetadataModel';

import {
  mapApplicableFiltersFromApplicationMetadata,
  mapApplicationMetadataFromApplicableFilters,
} from './assetMetadata';
import { mapTranslationsToDCTitle } from './title';

export function validate(
  collectionMetadataModel: CollectionMetadataModel,
): ValidationFieldErrors | undefined {
  const errors: ValidationFieldErrors = {};

  if (collectionMetadataModel[AssetMetadataModelKey.Name].length === 0) {
    errors[AssetMetadataModelKey.Name] = 'Required';
  }

  if (Object.keys(errors).length === 0) {
    return undefined;
  }

  return errors;
}

export function mapToCollectionMetadata(
  applicationMetadata: ApplicationMetadata,
  repoMetadata: RepoMetadata,
): CollectionMetadataModel {
  const formModelTitleMap = new Map<Language, string>([
    [
      Language.Default,
      new LocalizedTitle(applicationMetadata, Language.Default).toString(),
    ],
  ]);
  Object.values(TranslatedLanguages).forEach((language: Language) => {
    const existingLocalizedTitle = new LocalizedTitle(
      applicationMetadata,
      language,
    ).toString();

    if (existingLocalizedTitle) {
      formModelTitleMap.set(language, existingLocalizedTitle);
    }
  });

  const applicableRegions = applicationMetadata[
    ApplicationMetadataKey.ApplicableRegions
  ] ?? [''];

  return {
    [AssetMetadataModelKey.ApplicableFilters]:
      mapApplicableFiltersFromApplicationMetadata(applicationMetadata),
    [AssetMetadataModelKey.ApplicableRegions]: applicableRegions[0],
    [AssetMetadataModelKey.AssetId]: repoMetadata[RepoMetadataKey.AssetId],
    [AssetMetadataModelKey.CreatedBy]: repoMetadata[RepoMetadataKey.CreatedBy],
    [AssetMetadataModelKey.Language]:
      applicationMetadata[ApplicationMetadataKey.Language],
    [AssetMetadataModelKey.Name]: repoMetadata[RepoMetadataKey.Name],
    [AssetMetadataModelKey.Priority]:
      applicationMetadata[ApplicationMetadataKey.Priority],
    [AssetMetadataModelKey.Status]: getAssetStatus(
      applicationMetadata,
      repoMetadata,
    ),
    [AssetMetadataModelKey.Title]: formModelTitleMap,
  };
}

export function mapFromCollectionMetadata(
  collectionMetadata: CollectionMetadataModel,
  applicationMetadata?: ApplicationMetadata,
): ApplicationMetadata {
  const languageMap = collectionMetadata[AssetMetadataModelKey.Title];
  const applicableRegions =
    collectionMetadata[AssetMetadataModelKey.ApplicableRegions];
  const updatedApplicationMetadata: ApplicationMetadata = {
    ...applicationMetadata,
    [ApplicationMetadataKey.ApplicableFilters]:
      mapApplicationMetadataFromApplicableFilters(
        collectionMetadata[ApplicationMetadataKey.ApplicableFilters],
      ),
    [ApplicationMetadataKey.ApplicableRegions]: applicableRegions
      ? [applicableRegions]
      : undefined,
    [ApplicationMetadataKey.DCTitle]: mapTranslationsToDCTitle(languageMap),
    [ApplicationMetadataKey.Language]:
      collectionMetadata[AssetMetadataModelKey.Language],
    [ApplicationMetadataKey.Priority]:
      collectionMetadata[AssetMetadataModelKey.Priority],
  };

  return updatedApplicationMetadata;
}
