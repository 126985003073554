/**
 * *****************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2022 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Adobe and its suppliers, if any. The intellectual and technical concepts
 * contained herein are proprietary to Adobe and its suppliers and are
 * protected by all applicable intellectual property laws, including trade
 * secret and copyright laws. Dissemination of this information or reproduction
 * of this material is strictly forbidden unless prior written permission is
 * obtained from Adobe.
 * *****************************************************************************
 */

import React, { ReactElement } from 'react';

import { TextField, Well } from '@adobe/react-spectrum';

import FormLabel from 'components/AssetMetadataForm/fields/FormLabel';
import useRepoMetadata from 'hooks/acp/useRepoMetadata';
import { RepoMetadataKey } from 'model/acp/RepoMetadataKey';

import styles from './DetailView.module.scss';

type AssetFixedMetadataFormProps = {
  path: string;
  customFields?: string[][];
};

export default function AssetFixedMetadataForm({
  customFields,
  path,
}: AssetFixedMetadataFormProps): ReactElement {
  const { results: repoMetadata, isLoading } = useRepoMetadata({ path });

  if (isLoading || repoMetadata === undefined) {
    return <></>;
  }

  return (
    <Well>
      {customFields?.map(([label, value]) => (
        <TextField
          key={label}
          UNSAFE_className={styles.formField}
          marginBottom="size-100"
          isDisabled
          isReadOnly
          label={<FormLabel>{label}</FormLabel>}
          value={value}
          width="100%"
        />
      ))}
      <TextField
        UNSAFE_className={styles.formField}
        marginBottom="size-100"
        isDisabled
        isReadOnly
        label={<FormLabel>Asset ID</FormLabel>}
        value={repoMetadata[RepoMetadataKey.AssetId]}
        width="100%"
      />
      <TextField
        UNSAFE_className={styles.formField}
        isDisabled
        isReadOnly
        label={<FormLabel>Asset Path</FormLabel>}
        value={repoMetadata[RepoMetadataKey.Path]}
        width="100%"
      />
    </Well>
  );
}

AssetFixedMetadataForm.defaultProps = {
  customFields: [],
};
