/**
 * *****************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2022 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Adobe and its suppliers, if any. The intellectual and technical concepts
 * contained herein are proprietary to Adobe and its suppliers and are
 * protected by all applicable intellectual property laws, including trade
 * secret and copyright laws. Dissemination of this information or reproduction
 * of this material is strictly forbidden unless prior written permission is
 * obtained from Adobe.
 * *****************************************************************************
 */

import React, { ReactElement, useCallback, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import '@spectrum-css/treeview';
import {
  ActionButton,
  Flex,
  Heading,
  ProgressCircle,
  SearchField,
  Text,
  View,
} from '@adobe/react-spectrum';
import ChevronRight from '@spectrum-icons/workflow/ChevronRight';
import FileTemplate from '@spectrum-icons/workflow/FileTemplate';
import FileTxt from '@spectrum-icons/workflow/FileTxt';
import Folder from '@spectrum-icons/workflow/Folder';
import FolderOpen from '@spectrum-icons/workflow/FolderOpen';
import FolderOpenOutline from '@spectrum-icons/workflow/FolderOpenOutline';
import { useDebounce } from 'use-debounce';

import Loading from 'components/Loading';
import ScrollPane from 'components/ScrollPane';
import useBreadthFirstDirectorySearch from 'hooks/useBreadthFirstDirectorySearch';
import useConstant from 'hooks/useConstant';
import useCurrentAssetByPath from 'hooks/useCurrentAssetByPath';
import useDirectory from 'hooks/useDirectory';
import { AssetMimeType } from 'model/acp/AssetMimeType';
import { ChildAsset } from 'model/acp/ChildAsset';
import { RepoMetadataKey } from 'model/acp/RepoMetadataKey';
import { useAppSelector } from 'redux/hooks';
import { selectIsUsingExperimentalRepository } from 'redux/user/user.slice';
import { isCollection, isDirectory } from 'utils/assets';

import styles from './TreeView.module.scss';

type TreeViewElementProps = {
  item: ChildAsset;
  searchText?: string;
  pathPrefix: string;
};

function TreeViewElement({
  item,
  searchText,
  pathPrefix,
}: TreeViewElementProps): ReactElement {
  const { acpBasePath } = useConstant();
  const { currentAssetByPath } = useCurrentAssetByPath({
    shouldIncludeRoot: pathPrefix !== '',
  });
  const isUsingExperimentalRepository = useAppSelector(
    selectIsUsingExperimentalRepository,
  );
  const basePath =
    isUsingExperimentalRepository && pathPrefix === ''
      ? acpBasePath.wolverineAssets
      : acpBasePath.assets;
  const assetPath = currentAssetByPath.replace(basePath, '');
  const pathname = `${item[RepoMetadataKey.Path].replace(basePath, '')}`;
  const [isOpen, setIsOpen] = useState<boolean>(assetPath.startsWith(pathname));
  const { directory, isLoadingMore } = useDirectory({
    path: item[RepoMetadataKey.Path],
    type: AssetMimeType.Directory,
    enabled: isOpen && !searchText,
  });
  const toggleIsOpen = useCallback(
    () => setIsOpen(!isOpen),
    [isOpen, setIsOpen],
  );
  let IconElement: (props: any) => ReactElement | null;

  if (isDirectory(item)) {
    if (isOpen && directory?.children.length === 0) {
      IconElement = FolderOpenOutline;
    } else if (
      isOpen &&
      directory !== undefined &&
      directory.children.length > 0
    ) {
      IconElement = FolderOpen;
    } else {
      IconElement = Folder;
    }
  } else if (isCollection(item)) {
    IconElement = FileTemplate;
  } else {
    IconElement = FileTxt;
  }

  useEffect(() => {
    if (assetPath.startsWith(pathname)) {
      setIsOpen(true);
    }
  }, [assetPath, pathname, setIsOpen]);

  let searchMatch: JSX.Element | undefined;

  if (searchText) {
    const searchComparisonString =
      item[RepoMetadataKey.Name].toLocaleLowerCase();
    const searchPrefix = searchComparisonString.indexOf(searchText);
    const searchPostfix = searchPrefix + searchText.length;
    const prefixText = item[RepoMetadataKey.Name].slice(0, searchPrefix);
    const matchText = item[RepoMetadataKey.Name].slice(
      searchPrefix,
      searchPostfix,
    );
    const postfixText = item[RepoMetadataKey.Name].slice(searchPostfix);

    searchMatch = (
      <>
        <Text>{prefixText}</Text>
        <Text
          UNSAFE_style={{
            fontWeight: 900,
          }}
        >
          {matchText}
        </Text>
        <Text>{postfixText}</Text>
      </>
    );
  }

  return (
    <>
      <li
        className={`spectrum-TreeView-item ${isOpen ? 'is-open' : ''} ${
          assetPath === pathname ? 'is-selected' : ''
        }`}
      >
        <span className="spectrum-TreeView-itemLink">
          {!searchText && directory?.children.length !== 0 ? (
            <ActionButton
              UNSAFE_className="spectrum-TreeView-itemIndicator"
              height="size-250"
              isQuiet
              onPress={toggleIsOpen}
            >
              <ChevronRight size="XS" />
            </ActionButton>
          ) : null}
          {isOpen && isLoadingMore ? (
            <ProgressCircle
              UNSAFE_className="spectrum-TreeView-itemIcon"
              aria-label="loading"
              isIndeterminate
              marginEnd="size-100"
              marginStart="size-50"
              marginTop="-1px"
              size="S"
            />
          ) : null}
          <Link
            className="spectrum-TreeView-itemLink-link"
            to={`${pathPrefix}${pathname}`}
            onClick={() => setIsOpen(true)}
          >
            {!isOpen || !isLoadingMore ? (
              <IconElement
                UNSAFE_className="spectrum-TreeView-itemIcon"
                size="S"
              />
            ) : null}
            <Text UNSAFE_className="spectrum-TreeView-itemLabel">
              {searchMatch ?? item[RepoMetadataKey.Name]}

              {searchText ? (
                <>
                  {' '}
                  (in {item[RepoMetadataKey.Path].split('/').slice(0, -1).pop()}
                  )
                </>
              ) : null}
            </Text>
          </Link>
        </span>
        {!searchText ? (
          <>
            {!isLoadingMore ? (
              <ul
                className={`spectrum-TreeView spectrum-TreeView--sizeM ${styles.treeView}`}
              >
                {directory?.children.map((child) => (
                  <TreeViewElement
                    key={child[RepoMetadataKey.AssetId]}
                    item={child}
                    searchText={searchText}
                    pathPrefix={pathPrefix}
                  />
                ))}
              </ul>
            ) : (
              <ul className="spectrum-TreeView spectrum-TreeView--sizeM">
                <li className="spectrum-TreeView-item is-open">
                  <View UNSAFE_className="spectrum-TreeView-itemLink">
                    <Text UNSAFE_className="spectrum-TreeView-itemLabel">
                      ...
                    </Text>
                  </View>
                </li>
              </ul>
            )}
          </>
        ) : null}
      </li>
    </>
  );
}

TreeViewElement.defaultProps = {
  searchText: undefined,
};

type DefaultTreeViewProps = {
  path: string;
  pathPrefix: string;
};

function DefaultTreeView({
  path,
  pathPrefix,
}: DefaultTreeViewProps): ReactElement {
  const { directory } = useDirectory({
    path,
    type: AssetMimeType.Directory,
  });

  return (
    <ScrollPane
      style={{
        minHeight: '0%',
        flex: 1,
      }}
    >
      <ul
        className={`spectrum-TreeView spectrum-TreeView--sizeM ${styles.treeView}`}
        style={{
          margin: '0 var(--spectrum-global-dimension-size-125)',
        }}
      >
        {directory?.children.map((item) => (
          <TreeViewElement
            key={item[RepoMetadataKey.AssetId]}
            item={item}
            pathPrefix={pathPrefix}
          />
        ))}
      </ul>
    </ScrollPane>
  );
}

type SearchTreeViewProps = {
  search: string;
  path: string;
  pathPrefix: string;
};

function SearchTreeView({
  path,
  search,
  pathPrefix,
}: SearchTreeViewProps): ReactElement {
  const [searchText] = useDebounce(search, 250);
  const { results, isSearching } = useBreadthFirstDirectorySearch({
    path,
    search: searchText,
    type: AssetMimeType.Directory,
    enabled: search.length !== 0,
  });

  return (
    <>
      <Text
        UNSAFE_style={{
          fontWeight: 900,
        }}
        marginX="size-250"
      >
        Search Results
      </Text>
      <ScrollPane
        style={{
          minHeight: '0%',
          flex: 1,
        }}
      >
        <ul
          className={`spectrum-TreeView spectrum-TreeView--sizeM ${styles.treeView}`}
          style={{
            margin: '0 var(--spectrum-global-dimension-size-125)',
          }}
        >
          {results?.map((result) => (
            <TreeViewElement
              key={result[RepoMetadataKey.AssetId]}
              item={result}
              searchText={searchText.toLocaleLowerCase()}
              pathPrefix={pathPrefix}
            />
          ))}

          {isSearching ? (
            <View marginTop="size-150">
              <Loading />
            </View>
          ) : null}

          {!isSearching && (!results || results.length === 0) ? (
            <View
              UNSAFE_className={styles.noResults}
              marginTop="size-150"
              marginX="size-125"
            >
              No results found for <strong>{searchText}</strong>
            </View>
          ) : null}
        </ul>
      </ScrollPane>
    </>
  );
}

type TreeViewProps = {
  path: string;
  isSearchable?: boolean;
  pathPrefix?: string;
  title: string;
};

export default function TreeView({
  path,
  isSearchable,
  title,
  pathPrefix = '',
}: TreeViewProps): ReactElement {
  const [searchText, setSearchText] = useState<string>('');

  return (
    <Flex direction="column" height="100%">
      <View marginTop="size-250" marginX="size-250" marginBottom="size-150">
        <Heading
          UNSAFE_style={{
            fontWeight: 900,
          }}
          level={2}
          marginY="size-0"
        >
          {title}
        </Heading>
        <Text
          UNSAFE_style={{
            display: 'block',
            fontSize: 'var(--spectrum-global-dimension-size-160)',
          }}
          marginBottom="size-125"
        >
          {path}
        </Text>
        {isSearchable ? (
          <SearchField
            UNSAFE_className={styles.search}
            aria-label="Filter asset folders"
            placeholder="Filter asset folders"
            onChange={setSearchText}
            value={searchText}
            width="100%"
          />
        ) : null}
      </View>
      {searchText.length !== 0 ? (
        <SearchTreeView
          path={path}
          search={searchText}
          pathPrefix={pathPrefix}
        />
      ) : (
        <DefaultTreeView path={path} pathPrefix={pathPrefix} />
      )}
    </Flex>
  );
}

TreeView.defaultProps = {
  isSearchable: true,
  pathPrefix: '',
};
