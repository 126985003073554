/**
 * *****************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2022 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Adobe and its suppliers, if any. The intellectual and technical concepts
 * contained herein are proprietary to Adobe and its suppliers and are
 * protected by all applicable intellectual property laws, including trade
 * secret and copyright laws. Dissemination of this information or reproduction
 * of this material is strictly forbidden unless prior written permission is
 * obtained from Adobe.
 * *****************************************************************************
 */

import React, { ReactElement } from 'react';

import { Flex, View } from '@adobe/react-spectrum';
import FileTemplate from '@spectrum-icons/workflow/FileTemplate';

export default function CollectionRendition(): ReactElement {
  return (
    <>
      <View
        UNSAFE_style={{
          borderRadius: '500px',
          transform: 'translateY(50%)',
        }}
        backgroundColor="static-white"
        padding="size-50"
        position="absolute"
        bottom="0"
        left="size-300"
      >
        <Flex
          alignItems="center"
          justifyContent="center"
          height="size-600"
          width="size-600"
        >
          <FileTemplate size="L" />
        </Flex>
      </View>
    </>
  );
}
