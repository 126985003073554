/**
 * *****************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2022 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Adobe and its suppliers, if any. The intellectual and technical concepts
 * contained herein are proprietary to Adobe and its suppliers and are
 * protected by all applicable intellectual property laws, including trade
 * secret and copyright laws. Dissemination of this information or reproduction
 * of this material is strictly forbidden unless prior written permission is
 * obtained from Adobe.
 * *****************************************************************************
 */

import React, { Key, useCallback } from 'react';

import { ActionGroup, Item, Picker, View } from '@adobe/react-spectrum';
import Close from '@spectrum-icons/workflow/Close';

import { CCXConfigOption } from 'model/ccxConfig/CCXConfig';

import { AssetMetadataFormFieldProps } from './AssetMetadataFormFieldProps';
import FormLabel from './FormLabel';

import styles from './AssetMetadataFormField.module.scss';

export default function GenericMultipickerFormField({
  label,
  isReadOnly,
  isDisabled,
  onChange,
  options,
  textLabel,
  value: values,
}: AssetMetadataFormFieldProps<
  string[] | undefined,
  CCXConfigOption<{ isExclusive?: boolean }>[]
>) {
  const handleAdd = useCallback(
    (key: Key) => {
      const newItem = options?.find((option) => option.value === key);

      if (newItem?.isExclusive) {
        return onChange([key as string]);
      }

      const nonExclusiveValues = (values ?? []).filter((value) => {
        const item = options?.find((option) => option.value === value);

        return !item?.isExclusive;
      });

      return onChange([...nonExclusiveValues, key as string]);
    },
    [onChange, options, values],
  );
  const handleRemove = useCallback(
    (key: Key) => {
      onChange(values?.filter((value) => value !== key));
    },
    [onChange, values],
  );

  return (
    <>
      {typeof label === 'string' ? (
        <View marginTop="size-250">
          <FormLabel>{label}</FormLabel>
        </View>
      ) : (
        label
      )}
      <Picker
        items={options}
        isDisabled={isReadOnly || isDisabled}
        onSelectionChange={handleAdd}
        selectedKey={null}
        width="100%"
        aria-label={textLabel ?? (typeof label === 'string' ? label : '')}
      >
        {(item) => <Item key={item.value}>{item.label}</Item>}
      </Picker>
      <ActionGroup
        items={values?.map((value) => ({
          label:
            options?.find(
              (option) =>
                option.value.toLocaleLowerCase() === value.toLocaleLowerCase(),
            )?.label ?? value,
          value,
        }))}
        isDisabled={isReadOnly || isDisabled}
        onAction={handleRemove}
        UNSAFE_className={styles.genericMultiPickerFormField}
      >
        {(item) => (
          <Item key={item.value}>
            {item.label}{' '}
            <Close size="XS" UNSAFE_style={{ marginLeft: '4px' }} />
          </Item>
        )}
      </ActionGroup>
    </>
  );
}
