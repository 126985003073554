/**
 * *****************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2022 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Adobe and its suppliers, if any. The intellectual and technical concepts
 * contained herein are proprietary to Adobe and its suppliers and are
 * protected by all applicable intellectual property laws, including trade
 * secret and copyright laws. Dissemination of this information or reproduction
 * of this material is strictly forbidden unless prior written permission is
 * obtained from Adobe.
 * *****************************************************************************
 */

import { useCallback, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { DocumentView } from 'model/DocumentView';
import { setDocumentView } from 'redux/explorer/explorer.slice';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { selectIsUsingExperimentalRepository } from 'redux/user/user.slice';

import useConstant from './useConstant';

type UseCurrentAssetByPathHookProps = {
  shouldIncludeRoot?: boolean;
};

type UseCurrentAssetByPathHookResult = {
  currentAssetByPath: string;
  setCurrentAssetByPath: (
    path: string,
    options?: { documentView: DocumentView; target?: string },
  ) => void;
};

export default function useCurrentAssetByPath({
  shouldIncludeRoot = false,
}: UseCurrentAssetByPathHookProps = {}): UseCurrentAssetByPathHookResult {
  const { acpBasePath } = useConstant();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const nonRoutePathName = decodeURIComponent(
    pathname.replace(/^\/(permissions|assets)/, ''),
  );
  const routePathIndex = pathname.match(/^\/(permissions|assets)/);
  const dispatch = useAppDispatch();
  const isUsingExperimentalRepository = useAppSelector(
    selectIsUsingExperimentalRepository,
  );
  const basePath = useMemo(
    () =>
      isUsingExperimentalRepository && !shouldIncludeRoot
        ? acpBasePath.wolverineAssets
        : acpBasePath.assets,
    [
      acpBasePath.assets,
      acpBasePath.wolverineAssets,
      isUsingExperimentalRepository,
      shouldIncludeRoot,
    ],
  );
  const setCurrentAssetByPath = useCallback(
    (
      path: string,
      options: { documentView: DocumentView; target?: string } = {
        documentView: DocumentView.Card,
        target: 'self',
      },
    ) => {
      const targetPathname = path.replace(basePath, '');
      dispatch(setDocumentView(options.documentView));

      let assetUrl = `${targetPathname
        .split('/')
        .map(encodeURIComponent)
        .join('/')}?view=${options.documentView}`;

      if (routePathIndex !== null) {
        assetUrl = `/${routePathIndex[1]}${assetUrl}`;
      }

      if (options.target === '_blank') {
        window.open(assetUrl, '_blank');
      } else if (decodeURIComponent(pathname) !== targetPathname) {
        navigate(assetUrl);
      }
    },
    // have the base path here use content/assets/content
    [basePath, dispatch, routePathIndex, pathname, navigate],
  );

  return {
    currentAssetByPath: `${basePath}${nonRoutePathName}`,
    setCurrentAssetByPath,
  };
}
