/**
 * *****************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2022 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Adobe and its suppliers, if any. The intellectual and technical concepts
 * contained herein are proprietary to Adobe and its suppliers and are
 * protected by all applicable intellectual property laws, including trade
 * secret and copyright laws. Dissemination of this information or reproduction
 * of this material is strictly forbidden unless prior written permission is
 * obtained from Adobe.
 * *****************************************************************************
 */

import { SparkSearchAggs } from '__DEPRECATED_DO_NOT_USE_OR_YOU_WILL_BE_FIRED/controllers/api/SparkSearch';

export enum TAssetKind {
  template = 'template',
  image = 'image',
  folder = 'folder',
  collection = 'collection',
}

export interface TAsset {
  [key: string]: any;
  id: string;
  kind: TAssetKind;
}

export type SortOptions = {
  orderBy: string; // Prefixed with `-` for descending
  // descending: boolean  //deprecated
};

export type AssetsData = {
  /**
   * Current list of assets we've fetched from storage.
   * You will find the following asset types in this list:
   *      - TemplateAsset
   *      - PlatformFolder
   *      - SearchAsset
   */
  current?: TAsset[];

  /**
   * Assets: Keeps track of the folder paths the user has selected
   * - @see BrowseBreadcrumbs.tsx
   * Templates: Keeps track of the paths to fetch the next page of assets
   */
  paths?: string[];

  /** Number of assets currently displayed */
  count?: number;

  /** Total number of assets that match current filters */
  total?: number;

  /** Array of selected asset ids */
  selectedAssetIds?: string[];

  /** When toggled, Grid will select all displayed assets */
  doSelectAllAssets?: boolean;

  /** Term aggregates returned from Search service responses */
  aggs?: SparkSearchAggs;

  /** Sort order for assets */
  sortOptions?: SortOptions;
};
