/**
 * *****************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2022 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Adobe and its suppliers, if any. The intellectual and technical concepts
 * contained herein are proprietary to Adobe and its suppliers and are
 * protected by all applicable intellectual property laws, including trade
 * secret and copyright laws. Dissemination of this information or reproduction
 * of this material is strictly forbidden unless prior written permission is
 * obtained from Adobe.
 * *****************************************************************************
 */

import React, {
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';

import { filterTemplates } from '__DEPRECATED_DO_NOT_USE_OR_YOU_WILL_BE_FIRED/controllers/api/SparkSearch';
import { TemplateAsset } from '__DEPRECATED_DO_NOT_USE_OR_YOU_WILL_BE_FIRED/controllers/api/SparkSearchTypes';
import SearchParamsBuilder from '__DEPRECATED_DO_NOT_USE_OR_YOU_WILL_BE_FIRED/controllers/SearchParamsBuilder/SearchParamsBuilder';
import useShadowAsset from '__DEPRECATED_DO_NOT_USE_OR_YOU_WILL_BE_FIRED/hooks/useShadowAsset';
import { TAssetKind } from '__DEPRECATED_DO_NOT_USE_OR_YOU_WILL_BE_FIRED/redux/assets/asset.types';
import { FilterTab } from '__DEPRECATED_DO_NOT_USE_OR_YOU_WILL_BE_FIRED/redux/filter/filter.slice';
import {
  clearDirtyAssetProps,
  setAsset,
} from '__DEPRECATED_DO_NOT_USE_OR_YOU_WILL_BE_FIRED/redux/metadata/metadata.slice';
import { alertUnsavedMetadataChanges } from '__DEPRECATED_DO_NOT_USE_OR_YOU_WILL_BE_FIRED/redux/metadata/metadata.thunks';

import useAdobeIMS from 'hooks/ims/useAdobeIMS';
import { selectTemplateDetailRailState } from 'redux/__DEPRECATED_templates/templates.slice';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { ScreenView, setDetailViewId } from 'redux/screen/screen.slice';

import LimitedTemplatesBrowse from './LimitedTemplatesBrowse';
import LoadingBrowse from './LoadingBrowse';
import NoAssetsBrowse from './NoAssetsBrowse';
import TemplatesBrowseWithComments from './TemplatesBrowseWithComments';

type TemplatesBrowseProps = {
  isCurator: boolean;
  onViewButtonClick: (screen: ScreenView) => void;
  tab: FilterTab;
};

export default function TemplatesBrowse({
  isCurator,
  onViewButtonClick,
  tab,
}: TemplatesBrowseProps): ReactElement {
  // Global hooks
  const dispatch = useAppDispatch();
  const adobeIMS = useAdobeIMS();
  const profile = useAppSelector((state) => state.user.profile);
  // Asset hooks
  const assets: TemplateAsset[] = useAppSelector(
    (state) => state.assets.current as TemplateAsset[],
  );
  const assetList = useMemo(() => {
    let filteredAssetList = assets;

    if (
      filteredAssetList.length &&
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      filteredAssetList[0].kind !== TAssetKind.template
    ) {
      /*
       * when clicking a button to switch views sometimes we get one with the stale assets
       * and then it updates right away, so avoid case where assets are DesignAssets, to avoid
       * error. TBD: is there a way around that?
       */
      return null;
    }

    // show empty state
    if (filteredAssetList.length === 0) {
      return [];
    }

    if (tab === FilterTab.review) {
      // filter unapproved assets
      const filtered = dispatch(
        filterTemplates(filteredAssetList, SearchParamsBuilder.templates),
      );

      filteredAssetList = filtered.assets;
    }

    return filteredAssetList;
  }, [assets, tab, dispatch]);
  // Browsing hooks
  const templateDetailRailState = useAppSelector(selectTemplateDetailRailState);
  const [browseIndex, setBrowseIndex] = useState(0);
  const detailViewId = useAppSelector((state) => state.screen.detailViewId);
  const showAssetAtIndex = useCallback(
    (newIndex: number) => {
      if (!assetList) {
        return;
      }

      if (newIndex < 0) {
        newIndex = assetList.length - 1;
      } else if (newIndex >= assetList.length) {
        newIndex = 0;
      }

      dispatch(setDetailViewId(assetList[newIndex].id));
    },
    [assetList, dispatch],
  );
  const doBrowse = useCallback(
    (newIndex: number) => {
      // check for unsaved changes before moving to next or prev asset
      dispatch(
        alertUnsavedMetadataChanges(tab, () => showAssetAtIndex(newIndex)),
      );
    },
    [dispatch, showAssetAtIndex, tab],
  );

  useEffect(() => {
    const index = assetList?.findIndex((asset) => asset.id === detailViewId);

    if (index !== undefined && index !== -1) {
      setBrowseIndex(index);
    }

    return () => {
      setDetailViewId('');
    };
  }, [detailViewId, assetList]);

  // Load states
  const isLoadingAssets = assetList === null;
  const hasAssets = !isLoadingAssets && assetList?.length !== 0;
  const currentAsset = assetList?.[browseIndex];
  // Shadow assets
  const { result: shadowAssetId } = useShadowAsset({
    currentAssetId: currentAsset?.id ?? '',
    options: {
      enabled: currentAsset !== undefined,
    },
  });
  const currentAssetInMetadata = useAppSelector(
    (state) => state.metadata.asset,
  );

  useEffect(() => {
    if (currentAsset) {
      dispatch(setAsset(currentAsset));
      dispatch(clearDirtyAssetProps());
    }
  }, [currentAsset, dispatch, templateDetailRailState]);

  if (isLoadingAssets || adobeIMS === undefined) {
    return (
      <LoadingBrowse
        onBrowseNext={() => doBrowse(browseIndex + 1)}
        onBrowsePrevious={() => doBrowse(browseIndex - 1)}
        onViewButtonClick={onViewButtonClick}
      />
    );
  }

  if (!hasAssets || currentAsset === undefined) {
    return <NoAssetsBrowse />;
  }

  const token = adobeIMS.getAccessToken()?.token;

  if (
    shadowAssetId === undefined ||
    profile === undefined ||
    token === undefined
  ) {
    return (
      <LimitedTemplatesBrowse
        currentAsset={currentAsset}
        currentAssetInMetadata={currentAssetInMetadata}
        isCurator={isCurator}
        onBrowseNext={() => doBrowse(browseIndex + 1)}
        onBrowsePrevious={() => doBrowse(browseIndex - 1)}
        onViewButtonClick={onViewButtonClick}
        tab={tab}
      />
    );
  }

  return (
    <TemplatesBrowseWithComments
      key={currentAsset.id}
      currentAsset={currentAsset}
      currentAssetInMetadata={currentAssetInMetadata}
      isCurator={isCurator}
      onBrowseNext={() => doBrowse(browseIndex + 1)}
      onBrowsePrevious={() => doBrowse(browseIndex - 1)}
      onViewButtonClick={onViewButtonClick}
      shadowAssetId={shadowAssetId}
      tab={tab}
      profileId={profile.userId}
      token={token}
    />
  );
}
