/**
 * *****************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2022 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Adobe and its suppliers, if any. The intellectual and technical concepts
 * contained herein are proprietary to Adobe and its suppliers and are
 * protected by all applicable intellectual property laws, including trade
 * secret and copyright laws. Dissemination of this information or reproduction
 * of this material is strictly forbidden unless prior written permission is
 * obtained from Adobe.
 * *****************************************************************************
 */

import React, {
  ReactElement,
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import IndexPath from '@react/collection-view/src/IndexPath';
import Selection from '@react/collection-view/src/Selection';
import { Card } from '@react/react-spectrum/Card';
import { GridView } from '@react/react-spectrum/GridView';
import SentimentNeutral from '@react/react-spectrum/Icon/SentimentNeutral';
import IllustratedMessage from '@react/react-spectrum/IllustratedMessage';
import Wait from '@react/react-spectrum/Wait';

import SparkSearch from '__DEPRECATED_DO_NOT_USE_OR_YOU_WILL_BE_FIRED/controllers/api/SparkSearch';
import {
  addAssetsPath,
  resetAssets,
  setSelectedAssetIds,
  updateAssetsData,
} from '__DEPRECATED_DO_NOT_USE_OR_YOU_WILL_BE_FIRED/redux/assets/asset.slice';
import {
  AssetsData,
  TAsset,
} from '__DEPRECATED_DO_NOT_USE_OR_YOU_WILL_BE_FIRED/redux/assets/asset.types';
import {
  ContentType,
  FilterTab,
} from '__DEPRECATED_DO_NOT_USE_OR_YOU_WILL_BE_FIRED/redux/filter/filter.slice';

import { useCCXPublicConfig } from 'hooks/useCCXConfig';
import useEffectDidUpdate from 'hooks/useEffectDidUpdate';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { doRefresh } from 'redux/screen/screen.slice';
import { openDetailView } from 'redux/screen/screen.thunks';
import { selectOwnersMap } from 'redux/tempo/tempo.slice';

import GridDataSource from '../datasources/GridDataSource';
import GridRegistry from '../model/GridRegistry';
import { ReduxStoreDispatchProps } from '../model/types';

import styles from '../styles/Grid.module.scss';

/**
 * Returns the width of a single grid item depending on window.innerWidth
 * and window.innerHeight
 *
 * This is required to fetch the rendition url from CP for each asset.
 * The rendition url template requires the format, dimension, and size in pixels
 * @see {@link SparkSearch~getFixedWidthRendition}
 */
function getGridItemWidth() {
  const gridItemWidths = {
    minWidth: 190,
    maxWidth: 220,
  };

  return window.innerWidth <= 1212 || window.innerHeight < 800
    ? gridItemWidths.minWidth
    : gridItemWidths.maxWidth;
}

type GridProps = {
  tab: FilterTab;
  contentType: ContentType;
};

export default function SearchGrid({
  tab,
  contentType,
}: GridProps): ReactElement {
  // Global state
  const { results: ccxPublicConfig } = useCCXPublicConfig();
  // Redux state
  const assets = useAppSelector((state) => state.assets.current);
  const filters = useAppSelector((state) => state.filters);
  const selectedAssetIds = useAppSelector(
    (state) => state.assets.selectedAssetIds,
  );
  const selectionEnabled = useAppSelector(
    (state) => state.screen.selectionEnabled,
  );
  const doSelectAllAssets = useAppSelector(
    (state) => state.assets.doSelectAllAssets,
  );
  const ownersMap = useAppSelector(selectOwnersMap);
  // Redux actions
  const dispatch = useAppDispatch();
  const dispatchAddAssetsPath = useCallback(
    (assetPath: string) => dispatch(addAssetsPath(assetPath)),
    [dispatch],
  );
  const dispatchDoRefresh = useCallback(
    () => dispatch(doRefresh()),
    [dispatch],
  );
  const dispatchFetchAssets = useCallback(
    (
      filterTab: FilterTab,
      options: { forceFetch: boolean; contentType?: ContentType },
    ) => dispatch(SparkSearch.instance.fetchAssets(filterTab, options)),
    [dispatch],
  );
  const dispatchFetchNextAssets = useCallback(
    (filterTab: FilterTab) =>
      dispatch(SparkSearch.instance.fetchNextAssets(filterTab)),
    [dispatch],
  );
  const dispatchOpenDetailView = useCallback(
    (assetId: string) => dispatch(openDetailView(assetId)),
    [dispatch],
  );
  const dispatchResetAssets = useCallback(
    () => dispatch(resetAssets()),
    [dispatch],
  );
  const dispatchSetSelectedAssetIds = useCallback(
    (assetIds) => dispatch(setSelectedAssetIds(assetIds)),
    [dispatch],
  );
  const dispatchUpdateAssetsData = useCallback(
    (data: AssetsData) => dispatch(updateAssetsData(data)),
    [dispatch],
  );
  const actions: ReduxStoreDispatchProps = useMemo(
    () => ({
      addAssetsPath: dispatchAddAssetsPath,
      doRefresh: dispatchDoRefresh,
      fetchAssets: dispatchFetchAssets,
      fetchNextAssets: dispatchFetchNextAssets,
      openDetailView: dispatchOpenDetailView,
      resetAssets: dispatchResetAssets,
      setSelectedAssetIds: dispatchSetSelectedAssetIds,
      updateAssetsData: dispatchUpdateAssetsData,
    }),
    [
      dispatchAddAssetsPath,
      dispatchDoRefresh,
      dispatchFetchAssets,
      dispatchFetchNextAssets,
      dispatchOpenDetailView,
      dispatchResetAssets,
      dispatchSetSelectedAssetIds,
      dispatchUpdateAssetsData,
    ],
  );
  // State
  const isFirstRender = useRef<boolean>(true);
  const [dataSource] = useState<GridDataSource>(
    new GridDataSource(tab, contentType, actions),
  );
  const [selectedIndexPaths, setSelectedIndexPaths] = useState<
    typeof IndexPath[]
  >([]);
  const [gridItemWidth, setGridItemWidth] = useState<number>(
    getGridItemWidth(),
  );
  const [isLoadingNext, setIsLoadingNext] = useState<boolean>(false);
  // Actions
  const onResize = useCallback(() => {
    setGridItemWidth(getGridItemWidth());
  }, [setGridItemWidth]);
  const onSelectionChange = useCallback(
    (selection: typeof Selection) => {
      const selectedIndexes: number[] = [];
      const selectedAssetsIds: string[] = [];

      for (const indexPath of selection.reverseIterator) {
        selectedIndexes.push(indexPath);

        const asset = dataSource.getItem(indexPath.section, indexPath.index);

        if (asset) {
          selectedAssetsIds.push(asset.id);
        }
      }

      setSelectedAssetIds(selectedAssetsIds);
      setSelectedIndexPaths(selectedIndexes);
      dispatchSetSelectedAssetIds(selectedAssetsIds);
    },
    [dataSource, setSelectedIndexPaths, dispatchSetSelectedAssetIds],
  );
  const onViewLess = useCallback(() => {
    dataSource.loadPrev();
  }, [dataSource]);
  const onViewMore = useCallback(async () => {
    setIsLoadingNext(true);

    await dataSource.loadNext();

    setIsLoadingNext(false);
  }, [dataSource, setIsLoadingNext]);

  useEffect(() => {
    window.addEventListener('resize', onResize);

    return () => {
      dataSource.remove();
      window.removeEventListener('resize', onResize);
    };
  }, [dataSource, onResize]);

  useEffectDidUpdate(() => {
    const currentAssets = dataSource.getItemsInSection(0) || [];

    /*
     * setting the asset ids in the global state will re-render
     * this Grid component as well
     */
    actions.setSelectedAssetIds(currentAssets.map((asset: TAsset) => asset.id));
  }, [doSelectAllAssets]);

  useEffectDidUpdate(() => {
    setSelectedIndexPaths(dataSource.getAssetIndexPaths(selectedAssetIds));
  }, [dataSource, selectedAssetIds]);

  useEffectDidUpdate(() => {
    dataSource.reloadData();
  }, [gridItemWidth]);

  useEffectDidUpdate(() => {
    dataSource.reset(tab, contentType, actions);
  }, [dataSource, tab, contentType]);

  useEffectDidUpdate(() => {
    dataSource.updateFilters();
  }, [dataSource, filters]);

  useEffectDidUpdate(() => {
    dataSource.updateAssets(assets);
  }, [dataSource, assets]);

  useLayoutEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
    }

    return () => {
      isFirstRender.current = true;
    };
  }, [isFirstRender]);

  const taskList = ccxPublicConfig?.template.tasks.options.map(
    (option) => option.value,
  );
  const renderItem = useCallback(
    (asset: TAsset) => {
      const GridItem = GridRegistry.GridItem(asset);

      return (
        <Card variant="quiet" style={{ minWidth: gridItemWidth }}>
          <GridItem
            isLegacyItem={
              taskList &&
              asset.tasks &&
              asset.tasks.some((task: string) => !taskList.includes(task))
            }
            tab={tab}
            asset={asset}
            ownersMap={ownersMap}
            width={gridItemWidth}
            actions={actions}
            selectionEnabled={selectionEnabled}
          />
        </Card>
      );
    },
    [actions, gridItemWidth, ownersMap, selectionEnabled, tab, taskList],
  );
  const renderEmptyView = useCallback(
    () => (
      <div className={styles.templatesGridEmpty}>
        <IllustratedMessage
          heading="No Results"
          description="Try a different search"
          illustration={<SentimentNeutral size="XL" />}
        />
      </div>
    ),
    [],
  );

  return (
    <div
      className={
        tab === 'review' ? styles.templatesGrid : styles.templatesGridInifinte
      }
    >
      <GridView
        className={styles.grid}
        layout={GridRegistry.Layout(contentType)}
        renderItem={renderItem}
        renderEmptyView={renderEmptyView}
        dataSource={dataSource}
        allowsSelection={selectionEnabled}
        allowsMultipleSelection
        selectedIndexPaths={selectionEnabled ? selectedIndexPaths : []}
        onSelectionChange={onSelectionChange}
        cardSize="L"
      />
      <div className={styles.pagination}>
        <div className={styles.actions}>
          {isLoadingNext ? <Wait className={styles.loading} size="S" /> : null}
          {tab === 'review' ? (
            <>
              {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions */}
              <p onClick={onViewMore}>View More</p>
              <p>/</p>
              {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions */}
              <p onClick={onViewLess}>View Less</p>
            </>
          ) : null}
        </div>
      </div>
    </div>
  );
}
