/**
 * *****************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2022 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Adobe and its suppliers, if any. The intellectual and technical concepts
 * contained herein are proprietary to Adobe and its suppliers and are
 * protected by all applicable intellectual property laws, including trade
 * secret and copyright laws. Dissemination of this information or reproduction
 * of this material is strictly forbidden unless prior written permission is
 * obtained from Adobe.
 * *****************************************************************************
 */

import React, { ReactChild, ReactElement, useEffect, useState } from 'react';

import styles from './Header.module.scss';

type HeaderPopoverMenuProps = {
  badge: string | number | undefined;
  children: ReactChild | ReactChild[];
  hasError: boolean;
  icon: JSX.Element;
  isOpen: boolean;
  onToggle: () => void;
};

export default function HeaderPopoverMenu({
  badge,
  children,
  hasError,
  icon,
  isOpen,
  onToggle,
}: HeaderPopoverMenuProps): ReactElement {
  const [shouldPulseBadge, setShouldPulseBadge] = useState<boolean>(false);

  useEffect((): void => {
    if (!shouldPulseBadge) {
      setShouldPulseBadge(true);

      setTimeout(() => setShouldPulseBadge(false), 450);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [badge]);

  return (
    <div className={styles.popoverMenu}>
      <button
        className={`spectrum-Tool ${isOpen ? styles.open : ''}`}
        type="button"
        onClick={onToggle}
      >
        {icon}
        {badge === 0 || badge === undefined ? null : (
          <div
            className={`${styles.badge} ${
              shouldPulseBadge ? styles.pulse : ''
            } ${hasError ? styles.error : ''}`}
          >
            {badge}
          </div>
        )}
      </button>
      {isOpen ? children : null}
    </div>
  );
}
