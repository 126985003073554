/**
 * *****************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2022 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Adobe and its suppliers, if any. The intellectual and technical concepts
 * contained herein are proprietary to Adobe and its suppliers and are
 * protected by all applicable intellectual property laws, including trade
 * secret and copyright laws. Dissemination of this information or reproduction
 * of this material is strictly forbidden unless prior written permission is
 * obtained from Adobe.
 * *****************************************************************************
 */

import { useEffect, useMemo, useState } from 'react';
import { useQueries, UseQueryResult } from 'react-query';

import Axios, { AxiosResponse } from 'axios';
import { first } from 'lodash';

import useACPLink, { ACPError } from 'hooks/acp/useACPLink';
import { ACPLink } from 'model/acp/ACPLink';
import RepoMetadata from 'model/acp/RepoMetadata';

type SingleRepoMetadataParams = {
  path: string;
  paths?: never;
  enabled?: boolean;
  options?: { retries: number | boolean };
};
type SingleRepoMetadataHookResult = {
  results: RepoMetadata | undefined;
  isLoading: boolean;
  errors: ACPError[] | undefined;
};

type BatchRepoMetadataParams = {
  paths: string[];
  path?: never;
  enabled?: boolean;
  options?: { retries: number | boolean };
};
type BatchRepoMetadataHookResult = {
  errors: ACPError[] | undefined;
  results: Record<string, RepoMetadata | undefined> | undefined;
  isLoading: boolean;
};

function useRepoMetadata(
  args: SingleRepoMetadataParams,
): SingleRepoMetadataHookResult;
function useRepoMetadata(
  args: BatchRepoMetadataParams,
): BatchRepoMetadataHookResult;
function useRepoMetadata(
  args: SingleRepoMetadataParams | BatchRepoMetadataParams,
): SingleRepoMetadataHookResult | BatchRepoMetadataHookResult;
function useRepoMetadata({
  path: repoPath,
  paths: repoPaths,
  enabled = true,
  options,
}: SingleRepoMetadataParams | BatchRepoMetadataParams):
  | SingleRepoMetadataHookResult
  | BatchRepoMetadataHookResult {
  const [results, setResults] = useState<
    SingleRepoMetadataHookResult | BatchRepoMetadataHookResult
  >({
    results: undefined,
    isLoading: true,
    errors: undefined,
  });
  const queryPaths: string[] = useMemo(() => {
    let query: string[] = [];

    if (repoPaths !== undefined) {
      query = repoPaths;
    } else if (repoPath !== undefined) {
      query = [repoPath];
    }

    query = query.filter((queryPath) => queryPath !== '');

    return query;
  }, [repoPath, repoPaths]);
  const {
    results: linkUrls,
    isLoading: isACPLinkLoading,
    errors,
  } = useACPLink({
    acpLink: ACPLink.RepoMetadata,
    paths: queryPaths,
    enabled,
    options,
  });
  const queries = useQueries(
    queryPaths.map((path) => {
      const linkUrl = linkUrls?.[path]?.linkUrl ?? '';
      const query = async (): Promise<AxiosResponse<RepoMetadata>> =>
        Axios.get<RepoMetadata>(linkUrl);

      return {
        queryKey: ['repoMetadata', path],
        queryFn: query,
        enabled: enabled && !!linkUrl,
        refetchOnWindowFocus: false,
      };
    }),
  );
  const hasErrors = queries.some((query) => query.error !== null);
  const isLoading = queries.some((query) => query.isFetching);

  useEffect(() => {
    if (queries.length === 0) {
      setResults({
        errors,
        isLoading: false,
        results: undefined,
      });
    } else if (repoPath !== undefined) {
      const singleQuery = first(queries) as UseQueryResult<
        AxiosResponse<RepoMetadata>
      >;

      setResults({
        errors,
        isLoading: singleQuery.isFetching,
        results: singleQuery.data?.data,
      });
    } else {
      setResults({
        errors,
        isLoading:
          isACPLinkLoading || queries.some((query) => query.isFetching),
        results: queries.reduce((acc, query, index) => {
          if (!query.data) {
            return acc;
          }

          const response = query.data as AxiosResponse<RepoMetadata>;
          const path = queryPaths[index];

          return {
            ...acc,
            [path]: response.data,
          };
        }, {}),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errors, repoPath, repoPaths, linkUrls, hasErrors, isLoading]);

  return results;
}

export default useRepoMetadata;
