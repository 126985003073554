/**
 * *****************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2022 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Adobe and its suppliers, if any. The intellectual and technical concepts
 * contained herein are proprietary to Adobe and its suppliers and are
 * protected by all applicable intellectual property laws, including trade
 * secret and copyright laws. Dissemination of this information or reproduction
 * of this material is strictly forbidden unless prior written permission is
 * obtained from Adobe.
 * *****************************************************************************
 */

import React, { ReactElement, useContext, useMemo } from 'react';

import { Flex, View, Well } from '@adobe/react-spectrum';

import { MetadataFormContext } from 'components/AssetExplorer/AssetExplorer';
import AssetRendition from 'components/AssetRendition';
import ColorBar from 'components/ColorBar';
import useApplicationMetadata from 'hooks/acp/useApplicationMetadata';
import useRepoMetadata from 'hooks/acp/useRepoMetadata';
import useCurrentAssetByPath from 'hooks/useCurrentAssetByPath';
import { AssetMimeType } from 'model/acp/AssetMimeType';
import { RepoMetadataKey } from 'model/acp/RepoMetadataKey';

import AssetInfo from './AssetInfo';

const RENDITION_SIZE = 480;
const LARGE_RENDITION_SIZE = 900;

export default function FileDetailView(): ReactElement {
  // Load external configurations and path information
  const { currentAssetByPath } = useCurrentAssetByPath();
  const { results: applicationMetadata } = useApplicationMetadata({
    path: currentAssetByPath,
  });
  const { results: repoMetadata } = useRepoMetadata({
    path: currentAssetByPath,
  });
  const isMetadataFormCollapsed = useContext(MetadataFormContext);
  const extractedColors = useMemo(
    () => applicationMetadata?.extractedColors ?? [],
    [applicationMetadata],
  );
  const hasHeroRendition =
    repoMetadata?.[RepoMetadataKey.DCFormat] === AssetMimeType.Canvas;

  return (
    <View width="100%">
      <Flex
        direction="column"
        flex
        top="100px"
        UNSAFE_style={{
          paddingTop: '10px',
        }}
      >
        <>
          <AssetInfo />
          <Well
            UNSAFE_style={{
              overflowY: 'scroll',
            }}
            marginTop="size-0"
            marginBottom="size-200"
          >
            <Flex alignItems="center" justifyContent="center">
              <AssetRendition
                isHero={hasHeroRendition}
                path={currentAssetByPath}
                size={
                  isMetadataFormCollapsed
                    ? LARGE_RENDITION_SIZE
                    : RENDITION_SIZE
                }
              />
            </Flex>
          </Well>
          {extractedColors.length ? (
            <ColorBar colors={extractedColors} />
          ) : null}
        </>
      </Flex>
    </View>
  );
}
