/**
 * *****************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2022 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Adobe and its suppliers, if any. The intellectual and technical concepts
 * contained herein are proprietary to Adobe and its suppliers and are
 * protected by all applicable intellectual property laws, including trade
 * secret and copyright laws. Dissemination of this information or reproduction
 * of this material is strictly forbidden unless prior written permission is
 * obtained from Adobe.
 * *****************************************************************************
 */

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { SourceRepository } from 'model/constant/ACPRepositoryId';
import Constant from 'model/constant/Constant';
import { AdobeIMSProfile } from 'model/ims/AdobeIMSProfile';
import { ZoomLevel } from 'model/ZoomLevel';
import { AppThunk, RootState } from 'redux/store';

const { acpRepositoryId, imsEnvironment } = new Constant();
const USER_PREFERENCES_KEY = 'tempo:preferences';

export enum CPUserRole {
  curator = 'curator',
  public = 'public',
}

export enum ACPUserRole {
  admin = 'admin',
  member = 'member',
}

export interface UserState {
  profile: AdobeIMSProfile | undefined;
  cpRole: CPUserRole;
  acpRole: ACPUserRole;
  preferences: {
    repository: SourceRepository;
    zoom: ZoomLevel;
  };
}

export const initialState: UserState = {
  profile: undefined,
  cpRole: CPUserRole.public,
  acpRole: ACPUserRole.member,
  preferences: {
    repository: SourceRepository.CCX,
    zoom: ZoomLevel.Large,
  },
};

const savePreferencesToStorage = (state: UserState) => {
  const currentPreferences = state.preferences;
  window.localStorage.setItem(
    USER_PREFERENCES_KEY,
    JSON.stringify(currentPreferences),
  );
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setCPUserRole: (state, action: PayloadAction<CPUserRole>) => {
      state.cpRole = action.payload;
    },
    setACPUserRole: (state, action: PayloadAction<ACPUserRole>) => {
      state.acpRole = action.payload;
    },
    userSignIn: (state, action: PayloadAction<AdobeIMSProfile>) => {
      state.profile = action.payload;
    },
    userSignOut: (state) => {
      state.profile = undefined;
    },
    setUserZoom: (state, action: PayloadAction<ZoomLevel>) => {
      state.preferences.zoom = action.payload;

      savePreferencesToStorage(state);
    },
  },
});

export const {
  setCPUserRole,
  setACPUserRole,
  setUserZoom,
  userSignIn,
  userSignOut,
} = userSlice.actions;

export const selectIsAuthenticated = (state: RootState) =>
  state.user.profile !== undefined;
export const selectIsCurrentUserAdmin = (state: RootState) =>
  state.user.acpRole === ACPUserRole.admin;
export const selectIsUsingExperimentalRepository = () => false;
export const selectUserPreferences = (state: RootState) =>
  state.user.preferences;

export const selectACPRepoId = (state: RootState) =>
  acpRepositoryId[state.user.preferences.repository][imsEnvironment];

export const loadPreferencesFromStorage = (): AppThunk => async (dispatch) => {
  const possiblePreferences = window.localStorage.getItem(USER_PREFERENCES_KEY);

  if (possiblePreferences === null) {
    return;
  }

  let preferences;

  try {
    preferences = JSON.parse(possiblePreferences);
  } catch (error) {
    return;
  }

  dispatch(setUserZoom(preferences.zoom ?? ZoomLevel.Large));
};

export default userSlice.reducer;
