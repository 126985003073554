/**
 * *****************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2022 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Adobe and its suppliers, if any. The intellectual and technical concepts
 * contained herein are proprietary to Adobe and its suppliers and are
 * protected by all applicable intellectual property laws, including trade
 * secret and copyright laws. Dissemination of this information or reproduction
 * of this material is strictly forbidden unless prior written permission is
 * obtained from Adobe.
 * *****************************************************************************
 */

import React from 'react';

import { Flex, Heading, TextField, View } from '@adobe/react-spectrum';

import { AssetMetadataFormFieldProps } from './AssetMetadataFormFieldProps';
import FormLabel from './FormLabel';
import { HelpText } from './HelpText';

import styles from './AssetMetadataFormField.module.scss';

export default function CollectionNameFormField({
  error,
  isDisabled,
  isReadOnly,
  onChange,
  value,
}: AssetMetadataFormFieldProps<string>) {
  return (
    <View marginBottom="size-100">
      <Flex>
        <TextField
          UNSAFE_className={styles.formField}
          isDisabled={isDisabled}
          isReadOnly={isReadOnly}
          label={<FormLabel>Collection Name</FormLabel>}
          onChange={onChange}
          validationState={error ? 'invalid' : undefined}
          value={value}
          width="100%"
        />
        <Heading level={3} marginStart="size-75" marginTop="size-350">
          .collection
        </Heading>
      </Flex>
      {error && <HelpText>{error}</HelpText>}
    </View>
  );
}
