/**
 * *****************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2022 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Adobe and its suppliers, if any. The intellectual and technical concepts
 * contained herein are proprietary to Adobe and its suppliers and are
 * protected by all applicable intellectual property laws, including trade
 * secret and copyright laws. Dissemination of this information or reproduction
 * of this material is strictly forbidden unless prior written permission is
 * obtained from Adobe.
 * *****************************************************************************
 */

import React, { useCallback, useMemo } from 'react';

import { Asset } from '@react/react-spectrum/Asset';
import { Card, CardPreview } from '@react/react-spectrum/Card';
import { error as errorToast } from '@react/react-spectrum/Toast';
import Axios from 'axios';
import _ from 'lodash';

import { getFixedWidthRendition } from '__DEPRECATED_DO_NOT_USE_OR_YOU_WILL_BE_FIRED/controllers/api/SparkSearch';
import { DesignAsset } from '__DEPRECATED_DO_NOT_USE_OR_YOU_WILL_BE_FIRED/controllers/api/SparkSearchTypes';
import AssetUtils from '__DEPRECATED_DO_NOT_USE_OR_YOU_WILL_BE_FIRED/controllers/constants/AssetUtils';
import { GridRegistry } from '__DEPRECATED_DO_NOT_USE_OR_YOU_WILL_BE_FIRED/Grid';
import {
  MetadataLabelValueMap,
  MetadataOptions,
} from '__DEPRECATED_DO_NOT_USE_OR_YOU_WILL_BE_FIRED/redux/metadata/metadata.types';

import { useCCXPublicConfig } from 'hooks/useCCXConfig';
import useConstant from 'hooks/useConstant';
import useCurrentAssetByPath from 'hooks/useCurrentAssetByPath';
import { DocumentView } from 'model/DocumentView';

import Form, { EditableField } from '../Form/Form';

import styles from '../../styles/Detail.module.scss';

type DesignAssetDetailProps = {
  asset: DesignAsset;
};

enum DesignAssetMetadataKey {
  Animated = 'animated',
  ApplicableRegions = 'applicableRegions',
  DCSubject = 'dc:subject',
  DCTitle = 'dc:title',
  Hero = 'hero',
  Language = 'language',
  LicensingCategory = 'licensingCategory',
  Name = 'name',
  Priority = 'priority',
  Title = 'title',
}

const DesignAssetDetail: React.FC<DesignAssetDetailProps> = ({
  asset,
}: DesignAssetDetailProps) => {
  // Hooks
  const { results: ccxPublicConfig } = useCCXPublicConfig();
  const { acpBasePath } = useConstant();
  const { setCurrentAssetByPath } = useCurrentAssetByPath();
  // Render methods
  const titleLang = 'en-US';
  const getRendition = () => {
    const width = 300;
    const { href } = getFixedWidthRendition(asset, width, 'png');

    return (
      <Card variant="quiet" className={styles.image}>
        <CardPreview>
          <Asset
            type={GridRegistry.AssetResourceType(asset)}
            src={href}
            headers={{
              Authorization: Axios.defaults.headers.common.Authorization,
              'x-api-key': Axios.defaults.headers.common['x-api-key'],
            }}
            alt={asset.name}
          />
        </CardPreview>
      </Card>
    );
  };
  const titleOrName = useMemo(() => {
    const title = asset[DesignAssetMetadataKey.Title];

    if (title && !_.isEmpty(title)) {
      if (title[titleLang]) {
        return title[titleLang];
      }

      const firstKey = Object.keys(title).find((key) => key);

      if (firstKey) {
        return title[firstKey];
      }
    }

    return `(Name: ${asset[DesignAssetMetadataKey.Name]})`;
  }, [asset]);
  const titleOptions = useMemo(() => {
    const title = asset[DesignAssetMetadataKey.Title];

    if (!title) {
      return []; // currently there are some records with not title field at all...
    }

    const map: MetadataLabelValueMap = Object.keys(
      title,
    ).reduce<MetadataLabelValueMap>((memo, key) => {
      const label: string = `${title[key]} (${key})`;
      memo[label] = key;

      return memo;
    }, {});

    return MetadataOptions.getMetadataOptionsFromValueMap(map, true);
  }, [asset]);
  const showErrorToast = (message: string) => {
    errorToast(message, { closable: true });
  };
  const assetLinkClick = useCallback(async () => {
    try {
      setCurrentAssetByPath(
        asset.path.replace(acpBasePath.publish, acpBasePath.author),
        {
          documentView: DocumentView.Detail,
          target: '_blank',
        },
      );
    } catch (error) {
      showErrorToast((error as Error).message);
    }
  }, [acpBasePath, asset, setCurrentAssetByPath]);
  const getForm = () => {
    const assetLink = asset.id; // if the user clicks, we then query the applicationmetadata to get
    // the actual asset id. No need to do it beforehand for every asset.
    const assetMetadata = {
      ...asset,
      [DesignAssetMetadataKey.Title]: titleLang,
    };
    const infoFields = [
      {
        label: 'ID',
        value: asset.id,
      },
      {
        label: 'Path',
        value: asset.path,
      },
      {
        label: 'Author Asset',
        value: assetLink, // tbd: what to show here, since we don't know the actual urn until they click
        link: assetLink,
        onClick: () => assetLinkClick(),
      },
      {
        label: 'Title',
        value: titleOrName || 'No Title',
      },
      {
        label: 'Size (WxH)',
        value: AssetUtils.getSizeForDisplay(asset),
        hideIfEmpty: true,
      },
      {
        label: 'Priority',
        value: asset[DesignAssetMetadataKey.Priority] || 'NONE',
      },
    ];
    const editable: EditableField[] = [
      {
        label: 'Title Translations',
        propPath: [DesignAssetMetadataKey.Title],
        placeholder: 'No Title',
        element: 'select',
        config: {
          options: titleOptions,
        },
      },
      {
        label: 'Language',
        propPath: [DesignAssetMetadataKey.Language],
        element: 'select',
        config: {
          options: ccxPublicConfig
            ? ccxPublicConfig.common.locales.options
            : null,
        },
      },
      {
        label: 'Topics',
        propPath: ['topics'],
        element: 'tags-input',
        config: {},
      },
    ];

    if (!AssetUtils.isFolderOrCollection(asset)) {
      infoFields.push({
        label: 'Entitlement',
        value: asset[DesignAssetMetadataKey.LicensingCategory],
      });
      editable.push({
        label: 'Animated',
        propPath: [DesignAssetMetadataKey.Animated],
        element: 'checkbox',
      });
      editable.push({
        label: 'Hero',
        propPath: [DesignAssetMetadataKey.Hero],
        element: 'checkbox',
      });
    }

    return (
      <Form metadata={assetMetadata} info={infoFields} editable={editable} />
    );
  };

  return (
    <div className={`${styles.detailContainer} ${styles[asset.kind]}`}>
      <div className={styles.status}>
        {/*
                    <Actions
                        getStatus={
                            (forceFetch: boolean) => AssetDetail.getStatus(forceFetch)
                        }
                        readonly={AssetDetail.access === DetailAccess.read}
                        items={[
                            {
                                label: 'Publish',
                                onClick: async () => await AssetDetail.publish()
                            }
                        ]}
                    /> */}
      </div>
      <div className={styles.rendition}>{getRendition()}</div>
      {getForm()}
    </div>
  );
};

export default DesignAssetDetail;
