/**
 * *****************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2022 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Adobe and its suppliers, if any. The intellectual and technical concepts
 * contained herein are proprietary to Adobe and its suppliers and are
 * protected by all applicable intellectual property laws, including trade
 * secret and copyright laws. Dissemination of this information or reproduction
 * of this material is strictly forbidden unless prior written permission is
 * obtained from Adobe.
 * *****************************************************************************
 */

import {
  FilterProp,
  isBooleanFilter,
  isStringArrayFilter,
} from '__DEPRECATED_DO_NOT_USE_OR_YOU_WILL_BE_FIRED/controllers/SearchParamsBuilder/types';

import { AppThunk } from 'redux/store';

import {
  FilterOption,
  selectCurrentFilter,
  updateFilters,
} from './filter.slice';

// note: This is never called with option.value of '*'
export function addFilter(
  prop: FilterProp,
  option: FilterOption,
  removeAll: boolean,
): AppThunk {
  return (dispatch, getState) => {
    const { filters } = selectCurrentFilter(getState());
    let currentOptions: FilterOption[] | undefined = filters[prop];

    if (!currentOptions) {
      dispatch(
        updateFilters({
          prop,
          newFilters: [option],
        }),
      );
    } else if (isStringArrayFilter(prop)) {
      /**
       * String Array Filter
       *
       * Check if option already exists, if it doesn't
       * then append to currentOptions
       */
      const shouldAddOption =
        currentOptions.findIndex(
          (current: FilterOption) => current.value === option.value,
        ) === -1;

      if (shouldAddOption) {
        if (removeAll) {
          currentOptions = currentOptions.filter(
            (currentOption) => currentOption.value !== '*',
          );
        }

        dispatch(
          updateFilters({
            prop,
            newFilters: [...currentOptions, option],
          }),
        );
      }
    } else if (isBooleanFilter(prop)) {
      /**
       * Boolean Filter
       *
       * Check if inverse of option already exists, if it does
       * then set prop to undefined
       *
       */
      const shouldSetUndefined = currentOptions.some(
        (current: FilterOption) => !current.value === option.value,
      );

      if (shouldSetUndefined) {
        dispatch(
          updateFilters({
            prop,
            newFilters: undefined,
          }),
        );
      } else {
        dispatch(
          updateFilters({
            prop,
            newFilters: [option],
          }),
        );
      }
    } else {
      /**
       * Default
       *
       * Set prop to [option]
       */
      dispatch(
        updateFilters({
          prop,
          newFilters: [option],
        }),
      );
    }
  };
}

export function removeFilter(prop: FilterProp, option: FilterOption): AppThunk {
  return (dispatch, getState) => {
    const { filters } = selectCurrentFilter(getState());
    const currentOptions: FilterOption[] | undefined = filters[prop];

    // nothing to remove
    if (!currentOptions || currentOptions.length === 0) {
      return;
    }

    const updatedOptions: FilterOption[] = currentOptions.filter(
      (currentOption: FilterOption) => currentOption.value !== option.value,
    );

    dispatch(
      updateFilters({
        prop,
        newFilters: updatedOptions,
      }),
    );
  };
}
