/**
 * *****************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2022 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Adobe and its suppliers, if any. The intellectual and technical concepts
 * contained herein are proprietary to Adobe and its suppliers and are
 * protected by all applicable intellectual property laws, including trade
 * secret and copyright laws. Dissemination of this information or reproduction
 * of this material is strictly forbidden unless prior written permission is
 * obtained from Adobe.
 * *****************************************************************************
 */

export enum ScreenTitle {
  // CCX Screens
  Main = '',
  AssetPublish = 'Publish Assets',
  AssetReview = 'Review Assets',
  CollectionCreate = 'New Collection',
  Permissions = 'Permissions',
  Search = 'Search Live Content',
  StockCollectionCreate = 'New Stock Collection',
  TemplateReview = 'Review Templates',

  // Project X Screens
  Dashboard = 'Dashboard',
  ApprovedAssets = 'Approved Assets',
  PendingAssets = 'Pending Assets',
  RejectedAssets = 'Rejected Assets',
}
