/**
 * *****************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2022 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Adobe and its suppliers, if any. The intellectual and technical concepts
 * contained herein are proprietary to Adobe and its suppliers and are
 * protected by all applicable intellectual property laws, including trade
 * secret and copyright laws. Dissemination of this information or reproduction
 * of this material is strictly forbidden unless prior written permission is
 * obtained from Adobe.
 * *****************************************************************************
 */

import React, { ReactElement } from 'react';

import { Button } from '@adobe/react-spectrum';
import ChevronLeft from '@react/react-spectrum/Icon/ChevronLeft';
import ChevronRight from '@react/react-spectrum/Icon/ChevronRight';

import { TemplateAsset } from '__DEPRECATED_DO_NOT_USE_OR_YOU_WILL_BE_FIRED/controllers/api/SparkSearchTypes';
import { AssetStatus } from '__DEPRECATED_DO_NOT_USE_OR_YOU_WILL_BE_FIRED/controllers/constants/StatusUtils';
import DetailActionRail from '__DEPRECATED_DO_NOT_USE_OR_YOU_WILL_BE_FIRED/Detail/components/DetailActionRail';
import { FilterTab } from '__DEPRECATED_DO_NOT_USE_OR_YOU_WILL_BE_FIRED/redux/filter/filter.slice';

import Loading from 'components/Loading';
import {
  selectTemplateDetailRailState,
  TemplateRailActionState,
} from 'redux/__DEPRECATED_templates/templates.slice';
import { useAppSelector } from 'redux/hooks';
import { ScreenView } from 'redux/screen/screen.slice';

import TemplateLoadingPanel from '../TemplateLoadingPanel/TemplateLoadingPanel';
import TemplateMetadataPanel from '../TemplateMetadataPanel';
import TemplateOverviewPanel from '../TemplateOverviewPanel';
import TemplateRendition from '../TemplateRendition';

import styles from './TemplatesBrowse.module.scss';

type TemplatesBrowseWithCommentsProps = {
  currentAsset: TemplateAsset;
  currentAssetInMetadata: TemplateAsset | undefined;
  isCurator: boolean;
  onBrowseNext: () => void;
  onBrowsePrevious: () => void;
  onViewButtonClick: (screen: ScreenView) => void;
  tab: FilterTab;
};

export default function TemplatesBrowseWithComments({
  currentAsset,
  currentAssetInMetadata,
  isCurator,
  onBrowseNext,
  onBrowsePrevious,
  onViewButtonClick,
  tab,
}: TemplatesBrowseWithCommentsProps): ReactElement {
  const templateDetailRailState = useAppSelector(selectTemplateDetailRailState);

  return (
    <div className={styles.templatesBrowse}>
      <div className={styles.browse}>
        <div className={styles.navigation}>
          <Button
            variant="primary"
            isQuiet
            onPress={() => onViewButtonClick(ScreenView.Grid)}
          >
            <ChevronLeft /> Return to Templates
          </Button>
        </div>
        <Button
          onPress={onBrowsePrevious}
          UNSAFE_className={styles.prev}
          variant="secondary"
          isQuiet
          aria-label="Previous Template"
        >
          <ChevronLeft size="XL" />
        </Button>
        <div className={styles.rendition}>
          {currentAssetInMetadata ? (
            <TemplateRendition
              shouldLoadAnnotations={false}
              asset={currentAsset}
            />
          ) : (
            <Loading />
          )}
        </div>
        <Button
          onPress={onBrowseNext}
          UNSAFE_className={styles.next}
          variant="secondary"
          isQuiet
          aria-label="Next Template"
        >
          <ChevronRight size="XL" />
        </Button>
      </div>

      {templateDetailRailState === TemplateRailActionState.TemplateOverview ? (
        <TemplateOverviewPanel
          key={currentAsset.id}
          currentAsset={currentAsset}
        />
      ) : null}

      {templateDetailRailState === TemplateRailActionState.TemplateMetadata ? (
        <TemplateMetadataPanel
          key={currentAsset.id}
          tab={tab}
          currentAssetInMetadata={currentAssetInMetadata}
          currentAsset={currentAsset}
        />
      ) : null}

      {templateDetailRailState === TemplateRailActionState.Comments ? (
        <TemplateLoadingPanel title="Reviews and Comments" />
      ) : null}

      <DetailActionRail
        currentAssetId={currentAsset.id}
        currentAssetStatus={currentAsset.status as AssetStatus}
        isCurator={isCurator}
        tab={tab}
      />
    </div>
  );
}
