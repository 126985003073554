/**
 * *****************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2022 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Adobe and its suppliers, if any. The intellectual and technical concepts
 * contained herein are proprietary to Adobe and its suppliers and are
 * protected by all applicable intellectual property laws, including trade
 * secret and copyright laws. Dissemination of this information or reproduction
 * of this material is strictly forbidden unless prior written permission is
 * obtained from Adobe.
 * *****************************************************************************
 */

import React, { ReactElement, useCallback } from 'react';

import { Heading, View } from '@adobe/react-spectrum';

import Pane from 'components/@spectrum/Pane';
import AssetInfo from 'components/DetailView/AssetInfo';
import Loading from 'components/Loading';
import LocalizedField from 'components/LocalizedField';
import useRepoMetadata from 'hooks/acp/useRepoMetadata';
import useAssetMetadataModel from 'hooks/useAssetMetadataModel';
import { useCCXPrivateConfig, useCCXPublicConfig } from 'hooks/useCCXConfig';
import { AssetMimeType } from 'model/acp/AssetMimeType';
import { RepoMetadataKey } from 'model/acp/RepoMetadataKey';
import { ValidationFieldErrors } from 'model/errors/ValidationError';
import { AssetMetadataModelKey } from 'model/metadata/AssetMetadataModel';
import { FileMetadataModel } from 'model/metadata/FileMetadataModel';

import DefaultMetadataFormLayout from './DefaultMetadataFormLayout';
import AlbumNameFormField from './fields/AlbumNameFormField';
import AnimatedFormField from './fields/AnimatedFormField';
import ApplicableRegionFormField from './fields/ApplicableRegionFormField';
import BPMFormField from './fields/BPMFormField';
import CreatorsFormField from './fields/CreatorsFormField';
import FormLabel from './fields/FormLabel';
import GenericMultipickerFormField from './fields/GenericMultiPickerFormField';
import GenresFormField from './fields/GenresFormField';
import HeroFormField from './fields/HeroFormField';
import IsInstrumentalFormField from './fields/IsInstrumentalFormField';
import LanguagesFormField from './fields/LanguagesFormField';
import LicensingCategoryFormField from './fields/LicensingCategoryFormField';
import OptionsFormGroup from './fields/OptionsFormGroup';
import PriorityFormField from './fields/PriorityFormField';
import TasksFormField from './fields/TasksFormField';
import TopicsFormField from './fields/TopicsFormField';
import TraitsFormField from './fields/TraitsFormField';
import VendorFormField from './fields/VendorFormField';

type FileMetadataFormProps = {
  hasExternalChanges?: boolean;
  isCollapsed?: boolean;
  isSaving: boolean;
  onCollapse?: () => void;
  onReset?: () => void;
  onSave: (
    model: FileMetadataModel,
  ) => Promise<ValidationFieldErrors | undefined>;
  onChangesStaged: (value: boolean) => void;
  path?: string;
};

export default function FileMetadataForm({
  onChangesStaged,
  hasExternalChanges = false,
  isCollapsed = false,
  isSaving,
  onCollapse,
  onReset,
  onSave,
  path,
}: FileMetadataFormProps): ReactElement {
  const { results: ccxPrivateConfig, isLoading: isLoadingPrivateConfig } =
    useCCXPrivateConfig();
  const { results: ccxPublicConfig, isLoading: isLoadingPublicConfig } =
    useCCXPublicConfig();
  const {
    hasChanges,
    isLoading,
    model,
    reset,
    setModelField,
    setValidationErrors,
    validationErrors,
  } = useAssetMetadataModel({ path, type: 'file' });
  const { results: repoMetadata } = useRepoMetadata({ path: path ?? '' });
  const isAudio = repoMetadata
    ? repoMetadata[RepoMetadataKey.DCFormat].match(AssetMimeType.Audio)
    : false;
  const handleReset = useCallback(async () => {
    reset();
    onChangesStaged(false);

    if (onReset) {
      onReset();
    }
  }, [onReset, reset, onChangesStaged]);
  const handleCollapse = useCallback(() => {
    if (onCollapse) {
      onCollapse();
    }
  }, [onCollapse]);
  const handleSave = useCallback(async () => {
    if (!model) {
      return;
    }

    setValidationErrors();

    const errors = await onSave(model);

    if (errors) {
      setValidationErrors(errors);
    }

    onChangesStaged(false);
  }, [model, onSave, setValidationErrors, onChangesStaged]);

  if (isLoading || isLoadingPrivateConfig || isLoadingPublicConfig || !model) {
    return (
      <Pane isOpen={!isCollapsed} isSticky>
        <Loading />
      </Pane>
    );
  }

  const handleChange =
    (...keys: AssetMetadataModelKey[]) =>
    (value: any) => {
      setModelField(...keys)(value);
      onChangesStaged(true);
    };

  return (
    <Pane isOpen={!isCollapsed} isSticky>
      <View padding="10px">
        <AssetInfo />
      </View>
      <DefaultMetadataFormLayout
        hasChanges={hasChanges || hasExternalChanges}
        isCollapsed={isCollapsed}
        isSaving={isSaving}
        onCollapse={handleCollapse}
        onReset={handleReset}
        onSave={handleSave}
        path={path}
      >
        <LocalizedField
          label="Default Title"
          onChange={handleChange(AssetMetadataModelKey.Title)}
          value={model[AssetMetadataModelKey.Title]}
        />

        {isAudio ? (
          <>
            <AlbumNameFormField
              isDisabled={isSaving}
              isReadOnly={isSaving}
              error={validationErrors[AssetMetadataModelKey.Album]}
              onChange={handleChange(AssetMetadataModelKey.Album)}
              value={model[AssetMetadataModelKey.Album]}
            />
            <CreatorsFormField
              isDisabled={isSaving}
              isReadOnly={isSaving}
              error={validationErrors[AssetMetadataModelKey.Creators]}
              onChange={handleChange(AssetMetadataModelKey.Creators)}
              value={model[AssetMetadataModelKey.Creators]}
            />
            <VendorFormField
              isDisabled={isSaving}
              isReadOnly={isSaving}
              error={validationErrors[AssetMetadataModelKey.Vendor]}
              onChange={handleChange(AssetMetadataModelKey.Vendor)}
              value={model[AssetMetadataModelKey.Vendor]}
            />
          </>
        ) : (
          <></>
        )}

        <OptionsFormGroup title="Flags" isWrapping>
          <LicensingCategoryFormField
            isDisabled={isSaving}
            isReadOnly={isSaving}
            error={validationErrors[AssetMetadataModelKey.LicensingCategory]}
            onChange={handleChange(AssetMetadataModelKey.LicensingCategory)}
            value={model[AssetMetadataModelKey.LicensingCategory]}
          />

          <AnimatedFormField
            isDisabled={isSaving}
            isReadOnly={isSaving}
            isSelected={model[AssetMetadataModelKey.Animated]}
            error={validationErrors[AssetMetadataModelKey.Animated]}
            onChange={handleChange(AssetMetadataModelKey.Animated)}
          />

          <HeroFormField
            isDisabled={isSaving}
            isReadOnly={isSaving}
            isSelected={model[AssetMetadataModelKey.Hero]}
            error={validationErrors[AssetMetadataModelKey.Hero]}
            onChange={handleChange(AssetMetadataModelKey.Hero)}
          />

          <PriorityFormField
            isDisabled={isSaving}
            isReadOnly={isSaving}
            error={validationErrors[AssetMetadataModelKey.Priority]}
            onChange={handleChange(AssetMetadataModelKey.Priority)}
            value={model[AssetMetadataModelKey.Priority]}
          />
        </OptionsFormGroup>
        {isAudio ? (
          <OptionsFormGroup title="Audio Attributes" isWrapping>
            <IsInstrumentalFormField
              isDisabled={isSaving}
              isReadOnly={isSaving}
              error={validationErrors[AssetMetadataModelKey.IsInstrumental]}
              onChange={handleChange(AssetMetadataModelKey.IsInstrumental)}
              isSelected={model[AssetMetadataModelKey.IsInstrumental]}
            />
            <BPMFormField
              isDisabled={isSaving}
              isReadOnly={isSaving}
              error={validationErrors[AssetMetadataModelKey.BPM]}
              onChange={handleChange(AssetMetadataModelKey.BPM)}
              value={model[AssetMetadataModelKey.BPM]}
            />
          </OptionsFormGroup>
        ) : (
          <></>
        )}

        <TopicsFormField
          isDisabled={isSaving}
          isReadOnly={isSaving}
          error={validationErrors[AssetMetadataModelKey.Topics]}
          onChange={handleChange(AssetMetadataModelKey.Topics)}
          value={model[AssetMetadataModelKey.Topics]}
        />

        {isAudio ? (
          <GenresFormField
            isDisabled={isSaving}
            isReadOnly={isSaving}
            error={validationErrors[AssetMetadataModelKey.Genres]}
            options={ccxPrivateConfig?.audio.genres.options}
            onChange={handleChange(AssetMetadataModelKey.Genres)}
            value={model[AssetMetadataModelKey.Genres]}
          />
        ) : (
          <></>
        )}

        <TasksFormField
          isDisabled={isSaving}
          isReadOnly={isSaving}
          error={validationErrors[AssetMetadataModelKey.Tasks]}
          onChange={handleChange(AssetMetadataModelKey.Tasks)}
          options={ccxPrivateConfig?.common.tasks.options}
          value={model[AssetMetadataModelKey.Tasks]}
        />

        <TraitsFormField
          isDisabled={isSaving}
          isReadOnly={isSaving}
          error={validationErrors[AssetMetadataModelKey.Traits]}
          onChange={handleChange(AssetMetadataModelKey.Traits)}
          options={ccxPrivateConfig?.common.traits.options}
          value={model[AssetMetadataModelKey.Traits]}
        />

        <ApplicableRegionFormField
          isDisabled={isSaving}
          isReadOnly={isSaving}
          error={validationErrors[AssetMetadataModelKey.ApplicableRegions]}
          options={ccxPublicConfig?.common.applicableRegions.options}
          onChange={handleChange(AssetMetadataModelKey.ApplicableRegions)}
          value={model[AssetMetadataModelKey.ApplicableRegions]}
        />

        <LanguagesFormField
          isDisabled={isSaving}
          isReadOnly={isSaving}
          error={validationErrors[AssetMetadataModelKey.Language]}
          options={ccxPublicConfig?.common.language.options}
          onChange={handleChange(AssetMetadataModelKey.Language)}
          value={model[AssetMetadataModelKey.Language]}
        />

        <FormLabel>Applicable Filters</FormLabel>

        <GenericMultipickerFormField
          label={
            <Heading marginTop="size-100" marginBottom="size-50" level={5}>
              Entitlements
            </Heading>
          }
          isDisabled={isSaving}
          isReadOnly={isSaving}
          error={validationErrors[AssetMetadataModelKey.ApplicableFilters]}
          options={
            ccxPrivateConfig?.common.applicableFilters.entitlements.options
          }
          onChange={handleChange(
            AssetMetadataModelKey.ApplicableFilters,
            AssetMetadataModelKey.Entitlements,
          )}
          textLabel="Entitlements"
          value={
            model[AssetMetadataModelKey.ApplicableFilters][
              AssetMetadataModelKey.Entitlements
            ]
          }
        />

        <GenericMultipickerFormField
          label={
            <Heading marginTop="size-100" marginBottom="size-50" level={5}>
              Interest Groups
            </Heading>
          }
          isDisabled={isSaving}
          isReadOnly={isSaving}
          error={validationErrors[AssetMetadataModelKey.ApplicableFilters]}
          options={
            ccxPrivateConfig?.common.applicableFilters.interestGroups.options
          }
          onChange={handleChange(
            AssetMetadataModelKey.ApplicableFilters,
            AssetMetadataModelKey.InterestGroups,
          )}
          textLabel="Interest Groups"
          value={
            model[AssetMetadataModelKey.ApplicableFilters][
              AssetMetadataModelKey.InterestGroups
            ]
          }
        />

        <GenericMultipickerFormField
          label={
            <Heading marginTop="size-100" marginBottom="size-50" level={5}>
              Restrictions
            </Heading>
          }
          isDisabled={isSaving}
          isReadOnly={isSaving}
          error={validationErrors[AssetMetadataModelKey.ApplicableFilters]}
          options={
            ccxPrivateConfig?.common.applicableFilters.restrictions.options
          }
          onChange={handleChange(
            AssetMetadataModelKey.ApplicableFilters,
            AssetMetadataModelKey.Restrictions,
          )}
          textLabel="Restrictions"
          value={
            model[AssetMetadataModelKey.ApplicableFilters][
              AssetMetadataModelKey.Restrictions
            ]
          }
        />
      </DefaultMetadataFormLayout>
    </Pane>
  );
}

FileMetadataForm.defaultProps = {
  hasExternalChanges: false,
  isCollapsed: false,
  onCollapse: undefined,
  onReset: undefined,
  path: undefined,
};
