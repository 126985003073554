/**
 * *****************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2022 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Adobe and its suppliers, if any. The intellectual and technical concepts
 * contained herein are proprietary to Adobe and its suppliers and are
 * protected by all applicable intellectual property laws, including trade
 * secret and copyright laws. Dissemination of this information or reproduction
 * of this material is strictly forbidden unless prior written permission is
 * obtained from Adobe.
 * *****************************************************************************
 */

import React, { Key, ReactElement } from 'react';

import { Button, ButtonGroup, Flex } from '@adobe/react-spectrum';

import { CardAction } from './BaseCardQuickActions';

import styles from './BaseCard.module.scss';

type BaseCardActionsProps = {
  actions: CardAction[];
  onAction: (key: Key) => void;
};

export default function BaseCardActions({
  actions,
  onAction,
}: BaseCardActionsProps): ReactElement {
  return (
    <Flex UNSAFE_className="spectrum-Card-footer" justifyContent="end">
      <ButtonGroup>
        {actions.map((action) => (
          <Button
            UNSAFE_className={styles.cardAction}
            key={action.label}
            variant={action.variant ?? 'cta'}
            onPress={() => onAction(action.value)}
          >
            {action.label}
          </Button>
        ))}
      </ButtonGroup>
    </Flex>
  );
}
