/**
 * *****************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2022 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Adobe and its suppliers, if any. The intellectual and technical concepts
 * contained herein are proprietary to Adobe and its suppliers and are
 * protected by all applicable intellectual property laws, including trade
 * secret and copyright laws. Dissemination of this information or reproduction
 * of this material is strictly forbidden unless prior written permission is
 * obtained from Adobe.
 * *****************************************************************************
 */

import { Entitlement } from 'model/acp/Entitlement';

export type MetadataLabelValueMap = {
  [label: string]: string;
};

export interface MetadataOption {
  label: string;
  value: string;
}

export interface FolderOption extends MetadataOption {
  next?: boolean | string;
  parentPath?: string;
}

const metadataLanguages: Record<string, string> = {
  Default: 'i-default',
  English: 'en*',
  'English (Global)': 'en-US',
  'English (United Kingdom)': 'en-GB',
  French: 'fr*',
  German: 'de*',
  Italian: 'it*',
  Portuguese: 'pt*',
  Spanish: 'es*',
  Danish: 'da*',
  'Norwegian - Bokmal': 'nb*',
  Netherlands: 'nl*',
  Finnish: 'fi*',
  'Swedish - Sweden': 'sv*',
  Japanese: 'ja*',
  'Chinese (Simplified)': 'zh-hans*',
  'Chinese (Traditional)': 'zh-hant*',
  Korean: 'ko*',
};

export class MetadataOptions {
  public static getMetadataOptionsFromValueMap(
    obj: MetadataLabelValueMap,
    sortByValue = false,
  ): MetadataOption[] {
    let options: MetadataOption[] = [];

    options = Object.entries(obj).map((item: string[]) => {
      const [label, value] = item;

      return {
        label,
        value,
      };
    });

    if (sortByValue) {
      options.sort((a, b) => (a.value >= b.value ? 1 : -1));
    }

    return options;
  }
  public static get ENTITLEMENT(): MetadataOption[] {
    return this.getMetadataOptionsFromValueMap(Entitlement);
  }
  public static get CATEGORIES(): MetadataOption[] {
    const record = {
      Business: 'business',
      Craft: 'craft',
      School: 'edu',
      Featured: 'feed',
      Food: 'food',
      Collage: 'grid',
      Lifestyle: 'lifestyle',
      Seasonal: 'seasonal',
      Travel: 'travel',
    };

    return this.getMetadataOptionsFromValueMap(record);
  }
  public static get LANGUAGES_FOR_REVIEW(): MetadataOption[] {
    const record = { ...metadataLanguages };
    delete record.English;

    return this.getMetadataOptionsFromValueMap(record);
  }
  public static get LANGUAGES_FOR_SEARCH(): MetadataOption[] {
    const record = { ...metadataLanguages };
    delete record['English (Global)'];
    delete record['English (United Kingdom)'];

    return this.getMetadataOptionsFromValueMap(record);
  }
  public static get DIRECTORY(): MetadataOption[] {
    const record = {
      Stickers: '/content/assets/publish/stickers*',
      Backgrounds: '/content/assets/publish/backgrouds*',
      'Design Assets': '/content/assets/publish/Design Assets*',
      Frames: '/content/assets/publish/Design Assets/Frames*',
      Shapes: '/content/assets/publish/Design Assets/Shapes*',
      Ornaments: '/content/assets/publish/Design Assets/Ornaments*',
      Brushes: '/content/assets/publish/Design Assets/Brushes*',
      Textures: '/content/assets/publish/Design Assets/Textures*',
      Overlays: '/content/assets/publish/Design Assets/Overlays*',
    };

    return this.getMetadataOptionsFromValueMap(record);
  }
}

export const TAGS_VALUES_MAP: { [key: string]: any[] | undefined } = {
  categories: MetadataOptions.CATEGORIES.map(
    (option: MetadataOption) => option.value,
  ),
};
