/**
 * *****************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2022 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Adobe and its suppliers, if any. The intellectual and technical concepts
 * contained herein are proprietary to Adobe and its suppliers and are
 * protected by all applicable intellectual property laws, including trade
 * secret and copyright laws. Dissemination of this information or reproduction
 * of this material is strictly forbidden unless prior written permission is
 * obtained from Adobe.
 * *****************************************************************************
 */

import React, { Key, useCallback } from 'react';

import { Button, Flex, Item, Picker, Text, View } from '@adobe/react-spectrum';
import Close from '@spectrum-icons/workflow/Close';

import { CCXConfigOption } from 'model/ccxConfig/CCXConfig';

import { AssetMetadataFormFieldProps } from './AssetMetadataFormFieldProps';
import FormLabel from './FormLabel';

export default function GenericPickerFormField({
  label,
  isReadOnly,
  isDisabled,
  onChange,
  options,
  textLabel,
  value,
}: AssetMetadataFormFieldProps<string[] | undefined, CCXConfigOption[]>) {
  const selectedKey = options?.find(
    (option) =>
      value !== undefined && value.length !== 0 && option.value === value[0],
  );
  const handleChange = useCallback(
    (key: Key) => {
      onChange([key as string]);
    },
    [onChange],
  );
  const handleClear = useCallback(() => {
    onChange([]);
  }, [onChange]);

  return (
    <>
      <View marginTop="size-250" marginBottom="size-50" position="relative">
        <FormLabel>{label}</FormLabel>
        <Button
          bottom="-4px"
          isHidden={selectedKey === undefined}
          isQuiet
          onPress={handleClear}
          position="absolute"
          right="-12px"
          variant="negative"
        >
          <Flex alignItems="center" justifyContent="center">
            <Close size="S" />
            <Text>Clear</Text>
          </Flex>
        </Button>
      </View>
      <Picker
        items={options}
        isDisabled={isReadOnly || isDisabled}
        onSelectionChange={handleChange}
        selectedKey={selectedKey?.value ?? null}
        width="100%"
        aria-label={textLabel ?? (typeof label === 'string' ? label : '')}
      >
        {(item) => <Item key={item.value}>{item.label}</Item>}
      </Picker>
    </>
  );
}
