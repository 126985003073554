/**
 * *****************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2022 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Adobe and its suppliers, if any. The intellectual and technical concepts
 * contained herein are proprietary to Adobe and its suppliers and are
 * protected by all applicable intellectual property laws, including trade
 * secret and copyright laws. Dissemination of this information or reproduction
 * of this material is strictly forbidden unless prior written permission is
 * obtained from Adobe.
 * *****************************************************************************
 */

import React, { ReactElement } from 'react';

import {
  Content,
  Flex,
  Heading,
  IllustratedMessage,
} from '@adobe/react-spectrum';
import DataCheck from '@spectrum-icons/workflow/DataCheck';

import styles from './WorkflowActionsMenu.module.scss';

export default function EmptyCompletedJobsQueue(): ReactElement {
  return (
    <Flex
      alignItems="center"
      justifyContent="center"
      direction="column"
      height="size-5000"
    >
      <IllustratedMessage UNSAFE_className={styles.message}>
        <DataCheck size="XXL" />
        <Heading>
          <div>Your completed workflow actions</div>
          <div>will appear here</div>
        </Heading>
        <Content>
          This includes successful workflow actions and failed workflow
          <br />
          actions that were marked completed.
        </Content>
      </IllustratedMessage>
    </Flex>
  );
}
