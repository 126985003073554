/**
 * *****************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2022 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Adobe and its suppliers, if any. The intellectual and technical concepts
 * contained herein are proprietary to Adobe and its suppliers and are
 * protected by all applicable intellectual property laws, including trade
 * secret and copyright laws. Dissemination of this information or reproduction
 * of this material is strictly forbidden unless prior written permission is
 * obtained from Adobe.
 * *****************************************************************************
 */

import React from 'react';

import SpectrumTextfield from '@react/react-spectrum/Textfield';

import styles from '__DEPRECATED_DO_NOT_USE_OR_YOU_WILL_BE_FIRED/Detail/styles/Fields.module.scss';

interface TextFieldProps {
  label: string;
  value: string;
  setValue: (value: string) => void;
}

const TextField: React.FC<TextFieldProps> = ({
  label,
  value,
  setValue,
}: TextFieldProps) => (
  <div className={styles.textField}>
    {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
    <label className={styles.fieldLabel}>{label}</label>
    <SpectrumTextfield
      aria-label={label}
      onChange={setValue}
      value={value}
      className={`${styles.textField} ${styles.size}`}
    />
  </div>
);

export default TextField;
