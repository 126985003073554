/**
 * *****************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2022 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Adobe and its suppliers, if any. The intellectual and technical concepts
 * contained herein are proprietary to Adobe and its suppliers and are
 * protected by all applicable intellectual property laws, including trade
 * secret and copyright laws. Dissemination of this information or reproduction
 * of this material is strictly forbidden unless prior written permission is
 * obtained from Adobe.
 * *****************************************************************************
 */

import ApplicationMetadata, {
  ApplicableFilter,
} from 'model/acp/ApplicationMetadata';
import { ApplicationMetadataKey } from 'model/acp/ApplicationMetadataKey';
import {
  ApplicableFilters,
  AssetMetadataModelKey,
} from 'model/metadata/AssetMetadataModel';

export function mapApplicableFiltersFromApplicationMetadata(
  applicationMetadata: ApplicationMetadata,
): ApplicableFilters {
  const applicableFilters: ApplicableFilters = {
    [AssetMetadataModelKey.Entitlements]: [],
    [AssetMetadataModelKey.InterestGroups]: [],
    [AssetMetadataModelKey.Restrictions]: [],
  };

  applicationMetadata[ApplicationMetadataKey.ApplicableFilters]?.forEach(
    (filter) => {
      switch (filter.name) {
        case AssetMetadataModelKey.Entitlements:
          applicableFilters[AssetMetadataModelKey.Entitlements] =
            applicableFilters[AssetMetadataModelKey.Entitlements].concat(
              filter.values,
            );
          break;

        case AssetMetadataModelKey.InterestGroups:
          applicableFilters[AssetMetadataModelKey.InterestGroups] =
            applicableFilters[AssetMetadataModelKey.InterestGroups].concat(
              filter.values,
            );
          break;

        case AssetMetadataModelKey.Restrictions:
          applicableFilters[AssetMetadataModelKey.Restrictions] =
            applicableFilters[AssetMetadataModelKey.Restrictions].concat(
              filter.values,
            );
          break;
      }
    },
  );

  return applicableFilters;
}

export function mapApplicationMetadataFromApplicableFilters(
  applicableFilters: ApplicableFilters,
): ApplicableFilter[] | undefined {
  const filters: ApplicableFilter[] = [];

  if (applicableFilters[AssetMetadataModelKey.Entitlements].length !== 0) {
    filters.push({
      name: AssetMetadataModelKey.Entitlements,
      values: applicableFilters[AssetMetadataModelKey.Entitlements],
    });
  }

  if (applicableFilters[AssetMetadataModelKey.InterestGroups].length !== 0) {
    filters.push({
      name: AssetMetadataModelKey.InterestGroups,
      values: applicableFilters[AssetMetadataModelKey.InterestGroups],
    });
  }

  if (applicableFilters[AssetMetadataModelKey.Restrictions].length !== 0) {
    filters.push({
      name: AssetMetadataModelKey.Restrictions,
      values: applicableFilters[AssetMetadataModelKey.Restrictions],
    });
  }

  if (filters.length === 0) {
    return undefined;
  }

  return filters;
}
