/**
 * *****************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2022 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Adobe and its suppliers, if any. The intellectual and technical concepts
 * contained herein are proprietary to Adobe and its suppliers and are
 * protected by all applicable intellectual property laws, including trade
 * secret and copyright laws. Dissemination of this information or reproduction
 * of this material is strictly forbidden unless prior written permission is
 * obtained from Adobe.
 * *****************************************************************************
 */

import React, { ReactElement, useCallback, useEffect, useState } from 'react';

import { TextField, View } from '@adobe/react-spectrum';

import FormLabel from 'components/AssetMetadataForm/fields/FormLabel';
import { HelpText } from 'components/AssetMetadataForm/fields/HelpText';

import FormDialog, { FormDialogProps } from './FormDialog';

type CreateFolderFormDialogProps = Omit<
  FormDialogProps,
  'children' | 'onCreate'
> & {
  onCreate: (folderName: string) => Promise<void>;
};

export default function CreateFolderFormDialog({
  isOpen,
  onClose,
  onCreate,
}: CreateFolderFormDialogProps): ReactElement {
  const [folderName, setFolderName] = useState<string>('');
  const [folderError, setFolderError] = useState<Error>();
  const handleCreate = useCallback(async () => {
    setFolderError(undefined);

    try {
      if (folderName.length === 0) {
        throw new Error('Folder must have a name.');
      }

      await onCreate(folderName);
    } catch (error) {
      setFolderError(error as Error);

      throw error;
    }

    setFolderName('');
  }, [folderName, onCreate, setFolderName]);

  useEffect(() => {
    setFolderName('');
    setFolderError(undefined);
  }, [setFolderError, setFolderName, isOpen]);

  return (
    <FormDialog
      isDisabled={folderName === ''}
      isOpen={isOpen}
      onClose={onClose}
      onCreate={handleCreate}
      title="Create new folder"
    >
      <>
        <TextField
          autoFocus
          label={<FormLabel>Folder Name</FormLabel>}
          onChange={setFolderName}
          value={folderName}
        />
        {folderError && (
          <View marginTop="size-125">
            <HelpText validationState="invalid">{folderError.message}</HelpText>
          </View>
        )}
      </>
    </FormDialog>
  );
}
