/**
 * *****************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2022 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Adobe and its suppliers, if any. The intellectual and technical concepts
 * contained herein are proprietary to Adobe and its suppliers and are
 * protected by all applicable intellectual property laws, including trade
 * secret and copyright laws. Dissemination of this information or reproduction
 * of this material is strictly forbidden unless prior written permission is
 * obtained from Adobe.
 * *****************************************************************************
 */

import React, { ReactChild, ReactElement } from 'react';

import {
  Flex,
  ProgressCircle,
  Text,
  ToggleButton,
  Tooltip,
  TooltipTrigger,
  View,
  Well,
} from '@adobe/react-spectrum';
import Cancel from '@spectrum-icons/workflow/Cancel';
import RailRightClose from '@spectrum-icons/workflow/RailRightClose';
import RailRightOpen from '@spectrum-icons/workflow/RailRightOpen';
import SaveFloppy from '@spectrum-icons/workflow/SaveFloppy';

import ActionButton from 'components/ActionButton';
import AssetFixedMetadataForm from 'components/DetailView/AssetFixedMetadataForm';
import NavBlockPrompt from 'components/NavBlockPrompt';

export type MetadataFormWarning = {
  color:
    | 'grey'
    | 'red'
    | 'orange'
    | 'yellow'
    | 'seafoam'
    | 'green'
    | 'blue'
    | 'fuchsia';
  tooltip: string;
  label: string;
};

type DefaultMetadataFormProps = {
  actions?: ReactChild;
  isCollapsible?: boolean;
  children: ReactChild | ReactChild[];
  customFixedFields?: string[][];
  hasChanges: boolean;
  isCollapsed?: boolean;
  isSaving: boolean;
  onCollapse?: () => void;
  onReset: () => void;
  onSave: () => void;
  path: string | undefined;
  title?: ReactChild;
  warnings?: MetadataFormWarning[];
};

export default function DefaultMetadataFormLayout({
  actions,
  isCollapsible,
  children,
  customFixedFields,
  hasChanges,
  isCollapsed,
  isSaving,
  onCollapse,
  onReset,
  onSave,
  path = '',
  title,
  warnings,
}: DefaultMetadataFormProps): ReactElement {
  return (
    <View
      position="relative"
      paddingTop="47px"
      paddingBottom="size-200"
      width="100%"
    >
      <View
        position="absolute"
        top="size-0"
        right="size-0"
        height="size-250"
        marginBottom="size-125"
      >
        <Flex justifyContent="end" alignItems="center">
          <>
            <View flex minWidth="size-0">
              {title}
            </View>
            {warnings && warnings.length !== 0 && !isSaving ? (
              <>
                {warnings.map((warning) => (
                  <TooltipTrigger key={warning.label} delay={0}>
                    <ActionButton
                      isQuiet
                      marginEnd="size-100"
                      UNSAFE_className={`spectrum-Label spectrum-Label--${warning.color}`}
                    >
                      {warning.label}
                    </ActionButton>
                    <Tooltip>{warning.tooltip}</Tooltip>
                  </TooltipTrigger>
                ))}
              </>
            ) : null}
            {!isSaving ? (
              <>
                <ActionButton
                  isDisabled={!hasChanges}
                  type="submit"
                  onPress={onSave}
                  marginEnd="size-100"
                >
                  <View paddingTop="size-50">
                    <SaveFloppy size="S" />
                  </View>
                  <Text marginStart="size-50">Save</Text>
                </ActionButton>
                <ActionButton
                  isDisabled={!hasChanges}
                  type="button"
                  onPress={onReset}
                >
                  <View paddingTop="size-50">
                    <Cancel size="S" />
                  </View>
                  <Text marginStart="size-50">Reset</Text>
                </ActionButton>
              </>
            ) : (
              <Flex
                justifyContent="safe center"
                alignItems="center"
                marginEnd="size-100"
              >
                <ProgressCircle
                  aria-label="Saving Metadata"
                  size="S"
                  isIndeterminate
                />
              </Flex>
            )}
            <>{actions}</>
            {isCollapsible ? (
              <ToggleButton onPress={onCollapse} marginStart="size-100" isQuiet>
                {isCollapsed ? (
                  <RailRightOpen size="S" />
                ) : (
                  <RailRightClose size="S" />
                )}
              </ToggleButton>
            ) : null}
          </>
        </Flex>
      </View>

      <Flex
        isHidden={isCollapsed}
        direction="column"
        gap="size-200"
        height="100%"
      >
        {path ? (
          <AssetFixedMetadataForm
            path={path}
            customFields={customFixedFields}
          />
        ) : (
          <></>
        )}

        <Well flexShrink={1} UNSAFE_style={{ overflowY: 'auto' }}>
          {children}
        </Well>
      </Flex>

      <NavBlockPrompt
        hasBlockingChanges={hasChanges && !isSaving}
        title="You have unsaved changes"
        primaryActionLabel="Discard + Continue"
        cancelLabel="Keep Editing"
        message="Your changes will not be saved if you continue."
      />
    </View>
  );
}

DefaultMetadataFormLayout.defaultProps = {
  actions: undefined,
  isCollapsible: true,
  isCollapsed: undefined,
  customFixedFields: [],
  onCollapse: undefined,
  title: undefined,
  warnings: undefined,
};
