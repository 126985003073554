/**
 * *****************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2022 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Adobe and its suppliers, if any. The intellectual and technical concepts
 * contained herein are proprietary to Adobe and its suppliers and are
 * protected by all applicable intellectual property laws, including trade
 * secret and copyright laws. Dissemination of this information or reproduction
 * of this material is strictly forbidden unless prior written permission is
 * obtained from Adobe.
 * *****************************************************************************
 */

import React, { ReactElement } from 'react';

import { Grid, Heading, View } from '@adobe/react-spectrum';

import { PathCard } from 'components/Card';
import { CardAction } from 'components/Card/BaseCardQuickActions';
import ScrollPane from 'components/ScrollPane';
import { ChildAsset } from 'model/acp/ChildAsset';
import { RepoMetadataKey } from 'model/acp/RepoMetadataKey';
import { ZoomLevel, zoomLevelToSizeMapping } from 'model/ZoomLevel';
import { isCollection, isDirectory, isFile } from 'utils/assets';

import LoadingCard from './LoadingCard';

type Props = {
  assets: ChildAsset[];
  assetActions?: CardAction[];
  canLoadMore: (isVisible: boolean) => void;
  onAssetAction?: (asset: ChildAsset, key: string) => void;
  onAssetClick: (asset: ChildAsset) => void;
  onAssetSelect: (asset: ChildAsset) => void;
  selectedAssetPaths: Set<string>;
};

export default function CardGrid({
  assets,
  assetActions,
  canLoadMore,
  onAssetAction,
  onAssetClick,
  onAssetSelect,
  selectedAssetPaths,
}: Props): ReactElement {
  const directoryChildren = assets.filter(isDirectory);
  const fileChildren = assets.filter(isFile);
  const collectionChildren = assets.filter(isCollection);

  return (
    <ScrollPane>
      <>
        {(
          [
            [directoryChildren, 'Folders'],
            [collectionChildren, 'Collections'],
            [fileChildren, 'Files'],
          ] as [ChildAsset[] | undefined, string][]
        ).map(([activeCollection, title]) => {
          if (activeCollection === undefined || activeCollection.length === 0) {
            return <div key={title} />;
          }

          return (
            <div key={title}>
              <Heading level={3} marginX="size-400">
                {title}
              </Heading>
              <Grid
                columns={`repeat(auto-fill, minmax(min(var(${
                  zoomLevelToSizeMapping[ZoomLevel.ExtraLarge]
                }), 100%), 1fr)`}
                gap="size-300"
                marginTop="size-200"
                marginX="size-400"
                marginBottom="size-400"
              >
                {activeCollection.map(
                  (childAsset: ChildAsset): ReactElement => {
                    const path = childAsset[RepoMetadataKey.Path];

                    let kind: 'file' | 'collection' | 'folder' = 'file';

                    if (isCollection(childAsset)) {
                      kind = 'collection';
                    } else if (isDirectory(childAsset)) {
                      kind = 'folder';
                    }

                    return (
                      <View key={childAsset[RepoMetadataKey.AssetId]}>
                        <PathCard
                          isActive={selectedAssetPaths.size !== 0}
                          isSelected={selectedAssetPaths.has(path)}
                          kind={kind}
                          onAction={
                            onAssetAction
                              ? (action) => onAssetAction(childAsset, action)
                              : undefined
                          }
                          onClick={() => onAssetClick(childAsset)}
                          onSelect={() => onAssetSelect(childAsset)}
                          path={path}
                          quickActions={assetActions}
                          zoomLevel={ZoomLevel.ExtraLarge}
                        />
                      </View>
                    );
                  },
                )}
              </Grid>
            </div>
          );
        })}

        <LoadingCard isEnabled setIsVisible={canLoadMore} />
      </>
    </ScrollPane>
  );
}

CardGrid.defaultProps = {
  assetActions: undefined,
  onAssetAction: undefined,
};
