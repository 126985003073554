/**
 * *****************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2022 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Adobe and its suppliers, if any. The intellectual and technical concepts
 * contained herein are proprietary to Adobe and its suppliers and are
 * protected by all applicable intellectual property laws, including trade
 * secret and copyright laws. Dissemination of this information or reproduction
 * of this material is strictly forbidden unless prior written permission is
 * obtained from Adobe.
 * *****************************************************************************
 */

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { FilterProp } from '__DEPRECATED_DO_NOT_USE_OR_YOU_WILL_BE_FIRED/controllers/SearchParamsBuilder/types';

import { RootState } from 'redux/store';

export enum ContentType {
  templates = 'templates',
  assets = 'assets',
}

export enum FilterTab {
  review = 'review',
  search = 'search',
  browse = 'browse',
}

export interface FilterOption {
  label: string;
  value: any;
  isDefault?: boolean;
  isExactMatch?: boolean;
}

export type Filters = {
  [prop in FilterProp]?: FilterOption[];
};

export type FilterState = {
  mode: FilterTab;
  filters: Filters;
  order: string;
  query: string;
};

export const filterInitialStateTemplates: FilterState = {
  mode: FilterTab.search,
  filters: {
    categories: [],
    tasks: [],
    owner: [],
    animated: [],
    premium: [],
  },
  order: '-created',
  query: '*',
};

export const filterInitialStateAssets: FilterState = {
  mode: FilterTab.search,
  filters: {
    directory: [],
    topics: [],
    owner: [],
    animated: [],
    licensingCategory: [],
  },
  order: '-createDate',
  query: '*',
};

export interface FiltersState {
  contentType: ContentType;
  mode: FilterTab;
  [ContentType.templates]: {
    [key in FilterTab]: FilterState;
  };
  [ContentType.assets]: {
    [key in FilterTab]: FilterState;
  };
}

const initialState: FiltersState = {
  contentType: ContentType.assets,
  mode: FilterTab.search,
  [ContentType.templates]: {
    [FilterTab.browse]: {
      ...filterInitialStateTemplates,
      mode: FilterTab.browse,
    },
    [FilterTab.search]: {
      ...filterInitialStateTemplates,
      mode: FilterTab.search,
    },
    [FilterTab.review]: {
      ...filterInitialStateTemplates,
      mode: FilterTab.review,
    },
  },
  [ContentType.assets]: {
    [FilterTab.browse]: {
      ...filterInitialStateAssets,
      mode: FilterTab.browse,
    },
    [FilterTab.search]: {
      ...filterInitialStateAssets,
      mode: FilterTab.browse,
    },
    [FilterTab.review]: {
      ...filterInitialStateAssets,
      mode: FilterTab.browse,
    },
  },
};

export const filterSlice = createSlice({
  name: 'filter',
  initialState,
  reducers: {
    resetFiltersForMode: (state, action: PayloadAction<FilterTab>) => {
      state[ContentType.templates][action.payload] = {
        ...filterInitialStateTemplates,
        [action.payload]: action.payload,
      };

      state[ContentType.assets][action.payload] = {
        ...filterInitialStateAssets,
        [action.payload]: action.payload,
      };
    },
    updateContentType: (state, action: PayloadAction<ContentType>) => {
      state.contentType = action.payload;
    },
    updateContentTypeAndMode: (
      state,
      action: PayloadAction<{
        newContentType: ContentType;
        newMode: FilterTab;
      }>,
    ) => {
      state.contentType = action.payload.newContentType;
      state.mode = action.payload.newMode;
    },
    updateFilters: (
      state,
      action: PayloadAction<{
        prop: FilterProp;
        newFilters: FilterOption[] | undefined;
      }>,
    ) => {
      state[state.contentType][state.mode].filters[action.payload.prop] =
        action.payload.newFilters;
    },
    updateMode: (state, action: PayloadAction<FilterTab>) => ({
      // reset all properties when mode is updated
      ...initialState,
      ...state,
      mode: action.payload,
    }),
    updateOrder: (state, action: PayloadAction<string>) => {
      state[state.contentType][state.mode].order = action.payload;
    },
    updateQuery: (state, action: PayloadAction<string>) => {
      state[state.contentType][state.mode].query = action.payload;
    },
  },
});

export const {
  resetFiltersForMode,
  updateContentType,
  updateContentTypeAndMode,
  updateFilters,
  updateMode,
  updateOrder,
  updateQuery,
} = filterSlice.actions;

export const selectCurrentFilter = (state: RootState) =>
  state.filters[state.filters.contentType][state.filters.mode];

export default filterSlice.reducer;
