/**
 * *****************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2022 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Adobe and its suppliers, if any. The intellectual and technical concepts
 * contained herein are proprietary to Adobe and its suppliers and are
 * protected by all applicable intellectual property laws, including trade
 * secret and copyright laws. Dissemination of this information or reproduction
 * of this material is strictly forbidden unless prior written permission is
 * obtained from Adobe.
 * *****************************************************************************
 */

import React, { ReactElement, useCallback } from 'react';

import Toast from 'components/Toast';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import {
  hideToast,
  selectCurrentJob,
  selectIsAutoDismiss,
  selectJobProgress,
  selectShowToast,
} from 'redux/workflowActions/workflowActions.slice';

import styles from './ToastManager.module.scss';

export default function ToastManager(): ReactElement {
  const currentJob = useAppSelector(selectCurrentJob);
  const showToast = useAppSelector(selectShowToast);
  const isAutoDismiss = useAppSelector(selectIsAutoDismiss);
  const progress = useAppSelector(selectJobProgress);
  const dispatch = useAppDispatch();
  const onClose = useCallback(() => {
    dispatch(hideToast());
  }, [dispatch]);

  if (currentJob === undefined || !showToast) {
    return <></>;
  }

  return (
    <div className={styles.toast}>
      <Toast
        isAutoDismiss={isAutoDismiss}
        job={currentJob}
        onClose={onClose}
        progress={progress}
      />
    </div>
  );
}
