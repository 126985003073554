/**
 * *****************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2022 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Adobe and its suppliers, if any. The intellectual and technical concepts
 * contained herein are proprietary to Adobe and its suppliers and are
 * protected by all applicable intellectual property laws, including trade
 * secret and copyright laws. Dissemination of this information or reproduction
 * of this material is strictly forbidden unless prior written permission is
 * obtained from Adobe.
 * *****************************************************************************
 */

import Axios from 'axios';

import Constant from 'model/constant/Constant';

const CP_COMMUNITY_THEO = 'theo';

export interface CPUserProfile {
  id: string;
  displayName: string;
  userName: string;
  profileImageURL: string;
  isCurator?: boolean;
}

const { url } = new Constant();
class CommunityPlatform {
  cpUsersURL: string;
  cpUsersMeURL: string;
  constructor(community: string = CP_COMMUNITY_THEO) {
    this.cpUsersURL = `${url.cpAPIBase}/${community}/users/`;
    this.cpUsersMeURL = `${this.cpUsersURL}me`;
  }
  async fetchUserProfile(adobeId: string): Promise<CPUserProfile> {
    const result: CPUserProfile = {
      id: adobeId,
      displayName: '',
      userName: '',
      profileImageURL:
        'https://a5.behance.net/34afc4e3ddc2a1abaafc9e838a65ed69821c56e9/img/profile/no-image-50.png',
    };
    const response = await Axios.get(this.cpUsersURL + adobeId);

    if (response.data) {
      result.displayName = response.data.display_name;
      result.userName = response.data.user_name;
      result.profileImageURL = response.data._links.images[0].href;
    }

    return result;
  }
  async fetchCuratorStatus(): Promise<Pick<CPUserProfile, 'isCurator'>> {
    // default isCurator value is false
    const result: Pick<Required<CPUserProfile>, 'isCurator'> = {
      isCurator: false,
    };
    const response = await Axios.get(this.cpUsersMeURL);

    if (response.data) {
      result.isCurator = response.data.is_curator;
    }

    return result;
  }
}

export default new CommunityPlatform();
