/**
 * *****************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2022 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Adobe and its suppliers, if any. The intellectual and technical concepts
 * contained herein are proprietary to Adobe and its suppliers and are
 * protected by all applicable intellectual property laws, including trade
 * secret and copyright laws. Dissemination of this information or reproduction
 * of this material is strictly forbidden unless prior written permission is
 * obtained from Adobe.
 * *****************************************************************************
 */

import React, { ReactElement, useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { Flex, View } from '@adobe/react-spectrum';

import TreeView from 'components/@spectrum/TreeView';
import FileRailAndMetadata from 'components/AssetExplorer/FileRailAndMetadata';
import CardView from 'components/CardView';
import DetailView from 'components/DetailView';
import useRepoMetadata from 'hooks/acp/useRepoMetadata';
import useCurrentAssetByPath from 'hooks/useCurrentAssetByPath';
import useDirectory from 'hooks/useDirectory';
import useQuery from 'hooks/useQuery';
import { AssetMimeType } from 'model/acp/AssetMimeType';
import { RepoMetadataKey } from 'model/acp/RepoMetadataKey';
import { DocumentView } from 'model/DocumentView';
import {
  clearDocumentRailPane,
  setDocumentView,
} from 'redux/explorer/explorer.slice';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { NotFoundErrorScreen } from 'screens/errors';

import AssetExplorerHeader from './AssetExplorerHeader';
import CollectionRailAndMetadata from './CollectionRailAndMetadata';
import DocumentActionRail from './DocumentActionRail';

const LOAD_CHUNK_LIMIT = 50;
type AssetExplorerProps = {
  tab: string;
  title: string;
};

export const MetadataFormContext = React.createContext(false);

export default function AssetExplorer({
  tab,
  title,
}: AssetExplorerProps): ReactElement {
  const { pathname } = useLocation();
  const dispatch = useAppDispatch();
  const query = useQuery();
  const navigate = useNavigate();
  const { currentAssetByPath } = useCurrentAssetByPath();
  const { documentView, orderBy, sortOrder } = useAppSelector(
    (state) => state.explorer,
  );
  const { results: repoMetadata, errors: repoMetadataErrors } = useRepoMetadata(
    {
      path: currentAssetByPath,
    },
  );
  // Metadata form collapse logic
  const [isMetadataFormCollapsed, setIsMetadataFormCollapsed] =
    useState<boolean>(false);
  const toggleCollapse = useCallback(() => {
    setIsMetadataFormCollapsed((currentValue) => !currentValue);
  }, [setIsMetadataFormCollapsed]);
  const openMetadataForm = useCallback(() => {
    setIsMetadataFormCollapsed(false);
  }, [setIsMetadataFormCollapsed]);

  useEffect(() => {
    if (!query.has('view')) {
      navigate(`${pathname}?view=${documentView}`, { replace: true });
    } else if (
      ![
        DocumentView.Card,
        DocumentView.Detail,
        DocumentView.Permissions,
      ].includes(query.get('view') as DocumentView)
    ) {
      navigate(`${pathname}?view=${DocumentView.Card}`, { replace: true });
    }

    if (query.get('view') !== documentView) {
      dispatch(setDocumentView(query.get('view') as DocumentView));
    }
  }, [dispatch, documentView, navigate, pathname, query]);

  useEffect(() => {
    dispatch(clearDocumentRailPane());
  }, [currentAssetByPath, dispatch]);

  const {
    errors: directoryErrors,
    directory,
    getNextPage,
    hasNextPage,
    isLoadingMore,
  } = useDirectory({
    path: currentAssetByPath,
    limit: LOAD_CHUNK_LIMIT,
    orderBy,
    sortOrder,
  });
  const errors = (directoryErrors ?? []).concat(repoMetadataErrors ?? []);

  if (errors.length !== 0 && errors[0].code === '404') {
    return <NotFoundErrorScreen />;
  }

  return (
    <Flex width="100%" height="100%">
      <View
        backgroundColor="static-white"
        borderEndColor="static-gray-300"
        borderEndWidth="thin"
        height="100%"
        width="size-4000"
      >
        <TreeView path={tab} title={title} />
      </View>
      <Flex direction="column" flex height="100%">
        <>
          <View paddingX="size-300" paddingTop="size-300">
            <AssetExplorerHeader />
          </View>
          {documentView === DocumentView.Detail ? (
            <DetailView />
          ) : (
            <CardView
              directory={directory}
              getNextPage={getNextPage}
              hasNextPage={hasNextPage}
              isLoadingMore={isLoadingMore}
            />
          )}
        </>
      </Flex>
      {documentView === DocumentView.Detail ? <></> : <DocumentActionRail />}
      {documentView === DocumentView.Detail &&
      repoMetadata &&
      repoMetadata[RepoMetadataKey.DCFormat] !== AssetMimeType.Collection ? (
        <MetadataFormContext.Provider value={isMetadataFormCollapsed}>
          <FileRailAndMetadata
            isMetadataFormCollapsed={isMetadataFormCollapsed}
            onMetadataFormToggle={toggleCollapse}
            onMetadataFormOpen={openMetadataForm}
          />
        </MetadataFormContext.Provider>
      ) : (
        <></>
      )}
      {documentView === DocumentView.Detail &&
      repoMetadata?.[RepoMetadataKey.DCFormat] === AssetMimeType.Collection ? (
        <MetadataFormContext.Provider value={isMetadataFormCollapsed}>
          <CollectionRailAndMetadata
            isMetadataFormCollapsed={isMetadataFormCollapsed}
            onMetadataFormToggle={toggleCollapse}
            onMetadataFormOpen={openMetadataForm}
          />
        </MetadataFormContext.Provider>
      ) : (
        <></>
      )}
    </Flex>
  );
}
