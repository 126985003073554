/**
 * *****************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2022 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Adobe and its suppliers, if any. The intellectual and technical concepts
 * contained herein are proprietary to Adobe and its suppliers and are
 * protected by all applicable intellectual property laws, including trade
 * secret and copyright laws. Dissemination of this information or reproduction
 * of this material is strictly forbidden unless prior written permission is
 * obtained from Adobe.
 * *****************************************************************************
 */

import React, { ReactElement } from 'react';
import { Link } from 'react-router-dom';

import {
  Button,
  Content,
  Flex,
  Heading,
  IllustratedMessage,
  Well,
} from '@adobe/react-spectrum';
import Error from '@spectrum-icons/illustrations/Error';

import styles from '../errors.module.scss';

const HomeLink = React.forwardRef<HTMLAnchorElement>((props, ref) => (
  <Link
    to="/"
    ref={ref}
    // eslint-disable-next-line react/jsx-props-no-spreading
    {...props}
  />
));

type InternalErrorScreenProps = {
  error: Error;
};

export default function InternalErrorScreen({
  error,
}: InternalErrorScreenProps): ReactElement {
  return (
    <Flex
      alignItems="center"
      direction="column"
      height="100%"
      justifyContent="center"
      width="100%"
    >
      <IllustratedMessage
        UNSAFE_className={styles.error}
        height="auto"
        width="size-6000"
      >
        <Error />
        <Heading level={1}>Tempo has suffered an internal error</Heading>
        <Content UNSAFE_className={styles.content} flex>
          <p>
            An internal error occurred and was not properly handled by our
            systems. Try refreshing the page and repeating the action or file a
            support request in the <code>#cclight-tempo-support</code> channel
            on Slack.
          </p>

          <Well
            UNSAFE_style={{
              fontSize: '12px',
              overflow: 'scroll',
            }}
            maxHeight="size-2000"
            minHeight="size-0"
          >
            <code>{error.stack}</code>
          </Well>

          <Button elementType={HomeLink} marginTop="size-200" variant="cta">
            Back to Home
          </Button>
        </Content>
      </IllustratedMessage>
    </Flex>
  );
}
