/**
 * *****************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2022 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Adobe and its suppliers, if any. The intellectual and technical concepts
 * contained herein are proprietary to Adobe and its suppliers and are
 * protected by all applicable intellectual property laws, including trade
 * secret and copyright laws. Dissemination of this information or reproduction
 * of this material is strictly forbidden unless prior written permission is
 * obtained from Adobe.
 * *****************************************************************************
 */

import { AssetStatus } from 'model/acp/AssetStatus';
import { Language } from 'model/Language';

import { AssetMetadataModelKey } from './AssetMetadataModel';
import { CollectionMetadataModel } from './CollectionMetadataModel';

export default class DefaultCollectionMetadataModel
  implements CollectionMetadataModel
{
  public [AssetMetadataModelKey.ApplicableFilters] = {
    [AssetMetadataModelKey.Entitlements]: [],
    [AssetMetadataModelKey.InterestGroups]: [],
    [AssetMetadataModelKey.Restrictions]: [],
  };
  public [AssetMetadataModelKey.ApplicableRegions] = '';
  public [AssetMetadataModelKey.AssetId] = '';
  public [AssetMetadataModelKey.CreatedBy] = undefined;
  public [AssetMetadataModelKey.Language] = undefined;
  public [AssetMetadataModelKey.Name] = '';
  public [AssetMetadataModelKey.Priority] = undefined;
  public [AssetMetadataModelKey.Status] = AssetStatus.Unpublished;
  public [AssetMetadataModelKey.Title] = new Map<Language, string>();
}
