/**
 * *****************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2022 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Adobe and its suppliers, if any. The intellectual and technical concepts
 * contained herein are proprietary to Adobe and its suppliers and are
 * protected by all applicable intellectual property laws, including trade
 * secret and copyright laws. Dissemination of this information or reproduction
 * of this material is strictly forbidden unless prior written permission is
 * obtained from Adobe.
 * *****************************************************************************
 */

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { CollectionQueryModel } from 'model/acp/CollectionQueryModel';

export interface QueryModel {
  savedQueryModel: CollectionQueryModel | undefined;
  editedQueryModel: CollectionQueryModel | undefined;
}

export const initialState: QueryModel = {
  savedQueryModel: undefined,
  editedQueryModel: undefined,
};

export const queryModelSlice = createSlice({
  name: 'queryModel',
  initialState,
  reducers: {
    updateEditedQuery: (
      state,
      action: PayloadAction<CollectionQueryModel | undefined>,
    ) => {
      state.editedQueryModel = action.payload;
    },
    updateSavedQuery: (
      state,
      action: PayloadAction<CollectionQueryModel | undefined>,
    ) => {
      state.savedQueryModel = action.payload;
    },
    updateEditedAndSavedQuery: (
      state,
      action: PayloadAction<CollectionQueryModel | undefined>,
    ) => {
      state.savedQueryModel = action.payload;
      state.editedQueryModel = action.payload;
    },
  },
});

export const {
  updateSavedQuery,
  updateEditedQuery,
  updateEditedAndSavedQuery,
} = queryModelSlice.actions;

export default queryModelSlice.reducer;
