/**
 * *****************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2022 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Adobe and its suppliers, if any. The intellectual and technical concepts
 * contained herein are proprietary to Adobe and its suppliers and are
 * protected by all applicable intellectual property laws, including trade
 * secret and copyright laws. Dissemination of this information or reproduction
 * of this material is strictly forbidden unless prior written permission is
 * obtained from Adobe.
 * *****************************************************************************
 */

import { useEffect, useState } from 'react';

import {
  CCXAssetCollectionFile,
  CCXTemplateCollectionFile,
  CollectionFile,
  StockCollectionFile,
} from 'model/acp/CollectionFile';
import { CollectionQueryModel } from 'model/acp/CollectionQueryModel';
import { CollectionSource } from 'model/acp/CollectionSource';
import { Language } from 'model/Language';

import useFileData from './useFileData';

type SingleFileHookProps = {
  paths?: never;
  path: string;
  enabled?: boolean;
};

type CollectionQueryModelHookResult = {
  results?: CollectionQueryModel;
  file?: CollectionFile;
  isLoading: boolean;
  hash?: string;
};

function getCollectionSource(
  fileData: CollectionFile,
): CollectionSource | undefined {
  if ('ASSET' in fileData) {
    return CollectionSource.CCXAsset;
  }

  if ('TEMPLATE' in fileData) {
    return CollectionSource.CCXTemplate;
  }

  if ('STOCK' in fileData) {
    return CollectionSource.Stock;
  }

  return undefined;
}

export function mapCollectionQueryModel(
  fileData: CollectionFile,
): CollectionQueryModel {
  const source = getCollectionSource(fileData);
  let collectionQueryModel: CollectionQueryModel;
  let collectionData;

  switch (source) {
    case CollectionSource.CCXAsset:
      collectionData = fileData.ASSET as CCXAssetCollectionFile;
      collectionQueryModel = {
        [CollectionSource.CCXAsset]: {
          assetIds: new Set<string>(collectionData.include.id),
        },
        source,
      };
      break;

    case CollectionSource.CCXTemplate:
      collectionData = fileData.TEMPLATE as CCXTemplateCollectionFile;
      collectionQueryModel = {
        [CollectionSource.CCXTemplate]: {
          tasks: collectionData.tasks,
          topics: collectionData.topics,
        },
        source,
      };
      break;

    case CollectionSource.Stock:
      collectionData = fileData.STOCK as StockCollectionFile;
      collectionQueryModel = {
        [CollectionSource.Stock]: {
          query: collectionData.q
            ? Object.entries(collectionData.q).reduce(
                (map, [language, translation]) => {
                  map.set(language as Language, translation as string);

                  return map;
                },
                new Map<Language, string>(),
              )
            : undefined,
          externalCollectionId: collectionData.externalCollectionId,
        },
        source,
      };
      break;

    default:
      collectionQueryModel = {
        source,
      };
  }

  return collectionQueryModel;
}

export default function useCollectionQueryModel({
  path,
  enabled = true,
}: SingleFileHookProps): CollectionQueryModelHookResult {
  const [results, setResults] = useState<CollectionQueryModelHookResult>({
    isLoading: true,
    hash: undefined,
    results: undefined,
    file: undefined,
  });
  const { results: collectionFile } = useFileData<CollectionFile>({
    path,
    enabled,
  });

  useEffect(() => {
    if (collectionFile?.data !== undefined) {
      setResults({
        isLoading: false,
        hash: collectionFile.hash,
        results: mapCollectionQueryModel(collectionFile.data),
        file: collectionFile.data,
      });
    }
  }, [collectionFile]);

  return results;
}
