/**
 * *****************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2022 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Adobe and its suppliers, if any. The intellectual and technical concepts
 * contained herein are proprietary to Adobe and its suppliers and are
 * protected by all applicable intellectual property laws, including trade
 * secret and copyright laws. Dissemination of this information or reproduction
 * of this material is strictly forbidden unless prior written permission is
 * obtained from Adobe.
 * *****************************************************************************
 */

import React, { CSSProperties, ReactChild, ReactElement } from 'react';

import { Text } from '@adobe/react-spectrum';

type LabelProps = {
  UNSAFE_className?: string;
  UNSAFE_style?: CSSProperties;
  children: ReactChild | ReactChild[];
  color:
    | 'grey'
    | 'red'
    | 'orange'
    | 'yellow'
    | 'seafoam'
    | 'green'
    | 'blue'
    | 'fuchsia';
};

export default function Label({
  UNSAFE_className,
  UNSAFE_style,
  children,
  color,
}: LabelProps): ReactElement {
  let baseStyles = `spectrum-Label spectrum-Label--${color}`;

  if (UNSAFE_className) {
    baseStyles += ` ${UNSAFE_className}`;
  }

  return (
    <Text UNSAFE_className={baseStyles} UNSAFE_style={UNSAFE_style}>
      {children}
    </Text>
  );
}

Label.defaultProps = {
  UNSAFE_className: undefined,
  UNSAFE_style: undefined,
};
