/**
 * *****************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2022 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Adobe and its suppliers, if any. The intellectual and technical concepts
 * contained herein are proprietary to Adobe and its suppliers and are
 * protected by all applicable intellectual property laws, including trade
 * secret and copyright laws. Dissemination of this information or reproduction
 * of this material is strictly forbidden unless prior written permission is
 * obtained from Adobe.
 * *****************************************************************************
 */

import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';

import Axios from 'axios';

import useConstant from 'hooks/useConstant';
import { AdobeIMSProfile } from 'model/ims/AdobeIMSProfile';

type UseBehanceUserProfileProps = {
  imsProfile: AdobeIMSProfile | undefined;
  enabled?: boolean;
};

type UseBehanceUserProfileResult = {
  results: BehanceProfile | undefined;
  isLoading: boolean;
};

export type BehanceProfile = {
  adobeId: string;
  displayName: string;
  email: string;
  images: {
    '100px': string;
    '115px': string;
    '138px': string;
    '230px': string;
    '276px': string;
    '50px': string;
  };
};

type BehanceUserProfileData = {
  adobe_id: string;
  display_name: string;
  images: {
    100: string;
    115: string;
    138: string;
    230: string;
    276: string;
    50: string;
  };
};

type BehanceUserResponse = {
  user: BehanceUserProfileData;
  http_code: string;
};

function mapBehanceUserResponseToProfile(
  source: BehanceUserProfileData,
  imsProfile: AdobeIMSProfile,
): BehanceProfile {
  return {
    adobeId: source.adobe_id,
    displayName: source.display_name,
    email: imsProfile.email,
    images: {
      '100px': source.images[100],
      '115px': source.images[115],
      '138px': source.images[138],
      '230px': source.images[230],
      '276px': source.images[276],
      '50px': source.images[50],
    },
  };
}

export function useBehanceUserProfile({
  imsProfile,
  enabled = true,
}: UseBehanceUserProfileProps): UseBehanceUserProfileResult {
  // Configuration hooks
  const { behanceBaseUrl, clientId } = useConstant();
  // Behance requests
  const [result, setResult] = useState<UseBehanceUserProfileResult>({
    results: undefined,
    isLoading: true,
  });
  const requestUrl = `${behanceBaseUrl}/users/${imsProfile?.userId}?api_key=${clientId}`;
  const GETQuery = async (): Promise<BehanceUserResponse> => {
    const response = await Axios.get(requestUrl);

    return response.data;
  };
  const { data, isLoading } = useQuery<BehanceUserResponse>(
    ['behanceUserProfile', imsProfile?.userId],
    GETQuery,
    {
      /**
       * Disable the ReactQuery query call if this function is run but the links
       * haven't loaded, because there will be no requestUrl and no reason to
       * make the request.
       */
      enabled,
      refetchOnWindowFocus: false,
    },
  );

  useEffect((): void => {
    if (data && imsProfile) {
      setResult({
        results: mapBehanceUserResponseToProfile(data.user, imsProfile),
        isLoading,
      });
    }
  }, [data, isLoading, imsProfile]);

  return result;
}
