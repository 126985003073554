/**
 * *****************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2022 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Adobe and its suppliers, if any. The intellectual and technical concepts
 * contained herein are proprietary to Adobe and its suppliers and are
 * protected by all applicable intellectual property laws, including trade
 * secret and copyright laws. Dissemination of this information or reproduction
 * of this material is strictly forbidden unless prior written permission is
 * obtained from Adobe.
 * *****************************************************************************
 */

import React, { ReactElement, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import AssetExplorer from 'components/AssetExplorer';
import useConstant from 'hooks/useConstant';
import { ScreenTitle } from 'model/ScreenTitle';
import { clearSelectedAssetPaths } from 'redux/explorer/explorer.slice';
import { useAppDispatch } from 'redux/hooks';

export default function ViewLegacySubmitAssetsScreen(): ReactElement {
  const { acpBasePath } = useConstant();
  const { pathname } = useLocation();
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(clearSelectedAssetPaths());
  }, [pathname, dispatch]);

  return (
    <AssetExplorer tab={acpBasePath.submit} title={ScreenTitle.AssetReview} />
  );
}
