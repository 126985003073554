/**
 * *****************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2022 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Adobe and its suppliers, if any. The intellectual and technical concepts
 * contained herein are proprietary to Adobe and its suppliers and are
 * protected by all applicable intellectual property laws, including trade
 * secret and copyright laws. Dissemination of this information or reproduction
 * of this material is strictly forbidden unless prior written permission is
 * obtained from Adobe.
 * *****************************************************************************
 */

import { AssetStatus } from 'model/acp/AssetStatus';
import { Language as LanguageModel } from 'model/Language';

export const enum AssetMetadataModelKey {
  ApplicableFilters = 'applicableFilters',
  Album = 'album',
  Animated = 'animated',
  ApplicableRegions = 'applicableRegions',
  AssetId = 'assetId',
  BPM = 'bpm',
  Creators = 'creators',
  CreatedBy = 'createdBy',
  Genres = 'genres',
  Hero = 'hero',
  IsInstrumental = 'isInstrumental',
  Language = 'language',
  LicensingCategory = 'licensingCategory',
  Name = 'name',
  Priority = 'priority',
  PublishedAssetId = 'publishedAssetId',
  PublishedHash = 'publishedHash',
  PublishedVersion = 'publishedVersion',
  Status = 'status',
  SubmittedBy = 'submittedBy',
  Tasks = 'tasks',
  Title = 'title',
  Topics = 'topics',
  Traits = 'traits',
  Vendor = 'vendor',

  // Applicable filters
  Entitlements = 'entitlements',
  Restrictions = 'restrictions',
  InterestGroups = 'interestGroups',
}

export type ApplicableFilters = {
  [AssetMetadataModelKey.Entitlements]: string[];
  [AssetMetadataModelKey.Restrictions]: string[];
  [AssetMetadataModelKey.InterestGroups]: string[];
};

export type AssetMetadataModel = {
  readonly [AssetMetadataModelKey.AssetId]: string;
  readonly [AssetMetadataModelKey.CreatedBy]: string | undefined;
  [AssetMetadataModelKey.ApplicableFilters]: ApplicableFilters;
  [AssetMetadataModelKey.ApplicableRegions]: string | undefined;
  [AssetMetadataModelKey.Language]: string | undefined;
  [AssetMetadataModelKey.Name]: string;
  [AssetMetadataModelKey.Status]: AssetStatus;
  [AssetMetadataModelKey.Title]: Map<LanguageModel, string> | undefined;
};
