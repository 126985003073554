/**
 * *****************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2022 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Adobe and its suppliers, if any. The intellectual and technical concepts
 * contained herein are proprietary to Adobe and its suppliers and are
 * protected by all applicable intellectual property laws, including trade
 * secret and copyright laws. Dissemination of this information or reproduction
 * of this material is strictly forbidden unless prior written permission is
 * obtained from Adobe.
 * *****************************************************************************
 */

import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';

import Axios, { AxiosError } from 'axios';

import useACPLink, { ACPError } from 'hooks/acp/useACPLink';
import { ACPLink } from 'model/acp/ACPLink';
import { CCXPrivateConfig, CCXPublicConfig } from 'model/ccxConfig/CCXConfig';

import useConstant from './useConstant';

enum CCXConfigVariant {
  Private = 'private',
  Public = 'public',
}

type UseCCXPrivateConfigHookProps = {
  enabled: boolean;
  variant: CCXConfigVariant.Private;
};

type UseCCXPublicConfigHookProps = {
  enabled: boolean;
  variant: CCXConfigVariant.Public;
};

type UseCCXConfigHookProps = {
  enabled: boolean;
  variant: CCXConfigVariant;
};

type UseCCXConfigHookResult<T> = {
  errors: ACPError[] | undefined;
  isLoading: boolean;
  results: T | undefined;
};

/**
 * Use a 10 minute stale time. Shouldn't change very often.
 */
const QUERY_STALE_TIME = 60000;

function useCCXConfig(
  args: UseCCXPrivateConfigHookProps,
): UseCCXConfigHookResult<CCXPrivateConfig>;
function useCCXConfig(
  args: UseCCXPublicConfigHookProps,
): UseCCXConfigHookResult<CCXPublicConfig>;
function useCCXConfig({
  variant,
  enabled,
}: UseCCXConfigHookProps): UseCCXConfigHookResult<
  CCXPrivateConfig | CCXPublicConfig
> {
  const [result, setResult] = useState<
    UseCCXConfigHookResult<CCXPrivateConfig | CCXPublicConfig>
  >({
    errors: undefined,
    isLoading: true,
    results: undefined,
  });
  const { ccxConfigPath } = useConstant();
  const configPath =
    variant === CCXConfigVariant.Private
      ? ccxConfigPath.private
      : ccxConfigPath.public;
  const {
    isLoading: linkIsLoading,
    results,
    errors: acpLinkErrors,
  } = useACPLink({
    acpLink: ACPLink.Primary,
    enabled,
    path: configPath,
    options: {
      retries: 0,
    },
  });
  const requestUrl = results?.linkUrl ?? '';
  const GETQuery = async (): Promise<CCXPrivateConfig | CCXPublicConfig> => {
    const response = await Axios.get(requestUrl);

    return response.data;
  };
  const { data, isLoading, error } = useQuery<
    CCXPrivateConfig | CCXPublicConfig
  >(['ccxConfig', configPath], GETQuery, {
    /**
     * Disable the ReactQuery query call if this function is run but the links
     * haven't loaded, because there will be no requestUrl and no reason to
     * make the request.
     */
    enabled: !linkIsLoading && !!requestUrl && enabled,
    staleTime: QUERY_STALE_TIME,
    refetchOnWindowFocus: false,
    retry: false,
  });

  useEffect((): void => {
    if (data) {
      setResult({
        errors: [],
        isLoading,
        results: data,
      });
    } else if (acpLinkErrors) {
      setResult({
        errors: acpLinkErrors,
        isLoading: false,
        results: undefined,
      });
    } else if (error) {
      const err = error as AxiosError;

      setResult({
        errors: [
          {
            code: err.code ?? '',
            message: err.message,
          },
        ],
        isLoading: false,
        results: undefined,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [linkIsLoading, data, error, isLoading]);

  return result;
}

export function useCCXPrivateConfig({
  enabled = true,
}: { enabled?: boolean } = {}) {
  return useCCXConfig({ variant: CCXConfigVariant.Private, enabled });
}

export function useCCXPublicConfig({
  enabled = true,
}: { enabled?: boolean } = {}) {
  return useCCXConfig({ variant: CCXConfigVariant.Public, enabled });
}
