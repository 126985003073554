/**
 * *****************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2022 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Adobe and its suppliers, if any. The intellectual and technical concepts
 * contained herein are proprietary to Adobe and its suppliers and are
 * protected by all applicable intellectual property laws, including trade
 * secret and copyright laws. Dissemination of this information or reproduction
 * of this material is strictly forbidden unless prior written permission is
 * obtained from Adobe.
 * *****************************************************************************
 */

import React, { useEffect, useReducer } from 'react';

import { Heading } from '@adobe/react-spectrum';
import { orderBy } from 'lodash';

import SearchBar from '__DEPRECATED_DO_NOT_USE_OR_YOU_WILL_BE_FIRED/components/SearchBar';
import { SparkSearchAggs } from '__DEPRECATED_DO_NOT_USE_OR_YOU_WILL_BE_FIRED/controllers/api/SparkSearch';
import { FilterProp } from '__DEPRECATED_DO_NOT_USE_OR_YOU_WILL_BE_FIRED/controllers/SearchParamsBuilder/types';
import {
  ContentType,
  FilterOption,
  FilterTab,
} from '__DEPRECATED_DO_NOT_USE_OR_YOU_WILL_BE_FIRED/redux/filter/filter.slice';
import {
  MetadataOption,
  MetadataOptions,
} from '__DEPRECATED_DO_NOT_USE_OR_YOU_WILL_BE_FIRED/redux/metadata/metadata.types';

import { useCCXPublicConfig } from 'hooks/useCCXConfig';
import useFilterState from 'hooks/useFilterState';
import { useAppSelector } from 'redux/hooks';
import { TempoOwnersMap } from 'redux/tempo/tempo.slice';
import { CPUserRole } from 'redux/user/user.slice';

import FilterSelectList from './FilterSelectList';
import { filterGroupConstants } from './model/constants';
import filterGroupOptionsReducer, {
  FilterGroup,
  FilterGroupOption,
  initialState as filterGroupOptionsInitialState,
  updateCategoryOptions,
  updateLocales,
  updateOptionsFromAggs,
  updateOwnerOptions,
  updateTaskOptions,
} from './model/filterGroupOptions.reducer';
import OrderSelectList from './OrderSelectList';

import styles from './FilterBar.module.scss';

function getFilterGroupOrder(
  contentType: ContentType,
  mode: FilterTab,
  role: CPUserRole,
): FilterGroup[] {
  let groupOrder: FilterGroup[];

  if (contentType === ContentType.assets && mode === FilterTab.search) {
    groupOrder = [
      'contentType',
      'directory',
      'licensingCategory',
      'animated',
      'topics',
      'language',
    ];
  } else if (mode === FilterTab.review) {
    /*
     * public user roles do not need to see a list of all owners
     * because they will only be able to see their own unapproved assets
     */
    if (role === CPUserRole.public) {
      groupOrder = ['premium', 'animated', 'categories', 'tasks', 'locales'];
    } else {
      groupOrder = [
        'premium',
        'animated',
        'owner',
        'categories',
        'tasks',
        'locales',
      ];
    }
  } else {
    groupOrder = [
      'premium',
      'animated',
      'categories',
      'tasks',
      'owner',
      'applicableRegions',
      'locales',
    ];
  }

  return groupOrder;
}

type FilterBarProps = {
  title: string;
};

const FilterBar: React.FC<FilterBarProps> = ({ title }: FilterBarProps) => {
  const { results: ccxPublicConfig, isLoading } = useCCXPublicConfig();
  const { filters, query, contentType, mode } = useFilterState();
  const role: CPUserRole = useAppSelector((state) => state.user.cpRole);
  const ownersMap: TempoOwnersMap = useAppSelector(
    (state) => state.tempo.ownersMap,
  );
  const aggs: SparkSearchAggs = useAppSelector((state) => state.assets.aggs);
  const groupOrder = getFilterGroupOrder(contentType, mode, role);
  const [state, dispatch] = useReducer(
    filterGroupOptionsReducer,
    filterGroupOptionsInitialState,
  );

  useEffect(() => {
    if (contentType === ContentType.templates) {
      if (mode === FilterTab.search) {
        dispatch(
          updateLocales(
            MetadataOptions.LANGUAGES_FOR_SEARCH.map(
              (option: MetadataOption) => ({
                label: option.label,
                value: option.value,
                prop: 'locales',
                isExactMatch: true,
              }),
            ),
          ),
        );
      } else {
        dispatch(
          updateCategoryOptions(
            MetadataOptions.CATEGORIES.map((option: MetadataOption) => ({
              label: option.label,
              value: option.value,
              prop: 'categories',
              isExactMatch: true,
            })),
          ),
        );

        dispatch(
          updateLocales(
            MetadataOptions.LANGUAGES_FOR_REVIEW.map(
              (option: MetadataOption) => ({
                label: option.label,
                value: option.value,
                prop: 'locales',
                isExactMatch: true,
              }),
            ),
          ),
        );
      }
    } else {
      dispatch(updateCategoryOptions([]));
    }
  }, [contentType, mode]);

  useEffect(() => {
    if (ccxPublicConfig) {
      dispatch(updateTaskOptions(ccxPublicConfig.template.tasks.options));
    }
  }, [ccxPublicConfig, isLoading]);

  /*
   * update options for the 'owner' group
   * all owner data is saved in the global ownersMap
   */
  useEffect(() => {
    let options: FilterGroupOption[] = [];
    let map: Record<string, { key: string; docCount: number } | undefined> = {};

    /*
     * In the review tab, we don't have access to properly indexed designers for
     * unapproved assets (unapproved assets aren't indexed by search). As a result,
     * we can't actually show the list of designers with unapproved assets. Instead,
     * we first display the list of designers who match the locally loaded unapproved
     * assets followed by an alphabetical list of all designers who have previously
     * submitted approved assets. Not all of them will have assets, but it's better
     * than having to manually page to find new designers.
     */
    if (mode === FilterTab.review) {
      map = ownersMap.designers;
    } else {
      // display all owner options
      map = { ...ownersMap.designers, ...ownersMap.others };
    }

    // convert ownersMap to FilterGroupOption[]
    for (const id of Object.keys(map)) {
      if (map[id] !== undefined) {
        options.push({
          count: map[id]?.docCount,
          isExactMatch: true,
          label: map[id]?.key ?? '',
          prop: 'owner',
          value: id,
        });
      }
    }

    options = orderBy(options, ['count', 'label'], ['desc', 'asc']);

    dispatch(updateOwnerOptions(options));
  }, [mode, ownersMap]);

  /*
   * update options using aggregations returned from the Spark Search Service
   * this is only relevant for the Search tab
   */
  useEffect(() => {
    if (mode === FilterTab.search) {
      dispatch(updateOptionsFromAggs(aggs));
    }
  }, [mode, aggs]);

  return (
    <div className={styles.filterBar}>
      <Heading
        UNSAFE_style={{
          fontWeight: 900,
          textTransform: 'capitalize',
        }}
        level={2}
        marginTop="size-65"
        marginBottom="size-0"
        marginX="size-250"
      >
        {title}
      </Heading>

      {mode === FilterTab.search ? <SearchBar defaultValue={query} /> : null}
      <OrderSelectList />
      {groupOrder.map((group: FilterGroup) => {
        let selectedOptions: FilterGroupOption[] = [];
        const options: FilterGroupOption[] = state[group];
        const prop: FilterProp = group;
        const filterOptions: FilterOption[] | undefined = filters[prop];

        if (filterOptions?.length) {
          if (filterOptions[0].value === '*') {
            // count?
            selectedOptions.push({
              ...filterOptions[0],
              prop,
            });
          } else {
            selectedOptions = filterOptions.reduce<FilterGroupOption[]>(
              (memo, option) => {
                if (option.isDefault) {
                  memo.push({
                    ...option,
                    prop,
                  });
                }

                return memo;
              },
              [],
            );

            if (options.length) {
              selectedOptions = state[group].reduce((memo, thang) => {
                if (
                  filterOptions.find((option) => option.value === thang.value)
                ) {
                  memo.push(thang as FilterGroupOption);
                }

                return memo;
              }, selectedOptions);
            }
          }
        }

        return (
          <FilterSelectList
            key={group}
            groupName={group}
            info={filterGroupConstants[group]}
            options={options}
            selectedOptions={selectedOptions}
          />
        );
      })}
    </div>
  );
};

export default FilterBar;
