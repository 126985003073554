/**
 * *****************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2022 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Adobe and its suppliers, if any. The intellectual and technical concepts
 * contained herein are proprietary to Adobe and its suppliers and are
 * protected by all applicable intellectual property laws, including trade
 * secret and copyright laws. Dissemination of this information or reproduction
 * of this material is strictly forbidden unless prior written permission is
 * obtained from Adobe.
 * *****************************************************************************
 */

import React, { ReactElement, useCallback } from 'react';

import { Checkbox, Divider, Flex, View } from '@adobe/react-spectrum';

import useWorkflow from 'hooks/useWorkflow';
import { ChildAsset } from 'model/acp/ChildAsset';
import Directory from 'model/acp/Directory';
import { RepoMetadataKey } from 'model/acp/RepoMetadataKey';
import { AssetActionDomain } from 'model/app/AssetActionDomain';
import {
  clearSelectedAssetPaths,
  setSelectedAssetPaths,
} from 'redux/explorer/explorer.slice';
import { useAppDispatch, useAppSelector } from 'redux/hooks';

import SortingMenu from './SortingMenu';
import TotalAssets from './TotalAssets';

type Props = {
  directory: Directory | undefined;
  hasNextPage: boolean | undefined;
  isLoading: boolean;
};

export default function CardViewHeader({
  directory,
  hasNextPage,
  isLoading,
}: Props): ReactElement {
  const dispatch = useAppDispatch();
  const { selectedAssetPaths } = useAppSelector((state) => state.explorer);
  const { selectableAssets } = useWorkflow({
    actionDomain: AssetActionDomain.Bulk,
    actionContext: 'folder',
  });
  const possibleSelections = directory?.children.reduce<string[]>(
    (accumulator: string[], childAsset: ChildAsset): string[] => {
      if (selectableAssets.has(childAsset[RepoMetadataKey.AssetClass])) {
        accumulator.push(childAsset[RepoMetadataKey.Path]);
      }

      return accumulator;
    },
    [],
  );
  const setIsAllSelected = useCallback(
    (shouldSelectAll: boolean) => {
      if (shouldSelectAll) {
        dispatch(setSelectedAssetPaths(new Set<string>(possibleSelections)));
      } else {
        dispatch(clearSelectedAssetPaths());
      }
    },
    [dispatch, possibleSelections],
  );
  const isAllSelected: boolean =
    possibleSelections !== undefined &&
    possibleSelections.length > 0 &&
    possibleSelections.length === selectedAssetPaths.size;

  return (
    <Flex width="100%" direction="column">
      <Flex alignItems="center" width="100%">
        <View paddingStart="size-100" marginEnd="size-100" width="size-200">
          <Checkbox
            aria-label="Select all"
            isEmphasized
            isIndeterminate={selectedAssetPaths.size > 0 && !isAllSelected}
            isSelected={isAllSelected}
            onChange={setIsAllSelected}
          />
        </View>
        <View>
          <SortingMenu />
        </View>
        {isLoading ? (
          <></>
        ) : (
          <TotalAssets directory={directory} hasNextPage={hasNextPage} />
        )}
        <View flex />
      </Flex>
      <Divider size="S" />
    </Flex>
  );
}
