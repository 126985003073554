/**
 * *****************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2022 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Adobe and its suppliers, if any. The intellectual and technical concepts
 * contained herein are proprietary to Adobe and its suppliers and are
 * protected by all applicable intellectual property laws, including trade
 * secret and copyright laws. Dissemination of this information or reproduction
 * of this material is strictly forbidden unless prior written permission is
 * obtained from Adobe.
 * *****************************************************************************
 */

import React, { ReactElement } from 'react';

import { ToggleButton as SpectrumToggleButton } from '@adobe/react-spectrum';

type Props = {
  children: ReactElement | ReactElement[];
  isDisabled?: boolean;
  isSelected?: boolean;
  onChange: (newValue: boolean) => void;
};

/**
 * This component exists just to encapsulate the same styles for the toggle
 * button.  Unfortunately, the inner left padding of the spectrum component is 0
 * when there is an icon used.  So I have to work around this.
 */
export default function ToggleButton({
  isDisabled,
  isSelected,
  onChange,
  children,
}: Props): ReactElement {
  return (
    <SpectrumToggleButton
      isDisabled={isDisabled}
      isEmphasized
      isSelected={isSelected}
      onChange={onChange}
      UNSAFE_style={{ paddingLeft: '8px', paddingRight: '8px' }}
    >
      {children}
    </SpectrumToggleButton>
  );
}

ToggleButton.defaultProps = {
  isDisabled: false,
  isSelected: false,
};
