/**
 * *****************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2022 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Adobe and its suppliers, if any. The intellectual and technical concepts
 * contained herein are proprietary to Adobe and its suppliers and are
 * protected by all applicable intellectual property laws, including trade
 * secret and copyright laws. Dissemination of this information or reproduction
 * of this material is strictly forbidden unless prior written permission is
 * obtained from Adobe.
 * *****************************************************************************
 */

import { useCallback, useEffect, useMemo, useState } from 'react';
import { useQuery } from 'react-query';

import Axios, { AxiosResponse } from 'axios';

import { RepoMetadataKey } from 'model/acp/RepoMetadataKey';

import useRepoMetadata from './acp/useRepoMetadata';
import useConstant from './useConstant';

type FileData<T> = {
  data: T | undefined;
  hash: string;
};
type SingleFileHookResult<T> = {
  results?: FileData<T>;
  isLoading: boolean;
};
type SingleFileHookProps = {
  path: string;
  enabled?: boolean;
};

/**
 * Use a 1 minute stale time. This improves the navigating experience through
 * the Card view.
 */
const QUERY_STALE_TIME = 60000;

function useFileData<T>(args: SingleFileHookProps): SingleFileHookResult<T>;
function useFileData<T>({
  path,
  enabled = true,
}: SingleFileHookProps): SingleFileHookResult<T> {
  const [results, setResults] = useState<SingleFileHookResult<T>>({
    results: undefined,
    isLoading: true,
  });
  const { url } = useConstant();
  const { isLoading: isLoadingRepoMetadata, results: repoMetadata } =
    useRepoMetadata({
      path,
      enabled,
    });
  const assetId: string = useMemo(
    () => repoMetadata?.[RepoMetadataKey.AssetId] ?? '',
    [repoMetadata],
  );
  const GETQuery = useCallback(
    async (): Promise<AxiosResponse<T>> =>
      Axios.get<T>(`${url.platformStorageBase}/id/${assetId}`),
    [assetId, url.platformStorageBase],
  );
  const { data } = useQuery<AxiosResponse<T>>(['fileData', path], GETQuery, {
    enabled: enabled && !isLoadingRepoMetadata && assetId !== '',
    staleTime: QUERY_STALE_TIME,
  });

  useEffect(() => {
    if (data) {
      setResults({
        isLoading: false,
        results: {
          data: data.data,
          hash: data.headers.etag,
        },
      });
    }
  }, [data]);

  return results;
}

export default useFileData;
