/**
 * *****************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2022 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Adobe and its suppliers, if any. The intellectual and technical concepts
 * contained herein are proprietary to Adobe and its suppliers and are
 * protected by all applicable intellectual property laws, including trade
 * secret and copyright laws. Dissemination of this information or reproduction
 * of this material is strictly forbidden unless prior written permission is
 * obtained from Adobe.
 * *****************************************************************************
 */

import { MetadataFormWarning } from 'components/AssetMetadataForm/DefaultMetadataFormLayout';
import { CollectionFile } from 'model/acp/CollectionFile';
import {
  CCXAssetCollectionQuery,
  CCXTemplateCollectionQuery,
  CollectionQueryModel,
  StockCollectionQuery,
} from 'model/acp/CollectionQueryModel';
import { CollectionSource } from 'model/acp/CollectionSource';

function verifyCCXAssetCollectionQueryModel(
  queryModel: CCXAssetCollectionQuery | undefined,
): MetadataFormWarning[] {
  const warnings: MetadataFormWarning[] = [];
  const hasEmptyAssetIds = queryModel?.assetIds.size === 0;

  if (hasEmptyAssetIds) {
    warnings.push({
      color: 'orange',
      label: 'Empty Collection',
      tooltip:
        'This curated CCX collection does not have any assets. This is not recommended.',
    });
  }

  return warnings;
}

function verifyCCXTemplateCollectionQueryModel(
  queryModel: CCXTemplateCollectionQuery | undefined,
): MetadataFormWarning[] {
  const warnings: MetadataFormWarning[] = [];
  const hasEmptyTemplateQuery =
    (!queryModel?.tasks || queryModel.tasks.length === 0) &&
    (!queryModel?.topics || queryModel.topics.length === 0);

  if (hasEmptyTemplateQuery) {
    warnings.push({
      color: 'orange',
      label: 'Empty Collection',
      tooltip:
        'This dynamic CCX template collection does not have a query to populate the collection. This is not recommended.',
    });
  }

  return warnings;
}

function verifyStockCollectionModel(
  queryModel: StockCollectionQuery | undefined,
): MetadataFormWarning[] {
  const warnings: MetadataFormWarning[] = [];
  const hasEmptyStockQuery =
    !queryModel?.externalCollectionId &&
    (!queryModel?.query || queryModel.query.size === 0);

  if (hasEmptyStockQuery) {
    warnings.push({
      color: 'orange',
      label: 'Empty Collection',
      tooltip:
        'This Adobe Stock collection does not have a query to populate the collection. This is not recommended.',
    });
  }

  return warnings;
}

export function verifyCollectionQueryModel(
  queryModel: CollectionQueryModel | undefined,
): MetadataFormWarning[] {
  let warnings: MetadataFormWarning[] = [];

  if (!queryModel) {
    return warnings;
  }

  if (queryModel.source === CollectionSource.CCXAsset) {
    warnings = warnings.concat(
      verifyCCXAssetCollectionQueryModel(queryModel[CollectionSource.CCXAsset]),
    );
  }

  if (queryModel.source === CollectionSource.CCXTemplate) {
    warnings = warnings.concat(
      verifyCCXTemplateCollectionQueryModel(
        queryModel[CollectionSource.CCXTemplate],
      ),
    );
  }

  if (queryModel.source === CollectionSource.Stock) {
    warnings = warnings.concat(
      verifyStockCollectionModel(queryModel[CollectionSource.Stock]),
    );
  }

  return warnings;
}

export function fixCollectionFile(fileData: CollectionFile): CollectionFile {
  // eslint-disable-next-line deprecation/deprecation
  if (fileData.Stock !== undefined) {
    // eslint-disable-next-line deprecation/deprecation
    fileData.STOCK = fileData.Stock;
    // eslint-disable-next-line deprecation/deprecation
    delete fileData.Stock;

    return fileData;
  }

  throw new Error('No fixable errors were found in the collection');
}

export function verifyCollectionFile(
  fileData: CollectionFile | undefined,
): string[] {
  const errors: string[] = [];

  if (!fileData) {
    return errors;
  }

  // eslint-disable-next-line deprecation/deprecation
  if (fileData.Stock) {
    errors.push('Stock collection uses legacy keyword.');
  }

  return errors;
}
