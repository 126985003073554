/**
 * *****************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2022 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Adobe and its suppliers, if any. The intellectual and technical concepts
 * contained herein are proprietary to Adobe and its suppliers and are
 * protected by all applicable intellectual property laws, including trade
 * secret and copyright laws. Dissemination of this information or reproduction
 * of this material is strictly forbidden unless prior written permission is
 * obtained from Adobe.
 * *****************************************************************************
 */

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from 'redux/store';

export enum PopoverMenu {
  ActionsComplete = 'actions:complete',
  ActionsInProgress = 'actions:in-progress',
  None = 'none',
  Profile = 'profile',
}

export interface AlertState {
  openMenu: PopoverMenu;
}

const initialState: AlertState = {
  openMenu: PopoverMenu.None,
};

export const headerMenu = createSlice({
  name: 'header',
  initialState,
  reducers: {
    closeMenu: (state) => {
      state.openMenu = PopoverMenu.None;
    },
    openMenu: (state, action: PayloadAction<PopoverMenu>) => {
      state.openMenu = action.payload;
    },
    toggleMenu: (state, action: PayloadAction<PopoverMenu>) => {
      if (state.openMenu === action.payload) {
        state.openMenu = PopoverMenu.None;
      } else {
        state.openMenu = action.payload;
      }
    },
  },
});

export const { closeMenu, openMenu, toggleMenu } = headerMenu.actions;

export const selectOpenMenu = (state: RootState) => state.header.openMenu;

export default headerMenu.reducer;
