/**
 * *****************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2022 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Adobe and its suppliers, if any. The intellectual and technical concepts
 * contained herein are proprietary to Adobe and its suppliers and are
 * protected by all applicable intellectual property laws, including trade
 * secret and copyright laws. Dissemination of this information or reproduction
 * of this material is strictly forbidden unless prior written permission is
 * obtained from Adobe.
 * *****************************************************************************
 */

import React, { ReactElement, useCallback, useEffect, useState } from 'react';

import { Text, TextField, View } from '@adobe/react-spectrum';

import FormLabel from 'components/AssetMetadataForm/fields/FormLabel';
import { HelpText } from 'components/AssetMetadataForm/fields/HelpText';
import Loading from 'components/Loading';

import FormDialog from './FormDialog';

type RenameAssetFormDialogProps = {
  filename: string;
  isFolder: boolean;
  isLoading: boolean;
  isOpen: boolean;
  onClose: () => void;
  onRename: (filename: string) => Promise<void>;
};

export default function RenameAssetFormDialog({
  filename,
  isFolder,
  isLoading,
  isOpen,
  onClose,
  onRename,
}: RenameAssetFormDialogProps): ReactElement {
  const [assetToRenameFilename, setAssetToRenameFilename] =
    useState<string>(filename);
  const [assetError, setAssetError] = useState<Error>();

  useEffect(() => {
    setAssetToRenameFilename(filename);
  }, [filename, setAssetToRenameFilename]);

  const handleRename = useCallback(async () => {
    setAssetError(undefined);

    try {
      if (assetToRenameFilename.length === 0) {
        throw new Error('Asset must have a name.');
      }

      await onRename(assetToRenameFilename);
    } catch (error) {
      setAssetError(error as Error);

      throw error;
    }

    setAssetToRenameFilename('');
  }, [assetToRenameFilename, onRename, setAssetError]);

  return (
    <FormDialog
      create="Rename"
      isDisabled={assetToRenameFilename === filename}
      isOpen={isOpen}
      onClose={onClose}
      onCreate={handleRename}
      title="Rename asset"
    >
      {isLoading ? (
        <>
          <Loading />
        </>
      ) : (
        <>
          <TextField
            label={<FormLabel>Asset Filename</FormLabel>}
            onChange={setAssetToRenameFilename}
            value={assetToRenameFilename}
          />
          {assetError && (
            <View marginTop="size-125">
              <HelpText validationState="invalid">
                {assetError.message}
              </HelpText>
            </View>
          )}

          {isFolder ? (
            <Text
              UNSAFE_style={{
                fontWeight: 600,
                marginTop: '12px',
              }}
            >
              Warning: Renaming this folder will mark it and all published child
              assets as dirty.
            </Text>
          ) : null}
        </>
      )}
    </FormDialog>
  );
}
