/**
 * *****************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2022 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Adobe and its suppliers, if any. The intellectual and technical concepts
 * contained herein are proprietary to Adobe and its suppliers and are
 * protected by all applicable intellectual property laws, including trade
 * secret and copyright laws. Dissemination of this information or reproduction
 * of this material is strictly forbidden unless prior written permission is
 * obtained from Adobe.
 * *****************************************************************************
 */

import React, { ReactElement, useCallback, useMemo, useState } from 'react';

import {
  Divider,
  Flex,
  Heading,
  Item,
  Link,
  TabList,
  TabPanels,
  Tabs,
  Text,
  View,
  Well,
} from '@adobe/react-spectrum';
import Code from '@spectrum-icons/workflow/Code';
import CollectionAdd from '@spectrum-icons/workflow/CollectionAdd';
import LinkOut from '@spectrum-icons/workflow/LinkOut';

import CollectionFileLivePreview from 'components/CCXAssetCollectionForm/CollectionFileLivePreview';
import StockCollectionSearch from 'components/StockCollectionForm/StockCollectionSearch';
import useConstant from 'hooks/useConstant';
import {
  CollectionQueryModel,
  StockCollectionQuery,
} from 'model/acp/CollectionQueryModel';
import { CollectionSource } from 'model/acp/CollectionSource';
import { Language } from 'model/Language';

import overrideStyles from 'styles/spectrum-overrides.module.scss';

type StockCollectionFormProps = {
  collectionQueryModel: CollectionQueryModel;
  onChange: (queryModel: CollectionQueryModel) => void;
  initialMode: StockCollectionFormMode;
};

export enum StockCollectionFormMode {
  Edit = 'edit',
  File = 'file',
}

const stockCollectionFormTabTitles: Record<StockCollectionFormMode, string> = {
  [StockCollectionFormMode.Edit]: 'Update Stock Query',
  [StockCollectionFormMode.File]: 'Collection File',
};

export default function StockCollectionForm({
  collectionQueryModel,
  onChange,
  initialMode,
}: StockCollectionFormProps): ReactElement {
  const { stockBaseUrl, stockStaticSearchFilters } = useConstant();
  const [mode, setMode] = useState<StockCollectionFormMode>(initialMode);
  const stockQuery = collectionQueryModel[
    CollectionSource.Stock
  ] as StockCollectionQuery;
  const updateStockCollectionQuery = useCallback(
    (stockCollectionQuery: StockCollectionQuery): void => {
      onChange({
        ...collectionQueryModel,
        [CollectionSource.Stock]: stockCollectionQuery,
      });
    },
    [onChange, collectionQueryModel],
  );
  const stockPreviewUrl = useMemo(() => {
    let previewUrl = `${stockBaseUrl}${stockStaticSearchFilters}`;

    if (collectionQueryModel[CollectionSource.Stock]?.externalCollectionId) {
      previewUrl += `&gallery_id=${
        collectionQueryModel[CollectionSource.Stock]?.externalCollectionId
      }`;
    }

    if (collectionQueryModel[CollectionSource.Stock]?.query) {
      previewUrl += `&k=${
        collectionQueryModel[CollectionSource.Stock]?.query?.get(
          Language.Default,
        ) ?? ''
      }`;
    }

    return previewUrl;
  }, [collectionQueryModel, stockBaseUrl, stockStaticSearchFilters]);

  return (
    <Flex direction="column" flex marginBottom="size-200" minHeight="size-0">
      <Well
        flex
        marginTop="size-0"
        minHeight="size-0"
        position="relative"
        UNSAFE_style={{
          paddingBottom: 0,
          paddingLeft: 0,
          paddingRight: 0,
          paddingTop: 0,
        }}
      >
        <Tabs
          isQuiet
          onSelectionChange={(key) => setMode(key as StockCollectionFormMode)}
          selectedKey={mode}
          height="100%"
        >
          <View borderBottomWidth="thick" borderBottomColor="gray-300">
            <Flex marginBottom="-2px" marginX="size-300" alignItems="center">
              <Heading flex marginY="size-0">
                {stockCollectionFormTabTitles[mode]}
              </Heading>
              {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
              <Link isQuiet>
                <a
                  target="_blank"
                  href={stockPreviewUrl}
                  rel="noopener noreferrer"
                >
                  <Text marginEnd="size-75">Preview in Adobe Stock</Text>
                  <LinkOut size="XS" />
                </a>
              </Link>
              <Divider
                orientation="vertical"
                size="M"
                marginStart="size-250"
                marginEnd="size-300"
              />
              <TabList
                UNSAFE_className={overrideStyles.tabList}
                flexBasis="148px"
              >
                <Item key={StockCollectionFormMode.Edit}>
                  <CollectionAdd size="S" />
                  <Text>Update Query</Text>
                </Item>
                <Item key={StockCollectionFormMode.File}>
                  <Code size="S" />
                </Item>
              </TabList>
            </Flex>
          </View>
          <TabPanels
            minHeight="size-0"
            UNSAFE_style={{
              border: 'none',
            }}
          >
            <Item key={StockCollectionFormMode.Edit}>
              <StockCollectionSearch
                collectionQuery={stockQuery}
                onChange={updateStockCollectionQuery}
              />
            </Item>
            <Item key={StockCollectionFormMode.File}>
              <CollectionFileLivePreview
                collectionQueryModel={collectionQueryModel}
              />
            </Item>
          </TabPanels>
        </Tabs>
      </Well>
    </Flex>
  );
}
