/**
 * *****************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2022 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Adobe and its suppliers, if any. The intellectual and technical concepts
 * contained herein are proprietary to Adobe and its suppliers and are
 * protected by all applicable intellectual property laws, including trade
 * secret and copyright laws. Dissemination of this information or reproduction
 * of this material is strictly forbidden unless prior written permission is
 * obtained from Adobe.
 * *****************************************************************************
 */

import { MutableRefObject, useEffect, useState } from 'react';

type OnScreenProps = {
  ref: MutableRefObject<Element | undefined>;
  margin?: string;
};

export default function useOnScreen({
  ref,
  margin = '0px',
}: OnScreenProps): boolean {
  const [isIntersecting, setIsIntersecting] = useState<boolean>(false);

  useEffect((): (() => void) => {
    const currentRef = ref.current;
    const observer = new IntersectionObserver(
      ([entry]): void => {
        setIsIntersecting(entry.isIntersecting);
      },
      {
        rootMargin: margin.endsWith('px') ? margin : `${margin}px`,
      },
    );

    if (currentRef) {
      observer.observe(currentRef);
    }

    return (): void => {
      if (currentRef) {
        observer.unobserve(currentRef);
      }
    };
  }, [ref, margin]);

  return isIntersecting;
}
