/**
 * *****************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2022 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Adobe and its suppliers, if any. The intellectual and technical concepts
 * contained herein are proprietary to Adobe and its suppliers and are
 * protected by all applicable intellectual property laws, including trade
 * secret and copyright laws. Dissemination of this information or reproduction
 * of this material is strictly forbidden unless prior written permission is
 * obtained from Adobe.
 * *****************************************************************************
 */

import React from 'react';

import Select from '@react/react-spectrum/Select';

import styles from '__DEPRECATED_DO_NOT_USE_OR_YOU_WILL_BE_FIRED/Detail/styles/Fields.module.scss';
import { MetadataOption } from '__DEPRECATED_DO_NOT_USE_OR_YOU_WILL_BE_FIRED/redux/metadata/metadata.types';

export interface SelectFieldProps {
  label: string;
  value: string;
  placeholder?: string;
  setValue: (newValue: string) => void;
  config?: {
    options?: MetadataOption[];
  };
}

const SelectField: React.FC<SelectFieldProps> = ({
  label,
  value,
  setValue,
  config,
  placeholder,
}: SelectFieldProps) => (
  <div className={styles.textField}>
    {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
    <label className={styles.fieldLabel}>{label}</label>
    <Select
      options={config?.options ?? []}
      value={value}
      onChange={(option: string) => setValue(option)}
      className={`${styles.textField} ${styles.size}`}
      placeholder={placeholder}
    />
  </div>
);

SelectField.defaultProps = {
  placeholder: undefined,
  config: undefined,
};

export default SelectField;
