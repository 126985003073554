/**
 * *****************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2022 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Adobe and its suppliers, if any. The intellectual and technical concepts
 * contained herein are proprietary to Adobe and its suppliers and are
 * protected by all applicable intellectual property laws, including trade
 * secret and copyright laws. Dissemination of this information or reproduction
 * of this material is strictly forbidden unless prior written permission is
 * obtained from Adobe.
 * *****************************************************************************
 */

export enum RepoMetadataKey {
  Asignee = 'storage:assignee',
  AssetClass = 'repo:assetClass',
  AssetId = 'repo:assetId',
  CreateDate = 'repo:createDate',
  CreatedBy = 'repo:createdBy',
  DCFormat = 'dc:format',
  DeviceCreateDate = 'storage:deviceCreateDate',
  DeviceId = 'storage:deviceId',
  DeviceModifyDate = 'storage:deviceModifyDate',
  DirectoryType = 'storage:directoryType',
  DiscardDate = 'repo:discardDate',
  DiscardedBy = 'repo:discardedBy',
  ETag = 'repo:etag',
  ImageLength = 'tiff:imageLength',
  ImageWidth = 'tiff:imageWidth',
  ModifiedBy = 'repo:modifiedBy',
  ModifyDate = 'repo:modifyDate',
  Name = 'repo:name',
  NPages = 'xmpTPg:NPages',
  Path = 'repo:path',
  Region = 'storage:region',
  RepoId = 'repo:id',
  RepositoryId = 'repo:repositoryId',
  Size = 'repo:size',
  State = 'repo:state',
  Version = 'repo:version',
  Links = '_links',
}
