/**
 * *****************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2022 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Adobe and its suppliers, if any. The intellectual and technical concepts
 * contained herein are proprietary to Adobe and its suppliers and are
 * protected by all applicable intellectual property laws, including trade
 * secret and copyright laws. Dissemination of this information or reproduction
 * of this material is strictly forbidden unless prior written permission is
 * obtained from Adobe.
 * *****************************************************************************
 */

import { updateFilters } from '__DEPRECATED_DO_NOT_USE_OR_YOU_WILL_BE_FIRED/redux/filter/filter.slice';
import {
  addFilter,
  removeFilter,
} from '__DEPRECATED_DO_NOT_USE_OR_YOU_WILL_BE_FIRED/redux/filter/filter.thunks';

import { useAppDispatch } from 'redux/hooks';

import { filterGroupConstants } from './model/constants';
import {
  FilterGroup,
  FilterGroupOption,
} from './model/filterGroupOptions.reducer';

/**
 * Returns the onSelect and onDeselect functions used by FilterSelectList groups
 */
function useSelectDispatch(group: FilterGroup) {
  const dispatch = useAppDispatch();
  const onSelect = async (option: FilterGroupOption) => {
    const groupInfo = filterGroupConstants[group];
    const { prop, ...filterOption } = option;

    if (!groupInfo.multiSelect || option.value === '*') {
      // replace the entire filter value if we've selected a default value
      dispatch(
        updateFilters({
          prop,
          newFilters: [filterOption],
        }),
      );
    } else {
      // add to filter value
      dispatch(addFilter(prop, filterOption, true));
    }
  };
  const onDeselect = (option: FilterGroupOption) => {
    const { prop, ...filterOption } = option;
    dispatch(removeFilter(prop, filterOption));
  };
  /*
   * currently this becomes the sole selection, as it's more like a nav. However, we could try to make it more additive
   * if we wish
   */
  const onSubSelect = (
    option: FilterGroupOption,
    subLabel: string,
    subValue: string,
  ) => {
    dispatch(
      updateFilters({
        prop: option.prop,
        newFilters: [
          {
            label: subLabel,
            value: subValue,
            isDefault: option.isDefault,
            isExactMatch: option.isExactMatch,
          },
        ],
      }),
    );
  };

  return {
    onSelect,
    onDeselect,
    onSubSelect,
  };
}

export default useSelectDispatch;
