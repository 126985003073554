/**
 * *****************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2022 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Adobe and its suppliers, if any. The intellectual and technical concepts
 * contained herein are proprietary to Adobe and its suppliers and are
 * protected by all applicable intellectual property laws, including trade
 * secret and copyright laws. Dissemination of this information or reproduction
 * of this material is strictly forbidden unless prior written permission is
 * obtained from Adobe.
 * *****************************************************************************
 */

import { useQueryClient } from 'react-query';
import { useNavigate } from 'react-router';

import { AxiosError } from 'axios';

import useConstant from 'hooks/useConstant';
import {
  AccessLevel,
  AccessLevelToPrivilegesMap,
  Privileges,
} from 'model/AccessLevel';
import ApplicationMetadata from 'model/acp/ApplicationMetadata';
import { ApplicationMetadataKey } from 'model/acp/ApplicationMetadataKey';
import { AssetMimeType } from 'model/acp/AssetMimeType';
import { AssetStatus } from 'model/acp/AssetStatus';
import DCTitle from 'model/acp/DCTitle';
import Directory from 'model/acp/Directory';
import { Entitlement } from 'model/acp/Entitlement';
import RepoMetadata from 'model/acp/RepoMetadata';
import { AssetAction } from 'model/app/AssetAction';
import { ApplicableRegion } from 'model/ApplicableRegion';
import { SkippedError } from 'model/errors/SkippedError';
import { Language } from 'model/Language';
import { WorkflowActionJobAsset } from 'redux/workflowActions/workflowActions.slice';
import { buildACEForNewUser } from 'utils/acp/accessControlEntries';
import { mapFromCreatorMetadata } from 'utils/metadata/fileMetadata';
import { mapTranslationsToDCTitle } from 'utils/metadata/title';
import {
  stringToArray,
  stringToBoolean,
  stringToLanguage,
  stringToLicensingCategory,
  stringToPriority,
  stringToTitle,
  stringToTraits,
} from 'utils/parseFormat';
import {
  verifyCanPerformAction,
  verifyHasMigratedMetadata,
} from 'utils/workflowActions';

import useAssetAPIOperations, { AssetBody } from './useAssetAPIOperations';
import { useCCXPrivateConfig, useCCXPublicConfig } from './useCCXConfig';
import { AccessControlEntry } from './useFolderACL';

export type AssetMetadata<T = unknown> = {
  pageMetadata: Directory | undefined;
  repoMetadata: RepoMetadata | undefined;
  applicationMetadata: ApplicationMetadata | undefined;
  fileContents: T | undefined;
};

export type RawAssetColumns =
  | 'path'
  | ApplicationMetadataKey.DCTitle
  | ApplicationMetadataKey.DCSubject
  | ApplicationMetadataKey.LicensingCategory
  | ApplicationMetadataKey.Animated
  | ApplicationMetadataKey.Language
  | ApplicationMetadataKey.Priority
  | ApplicationMetadataKey.Hero
  | ApplicationMetadataKey.Traits;

export type RawAssetBody = {
  [key in RawAssetColumns]: string;
};

type AssetWorkflowActionsHookResult = {
  approve: (
    targetDestination: string,
    redirect?: boolean,
  ) => (assetPath: string) => Promise<void | string>;
  createCollection: (
    parentPath: string,
    filename: string,
    rawData: string | object,
  ) => Promise<void | string>;
  createFolder: (
    parentPath: string,
    folderName: string,
  ) => Promise<void | string>;
  createFile: (
    parentPath: string,
    file: File,
    options?: { contentType?: string; blockContentType?: string },
  ) => Promise<void | string>;
  download: (assetPath: string) => Promise<void | string>;
  delete: (assetPath: string) => Promise<void | string>;
  fetchAsWorkflowActionJobAsset: (
    assetPath: string,
  ) => Promise<WorkflowActionJobAsset<AssetMetadata>>;
  fetchMetadata: (assetPath: string) => Promise<AssetMetadata>;
  move: (
    assetPath: string,
    {
      targetDestination,
      redirect,
    }: { targetDestination: string; redirect?: string },
  ) => Promise<void | string>;
  shareAsset: (
    assetPath: string,
    principalID: string | undefined,
    accessLevel: AccessLevel,
  ) => Promise<void>;
  publish: (assetPath: string) => Promise<void | string>;
  reject: (assetPath: string) => Promise<void | string>;
  rename: (
    assetPath: string,
    filename: string,
    redirect?: string,
  ) => Promise<void | string>;
  unshareAsset: (
    assetPath: string,
    principalID: string | undefined,
  ) => Promise<void>;
  unpublish: (assetPath: string) => Promise<void | string>;
  updateFile: (
    assetPath: string,
    rawData: string | object,
    hash: string,
  ) => Promise<void | string>;
  updateMetadata: (
    assetPath: string,
    updatedMetadata: ApplicationMetadata,
  ) => Promise<void | string>;
  updateAssetACL: (
    assetPath: string,
    principalID: string | undefined,
    accessLevel: AccessLevel,
  ) => Promise<void>;
  updateRaw: (
    assetPath: string,
    rawData: RawAssetBody,
  ) => Promise<void | string>;
};

export default function useAssetWorkflowActions(): AssetWorkflowActionsHookResult {
  const { acpBasePath } = useConstant();
  const { results: ccxPublicConfig } = useCCXPublicConfig();
  const { results: ccxPrivateConfig } = useCCXPrivateConfig();
  const getAPIOperationsForAsset = useAssetAPIOperations();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  return {
    approve:
      (targetDestination: string, redirect?: boolean) =>
      async (assetPath: string) => {
        const assetOps = await getAPIOperationsForAsset(assetPath);
        const { repoMetadata, applicationMetadata } = await assetOps.fetch();
        const errors = verifyCanPerformAction(AssetAction.Approve, {
          repoMetadata,
          applicationMetadata: applicationMetadata as ApplicationMetadata,
        });

        if (errors.length !== 0) {
          throw new Error(errors[0]);
        }

        await assetOps.approve(targetDestination);

        /*
         * Get parent folder and destination folder
         * Remove matching queries from React query
         */
        let parentFolder = `${assetPath.split('/').slice(0, -1).join('/')}/`;
        let destinationFolder = targetDestination.endsWith('/')
          ? targetDestination
          : `${targetDestination}/`;

        if (parentFolder.startsWith(acpBasePath.assets)) {
          parentFolder = parentFolder.substring(acpBasePath.assets.length);
        }

        if (destinationFolder.startsWith(acpBasePath.assets)) {
          destinationFolder = destinationFolder.substring(
            acpBasePath.assets.length,
          );
        }

        await queryClient.invalidateQueries({
          predicate: (query) => {
            const stringQuery = query.queryKey as string;

            return (
              stringQuery.includes(parentFolder) ||
              stringQuery.includes(destinationFolder)
            );
          },
        });

        if (redirect) {
          assetOps.refresh();
        }
      },
    createCollection: async (
      parentPath: string,
      filename: string,
      collectionContent: string | object,
    ) => {
      const assetOps = await getAPIOperationsForAsset(parentPath);
      const { repoMetadata, applicationMetadata } = await assetOps.fetch();
      const errors = verifyCanPerformAction(AssetAction.Create, {
        repoMetadata,
        applicationMetadata: applicationMetadata as ApplicationMetadata,
      });

      if (errors.length !== 0) {
        throw new Error(errors[0]);
      }

      try {
        await assetOps.create(filename, {
          contentType: AssetMimeType.Collection,
          fileBody: collectionContent,
        });
      } catch (error) {
        const axiosError = error as AxiosError;

        if (axiosError.isAxiosError && axiosError.response?.status === 409) {
          throw new Error('File with same name already exists');
        }

        throw error;
      }

      return assetOps.refresh();
    },
    delete: async (assetPath: string): Promise<void | string> => {
      const assetOps = await getAPIOperationsForAsset(assetPath);
      const { pageMetadata, repoMetadata, applicationMetadata } =
        await assetOps.fetch();
      // If deletion fails after unpublishing an asset, we don't re-publish again because the original status might have been dirty, not simply published.
      const errors = verifyCanPerformAction(AssetAction.Delete, {
        repoMetadata,
        parentDirectory: pageMetadata,
        applicationMetadata: applicationMetadata as ApplicationMetadata,
      });
      const isUnpublished =
        errors.length !== 0 && errors[0].includes('not published');

      if (!isUnpublished) {
        await assetOps.unpublish();
      } else if (errors.length > 1) {
        throw new Error(errors[1]);
      }

      await assetOps.discard(assetPath);

      // Invalidate existing directories for deleted data
      await queryClient.invalidateQueries(['directory']);
    },
    download: async (assetPath: string): Promise<void | string> => {
      const assetOps = await getAPIOperationsForAsset(assetPath);
      const { pageMetadata, repoMetadata, applicationMetadata } =
        await assetOps.fetch();
      const errors = verifyCanPerformAction(AssetAction.Download, {
        repoMetadata,
        parentDirectory: pageMetadata,
        applicationMetadata: applicationMetadata as ApplicationMetadata,
      });

      if (errors.length !== 0) {
        throw new Error(errors[0]);
      }

      await assetOps.download();
    },
    createFile: async (
      parentPath: string,
      file: File,
      { contentType = '', blockContentType = '' } = {},
    ) => {
      const parentAssetOps = await getAPIOperationsForAsset(parentPath);
      const { repoMetadata, applicationMetadata } =
        await parentAssetOps.fetch();
      const errors = verifyCanPerformAction(AssetAction.Create, {
        repoMetadata,
        applicationMetadata: applicationMetadata as ApplicationMetadata,
      });

      if (errors.length !== 0) {
        throw new Error(errors[0]);
      }

      if (
        // eslint-disable-next-line no-control-regex
        /^((?:CON|PRN|AUX|NUL|COM[1-9]|LPT[1-9])(?:\.[^.]*)?$)|[+#\\:*?"/|\x00-\x1F]+|[ .]$/.test(
          file.name,
        )
      ) {
        throw new Error('File names cannot contain restricted characters.');
      }

      try {
        await parentAssetOps.create(file.name, {
          contentType: contentType || file.type,
        });
      } catch (error) {
        const axiosError = error as AxiosError;

        if (axiosError.isAxiosError && axiosError.response?.status === 409) {
          throw new Error('An asset with same name already exists');
        }

        if (axiosError.isAxiosError && axiosError.response?.status === 403) {
          throw new Error('Missing permissions for this operation');
        }

        throw error;
      }

      const assetOps = await getAPIOperationsForAsset(
        `${parentPath}/${file.name}`,
      );

      try {
        await assetOps.upload(
          file,
          contentType
            ? {
                contentType,
                blockContentType,
              }
            : undefined,
        );

        const { applicationMetadata: createdFileApplicationMetadata = {} } =
          await assetOps.fetch();

        await assetOps.updateMetadata({
          ...createdFileApplicationMetadata,
          [ApplicationMetadataKey.Animated]: false,
          [ApplicationMetadataKey.Language]: Language.Default,
          [ApplicationMetadataKey.LicensingCategory]: Entitlement.Free,
          [ApplicationMetadataKey.ApplicableRegions]: [ApplicableRegion.Global],
        });
      } catch (error) {
        const axiosError = error as AxiosError;

        if (
          axiosError.isAxiosError &&
          axiosError.response?.status === 400 &&
          axiosError.response.data.type ===
            'http://ns.adobe.com/adobecloud/problem/limit/resourcesize'
        ) {
          throw new Error('File is larger than maximum allowed size');
        }

        throw error;
      }

      await parentAssetOps.refresh();

      return assetOps.refresh();
    },
    createFolder: async (parentPath: string, folderName: string) => {
      const assetOps = await getAPIOperationsForAsset(parentPath);
      const { repoMetadata, applicationMetadata } = await assetOps.fetch();
      const errors = verifyCanPerformAction(AssetAction.Create, {
        repoMetadata,
        applicationMetadata: applicationMetadata as ApplicationMetadata,
      });

      if (errors.length !== 0) {
        throw new Error(errors[0]);
      }

      try {
        await assetOps.create(folderName, {
          contentType: AssetMimeType.Directory,
        });
      } catch (error) {
        const axiosError = error as AxiosError;

        if (axiosError.isAxiosError && axiosError.response?.status === 409) {
          throw new Error('An asset with same name already exists');
        }

        throw error;
      }

      return assetOps.refresh();
    },
    fetchAsWorkflowActionJobAsset: async (
      assetPath: string,
    ): Promise<WorkflowActionJobAsset<AssetMetadata>> => {
      const assetOps = await getAPIOperationsForAsset(assetPath);

      return {
        assetPath,
        body: await assetOps.fetch(),
      };
    },
    fetchMetadata: async (assetPath: string): Promise<AssetMetadata> => {
      const assetOps = await getAPIOperationsForAsset(assetPath);

      return assetOps.fetch();
    },
    move: async (
      assetPath: string,
      {
        targetDestination,
        redirect,
      }: { targetDestination: string; redirect?: string },
    ) => {
      const assetOps = await getAPIOperationsForAsset(assetPath);
      const { repoMetadata, applicationMetadata } = await assetOps.fetch();
      const errors = verifyCanPerformAction(AssetAction.Move, {
        repoMetadata,
        applicationMetadata: applicationMetadata as ApplicationMetadata,
      });

      if (errors.length !== 0) {
        throw new Error(errors[0]);
      }

      await assetOps.move(targetDestination);

      /*
       * Get parent folder and destination folder
       * Remove matching queries from React query
       */
      let parentFolder = `${assetPath.split('/').slice(0, -1).join('/')}/`;
      let destinationFolder = targetDestination.endsWith('/')
        ? targetDestination
        : `${targetDestination}/`;

      if (parentFolder.startsWith(acpBasePath.assets)) {
        parentFolder = parentFolder.substring(acpBasePath.assets.length);
      }

      if (destinationFolder.startsWith(acpBasePath.assets)) {
        destinationFolder = destinationFolder.substring(
          acpBasePath.assets.length,
        );
      }

      if (redirect) {
        navigate(redirect);
      }

      await queryClient.invalidateQueries(['directory']);
    },
    publish: async (assetPath) => {
      const assetOps = await getAPIOperationsForAsset(assetPath);
      const { repoMetadata, applicationMetadata } = await assetOps.fetch();
      const { hasMigrations, result: updatedApplicationMetadata } =
        verifyHasMigratedMetadata(
          repoMetadata,
          applicationMetadata as ApplicationMetadata,
        );

      if (hasMigrations) {
        await assetOps.updateMetadata(updatedApplicationMetadata);
      }

      const errors = verifyCanPerformAction(AssetAction.Publish, {
        repoMetadata,
        applicationMetadata: updatedApplicationMetadata as ApplicationMetadata,
      });

      if (
        errors.length === 1 &&
        errors[0] ===
          'File has an incorrect creator schema. This will be fixed on publish.'
      ) {
        const attributionMetadata =
          applicationMetadata?.[ApplicationMetadataKey.Attribution];

        if (attributionMetadata !== undefined) {
          await assetOps.updateMetadata({
            ...applicationMetadata,
            [ApplicationMetadataKey.Attribution]: {
              ...attributionMetadata,
              [ApplicationMetadataKey.Creators]: mapFromCreatorMetadata(
                applicationMetadata?.[ApplicationMetadataKey.Attribution]?.[
                  ApplicationMetadataKey.Creators
                ] ?? [],
              ),
            },
          });
        }
      } else if (
        errors.length !== 0 &&
        !errors.some((error) => error.includes('already published'))
      ) {
        throw new Error(errors[0]);
      } else if (errors.length !== 0) {
        throw new SkippedError('Already published');
      }

      // Attempt publish
      try {
        await assetOps.publish();
      } catch (error) {
        const axiosError = error as AxiosError;

        if (!axiosError.isAxiosError) {
          throw error;
        }

        if (axiosError.response?.data.error.includes('title cannot be empty')) {
          throw new Error('Folders and collections must have titles.');
        }

        if (
          axiosError.response?.data.error.includes('language cannot be null')
        ) {
          throw new Error(
            'Folders and collections must have at least one language.',
          );
        }

        if (axiosError.response?.status === 400) {
          throw new Error(
            'Asset was not able to be published. This can be caused by trying to publish a file with an unpublished parent.',
          );
        }

        if (axiosError.response?.status === 403) {
          throw new Error('Missing permissions for this operation');
        }

        if (axiosError.response?.status === 404) {
          throw new Error(
            'Asset does not exist in publish folder. This can be caused by trying to publish a folder with an unpublished parent.',
          );
        }

        throw new Error(`${axiosError.response?.data.error}`);
      }

      return assetOps.refresh();
    },
    reject: async (assetPath) => {
      const assetOps = await getAPIOperationsForAsset(assetPath);
      const { repoMetadata, applicationMetadata } = await assetOps.fetch();
      const errors = verifyCanPerformAction(AssetAction.Reject, {
        repoMetadata,
        applicationMetadata: applicationMetadata as ApplicationMetadata,
      });

      if (errors.length !== 0) {
        throw new Error(errors[0]);
      }

      const updatedData: AssetBody = {
        ...applicationMetadata,
        [ApplicationMetadataKey.Status]: AssetStatus.Rejected,
      };

      // Attempt update
      await assetOps.updateMetadata(updatedData);

      return assetOps.refresh();
    },
    rename: async (
      assetPath: string,
      newFilename: string,
      redirect?: string,
    ) => {
      const assetOps = await getAPIOperationsForAsset(assetPath);
      const { repoMetadata, applicationMetadata } = await assetOps.fetch();
      const errors = verifyCanPerformAction(AssetAction.Rename, {
        repoMetadata,
        applicationMetadata: applicationMetadata as ApplicationMetadata,
      });

      if (errors.length !== 0) {
        throw new Error(errors[0]);
      }

      try {
        await assetOps.rename(newFilename);
      } catch (error) {
        const axiosError = error as AxiosError;

        if (axiosError.isAxiosError && axiosError.response?.status === 409) {
          throw new Error('Asset with same name already exists');
        }

        if (
          (error as Error).message ===
          'The request failed because of a name conflict. Resource names must be unique.'
        ) {
          throw new Error('Asset with same name already exists');
        }

        if (
          (error as Error).message.includes(
            'Target should be different from source.',
          )
        ) {
          throw new Error('Asset must have different name than original name');
        }

        throw error;
      }

      if (redirect) {
        navigate(redirect);
      }

      // Get parent folder and remove matching queries from React query
      let parentFolder = `${assetPath.split('/').slice(0, -1).join('/')}/`;

      if (parentFolder.startsWith(acpBasePath.assets)) {
        parentFolder = parentFolder.substring(acpBasePath.assets.length);
      }

      await queryClient.invalidateQueries({
        predicate: (query) => {
          if (typeof query.queryKey === 'string') {
            return query.queryKey.includes(parentFolder);
          }

          return query.queryKey.some((queryKey) =>
            (queryKey as string).indexOf(parentFolder),
          );
        },
      });
    },
    unpublish: async (assetPath) => {
      const assetOps = await getAPIOperationsForAsset(assetPath);
      const { repoMetadata, applicationMetadata } = await assetOps.fetch();
      const errors = verifyCanPerformAction(AssetAction.Unpublish, {
        repoMetadata,
        applicationMetadata: applicationMetadata as ApplicationMetadata,
      });

      if (
        errors.length !== 0 &&
        !errors.some((error) => error.includes('not published'))
      ) {
        throw new Error(errors[0]);
      } else if (errors.length !== 0) {
        return 'Skipped';
      }

      // Attempt unpublish
      await assetOps.unpublish();

      return assetOps.refresh();
    },
    updateFile: async (assetPath, rawBody, hash) => {
      const assetOps = await getAPIOperationsForAsset(assetPath);
      const { repoMetadata, applicationMetadata } = await assetOps.fetch();
      const errors = verifyCanPerformAction(AssetAction.Create, {
        repoMetadata,
        applicationMetadata: applicationMetadata as ApplicationMetadata,
      });

      if (errors.length !== 0) {
        throw new Error(errors[0]);
      }

      await assetOps.updateFile(rawBody, hash);

      return assetOps.refresh();
    },
    updateMetadata: async (assetPath, updatedMetadata) => {
      const assetOps = await getAPIOperationsForAsset(assetPath);
      const { repoMetadata, applicationMetadata } = await assetOps.fetch();
      const errors = verifyCanPerformAction(AssetAction.Save, {
        repoMetadata,
        applicationMetadata: applicationMetadata as ApplicationMetadata,
      });

      if (errors.length !== 0) {
        throw new Error(errors[0]);
      }

      // Attempt update
      await assetOps.updateMetadata(updatedMetadata);

      return assetOps.refresh();
    },
    updateRaw: async (assetPath, rawData) => {
      const assetOps = await getAPIOperationsForAsset(assetPath);
      const { repoMetadata, applicationMetadata } = await assetOps.fetch();
      const errors = verifyCanPerformAction(AssetAction.MetadataImport, {
        repoMetadata,
        applicationMetadata: applicationMetadata as ApplicationMetadata,
      });

      if (errors.length !== 0) {
        throw new Error(errors[0]);
      }

      let dcTitle: DCTitle | undefined;

      if (rawData[ApplicationMetadataKey.DCTitle]) {
        const titleMap: Map<Language, string> = rawData[
          ApplicationMetadataKey.DCTitle
        ]
          ? new Map([
              [Language.Default, rawData[ApplicationMetadataKey.DCTitle]],
            ])
          : new Map();

        dcTitle = mapTranslationsToDCTitle(titleMap);
      } else {
        dcTitle = stringToTitle(rawData[ApplicationMetadataKey.DCTitle]);
      }

      const applicableRegion: string | undefined =
        ccxPublicConfig?.common.applicableRegions.options[0].value;

      if (!applicableRegion) {
        throw new Error(
          'Applicable region could not be loaded from configuration',
        );
      }

      const allowedTraits: string[] | undefined =
        ccxPrivateConfig?.common.traits.options.map((trait) =>
          trait.value.toLowerCase(),
        );

      if (!allowedTraits) {
        throw new Error('Traits could not be loaded from configuration');
      }

      const updatedData: AssetBody = {
        ...applicationMetadata,
        [ApplicationMetadataKey.Animated]:
          stringToBoolean(
            rawData[ApplicationMetadataKey.Animated],
            ApplicationMetadataKey.Animated,
          ) ?? applicationMetadata?.[ApplicationMetadataKey.Animated],
        [ApplicationMetadataKey.DCSubject]:
          stringToArray(rawData[ApplicationMetadataKey.DCSubject]) ??
          applicationMetadata?.[ApplicationMetadataKey.DCSubject],
        [ApplicationMetadataKey.DCTitle]: dcTitle,
        [ApplicationMetadataKey.Hero]:
          stringToBoolean(
            rawData[ApplicationMetadataKey.Hero],
            ApplicationMetadataKey.Hero,
          ) ?? applicationMetadata?.[ApplicationMetadataKey.Hero],
        [ApplicationMetadataKey.Language]:
          stringToLanguage(rawData[ApplicationMetadataKey.Language]) ??
          applicationMetadata?.[ApplicationMetadataKey.Language],
        [ApplicationMetadataKey.LicensingCategory]:
          stringToLicensingCategory(
            rawData[ApplicationMetadataKey.LicensingCategory],
          ) ?? applicationMetadata?.[ApplicationMetadataKey.LicensingCategory],
        [ApplicationMetadataKey.Priority]:
          stringToPriority(rawData[ApplicationMetadataKey.Priority]) ??
          applicationMetadata?.[ApplicationMetadataKey.Priority],
        [ApplicationMetadataKey.ApplicableRegions]: [applicableRegion],
        [ApplicationMetadataKey.Traits]:
          stringToTraits(
            rawData[ApplicationMetadataKey.Traits],
            allowedTraits,
          ) ?? applicationMetadata?.[ApplicationMetadataKey.Traits],
      };

      // Attempt update
      await assetOps.updateMetadata(updatedData);

      return assetOps.refresh();
    },
    shareAsset: async (assetPath, principalId, accessLevel) => {
      if (!principalId) {
        throw new Error(
          'Principal (ie. user or user group) being added is not in the ACP Organization.',
        );
      }

      const privileges: Privileges[] | undefined =
        AccessLevelToPrivilegesMap.get(accessLevel);

      if (!privileges) {
        throw new Error('Access Level is not permitted.');
      }

      const assetOps = await getAPIOperationsForAsset(assetPath);
      const { repoMetadata, applicationMetadata } = await assetOps.fetch();
      const errors = verifyCanPerformAction(AssetAction.Share, {
        repoMetadata,
        applicationMetadata: applicationMetadata as ApplicationMetadata,
      });

      if (errors.length !== 0) {
        throw new Error(errors[0]);
      }

      const principalACE: AccessControlEntry = buildACEForNewUser(
        principalId,
        accessLevel,
        privileges,
      );

      await assetOps.share(principalACE);

      return assetOps.refresh();
    },
    unshareAsset: async (assetPath, principalId) => {
      if (!principalId) {
        throw new Error(
          'Principal (ie. user or user group) being added is not in the ACP Organization.',
        );
      }

      const assetOps = await getAPIOperationsForAsset(assetPath);
      const { repoMetadata, applicationMetadata } = await assetOps.fetch();
      const errors = verifyCanPerformAction(AssetAction.Unshare, {
        repoMetadata,
        applicationMetadata: applicationMetadata as ApplicationMetadata,
      });

      if (errors.length !== 0) {
        throw new Error(errors[0]);
      }

      await assetOps.unshare(principalId);

      return assetOps.refresh();
    },
    updateAssetACL: async (assetPath, principalId, accessLevel) => {
      if (!principalId) {
        throw new Error(
          'Principal (ie. user or user group) being added is not in the ACP Org.',
        );
      }

      const privileges: Privileges[] | undefined =
        AccessLevelToPrivilegesMap.get(accessLevel);

      if (!privileges) {
        throw new Error('Access Level is not permitted.');
      }

      const assetOps = await getAPIOperationsForAsset(assetPath);
      const { repoMetadata, applicationMetadata } = await assetOps.fetch();
      const errors = verifyCanPerformAction(AssetAction.UpdateACL, {
        repoMetadata,
        applicationMetadata: applicationMetadata as ApplicationMetadata,
      });

      if (errors.length !== 0) {
        throw new Error(errors[0]);
      }

      const newPrincipalACE: AccessControlEntry = buildACEForNewUser(
        principalId,
        accessLevel,
        privileges,
      );
      await assetOps.updateACEInACL(newPrincipalACE);

      return assetOps.refresh();
    },
  };
}
