/**
 * *****************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2022 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Adobe and its suppliers, if any. The intellectual and technical concepts
 * contained herein are proprietary to Adobe and its suppliers and are
 * protected by all applicable intellectual property laws, including trade
 * secret and copyright laws. Dissemination of this information or reproduction
 * of this material is strictly forbidden unless prior written permission is
 * obtained from Adobe.
 * *****************************************************************************
 */

import React, { useEffect, useState } from 'react';

import '../styles/TempoModal.css';
import Dialog from '@react/react-spectrum/Dialog';
import ModalContainer from '@react/react-spectrum/ModalContainer';

import { Alert, removeAlert } from 'redux/alert/alert.slice';
import { useAppDispatch, useAppSelector } from 'redux/hooks';

const CONFIRM_DEFAULT_LABEL = 'OK';

type DialogProps = Alert;

const TempoModal: React.FC = () => {
  const dispatch = useAppDispatch();
  const dialogProps = useAppSelector((state) => state.alert.alerts);
  const [isShowing, setIsShowing] = useState(false);
  const hide = () => {
    setIsShowing(false);
  };
  const getDialog = ({
    onConfirm: handleOnConfirm,
    type,
    title,
    cancelLabel,
    secondaryLabel,
    confirmLabel,
    content,
  }: DialogProps) => {
    const onConfirm = (label: 'primary' | 'secondary') => {
      if (handleOnConfirm) {
        handleOnConfirm(label);

        return undefined;
      }

      return null;
    };

    return (
      <Dialog
        mode="alert"
        keyboardConfirm
        variant={type}
        title={title}
        cancelLabel={cancelLabel}
        secondaryLabel={secondaryLabel}
        confirmLabel={confirmLabel ?? CONFIRM_DEFAULT_LABEL}
        onConfirm={onConfirm}
        onClose={hide}
      >
        {content}
      </Dialog>
    );
  };
  const showNext = () => {
    const nextProps: DialogProps | undefined = dialogProps[0] as
      | DialogProps
      | undefined;

    if (nextProps) {
      const nextDialog = getDialog(nextProps);
      ModalContainer.show(nextDialog);
      setIsShowing(true);
      dispatch(removeAlert(nextProps.id));
    }
  };

  useEffect(() => {
    // will show the next dialog only when no other dialogs are showing
    if (!isShowing && dialogProps.length > 0) {
      showNext();
    }
  });

  return <div id="tempo-modal" />;
};

export default TempoModal;
