/**
 * *****************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2022 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Adobe and its suppliers, if any. The intellectual and technical concepts
 * contained herein are proprietary to Adobe and its suppliers and are
 * protected by all applicable intellectual property laws, including trade
 * secret and copyright laws. Dissemination of this information or reproduction
 * of this material is strictly forbidden unless prior written permission is
 * obtained from Adobe.
 * *****************************************************************************
 */

import React, { ReactElement } from 'react';

import SentimentNeutral from '@react/react-spectrum/Icon/SentimentNeutral';
import IllustratedMessage from '@react/react-spectrum/IllustratedMessage';

import styles from './TemplatesBrowse.module.scss';

export default function NoAssetsBrowse(): ReactElement {
  return (
    <div className={styles.templatesBrowse}>
      <div className={styles.browse}>
        <IllustratedMessage
          heading="No Assets"
          description="Try a different search"
          illustration={<SentimentNeutral size="XL" />}
        />
      </div>
    </div>
  );
}
