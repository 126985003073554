/**
 * *****************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2022 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Adobe and its suppliers, if any. The intellectual and technical concepts
 * contained herein are proprietary to Adobe and its suppliers and are
 * protected by all applicable intellectual property laws, including trade
 * secret and copyright laws. Dissemination of this information or reproduction
 * of this material is strictly forbidden unless prior written permission is
 * obtained from Adobe.
 * *****************************************************************************
 */

import React, { useState } from 'react';

import Checkbox from '@react/react-spectrum/Checkbox';
import SpectrumNumberInput from '@react/react-spectrum/NumberInput';

import styles from '__DEPRECATED_DO_NOT_USE_OR_YOU_WILL_BE_FIRED/Detail/styles/Fields.module.scss';

interface PriorityFieldProps {
  label: string;
  value?: number;
  setValue: (value?: number) => void;
}

const PriorityField: React.FC<PriorityFieldProps> = ({
  label,
  value,
  setValue,
}: PriorityFieldProps) => {
  const [checked, setChecked] = useState(value && value > 0);

  /**
   *  When Priority Check is enabled, the value in input field is used.
   *  Otherwise Priority is set to undefined.
   */
  function onChangeHandler(checkedVal: boolean) {
    setChecked(checkedVal);

    if (checkedVal) {
      setValue(value);
    } else {
      setValue();
    }
  }

  return (
    <div className={styles.priorityField}>
      {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
      <label className={styles.fieldLabel}>{label}</label>

      <div>
        <Checkbox
          label="Enable"
          checked={checked}
          onChange={(checkedVal: boolean) => onChangeHandler(checkedVal)}
        />
      </div>

      <SpectrumNumberInput
        invalid={false}
        onChange={setValue}
        disabled={!checked}
        value={value}
        step={1}
        min={1}
        defaultValue={1}
      />
    </div>
  );
};

PriorityField.defaultProps = {
  value: undefined,
};

export default PriorityField;
