/**
 * *****************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2022 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Adobe and its suppliers, if any. The intellectual and technical concepts
 * contained herein are proprietary to Adobe and its suppliers and are
 * protected by all applicable intellectual property laws, including trade
 * secret and copyright laws. Dissemination of this information or reproduction
 * of this material is strictly forbidden unless prior written permission is
 * obtained from Adobe.
 * *****************************************************************************
 */

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { TemplateAsset } from '__DEPRECATED_DO_NOT_USE_OR_YOU_WILL_BE_FIRED/controllers/api/SparkSearchTypes';
import { ActionInfo } from '__DEPRECATED_DO_NOT_USE_OR_YOU_WILL_BE_FIRED/controllers/constants/StatusUtils';

export interface MetadataState {
  asset: TemplateAsset | undefined;
  dirtyAssetProps: string[];
  actionCompleted: ActionInfo | null;
}

export const initialState: MetadataState = {
  asset: undefined,
  dirtyAssetProps: [],
  actionCompleted: null,
};

export const metadataSlice = createSlice({
  name: 'metadata',
  initialState,
  reducers: {
    addDirtyAssetProp: (state, action: PayloadAction<string>) => {
      const dirtyAssetProps = new Set<string>([...state.dirtyAssetProps]);
      dirtyAssetProps.add(action.payload);

      state.dirtyAssetProps = [...dirtyAssetProps];
    },
    clearDirtyAssetProps: (state) => {
      state.dirtyAssetProps = [];
    },
    removeDirtyAssetProp: (state, action: PayloadAction<string>) => {
      const dirtyAssetProps = new Set<string>([...state.dirtyAssetProps]);
      dirtyAssetProps.delete(action.payload);

      state.dirtyAssetProps = [...dirtyAssetProps];
    },
    setActionCompleted: (state, action: PayloadAction<ActionInfo | null>) => {
      state.actionCompleted = action.payload;
    },
    setAsset: (state, action: PayloadAction<TemplateAsset | undefined>) => {
      state.asset = action.payload;
    },
  },
});

export const {
  addDirtyAssetProp,
  clearDirtyAssetProps,
  removeDirtyAssetProp,
  setActionCompleted,
  setAsset,
} = metadataSlice.actions;

export default metadataSlice.reducer;
