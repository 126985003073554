/**
 * *****************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2022 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Adobe and its suppliers, if any. The intellectual and technical concepts
 * contained herein are proprietary to Adobe and its suppliers and are
 * protected by all applicable intellectual property laws, including trade
 * secret and copyright laws. Dissemination of this information or reproduction
 * of this material is strictly forbidden unless prior written permission is
 * obtained from Adobe.
 * *****************************************************************************
 */

import React, { useLayoutEffect, useMemo, useState } from 'react';

import Button from '@react/react-spectrum/Button';
import AddCircle from '@react/react-spectrum/Icon/AddCircle';
import { MenuItem } from '@react/react-spectrum/Menu';

import SparkSearch from '__DEPRECATED_DO_NOT_USE_OR_YOU_WILL_BE_FIRED/controllers/api/SparkSearch';
import { FilterTab } from '__DEPRECATED_DO_NOT_USE_OR_YOU_WILL_BE_FIRED/redux/filter/filter.slice';
import { alertUnsavedMetadataChanges } from '__DEPRECATED_DO_NOT_USE_OR_YOU_WILL_BE_FIRED/redux/metadata/metadata.thunks';

import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { chunkForceSel } from 'utils';

import {
  FilterGroup,
  FilterGroupInfo,
  FilterGroupOption,
} from './model/filterGroupOptions.reducer';
import useSelectDispatch from './useSelectDispatch';

import styles from './FilterSelectList.module.scss';

type FilterSelectListProps = {
  groupName: FilterGroup;
  info: FilterGroupInfo;
  options: FilterGroupOption[];
  selectedOptions: FilterGroupOption[];
};

export const FILTER_GROUP_CHUNK_SIZE = 5;

function createOptionsList(
  chunkedOptions: FilterGroupOption[][],
  chunkIndex: number,
): FilterGroupOption[] {
  let optionsList: FilterGroupOption[] = chunkedOptions[0] || [];
  let index = 1;

  while (index <= chunkIndex) {
    if (chunkedOptions[index]) {
      optionsList = [...optionsList, ...chunkedOptions[index]];
    }

    index++;
  }

  return optionsList;
}

function optionIsSelected(
  selectedOptions: FilterGroupOption[],
  option: FilterGroupOption,
): boolean {
  return (
    selectedOptions.findIndex(
      (selectedOption: FilterGroupOption) =>
        selectedOption.value === option.value,
    ) >= 0
  );
}

const FilterSelectList: React.FC<FilterSelectListProps> = ({
  groupName,
  info,
  options,
  selectedOptions,
}: FilterSelectListProps) => {
  const dispatch = useAppDispatch();
  const mode: FilterTab = useAppSelector((state) => state.filters.mode);
  const { defaultOptions, heading, seeMoreHeading } = info;
  const { onSelect, onDeselect, onSubSelect } = useSelectDispatch(groupName);
  const useDirectoryChunks = groupName === 'directory';
  const chunkedOptions = useMemo(() => {
    if (seeMoreHeading) {
      return chunkForceSel(options, FILTER_GROUP_CHUNK_SIZE, selectedOptions);
    }

    return [options];
  }, [options, seeMoreHeading, selectedOptions]);
  const [chunkIndex, setChunkIndex] = useState(0);
  const [optionsList, setOptionsList] = useState(
    createOptionsList(chunkedOptions, chunkIndex),
  );
  const hasMoreChunks =
    seeMoreHeading && chunkedOptions.length - 1 > chunkIndex;
  const sectionId = heading.toLowerCase();

  // updates optionsList if we get new options (from an async call)
  useLayoutEffect(() => {
    const newOptionsList = createOptionsList(chunkedOptions, chunkIndex);
    setOptionsList(newOptionsList);
  }, [options, chunkIndex, chunkedOptions]);

  const handleSelect = (event: any, option: FilterGroupOption) => {
    dispatch(
      alertUnsavedMetadataChanges(mode, () => {
        if (useDirectoryChunks) {
          const { target } = event;

          if (target.id && target.id !== option.label) {
            const val = `${SparkSearch.ASSETS_DIRECTORY_BASE + target.id}/`;
            onSubSelect(option, target.id, val);

            return;
          }
        }

        if (optionIsSelected(selectedOptions, option)) {
          onDeselect(option);
        } else {
          onSelect(option);
        }
      }),
    );
  };
  const getDirectoryChunks = (label: string) => {
    const chunks = label.split('/');
    const value: string[] = [];

    return (
      <>
        {chunks.map((chunk, index) => {
          const sep = index === chunks.length - 1 ? '' : ' > ';
          value.push(chunk);
          const valueId = value.join('/');

          return (
            <span key={valueId} id={valueId}>
              {`${chunk}${sep}`}
            </span>
          );
        })}
      </>
    );
  };
  const getOptions = (): JSX.Element[] => {
    const menuItemList: JSX.Element[] = [];

    if (defaultOptions.length) {
      defaultOptions.forEach((option: FilterGroupOption) => {
        menuItemList.push(
          <MenuItem
            key={option.label}
            className={styles.option}
            value={option.value}
            onClick={(event: any) => handleSelect(event, option)}
            selected={optionIsSelected(selectedOptions, option)}
          >
            {option.label}
          </MenuItem>,
        );
      });
    }

    optionsList.forEach((option: FilterGroupOption) =>
      menuItemList.push(
        <MenuItem
          key={option.value}
          className={styles.option}
          value={option.value}
          onClick={(event: any) => handleSelect(event, option)}
          selected={optionIsSelected(selectedOptions, option)}
        >
          <div className={styles.label}>
            {useDirectoryChunks
              ? getDirectoryChunks(option.label)
              : option.label}
          </div>
          {option.count && option.prop === 'owner' ? (
            <div className={styles.count}>({option.count})</div>
          ) : (
            ''
          )}
        </MenuItem>,
      ),
    );

    return menuItemList;
  };
  const showMoreItems = () => {
    if (chunkIndex + 1 < chunkedOptions.length) {
      setOptionsList([...optionsList, ...chunkedOptions[chunkIndex + 1]]);

      setChunkIndex(chunkIndex + 1);
    }
  };

  return (
    <div
      data-testid={`${sectionId}-select-list`}
      className={styles.filterSelectList}
    >
      {heading ? <h3>{heading}</h3> : ''}
      <div data-testid={`${sectionId}-options`} className={styles.options}>
        {options.length === 0 && defaultOptions.length === 0 ? (
          <MenuItem disabled>Loading...</MenuItem>
        ) : (
          getOptions()
        )}
      </div>
      {hasMoreChunks ? (
        <Button
          data-testid={`${sectionId}-see-more`}
          onClick={() => showMoreItems()}
          quiet
          icon={<AddCircle size="S" />}
          className={styles.seeMore}
        >
          {seeMoreHeading}
        </Button>
      ) : (
        ''
      )}
    </div>
  );
};

export default FilterSelectList;
