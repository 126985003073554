/**
 * *****************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2022 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Adobe and its suppliers, if any. The intellectual and technical concepts
 * contained herein are proprietary to Adobe and its suppliers and are
 * protected by all applicable intellectual property laws, including trade
 * secret and copyright laws. Dissemination of this information or reproduction
 * of this material is strictly forbidden unless prior written permission is
 * obtained from Adobe.
 * *****************************************************************************
 */

/* eslint-disable class-methods-use-this, max-classes-per-file */

import Size from '@react/collection-view/src/Size';
import {
  info as infoToast,
  success as successToast,
} from '@react/react-spectrum/Toast';
import Axios from 'axios';

import {
  SearchParams,
  SearchSchema,
} from '__DEPRECATED_DO_NOT_USE_OR_YOU_WILL_BE_FIRED/controllers/SearchParamsBuilder/types';
import { TAssetThunks } from '__DEPRECATED_DO_NOT_USE_OR_YOU_WILL_BE_FIRED/redux/assets/asset.abstract';
import {
  removeAssets,
  replaceAssets,
  updateAggs,
  updateAssetsData,
} from '__DEPRECATED_DO_NOT_USE_OR_YOU_WILL_BE_FIRED/redux/assets/asset.slice';
import {
  AssetsData,
  TAssetKind,
} from '__DEPRECATED_DO_NOT_USE_OR_YOU_WILL_BE_FIRED/redux/assets/asset.types';
import {
  ContentType,
  FilterOption,
  FilterState,
  FilterTab,
  selectCurrentFilter,
} from '__DEPRECATED_DO_NOT_USE_OR_YOU_WILL_BE_FIRED/redux/filter/filter.slice';
import { transformToTemporaryLocaleAsset } from '__DEPRECATED_DO_NOT_USE_OR_YOU_WILL_BE_FIRED/utils/localeTransform';

import { ApplicationMetadataNamespace } from 'model/acp/ApplicationMetadataNamespace';
import { AssetMimeType } from 'model/acp/AssetMimeType';
import Constant from 'model/constant/Constant';
import { addAlert, Alert } from 'redux/alert/alert.slice';
import { AppThunk } from 'redux/store';
import { descending } from 'utils';

import { AssetAction, AssetStatus } from '../constants/StatusUtils';
import SearchParamsBuilder, {
  FETCH_ALL_OWNERS_PARAMS,
} from '../SearchParamsBuilder/SearchParamsBuilder';
import { DesignAsset, TemplateAsset } from './SparkSearchTypes';

export const BASE_PATH = '/v2/content';
export const UNAPPROVED_ASSETS_BASE_PATH = '/content/cp/unapproved';
const IMAGE_METADATA_LINK_PRIMARY =
  'http://ns.adobe.com/adobecloud/rel/primary';
const IMAGE_METADATA_LINK_RENDITION =
  'http://ns.adobe.com/adobecloud/rel/rendition';
const DIRECTORIES_FILTER = 'contentType=vnd.adobecloud.directory+json';
const SPARK_SEARCH_CONSTANTS = {
  MAX_PREMIUM_IMAGE_DIM: 160,
  ASSETS_DIRECTORY_BASE: '/content/assets/publish/',
};
const { url } = new Constant();

export function getBasePath(tab: FilterTab) {
  return tab === FilterTab.review ? UNAPPROVED_ASSETS_BASE_PATH : BASE_PATH;
}

export type SearchAsset = TemplateAsset | DesignAsset;

export interface SparkSearchAgg {
  key: string;
  count: number;
  label?: string;
}

export interface SparkSearchAggs {
  [aggTerm: string]: SparkSearchAgg[] | undefined;
}

export function assetDirectoryLabelFromFullPath(fullPath: string) {
  const prefix = SPARK_SEARCH_CONSTANTS.ASSETS_DIRECTORY_BASE;
  const folderPath = fullPath.split(prefix)[1];

  if (!folderPath) return 'ROOT';
  const label = folderPath
    .split('/')
    .map((chunk) =>
      chunk
        .split(' ')
        .map((word) => (word[0] || '').toUpperCase() + word.substring(1))
        .join(' '),
    )
    .join('/');

  return label;
}

/**
 * Returns rendition data, including the image url, for the specified asset
 * @param asset
 * @param width - width in pixels needed to fetch the rendition url
 */
export function getFixedWidthRendition(
  asset: SearchAsset,
  width: number,
  format = 'jpg',
) {
  let thumbnail;

  if (asset.rendition.isMatrix) {
    // tbd: currently getting 404's for these, but also for primary, which doesn't have the matrix stuff...
    const splitPath = asset.rendition.href.split('{;');
    const pathSansMatrix = `${splitPath[0]};`;

    let thumbnailWidth = width;

    if (splitPath[0].indexOf('/premium/content/') !== -1) {
      // tbd: can we know if the user actually has premium on their account and we could use the larger size?
      thumbnailWidth = SPARK_SEARCH_CONSTANTS.MAX_PREMIUM_IMAGE_DIM;
    }

    /*
     *const matrixChunks = splitPath[1].substring(0, splitPath[1].length - 1).split(',').map((chunk) => {
     *    let chunkVal: string | number = '';
     *    switch(chunk) {
     *    case 'page':
     *        chunkVal = 0;
     *        break;
     *    case 'type':
     *        chunkVal = 'image/jpg'; //couldn't get this to actually work... will revisit once the OPTIONS fix makes it easier
     *        break;
     *    case 'size':
     *        chunkVal = thumbnailWidth;
     *        break;
     *    default:
     *        chunkVal = '';
     *        break;
     *    }
     *    return `${chunk}=${chunkVal}`;
     *});
     *thumbnail = pathSansMatrix + matrixChunks.join(';');
     */
    thumbnail = `${pathSansMatrix}size=${thumbnailWidth}`;
  } else {
    thumbnail = asset.rendition.href
      .replace('{format}', format)
      .replace('{dimension}', 'width')
      .replace('{size}', `${width}`);
  }

  return {
    href: thumbnail,
    width,
    height: (width * asset.rendition.maxHeight) / asset.rendition.maxWidth,
    size: new Size(
      width,
      (width * asset.rendition.maxHeight) / asset.rendition.maxWidth,
    ),
  };
}

export function getPrimaryRendition(asset: SearchAsset, fallbackWidth: number) {
  if (asset.primaryImage) return asset.primaryImage;

  return getFixedWidthRendition(asset, fallbackWidth);
}

export function mapAssetToDesignAsset(asset: any): DesignAsset {
  const { _links: links } = asset;
  let rendition = links?.[IMAGE_METADATA_LINK_RENDITION];

  const primaryImage = links?.[IMAGE_METADATA_LINK_PRIMARY];

  if (rendition) {
    rendition = {
      ...rendition,
      isMatrix: true,
    };
  } else {
    /*
     * use placeholder kitty. Note that because the only asset dimension info is in the rendition, we can't fake the
     * actual asset's rendition aspect ratio
     */
    rendition = {
      href: 'https://cdn.cp.adobe.io/content/2/dcx/1eb0fb1d-0d0f-499f-bcff-54fa3bd55812/rendition/preview.jpg/version/1/format/{format}/dimension/{dimension}/size/{size}',
      maxWidth: 1200,
      maxHeight: 1200,
      isMatrix: false,
    };
  }

  let designAssetKind = TAssetKind.image;

  if (asset.contentType === AssetMimeType.Directory) {
    designAssetKind = TAssetKind.folder;
  } else if (asset.contentType === AssetMimeType.Collection) {
    designAssetKind = TAssetKind.collection;
  }

  const designAsset = {
    ...asset,
    modifyDate: new Date(asset.modifyDate),
    createDate: new Date(asset.createDate),
    kind: designAssetKind,
    status: AssetStatus.published,
    rendition,
    primaryImage,
    _score: asset._score,
    schema: SearchSchema.asset,
  };

  return designAsset;
}

function mapResults(schema: SearchSchema) {
  return (asset: any) => {
    if (schema === SearchSchema.asset) {
      return mapAssetToDesignAsset(asset);
    }

    return {
      ...asset,
      kind: TAssetKind.template,
      collections: asset.collections || [],
      categories: asset.categories || [],
      tasks: asset.tasks || [],
      topics: asset.topics || [],
      locales: asset.locales || [],
      title: asset.title || '',
      description: asset.description || '',
      status: asset.status || AssetStatus.approved,
      schema: SearchSchema.template,
    };
  };
}

function mapAggs(bucket: any) {
  return {
    key: bucket.key,
    count: bucket.docCount,
  };
}

/**
 * Sort comparator function for a list of @type {SearchAsset}
 * Only use this for simple strings like dates and numbers.
 * @param prop - a property that has a string value in @type {SearchAsset}
 */
export function sortResults(prop: string) {
  return (asset1: SearchAsset, asset2: SearchAsset) => {
    if (asset1[prop] < asset2[prop]) {
      return -1;
    }

    if (asset1[prop] > asset2[prop]) {
      return 1;
    }

    return 0;
  };
}

export function filterTemplates(
  assets: TemplateAsset[],
  paramsBuilder: InstanceType<typeof SearchParamsBuilder>,
): AppThunk<{ assets: TemplateAsset[] }> {
  return (_dispatch, getState) => {
    const { filters } = selectCurrentFilter(getState());
    const { prop, isDescending } = paramsBuilder.order;
    const results: TemplateAsset[] = assets
      .filter((asset: TemplateAsset) =>
        SearchParamsBuilder.isTemplateFilterMatch(asset, filters),
      )
      .sort(isDescending ? descending(sortResults(prop)) : sortResults(prop));

    return {
      assets: results,
    };
  };
}

class SparkSearch implements TAssetThunks {
  static instance: InstanceType<typeof SparkSearch> = new SparkSearch();
  static MAX_PREMIUM_IMAGE_DIM = SPARK_SEARCH_CONSTANTS.MAX_PREMIUM_IMAGE_DIM;
  static ASSETS_DIRECTORY_BASE = SPARK_SEARCH_CONSTANTS.ASSETS_DIRECTORY_BASE;
  getUpdateUrl = (id: string) => `${url.searchServiceBase}/content/cp/${id}`;
  /**
   * Fetches approved assets from the Spark Content Search Service.
   * If successful, returns assets, the path to fetch more assets, aggregations, total and count
   * @param path - path string that should contain @constant BASE_PATH
   */
  async fetchApprovedAssets(
    path: string,
    params?: SearchParams,
    schema?: SearchSchema,
  ) {
    /*
     * Spark Search bug: asset count does not add up to
     * the total when we fetch with only '-remixCount'.
     * Adding '-created' fixes the problem.
     */
    if (params && params.orderBy === '-remixCount') {
      params.orderBy = '-remixCount,-created';
    }

    if (params) {
      schema = params.schema;
    } else {
      schema = schema ?? SearchSchema.template;
    }

    const config = {
      params: {
        ...params,

        // TODO: Replace with proper cache busting where the server respects no-cache headers
        cacheDate: new Date().toISOString(),
      },
    };
    const response = await Axios.get(url.searchServiceBase + path, config);
    const results = response.data._embedded.results.map(mapResults(schema));
    const next = response.data._links?.next
      ? response.data._links.next.href
      : null;
    const assets = results.length > 0 ? results : [];
    const aggs: SparkSearchAggs = {};

    if (response.data._embedded.aggregations) {
      Object.keys(response.data._embedded.aggregations).forEach(
        (aggTerm: string) => {
          const term = aggTerm;

          aggs[term] =
            response.data._embedded.aggregations[aggTerm].buckets.map(mapAggs);
        },
      );
    }

    const { total } = response.data._embedded;
    const { count } = response.data._embedded;

    return {
      assets,
      next,
      aggs,
      total,
      count,
    };
  }
  /**
   * Fetches unapproved assets using the Spark Content Search Service.
   * Unapproved assets come directly from CP and are not indexed by the search service.
   * @param path - path string that should contain @constant UNAPPROVED_ASSETS_BASE_PATH
   * @param limit - max number of assets to receive in the response payload
   */
  async fetchUnapprovedAssets(
    path: string,
    schema: SearchSchema = SearchSchema.template,
    limit = 50,
  ) {
    const config = {
      params: {
        limit,

        // TODO: Replace with proper cache busting where the server respects no-cache headers
        cacheDate: new Date().toISOString(),
      },
    };
    const response = await Axios.get(url.searchServiceBase + path, config);
    const next = response.data._links?.next
      ? response.data._links.next.href
      : null;
    const { total } = response.data._embedded;
    const results = response.data._embedded.results.map(mapResults(schema));
    const assets = results.length > 0 ? results : [];

    return {
      assets,
      next,
      total,
    };
  }
  /**
   * Fetches all owner ids that have created assets indexed by the Spark Content Search Service
   */
  async fetchOwners(): Promise<{ key: string; docCount: number }[]> {
    const config = {
      params: {
        ...FETCH_ALL_OWNERS_PARAMS,
      },
    };
    const response = await Axios.get(url.searchServiceBase + BASE_PATH, config);
    // map results to a string[] containing only owner ids
    const results: { key: string; docCount: number }[] =
      response.data._embedded.aggregations['owner.id'].buckets.map(
        (bucket: any) => ({
          key: bucket.key,
          docCount: bucket.docCount,
        }),
      );
    const ownerAggs = results.length > 0 ? results : [];

    return ownerAggs;
  }
  // path has to be in the format "/content/assets/publish"
  private async _resolvePath(path: string): Promise<any> {
    return Axios.get(
      `${url.searchServiceBase}${BASE_PATH}/resolve?path=${path}`,
    );
  }
  private async _fetchChildrenByPath(
    path: string,
    directoriesOnly = false,
  ): Promise<[]> {
    const asset = await this._resolvePath(path);

    if (asset) {
      const directoryFilter = directoriesOnly ? DIRECTORIES_FILTER : undefined;
      const results = await Axios.get(
        `${url.searchServiceBase}${BASE_PATH}/folders/${asset.data.id}/children?${directoryFilter}`,
      );

      return results.data._embedded.children;
    }

    return Promise.resolve([]);
  }
  public async fetchFilterDirectoryOptions(
    path: string,
  ): Promise<FilterOption[]> {
    const childrenAssets = await this._fetchChildrenByPath(path, true);

    return childrenAssets.map((child: any) => ({
      label: child.name,
      value: child.path,
    }));
  }
  /**
   * Fetches a single asset using the asset's id
   */
  async fetchSingleAsset(schema: SearchSchema, id: string) {
    const paramsBuilder = new SearchParamsBuilder(schema, '*', 1);

    paramsBuilder.setFilters({
      id: [
        {
          label: 'ID',
          value: id,
          isExactMatch: true,
        },
      ],
    });

    const config = {
      params: {
        ...paramsBuilder.params,
      },
    };
    const response = await Axios.get(url.searchServiceBase + BASE_PATH, config);
    const results = response.data._embedded.results.map(mapResults(schema));
    const asset: SearchAsset = results[0];

    return asset;
  }
  /**
   * Fetches authoring applicationMetadata from a published asset id
   */
  async fetchApplicationMetadataFromPublishedUrn(publishedAssetId: string) {
    const repoLink = `${url.platformStorageBase}/id/${publishedAssetId}/:applicationmetadata`;
    const response = await Axios.get(repoLink);

    return response.data[ApplicationMetadataNamespace];
  }
  // NOTE: This is ONLY for TemplateAsset's currently, as they're they only CP things...
  async updateAssetMetadata(asset: TemplateAsset) {
    const updateUrl = this.getUpdateUrl(asset.id);

    /*
     * This PATCH request is actually a PUT request to CP.
     * All classification properties in a TemplateAsset map to the
     * 'tags' field in CP, so we need to send the entire asset model to update it.
     */
    await Axios.patch(updateUrl, asset);

    return true;
  }
  reviewSelectedAssets(newStatus: {
    label: AssetAction;
    value: AssetStatus;
  }): AppThunk {
    return (dispatch, getState) => {
      const { selectedAssetIds } = getState().assets;
      const current: TemplateAsset[] = getState().assets
        .current as TemplateAsset[];
      const currentStatus: FilterOption[] | undefined = selectCurrentFilter(
        getState(),
      ).filters.status;
      /*
       * only update the status of assets that do not already
       * have the same value of newStatus
       */
      const selectedAssets: TemplateAsset[] = current.filter(
        (asset: TemplateAsset) => {
          if (asset.status === newStatus.value) {
            return false;
          }

          return selectedAssetIds.includes(asset.id);
        },
      );

      if (selectedAssetIds.length === 0) {
        return infoToast('No Assets Selected', {
          closable: false,
          timeout: 1000,
        });
      }

      const alert: Alert = {
        type:
          newStatus.value === AssetStatus.rejected
            ? 'destructive'
            : 'confirmation',
        id: `ReviewSelectedAssets-${newStatus.label}`,
        title: `
                    Do you want to ${newStatus.label.toLowerCase()} these
                    (${selectedAssetIds.length}) templates?
                `,
        content: '',
        cancelLabel: 'Cancel',
        confirmLabel: 'Yes',
        onConfirm: async () => {
          try {
            const updatedAssets: TemplateAsset[] = await Promise.all(
              selectedAssets.map(async (asset: TemplateAsset) => {
                const updatedAsset = {
                  ...asset,
                  status: newStatus.value,
                };

                await this.updateAssetMetadata(updatedAsset);

                return updatedAsset;
              }),
            );
            const replacedAssets: TemplateAsset[] = updatedAssets.filter(
              (asset: TemplateAsset) =>
                SearchParamsBuilder.isStatusMatch(asset, currentStatus),
            );
            const removedAssets: string[] = updatedAssets
              .filter(
                (asset: TemplateAsset) =>
                  !SearchParamsBuilder.isStatusMatch(asset, currentStatus),
              )
              .map((asset: TemplateAsset) => asset.id);

            if (removedAssets.length > 0) {
              dispatch(removeAssets(removedAssets));
            }

            if (replacedAssets.length > 0) {
              dispatch(replaceAssets(replacedAssets));
            }

            successToast(`Assets ${newStatus.value}`, {
              closable: false,
              timeout: 2000,
            });
          } catch (error) {
            let failedAsset = {
              id: 'unknown',
            };

            if (error.config?.data) {
              failedAsset = JSON.parse(error.config.data);
            }

            const errorAlert: Alert = {
              type: 'error',
              id: `BulkUpdateStatus-${newStatus.label}`,
              title: `
                                Bulk Review Failed.
                                Unable to ${newStatus.label.toLowerCase()} all assets.
                            `,
              content: `
                                ${error.message}.
                                Failed to update asset: ${failedAsset.id}
                            `,
            };

            dispatch(addAlert(errorAlert));
          }
        },
      };

      dispatch(addAlert(alert));

      return undefined;
    };
  }
  /**
   * Use contentType and tab to return the correct instance of SearchParamsBuilder
   * after applying all filters from the global state
   */
  getParamsBuilder(
    tab: FilterTab,
    contentType: ContentType,
  ): AppThunk<InstanceType<typeof SearchParamsBuilder>> {
    return (dispatch, getState) => {
      // get filter state depending on contentType and tab
      const filterStates = getState().filters;
      const filterState: FilterState = filterStates[contentType][tab];
      const { filters } = filterState;
      const { query } = filterState;
      const { order } = filterState;
      const searchParamsInstance =
        contentType === ContentType.templates
          ? SearchParamsBuilder.templates
          : SearchParamsBuilder.assets;

      searchParamsInstance.setFilters(filters);
      searchParamsInstance.updateQuery(query);
      searchParamsInstance.updateOrder(order);

      return searchParamsInstance;
    };
  }
  /*
   * tbd: we really need a way to specify that a specific fetch should cause any that haven't returned yet to
   * ignore their return
   */
  fetchAssets(
    tab: FilterTab,
    options: {
      forceFetch: boolean;
      contentType?: ContentType;
    } = {
      forceFetch: false,
      contentType: ContentType.templates,
    },
  ): AppThunk<Promise<SearchAsset[]>> {
    return async (dispatch, getState) => {
      const basePath: string = getBasePath(tab);
      const { forceFetch, contentType = ContentType.templates } = options;
      const paramsBuilder = dispatch(this.getParamsBuilder(tab, contentType));
      let assets: SearchAsset[] = [];

      // eslint-disable-next-line no-useless-catch
      try {
        let assetsData: AssetsData = {};

        if (tab === FilterTab.review) {
          assets = getState().assets.current as SearchAsset[];

          /*
           * only fetch unapproved assets if we don't have any in the global state
           * OR if we're forced to fetch (refresh)
           */
          if (
            assets.length === 0 ||
            forceFetch ||
            assets[0].kind !== TAssetKind.template
          ) {
            const response = await this.fetchUnapprovedAssets(
              basePath,
              SearchSchema.template,
            );

            assets = [...response.assets].map(transformToTemporaryLocaleAsset);

            assetsData = {
              total: response.total,
              paths: [basePath, response.next],
              current: assets,
            };
          }

          const filtered = dispatch(
            filterTemplates(assets as TemplateAsset[], paramsBuilder),
          );

          assets = filtered.assets;
        } else {
          // max limit for SCS is 100
          paramsBuilder.limit = 100;

          // approved assets
          const response = await this.fetchApprovedAssets(
            basePath,
            paramsBuilder.params,
          );

          assets = [...response.assets].map(transformToTemporaryLocaleAsset);

          assetsData = {
            total: response.total,
            count: assets.length,
            paths: [basePath, response.next],
            current: assets,
          };

          if (response.total) {
            if (response.aggs.directory?.length) {
              response.aggs.directory.forEach((dir) => {
                dir.label = assetDirectoryLabelFromFullPath(dir.key);
                dir.key += '/'; // anding directory in '/' will search subdirectories as well :)
              });
            }

            // update aggs
            dispatch(updateAggs(response.aggs));
          }
        }

        dispatch(updateAssetsData(assetsData));

        return assets;
      } catch (error) {
        throw error;
      }
    };
  }
  isTemplate(searchAsset: SearchAsset): boolean {
    return (
      searchAsset.kind === TAssetKind.template &&
      searchAsset.schema === SearchSchema.template
    );
  }
  fetchNextAssets(tab: FilterTab): AppThunk<Promise<SearchAsset[]>> {
    return async (dispatch, getState) => {
      const { paths } = getState().assets;
      const nextPath: string = paths[paths.length - 1];
      const current: SearchAsset[] = getState().assets.current as SearchAsset[];

      let allAssets: SearchAsset[] = [];
      let nextAssets: SearchAsset[] = [];

      if (nextPath) {
        let response;

        try {
          const assetsData: AssetsData = {};

          // fetch the next set of assets with the next path
          if (tab === FilterTab.review) {
            response = await this.fetchUnapprovedAssets(
              nextPath,
              SearchSchema.template,
            );
            const transformedAssets = [...response.assets].map(
              transformToTemporaryLocaleAsset,
            ) as TemplateAsset[];

            allAssets = [...current, ...transformedAssets];

            const filtered = dispatch(
              filterTemplates(
                [...transformedAssets],
                SearchParamsBuilder.templates,
              ),
            );
            const filteredAll = dispatch(
              filterTemplates(
                allAssets as TemplateAsset[],
                SearchParamsBuilder.templates,
              ),
            );

            nextAssets = filtered.assets as TemplateAsset[];
            assetsData.count = filteredAll.assets.length;
            assetsData.total = response.total;
          } else {
            const schema = this.isTemplate(current[0])
              ? SearchSchema.template
              : SearchSchema.asset;

            response = await this.fetchApprovedAssets(
              nextPath,
              undefined,
              schema,
            );
            nextAssets = [...response.assets].map(
              transformToTemporaryLocaleAsset,
            ) as SearchAsset[];

            allAssets = [...current, ...nextAssets];
            assetsData.count = allAssets.length;
          }

          assetsData.current = allAssets;
          assetsData.paths = [...paths, response.next];

          dispatch(updateAssetsData(assetsData));
        } catch (error) {
          const alert: Alert = {
            id: 'SparkSearch-fetchAssets',
            type: 'error',
            title: 'Spark Search: Unable to fetch the next set of assets',
            content: error.message,
          };

          dispatch(addAlert(alert));
        }
      }

      return nextAssets;
    };
  }
}

export default SparkSearch;
