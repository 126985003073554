/**
 * *****************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2022 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Adobe and its suppliers, if any. The intellectual and technical concepts
 * contained herein are proprietary to Adobe and its suppliers and are
 * protected by all applicable intellectual property laws, including trade
 * secret and copyright laws. Dissemination of this information or reproduction
 * of this material is strictly forbidden unless prior written permission is
 * obtained from Adobe.
 * *****************************************************************************
 */

type Object = {
  [key: string]: any;
};

/**
 * Splits, or chunks, the given array into arrays of the specified size
 * @param array
 * @param size - size of each array in resulting chunked array
 */
export function chunk(array: any[], size: number): any[][] {
  const chunked = [];
  let index = 0;

  while (index < array.length) {
    chunked.push(array.slice(index, size + index));
    index += size;
  }

  return chunked;
}

/**
 * Splits, or chunks, the given array into arrays of the specified size, but forces the
 * first chunk to have at least all the values in the selected array
 * @param array
 * @param size - size of each array in resulting chunked array
 * @param selected -
 */
export function chunkForceSel(
  array: any[],
  size: number,
  selected: any[],
): any[][] {
  if (array.length <= size) return [array.slice(0)];
  if (!selected.length) return chunk(array, size);

  const selectedValuesList: Record<any, boolean> = selected.reduce<
    Record<any, boolean>
  >((memo, option) => {
    memo[option.value] = true;

    return memo;
  }, {});
  let numMatched = 0;
  let lastMatchIndex = -1;
  array.find((o, index) => {
    if (selectedValuesList[o.value]) {
      numMatched++;
      lastMatchIndex = index;

      return numMatched === selected.length;
    }

    return false;
  });

  if (lastMatchIndex < size) {
    // they're all in first chunk
    return chunk(array, size);
  }

  const chunked = [array.slice(0, lastMatchIndex + 1)];

  let index = lastMatchIndex + 1;

  while (index < array.length) {
    chunked.push(array.slice(index, size + index));
    index += size;
  }

  return chunked;
}

/**
 * Creates and returns the object with a subset of its keys
 * @param obj
 * @param props - a subset of keys that should exist in obj
 */
export function pick(obj: Object, props: string[]): Object {
  const pickedObj: typeof obj = {};

  props.forEach((prop: string) => {
    pickedObj[prop] = obj[prop];
  });

  return pickedObj;
}

/**
 * Negates the return value of a comparator function.
 * @param comparator - function used to compare two values
 */
export function descending(comparator: (a: any, b: any) => number) {
  return (a: any, b: any) => -comparator(a, b);
}

/**
 * Returns true if the the value of a nested property
 * has been successfully set in an object.
 * @param path - property path that should exist in obj
 * @param value - value to set in obj at property path
 * @param obj
 */
export function setNestedValue(
  path: string[],
  value: any,
  obj: Object,
): boolean {
  try {
    const [prop, ...next] = path;

    if (next.length > 0) {
      if (obj[prop] === undefined) {
        obj[prop] = {};
      }

      return setNestedValue(next, value, obj[prop]);
    }

    obj[prop] = value;

    return true;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);

    return false;
  }
}

/**
 * Gets the value of a nested property in an object
 * @param path - property path that should exist in obj
 * @param obj
 */
export function getNestedValue(path: string[], obj: Object): any {
  const [prop, ...next] = path;

  if (next.length > 0) {
    if (obj[prop] === undefined) {
      return undefined;
    }

    return getNestedValue(next, obj[prop]);
  }

  return obj[prop];
}
