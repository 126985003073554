/**
 * *****************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2022 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Adobe and its suppliers, if any. The intellectual and technical concepts
 * contained herein are proprietary to Adobe and its suppliers and are
 * protected by all applicable intellectual property laws, including trade
 * secret and copyright laws. Dissemination of this information or reproduction
 * of this material is strictly forbidden unless prior written permission is
 * obtained from Adobe.
 * *****************************************************************************
 */

import { ReactElement } from 'react';

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type AlertType =
  | 'error'
  | 'information'
  | 'confirmation'
  | 'destructive';
export type Alert = {
  type: AlertType;
  title: string;
  id: string;
  content: string | ReactElement;
  cancelLabel?: string;
  secondaryLabel?: string;
  confirmLabel?: string;
  onConfirm?: (label: 'primary' | 'secondary') => any;
};

export interface AlertState {
  alerts: Alert[];
}

const initialState: AlertState = {
  alerts: [],
};

export const alertSlice = createSlice({
  name: 'alerts',
  initialState,
  reducers: {
    addAlert: (state, action: PayloadAction<Alert>) => {
      if (!state.alerts.find((alert) => alert.id === action.payload.id)) {
        state.alerts.push(action.payload);
      }
    },
    removeAlert: (state, action: PayloadAction<string>) => {
      state.alerts = state.alerts.filter(
        (alert) => alert.id !== action.payload,
      );
    },
  },
});

export const { addAlert, removeAlert } = alertSlice.actions;

export default alertSlice.reducer;
