/**
 * *****************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2022 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Adobe and its suppliers, if any. The intellectual and technical concepts
 * contained herein are proprietary to Adobe and its suppliers and are
 * protected by all applicable intellectual property laws, including trade
 * secret and copyright laws. Dissemination of this information or reproduction
 * of this material is strictly forbidden unless prior written permission is
 * obtained from Adobe.
 * *****************************************************************************
 */

export enum SearchSchema {
  template = 'TEMPLATE',
  asset = 'ASSET',
}

export type SearchParams = {
  [key: string]: any;
  schema: SearchSchema;
  q: string;
  filters: string | null;
  limit: number;
  qLoc: string;
  orderBy?: string;
  aggs?: string;
  aggsLimit?: number;
};

export type OrderValue = {
  prop: string;
  isDescending: boolean;
};

/**
 * Properties supported by the Spark Search Service for the filters parameter
 */
export type FilterProp =
  | 'premium'
  | 'tasks'
  | 'categories'
  | 'owner'
  | 'id'
  | 'status'
  | 'type'
  | 'locales'
  | 'language'
  | 'animated'
  | 'topics'
  | 'licensingCategory'
  | 'directory'
  | 'contentType'
  | 'l2'
  | 'applicableRegions';

/**
 * Returns true if prop is a boolean value
 * @param prop Filter prop
 */
export function isBooleanFilter(prop: FilterProp): boolean {
  return prop === 'premium';
}

/**
 * Returns true if prop is a string[] value
 * @param prop Filter prop
 */
export function isStringArrayFilter(prop: FilterProp): boolean {
  return !isBooleanFilter(prop) && prop !== 'status';
}
