/**
 * *****************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2022 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Adobe and its suppliers, if any. The intellectual and technical concepts
 * contained herein are proprietary to Adobe and its suppliers and are
 * protected by all applicable intellectual property laws, including trade
 * secret and copyright laws. Dissemination of this information or reproduction
 * of this material is strictly forbidden unless prior written permission is
 * obtained from Adobe.
 * *****************************************************************************
 */

import React, { ReactElement } from 'react';

import { Flex, View } from '@adobe/react-spectrum';

import TreeView from 'components/@spectrum/TreeView';
import AssetExplorerHeader from 'components/AssetExplorer/AssetExplorerHeader';
import FolderPermissionsTable from 'components/FolderPermissionsTable';
import useConstant from 'hooks/useConstant';
import { ScreenTitle } from 'model/ScreenTitle';

export default function ViewPermissionsScreen(): ReactElement {
  const { acpBasePath } = useConstant();

  return (
    <Flex width="100%" height="100%">
      <View
        backgroundColor="static-white"
        borderEndColor="static-gray-300"
        borderEndWidth="thin"
        height="100%"
        width="size-4000"
      >
        <TreeView
          path={acpBasePath.assets}
          pathPrefix="/permissions"
          title={ScreenTitle.Permissions}
        />
      </View>
      <Flex direction="column" flex height="100%">
        <View paddingX="size-300" paddingTop="size-300">
          <AssetExplorerHeader hasPathPrefix />
        </View>
        <View flex minHeight="size-0" padding="size-300">
          <FolderPermissionsTable />
        </View>
      </Flex>
    </Flex>
  );
}
