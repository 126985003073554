/**
 * *****************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2022 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Adobe and its suppliers, if any. The intellectual and technical concepts
 * contained herein are proprietary to Adobe and its suppliers and are
 * protected by all applicable intellectual property laws, including trade
 * secret and copyright laws. Dissemination of this information or reproduction
 * of this material is strictly forbidden unless prior written permission is
 * obtained from Adobe.
 * *****************************************************************************
 */

import React from 'react';

import { Text, View } from '@adobe/react-spectrum';
import Reorder from '@spectrum-icons/workflow/Reorder';

import { AssetMetadataFormFieldProps } from './AssetMetadataFormFieldProps';
import ToggleNumberField from './ToggleNumberField';

export default function BPMFormField({
  isReadOnly,
  onChange,
  value,
}: AssetMetadataFormFieldProps<number | undefined>) {
  return (
    <View>
      <ToggleNumberField
        isReadOnly={isReadOnly}
        aria-label="BPM"
        onChange={onChange}
        value={value}
        maxValue={300}
      >
        <Reorder marginEnd="size-50" size="S" />
        <Text>BPM</Text>
      </ToggleNumberField>
    </View>
  );
}
