/**
 * *****************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2022 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Adobe and its suppliers, if any. The intellectual and technical concepts
 * contained herein are proprietary to Adobe and its suppliers and are
 * protected by all applicable intellectual property laws, including trade
 * secret and copyright laws. Dissemination of this information or reproduction
 * of this material is strictly forbidden unless prior written permission is
 * obtained from Adobe.
 * *****************************************************************************
 */

import React, { Key, ReactElement, useContext } from 'react';

import { View } from '@adobe/react-spectrum';
import { ComboBox, Item } from '@react-spectrum/combobox';

import { MetadataOption } from '__DEPRECATED_DO_NOT_USE_OR_YOU_WILL_BE_FIRED/redux/metadata/metadata.types';

import { TagInputContext } from './TagInputContext';

type Props = {
  onSelectAll: () => void;
};

export default function DeterminateInput({ onSelectAll }: Props): ReactElement {
  const {
    currentItems,
    setCurrentItems,
    possibleItems,
    singularLabel,
    isSingleSelect,
  } = useContext(TagInputContext);
  let comboBoxItems: (string | MetadataOption)[] = [];

  if (possibleItems) {
    comboBoxItems = possibleItems.filter(
      (item: string): boolean => !currentItems.includes(item),
    );
  }

  const onChange = (newValue: Key): void => {
    if (newValue) {
      if (isSingleSelect) {
        setCurrentItems([String(newValue)]);
      } else {
        const deduped = new Set([...currentItems, String(newValue)]);
        setCurrentItems(Array.from(deduped));
      }
    }
  };

  if (comboBoxItems.length === 0) {
    onSelectAll();

    return <div />;
  }

  return (
    <View UNSAFE_style={{ marginTop: '-8px' }}>
      <ComboBox
        isQuiet
        autoFocus
        selectedKey={0}
        onSelectionChange={onChange}
        placeholder={`Enter ${singularLabel}`}
        aria-label={singularLabel}
      >
        {comboBoxItems.map((item: string | MetadataOption): ReactElement => {
          const metadataItem = item as MetadataOption;
          const stringItem = item as string;

          if (metadataItem.value) {
            return <Item key={metadataItem.value}>{metadataItem.label}</Item>;
          }

          return <Item key={stringItem}>{stringItem}</Item>;
        })}
      </ComboBox>
    </View>
  );
}
