/**
 * *****************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2022 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Adobe and its suppliers, if any. The intellectual and technical concepts
 * contained herein are proprietary to Adobe and its suppliers and are
 * protected by all applicable intellectual property laws, including trade
 * secret and copyright laws. Dissemination of this information or reproduction
 * of this material is strictly forbidden unless prior written permission is
 * obtained from Adobe.
 * *****************************************************************************
 */

import React, { ReactElement } from 'react';

import { DesignAsset } from '__DEPRECATED_DO_NOT_USE_OR_YOU_WILL_BE_FIRED/controllers/api/SparkSearchTypes';

import { AssetStatus } from 'model/acp/AssetStatus';
import { Entitlement } from 'model/acp/Entitlement';
import { ZoomLevel } from 'model/ZoomLevel';

import BaseCard, { BaseCardProps } from './BaseCard';
import BaseRendition from './BaseRendition';
import SearchAssetRendition from './SearchAssetRendition';

const RENDITION_SIZE = 160;

type SearchAssetCardProps = BaseCardProps & {
  asset: DesignAsset;
  zoomLevel: ZoomLevel;
};

export default function SearchAssetCard({
  asset,
  isActive,
  isSelected,
  onAction,
  onClick,
  onSelect,
  quickActions,
  zoomLevel,
}: SearchAssetCardProps): ReactElement {
  return (
    <BaseCard
      canBeSelected
      isActive={isActive}
      isSelected={isSelected}
      metadata={[['Folder', asset.directory.split('/').pop()]]}
      onAction={onAction}
      onClick={onClick}
      onSelect={onSelect}
      rendition={
        <BaseRendition
          isPremium={asset.licensingCategory === Entitlement.Premium}
        >
          <SearchAssetRendition
            link={asset.rendition.href}
            size={RENDITION_SIZE}
          />
        </BaseRendition>
      }
      size={zoomLevel}
      subtitle={asset.contentType}
      status={AssetStatus.Default}
      title={asset.name}
      quickActions={quickActions}
    />
  );
}
