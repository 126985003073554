/**
 * *****************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2022 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Adobe and its suppliers, if any. The intellectual and technical concepts
 * contained herein are proprietary to Adobe and its suppliers and are
 * protected by all applicable intellectual property laws, including trade
 * secret and copyright laws. Dissemination of this information or reproduction
 * of this material is strictly forbidden unless prior written permission is
 * obtained from Adobe.
 * *****************************************************************************
 */

import React, { ReactElement, useCallback } from 'react';

import DetailActionButtons from 'components/DetailActionButtons';
import Loading from 'components/Loading';
import LocalizedField from 'components/LocalizedField';
import useApplicationMetadata from 'hooks/acp/useApplicationMetadata';
import useRepoMetadata from 'hooks/acp/useRepoMetadata';
import useAssetMetadataModel from 'hooks/useAssetMetadataModel';
import { useCCXPublicConfig } from 'hooks/useCCXConfig';
import { CollectionQueryModel } from 'model/acp/CollectionQueryModel';
import { ValidationFieldErrors } from 'model/errors/ValidationError';
import { AssetMetadataModelKey } from 'model/metadata/AssetMetadataModel';
import { CollectionMetadataModel } from 'model/metadata/CollectionMetadataModel';
import { verifyCollectionQueryModel } from 'utils/validation/collections';

import ApplicableRegionFormField from './fields/ApplicableRegionFormField';
import CollectionNameFormField from './fields/CollectionNameFormField';
import LanguagesFormField from './fields/LanguagesFormField';
import OptionsFormGroup from './fields/OptionsFormGroup';
import PriorityFormField from './fields/PriorityFormField';
import DefaultMetadataFormLayout from './LEGACYDefaultMetadataFormLayout';

type CollectionMetadataFormProps = {
  editedQueryModel: CollectionQueryModel | undefined;
  hasExternalChanges?: boolean;
  isCollapsed?: boolean;
  isSaving: boolean;
  onCollapse?: () => void;
  onReset?: () => void;
  onSave: (
    model: CollectionMetadataModel,
  ) => Promise<ValidationFieldErrors | undefined>;
  path?: string;
};

export default function CollectionMetadataForm({
  editedQueryModel,
  hasExternalChanges = false,
  isCollapsed = false,
  isSaving,
  onCollapse,
  onReset,
  onSave,
  path,
}: CollectionMetadataFormProps): ReactElement {
  const { results: ccxPublicConfig } = useCCXPublicConfig();
  const warnings = verifyCollectionQueryModel(editedQueryModel);
  const {
    hasChanges,
    isLoading,
    model,
    reset,
    setModelField,
    setValidationErrors,
    validationErrors,
  } = useAssetMetadataModel({ path, type: 'collection' });
  const { results: repoMetadata } = useRepoMetadata({ path: path ?? '' });
  const { results: applicationMetadata } = useApplicationMetadata({
    path: path ?? '',
  });
  const handleReset = useCallback(async () => {
    reset();

    if (onReset) {
      onReset();
    }
  }, [onReset, reset]);
  const handleCollapse = useCallback(() => {
    if (onCollapse) {
      onCollapse();
    }
  }, [onCollapse]);
  const handleSave = useCallback(async () => {
    if (!model) {
      return;
    }

    setValidationErrors();

    const errors = await onSave(model);

    if (errors) {
      setValidationErrors(errors);
    }
  }, [model, onSave, setValidationErrors]);

  if (isLoading || !model) {
    return <Loading />;
  }

  return (
    <DefaultMetadataFormLayout
      hasChanges={hasChanges || hasExternalChanges}
      isCollapsed={isCollapsed}
      isSaving={isSaving}
      onCollapse={handleCollapse}
      onReset={handleReset}
      onSave={handleSave}
      path={path}
      customFixedFields={
        editedQueryModel?.source
          ? [['Collection Source', editedQueryModel.source]]
          : undefined
      }
      warnings={warnings}
      actions={
        path &&
        applicationMetadata !== undefined &&
        repoMetadata !== undefined ? (
          <DetailActionButtons
            applicationMetadata={applicationMetadata}
            hasStagedChanges={hasChanges || hasExternalChanges}
            path={path}
            repoMetadata={repoMetadata}
          />
        ) : undefined
      }
    >
      <CollectionNameFormField
        isDisabled={isSaving || path !== undefined}
        isReadOnly={isSaving || path !== undefined}
        error={validationErrors[AssetMetadataModelKey.Name]}
        onChange={setModelField(AssetMetadataModelKey.Name)}
        value={
          path
            ? model[AssetMetadataModelKey.Name].replace('.collection', '')
            : model[AssetMetadataModelKey.Name]
        }
      />

      <LocalizedField
        label="Default Title"
        onChange={setModelField(AssetMetadataModelKey.Title)}
        value={model[AssetMetadataModelKey.Title]}
      />

      <OptionsFormGroup title="Flags">
        <PriorityFormField
          isDisabled={isSaving}
          isReadOnly={isSaving}
          error={validationErrors[AssetMetadataModelKey.Priority]}
          onChange={setModelField(AssetMetadataModelKey.Priority)}
          value={model[AssetMetadataModelKey.Priority]}
        />
      </OptionsFormGroup>

      <ApplicableRegionFormField
        isDisabled={isSaving}
        isReadOnly={isSaving}
        error={validationErrors[AssetMetadataModelKey.ApplicableRegions]}
        options={ccxPublicConfig?.common.applicableRegions.options}
        onChange={setModelField(AssetMetadataModelKey.ApplicableRegions)}
        value={model[AssetMetadataModelKey.ApplicableRegions]}
      />

      <LanguagesFormField
        isDisabled={isSaving}
        isReadOnly={isSaving}
        error={validationErrors[AssetMetadataModelKey.Language]}
        options={ccxPublicConfig?.common.language.options}
        onChange={setModelField(AssetMetadataModelKey.Language)}
        value={model[AssetMetadataModelKey.Language]}
      />
    </DefaultMetadataFormLayout>
  );
}

CollectionMetadataForm.defaultProps = {
  hasExternalChanges: false,
  isCollapsed: false,
  onCollapse: undefined,
  onReset: undefined,
  path: undefined,
};
