/**
 * *****************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2022 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Adobe and its suppliers, if any. The intellectual and technical concepts
 * contained herein are proprietary to Adobe and its suppliers and are
 * protected by all applicable intellectual property laws, including trade
 * secret and copyright laws. Dissemination of this information or reproduction
 * of this material is strictly forbidden unless prior written permission is
 * obtained from Adobe.
 * *****************************************************************************
 */

import React, { Key, ReactElement, useCallback, useMemo } from 'react';

import {
  ActionButton,
  Item,
  Menu,
  MenuTrigger,
  Text,
} from '@adobe/react-spectrum';
import SortOrderDown from '@spectrum-icons/workflow/SortOrderDown';
import SortOrderUp from '@spectrum-icons/workflow/SortOrderUp';

import { OrderBy } from 'model/acp/OrderBy';
import { SortOrder } from 'model/acp/SortOrder';
import { setOrderBy, toggleSortOrder } from 'redux/explorer/explorer.slice';
import { useAppDispatch, useAppSelector } from 'redux/hooks';

export default function SortingMenu(): ReactElement {
  const { orderBy, sortOrder } = useAppSelector((state) => state.explorer);
  const prettyOrderBys = useMemo<Map<OrderBy, string>>(
    (): Map<OrderBy, string> =>
      new Map([
        [OrderBy.Name, 'Name'],
        [OrderBy.ModifyDate, 'Modified'],
        [OrderBy.CreateDate, 'Created'],
        [OrderBy.DeviceCreateDate, 'Device Created'],
        [OrderBy.DeviceModifyDate, 'Device Modified'],
      ]),
    [],
  );
  const orderBys: OrderBy[] = [];

  for (const orderByOption in OrderBy) {
    if (Object.prototype.hasOwnProperty.call(OrderBy, orderByOption)) {
      const order = orderByOption as unknown as OrderBy;

      if (prettyOrderBys.has(order)) {
        orderBys.push(order);
      }
    }
  }

  const dispatch = useAppDispatch();
  const onOrderBy = useCallback(
    (key: Key) => dispatch(setOrderBy(key as OrderBy)),
    [dispatch],
  );
  const onSortOrder = useCallback(
    () => dispatch(toggleSortOrder()),
    [dispatch],
  );

  return (
    <>
      <MenuTrigger align="end">
        <ActionButton isQuiet>
          <Text width="auto">Sort By:</Text>
          <Text
            width="auto"
            UNSAFE_style={{
              textDecoration: 'underline',
            }}
          >
            {prettyOrderBys.get(orderBy)}
          </Text>
        </ActionButton>
        <Menu
          selectionMode="single"
          onAction={onOrderBy}
          selectedKeys={[orderBy]}
        >
          {orderBys.map(
            (sOrder: OrderBy): ReactElement => (
              <Item
                key={sOrder}
                textValue={`sort by ${prettyOrderBys.get(sOrder)}`}
              >
                {prettyOrderBys.get(sOrder)}
              </Item>
            ),
          )}
        </Menu>
      </MenuTrigger>
      <ActionButton width="auto" onPress={onSortOrder} isQuiet>
        {sortOrder === SortOrder.Descending ? (
          <SortOrderDown />
        ) : (
          <SortOrderUp />
        )}
      </ActionButton>
    </>
  );
}
