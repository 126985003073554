/**
 * *****************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2022 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Adobe and its suppliers, if any. The intellectual and technical concepts
 * contained herein are proprietary to Adobe and its suppliers and are
 * protected by all applicable intellectual property laws, including trade
 * secret and copyright laws. Dissemination of this information or reproduction
 * of this material is strictly forbidden unless prior written permission is
 * obtained from Adobe.
 * *****************************************************************************
 */

import { addAlert, Alert } from 'redux/alert/alert.slice';
import { AppThunk } from 'redux/store';
import { CPUserRole, setCPUserRole } from 'redux/user/user.slice';

import CP from '../controllers/api/CommunityPlatform';

export function fetchCPUserRole(): AppThunk {
  return async (dispatch) => {
    let isCurator;

    try {
      // fetch curator status to determine user's role
      const response = await CP.fetchCuratorStatus();
      isCurator = response.isCurator;
    } catch (error) {
      // set curator status to false if there's been an error
      isCurator = false;

      // notify user that they will not have curator access
      const alert: Alert = {
        id: 'CP-fetchCuratorStatus',
        type: 'error',
        title: 'CP: Unable to fetch curator status',
        content: `You will not have curator access while using Tempo. ${
          (error as Error).message
        }`,
      };
      dispatch(addAlert(alert));
    } finally {
      const role: CPUserRole = isCurator
        ? CPUserRole.curator
        : CPUserRole.public;
      dispatch(setCPUserRole(role));
    }
  };
}
