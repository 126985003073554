/**
 * *****************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2022 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Adobe and its suppliers, if any. The intellectual and technical concepts
 * contained herein are proprietary to Adobe and its suppliers and are
 * protected by all applicable intellectual property laws, including trade
 * secret and copyright laws. Dissemination of this information or reproduction
 * of this material is strictly forbidden unless prior written permission is
 * obtained from Adobe.
 * *****************************************************************************
 */

import React, { ReactElement, useCallback, useState } from 'react';

import { Button, Flex, Heading } from '@adobe/react-spectrum';

import { TemplateAsset } from '__DEPRECATED_DO_NOT_USE_OR_YOU_WILL_BE_FIRED/controllers/api/SparkSearchTypes';
import { FilterTab } from '__DEPRECATED_DO_NOT_USE_OR_YOU_WILL_BE_FIRED/redux/filter/filter.slice';
import {
  clearDirtyAssetProps,
  setAsset,
} from '__DEPRECATED_DO_NOT_USE_OR_YOU_WILL_BE_FIRED/redux/metadata/metadata.slice';
import {
  saveAsset,
  setMetadataValue,
} from '__DEPRECATED_DO_NOT_USE_OR_YOU_WILL_BE_FIRED/redux/metadata/metadata.thunks';
import { MetadataOptions } from '__DEPRECATED_DO_NOT_USE_OR_YOU_WILL_BE_FIRED/redux/metadata/metadata.types';

import GenericBooleanFormField from 'components/AssetMetadataForm/fields/GenericBooleanFormField';
import GenericMultipickerFormField from 'components/AssetMetadataForm/fields/GenericMultiPickerFormField';
import GenericPickerFormField from 'components/AssetMetadataForm/fields/GenericPickerFormField';
import GenericTagFormField from 'components/AssetMetadataForm/fields/GenericTagFormField';
import GenericTextAreaField from 'components/AssetMetadataForm/fields/GenericTextAreaField';
import GenericTextFormField from 'components/AssetMetadataForm/fields/GenericTextFormField';
import OptionsFormGroup from 'components/AssetMetadataForm/fields/OptionsFormGroup';
import Loading from 'components/Loading';
import { useCCXPublicConfig } from 'hooks/useCCXConfig';
import { setAssetUpdated } from 'redux/clientCache/clientCache.slice';
import { useAppDispatch, useAppSelector } from 'redux/hooks';

import Pane from '../Pane';

type TemplateMetadataPanelProps = {
  tab: FilterTab;
  currentAssetInMetadata: TemplateAsset | undefined;
  currentAsset: TemplateAsset;
};

export default function TemplateMetadataPanel({
  tab,
  currentAssetInMetadata,
  currentAsset,
}: TemplateMetadataPanelProps): ReactElement {
  // Form save
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  const onSave = useCallback(() => {
    setIsSaving(true);

    dispatch(saveAsset()).then(() => {
      if (currentAssetInMetadata) {
        dispatch(setAssetUpdated(currentAssetInMetadata.id));
      }

      setIsSaving(false);
    });
  }, [currentAssetInMetadata, dispatch]);
  const onReset = useCallback(() => {
    dispatch(setAsset(currentAsset));
    dispatch(clearDirtyAssetProps());
  }, [currentAsset, dispatch]);
  // Form inputs
  const { results: ccxConfig } = useCCXPublicConfig();
  const hasDirtyProps = useAppSelector(
    (state) => state.metadata.dirtyAssetProps.length !== 0,
  );
  const setValue = useCallback(
    (value: any, key: string) => dispatch(setMetadataValue(tab, key, value)),
    [tab, dispatch],
  );

  return (
    <Pane>
      {currentAssetInMetadata && ccxConfig ? (
        <>
          <Flex justifyContent="space-between">
            <Heading
              level={2}
              marginY="size-0"
              UNSAFE_style={{ fontWeight: 900 }}
            >
              Template Metadata
            </Heading>
            <Flex>
              <Button
                isDisabled={!hasDirtyProps}
                onPress={onReset}
                variant="secondary"
                isQuiet
              >
                Reset
              </Button>
              <Button
                isDisabled={!hasDirtyProps}
                onPress={onSave}
                variant="cta"
              >
                Save
              </Button>
            </Flex>
          </Flex>
          <GenericTextFormField
            error={undefined}
            label="Title"
            isDisabled={isSaving}
            isReadOnly={isSaving}
            onChange={(value) => setValue(value, 'title')}
            value={currentAssetInMetadata.title}
          />

          <GenericTextAreaField
            error={undefined}
            label="Description"
            isDisabled={isSaving}
            isReadOnly={isSaving}
            onChange={(value) => setValue(value, 'description')}
            value={currentAssetInMetadata.description}
          />

          <OptionsFormGroup title="Associated Tags" isWrapping>
            <GenericBooleanFormField
              error={undefined}
              isDisabled={isSaving}
              isReadOnly={isSaving}
              isSelected={currentAssetInMetadata.remixfeed}
              label="remixfeed"
              onChange={(value) => setValue(value, 'remixfeed')}
            />

            <GenericBooleanFormField
              error={undefined}
              isDisabled={isSaving}
              isReadOnly={isSaving}
              isSelected={currentAssetInMetadata.premium}
              label="premium"
              onChange={(value) => setValue(value, 'premium')}
            />

            <GenericBooleanFormField
              error={undefined}
              isDisabled={isSaving}
              isReadOnly={isSaving}
              isSelected={currentAssetInMetadata.animated}
              label="animated"
              onChange={(value) => setValue(value, 'animated')}
            />
          </OptionsFormGroup>

          <GenericPickerFormField
            error={undefined}
            isDisabled={isSaving}
            isReadOnly={isSaving}
            label="Categories"
            options={MetadataOptions.CATEGORIES}
            onChange={(value) => setValue(value, 'categories')}
            value={currentAssetInMetadata.categories}
          />

          <GenericMultipickerFormField
            error={undefined}
            isDisabled={isSaving}
            isReadOnly={isSaving}
            label="Use Case/Task"
            options={ccxConfig.template.tasks.options}
            onChange={(value) => setValue(value, 'tasks')}
            value={currentAssetInMetadata.tasks}
          />

          <GenericTagFormField
            error={undefined}
            isDisabled={isSaving}
            isReadOnly={isSaving}
            label="Key Terms"
            onChange={(value) => setValue(value, 'topics')}
            value={currentAssetInMetadata.topics}
          />

          <GenericMultipickerFormField
            error={undefined}
            isDisabled={isSaving}
            isReadOnly={isSaving}
            label="Locales"
            options={ccxConfig.common.locales.options}
            onChange={(values) =>
              setValue(
                values?.map((value) => value.toLocaleLowerCase()),
                'locales',
              )
            }
            value={currentAssetInMetadata.locales}
          />
        </>
      ) : (
        <Loading />
      )}
    </Pane>
  );
}
