/**
 * *****************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2022 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Adobe and its suppliers, if any. The intellectual and technical concepts
 * contained herein are proprietary to Adobe and its suppliers and are
 * protected by all applicable intellectual property laws, including trade
 * secret and copyright laws. Dissemination of this information or reproduction
 * of this material is strictly forbidden unless prior written permission is
 * obtained from Adobe.
 * *****************************************************************************
 */

import React, { ReactElement } from 'react';

import { Flex, View } from '@adobe/react-spectrum';

import BreadcrumbNav from 'components/BreadcrumbNav';

type AssetExplorerHeaderProps = {
  hasPathPrefix?: boolean;
};

export default function AssetExplorerHeader({
  hasPathPrefix,
}: AssetExplorerHeaderProps): ReactElement {
  return (
    <>
      <View flex width="100%">
        <Flex justifyContent="space-between" width="100%">
          <BreadcrumbNav hasPathPrefix={hasPathPrefix} />
        </Flex>
      </View>
    </>
  );
}

AssetExplorerHeader.defaultProps = {
  hasPathPrefix: false,
};
