/**
 * *****************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2022 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Adobe and its suppliers, if any. The intellectual and technical concepts
 * contained herein are proprietary to Adobe and its suppliers and are
 * protected by all applicable intellectual property laws, including trade
 * secret and copyright laws. Dissemination of this information or reproduction
 * of this material is strictly forbidden unless prior written permission is
 * obtained from Adobe.
 * *****************************************************************************
 */

import { useEffect, useMemo, useState } from 'react';

import { AccessLevel, Privileges } from 'model/AccessLevel';
import { UserGroupProfile } from 'model/acp/UserGroupProfile';
import { UserProfile } from 'model/acp/UserProfile';
import {
  isUserGroupPrincipal,
  isUserPrincipal,
} from 'utils/acp/accessControlEntries';

import useFolderACL, { AccessControlEntry, IMSPrincipal } from './useFolderACL';
import useOrgUserGroups from './useOrgUserGroups';
import useOrgUsers from './useOrgUsers';

type ACLModel = {
  id: string;
  name: string;
  access: AccessLevel;
  normalizedName?: string;
};

export type UserACLModel = ACLModel & {
  type: string;
  email: string;
  normalizedEmail?: string;
};

export type UserGroupACLModel = ACLModel & {
  userCount: number;
};

export type FolderACLModel = UserACLModel | UserGroupACLModel;

export type FolderACLModelHookResult = {
  isLoading: boolean;
  userItems?: UserACLModel[];
  userGroupItems?: UserGroupACLModel[];
};

function getAccessLevel(acls: Privileges[]): AccessLevel {
  if (acls.includes('delete')) {
    return AccessLevel.Owner;
  }

  if (acls.includes('write')) {
    return AccessLevel.Edit;
  }

  if (acls.includes('read')) {
    return AccessLevel.Read;
  }

  if (acls.includes('ack')) {
    return AccessLevel.Check;
  }

  return AccessLevel.None;
}

export default function useFolderACLModel(): FolderACLModelHookResult {
  const { isLoading: isOrgUsersLoading, result: orgUsers } = useOrgUsers();
  const { isLoading: isOrgUserGroupsLoading, result: orgUserGroups } =
    useOrgUserGroups();
  const { data: folderACLPolicy, isLoading: isFolderACLPolicyLoading } =
    useFolderACL();
  const [result, setResult] = useState<FolderACLModelHookResult>({
    isLoading:
      isOrgUsersLoading || isFolderACLPolicyLoading || isOrgUserGroupsLoading,
  });
  const getUserItems = useMemo(
    () =>
      folderACLPolicy?.['repo:acl'].reduce(
        (acc: UserACLModel[], acl: AccessControlEntry) => {
          const principal = acl['repo:principal'] as IMSPrincipal;
          const isUser = isUserPrincipal(principal);

          if (isUser && !isOrgUsersLoading) {
            const userProfile = orgUsers?.find(
              (user: UserProfile) => user.id === principal['@id'],
            );

            if (userProfile) {
              return acc.concat({
                id: userProfile.id,
                name: `${userProfile.firstName} ${userProfile.lastName}`,
                type: userProfile.type.slice(-1),
                access: getAccessLevel(acl['repo:privileges']),
                email: userProfile.email,
                normalizedEmail: userProfile.normalizedEmail,
              });
            }

            return acc.concat({
              id: principal['@id'],
              name: `Missing (${principal['@id']})`,
              type: 'Unknown',
              access: AccessLevel.None,
              email: '–',
            });
          }

          return acc;
        },
        [] as UserACLModel[],
      ),
    [folderACLPolicy, isOrgUsersLoading, orgUsers],
  );
  const getUserGroupItems = useMemo(
    () =>
      folderACLPolicy?.['repo:acl'].reduce(
        (acc: UserGroupACLModel[], acl: AccessControlEntry) => {
          const principal = acl['repo:principal'] as IMSPrincipal;
          const isUserGroup = isUserGroupPrincipal(principal);
          // IMS UserGroup Ids are in different formats and this is in order to sanitize the id string to just the ID
          const trimmedId =
            principal['@id'].split('@AdobeOrg:').pop() ?? principal['@id'];

          if (isUserGroup && !isOrgUserGroupsLoading) {
            const groupProfile = orgUserGroups?.find(
              (group: UserGroupProfile) => group.id === trimmedId,
            );

            if (groupProfile) {
              return acc.concat({
                id: groupProfile.id,
                name: groupProfile.name,
                normalizedName: groupProfile.normalizedName,
                access: getAccessLevel(acl['repo:privileges']),
                userCount: groupProfile.userCount,
              });
            }

            return acc.concat({
              id: principal['@id'],
              name: trimmedId,
              access: AccessLevel.None,
              userCount: 0,
            });
          }

          return acc;
        },
        [] as UserGroupACLModel[],
      ),
    [folderACLPolicy, orgUserGroups, isOrgUserGroupsLoading],
  );

  useEffect(() => {
    setResult({
      isLoading: isFolderACLPolicyLoading || isOrgUsersLoading,
      userGroupItems: getUserGroupItems,
      userItems: getUserItems,
    });
  }, [
    getUserGroupItems,
    getUserItems,
    isFolderACLPolicyLoading,
    isOrgUsersLoading,
  ]);

  return result;
}
