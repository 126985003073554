/**
 * *****************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2022 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Adobe and its suppliers, if any. The intellectual and technical concepts
 * contained herein are proprietary to Adobe and its suppliers and are
 * protected by all applicable intellectual property laws, including trade
 * secret and copyright laws. Dissemination of this information or reproduction
 * of this material is strictly forbidden unless prior written permission is
 * obtained from Adobe.
 * *****************************************************************************
 */

import React, {
  JSXElementConstructor,
  ReactElement,
  useCallback,
  useState,
} from 'react';
import { useNavigate } from 'react-router-dom';

import {
  ActionGroup,
  Item,
  Text,
  Tooltip,
  TooltipTrigger,
  View,
} from '@adobe/react-spectrum';

import ConfirmationDialog from 'components/Dialogs/ConfirmationDialog';
import FolderDialog from 'components/Dialogs/FolderDialog';
import { RenameAssetFormDialog } from 'components/Dialogs/FormDialog';
import useAssetWorkflowActions from 'hooks/useAssetWorkflowActions';
import useConstant from 'hooks/useConstant';
import useWorkflow from 'hooks/useWorkflow';
import ApplicationMetadata from 'model/acp/ApplicationMetadata';
import RepoMetadata from 'model/acp/RepoMetadata';
import { RepoMetadataKey } from 'model/acp/RepoMetadataKey';
import { AssetAction } from 'model/app/AssetAction';
import { AssetActionDomain } from 'model/app/AssetActionDomain';
import { AssetActionIconMap } from 'model/app/AssetActionIconMap';
import { useAppDispatch } from 'redux/hooks';
import { queueJob } from 'redux/workflowActions/workflowActions.slice';
import { getParentPath, isDirectory } from 'utils/assets';
import { verifyCanPerformAction } from 'utils/workflowActions';

import styles from './DetailActionButtons.module.scss';

type Props = {
  applicationMetadata: ApplicationMetadata;
  hasStagedChanges: boolean;
  path: string;
  repoMetadata: RepoMetadata;
};

enum Dialog {
  Approve,
  Delete,
  Move,
  Rename,
}

export default function DetailActionButtons({
  applicationMetadata,
  hasStagedChanges,
  path,
  repoMetadata,
}: Props): ReactElement {
  // Data hooks
  const { assetActions: actions } = useWorkflow({
    actionDomain: AssetActionDomain.Single,
    actionContext: isDirectory(repoMetadata) ? 'folder' : 'file',
  });
  const { acpBasePath } = useConstant();
  const assetWorkflowActions = useAssetWorkflowActions();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  // Modal state
  const [openDialog, setOpenDialog] = useState<Dialog>();
  // Action callbacks
  const hideDialog = useCallback(
    () => setOpenDialog(undefined),
    [setOpenDialog],
  );
  const doApprove = useCallback(
    (targetDestination: string) => {
      hideDialog();

      dispatch(
        queueJob({
          message: `Approving asset "${
            repoMetadata[RepoMetadataKey.Name]
          }" to "${targetDestination}"`,
          action: async (): Promise<string> => {
            try {
              let newPath = targetDestination.startsWith(acpBasePath.assets)
                ? targetDestination.slice(acpBasePath.assets.length)
                : targetDestination;

              newPath += `/${repoMetadata[RepoMetadataKey.Name]}?view=detail`;

              await assetWorkflowActions.approve(targetDestination)(path);
              navigate(newPath);

              return `Approved "${
                repoMetadata[RepoMetadataKey.Name]
              }" to ${targetDestination}`;
            } catch (error) {
              if ((error as Error).message === 'Failed') {
                throw new Error(
                  `Failed to approve "${repoMetadata[RepoMetadataKey.Name]}"`,
                );
              }

              throw new Error(
                `Failed to approve "${repoMetadata[RepoMetadataKey.Name]}": ${
                  (error as Error).message
                }`,
              );
            }
          },
          options: {
            isIndeterminate: true,
          },
        }),
      );
    },
    [
      acpBasePath,
      assetWorkflowActions,
      dispatch,
      path,
      repoMetadata,
      hideDialog,
      navigate,
    ],
  );
  const doMove = useCallback(
    (targetDestination: string) => {
      hideDialog();

      dispatch(
        queueJob({
          message: `Moving asset "${
            repoMetadata[RepoMetadataKey.Name]
          }" to "${targetDestination}"`,
          action: async (): Promise<string> => {
            try {
              let newPath = targetDestination.startsWith(acpBasePath.assets)
                ? targetDestination.slice(acpBasePath.assets.length)
                : targetDestination;

              newPath += `/${encodeURIComponent(
                repoMetadata[RepoMetadataKey.Name],
              )}`;

              await assetWorkflowActions.move(path, {
                targetDestination,
                redirect: newPath,
              });

              return `Moved "${
                repoMetadata[RepoMetadataKey.Name]
              }" to ${targetDestination}`;
            } catch (error) {
              if ((error as Error).message === 'Failed') {
                throw new Error(
                  `Failed to move "${repoMetadata[RepoMetadataKey.Name]}"`,
                );
              }

              throw new Error(
                `Failed to move "${repoMetadata[RepoMetadataKey.Name]}": ${
                  (error as Error).message
                }`,
              );
            }
          },
          options: {
            isIndeterminate: true,
          },
        }),
      );
    },
    [
      acpBasePath,
      assetWorkflowActions,
      dispatch,
      path,
      repoMetadata,
      hideDialog,
    ],
  );
  const doPublish = useCallback(
    async () =>
      dispatch(
        queueJob({
          message: `Publishing asset "${repoMetadata[RepoMetadataKey.Name]}"`,
          action: async (): Promise<string> => {
            try {
              await assetWorkflowActions.publish(path);

              return `Published "${repoMetadata[RepoMetadataKey.Name]}"`;
            } catch (error) {
              if ((error as Error).message === 'Failed') {
                throw new Error(
                  `Failed to publish "${repoMetadata[RepoMetadataKey.Name]}"`,
                );
              }

              throw new Error(
                `Failed to publish "${repoMetadata[RepoMetadataKey.Name]}": ${
                  (error as Error).message
                }`,
              );
            }
          },
          options: {
            isIndeterminate: true,
          },
        }),
      ),
    [assetWorkflowActions, dispatch, path, repoMetadata],
  );
  const doReject = useCallback(
    async () =>
      dispatch(
        queueJob({
          message: `Rejecting asset "${repoMetadata[RepoMetadataKey.Name]}"`,
          action: async (): Promise<string> => {
            try {
              await assetWorkflowActions.reject(path);

              return `Rejected "${repoMetadata[RepoMetadataKey.Name]}"`;
            } catch (error) {
              if ((error as Error).message === 'Failed') {
                throw new Error(
                  `Failed to reject "${repoMetadata[RepoMetadataKey.Name]}"`,
                );
              }

              throw new Error(
                `Failed to reject "${repoMetadata[RepoMetadataKey.Name]}": ${
                  (error as Error).message
                }`,
              );
            }
          },
          options: {
            isIndeterminate: true,
          },
        }),
      ),
    [assetWorkflowActions, dispatch, path, repoMetadata],
  );
  const doRename = useCallback(
    async (filename: string) => {
      hideDialog();

      dispatch(
        queueJob({
          message: `Renaming asset "${
            repoMetadata[RepoMetadataKey.Name]
          }" to "${filename}"`,
          action: async (): Promise<string> => {
            try {
              let newPath = repoMetadata[RepoMetadataKey.Path].startsWith(
                acpBasePath.assets,
              )
                ? repoMetadata[RepoMetadataKey.Path].slice(
                    acpBasePath.assets.length,
                  )
                : repoMetadata[RepoMetadataKey.Path];

              newPath = newPath.split('/').slice(0, -1).join('/');
              newPath += `/${filename}?view=detail`;

              await assetWorkflowActions.rename(path, filename, newPath);

              return `Renamed asset to "${filename}"`;
            } catch (error) {
              if ((error as Error).message === 'Failed') {
                throw new Error(
                  `Failed to rename "${repoMetadata[RepoMetadataKey.Name]}"`,
                );
              }

              throw new Error(
                `Failed to rename "${repoMetadata[RepoMetadataKey.Name]}": ${
                  (error as Error).message
                }`,
              );
            }
          },
          options: {
            isIndeterminate: true,
          },
        }),
      );
    },
    [
      acpBasePath.assets,
      assetWorkflowActions,
      dispatch,
      hideDialog,
      path,
      repoMetadata,
    ],
  );
  const doUnpublish = useCallback(
    async () =>
      dispatch(
        queueJob({
          message: `Unpublishing asset "${repoMetadata[RepoMetadataKey.Name]}"`,
          action: async (): Promise<string> => {
            try {
              await assetWorkflowActions.unpublish(path);

              return `Unpublished "${repoMetadata[RepoMetadataKey.Name]}"`;
            } catch (error) {
              if ((error as Error).message === 'Failed') {
                throw new Error(
                  `Failed to unpublish "${repoMetadata[RepoMetadataKey.Name]}"`,
                );
              }

              throw new Error(
                `Failed to unpublish "${repoMetadata[RepoMetadataKey.Name]}": ${
                  (error as Error).message
                }`,
              );
            }
          },
          options: {
            isIndeterminate: true,
          },
        }),
      ),
    [assetWorkflowActions, dispatch, path, repoMetadata],
  );
  const doDelete = useCallback(() => {
    hideDialog();

    dispatch(
      queueJob({
        message: `Deleting ${path}`,
        action: async (): Promise<string> => {
          try {
            await assetWorkflowActions.delete(path);
            navigate(getParentPath(path, {}), { replace: true });

            return `Deleted "${path}"`;
          } catch (error) {
            if ((error as Error).message === 'Failed') {
              throw new Error(
                `Failed to delete "${repoMetadata[RepoMetadataKey.Name]}"`,
              );
            }

            throw new Error(
              `Failed to delete "${repoMetadata[RepoMetadataKey.Name]}": ${
                (error as Error).message
              }`,
            );
          }
        },
        options: {
          isIndeterminate: true,
        },
      }),
    );
  }, [
    assetWorkflowActions,
    dispatch,
    hideDialog,
    navigate,
    path,
    repoMetadata,
  ]);
  const handleAction = useCallback(
    (action: AssetAction) => {
      switch (action) {
        case AssetAction.Approve:
          setOpenDialog(Dialog.Approve);
          break;

        case AssetAction.Move:
          setOpenDialog(Dialog.Move);
          break;

        case AssetAction.Publish:
          doPublish();
          break;

        case AssetAction.Reject:
          doReject();
          break;

        case AssetAction.Rename:
          setOpenDialog(Dialog.Rename);
          break;

        case AssetAction.Unpublish:
          doUnpublish();
          break;
        case AssetAction.Delete:
          setOpenDialog(Dialog.Delete);
          break;
      }
    },
    [doPublish, doReject, doUnpublish, setOpenDialog],
  );

  return (
    <>
      <ActionGroup
        UNSAFE_className={styles.actionGroup}
        isDisabled={hasStagedChanges}
        marginStart="size-100"
        maxWidth={isDirectory(repoMetadata) ? 'size-2400' : 'size-3400'}
        onAction={(action) => handleAction(action as AssetAction)}
        overflowMode="collapse"
      >
        {actions.map((action: AssetAction): ReactElement => {
          const [error] = verifyCanPerformAction(action, {
            repoMetadata,
            applicationMetadata,
          });
          const Icon = AssetActionIconMap.get(
            action,
          ) as JSXElementConstructor<any>;
          const item = (
            <Item key={action} textValue={action}>
              <View
                gridArea="icon"
                height="size-225"
                paddingEnd="size-75"
                position="relative"
                width="size-225"
              >
                <Icon size="S" />
              </View>
              <Text>{action}</Text>
            </Item>
          );

          if (!error) {
            return item;
          }

          return (
            <TooltipTrigger key={action} isDisabled={false} delay={0}>
              {item}
              <Tooltip>{error}</Tooltip>
            </TooltipTrigger>
          );
        })}
      </ActionGroup>

      <ConfirmationDialog
        isOpen={openDialog === Dialog.Delete}
        onClose={() => setOpenDialog(undefined)}
        onConfirm={doDelete}
        title="Are you sure?"
        confirm="Delete"
        content={
          <>
            Deletion will unpublish this asset and then remove it from storage.
          </>
        }
      />

      <FolderDialog
        invalidDestinations={[path.split('/').slice(0, -1).join('/')]}
        invalidDestinationDirectories={
          isDirectory(repoMetadata) ? [path] : undefined
        }
        initialDirectory={
          openDialog === Dialog.Move
            ? path.split('/').slice(0, -1).join('/')
            : acpBasePath.author
        }
        isOpen={openDialog === Dialog.Approve || openDialog === Dialog.Move}
        onClose={hideDialog}
        onConfirm={openDialog === Dialog.Move ? doMove : doApprove}
        title={openDialog === Dialog.Approve ? 'Select Destination' : undefined}
      />

      <RenameAssetFormDialog
        filename={repoMetadata[RepoMetadataKey.Name]}
        isFolder={isDirectory(repoMetadata)}
        isLoading={false}
        isOpen={openDialog === Dialog.Rename}
        onClose={hideDialog}
        onRename={doRename}
      />
    </>
  );
}
