/**
 * *****************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2022 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Adobe and its suppliers, if any. The intellectual and technical concepts
 * contained herein are proprietary to Adobe and its suppliers and are
 * protected by all applicable intellectual property laws, including trade
 * secret and copyright laws. Dissemination of this information or reproduction
 * of this material is strictly forbidden unless prior written permission is
 * obtained from Adobe.
 * *****************************************************************************
 */

import { FilterGroup, FilterGroupInfo } from './filterGroupOptions.reducer';

type FilterGroupConstants = {
  [key in FilterGroup]: FilterGroupInfo;
};

export const filterGroupConstants: FilterGroupConstants = {
  premium: {
    heading: 'Type',
    seeMoreHeading: null,
    multiSelect: false,
    defaultOptions: [],
  },
  licensingCategory: {
    heading: 'Licensing',
    seeMoreHeading: null,
    multiSelect: false,
    defaultOptions: [],
  },
  contentType: {
    heading: 'Content Type',
    seeMoreHeading: null,
    multiSelect: true,
    defaultOptions: [
      {
        label: 'View All',
        value: '*',
        prop: 'contentType',
        isDefault: true,
      },
    ],
  },
  animated: {
    heading: 'Animated',
    seeMoreHeading: null,
    multiSelect: false,
    defaultOptions: [],
  },
  tasks: {
    heading: 'Use Case',
    seeMoreHeading: 'See More Use Cases',
    multiSelect: true,
    defaultOptions: [
      {
        label: 'View All',
        value: '*',
        prop: 'tasks',
        isDefault: true,
      },
      {
        label: 'None',
        value: 'none',
        prop: 'tasks',
        isDefault: true,
      },
    ],
  },
  categories: {
    heading: 'Category',
    seeMoreHeading: 'See More Categories',
    multiSelect: true,
    defaultOptions: [
      {
        label: 'View All',
        value: '*',
        prop: 'categories',
        isDefault: true,
      },
      {
        label: 'None',
        value: 'none',
        prop: 'categories',
        isDefault: true,
      },
    ],
  },
  topics: {
    heading: 'Topics',
    seeMoreHeading: 'See More Topics',
    multiSelect: true,
    defaultOptions: [
      {
        label: 'View All',
        value: '*',
        prop: 'topics',
        isDefault: true,
      },
    ],
  },
  directory: {
    heading: 'Directory',
    seeMoreHeading: 'See More Directories',
    multiSelect: true,
    defaultOptions: [
      {
        label: 'View All',
        value: '*',
        prop: 'directory',
        isDefault: true,
      },
    ],
  },
  // locales (String[]) is only used for Templates Search
  locales: {
    heading: 'Locales',
    seeMoreHeading: 'See More Locales',
    multiSelect: true,
    defaultOptions: [
      {
        label: 'View All',
        value: '*',
        prop: 'locales',
        isDefault: true,
      },
    ],
  },
  // language (String) is only used for Design Assets Search
  language: {
    heading: 'Languages',
    seeMoreHeading: 'See More Languages',
    multiSelect: true,
    defaultOptions: [
      {
        label: 'View All',
        value: '*',
        prop: 'language',
        isDefault: true,
      },
    ],
  },
  owner: {
    heading: 'Designer',
    seeMoreHeading: 'See More Designers',
    multiSelect: true,
    defaultOptions: [],
  },
  applicableRegions: {
    heading: 'Applicable Regions',
    seeMoreHeading: 'See More Regions',
    multiSelect: true,
    defaultOptions: [
      {
        label: 'View All',
        value: '*',
        prop: 'applicableRegions',
        isDefault: true,
      },
    ],
  },
};
