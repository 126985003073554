/**
 * *****************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2022 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Adobe and its suppliers, if any. The intellectual and technical concepts
 * contained herein are proprietary to Adobe and its suppliers and are
 * protected by all applicable intellectual property laws, including trade
 * secret and copyright laws. Dissemination of this information or reproduction
 * of this material is strictly forbidden unless prior written permission is
 * obtained from Adobe.
 * *****************************************************************************
 */

import { useMemo } from 'react';
import { useQueryClient } from 'react-query';

import Axios, { AxiosError, AxiosResponse } from 'axios';
import { compare as jsonPatchCompare } from 'fast-json-patch';
import * as URITemplate from 'uritemplate';

import useConstant from 'hooks/useConstant';
import { ACPLink } from 'model/acp/ACPLink';
import ApplicationMetadata from 'model/acp/ApplicationMetadata';
import { ApplicationMetadataKey } from 'model/acp/ApplicationMetadataKey';
import { ApplicationMetadataNamespace } from 'model/acp/ApplicationMetadataNamespace';
import { AssetClass } from 'model/acp/AssetClass';
import { AssetMimeType } from 'model/acp/AssetMimeType';
import Directory from 'model/acp/Directory';
import HALLink from 'model/acp/HALLink';
import RepoMetadata from 'model/acp/RepoMetadata';
import { RepoMetadataKey } from 'model/acp/RepoMetadataKey';
import { AssetAPIOperation } from 'model/AssetAPIOperation';
import { useAppSelector } from 'redux/hooks';
import { selectACPRepoId } from 'redux/user/user.slice';
import { getACLForAsset } from 'utils/acp/accessControlEntries';
import { getACPLinkUrl } from 'utils/acp/acpLinks';
import { isCollection } from 'utils/assets';
import { getApplicationMetadataForAsset } from 'utils/metadata/applicationMetadata';
import { getFileContentsForAsset } from 'utils/metadata/fileContents';
import { getPageMetadataForAsset } from 'utils/metadata/pageMetadata';
import { getRepoMetadataForAsset } from 'utils/metadata/repoMetadata';
import parseHttpResponse from 'utils/parseHttpResponse';
import parseLinkHeader from 'utils/parseLinkHeader';

import { AccessControlEntry, IMSPrincipal } from './useFolderACL';

type ACPUploadOperationResponse = {
  'repo:blocksize': number;
  _links: {
    [ACPLink.BlockExtend]: HALLink;
    [ACPLink.BlockFinalize]: HALLink;
    [ACPLink.BlockTransfer]: HALLink[];
  };
};

type ACPRepoPath = {
  path: string;
  'repo:repositoryId': string;
};

type ACPDiscardOperationBody = {
  op: AssetAPIOperation.Discard;
  target: ACPRepoPath;
};

type UpdateACLBody = {
  'repo:acl': AccessControlEntry[];
};

type ACPMoveOperationBody = {
  error?: {
    status: number;
    title: string;
  };
  op: AssetAPIOperation.Move;
  source: ACPRepoPath;
  target: ACPRepoPath;
};

export type AssetBody = Pick<
  ApplicationMetadata,
  | ApplicationMetadataKey.Attribution
  | ApplicationMetadataKey.Audio
  | ApplicationMetadataKey.Animated
  | ApplicationMetadataKey.ApplicableRegions
  | ApplicationMetadataKey.DCSubject
  | ApplicationMetadataKey.DCTitle
  | ApplicationMetadataKey.Hero
  | ApplicationMetadataKey.Traits
  | ApplicationMetadataKey.Language
  | ApplicationMetadataKey.LicensingCategory
  | ApplicationMetadataKey.Priority
  | ApplicationMetadataKey.Status
>;

type PublishRepoPath = {
  path: string;
  repositoryId?: string;
};

type ApproveOperationBody = {
  ops: AssetAPIOperation.Approve;
  source: PublishRepoPath;
  target: PublishRepoPath;
};

type PublishOperationBody = {
  ops: AssetAPIOperation.Publish;
  source: PublishRepoPath;
  target: PublishRepoPath;
};

type AssetAPIOperationsHookResult = <T>(assetPath: string) => {
  approve: (targetDestination: string) => Promise<void>;
  create: (
    filename: string,
    options: { fileBody?: string | object; contentType: string },
  ) => Promise<void>;
  discard: (assetPath: string) => Promise<void>;
  download: () => Promise<void>;
  fetch: () => Promise<{
    repoMetadata: RepoMetadata;
    applicationMetadata: ApplicationMetadata | undefined;
    pageMetadata: Directory | undefined;
    fileContents: T | undefined;
  }>;
  move: (targetDestination: string) => Promise<void>;
  publish: () => Promise<void>;
  refresh: () => Promise<void>;
  rename: (filename: string) => Promise<void>;
  share: (ace: AccessControlEntry) => Promise<void>;
  unshare: (principalId: string) => Promise<void>;
  unpublish: () => Promise<void>;
  updateACEInACL: (newACE: AccessControlEntry) => Promise<void>;
  updateFile: (fileBody: string | object, hash: string) => Promise<void>;
  updateMetadata: (assetBody: AssetBody) => Promise<void>;
  upload: (
    file: File,
    options?: { contentType?: string; blockContentType?: string },
  ) => Promise<void>;
};

export default function useAssetAPIOperations(): AssetAPIOperationsHookResult {
  const { acpBasePath, url } = useConstant();
  const acpRepoId = useAppSelector(selectACPRepoId);
  const acpOpsRequestUrl = useMemo(
    (): string => `${url.platformBase}${acpBasePath.directoryOps}`,
    [acpBasePath, url.platformBase],
  );
  const publishOpsRequestUrl = useMemo(
    (): string => `${url.publishServiceBase}${acpBasePath.assetsOps}`,
    [acpBasePath, url.publishServiceBase],
  );
  const unPublishServicePublishedRequestUrl = useMemo(
    (): string => `${url.publishServiceBase}${acpBasePath.unpublish}`,
    [acpBasePath, url.publishServiceBase],
  );
  const platformStorageRequestUrl = useMemo(
    (): string => `${url.platformStorageBase}/id/`,
    [url.platformStorageBase],
  );
  const queryClient = useQueryClient();

  return <T = unknown>(assetPath: string) => {
    async function getACPLinkTemplate(
      link: ACPLink,
    ): Promise<string | undefined> {
      const acpLinkUrl = getACPLinkUrl(assetPath, acpRepoId, url.platformBase);
      const data = await Axios.head(acpLinkUrl);
      const links = parseLinkHeader(data.headers.link);

      return links[link]?.url;
    }

    async function getRepoMetadata() {
      // Fetch repository metadata
      return getRepoMetadataForAsset(assetPath, {
        acpRepoId,
        url,
      });
    }

    async function getApplicationMetadata() {
      return getApplicationMetadataForAsset(assetPath, {
        acpRepoId,
        url,
      });
    }

    async function getFileContents() {
      return getFileContentsForAsset<T>(assetPath, {
        acpRepoId,
        url,
      });
    }

    return {
      approve: async (targetDestination) => {
        const filename = assetPath.split('/').pop();
        const body: ApproveOperationBody = {
          ops: AssetAPIOperation.Approve,
          source: {
            path: assetPath,
            repositoryId: acpRepoId,
          },
          target: {
            path: `${targetDestination}${
              targetDestination.endsWith('/') ? '' : '/'
            }${filename}`,
            repositoryId: acpRepoId,
          },
        };

        await Axios.post<ApproveOperationBody>(publishOpsRequestUrl, body, {
          headers: {
            'Content-Type': 'application/json',
          },
        });
      },
      create: async (filename, { fileBody = '', contentType }) => {
        const requestUrlTemplate = await getACPLinkTemplate(ACPLink.Create);

        if (!requestUrlTemplate) {
          throw new Error('Unable to find create request URL');
        }

        const requestUrl = URITemplate.parse(requestUrlTemplate).expand({
          path: filename,
          intermediates: true,
        });
        const createResult = await Axios.post(requestUrl, fileBody, {
          headers: {
            'Content-Type': contentType,
          },
        });

        if (createResult.status !== 201) {
          throw new Error('Unable to create resource');
        }
      },
      discard: async (path) => {
        const body: ACPDiscardOperationBody = {
          op: AssetAPIOperation.Discard,
          target: {
            path,
            'repo:repositoryId': acpRepoId,
          },
        };

        await Axios.post<ACPDiscardOperationBody>(acpOpsRequestUrl, body);
      },
      download: async () => {
        const blockDownloadInitRequestUrlTemplate = await getACPLinkTemplate(
          ACPLink.BlockDownload,
        );

        if (!blockDownloadInitRequestUrlTemplate) {
          throw new Error('Resource does not support being download');
        }

        const blockDownloadInitRequestUrl = URITemplate.parse(
          blockDownloadInitRequestUrlTemplate,
        ).expand({});
        const downloadInitResult = await Axios.get(
          blockDownloadInitRequestUrl,
          {
            headers: {
              'Content-Type': 'application/vnd.adobecloud.download+json',
            },
          },
        );
        let downloadFinalizeResult: AxiosResponse | undefined;
        let httpDownloadUrl: string;

        if (downloadInitResult.status === 200) {
          httpDownloadUrl = downloadInitResult.data.href;
        } else {
          while (
            !downloadFinalizeResult ||
            downloadFinalizeResult.status === 202
          ) {
            // eslint-disable-next-line no-await-in-loop
            downloadFinalizeResult = await Axios.get(
              downloadInitResult.headers.location,
            );

            const timeout =
              downloadFinalizeResult?.headers['retry-after'] * 1000;
            // eslint-disable-next-line no-await-in-loop
            await new Promise((resolve) => {
              setTimeout(resolve, timeout);
            });
          }

          if (downloadFinalizeResult.status > 299) {
            throw new Error('Server error while downloading');
          }

          const result = parseHttpResponse(
            downloadFinalizeResult.data,
          ).toString();
          httpDownloadUrl = JSON.parse(result).href;
        }

        window.location.assign(httpDownloadUrl);
      },
      fetch: async () => {
        const { result: repoMetadata } = await getRepoMetadata();
        const { result: applicationMetadata } = await getApplicationMetadata();

        // Fetch file contents
        let fileContents: T | undefined;

        if (isCollection(repoMetadata)) {
          ({ result: fileContents } = await getFileContents());
        }

        // Fetch directory listing
        let pageMetadata: Directory | undefined;

        if (repoMetadata[RepoMetadataKey.AssetClass] === AssetClass.Directory) {
          ({ result: pageMetadata } = await getPageMetadataForAsset(assetPath, {
            acpRepoId,
            limit: 'all',
            url,
          }));
        }

        return {
          applicationMetadata,
          fileContents,
          pageMetadata,
          repoMetadata,
        };
      },
      move: async (targetDestination) => {
        const filename = assetPath.split('/').pop();
        const body: ACPMoveOperationBody = {
          op: AssetAPIOperation.Move,
          source: {
            path: assetPath,
            'repo:repositoryId': acpRepoId,
          },
          target: {
            path: `${targetDestination}${
              targetDestination.endsWith('/') ? '' : '/'
            }${filename}`,
            'repo:repositoryId': acpRepoId,
          },
        };

        await Axios.post<ACPMoveOperationBody>(acpOpsRequestUrl, body);
      },
      publish: async () => {
        const body: PublishOperationBody = {
          ops: AssetAPIOperation.Publish,
          source: {
            path: assetPath,
            repositoryId: acpRepoId,
          },
          target: {
            path: assetPath.replace(acpBasePath.author, acpBasePath.publish),
            repositoryId: acpRepoId,
          },
        };

        await Axios.post<PublishOperationBody>(publishOpsRequestUrl, body, {
          headers: {
            'Content-Type': 'application/json',
          },
        });
      },
      refresh: async () => {
        // Refetch repo and application metadata
        await queryClient.invalidateQueries(['repoMetadata', assetPath]);
        await queryClient.invalidateQueries(['applicationMetadata', assetPath]);
        await queryClient.invalidateQueries(['acl', assetPath]);
        await queryClient.invalidateQueries(['directory']);
        await queryClient.invalidateQueries([
          'breadthFirstDirectorySearch',
          assetPath,
        ]);
        await queryClient.invalidateQueries(['fileData', assetPath]);
      },
      rename: async (filename) => {
        const body: ACPMoveOperationBody = {
          op: AssetAPIOperation.Move,
          source: {
            path: assetPath,
            'repo:repositoryId': acpRepoId,
          },
          target: {
            path: `${assetPath.split('/').slice(0, -1).join('/')}/${filename}`,
            'repo:repositoryId': acpRepoId,
          },
        };
        const result = await Axios.post<ACPMoveOperationBody>(
          acpOpsRequestUrl,
          body,
        );

        if (result.data.error) {
          throw new Error(result.data.error.title);
        }
      },
      unpublish: async () => {
        try {
          await Axios.delete<PublishOperationBody>(
            unPublishServicePublishedRequestUrl,
            {
              headers: {
                'Content-Type': 'application/json',
              },
              params: {
                repositoryId: acpRepoId,
                path: assetPath.replace(
                  acpBasePath.author,
                  acpBasePath.publish,
                ),
              },
            },
          );
        } catch (error) {
          const axiosError = error as AxiosError;

          if (axiosError.isAxiosError) {
            throw new Error(axiosError.response?.data.error);
          }

          throw error;
        }
      },
      updateFile: async (
        fileBody,
        hash,
        contentType = AssetMimeType.Collection,
      ) => {
        const { result: repoMetadata } = await getRepoMetadata();
        const assetId = repoMetadata[RepoMetadataKey.AssetId];
        const requestUrl = `${platformStorageRequestUrl}${assetId}`;

        await Axios.put(requestUrl, fileBody, {
          headers: {
            'Content-Type': contentType,
            'If-Match': hash,
          },
        });
      },
      updateMetadata: async (updatedApplicationMetadata) => {
        const { result: applicationMetadata, linkUrl: applicationMetadataUrl } =
          await getApplicationMetadata();
        const body = jsonPatchCompare(
          applicationMetadata
            ? {
                [ApplicationMetadataNamespace]: applicationMetadata,
              }
            : {},
          {
            [ApplicationMetadataNamespace]: updatedApplicationMetadata,
          },
        );

        await Axios.patch<ACPMoveOperationBody>(applicationMetadataUrl, body, {
          headers: {
            'Content-Type': 'application/json-patch+json',
          },
        });
      },
      share: async (ace) => {
        // Fetch acl
        const acl = await getACLForAsset(assetPath, {
          acpRepoId,
          url,
        });
        // Process share
        const { result: repoMetadata } = await getRepoMetadata();
        const assetId = repoMetadata[RepoMetadataKey.AssetId];
        const requestUrl = `${url.platformACLPolicyBase}${assetId}`;
        const updatedACL: AccessControlEntry[] = acl['repo:acl'].concat(ace);
        const requestBody: UpdateACLBody = { 'repo:acl': updatedACL };
        await Axios.put<UpdateACLBody>(requestUrl, requestBody, {
          headers: {
            'Content-Type': AssetMimeType.AccessControlPolicy,
            directive: 'acl-policy-put',
          },
        });
      },
      unshare: async (principalId) => {
        // Fetch acl
        const acl = await getACLForAsset(assetPath, {
          acpRepoId,
          url,
        });
        // Process unshare
        const { result: repoMetadata } = await getRepoMetadata();
        const assetId = repoMetadata[RepoMetadataKey.AssetId];
        const requestUrl = `${url.platformACLPolicyBase}${assetId}`;
        const updatedACL: AccessControlEntry[] = acl['repo:acl'].filter(
          (ace) => {
            const principal = ace['repo:principal'] as IMSPrincipal;

            return principal['@id'] !== principalId;
          },
        );
        const requestBody: UpdateACLBody = { 'repo:acl': updatedACL };
        await Axios.put<UpdateACLBody>(requestUrl, requestBody, {
          headers: {
            'Content-Type': AssetMimeType.AccessControlPolicy,
            directive: 'acl-policy-put',
          },
        });
      },
      updateACEInACL: async (newACE) => {
        // Fetch acl
        const acl = await getACLForAsset(assetPath, {
          acpRepoId,
          url,
        });
        // Process ACE
        const { result: repoMetadata } = await getRepoMetadata();
        const assetId = repoMetadata[RepoMetadataKey.AssetId];
        const requestUrl = `${url.platformACLPolicyBase}${assetId}`;
        const userToUpdate = newACE['repo:principal'] as IMSPrincipal;
        const userToUpdateId = userToUpdate['@id'];
        const updateACEListWIthRemovedACE: AccessControlEntry[] = acl[
          'repo:acl'
        ].filter((ace) => {
          const principal = ace['repo:principal'] as IMSPrincipal;

          return principal['@id'] !== userToUpdateId;
        });
        const updatedACL = updateACEListWIthRemovedACE.concat(newACE);
        const requestBody: UpdateACLBody = { 'repo:acl': updatedACL };
        await Axios.put<UpdateACLBody>(requestUrl, requestBody, {
          headers: {
            'Content-Type': AssetMimeType.AccessControlPolicy,
            directive: 'acl-policy-put',
          },
        });
      },
      upload: async (file: File, { contentType, blockContentType } = {}) => {
        const blockUploadInitRequestUrlTemplate = await getACPLinkTemplate(
          ACPLink.BlockUploadInit,
        );

        if (!blockUploadInitRequestUrlTemplate) {
          throw new Error('Unable to find block upload init URL');
        }

        const blockUploadInitRequestUrl = URITemplate.parse(
          blockUploadInitRequestUrlTemplate,
        ).expand({});
        const blockUploadInitData = {
          'repo:size': file.size,
          'dc:format': contentType ?? file.type,
          'repo:reltype': 'http://ns.adobe.com/adobecloud/rel/primary',
          'repo:md5': null,
          'repo:expires': null,
          _links: null,
        };
        const uploadInitResult = await Axios.post<ACPUploadOperationResponse>(
          blockUploadInitRequestUrl,
          blockUploadInitData,
          {
            headers: {
              'Content-Type': 'application/vnd.adobecloud.bulk-transfer+json',
            },
          },
        );
        const { 'repo:blocksize': maximumBlocksize, _links: uploadLinks } =
          uploadInitResult.data;

        if (
          uploadLinks[ACPLink.BlockTransfer].length === 0 ||
          uploadLinks[ACPLink.BlockTransfer].some(
            (link) => link.href === undefined,
          )
        ) {
          throw new Error('Unable to find block upload transfer URL');
        }

        if (!uploadLinks[ACPLink.BlockFinalize].href) {
          throw new Error('Unable to find block upload finalize URL');
        }

        const blockTransfers = [];
        let currentBlobMarker = 0;

        while (currentBlobMarker + maximumBlocksize < file.size) {
          blockTransfers.push(
            file.slice(currentBlobMarker, currentBlobMarker + maximumBlocksize),
          );
          currentBlobMarker += maximumBlocksize;
        }

        blockTransfers.push(file.slice(currentBlobMarker));

        if (
          blockTransfers.filter(
            (_, index) =>
              uploadLinks[ACPLink.BlockTransfer][index].href === undefined,
          ).length !== 0
        ) {
          throw new Error('Missing block transfer URL');
        }

        const instance = Axios.create();
        delete instance.defaults.headers.common.Authorization;
        delete instance.defaults.headers.common['x-api-key'];

        await Axios.all(
          blockTransfers.map((fileBlock, index) =>
            instance.put(
              uploadLinks[ACPLink.BlockTransfer][index].href as string,
              fileBlock,
              {
                withCredentials: false,
                auth: undefined,
                headers: {
                  'Content-Type': blockContentType ?? contentType ?? file.type,
                },
              },
            ),
          ),
        );

        const uploadFinalizeRequestUrl = URITemplate.parse(
          uploadLinks[ACPLink.BlockFinalize].href as string,
        ).expand({});
        const uploadFinalizeResult = await Axios.post(
          uploadFinalizeRequestUrl,
          {
            ...blockUploadInitData,
            'dc:format': blockContentType ?? contentType ?? file.type,
            'repo:blocksize': uploadInitResult.data['repo:blocksize'],
            _links: uploadInitResult.data._links,
          },
          {
            headers: {
              'Content-Type': 'application/vnd.adobecloud.bulk-transfer+json',
            },
          },
        );
        let finalizeResponse: AxiosResponse | undefined;

        while (!finalizeResponse || finalizeResponse.status === 202) {
          // eslint-disable-next-line no-await-in-loop
          finalizeResponse = await Axios.get(
            uploadFinalizeResult.headers.location,
          );

          const timeout = finalizeResponse?.headers['retry-after'] * 1000;
          // eslint-disable-next-line no-await-in-loop
          await new Promise((resolve) => {
            setTimeout(resolve, timeout);
          });
        }

        if (finalizeResponse.status > 299) {
          throw new Error('Server error while uploading');
        }
      },
    };
  };
}
