/**
 * *****************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2022 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Adobe and its suppliers, if any. The intellectual and technical concepts
 * contained herein are proprietary to Adobe and its suppliers and are
 * protected by all applicable intellectual property laws, including trade
 * secret and copyright laws. Dissemination of this information or reproduction
 * of this material is strictly forbidden unless prior written permission is
 * obtained from Adobe.
 * *****************************************************************************
 */

import React, { ReactElement } from 'react';

import { Flex, Text, View } from '@adobe/react-spectrum';
import Clock from '@spectrum-icons/workflow/Clock';
import formatDistanceToNowStrict from 'date-fns/formatDistanceToNowStrict';

import styles from './BaseCard.module.scss';

type BaseCardModifiedDateProps = {
  modifiedDate?: string;
};

export default function BaseCardModifiedDate({
  modifiedDate,
}: BaseCardModifiedDateProps): ReactElement {
  return (
    <Flex UNSAFE_className={styles.content} direction="column">
      <Flex alignItems="center" UNSAFE_className={styles.subtitle}>
        {modifiedDate ? (
          <>
            <View height="size-150" marginEnd="size-100">
              <Clock size="XS" />
            </View>
            <View
              flex
              overflow="hidden"
              UNSAFE_style={{
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
              }}
            >
              <Text>
                {modifiedDate
                  ? formatDistanceToNowStrict(new Date(modifiedDate), {
                      addSuffix: true,
                    })
                  : null}
              </Text>
            </View>
          </>
        ) : (
          <View UNSAFE_className={styles.placeholder} />
        )}
      </Flex>
    </Flex>
  );
}

BaseCardModifiedDate.defaultProps = {
  modifiedDate: undefined,
};
