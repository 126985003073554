/**
 * *****************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2022 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Adobe and its suppliers, if any. The intellectual and technical concepts
 * contained herein are proprietary to Adobe and its suppliers and are
 * protected by all applicable intellectual property laws, including trade
 * secret and copyright laws. Dissemination of this information or reproduction
 * of this material is strictly forbidden unless prior written permission is
 * obtained from Adobe.
 * *****************************************************************************
 */

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { SkippedError } from 'model/errors/SkippedError';
import { openMenu, PopoverMenu } from 'redux/header/header.slice';
import { AppThunk, RootState } from 'redux/store';

export enum WorkflowActionJobStatus {
  Skipped = 'skipped',
  Queued = 'queued',
  Processing = 'processing',
  Fulfilled = 'fulfilled',
  Rejected = 'rejected',
  RejectedAndResolved = 'rejectedAndResolved',
}

export type WorkflowActionJobAsset<T> = {
  status?: WorkflowActionJobStatus;
  message?: string;
  body?: T;
  assetPath: string;
};

type AssetTask<T = unknown> = {
  assets: WorkflowActionJobAsset<T>[];
  action: (
    assetPath: string,
    body: T,
  ) => Promise<string | undefined | void | WorkflowActionJobAsset<T>>;
  onFinish?: (assets: WorkflowActionJobAsset<T>[]) => Promise<void>;
  message: (assets: WorkflowActionJobAsset<T>[]) => string;
};

export type WorkflowActionJob<T = unknown> = {
  key: number;
  status: WorkflowActionJobStatus;
  message: string;
  task?: AssetTask<T>;
  action?: () => Promise<string>;
  options?: {
    alwaysResolve?: boolean;
    workerCount?: number;
    isIndeterminate?: boolean;
  };
};

export type CreatedWorkflowActionJob<T = unknown> = Omit<
  WorkflowActionJob<T>,
  'key' | 'status'
>;

interface WorkflowActionsState {
  autoDismissTimer?: number;
  queue: WorkflowActionJob[];
  currentIndex: number | undefined;
  showToast: boolean;
  progress: number | undefined;
}

const initialState: WorkflowActionsState = {
  queue: [],
  currentIndex: undefined,
  showToast: false,
  progress: undefined,
};

export const workflowActionsSlice = createSlice({
  name: 'workflowActions',
  initialState,
  reducers: {
    addJobToQueue: (state, action: PayloadAction<CreatedWorkflowActionJob>) => {
      state.queue.push({
        ...action.payload,
        key: state.queue.length,
        status: WorkflowActionJobStatus.Queued,
      });
    },
    addAssetsToCurrentJob: (
      state,
      action: PayloadAction<{
        assets: WorkflowActionJobAsset<unknown>[];
        message?: (assets: WorkflowActionJobAsset<unknown>[]) => string;
      }>,
    ) => {
      if (state.currentIndex === undefined) {
        throw new Error('Attempted to add assets but no jobs exist.');
      }

      const taskForCurrentJob = state.queue[state.currentIndex].task;

      if (taskForCurrentJob === undefined) {
        throw new Error('Attempted to add assets but current job has no task.');
      }

      const newAssets = action.payload.assets.map((asset) => ({
        ...asset,
        status: WorkflowActionJobStatus.Queued,
        message: undefined,
        body: undefined,
      }));

      taskForCurrentJob.assets = taskForCurrentJob.assets.concat(newAssets);

      if (action.payload.message) {
        state.queue[state.currentIndex].message = action.payload.message(
          taskForCurrentJob.assets,
        );
      }

      state.queue[state.currentIndex].task = taskForCurrentJob;
    },
    clearQueue: (state) => {
      if (state.currentIndex !== undefined) {
        state.queue[state.currentIndex].status =
          WorkflowActionJobStatus.RejectedAndResolved;
      }

      state.currentIndex = state.queue.length - 1;
      state.showToast = false;
      state.queue
        .filter((job) => job.status === WorkflowActionJobStatus.Queued)
        .forEach((job) => {
          job.status = WorkflowActionJobStatus.Skipped;

          if (job.task) {
            job.task.assets.forEach((asset) => {
              asset.status = WorkflowActionJobStatus.Skipped;
              asset.message = 'Skipped';
            });
          }
        });
    },
    skipJobInQueue: (state, action: PayloadAction<WorkflowActionJob>) => {
      const jobIndex = state.queue.findIndex(
        (job) => job.key === action.payload.key,
      );

      if (jobIndex === -1) {
        throw new Error('Attempted to skip missing job.');
      }

      state.queue[jobIndex].status = WorkflowActionJobStatus.Skipped;

      if (state.currentIndex !== undefined) {
        state.queue = state.queue
          .slice(0, state.currentIndex)
          .concat(state.queue[jobIndex])
          .concat(state.queue.slice(state.currentIndex, jobIndex))
          .concat(state.queue.slice(jobIndex + 1));
        state.currentIndex++;
      }
    },
    markToastAsHidden: (state) => {
      if (state.autoDismissTimer) {
        window.clearTimeout(state.autoDismissTimer);
        state.autoDismissTimer = undefined;
      }

      state.showToast = false;
    },
    markAssetForCurrentAsSkipped: (state, action: PayloadAction<number>) => {
      if (state.currentIndex === undefined) {
        throw new Error(
          'Attempted to mark asset for current job as skipped but no jobs exist.',
        );
      }

      const currentTask = state.queue[state.currentIndex].task;

      if (currentTask === undefined) {
        throw new Error(
          'Attempted to mark asset for current job as skipped but no assets exist for current job.',
        );
      }

      currentTask.assets[action.payload].status =
        WorkflowActionJobStatus.Skipped;
      state.queue[state.currentIndex].task = currentTask;
    },
    markAssetForCurrentAsProcessing: (state, action: PayloadAction<number>) => {
      if (state.currentIndex === undefined) {
        throw new Error(
          'Attempted to mark asset for current job as processing but no jobs exist.',
        );
      }

      const currentTask = state.queue[state.currentIndex].task;

      if (currentTask === undefined) {
        throw new Error(
          'Attempted to mark asset for current job as processing but no assets exist for current job.',
        );
      }

      currentTask.assets[action.payload].status =
        WorkflowActionJobStatus.Processing;
      state.queue[state.currentIndex].task = currentTask;
    },
    markAssetForCurrentAsFulfilled: (
      state,
      action: PayloadAction<{
        result: string | undefined | void | WorkflowActionJobAsset<unknown>;
        assetIndex: number;
      }>,
    ) => {
      if (state.currentIndex === undefined) {
        throw new Error(
          'Attempted to mark asset for current job as fulfilled but no jobs exist.',
        );
      }

      const currentTask = state.queue[state.currentIndex].task;

      if (currentTask === undefined) {
        throw new Error(
          'Attempted to mark asset for current job as fulfilled but no assets exist for current job.',
        );
      }

      currentTask.assets[action.payload.assetIndex].status =
        WorkflowActionJobStatus.Fulfilled;

      if (action.payload.result) {
        const jobAssetResult = action.payload
          .result as WorkflowActionJobAsset<unknown>;

        if (jobAssetResult.body !== undefined) {
          currentTask.assets[action.payload.assetIndex].message =
            jobAssetResult.message;
          currentTask.assets[action.payload.assetIndex].body =
            jobAssetResult.body;
        } else {
          currentTask.assets[action.payload.assetIndex].message = action.payload
            .result as string;
        }
      }

      state.queue[state.currentIndex].task = currentTask;
      state.progress = currentTask.assets.filter(
        (asset) =>
          asset.status === WorkflowActionJobStatus.Fulfilled ||
          asset.status === WorkflowActionJobStatus.Rejected,
      ).length;
    },
    markAssetForCurrentAsRejected: (
      state,
      action: PayloadAction<{ error: Error; assetIndex: number }>,
    ) => {
      if (state.currentIndex === undefined) {
        throw new Error(
          'Attempted to mark asset for current job as rejected but no jobs exist.',
        );
      }

      const currentTask = state.queue[state.currentIndex].task;

      if (currentTask === undefined) {
        throw new Error(
          'Attempted to mark asset for current job as rejected but no assets exist for current job.',
        );
      }

      currentTask.assets[action.payload.assetIndex].status =
        WorkflowActionJobStatus.Rejected;
      currentTask.assets[action.payload.assetIndex].message =
        action.payload.error.message;

      state.queue[state.currentIndex].task = currentTask;
      state.progress = currentTask.assets.filter(
        (asset) =>
          asset.status === WorkflowActionJobStatus.Fulfilled ||
          asset.status === WorkflowActionJobStatus.Rejected,
      ).length;
    },
    markCurrentAsProcessing: (state) => {
      if (state.currentIndex === undefined) {
        throw new Error(
          'Attempted to mark current job as processing but no jobs exist.',
        );
      }

      if (
        state.queue[state.currentIndex].status ===
        WorkflowActionJobStatus.Processing
      ) {
        throw new Error(
          'Attempted to mark current job as processing but currrent job is already processing.',
        );
      }

      const currentTask = state.queue[state.currentIndex].task;
      state.queue[state.currentIndex].status =
        WorkflowActionJobStatus.Processing;

      if (state.queue[state.currentIndex].options?.isIndeterminate) {
        state.progress = undefined;
      } else {
        state.progress = 0;
      }

      if (currentTask !== undefined) {
        currentTask.assets.forEach((asset) => {
          if (asset.status === WorkflowActionJobStatus.Rejected) {
            asset.status = WorkflowActionJobStatus.Queued;
            delete asset.message;
            delete asset.body;
          }
        });
      }

      state.showToast = true;

      if (state.autoDismissTimer) {
        window.clearTimeout(state.autoDismissTimer);
        state.autoDismissTimer = undefined;
      }
    },
    markCurrentAsFulfilled: (
      state,
      action: PayloadAction<{ result: string; timer: number }>,
    ) => {
      if (state.currentIndex === undefined) {
        throw new Error(
          'Attempted to mark current job as fulfilled but no jobs exist.',
        );
      }

      if (
        state.queue[state.currentIndex].status ===
        WorkflowActionJobStatus.Fulfilled
      ) {
        throw new Error(
          'Attempted to mark current job as fulfilled but currrent job is already fulfilled.',
        );
      }

      state.queue[state.currentIndex].status =
        WorkflowActionJobStatus.Fulfilled;
      state.queue[state.currentIndex].message = action.payload.result;

      state.showToast = true;
      state.autoDismissTimer = action.payload.timer;
    },
    markCurrentAsRejectedAndResolved: (state, action: PayloadAction<Error>) => {
      if (state.currentIndex === undefined) {
        throw new Error(
          'Attempted to mark current job as rejected and resolved but no jobs exist.',
        );
      }

      if (
        state.queue[state.currentIndex].status ===
        WorkflowActionJobStatus.Fulfilled
      ) {
        throw new Error(
          'Attempted to mark current job as rejected and resolved but currrent job is already fulfilled.',
        );
      }

      state.queue[state.currentIndex].status =
        WorkflowActionJobStatus.RejectedAndResolved;
      state.queue[state.currentIndex].message = action.payload.message;

      state.showToast = true;
    },
    markCurrentAsRejected: (state, action: PayloadAction<Error>) => {
      if (state.currentIndex === undefined) {
        throw new Error(
          'Attempted to mark current job as rejected but no jobs exist.',
        );
      }

      if (
        state.queue[state.currentIndex].status ===
        WorkflowActionJobStatus.Rejected
      ) {
        throw new Error(
          'Attempted to mark current job as rejected but currrent job is already fulfilled.',
        );
      }

      state.queue[state.currentIndex].status = WorkflowActionJobStatus.Rejected;
      state.queue[state.currentIndex].message = action.payload.message;
    },
    startNextJob: (state) => {
      if (state.queue.length === 0) {
        return;
      }

      if (
        state.currentIndex !== undefined &&
        state.queue[state.currentIndex].status ===
          WorkflowActionJobStatus.Rejected
      ) {
        state.queue[state.currentIndex].status =
          WorkflowActionJobStatus.RejectedAndResolved;
      }

      if (state.currentIndex === undefined) {
        state.currentIndex = -1;
      }

      if (state.currentIndex < state.queue.length - 1) {
        state.currentIndex++;
        state.queue[state.currentIndex].status =
          WorkflowActionJobStatus.Processing;

        if (state.queue[state.currentIndex].options?.isIndeterminate) {
          state.progress = undefined;
        } else {
          state.progress = 0;
        }

        state.showToast = true;
      } else {
        state.showToast = false;
      }

      state.autoDismissTimer = undefined;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      (action) =>
        action.type === openMenu.type &&
        ((action as PayloadAction<PopoverMenu>).payload ===
          PopoverMenu.ActionsInProgress ||
          (action as PayloadAction<PopoverMenu>).payload ===
            PopoverMenu.ActionsComplete),
      (state) => {
        state.showToast = false;
      },
    );
  },
});

export const { addAssetsToCurrentJob, clearQueue, skipJobInQueue } =
  workflowActionsSlice.actions;
const {
  addJobToQueue,
  markAssetForCurrentAsProcessing,
  markAssetForCurrentAsFulfilled,
  markAssetForCurrentAsRejected,
  markAssetForCurrentAsSkipped,
  markCurrentAsProcessing,
  markCurrentAsFulfilled,
  markCurrentAsRejected,
  markCurrentAsRejectedAndResolved,
  markToastAsHidden,
  startNextJob,
} = workflowActionsSlice.actions;

export const selectCurrentJob = (state: RootState) =>
  state.workflowActions.currentIndex !== undefined
    ? state.workflowActions.queue[state.workflowActions.currentIndex]
    : undefined;
export const selectIsAutoDismiss = (state: RootState) =>
  state.workflowActions.autoDismissTimer !== undefined;
export const selectJobProgress = (state: RootState) =>
  state.workflowActions.progress;
export const selectQueueSize = (state: RootState) =>
  state.workflowActions.queue.filter((workflowAction) =>
    [
      WorkflowActionJobStatus.Processing,
      WorkflowActionJobStatus.Queued,
      WorkflowActionJobStatus.Rejected,
    ].includes(workflowAction.status),
  ).length;
export const selectShowToast = (state: RootState) =>
  state.workflowActions.showToast;

export const selectActiveQueue = (state: RootState) => {
  if (state.workflowActions.currentIndex === undefined) {
    return [];
  }

  const current =
    state.workflowActions.queue[state.workflowActions.currentIndex];

  switch (current.status) {
    case WorkflowActionJobStatus.Fulfilled:
    case WorkflowActionJobStatus.Skipped:
    case WorkflowActionJobStatus.RejectedAndResolved:
      return state.workflowActions.queue.slice(
        state.workflowActions.currentIndex + 1,
      );

    case WorkflowActionJobStatus.Queued:
    case WorkflowActionJobStatus.Processing:
    case WorkflowActionJobStatus.Rejected:
    default:
      return state.workflowActions.queue.slice(
        state.workflowActions.currentIndex,
      );
  }
};

export const selectCompleteQueue = (state: RootState) => {
  if (state.workflowActions.currentIndex === undefined) {
    return state.workflowActions.queue;
  }

  const current =
    state.workflowActions.queue[state.workflowActions.currentIndex];

  switch (current.status) {
    case WorkflowActionJobStatus.Fulfilled:
    case WorkflowActionJobStatus.Skipped:
    case WorkflowActionJobStatus.RejectedAndResolved:
      return state.workflowActions.queue
        .slice(0, state.workflowActions.currentIndex + 1)
        .reverse();

    case WorkflowActionJobStatus.Queued:
    case WorkflowActionJobStatus.Processing:
    case WorkflowActionJobStatus.Rejected:
    default:
      return state.workflowActions.queue
        .slice(0, state.workflowActions.currentIndex)
        .reverse();
  }
};

let internalRunNextQueuedJob: () => AppThunk;

const runCurrentJob = (): AppThunk => async (dispatch, getState) => {
  let current = selectCurrentJob(getState());

  if (!current || current.status !== WorkflowActionJobStatus.Processing) {
    return;
  }

  try {
    let result: string;

    if (current.action) {
      result = await current.action();
    } else if (current.task) {
      const workerCount = current.options?.workerCount ?? 10;

      let assetIndex = 0;
      await Promise.all(
        Array.from({ length: workerCount }).map(async () => {
          while (
            current?.task?.assets.length &&
            assetIndex < current.task.assets.length
          ) {
            const taskAssetIndex = assetIndex++;

            if (
              current.task.assets[taskAssetIndex].status !==
              WorkflowActionJobStatus.Fulfilled
            ) {
              dispatch(markAssetForCurrentAsProcessing(taskAssetIndex));

              try {
                // eslint-disable-next-line no-await-in-loop
                const assetResult = await current.task.action(
                  current.task.assets[taskAssetIndex].assetPath,
                  current.task.assets[taskAssetIndex].body,
                );
                dispatch(
                  markAssetForCurrentAsFulfilled({
                    assetIndex: taskAssetIndex,
                    result: assetResult,
                  }),
                );
              } catch (error) {
                if (error instanceof SkippedError) {
                  dispatch(markAssetForCurrentAsSkipped(taskAssetIndex));
                } else {
                  dispatch(
                    markAssetForCurrentAsRejected({
                      assetIndex: taskAssetIndex,
                      error: error as Error,
                    }),
                  );
                }
              }
            }

            current = selectCurrentJob(getState());
          }
        }),
      );

      const currentSnapshot = selectCurrentJob(getState());

      if (!currentSnapshot || !currentSnapshot.task) {
        throw new Error('Current task changed before it could be resolved.');
      }

      if (current.task.onFinish) {
        await current.task.onFinish(currentSnapshot.task.assets);
      }

      result = current.task.message(currentSnapshot.task.assets);
    } else {
      throw new Error(
        'Attempted to run a job with neither action nor task defined.',
      );
    }

    const timer = window.setTimeout(() => {
      dispatch(internalRunNextQueuedJob());
    }, 5000);

    dispatch(markCurrentAsFulfilled({ result, timer }));
  } catch (error) {
    if (current.options?.alwaysResolve) {
      dispatch(markCurrentAsRejectedAndResolved(error as Error));
    } else {
      dispatch(markCurrentAsRejected(error as Error));
    }
  }
};

export const rerunCurrentJob = (): AppThunk => (dispatch) => {
  dispatch(markCurrentAsProcessing());
  dispatch(runCurrentJob());
};

internalRunNextQueuedJob = (): AppThunk => async (dispatch) => {
  // Set the next toast in queue to current
  dispatch(startNextJob());
  dispatch(runCurrentJob());
};

export const runNextQueuedJob = internalRunNextQueuedJob;

export const queueJob =
  <T = unknown>(job: CreatedWorkflowActionJob<T>): AppThunk =>
  (dispatch, getState) => {
    dispatch(addJobToQueue(job as CreatedWorkflowActionJob<unknown>));

    const current = selectCurrentJob(getState());
    const isAutoDismiss = selectIsAutoDismiss(getState());

    if (
      !current ||
      current.status === WorkflowActionJobStatus.Skipped ||
      current.status === WorkflowActionJobStatus.RejectedAndResolved ||
      (current.status === WorkflowActionJobStatus.Fulfilled && !isAutoDismiss)
    ) {
      dispatch(runNextQueuedJob());
    }
  };

export const hideToast = (): AppThunk => (dispatch, getState) => {
  const isCurrentlyAutoDismissing = selectIsAutoDismiss(getState());

  dispatch(markToastAsHidden());

  if (isCurrentlyAutoDismissing) {
    dispatch(runNextQueuedJob());
  }
};

export default workflowActionsSlice.reducer;
