/**
 * *****************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2022 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Adobe and its suppliers, if any. The intellectual and technical concepts
 * contained herein are proprietary to Adobe and its suppliers and are
 * protected by all applicable intellectual property laws, including trade
 * secret and copyright laws. Dissemination of this information or reproduction
 * of this material is strictly forbidden unless prior written permission is
 * obtained from Adobe.
 * *****************************************************************************
 */

export enum AccessLevel {
  None = 'none',
  Check = 'ack',
  Read = 'read',
  Edit = 'edit',
  Owner = 'owner',
}

export type Privileges = 'read' | 'write' | 'delete' | 'ack' | 'full';

export const AccessLevelToPrivilegesMap = new Map<AccessLevel, Privileges[]>([
  [AccessLevel.Owner, ['read', 'write', 'delete']],
  [AccessLevel.Edit, ['read', 'write']],
  [AccessLevel.Read, ['read']],
]);
