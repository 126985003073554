/**
 * *****************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2022 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Adobe and its suppliers, if any. The intellectual and technical concepts
 * contained herein are proprietary to Adobe and its suppliers and are
 * protected by all applicable intellectual property laws, including trade
 * secret and copyright laws. Dissemination of this information or reproduction
 * of this material is strictly forbidden unless prior written permission is
 * obtained from Adobe.
 * *****************************************************************************
 */

import React, { useCallback } from 'react';

import Button from '@react/react-spectrum/Button';
import LoupeView from '@react/react-spectrum/Icon/LoupeView';
import Refresh from '@react/react-spectrum/Icon/Refresh';
import ViewGrid from '@react/react-spectrum/Icon/ViewGrid';

import { useAppSelector } from 'redux/hooks';
import { ScreenView } from 'redux/screen/screen.slice';
import { CPUserRole } from 'redux/user/user.slice';

import styles from './styles/Wrapper.module.scss';

type TemplatesViewChildProps = {
  isCurator: boolean;
  onViewButtonClick: (newView: ScreenView) => void;
};

type SearchWrapperProps = {
  onRefresh: () => void;
  onViewChange: (type: ScreenView) => void;
  children: (args: TemplatesViewChildProps) => React.ReactNode;
};

const SearchWrapper: React.FC<SearchWrapperProps> = ({
  onRefresh,
  onViewChange,
  children,
}: SearchWrapperProps) => {
  const view = useAppSelector((state) => state.screen.view);
  const count = useAppSelector((state) => state.assets.count);
  const total = useAppSelector((state) => state.assets.total);
  const role = useAppSelector((state) => state.user.cpRole);
  const onViewButtonClick = useCallback(
    (newView: ScreenView) => {
      if (view !== newView) {
        onViewChange(newView);
      }
    },
    [onViewChange, view],
  );

  return (
    <div className={styles.wrapper}>
      {view === ScreenView.Grid ? (
        <div className={styles.buttons}>
          <div>
            <Button
              variant="tool"
              icon={<Refresh size="S" />}
              onClick={() => onRefresh()}
            />
            <Button
              selected
              variant="tool"
              icon={<ViewGrid size="S" />}
              onClick={() => onViewButtonClick(ScreenView.Grid)}
            />
            <Button
              selected={false}
              variant="tool"
              icon={<LoupeView size="S" />}
              onClick={() => onViewButtonClick(ScreenView.Detail)}
            />
            <div className={styles.total}>{`${count}/${total}`}</div>
          </div>
        </div>
      ) : null}
      <div className={styles.children}>
        {children({
          onViewButtonClick,
          isCurator: role === CPUserRole.curator,
        })}
      </div>
    </div>
  );
};

export default SearchWrapper;
