/**
 * *****************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2022 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Adobe and its suppliers, if any. The intellectual and technical concepts
 * contained herein are proprietary to Adobe and its suppliers and are
 * protected by all applicable intellectual property laws, including trade
 * secret and copyright laws. Dissemination of this information or reproduction
 * of this material is strictly forbidden unless prior written permission is
 * obtained from Adobe.
 * *****************************************************************************
 */

import React, { ReactElement, useEffect, useMemo, useState } from 'react';

import Button from '@react/react-spectrum/Button';
import ChevronLeft from '@react/react-spectrum/Icon/ChevronLeft';
import ChevronRight from '@react/react-spectrum/Icon/ChevronRight';
import SentimentNeutral from '@react/react-spectrum/Icon/SentimentNeutral';
import IllustratedMessage from '@react/react-spectrum/IllustratedMessage';

import { SearchAsset } from '__DEPRECATED_DO_NOT_USE_OR_YOU_WILL_BE_FIRED/controllers/api/SparkSearch';
import { DesignAsset } from '__DEPRECATED_DO_NOT_USE_OR_YOU_WILL_BE_FIRED/controllers/api/SparkSearchTypes';
import DesignAssetDetail from '__DEPRECATED_DO_NOT_USE_OR_YOU_WILL_BE_FIRED/Detail/components/Search/DesignAssetDetail';
import { updateAssetsData } from '__DEPRECATED_DO_NOT_USE_OR_YOU_WILL_BE_FIRED/redux/assets/asset.slice';
import { FilterTab } from '__DEPRECATED_DO_NOT_USE_OR_YOU_WILL_BE_FIRED/redux/filter/filter.slice';
import { alertUnsavedMetadataChanges } from '__DEPRECATED_DO_NOT_USE_OR_YOU_WILL_BE_FIRED/redux/metadata/metadata.thunks';

import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { setDetailViewId } from 'redux/screen/screen.slice';

import styles from './SearchAssetsBrowse.module.scss';

export default function SearchAssetsBrowse(): ReactElement {
  const tab = FilterTab.search;
  const dispatch = useAppDispatch();
  const detailViewId = useAppSelector((state) => state.screen.detailViewId);
  const [browseIndex, setBrowseIndex] = useState(0);
  const assets: SearchAsset[] = useAppSelector(
    (state) => state.assets.current as SearchAsset[],
  );
  const metadataComponentList = useMemo(() => {
    const assetList = assets;

    // show empty state
    if (assetList.length === 0) {
      return [
        <IllustratedMessage
          heading="No Assets"
          description="Try a different search"
          illustration={<SentimentNeutral size="XL" />}
        />,
      ];
    }

    const list = (assetList as DesignAsset[]).map((asset: DesignAsset) => (
      <DesignAssetDetail key={asset.id} asset={asset} />
    ));

    return list;
  }, [assets]);

  useEffect(() => {
    const index = metadataComponentList.findIndex(
      (element: ReactElement) => element.key === detailViewId,
    );

    if (index >= 0) {
      setBrowseIndex(index);
    }

    return () => {
      setDetailViewId('');
    };
  }, [detailViewId, metadataComponentList]);

  useEffect(() => {
    if (metadataComponentList.length > 0) {
      dispatch(
        updateAssetsData({
          count: browseIndex + 1,
          total: metadataComponentList.length,
        }),
      );
    }
  }, [tab, browseIndex, metadataComponentList, dispatch]);

  const showAssetAtIndex = (newIndex: number) => {
    if (newIndex < 0) {
      newIndex = metadataComponentList.length - 1;
    } else if (newIndex >= metadataComponentList.length) {
      newIndex = 0;
    }

    dispatch(setDetailViewId(metadataComponentList[newIndex].key as string));
  };
  const doBrowse = (newIndex: number) => {
    // check for unsaved changes before moving to next or prev asset
    dispatch(
      alertUnsavedMetadataChanges(tab, () => showAssetAtIndex(newIndex)),
    );
  };

  return (
    <div className={styles.searchAssetsBrowse}>
      <div className={styles.browse}>
        <Button
          onClick={() => doBrowse(browseIndex - 1)}
          className={styles.prev}
          variant="tool"
          icon={<ChevronLeft size="XXL" />}
          aria-label="Previous Template"
        />
        {metadataComponentList[browseIndex]}
        <Button
          onClick={() => doBrowse(browseIndex + 1)}
          className={styles.next}
          variant="tool"
          icon={<ChevronRight size="XXL" />}
          aria-label="Next Template"
        />
      </div>
    </div>
  );
}
