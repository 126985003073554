/**
 * *****************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2022 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Adobe and its suppliers, if any. The intellectual and technical concepts
 * contained herein are proprietary to Adobe and its suppliers and are
 * protected by all applicable intellectual property laws, including trade
 * secret and copyright laws. Dissemination of this information or reproduction
 * of this material is strictly forbidden unless prior written permission is
 * obtained from Adobe.
 * *****************************************************************************
 */

import ApplicationMetadata from 'model/acp/ApplicationMetadata';
import { ApplicationMetadataKey } from 'model/acp/ApplicationMetadataKey';
import LanguageAlternative from 'model/acp/LanguageAlternative';
import Constant from 'model/constant/Constant';
import { Language } from 'model/Language';

/**
 * Selects the locale from the list of translations. This class overrides the
 * toString method so when concatenated with a string it displays the actual
 * title.
 */

type LanguageAlternativeMaybe = string | LanguageAlternative;

const { defaultLanguage } = new Constant();

export default class LocalizedTitle {
  private value: string = '';
  public constructor(
    applicationMetadata?: ApplicationMetadata,
    languageLocale: Language = defaultLanguage,
  ) {
    const dcTitle = applicationMetadata?.[ApplicationMetadataKey.DCTitle];

    if (dcTitle) {
      const entries = Object.entries<LanguageAlternativeMaybe>(dcTitle);

      /**
       * uses `every` but ignores the value because we want to take advantage of
       * early loop exit.
       */
      entries.every(([key, item]): boolean => {
        let keepGoing = true;

        if (key !== '@value') {
          const langAlt = item as LanguageAlternative;

          if (langAlt['@language'] === languageLocale) {
            this.value = langAlt['@value'];
            keepGoing = false;
          }
        }

        return keepGoing;
      });
    }
  }
  public toString(): string {
    return this.value;
  }
}
