/**
 * *****************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2022 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Adobe and its suppliers, if any. The intellectual and technical concepts
 * contained herein are proprietary to Adobe and its suppliers and are
 * protected by all applicable intellectual property laws, including trade
 * secret and copyright laws. Dissemination of this information or reproduction
 * of this material is strictly forbidden unless prior written permission is
 * obtained from Adobe.
 * *****************************************************************************
 */

import useConstant from './useConstant';
import useRenditionLink, {
  RenditionLinkHookResult,
  RenditionLinkParams,
} from './useRenditionLink';

export default function useHeroRenditionLink({
  templateParams,
  ...rest
}: RenditionLinkParams): RenditionLinkHookResult {
  const { heroRenditionFragment } = useConstant();
  const { isLoading, renditionLink } = useRenditionLink({
    ...rest,
    templateParams: {
      ...templateParams,
      component_id: heroRenditionFragment,
    },
  });

  return {
    isLoading,
    renditionLink,
  };
}
