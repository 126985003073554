/**
 * *****************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2022 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Adobe and its suppliers, if any. The intellectual and technical concepts
 * contained herein are proprietary to Adobe and its suppliers and are
 * protected by all applicable intellectual property laws, including trade
 * secret and copyright laws. Dissemination of this information or reproduction
 * of this material is strictly forbidden unless prior written permission is
 * obtained from Adobe.
 * *****************************************************************************
 */

import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';

import Axios, { AxiosError } from 'axios';

import { OrgAdminProfile } from 'model/acp/OrgAdminProfile';
import { useAppSelector } from 'redux/hooks';
import { selectACPRepoId } from 'redux/user/user.slice';

import useConstant from './useConstant';

type OrgAdminsHookResult = {
  isLoading: boolean;
  result?: OrgAdminProfile[];
  error?: Error;
};

type OrgAdminsProps = {
  enabled?: boolean;
};

export default function useOrgAdmins({
  enabled,
}: OrgAdminsProps): OrgAdminsHookResult {
  const [result, setResult] = useState<OrgAdminsHookResult>({
    isLoading: true,
  });
  const { jilBaseUrl, jilBasePaths } = useConstant();
  const acpRepoId = useAppSelector(selectACPRepoId);
  const requestUrl = `${jilBaseUrl}${jilBasePaths.organizations}/${acpRepoId}${jilBasePaths.admins}`;
  const { data, isLoading, isError, error } = useQuery<
    OrgAdminProfile[],
    AxiosError | undefined
  >(
    ['orgAdmins', acpRepoId],
    async (): Promise<OrgAdminProfile[]> => {
      const response = await Axios.get(requestUrl);

      return response.data;
    },
    {
      enabled:
        enabled && Axios.defaults.headers.common.Authorization !== undefined,
      refetchOnWindowFocus: false,
      retry: (failureCount, retryError) => {
        if (retryError?.response?.status === 403) {
          return false;
        }

        return failureCount < 3;
      },
    },
  );

  useEffect((): void => {
    if (data) {
      setResult({
        isLoading: false,
        result: data,
      });
    } else if (isError) {
      setResult({
        isLoading: false,
        error: new Error(error?.code),
      });
    }
  }, [data, isLoading, error, isError]);

  return result;
}
