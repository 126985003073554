/**
 * *****************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2022 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Adobe and its suppliers, if any. The intellectual and technical concepts
 * contained herein are proprietary to Adobe and its suppliers and are
 * protected by all applicable intellectual property laws, including trade
 * secret and copyright laws. Dissemination of this information or reproduction
 * of this material is strictly forbidden unless prior written permission is
 * obtained from Adobe.
 * *****************************************************************************
 */

import { useAppSelector } from 'redux/hooks';

/**
 * useFilterState Hook
 *
 * Get the current FilterState based on the current contentType and mode
 * in the global state.filters slice.
 */
function useFilterState() {
  const contentType = useAppSelector((state) => state.filters.contentType);
  const mode = useAppSelector((state) => state.filters.mode);
  const { filters, query, order } = useAppSelector((state) => {
    const filterState = state.filters[contentType][mode];

    return {
      filters: filterState.filters,
      query: filterState.query,
      order: filterState.order,
    };
  });

  return { filters, query, order, contentType, mode };
}

export default useFilterState;
