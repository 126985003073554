/**
 * *****************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2022 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Adobe and its suppliers, if any. The intellectual and technical concepts
 * contained herein are proprietary to Adobe and its suppliers and are
 * protected by all applicable intellectual property laws, including trade
 * secret and copyright laws. Dissemination of this information or reproduction
 * of this material is strictly forbidden unless prior written permission is
 * obtained from Adobe.
 * *****************************************************************************
 */

import Axios from 'axios';
import qs from 'query-string';

import { AirtableOwnerRecord } from 'model/airtable/AirtableOwnerRecord';
import {
  AirtableResponse,
  AirtableResponseFields,
} from 'model/airtable/AirtableResponse';
import Constant from 'model/constant/Constant';

export async function fetchOwnerRecords(): Promise<AirtableOwnerRecord> {
  const { airtableDesignerTable, airtableBase } = new Constant();
  const url = `/api/airtable/v0/${airtableBase}/${airtableDesignerTable}`;
  const config = {
    params: {
      view: 'Grid view',
      fields: ['Name', 'CP User Name'],
    },
    paramsSerializer: (params: { view: string; fields: string }): string =>
      qs.stringify(params),
  };
  const ownerRecords: AirtableOwnerRecord = {};
  const response: AirtableResponse | undefined = await Axios.get(url, config);

  if (response?.data && Array.isArray(response.data.records)) {
    response.data.records.forEach((item: AirtableResponseFields): void => {
      const { fields } = item;
      const id = fields['CP User Name'];
      const displayName = fields.Name;

      /**
       * Not entirely sure why, but this static function is called not just
       * when owner records are fetched but another time as well.  This guard
       * will prevent errors.
       */
      if (id && displayName) {
        ownerRecords[id.trim()] = displayName;
      }
    });
  }

  return ownerRecords;
}
