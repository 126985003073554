/**
 * *****************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2022 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Adobe and its suppliers, if any. The intellectual and technical concepts
 * contained herein are proprietary to Adobe and its suppliers and are
 * protected by all applicable intellectual property laws, including trade
 * secret and copyright laws. Dissemination of this information or reproduction
 * of this material is strictly forbidden unless prior written permission is
 * obtained from Adobe.
 * *****************************************************************************
 */

import React, { ReactElement } from 'react';

import { Flex, Heading } from '@adobe/react-spectrum';

import Loading from 'components/Loading';

import Pane from '../Pane';

type TemplateLoadingPanelProps = {
  title: string;
};

export default function TemplateLoadingPanel({
  title,
}: TemplateLoadingPanelProps): ReactElement {
  return (
    <Pane>
      <Flex justifyContent="space-between">
        <Heading level={2} marginY="size-0" UNSAFE_style={{ fontWeight: 900 }}>
          {title}
        </Heading>
      </Flex>

      <Loading />
    </Pane>
  );
}
