/**
 * *****************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2022 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Adobe and its suppliers, if any. The intellectual and technical concepts
 * contained herein are proprietary to Adobe and its suppliers and are
 * protected by all applicable intellectual property laws, including trade
 * secret and copyright laws. Dissemination of this information or reproduction
 * of this material is strictly forbidden unless prior written permission is
 * obtained from Adobe.
 * *****************************************************************************
 */

import { HTTPParser } from 'http-parser-js';

export default function parseHttpResponse(input: string): Buffer {
  const parser = new HTTPParser(HTTPParser.RESPONSE);
  const bodyChunks: Buffer[] = [];

  parser[HTTPParser.kOnBody] = (chunk, offset, length) => {
    bodyChunks.push(chunk.slice(offset, offset + length));
  };

  parser.execute(Buffer.from(input, 'utf-8'));
  parser.finish();

  return Buffer.concat(bodyChunks);
}
