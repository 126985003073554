/**
 * *****************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2022 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Adobe and its suppliers, if any. The intellectual and technical concepts
 * contained herein are proprietary to Adobe and its suppliers and are
 * protected by all applicable intellectual property laws, including trade
 * secret and copyright laws. Dissemination of this information or reproduction
 * of this material is strictly forbidden unless prior written permission is
 * obtained from Adobe.
 * *****************************************************************************
 */

import React, { ReactElement, useCallback, useState } from 'react';

import {
  Button,
  Content,
  Grid,
  Heading,
  IllustratedMessage,
  Text,
  View,
} from '@adobe/react-spectrum';
import AssetsExpired from '@spectrum-icons/workflow/AssetsExpired';
import CollectionExclude from '@spectrum-icons/workflow/CollectionExclude';
import FolderSearch from '@spectrum-icons/workflow/FolderSearch';
import LinkOut from '@spectrum-icons/workflow/LinkOut';

import { mapAssetToDesignAsset } from '__DEPRECATED_DO_NOT_USE_OR_YOU_WILL_BE_FIRED/controllers/api/SparkSearch';
import { DesignAsset } from '__DEPRECATED_DO_NOT_USE_OR_YOU_WILL_BE_FIRED/controllers/api/SparkSearchTypes';

import { SearchAssetCard } from 'components/Card';
import Loading from 'components/Loading';
import ScrollPane from 'components/ScrollPane';
import useConstant from 'hooks/useConstant';
import useSparkSearch from 'hooks/useSparkSearch';
import { ContentSearchFilter } from 'model/ContentSearch/ContentSearchFilter';
import ContentSearchQuery from 'model/ContentSearch/ContentSearchQuery';
import { SemanticFolder } from 'model/SemanticFolder';
import { ZoomLevel, zoomLevelToSizeMapping } from 'model/ZoomLevel';
import { chunk } from 'utils';

import BulkActionBar from './BulkActionBar';

type Props = {
  collectionAssetIds: Set<string>;
  jumpToSearch: (asset: DesignAsset) => void;
  removeFromCollection: (selected: Set<string>) => void;
  zoomLevel: ZoomLevel;
};

export default function CollectionAssetPreview({
  collectionAssetIds,
  jumpToSearch,
  removeFromCollection,
  zoomLevel,
}: Props): ReactElement {
  const { acpBasePath } = useConstant();
  const [selected, setSelected] = useState<Set<string>>(new Set<string>());
  const clearSelected = useCallback(
    () => setSelected(new Set<string>()),
    [setSelected],
  );
  const handleRemoveFromCollection = useCallback(() => {
    removeFromCollection(selected);

    clearSelected();
  }, [removeFromCollection, selected, clearSelected]);
  const { results, isLoading } = useSparkSearch({
    queries: chunk([...collectionAssetIds], 10).map((assetIdChunk) => {
      const csQuery = new ContentSearchQuery();
      csQuery.filters.set(ContentSearchFilter.Id, assetIdChunk);

      return csQuery;
    }),
    enabled: collectionAssetIds.size !== 0,
  });
  const onAssetAction = useCallback(
    (asset: DesignAsset) => (assetAction: string) => {
      switch (assetAction) {
        case 'search-in-folder':
          jumpToSearch(asset);
          break;

        case 'view-details':
          window.open(
            asset.path.replace(
              acpBasePath.publish,
              `/${SemanticFolder.Author}`,
            ),
            '_blank',
          );
          break;
      }
    },
    [acpBasePath.publish, jumpToSearch],
  );
  const onAssetSelect = useCallback(
    (assetPath: string) => {
      if (selected.has(assetPath)) {
        setSelected(
          new Set<string>(
            [...selected].filter((selection) => selection !== assetPath),
          ),
        );
      } else {
        setSelected(new Set<string>([...selected, assetPath]));
      }
    },
    [selected, setSelected],
  );
  const allSearchResults: DesignAsset[] = results
    .map((result) => result.results)
    .flat()
    .map(mapAssetToDesignAsset);

  if (collectionAssetIds.size === 0) {
    return (
      <IllustratedMessage flex minHeight="size-0">
        <AssetsExpired size="XXL" />
        <Heading>No assets in collection</Heading>
        <Content>Add assets to the collection to see their preview</Content>
      </IllustratedMessage>
    );
  }

  return (
    <>
      <BulkActionBar selected={selected} onClear={clearSelected}>
        <Button
          onPress={handleRemoveFromCollection}
          variant="overBackground"
          isQuiet
        >
          <CollectionExclude size="S" marginEnd="size-75" />
          <Text marginStart="size-75">Remove from Collection</Text>
        </Button>
      </BulkActionBar>

      <View margin="size-0" height="100%" position="relative">
        <ScrollPane>
          {isLoading ? (
            <Loading />
          ) : (
            <Grid
              columns={`repeat(auto-fill, minmax(min(var(${zoomLevelToSizeMapping[zoomLevel]}), 100%), 1fr)`}
              gap="size-300"
              marginTop="size-300"
              marginBottom="size-400"
              marginX="size-300"
            >
              {allSearchResults.map(
                (asset: DesignAsset): ReactElement => (
                  <View key={asset.name}>
                    <SearchAssetCard
                      key={asset.path}
                      asset={asset}
                      isActive={selected.size !== 0}
                      isSelected={selected.has(asset.id)}
                      onAction={onAssetAction(asset)}
                      onClick={() => onAssetSelect(asset.id)}
                      onSelect={() => onAssetSelect(asset.id)}
                      quickActions={
                        zoomLevel > ZoomLevel.Small
                          ? [
                              {
                                icon: FolderSearch,
                                label: 'Search in Folder',
                                value: 'search-in-folder',
                              },
                              {
                                icon: LinkOut,
                                label: 'Go to Asset',
                                value: 'view-details',
                              },
                            ]
                          : undefined
                      }
                      zoomLevel={zoomLevel}
                    />
                  </View>
                ),
              )}
            </Grid>
          )}
        </ScrollPane>
      </View>
    </>
  );
}
