/**
 * *****************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2022 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Adobe and its suppliers, if any. The intellectual and technical concepts
 * contained herein are proprietary to Adobe and its suppliers and are
 * protected by all applicable intellectual property laws, including trade
 * secret and copyright laws. Dissemination of this information or reproduction
 * of this material is strictly forbidden unless prior written permission is
 * obtained from Adobe.
 * *****************************************************************************
 */

import React, { useCallback } from 'react';

import Button from '@react/react-spectrum/Button';
import LoupeView from '@react/react-spectrum/Icon/LoupeView';
import Refresh from '@react/react-spectrum/Icon/Refresh';
import ViewGrid from '@react/react-spectrum/Icon/ViewGrid';

import SparkSearch from '__DEPRECATED_DO_NOT_USE_OR_YOU_WILL_BE_FIRED/controllers/api/SparkSearch';
import {
  AssetAction,
  AssetStatus,
} from '__DEPRECATED_DO_NOT_USE_OR_YOU_WILL_BE_FIRED/controllers/constants/StatusUtils';
import {
  doSelectAllAssets,
  setSelectedAssetIds,
} from '__DEPRECATED_DO_NOT_USE_OR_YOU_WILL_BE_FIRED/redux/assets/asset.slice';

import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { ScreenView, setSelectionEnabled } from 'redux/screen/screen.slice';
import { CPUserRole } from 'redux/user/user.slice';

import ReviewButtonsToggle from './ReviewButtonsToggle';

import styles from './styles/Wrapper.module.scss';

type TemplatesViewChildProps = {
  isCurator: boolean;
  onViewButtonClick: (newView: ScreenView) => void;
};

type TemplatesViewProps = {
  onRefresh: () => void;
  onViewChange: (type: ScreenView) => void;
  children: (args: TemplatesViewChildProps) => React.ReactNode;
};

const ReviewWrapper: React.FC<TemplatesViewProps> = ({
  onRefresh,
  onViewChange,
  children,
}: TemplatesViewProps) => {
  const dispatch = useAppDispatch();
  const view = useAppSelector((state) => state.screen.view);
  const count = useAppSelector((state) => state.assets.count);
  const total = useAppSelector((state) => state.assets.total);
  const selectionEnabled = useAppSelector(
    (state) => state.screen.selectionEnabled,
  );
  const role = useAppSelector((state) => state.user.cpRole);
  const onReviewToggleClick = (toggleOption: number) => {
    // Clicking the toggle once or twice will enable selection
    const doSelection = toggleOption > 0;
    // Clicking the toggle once will select all items in the Grid
    const doSelectAll = toggleOption === 1;

    dispatch(setSelectionEnabled(doSelection));

    if (doSelectAll) {
      dispatch(doSelectAllAssets());
    } else {
      dispatch(setSelectedAssetIds([]));
    }
  };
  const onApproveClick = () => {
    dispatch(
      SparkSearch.instance.reviewSelectedAssets({
        label: AssetAction.approve,
        value: AssetStatus.approved,
      }),
    );
  };
  const onRejectClick = () => {
    dispatch(
      SparkSearch.instance.reviewSelectedAssets({
        label: AssetAction.reject,
        value: AssetStatus.rejected,
      }),
    );
  };
  const onViewButtonClick = useCallback(
    (newView: ScreenView) => {
      if (view !== newView) {
        onViewChange(newView);
      }
    },
    [onViewChange, view],
  );

  return (
    <>
      <div
        className={`${styles.wrapper} ${
          view === ScreenView.Detail ? styles.detailWrapper : ''
        }`}
      >
        {view === ScreenView.Grid ? (
          <div className={styles.buttons}>
            <div>
              <Button
                variant="tool"
                icon={<Refresh size="S" />}
                onClick={() => onRefresh()}
              />
              <Button
                selected
                variant="tool"
                icon={<ViewGrid size="S" />}
                onClick={() => onViewButtonClick(ScreenView.Grid)}
              />
              <Button
                selected={false}
                variant="tool"
                icon={<LoupeView size="S" />}
                onClick={() => onViewButtonClick(ScreenView.Detail)}
              />
              <div className={styles.total}>{`${count}/${total}`}</div>
            </div>
            <div>
              {role === CPUserRole.curator ? (
                <ReviewButtonsToggle
                  isShowingOptions={selectionEnabled}
                  onToggle={onReviewToggleClick}
                  onApprove={onApproveClick}
                  onReject={onRejectClick}
                />
              ) : null}
            </div>
          </div>
        ) : null}
        <div className={styles.children}>
          {children({
            onViewButtonClick,
            isCurator: role === CPUserRole.curator,
          })}
        </div>
      </div>
    </>
  );
};

export default ReviewWrapper;
