/**
 * *****************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2022 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Adobe and its suppliers, if any. The intellectual and technical concepts
 * contained herein are proprietary to Adobe and its suppliers and are
 * protected by all applicable intellectual property laws, including trade
 * secret and copyright laws. Dissemination of this information or reproduction
 * of this material is strictly forbidden unless prior written permission is
 * obtained from Adobe.
 * *****************************************************************************
 */

import React, { ReactElement, useCallback, useEffect, useState } from 'react';

import {
  Button,
  Content,
  Flex,
  Heading,
  IllustratedMessage,
} from '@adobe/react-spectrum';
import Error from '@spectrum-icons/illustrations/Error';

import CCXAssetCollectionForm, {
  CCXAssetCollectionFormMode,
} from 'components/CCXAssetCollectionForm/CCXAssetCollectionForm';
import CCXTemplateCollectionForm, {
  CCXTemplateCollectionFormMode,
} from 'components/CCXTemplateCollectionForm';
import StockCollectionForm from 'components/StockCollectionForm';
import { StockCollectionFormMode } from 'components/StockCollectionForm/StockCollectionForm';
import useCollectionQueryModel, {
  mapCollectionQueryModel,
} from 'hooks/useCollectionQueryModel';
import useCurrentAssetByPath from 'hooks/useCurrentAssetByPath';
import { CollectionFile } from 'model/acp/CollectionFile';
import { CollectionQueryModel } from 'model/acp/CollectionQueryModel';
import { CollectionSource } from 'model/acp/CollectionSource';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { updateEditedQuery } from 'redux/queryModel/queryModel.slice';
import { fixCollectionFile } from 'utils/validation/collections';

import AssetInfo from './AssetInfo';
import LoadingDetailView from './LoadingDetailView';

export default function CollectionDetailView(): ReactElement {
  const dispatch = useAppDispatch();
  // Load external configurations and path information
  const { currentAssetByPath } = useCurrentAssetByPath();
  const {
    results: collectionQueryModel,
    file: collectionFile,
    isLoading: fileIsLoading,
  } = useCollectionQueryModel({ path: currentAssetByPath });
  const editedQueryModel = useAppSelector(
    (state) => state.queryModel.editedQueryModel,
  );

  useEffect(() => {
    if (fileIsLoading) {
      dispatch(updateEditedQuery(undefined));
    } else {
      dispatch(updateEditedQuery(collectionQueryModel));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentAssetByPath, fileIsLoading]);

  // Collection preview actions
  const addToCollectionPreview = useCallback(
    (selectedToAdd: Set<string>) => {
      const currentAssetIds =
        editedQueryModel?.[CollectionSource.CCXAsset]?.assetIds ?? [];
      dispatch(
        updateEditedQuery({
          source: CollectionSource.CCXAsset,
          [CollectionSource.CCXAsset]: {
            assetIds: new Set([...currentAssetIds, ...selectedToAdd]),
          },
        }),
      );
    },
    [editedQueryModel, dispatch],
  );
  const removeFromCollectionPreview = useCallback(
    (selectedToRemove: Set<string>) => {
      const currentAssetIds =
        editedQueryModel?.[CollectionSource.CCXAsset]?.assetIds ?? [];
      const updatedSelected = new Set([...currentAssetIds]);

      selectedToRemove.forEach((selectedId) => {
        if (updatedSelected.has(selectedId)) {
          updatedSelected.delete(selectedId);
        }
      });
      dispatch(
        updateEditedQuery({
          source: CollectionSource.CCXAsset,
          [CollectionSource.CCXAsset]: {
            assetIds: updatedSelected,
          },
        } as CollectionQueryModel),
      );
    },
    [editedQueryModel, dispatch],
  );
  // Handle broken collection
  const [fixAttemptError, setFixAttemptError] = useState<Error>();
  const fixQueryModel = useCallback(() => {
    if (!collectionFile) {
      return;
    }

    let fixedCollectionFile: CollectionFile;

    try {
      fixedCollectionFile = fixCollectionFile(collectionFile);
      dispatch(updateEditedQuery(mapCollectionQueryModel(fixedCollectionFile)));
    } catch (error) {
      setFixAttemptError(error as Error);
    }
  }, [collectionFile, dispatch]);

  if (fileIsLoading || collectionQueryModel === undefined) {
    return <LoadingDetailView />;
  }

  let collectionForm;

  if (fixAttemptError) {
    collectionForm = (
      <Flex
        alignItems="center"
        direction="column"
        flex
        justifyContent="center"
        marginBottom="size-200"
        minHeight="size-0"
      >
        <IllustratedMessage>
          <Error />
          <Heading>Unable to Fix Collection</Heading>
          <Content>
            <p>
              This collection was unable to be fixed for the following reason:
            </p>
            <p>
              <code>{fixAttemptError.message}</code>
            </p>
            <p>
              Report this error in the <code>#cclight-tempo-support</code>{' '}
              channel.
            </p>
          </Content>
        </IllustratedMessage>
      </Flex>
    );
  } else if (editedQueryModel) {
    switch (editedQueryModel.source) {
      case CollectionSource.CCXAsset:
        collectionForm = (
          <CCXAssetCollectionForm
            collectionQueryModel={editedQueryModel}
            initialMode={CCXAssetCollectionFormMode.Preview}
            removeFromCollection={removeFromCollectionPreview}
            addToCollection={addToCollectionPreview}
          />
        );
        break;

      case CollectionSource.CCXTemplate:
        collectionForm = (
          <CCXTemplateCollectionForm
            collectionQueryModel={editedQueryModel}
            initialMode={CCXTemplateCollectionFormMode.Edit}
            onChange={(query) => dispatch(updateEditedQuery(query))}
          />
        );
        break;

      case CollectionSource.Stock:
        collectionForm = (
          <StockCollectionForm
            collectionQueryModel={editedQueryModel}
            initialMode={StockCollectionFormMode.Edit}
            onChange={(query) => dispatch(updateEditedQuery(query))}
          />
        );
        break;

      default:
        collectionForm = (
          <Flex
            alignItems="center"
            direction="column"
            flex
            justifyContent="center"
            marginBottom="size-200"
            minHeight="size-0"
          >
            <IllustratedMessage>
              <Error />
              <Heading>Broken Collection</Heading>
              <Content>
                <p>
                  This collection has an outdated or invalid collection
                  specification. Tempo can automatically attempt to fix the
                  collection.
                </p>
                <p>
                  This is a safe operation that must be saved if it succeeds. It
                  will not update the collection file otherwise.
                </p>
              </Content>

              <Button
                marginTop="size-100"
                onPress={fixQueryModel}
                variant="cta"
              >
                Fix Collection
              </Button>
            </IllustratedMessage>
          </Flex>
        );
    }
  }

  return (
    <Flex height="100%" width="100%">
      <Flex flex direction="column" marginTop="size-400">
        <>
          <AssetInfo />
          {collectionForm}
        </>
      </Flex>
    </Flex>
  );
}
