/**
 * *****************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2022 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Adobe and its suppliers, if any. The intellectual and technical concepts
 * contained herein are proprietary to Adobe and its suppliers and are
 * protected by all applicable intellectual property laws, including trade
 * secret and copyright laws. Dissemination of this information or reproduction
 * of this material is strictly forbidden unless prior written permission is
 * obtained from Adobe.
 * *****************************************************************************
 */

import React, { ReactElement } from 'react';

import { View } from '@adobe/react-spectrum';

import { CollectionQueryModel } from 'model/acp/CollectionQueryModel';
import { buildCollectionJSON } from 'utils/file';

type CollectionFileLivePreviewProps = {
  collectionQueryModel: CollectionQueryModel;
};

export default function CollectionFileLivePreview({
  collectionQueryModel,
}: CollectionFileLivePreviewProps): ReactElement {
  return (
    <View
      backgroundColor="static-white"
      borderColor="gray-300"
      borderRadius="small"
      borderWidth="thin"
      height="calc(100% - 32px)"
      marginTop="size-200"
      marginX="size-300"
      minHeight="size-0"
      overflow="scroll"
      padding="size-100"
      position="relative"
    >
      <code
        className="spectrum-Code spectrum-Code--sizeS"
        style={{
          whiteSpace: 'pre',
          overflow: 'scroll',
        }}
      >
        {JSON.stringify(buildCollectionJSON(collectionQueryModel), null, 2)}
      </code>
    </View>
  );
}
