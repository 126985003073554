/**
 * *****************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2022 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Adobe and its suppliers, if any. The intellectual and technical concepts
 * contained herein are proprietary to Adobe and its suppliers and are
 * protected by all applicable intellectual property laws, including trade
 * secret and copyright laws. Dissemination of this information or reproduction
 * of this material is strictly forbidden unless prior written permission is
 * obtained from Adobe.
 * *****************************************************************************
 */

import { useEffect, useMemo, useState } from 'react';
import { useQueries, UseQueryResult } from 'react-query';

import Axios, { AxiosResponse } from 'axios';
import { first } from 'lodash';

import useACPLink from 'hooks/acp/useACPLink';
import { ACPLink } from 'model/acp/ACPLink';
import ApplicationMetadata from 'model/acp/ApplicationMetadata';
import { ApplicationMetadataNamespace } from 'model/acp/ApplicationMetadataNamespace';
import { ApplicationMetadataResponse } from 'utils/metadata/applicationMetadata';

type SingleApplicationMetadataHookProps = {
  path: string;
  paths?: never;
  enabled?: boolean;
};
type SingleApplicationMetadataHookResult = {
  results: ApplicationMetadata | undefined;
  isLoading: boolean;
};
type MultipleApplicationMetadataHookProps = {
  path?: never;
  paths: string[];
  enabled?: boolean;
};
type MultipleApplicationMetadataHookResult = {
  results: Record<string, ApplicationMetadata | undefined>;
  isLoading: boolean;
};

/**
 * Use a 1 minute stale time. This improves the navigating experience through
 * the Card view.
 */
const QUERY_STALE_TIME = 60000;

function useApplicationMetadata(
  args: SingleApplicationMetadataHookProps,
): SingleApplicationMetadataHookResult;
function useApplicationMetadata(
  args: MultipleApplicationMetadataHookProps,
): MultipleApplicationMetadataHookResult;
function useApplicationMetadata(
  args:
    | SingleApplicationMetadataHookProps
    | MultipleApplicationMetadataHookProps,
): SingleApplicationMetadataHookResult | MultipleApplicationMetadataHookResult;
function useApplicationMetadata({
  path: metadataPath,
  paths: metadataPaths,
  enabled = true,
}: SingleApplicationMetadataHookProps | MultipleApplicationMetadataHookProps):
  | SingleApplicationMetadataHookResult
  | MultipleApplicationMetadataHookResult {
  const [results, setResults] = useState<
    SingleApplicationMetadataHookResult | MultipleApplicationMetadataHookResult
  >({
    isLoading: true,
    results: undefined,
  });
  /**
   * Create at least the basic return result and if it turns out there is data
   * to return then it will be enhanced.
   */
  const queryPaths: string[] = useMemo(() => {
    let query: string[] = [];

    if (metadataPaths !== undefined) {
      query = metadataPaths;
    } else if (metadataPath !== undefined) {
      query = [metadataPath];
    }

    return query.filter((queryPath) => queryPath !== '');
  }, [metadataPath, metadataPaths]);
  const { isLoading: isLoadingACPLink, results: acpLinks } = useACPLink({
    acpLink: ACPLink.ApplicationMetadata,
    paths: queryPaths,
    enabled,
  });
  const queries = useQueries(
    queryPaths.map((path) => {
      const requestUrl = acpLinks?.[path]?.linkUrl;
      const GETQuery = async (): Promise<
        AxiosResponse<ApplicationMetadataResponse> | undefined
      > =>
        requestUrl
          ? Axios.get<ApplicationMetadataResponse>(requestUrl)
          : undefined;

      return {
        queryKey: ['applicationMetadata', path],
        queryFn: GETQuery,
        /**
         * Disable the ReactQuery query call if this function is run but the links
         * haven't loaded, because there will be no requestUrl and no reason to
         * make the request.
         */
        enabled: enabled && !isLoadingACPLink && !!requestUrl,
        staleTime: QUERY_STALE_TIME,
      };
    }),
  );
  const hasErrors = queries.some((query) => query.error !== null);
  const isLoading = queries.some((query) => query.isFetching);

  useEffect(() => {
    if (queries.length === 0) {
      return setResults({
        isLoading: false,
        results: undefined,
      });
    }

    if (metadataPath !== undefined) {
      const singleQuery = first(queries) as UseQueryResult<
        AxiosResponse<ApplicationMetadataResponse>
      >;

      return setResults({
        isLoading:
          singleQuery.isLoading ||
          singleQuery.isFetching ||
          singleQuery.isStale,
        results: singleQuery.data
          ? singleQuery.data.data[ApplicationMetadataNamespace] ?? {}
          : undefined,
      });
    }

    return setResults({
      /**
       * This hook is loading if either the initial link request or one of the GET
       * requests is loading
       */
      isLoading: isLoadingACPLink || isLoading,
      results: queries.reduce<Record<string, ApplicationMetadata | undefined>>(
        (
          acc: Record<string, ApplicationMetadata | undefined>,
          query,
          index,
        ) => {
          if (!query.data) {
            return acc;
          }

          const applicationMetadata = (
            query.data as AxiosResponse<ApplicationMetadataResponse>
          ).data[ApplicationMetadataNamespace];
          const path = queryPaths[index];

          return {
            ...acc,
            [path]: applicationMetadata,
          };
        },
        {},
      ),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoadingACPLink, metadataPath, queryPaths, isLoading, hasErrors]);

  return results;
}

export default useApplicationMetadata;
