/**
 * *****************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2022 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Adobe and its suppliers, if any. The intellectual and technical concepts
 * contained herein are proprietary to Adobe and its suppliers and are
 * protected by all applicable intellectual property laws, including trade
 * secret and copyright laws. Dissemination of this information or reproduction
 * of this material is strictly forbidden unless prior written permission is
 * obtained from Adobe.
 * *****************************************************************************
 */

import React, { ReactElement, useCallback, useEffect, useState } from 'react';

import { Flex, View } from '@adobe/react-spectrum';
import { isEqual } from 'lodash';

import DetailActionRail from 'components/AssetExplorer/DetailActionRail';
import CollectionMetadataForm from 'components/AssetMetadataForm/CollectionMetadataForm';
import CommentsMetadataForm from 'components/AssetMetadataForm/CommentsMetadataForm';
import LoadingDetailView from 'components/DetailView/LoadingDetailView';
import useApplicationMetadata from 'hooks/acp/useApplicationMetadata';
import useRepoMetadata from 'hooks/acp/useRepoMetadata';
import useAssetWorkflowActions from 'hooks/useAssetWorkflowActions';
import useCollectionQueryModel from 'hooks/useCollectionQueryModel';
import useCurrentAssetByPath from 'hooks/useCurrentAssetByPath';
import { RepoMetadataKey } from 'model/acp/RepoMetadataKey';
import { DocumentRailPane } from 'model/DocumentRailPane';
import { ValidationFieldErrors } from 'model/errors/ValidationError';
import { CollectionMetadataModel } from 'model/metadata/CollectionMetadataModel';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import {
  updateEditedAndSavedQuery,
  updateEditedQuery,
  updateSavedQuery,
} from 'redux/queryModel/queryModel.slice';
import { buildCollectionJSON } from 'utils/file';
import {
  mapFromCollectionMetadata,
  validate,
} from 'utils/metadata/collectionMetadata';

type CollectionRailAndMetadataProps = {
  isMetadataFormCollapsed: boolean;
  onMetadataFormToggle: () => void;
  onMetadataFormOpen: () => void;
};

export default function CollectionRailAndMetadata({
  isMetadataFormCollapsed,
  onMetadataFormToggle,
  onMetadataFormOpen,
}: CollectionRailAndMetadataProps): ReactElement {
  const dispatch = useAppDispatch();
  // Load external configurations and path information
  const { currentAssetByPath } = useCurrentAssetByPath();
  const { results: applicationMetadata } = useApplicationMetadata({
    path: currentAssetByPath,
  });
  const { results: repoMetadata } = useRepoMetadata({
    path: currentAssetByPath,
  });
  const assetWorkflowActions = useAssetWorkflowActions();
  const {
    results: collectionQueryModel,
    isLoading: fileIsLoading,
    hash: fileHash,
  } = useCollectionQueryModel({ path: currentAssetByPath });
  const [hasStagedChanges, setHasStagedChanges] = useState(false);
  const { detailRailPane } = useAppSelector((state) => state.explorer);
  const { savedQueryModel, editedQueryModel } = useAppSelector(
    (state) => state.queryModel,
  );

  useEffect(() => {
    if (fileIsLoading) {
      dispatch(updateEditedAndSavedQuery({}));
    } else {
      dispatch(updateEditedAndSavedQuery(collectionQueryModel));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentAssetByPath, fileIsLoading]);

  const resetCollectionPreview = useCallback(
    () => dispatch(updateEditedQuery(savedQueryModel)),
    [savedQueryModel, dispatch],
  );
  // Handle save
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const saveCollection = useCallback(
    async (
      model: CollectionMetadataModel,
    ): Promise<ValidationFieldErrors | undefined> => {
      if (!repoMetadata || !collectionQueryModel || !fileHash) {
        return undefined;
      }

      setIsSaving(true);

      // Validate model fields
      const errors = validate(model);

      if (errors) {
        setIsSaving(false);

        return errors;
      }

      const updatedMetadata = mapFromCollectionMetadata(
        model,
        applicationMetadata,
      );
      await assetWorkflowActions.updateMetadata(
        repoMetadata[RepoMetadataKey.Path],
        updatedMetadata,
      );

      // Update collection file contents
      if (editedQueryModel && savedQueryModel !== editedQueryModel) {
        await assetWorkflowActions.updateFile(
          repoMetadata[RepoMetadataKey.Path],
          buildCollectionJSON(editedQueryModel),
          fileHash,
        );
        dispatch(updateSavedQuery(editedQueryModel));
      }

      setIsSaving(false);

      return undefined;
    },
    [
      assetWorkflowActions,
      applicationMetadata,
      collectionQueryModel,
      editedQueryModel,
      fileHash,
      repoMetadata,
      savedQueryModel,
      setIsSaving,
      dispatch,
    ],
  );

  if (fileIsLoading || collectionQueryModel === undefined) {
    return <LoadingDetailView />;
  }

  return (
    <View
      marginStart={isMetadataFormCollapsed ? 'size-0' : 'size-200'}
      height="calc(100vh - 75px)"
    >
      <Flex width="100%" gap="size-200" height="100%">
        <Flex width="100%" flex>
          {detailRailPane === DocumentRailPane.Comments &&
            !isMetadataFormCollapsed && (
              <CommentsMetadataForm
                isCollapsed={isMetadataFormCollapsed}
                onCollapse={onMetadataFormToggle}
              />
            )}
          {detailRailPane === DocumentRailPane.Details &&
            !isMetadataFormCollapsed && (
              <CollectionMetadataForm
                editedQueryModel={editedQueryModel}
                hasExternalChanges={!isEqual(savedQueryModel, editedQueryModel)}
                isCollapsed={isMetadataFormCollapsed}
                isSaving={isSaving}
                onCollapse={onMetadataFormToggle}
                onReset={resetCollectionPreview}
                onSave={saveCollection}
                path={currentAssetByPath}
                onChangesStaged={(value) => setHasStagedChanges(value)}
              />
            )}
          {repoMetadata && (
            <DetailActionRail
              path={currentAssetByPath}
              repoMetadata={repoMetadata}
              hasStagedChanges={hasStagedChanges}
              onOpenForm={onMetadataFormOpen}
              isPanelCollapsed={isMetadataFormCollapsed}
            />
          )}
        </Flex>
      </Flex>
    </View>
  );
}
