/**
 * *****************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2022 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Adobe and its suppliers, if any. The intellectual and technical concepts
 * contained herein are proprietary to Adobe and its suppliers and are
 * protected by all applicable intellectual property laws, including trade
 * secret and copyright laws. Dissemination of this information or reproduction
 * of this material is strictly forbidden unless prior written permission is
 * obtained from Adobe.
 * *****************************************************************************
 */

import { useQueries, UseQueryResult } from 'react-query';

import Axios, { AxiosResponse } from 'axios';
import { first } from 'lodash';

import ContentSearchQuery from 'model/ContentSearch/ContentSearchQuery';

import useConstant from './useConstant';

type EmbeddedResults = {
  aggregations?: {
    [key: string]: {
      buckets: {
        key: string;
        docCount: number;
      }[];
    };
  };
  count: number;
  results: any[];
  total: number;
};
type ContentSearchResponse = {
  _embedded: EmbeddedResults;
  _links: {
    next: {
      href: string;
    };
  };
};
type SparkSearchHookResult = {
  isLoading: boolean;
  results?: EmbeddedResults;
};
type SparkSearchProps = {
  query?: ContentSearchQuery;
  queries?: never;
  enabled?: boolean;
};

type BatchSparkSearchProps = {
  queries: ContentSearchQuery[];
  query?: never;
  enabled?: boolean;
};

type BatchSparkSearchHookResult = {
  isLoading: boolean;
  results: EmbeddedResults[];
};

function useSparkSearch(args: SparkSearchProps): SparkSearchHookResult;
function useSparkSearch(
  args: BatchSparkSearchProps,
): BatchSparkSearchHookResult;
function useSparkSearch({
  query: sparkSearchQuery,
  queries: sparkSearchQueries,
  enabled = true,
}: SparkSearchProps | BatchSparkSearchProps):
  | SparkSearchHookResult
  | BatchSparkSearchHookResult {
  const { url, sparkSearch } = useConstant();
  const sparkQueries = sparkSearchQuery
    ? [sparkSearchQuery]
    : (sparkSearchQueries as ContentSearchQuery[]);
  const queries = useQueries(
    sparkQueries.map((queryToPerform) => {
      const requestUrl = `${url.searchServiceBase}${sparkSearch.contentSearchPath}${queryToPerform}`;
      const getQuery = async (): Promise<
        AxiosResponse<ContentSearchResponse>
      > => Axios.get(requestUrl);

      return {
        queryFn: getQuery,
        queryKey: requestUrl,
        enabled,
        refetchOnWindowFocus: false,
      };
    }),
  );

  if (sparkSearchQuery) {
    const singleQuery = first(queries) as UseQueryResult<
      AxiosResponse<ContentSearchResponse>
    >;

    return {
      isLoading: singleQuery.isFetching,
      results: singleQuery.data?.data._embedded,
    };
  }

  return {
    isLoading: queries.some((query) => query.isFetching),
    results: queries
      .map((query) => {
        if (!query.isFetching && query.data) {
          const response = query.data as AxiosResponse<ContentSearchResponse>;

          return response.data._embedded;
        }

        return null;
      })
      .filter((results) => results !== null) as EmbeddedResults[],
  };
}

export default useSparkSearch;
