/**
 * *****************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2022 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Adobe and its suppliers, if any. The intellectual and technical concepts
 * contained herein are proprietary to Adobe and its suppliers and are
 * protected by all applicable intellectual property laws, including trade
 * secret and copyright laws. Dissemination of this information or reproduction
 * of this material is strictly forbidden unless prior written permission is
 * obtained from Adobe.
 * *****************************************************************************
 */

export enum AssetAction {
  Approve = 'Approve',
  Create = 'Create',
  Delete = 'Delete',
  Download = 'Download',
  MetadataExport = 'Export Metadata',
  MetadataImport = 'Import Metadata',
  Move = 'Move',
  Publish = 'Publish',
  Reject = 'Reject',
  Rename = 'Rename',
  Save = 'Save',
  Share = 'Share',
  Unshare = 'Unshare',
  Unpublish = 'Unpublish',
  Update = 'Update',
  UpdateACL = 'Update ACL',
  Verify = 'Verify',
}
