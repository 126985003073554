/**
 * *****************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2022 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Adobe and its suppliers, if any. The intellectual and technical concepts
 * contained herein are proprietary to Adobe and its suppliers and are
 * protected by all applicable intellectual property laws, including trade
 * secret and copyright laws. Dissemination of this information or reproduction
 * of this material is strictly forbidden unless prior written permission is
 * obtained from Adobe.
 * *****************************************************************************
 */

// NOTE: if we add any new ones here, please make sure to update MTService

export enum Language {
  Default = 'i-default',
  'Chinese (Simplified)' = 'zh-Hans-CN',
  'Chinese (Traditional)' = 'zh-Hant-TW',
  'Norwegian - Bokmal' = 'nb-NO',
  'Swedish - Sweden' = 'sv-SE',
  Danish = 'da-DK',
  English = 'en-US',
  Finnish = 'fi-FI',
  French = 'fr-FR',
  German = 'de-DE',
  Italian = 'it-IT',
  Japanese = 'ja-JP',
  Korean = 'ko-KR',
  Netherlands = 'nl-NL',
  Portuguese = 'pt-BR',
  Spanish = 'es-ES',
}

export const TranslatedLanguages = Object.values(Language)
  .filter((locale: string) => locale !== Language.Default)
  .sort((l1: string, l2: string) => (l1 > l2 ? 1 : -1));
