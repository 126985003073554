/**
 * *****************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2022 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Adobe and its suppliers, if any. The intellectual and technical concepts
 * contained herein are proprietary to Adobe and its suppliers and are
 * protected by all applicable intellectual property laws, including trade
 * secret and copyright laws. Dissemination of this information or reproduction
 * of this material is strictly forbidden unless prior written permission is
 * obtained from Adobe.
 * *****************************************************************************
 */

import parse from 'csv-parse';

import { CollectionFile } from 'model/acp/CollectionFile';
import { CollectionQueryModel } from 'model/acp/CollectionQueryModel';
import { CollectionSource } from 'model/acp/CollectionSource';
import { Language } from 'model/Language';

export function buildCollectionJSON(
  queryModel: CollectionQueryModel,
): CollectionFile {
  const collectionJSON: CollectionFile = {};

  if (queryModel.source === CollectionSource.CCXAsset) {
    const assetIds = queryModel[CollectionSource.CCXAsset]?.assetIds ?? [];
    collectionJSON.ASSET = {
      include: {
        id: [...assetIds],
      },
    };
  }

  if (queryModel.source === CollectionSource.CCXTemplate) {
    const tasks = queryModel[CollectionSource.CCXTemplate]?.tasks;
    const topics = queryModel[CollectionSource.CCXTemplate]?.topics;
    collectionJSON.TEMPLATE = {
      tasks,
      topics,
    };
  }

  if (queryModel[CollectionSource.Stock] !== undefined) {
    collectionJSON.STOCK = {};

    const stockQuery = queryModel[CollectionSource.Stock]?.query;

    if (stockQuery && stockQuery.size !== 0) {
      const languageRecord: Record<Language, string> = {} as Record<
        Language,
        string
      >;

      for (const [language, translation] of stockQuery) {
        languageRecord[language] = translation;
      }

      collectionJSON.STOCK.q = languageRecord;
    }

    const stockExternalCollectionId =
      queryModel[CollectionSource.Stock]?.externalCollectionId;

    if (stockExternalCollectionId) {
      collectionJSON.STOCK.externalCollectionId = stockExternalCollectionId;
    }
  }

  if (
    Object.values(collectionJSON).length === 0 &&
    queryModel.source === CollectionSource.Stock
  ) {
    collectionJSON.STOCK = {};
  }

  return collectionJSON;
}

export function downloadCSV(fileName: string, data: string): void {
  const link = document.createElement('a');

  link.download = fileName;
  link.href = `data:text/csv;charset=utf-8,${escape(data)}`;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}

export function parseDataToCSV<T>(
  data: string,
  headers: { [key: string]: string },
): Promise<T[]> {
  return new Promise((resolve, reject) => {
    function transformAndValidateHeaders(header: string[]) {
      const newHeaders: string[] = header.map((column: string) => {
        if (column in headers) {
          return headers[column];
        }

        return column;
      });

      if (!Object.values(headers).every((r) => newHeaders.includes(r))) {
        throw new Error(
          `At least one missing required column header: ${Object.keys(
            headers,
          ).join('/')}`,
        );
      }

      return newHeaders;
    }

    parse(
      data,
      {
        trim: true,
        skip_lines_with_empty_values: true,
        columns: (header: string[]) => transformAndValidateHeaders(header),
        raw: true,
        relax_column_count: true,
        on_record: ({ record }: any) =>
          Object.values(headers).reduce(
            (acc: any, key: string) => ({
              ...acc,
              [key]: record[key],
            }),
            {},
          ),
      },
      (err, output) => {
        if (err) {
          return reject(err);
        }

        return resolve(output);
      },
    );
  });
}

export function parseFileAsText(file: File): Promise<string | undefined> {
  const reader = new FileReader();

  return new Promise((resolve, reject) => {
    reader.onerror = () => {
      reader.abort();

      reject(new Error('Error parsing input file.'));
    };

    reader.onload = () => {
      resolve(reader.result as string | undefined);
    };

    reader.readAsText(file);
  });
}
