/**
 * *****************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2022 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Adobe and its suppliers, if any. The intellectual and technical concepts
 * contained herein are proprietary to Adobe and its suppliers and are
 * protected by all applicable intellectual property laws, including trade
 * secret and copyright laws. Dissemination of this information or reproduction
 * of this material is strictly forbidden unless prior written permission is
 * obtained from Adobe.
 * *****************************************************************************
 */

import React, { ReactChild, ReactElement, useCallback } from 'react';

import { View } from '@adobe/react-spectrum';
import Checkmark from '@spectrum-icons/workflow/Checkmark';
import Comment from '@spectrum-icons/workflow/Comment';
import Info from '@spectrum-icons/workflow/Info';
import PublishCheck from '@spectrum-icons/workflow/PublishCheck';
import PublishPending from '@spectrum-icons/workflow/PublishPending';
import PublishReject from '@spectrum-icons/workflow/PublishReject';
import TableEdit from '@spectrum-icons/workflow/TableEdit';

import TemplateRail from '__DEPRECATED_DO_NOT_USE_OR_YOU_WILL_BE_FIRED/components/TemplateRail';
import { TemplateRailActionProps } from '__DEPRECATED_DO_NOT_USE_OR_YOU_WILL_BE_FIRED/components/TemplateRail/TemplateRailAction';
import {
  AssetAction,
  AssetStatus,
} from '__DEPRECATED_DO_NOT_USE_OR_YOU_WILL_BE_FIRED/controllers/constants/StatusUtils';
import useAssetCommentCount from '__DEPRECATED_DO_NOT_USE_OR_YOU_WILL_BE_FIRED/hooks/useAssetCommentCount';
import { FilterTab } from '__DEPRECATED_DO_NOT_USE_OR_YOU_WILL_BE_FIRED/redux/filter/filter.slice';
import {
  saveAsset,
  setMetadataValue,
} from '__DEPRECATED_DO_NOT_USE_OR_YOU_WILL_BE_FIRED/redux/metadata/metadata.thunks';

import {
  selectTemplateDetailRailState,
  setTemplateDetailRailState,
  TemplateRailActionState,
} from 'redux/__DEPRECATED_templates/templates.slice';
import { useAppDispatch, useAppSelector } from 'redux/hooks';

type CommentsAwareTemplateRailActionProps = TemplateRailActionProps & {
  currentAssetId: string;
};

function CommentsAwareTemplateRailAction({
  currentAssetId,
  ...args
}: CommentsAwareTemplateRailActionProps) {
  const { result: openCommentCount } = useAssetCommentCount({
    currentAssetId,
    options: {
      status: 'open',
    },
  });
  const { result: resolvedCommentCount } = useAssetCommentCount({
    currentAssetId,
    options: {
      status: 'resolved',
    },
  });
  const hasLoadedComments =
    openCommentCount !== undefined && resolvedCommentCount !== undefined;
  const hasOpenComments = hasLoadedComments && openCommentCount !== 0;
  const hasResolvedComments = hasLoadedComments && resolvedCommentCount !== 0;
  let commentCount: ReactChild | undefined;
  let badgeVariant: string | undefined;

  if (hasOpenComments) {
    commentCount = openCommentCount;
    badgeVariant = 'warning';
  } else if (hasResolvedComments) {
    commentCount = <Checkmark size="XS" />;
    badgeVariant = 'success';
  }

  return (
    <TemplateRail.Action
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...args}
      badge={commentCount}
      badgeVariant={badgeVariant}
    />
  );
}

type DetailActionRailProps = {
  shouldShowCommentCount?: boolean;
  currentAssetId: string;
  currentAssetStatus: AssetStatus;
  isCurator: boolean;
  tab: FilterTab;
};

export default function DetailActionRail({
  shouldShowCommentCount = false,
  currentAssetId,
  currentAssetStatus,
  isCurator,
  tab,
}: DetailActionRailProps): ReactElement {
  const templateDetailRailState = useAppSelector(selectTemplateDetailRailState);
  const dispatch = useAppDispatch();
  const openTemplateOverview = useCallback(
    () =>
      dispatch(
        setTemplateDetailRailState(TemplateRailActionState.TemplateOverview),
      ),
    [dispatch],
  );
  const openTemplateMetadata = useCallback(
    () =>
      dispatch(
        setTemplateDetailRailState(TemplateRailActionState.TemplateMetadata),
      ),
    [dispatch],
  );
  const openComments = useCallback(
    () =>
      dispatch(setTemplateDetailRailState(TemplateRailActionState.Comments)),
    [dispatch],
  );
  const onUpdateStatus = useCallback(
    (assetStatus: AssetStatus) => {
      dispatch(setMetadataValue(tab, 'status', assetStatus));
      dispatch(saveAsset());
    },
    [dispatch, tab],
  );
  const onApprove = useCallback(
    () => onUpdateStatus(AssetStatus.approved),
    [onUpdateStatus],
  );
  const onPending = useCallback(
    () => onUpdateStatus(AssetStatus.pending),
    [onUpdateStatus],
  );
  const onReject = useCallback(
    () => onUpdateStatus(AssetStatus.rejected),
    [onUpdateStatus],
  );

  return (
    <TemplateRail>
      <TemplateRail.Action
        isActive={
          templateDetailRailState === TemplateRailActionState.TemplateOverview
        }
        label="Overview"
        icon={Info}
        action={openTemplateOverview}
      />
      <TemplateRail.Action
        isActive={
          templateDetailRailState === TemplateRailActionState.TemplateMetadata
        }
        label="Template Metadata"
        icon={TableEdit}
        action={openTemplateMetadata}
      />
      {shouldShowCommentCount ? (
        <CommentsAwareTemplateRailAction
          currentAssetId={currentAssetId}
          isActive={
            templateDetailRailState === TemplateRailActionState.Comments
          }
          label="Comments"
          icon={Comment}
          action={openComments}
        />
      ) : (
        <TemplateRail.Action
          isActive={
            templateDetailRailState === TemplateRailActionState.Comments
          }
          label="Comments"
          icon={Comment}
          action={openComments}
        />
      )}
      <View
        key="separator"
        borderBottomColor="gray-400"
        borderBottomWidth="thin"
      />
      <TemplateRail.Action
        isDisabled={!isCurator || currentAssetStatus === AssetStatus.approved}
        label={AssetAction.approve}
        icon={PublishCheck}
        action={onApprove}
      />
      <TemplateRail.Action
        isDisabled={!isCurator || currentAssetStatus === AssetStatus.rejected}
        label={AssetAction.reject}
        icon={PublishReject}
        action={onReject}
      />
      {currentAssetStatus === AssetStatus.approved ? (
        <></>
      ) : (
        <TemplateRail.Action
          isDisabled={!isCurator || currentAssetStatus === AssetStatus.pending}
          label={AssetAction.pending}
          icon={PublishPending}
          action={onPending}
        />
      )}
    </TemplateRail>
  );
}

DetailActionRail.defaultProps = {
  shouldShowCommentCount: false,
};
