/**
 * *****************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2022 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Adobe and its suppliers, if any. The intellectual and technical concepts
 * contained herein are proprietary to Adobe and its suppliers and are
 * protected by all applicable intellectual property laws, including trade
 * secret and copyright laws. Dissemination of this information or reproduction
 * of this material is strictly forbidden unless prior written permission is
 * obtained from Adobe.
 * *****************************************************************************
 */

import React, { ReactElement } from 'react';

import { getFixedWidthRendition } from '__DEPRECATED_DO_NOT_USE_OR_YOU_WILL_BE_FIRED/controllers/api/SparkSearch';
import { TemplateAsset } from '__DEPRECATED_DO_NOT_USE_OR_YOU_WILL_BE_FIRED/controllers/api/SparkSearchTypes';
import { FilterTab } from '__DEPRECATED_DO_NOT_USE_OR_YOU_WILL_BE_FIRED/redux/filter/filter.slice';

import { GridItemProps } from '../../model/types';
import TemplateCard from './TemplateCard';

export default function TemplatesGridItem({
  actions,
  asset,
  ownersMap,
  selectionEnabled,
  tab,
  width,
}: GridItemProps): ReactElement {
  const templateAsset: TemplateAsset = asset as TemplateAsset;
  const rendition = getFixedWidthRendition(templateAsset, width);
  const onClick = (assetId: string) => {
    if (!selectionEnabled) {
      actions.openDetailView(assetId);
    }
  };

  return (
    <TemplateCard
      asset={templateAsset}
      onClick={onClick}
      ownersMap={ownersMap}
      showBadges
      showDate
      showDetails
      showRemixCount={tab === FilterTab.search}
      showStatus={tab === FilterTab.review}
      showTag={false}
      thumbnailHref={rendition.href}
      thumbnailSize={rendition.size}
    />
  );
}
