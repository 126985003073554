/**
 * *****************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2022 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Adobe and its suppliers, if any. The intellectual and technical concepts
 * contained herein are proprietary to Adobe and its suppliers and are
 * protected by all applicable intellectual property laws, including trade
 * secret and copyright laws. Dissemination of this information or reproduction
 * of this material is strictly forbidden unless prior written permission is
 * obtained from Adobe.
 * *****************************************************************************
 */

import React, { ReactElement, useCallback, useState } from 'react';

import { Flex, View } from '@adobe/react-spectrum';

import DetailActionRail from 'components/AssetExplorer/DetailActionRail';
import CommentsMetadataForm from 'components/AssetMetadataForm/CommentsMetadataForm';
import FileMetadataForm from 'components/AssetMetadataForm/FileMetadataForm';
import useApplicationMetadata from 'hooks/acp/useApplicationMetadata';
import useRepoMetadata from 'hooks/acp/useRepoMetadata';
import useAssetWorkflowActions from 'hooks/useAssetWorkflowActions';
import useCurrentAssetByPath from 'hooks/useCurrentAssetByPath';
import { RepoMetadataKey } from 'model/acp/RepoMetadataKey';
import { DocumentRailPane } from 'model/DocumentRailPane';
import { ValidationFieldErrors } from 'model/errors/ValidationError';
import { FileMetadataModel } from 'model/metadata/FileMetadataModel';
import { useAppSelector } from 'redux/hooks';
import { mapFromFileMetadata, validate } from 'utils/metadata/fileMetadata';

type FileRailAndMetadataProps = {
  isMetadataFormCollapsed: boolean;
  onMetadataFormToggle: () => void;
  onMetadataFormOpen: () => void;
};

export default function FileRailAndMetadata({
  isMetadataFormCollapsed,
  onMetadataFormToggle,
  onMetadataFormOpen,
}: FileRailAndMetadataProps): ReactElement {
  // Load external configurations and path information
  const { currentAssetByPath } = useCurrentAssetByPath();
  const { results: applicationMetadata } = useApplicationMetadata({
    path: currentAssetByPath,
  });
  const { results: repoMetadata } = useRepoMetadata({
    path: currentAssetByPath,
  });
  const { detailRailPane } = useAppSelector((state) => state.explorer);
  const assetWorkflowActions = useAssetWorkflowActions();
  const [hasStagedChanges, setHasStagedChanges] = useState(false);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const saveFileMetadata = useCallback(
    async (
      model: FileMetadataModel,
    ): Promise<ValidationFieldErrors | undefined> => {
      if (!repoMetadata) {
        return undefined;
      }

      setIsSaving(true);

      // Validate model fields
      const errors = validate(model);

      if (errors) {
        setIsSaving(false);

        return errors;
      }

      const updatedMetadata = mapFromFileMetadata(model, applicationMetadata);
      await assetWorkflowActions.updateMetadata(
        repoMetadata[RepoMetadataKey.Path],
        updatedMetadata,
      );

      setIsSaving(false);

      return undefined;
    },
    [assetWorkflowActions, applicationMetadata, repoMetadata, setIsSaving],
  );

  if (!repoMetadata) {
    return <></>;
  }

  return (
    <View
      marginStart={isMetadataFormCollapsed ? 'size-0' : 'size-200'}
      height="100%"
    >
      <Flex width="100%" gap="size-200" height="100%">
        <Flex
          width="100%"
          flex
          UNSAFE_style={{
            borderTopColor: 'informative',
            borderTopWidth: 'thicker',
          }}
        >
          {detailRailPane === DocumentRailPane.Comments &&
            !isMetadataFormCollapsed && (
              <CommentsMetadataForm
                isCollapsed={isMetadataFormCollapsed}
                onCollapse={onMetadataFormToggle}
              />
            )}
          {detailRailPane === DocumentRailPane.Details &&
            !isMetadataFormCollapsed && (
              <FileMetadataForm
                isCollapsed={isMetadataFormCollapsed}
                onCollapse={onMetadataFormToggle}
                isSaving={isSaving}
                onSave={saveFileMetadata}
                path={currentAssetByPath}
                onChangesStaged={(value) => setHasStagedChanges(value)}
              />
            )}
          <DetailActionRail
            path={currentAssetByPath}
            repoMetadata={repoMetadata}
            hasStagedChanges={hasStagedChanges}
            onOpenForm={onMetadataFormOpen}
            isPanelCollapsed={isMetadataFormCollapsed}
          />
        </Flex>
      </Flex>
    </View>
  );
}
