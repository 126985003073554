/**
 * *****************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2022 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Adobe and its suppliers, if any. The intellectual and technical concepts
 * contained herein are proprietary to Adobe and its suppliers and are
 * protected by all applicable intellectual property laws, including trade
 * secret and copyright laws. Dissemination of this information or reproduction
 * of this material is strictly forbidden unless prior written permission is
 * obtained from Adobe.
 * *****************************************************************************
 */

import { cloneDeep, isEmpty } from 'lodash';

import CP, {
  CPUserProfile,
} from '__DEPRECATED_DO_NOT_USE_OR_YOU_WILL_BE_FIRED/controllers/api/CommunityPlatform';
import SparkSearch from '__DEPRECATED_DO_NOT_USE_OR_YOU_WILL_BE_FIRED/controllers/api/SparkSearch';

import { AirtableOwnerRecord } from 'model/airtable/AirtableOwnerRecord';
import { addAlert, Alert } from 'redux/alert/alert.slice';
import { AppThunk } from 'redux/store';
import { fetchOwnerRecords } from 'services/airtable';

import { setOwnersMap, TempoOwnersMap } from './tempo.slice';

function ownersMapIsEmpty(ownersMap: TempoOwnersMap) {
  return isEmpty(ownersMap.designers) && isEmpty(ownersMap.others);
}

export function initializeOwners(): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    let sparkSearchOwnerIds: { key: string; docCount: number }[] = [];
    let airtableOwnersMap: AirtableOwnerRecord = {};
    let alert: Alert;
    const {
      tempo: { ownersMap: currentOwnersMap },
    } = getState();

    // populate ownersMap only if it is empty
    if (!ownersMapIsEmpty(currentOwnersMap)) {
      return;
    }

    const newOwnersMap = cloneDeep(currentOwnersMap);

    try {
      // fetch owner records from Airtable (maps the owner id to displayName)
      airtableOwnersMap = await fetchOwnerRecords();
    } catch (error) {
      alert = {
        type: 'information',
        id: 'Airtable-fetchOwnerRecords',
        title: 'Airtable: Unable to fetch owner records',
        content: `
                    You will not be able to filter by Designer in the Review tab.
                    ${(error as Error).message}
                `,
      };

      dispatch(addAlert(alert));
    }

    try {
      // fetch owner ids from search service
      sparkSearchOwnerIds = await SparkSearch.instance.fetchOwners();
    } catch (error) {
      alert = {
        type: 'error',
        id: 'SparkSearch-fetchOwners',
        title: 'Spark Search: Unable to fetch owner ids',
        content: `
                    You will not be able to filter by Designer in the Search tab.
                    ${(error as Error).message}
                `,
      };

      dispatch(addAlert(alert));
    }

    // add id:displayName entry into ownersMap
    Object.keys(airtableOwnersMap).forEach((ownerId) => {
      // display owner's id if we don't have the display name from Airtable
      const profile = {
        id: ownerId,
        displayName: airtableOwnersMap[ownerId] || ownerId,
      };

      newOwnersMap.designers[ownerId] = {
        key: profile.displayName,
        docCount: 0,
      };
    });

    // filter out the ids we've already added to ownersMap
    sparkSearchOwnerIds = sparkSearchOwnerIds.filter(
      (id) =>
        !Object.prototype.hasOwnProperty.call(newOwnersMap.designers, id.key),
    );

    // fetch cp profiles for the remaining owner ids so that we get a displayName
    await Promise.all(
      sparkSearchOwnerIds.map(async (owner) => {
        let cpProfile: CPUserProfile;

        try {
          cpProfile = await CP.fetchUserProfile(owner.key);

          // display owner's id if the display name from CP doesn't exist
          newOwnersMap.others[owner.key] = {
            key: cpProfile.displayName || owner.key,
            docCount: owner.docCount,
          };
        } catch (error) {
          // eslint-disable-next-line no-console
          console.error(
            `Unable to fetch CP User Profile for ID: ${owner.key}`,
            error,
          );

          newOwnersMap.others[owner.key] = owner;
        }
      }),
    );

    dispatch(setOwnersMap(newOwnersMap));
  };
}
