/**
 * *****************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2022 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Adobe and its suppliers, if any. The intellectual and technical concepts
 * contained herein are proprietary to Adobe and its suppliers and are
 * protected by all applicable intellectual property laws, including trade
 * secret and copyright laws. Dissemination of this information or reproduction
 * of this material is strictly forbidden unless prior written permission is
 * obtained from Adobe.
 * *****************************************************************************
 */

import React, { ReactElement, useCallback, useState } from 'react';

import { AlertDialog, DialogContainer } from '@adobe/react-spectrum';
import type { Transition } from 'history';

import { useBlocker } from './useBlocker';

type NavBlockPromptProps = {
  cancelLabel?: string;
  hasBlockingChanges: boolean;
  message: string;
  primaryActionLabel?: string;
  title: string;
};

const DEFAULT_PRIMARY_ACTION_LABEL = 'Confirm';
const DEFAULT_CANCEL_LABEL = 'Cancel';

export default function NavBlockPrompt({
  hasBlockingChanges,
  primaryActionLabel = DEFAULT_PRIMARY_ACTION_LABEL,
  cancelLabel = DEFAULT_CANCEL_LABEL,
  message,
  title,
}: NavBlockPromptProps): ReactElement {
  const [blockingTransition, setBlockingTransition] = useState<Transition>();
  const shouldAbandonChanges = useCallback(
    (transition: Transition) => setBlockingTransition(transition),
    [setBlockingTransition],
  );

  useBlocker(shouldAbandonChanges, hasBlockingChanges);

  const onDismiss = useCallback(
    () => setBlockingTransition(undefined),
    [setBlockingTransition],
  );
  const onConfirm = useCallback(
    () => blockingTransition?.retry(),
    [blockingTransition],
  );

  if (blockingTransition !== undefined) {
    return (
      <DialogContainer onDismiss={() => {}}>
        <AlertDialog
          onCancel={onDismiss}
          onPrimaryAction={onConfirm}
          primaryActionLabel={primaryActionLabel}
          cancelLabel={cancelLabel}
          variant="destructive"
          title={title}
        >
          {message}
        </AlertDialog>
      </DialogContainer>
    );
  }

  return <></>;
}

NavBlockPrompt.defaultProps = {
  primaryActionLabel: DEFAULT_PRIMARY_ACTION_LABEL,
  cancelLabel: DEFAULT_CANCEL_LABEL,
};
