/**
 * *****************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2022 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Adobe and its suppliers, if any. The intellectual and technical concepts
 * contained herein are proprietary to Adobe and its suppliers and are
 * protected by all applicable intellectual property laws, including trade
 * secret and copyright laws. Dissemination of this information or reproduction
 * of this material is strictly forbidden unless prior written permission is
 * obtained from Adobe.
 * *****************************************************************************
 */

import { useEffect, useMemo, useState } from 'react';
import { useQuery } from 'react-query';

import Axios from 'axios';

import useConstant from 'hooks/useConstant';

import useShadowAsset from './useShadowAsset';

type UseAssetCommentCountHookProps = {
  currentAssetId: string;
  options?: {
    enabled?: boolean;
    status?: 'open' | 'resolved' | 'open,resolved';
  };
};

type UseAssetCommentCountHookResult = {
  result: number | undefined;
  isLoading: boolean;
};

type CommentsResponse = {
  total: number;
};

export default function useAssetCommentCount({
  currentAssetId,
  options: { enabled = true, status = 'open,resolved' } = {},
}: UseAssetCommentCountHookProps): UseAssetCommentCountHookResult {
  const [result, setResult] = useState<UseAssetCommentCountHookResult>({
    result: undefined,
    isLoading: true,
  });
  const { commentsBaseUrl } = useConstant();
  const { result: shadowAssetId, isLoading: isLoadingShadowAsset } =
    useShadowAsset({
      currentAssetId,
      options: {
        enabled,
        shouldCreate: false,
      },
    });

  useEffect(() => {
    if (!isLoadingShadowAsset && !shadowAssetId) {
      setResult({
        result: 0,
        isLoading: false,
      });
    }
  }, [isLoadingShadowAsset, shadowAssetId]);

  // Attempt to fetch comment data
  const commentsFetchUrl = useMemo(
    () =>
      `${commentsBaseUrl}/assets/${shadowAssetId}/annots/?status=${status}&motivation=commenting`,
    [commentsBaseUrl, shadowAssetId, status],
  );
  const GetQuery = async (): Promise<CommentsResponse> => {
    const response = await Axios.get(commentsFetchUrl, {
      headers: {
        Accept:
          'application/ld+json; profile="https://www.w3.org/ns/anno.jsonld"',
        'x-ccx-workflow': true,
        'x-mentions': true,
      },
    });

    return response.data;
  };
  const { data } = useQuery<CommentsResponse>(
    ['comment-counts', currentAssetId, status],
    GetQuery,
    {
      retry: false,
      /**
       * Disable the ReactQuery query call if this function is run but the links
       * haven't loaded, because there will be no requestUrl and no reason to
       * make the request.
       */
      enabled: enabled && shadowAssetId !== undefined && !isLoadingShadowAsset,
    },
  );

  useEffect(() => {
    if (data) {
      setResult({
        result: data.total,
        isLoading: false,
      });
    }
  }, [data]);

  return result;
}
