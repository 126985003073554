/**
 * *****************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2022 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Adobe and its suppliers, if any. The intellectual and technical concepts
 * contained herein are proprietary to Adobe and its suppliers and are
 * protected by all applicable intellectual property laws, including trade
 * secret and copyright laws. Dissemination of this information or reproduction
 * of this material is strictly forbidden unless prior written permission is
 * obtained from Adobe.
 * *****************************************************************************
 */

import { IEnvironment } from '@identity/imslib/adobe-id/IEnvironment';

export enum SourceRepository {
  CCX,
  ProjectX,
}

export default class ACPRepositoryId {
  public readonly [SourceRepository.ProjectX] = {
    [IEnvironment.PROD]: 'C47F256E62718F560A495C48@AdobeOrg',
    [IEnvironment.STAGE]: '071B766F623106EF0A49400E@AdobeOrg',
  };
  public readonly [SourceRepository.CCX] = {
    [IEnvironment.PROD]: 'C89266675E5D603D0A495FBA@AdobeOrg',
    [IEnvironment.STAGE]: '5C1209215E61996C0A49402E@AdobeOrg',
  };
}
