/**
 * *****************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2022 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Adobe and its suppliers, if any. The intellectual and technical concepts
 * contained herein are proprietary to Adobe and its suppliers and are
 * protected by all applicable intellectual property laws, including trade
 * secret and copyright laws. Dissemination of this information or reproduction
 * of this material is strictly forbidden unless prior written permission is
 * obtained from Adobe.
 * *****************************************************************************
 */

import {
  setTemplateDetailRailState,
  TemplateRailActionState,
} from 'redux/__DEPRECATED_templates/templates.slice';
import { AppThunk } from 'redux/store';

import { ScreenView, setDetailViewId, setScreenView } from './screen.slice';

export function openDetailView(assetId: string): AppThunk {
  return (dispatch) => {
    dispatch(setDetailViewId(assetId));
    dispatch(
      setTemplateDetailRailState(TemplateRailActionState.TemplateMetadata),
    );
    dispatch(setScreenView(ScreenView.Detail));
  };
}
