/**
 * *****************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2022 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Adobe and its suppliers, if any. The intellectual and technical concepts
 * contained herein are proprietary to Adobe and its suppliers and are
 * protected by all applicable intellectual property laws, including trade
 * secret and copyright laws. Dissemination of this information or reproduction
 * of this material is strictly forbidden unless prior written permission is
 * obtained from Adobe.
 * *****************************************************************************
 */

import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';

import Axios from 'axios';

import { UserProfile } from 'model/acp/UserProfile';
import { useAppSelector } from 'redux/hooks';
import { selectACPRepoId } from 'redux/user/user.slice';

import useConstant from './useConstant';

export type OrgUserHookResult = {
  isLoading: boolean;
  result: UserProfile[] | undefined;
};

export default function useOrgUsers(): OrgUserHookResult {
  const [result, setResult] = useState<OrgUserHookResult>({
    isLoading: true,
    result: undefined,
  });
  const { jilBaseUrl, jilBasePaths } = useConstant();
  const acpRepoId = useAppSelector(selectACPRepoId);
  const requestUrl = `${jilBaseUrl}${jilBasePaths.organizations}/${acpRepoId}${jilBasePaths.users}?page_size=500`;
  const GETQuery = async (): Promise<UserProfile[]> => {
    const response = await Axios.get(requestUrl);

    return response.data;
  };
  const { data, isLoading } = useQuery<UserProfile[]>(
    ['orgUsers', acpRepoId],
    GETQuery,
  );

  useEffect((): void => {
    if (data) {
      setResult({
        isLoading: false,
        result: data.map((user) => ({
          ...user,
          normalizedEmail: user.email.toLowerCase(),
        })),
      });
    }
  }, [data, isLoading]);

  return result;
}
