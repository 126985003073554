/**
 * *****************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2022 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Adobe and its suppliers, if any. The intellectual and technical concepts
 * contained herein are proprietary to Adobe and its suppliers and are
 * protected by all applicable intellectual property laws, including trade
 * secret and copyright laws. Dissemination of this information or reproduction
 * of this material is strictly forbidden unless prior written permission is
 * obtained from Adobe.
 * *****************************************************************************
 */

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  SparkSearchAgg,
  SparkSearchAggs,
} from '__DEPRECATED_DO_NOT_USE_OR_YOU_WILL_BE_FIRED/controllers/api/SparkSearch';
import { FilterProp } from '__DEPRECATED_DO_NOT_USE_OR_YOU_WILL_BE_FIRED/controllers/SearchParamsBuilder/types';
import { FilterOption } from '__DEPRECATED_DO_NOT_USE_OR_YOU_WILL_BE_FIRED/redux/filter/filter.slice';
import {
  MetadataOption,
  MetadataOptions,
} from '__DEPRECATED_DO_NOT_USE_OR_YOU_WILL_BE_FIRED/redux/metadata/metadata.types';

import { AssetMimeType } from 'model/acp/AssetMimeType';
import { ApplicableRegion } from 'model/ApplicableRegion';
import { CCXConfigOption } from 'model/ccxConfig/CCXConfig';

function mapAggsToFilterGroupOptions(prop: FilterProp) {
  return (aggsData: SparkSearchAgg) => ({
    label: aggsData.label ?? aggsData.key,
    value: aggsData.key,
    prop,
    count: aggsData.count,
    isExactMatch: true,
  });
}

export interface FilterGroupOption extends FilterOption {
  prop: FilterProp;
  count?: number;
}

export interface FilterGroupInfo {
  heading: string;
  seeMoreHeading: string | null;
  multiSelect: boolean;
  defaultOptions: FilterGroupOption[];
}

export type FilterGroup =
  | 'premium'
  | 'categories'
  | 'tasks'
  | 'owner'
  | 'animated'
  | 'locales'
  | 'language'
  | 'topics'
  | 'licensingCategory'
  | 'directory'
  | 'contentType'
  | 'applicableRegions';

type FilterGroupOptionsState = {
  animated: FilterGroupOption[];
  categories: FilterGroupOption[];
  contentType: FilterGroupOption[];
  directory: FilterGroupOption[];
  language: FilterGroupOption[];
  licensingCategory: FilterGroupOption[];
  locales: FilterGroupOption[];
  owner: FilterGroupOption[];
  premium: FilterGroupOption[];
  tasks: FilterGroupOption[];
  topics: FilterGroupOption[];
  applicableRegions: FilterGroupOption[];
};

/**
 * Depending on the contentType (assets vs templates), we display differently since the Template schema uses locales
 * and the Design Assets schema uses language.
 */
function getLocaleOptions(prop: FilterProp): FilterGroupOption[] {
  const excludeDefaultLocale = (option: MetadataOption) =>
    option.label !== 'Default';
  /**
   * Templates do not use the 'i-default' locale.
   * We can use the same list of locales once Templates are moved to ACP.
   */
  let localeOptions;

  if (prop === 'locales') {
    localeOptions =
      MetadataOptions.LANGUAGES_FOR_SEARCH.filter(excludeDefaultLocale);
  } else {
    localeOptions = MetadataOptions.LANGUAGES_FOR_SEARCH;
  }

  const mapToFilterGroupOption = (
    option: MetadataOption,
  ): FilterGroupOption => ({
    label: option.label,
    value: option.value,
    prop,
    isExactMatch: true,
  });

  return localeOptions.map(mapToFilterGroupOption);
}

export const initialState: FilterGroupOptionsState = {
  animated: [
    {
      label: 'Animated',
      prop: 'animated',
      value: true,
    },
    {
      label: 'Still',
      prop: 'animated',
      value: false,
    },
  ],
  categories: [],
  contentType: [
    {
      label: 'Collection',
      prop: 'contentType',
      value: AssetMimeType.Collection,
      isExactMatch: true,
    },
    {
      label: 'Folder',
      prop: 'contentType',
      value: AssetMimeType.Directory,
      isExactMatch: true,
    },
    {
      label: 'Image',
      prop: 'contentType',
      value: AssetMimeType.Image,
      isExactMatch: true,
    },
  ],
  directory: [],
  language: getLocaleOptions('language').sort(),
  licensingCategory: [
    {
      label: 'Premium',
      prop: 'licensingCategory',
      value: 'premium',
    },
    {
      label: 'Free',
      prop: 'licensingCategory',
      value: 'free',
    },
  ],
  locales: getLocaleOptions('locales').sort(),
  owner: [],
  premium: [
    {
      label: 'Premium',
      prop: 'premium',
      value: true,
    },
    {
      label: 'Free',
      prop: 'premium',
      value: false,
    },
  ],
  tasks: [],
  topics: [],
  applicableRegions: [
    {
      label: 'Default',
      prop: 'applicableRegions',
      value: ApplicableRegion.Global,
    },
    {
      label: 'United Kingdom',
      prop: 'applicableRegions',
      value: ApplicableRegion.GB,
    },
  ],
};

export const filterGroupOptionsSlice = createSlice({
  name: 'filterGroupOptions',
  initialState,
  reducers: {
    reset: (state, action: PayloadAction<FilterGroupOptionsState>) => {
      state = action.payload;
    },
    updateCategoryOptions: (
      state,
      action: PayloadAction<FilterGroupOption[]>,
    ) => {
      state.categories = action.payload;
    },
    updateOptionsFromAggs: (state, action: PayloadAction<SparkSearchAggs>) => {
      Object.entries(action.payload).forEach(([aggTerm, aggsDataArray]) => {
        if (aggsDataArray) {
          const prop: FilterProp = aggTerm as FilterGroup;
          const options: FilterGroupOption[] = aggsDataArray.map(
            mapAggsToFilterGroupOptions(prop),
          );

          state[prop] = options;
        }
      });
    },
    updateOwnerOptions: (state, action: PayloadAction<FilterGroupOption[]>) => {
      state.owner = action.payload;
    },
    updateTaskOptions: (state, action: PayloadAction<CCXConfigOption[]>) => {
      state.tasks = action.payload.map((option) => ({
        ...option,
        isExactMatch: true,
        prop: 'tasks',
      }));
    },
    updateLocales: (state, action: PayloadAction<FilterGroupOption[]>) => {
      state.locales = action.payload;
    },
  },
});

export const {
  reset,
  updateCategoryOptions,
  updateOptionsFromAggs,
  updateOwnerOptions,
  updateTaskOptions,
  updateLocales,
} = filterGroupOptionsSlice.actions;

export default filterGroupOptionsSlice.reducer;
