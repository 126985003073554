/**
 * *****************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2022 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Adobe and its suppliers, if any. The intellectual and technical concepts
 * contained herein are proprietary to Adobe and its suppliers and are
 * protected by all applicable intellectual property laws, including trade
 * secret and copyright laws. Dissemination of this information or reproduction
 * of this material is strictly forbidden unless prior written permission is
 * obtained from Adobe.
 * *****************************************************************************
 */

import React, { ReactElement } from 'react';

import { ActionButton, Flex, Text, View } from '@adobe/react-spectrum';
import { PressEvent } from '@react-types/shared';

import styles from './RailElement.module.scss';

export enum RailElementVariant {
  Positive = 'positive',
  Default = 'default',
  Negative = 'negative',
}

const variantClassMap: Record<RailElementVariant, string> = {
  [RailElementVariant.Positive]: styles.positive,
  [RailElementVariant.Default]: '',
  [RailElementVariant.Negative]: styles.negative,
};

type Props = {
  action: (event: PressEvent) => void;
  variant?: RailElementVariant;
  icon: ReactElement;
  isActive?: boolean;
  isDisabled?: boolean;
  label: string;
};

export default function RailElement({
  action,
  icon,
  isActive,
  isDisabled,
  label,
  variant = RailElementVariant.Default,
}: Props): ReactElement {
  return (
    <View width="100%">
      <ActionButton
        UNSAFE_className={`${styles.navItem} ${
          isActive ? styles.activeItem : ''
        } ${variantClassMap[variant]}`}
        onPress={action}
        aria-current="location"
        isDisabled={isDisabled}
      >
        <Flex direction="column" alignItems="center">
          <View>{icon}</View>
          <Text UNSAFE_className={styles.navItemLabel}>{label}</Text>
        </Flex>
      </ActionButton>
    </View>
  );
}

RailElement.defaultProps = {
  isActive: false,
  isDisabled: false,
  variant: RailElementVariant.Default,
};
