/**
 * *****************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2022 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Adobe and its suppliers, if any. The intellectual and technical concepts
 * contained herein are proprietary to Adobe and its suppliers and are
 * protected by all applicable intellectual property laws, including trade
 * secret and copyright laws. Dissemination of this information or reproduction
 * of this material is strictly forbidden unless prior written permission is
 * obtained from Adobe.
 * *****************************************************************************
 */

import Axios from 'axios';

import { ACPLink } from 'model/acp/ACPLink';
import ApplicationMetadata from 'model/acp/ApplicationMetadata';
import { ApplicationMetadataNamespace } from 'model/acp/ApplicationMetadataNamespace';
import Url from 'model/constant/Url';
import { getACPLinkUrl } from 'utils/acp/acpLinks';
import parseLinkHeader from 'utils/parseLinkHeader';

export type ApplicationMetadataResponse = {
  [ApplicationMetadataNamespace]?: ApplicationMetadata;
};

type ApplicationMetadataResult = {
  result: ApplicationMetadata | undefined;
  linkUrl: string;
};

export async function getApplicationMetadataForAsset(
  assetPath: string,
  { acpRepoId, url }: { acpRepoId: string; url: Url },
): Promise<ApplicationMetadataResult> {
  const acpLinkUrl = getACPLinkUrl(assetPath, acpRepoId, url.platformBase);
  const data = await Axios.head(acpLinkUrl);
  const links = parseLinkHeader(data.headers.link);
  const linkUrl = links[ACPLink.ApplicationMetadata]?.url;

  if (linkUrl === undefined) {
    throw new Error('Unable to find ACP link.');
  }

  const applicationMetadataData = await Axios.get<ApplicationMetadataResponse>(
    linkUrl,
  );

  return {
    result: applicationMetadataData.data[ApplicationMetadataNamespace] as
      | ApplicationMetadata
      | undefined,
    linkUrl,
  };
}
