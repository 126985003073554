/**
 * *****************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2022 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Adobe and its suppliers, if any. The intellectual and technical concepts
 * contained herein are proprietary to Adobe and its suppliers and are
 * protected by all applicable intellectual property laws, including trade
 * secret and copyright laws. Dissemination of this information or reproduction
 * of this material is strictly forbidden unless prior written permission is
 * obtained from Adobe.
 * *****************************************************************************
 */

import React, { Fragment, ReactElement } from 'react';

import styles from './BaseCard.module.scss';

type CardMetadataProps = {
  metadata: (string | undefined)[][];
};

export default function BaseCardMetadata({
  metadata,
}: CardMetadataProps): ReactElement {
  return (
    <div className="spectrum-Card-footer">
      <div className={styles.metadata}>
        {metadata.map(([title, value]) => (
          <Fragment key={title}>
            <span className={styles.label}>{`${title}:`}</span>
            <span className={styles.value}>{value}</span>
          </Fragment>
        ))}
      </div>
    </div>
  );
}
