/**
 * *****************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2022 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Adobe and its suppliers, if any. The intellectual and technical concepts
 * contained herein are proprietary to Adobe and its suppliers and are
 * protected by all applicable intellectual property laws, including trade
 * secret and copyright laws. Dissemination of this information or reproduction
 * of this material is strictly forbidden unless prior written permission is
 * obtained from Adobe.
 * *****************************************************************************
 */

import React, { ReactElement, useCallback, useState } from 'react';

import {
  Divider,
  Flex,
  Heading,
  Item,
  Slider,
  TabList,
  TabPanels,
  Tabs,
  Text,
  View,
  Well,
} from '@adobe/react-spectrum';
import Code from '@spectrum-icons/workflow/Code';
import CollectionAdd from '@spectrum-icons/workflow/CollectionAdd';
import CollectionEdit from '@spectrum-icons/workflow/CollectionEdit';
import Image from '@spectrum-icons/workflow/Image';

import { DesignAsset } from '__DEPRECATED_DO_NOT_USE_OR_YOU_WILL_BE_FIRED/controllers/api/SparkSearchTypes';

import { CollectionQueryModel } from 'model/acp/CollectionQueryModel';
import { CollectionSource } from 'model/acp/CollectionSource';
import { ZoomLevel } from 'model/ZoomLevel';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { selectUserPreferences, setUserZoom } from 'redux/user/user.slice';

import CollectionAssetPreview from './CCXAssetCollectionPreview';
import CollectionAssetSearch from './CCXAssetCollectionSearch';
import CollectionFileLivePreview from './CollectionFileLivePreview';

import overrideStyles from 'styles/spectrum-overrides.module.scss';

type CCXAssetCollectionFormProps = {
  collectionQueryModel: CollectionQueryModel;
  initialMode: CCXAssetCollectionFormMode;
  addToCollection: (assetIds: Set<string>) => void;
  removeFromCollection: (assetIds: Set<string>) => void;
};

export enum CCXAssetCollectionFormMode {
  Add = 'add',
  Preview = 'preview',
  File = 'file',
}

const collectionAssetFormTabTitles: Record<CCXAssetCollectionFormMode, string> =
  {
    [CCXAssetCollectionFormMode.Add]: 'Add to Collection',
    [CCXAssetCollectionFormMode.Preview]: 'Collection Preview',
    [CCXAssetCollectionFormMode.File]: 'Collection File',
  };

export default function CCXAssetCollectionForm({
  collectionQueryModel,
  addToCollection,
  removeFromCollection,
  initialMode = CCXAssetCollectionFormMode.Preview,
}: CCXAssetCollectionFormProps): ReactElement {
  const assetIds =
    collectionQueryModel[CollectionSource.CCXAsset]?.assetIds ??
    new Set<string>();
  const [mode, setMode] = useState<CCXAssetCollectionFormMode>(initialMode);
  const [initialSearchPath, setInitialSearchPath] = useState<string>();
  const { zoom } = useAppSelector(selectUserPreferences);
  const dispatch = useAppDispatch();
  const setZoom = useCallback(
    (zoomLevel: ZoomLevel) => {
      dispatch(setUserZoom(zoomLevel));
    },
    [dispatch],
  );
  const jumpToSearch = useCallback(
    (asset: DesignAsset) => {
      setMode(CCXAssetCollectionFormMode.Add);
      setInitialSearchPath(asset.directory);
    },
    [setInitialSearchPath],
  );

  return (
    <Flex direction="column" flex marginBottom="size-200" minHeight="size-0">
      <Well
        flex
        marginTop="size-0"
        minHeight="size-0"
        position="relative"
        UNSAFE_style={{
          paddingBottom: 0,
          paddingLeft: 0,
          paddingRight: 0,
          paddingTop: 0,
        }}
      >
        <Tabs
          isQuiet
          onSelectionChange={(key) =>
            setMode(key as CCXAssetCollectionFormMode)
          }
          selectedKey={mode}
          height="100%"
        >
          <View borderBottomWidth="thick" borderBottomColor="gray-300">
            <Flex marginBottom="-2px" marginX="size-300" alignItems="center">
              <Heading flex marginY="size-0">
                {collectionAssetFormTabTitles[mode]}
              </Heading>
              <Flex alignItems="center" marginStart="size-300" gap="size-100">
                <Image size="XS" />
                <Slider
                  aria-label="Zoom"
                  onChange={setZoom}
                  value={zoom}
                  isFilled
                  minValue={1}
                  maxValue={5}
                  width="size-800"
                />
                <Image size="M" />
              </Flex>
              <Divider
                orientation="vertical"
                size="M"
                marginStart="size-150"
                marginEnd="size-300"
              />
              <TabList
                UNSAFE_className={overrideStyles.tabList}
                flexBasis="260px"
              >
                <Item key={CCXAssetCollectionFormMode.Preview}>
                  <CollectionEdit size="S" />
                  <Text>Preview</Text>
                </Item>
                <Item key={CCXAssetCollectionFormMode.Add}>
                  <CollectionAdd size="S" />
                  <Text>Add More Assets</Text>
                </Item>
                <Item key={CCXAssetCollectionFormMode.File}>
                  <Code size="S" />
                </Item>
              </TabList>
            </Flex>
          </View>
          <TabPanels
            minHeight="size-0"
            UNSAFE_style={{
              border: 'none',
            }}
          >
            <Item key={CCXAssetCollectionFormMode.Preview}>
              <CollectionAssetPreview
                collectionAssetIds={assetIds}
                jumpToSearch={jumpToSearch}
                removeFromCollection={removeFromCollection}
                zoomLevel={zoom}
              />
            </Item>
            <Item key={CCXAssetCollectionFormMode.Add}>
              <CollectionAssetSearch
                addToCollection={addToCollection}
                collectionAssetIds={assetIds}
                initialSearchPath={initialSearchPath}
                zoomLevel={zoom}
              />
            </Item>
            <Item key={CCXAssetCollectionFormMode.File}>
              <CollectionFileLivePreview
                collectionQueryModel={collectionQueryModel}
              />
            </Item>
          </TabPanels>
        </Tabs>
      </Well>
    </Flex>
  );
}
