/**
 * *****************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2022 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Adobe and its suppliers, if any. The intellectual and technical concepts
 * contained herein are proprietary to Adobe and its suppliers and are
 * protected by all applicable intellectual property laws, including trade
 * secret and copyright laws. Dissemination of this information or reproduction
 * of this material is strictly forbidden unless prior written permission is
 * obtained from Adobe.
 * *****************************************************************************
 */

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { SparkSearchAggs } from '__DEPRECATED_DO_NOT_USE_OR_YOU_WILL_BE_FIRED/controllers/api/SparkSearch';
import { TemplateAsset } from '__DEPRECATED_DO_NOT_USE_OR_YOU_WILL_BE_FIRED/controllers/api/SparkSearchTypes';

import { AssetsData, SortOptions, TAsset } from './asset.types';

export interface AssetsState {
  current: TAsset[];
  paths: string[];
  folder: null;
  count: number;
  total: number;
  selectedAssetIds: string[];
  doSelectAllAssets: boolean;
  aggs: SparkSearchAggs;
  sortOptions: SortOptions;
}

function getInitialState(): AssetsState {
  return {
    current: [],
    paths: [],
    folder: null,
    count: 0,
    total: 0,
    selectedAssetIds: [],
    doSelectAllAssets: false,
    aggs: {},
    sortOptions: {
      orderBy: 'repo:name',
    },
  };
}

const initialState: AssetsState = getInitialState();

export const assetsSlice = createSlice({
  name: 'assets',
  initialState,
  reducers: {
    addAssetsPath: (state, action: PayloadAction<string>) => {
      state.paths.push(action.payload);
    },
    doSelectAllAssets: (state) => {
      state.doSelectAllAssets = !state.doSelectAllAssets;
    },
    removeAssets: (state, action: PayloadAction<string[]>) => {
      state.current = state.current.filter(
        (asset: TAsset) => !action.payload.includes(asset.id),
      );
    },
    replaceAssets: (state, action: PayloadAction<TemplateAsset[]>) => {
      type AssetMap = Record<string, TAsset | undefined>;
      const updatedAssetMap = action.payload.reduce(
        (map: AssetMap, updatedAsset: TAsset) => {
          map[updatedAsset.id] = updatedAsset;

          return map;
        },
        {},
      );

      state.current = state.current.map(
        (asset: TAsset) => updatedAssetMap[asset.id] ?? asset,
      );
    },
    resetAssets: () => getInitialState(),
    setSelectedAssetIds: (state, action: PayloadAction<string[]>) => {
      state.selectedAssetIds = action.payload;
    },
    updateAggs: (state, action: PayloadAction<SparkSearchAggs>) => {
      state.aggs = action.payload;
    },
    updateAssetsData: (state, action: PayloadAction<AssetsData>) => ({
      ...state,
      ...action.payload,
    }),
    updateSortOptions: (state, action: PayloadAction<SortOptions>) => {
      state.sortOptions = action.payload;
    },
  },
});

export const {
  addAssetsPath,
  doSelectAllAssets,
  removeAssets,
  replaceAssets,
  resetAssets,
  setSelectedAssetIds,
  updateAggs,
  updateAssetsData,
  updateSortOptions,
} = assetsSlice.actions;

export default assetsSlice.reducer;
