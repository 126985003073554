/**
 * *****************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2022 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Adobe and its suppliers, if any. The intellectual and technical concepts
 * contained herein are proprietary to Adobe and its suppliers and are
 * protected by all applicable intellectual property laws, including trade
 * secret and copyright laws. Dissemination of this information or reproduction
 * of this material is strictly forbidden unless prior written permission is
 * obtained from Adobe.
 * *****************************************************************************
 */

import React, { ReactElement } from 'react';

import { Flex, Heading, Link, Text, View } from '@adobe/react-spectrum';
import LinkOut from '@spectrum-icons/workflow/LinkOut';

import StatusLabel from 'components/StatusLabel';
import useRepoMetadata from 'hooks/acp/useRepoMetadata';
import useConstant from 'hooks/useConstant';
import useCurrentAssetByPath from 'hooks/useCurrentAssetByPath';
import { RepoMetadataKey } from 'model/acp/RepoMetadataKey';
import { isDirectory } from 'utils/assets';

import styles from './DetailView.module.scss';

export default function AssetInfo(): ReactElement {
  const { acpBasePath, url, orgName } = useConstant();
  const { currentAssetByPath } = useCurrentAssetByPath();
  const { results: repoMetadata } = useRepoMetadata({
    path: currentAssetByPath,
  });
  const assetPath = repoMetadata?.[RepoMetadataKey.Path];
  const ddamLink = assetPath
    ? `${url.ddamBase}/#/${orgName}/assets/detail${assetPath}`
    : '';
  const width = repoMetadata?.[RepoMetadataKey.ImageWidth];
  const height = repoMetadata?.[RepoMetadataKey.ImageLength];

  return (
    <Flex direction="column">
      <View paddingTop="size-25">
        <Heading
          height="size-100"
          UNSAFE_style={{
            fontWeight: 900,
            marginTop: 0,
            lineHeight: 0,
          }}
          level={2}
        >
          <Flex alignContent="center" justifyContent="start" gap="size-100">
            <>
              {currentAssetByPath.startsWith(acpBasePath.submit) &&
              repoMetadata &&
              isDirectory(repoMetadata) ? null : (
                <StatusLabel path={repoMetadata?.[RepoMetadataKey.Path]} />
              )}
              <Text
                marginTop="size-25"
                flex
                UNSAFE_className={styles.assetInfoTitle}
              >
                {repoMetadata?.[RepoMetadataKey.Name]}
              </Text>
            </>
          </Flex>
        </Heading>
      </View>

      <Flex>
        <p className="spectrum-Detail spectrum-Detail--sizeM">
          {repoMetadata?.['dc:format']}

          {width || height ? <span style={{ margin: '5px' }}>•</span> : null}

          {width ? (
            <>
              <strong>w: </strong>
              <Text
                UNSAFE_style={{
                  textTransform: 'lowercase',
                }}
              >
                {width}
                px
              </Text>
            </>
          ) : null}

          {width && height ? (
            <>
              {' '}
              <strong>\u00d7</strong>{' '}
            </>
          ) : null}

          {height ? (
            <>
              <strong>h: </strong>
              <Text
                UNSAFE_style={{
                  textTransform: 'lowercase',
                }}
              >
                {height}
                px
              </Text>
            </>
          ) : null}
        </p>

        <span style={{ margin: '5px' }}>•</span>

        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
        <Link variant="secondary">
          <a href={ddamLink} rel="noopener noreferrer" target="_blank">
            <Flex gap="size-50">
              <View>
                <p className="spectrum-Detail spectrum-Detail--sizeM">DDAM</p>
              </View>
              <View paddingTop="size-100">
                <LinkOut size="XS" />
              </View>
            </Flex>
          </a>
        </Link>
      </Flex>
    </Flex>
  );
}
