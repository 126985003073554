/**
 * *****************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2022 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Adobe and its suppliers, if any. The intellectual and technical concepts
 * contained herein are proprietary to Adobe and its suppliers and are
 * protected by all applicable intellectual property laws, including trade
 * secret and copyright laws. Dissemination of this information or reproduction
 * of this material is strictly forbidden unless prior written permission is
 * obtained from Adobe.
 * *****************************************************************************
 */

export enum ApplicationMetadataKey {
  Album = 'album',
  Animated = 'animated',
  ApplicableFilters = 'applicableFilters',
  ApplicableRegions = 'applicableRegions',
  Attribution = 'attribution',
  Audio = 'audio',
  BPM = 'bpm',
  Creators = 'creators',
  DCSubject = 'dc:subject',
  DCTitle = 'dc:title',
  ExtractedColors = 'extractedColors',
  Genres = 'genres',
  Hero = 'hero',
  IsInstrumental = 'isInstrumental',
  Language = 'language',
  LicensingCategory = 'licensingCategory',
  Name = 'name',
  Priority = 'priority',
  PublishedAssetId = 'publishedAssetId',
  PublishedHash = 'publishedMetadataHash',
  PublishedVersion = 'publishedVersion',
  Status = 'status',
  SubmittedBy = 'submittedBy',
  Tasks = 'tasks',
  Traits = 'traits',
  Vendor = 'vendor',
}

export type ApplicationMetadataFlatKey = Exclude<
  ApplicationMetadataKey,
  | ApplicationMetadataKey.Album
  | ApplicationMetadataKey.BPM
  | ApplicationMetadataKey.Creators
  | ApplicationMetadataKey.Genres
  | ApplicationMetadataKey.IsInstrumental
  | ApplicationMetadataKey.SubmittedBy
  | ApplicationMetadataKey.Vendor
>;
