/**
 * *****************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2022 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Adobe and its suppliers, if any. The intellectual and technical concepts
 * contained herein are proprietary to Adobe and its suppliers and are
 * protected by all applicable intellectual property laws, including trade
 * secret and copyright laws. Dissemination of this information or reproduction
 * of this material is strictly forbidden unless prior written permission is
 * obtained from Adobe.
 * *****************************************************************************
 */

import { getAssetStatus } from 'hooks/useAssetStatus';
import ApplicationMetadata from 'model/acp/ApplicationMetadata';
import { ApplicationMetadataKey } from 'model/acp/ApplicationMetadataKey';
import { AssetStatus } from 'model/acp/AssetStatus';
import DCTitle from 'model/acp/DCTitle';
import Directory from 'model/acp/Directory';
import { Entitlement } from 'model/acp/Entitlement';
import LanguageAlternative from 'model/acp/LanguageAlternative';
import RepoMetadata from 'model/acp/RepoMetadata';
import { RepoMetadataKey } from 'model/acp/RepoMetadataKey';
import { AssetAction } from 'model/app/AssetAction';
import Constant from 'model/constant/Constant';
import { TranslatedLanguages } from 'model/Language';

import { isCollection, isDirectory, isFile } from './assets';

type VerifyActionConfig = {
  repoMetadata: RepoMetadata;
  status?: AssetStatus;
  applicationMetadata: ApplicationMetadata;
  parentDirectory?: Directory;
};

function verifyValidTitle(titleObject: DCTitle): boolean {
  const constant = new Constant();

  if (titleObject[constant.defaultLanguage]) {
    return true;
  }

  for (const key in titleObject) {
    if (key) {
      let titleItem = { '@language': '', '@value': '' };

      (titleItem as string | LanguageAlternative) = titleObject[key];

      if (titleItem['@language'] && !titleItem['@value']) {
        return false;
      }
    }
  }

  return true;
}

function verifyCanPublish(
  repoMetadata: RepoMetadata | undefined,
  status: AssetStatus,
  applicationMetadata: ApplicationMetadata | undefined,
) {
  const errors = [];

  if (repoMetadata === undefined) {
    errors.push('Could not get repo metadata');

    return errors;
  }

  if (status === AssetStatus.Published) {
    errors.push(`"${repoMetadata[RepoMetadataKey.Name]}" is already published`);
  }

  if (
    // eslint-disable-next-line no-control-regex
    /^((?:CON|PRN|AUX|NUL|COM[1-9]|LPT[1-9])(?:\.[^.]*)?$)|[+#\\:*?"/|\x00-\x1F]+|[ .]$/.test(
      repoMetadata[RepoMetadataKey.Name],
    )
  ) {
    errors.push('File names cannot contain restricted characters.');
  }

  if (isDirectory(repoMetadata)) {
    const title = applicationMetadata?.[ApplicationMetadataKey.DCTitle];

    if (!title || !verifyValidTitle(title)) {
      errors.push('Folders must have a title.');
    }
  }

  if (isCollection(repoMetadata)) {
    const title = applicationMetadata?.[ApplicationMetadataKey.DCTitle];
    const language = applicationMetadata?.[ApplicationMetadataKey.Language];

    if (title === undefined || !verifyValidTitle(title)) {
      errors.push('Collections must have a title');
    }

    if (Object.values(TranslatedLanguages).some((lang) => title?.[lang])) {
      errors.push(
        `Collections cannot have empty translations: ${Object.values(
          TranslatedLanguages,
        ).filter((lang) => title?.[lang])}`,
      );
    }

    if (!language || !language.length) {
      errors.push('Collections must have a language.');
    }
  }

  if (isFile(repoMetadata)) {
    const title = applicationMetadata?.[ApplicationMetadataKey.DCTitle];

    if (title !== undefined && !verifyValidTitle(title)) {
      errors.push('Files must have a title.');
    }

    if (
      !applicationMetadata?.[ApplicationMetadataKey.DCSubject] ||
      !applicationMetadata[ApplicationMetadataKey.DCSubject]?.length
    ) {
      errors.push('Files must have at least one topic');
    }

    const attribution =
      applicationMetadata?.[ApplicationMetadataKey.Attribution];

    if (attribution?.[ApplicationMetadataKey.Creators] !== undefined) {
      if (
        attribution[ApplicationMetadataKey.Creators]?.some(
          (creator) => typeof creator === 'string',
        )
      ) {
        errors.push(
          'File has an incorrect creator schema. This will be fixed on publish.',
        );
      }
    }
  }

  return errors;
}

function verifyCanUnpublish(
  repoMetadata: RepoMetadata | undefined,
  status: AssetStatus,
) {
  const errors = [];

  if (repoMetadata === undefined) {
    errors.push('Could not get repo metadata');

    return errors;
  }

  if (status !== AssetStatus.Published && status !== AssetStatus.Dirty) {
    errors.push(`"${repoMetadata[RepoMetadataKey.Name]}" is not published`);
  }

  return errors;
}

function verifyCanDelete(
  repoMetadata: RepoMetadata | undefined,
  status: AssetStatus,
  directoryMetadata?: Directory,
) {
  const errors = verifyCanUnpublish(repoMetadata, status);

  if (directoryMetadata && directoryMetadata.children.length > 0) {
    errors.push('Deleting folders with children is not yet supported.');
  }

  return errors;
}

export function verifyCanPerformAction(
  action: AssetAction,
  {
    repoMetadata,
    parentDirectory,
    status,
    applicationMetadata,
  }: VerifyActionConfig,
): string[] {
  const assetStatus =
    status ?? getAssetStatus(applicationMetadata, repoMetadata);

  switch (action) {
    case AssetAction.Publish:
      return verifyCanPublish(repoMetadata, assetStatus, applicationMetadata);

    case AssetAction.Unpublish:
      return verifyCanUnpublish(repoMetadata, assetStatus);

    case AssetAction.Delete:
      return verifyCanDelete(repoMetadata, assetStatus, parentDirectory);

    default:
      return [];
  }
}

type VerifyHasMigratedMetadataResult = {
  hasMigrations: boolean;
  result: ApplicationMetadata;
};

export function verifyHasMigratedMetadata(
  repoMetadata: RepoMetadata,
  applicationMetadata: ApplicationMetadata,
): VerifyHasMigratedMetadataResult {
  let hasMigrations = false;
  const updatedApplicationMetadata = {
    ...applicationMetadata,
  };

  if (isFile(repoMetadata)) {
    if (applicationMetadata[ApplicationMetadataKey.Animated] === undefined) {
      updatedApplicationMetadata[ApplicationMetadataKey.Animated] = false;
      hasMigrations = true;
    }

    if (
      applicationMetadata[ApplicationMetadataKey.LicensingCategory] ===
      undefined
    ) {
      updatedApplicationMetadata[ApplicationMetadataKey.LicensingCategory] =
        Entitlement.Free;
      hasMigrations = true;
    }
  }

  return {
    hasMigrations,
    result: updatedApplicationMetadata,
  };
}
