/**
 * *****************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2022 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Adobe and its suppliers, if any. The intellectual and technical concepts
 * contained herein are proprietary to Adobe and its suppliers and are
 * protected by all applicable intellectual property laws, including trade
 * secret and copyright laws. Dissemination of this information or reproduction
 * of this material is strictly forbidden unless prior written permission is
 * obtained from Adobe.
 * *****************************************************************************
 */

import React, { ReactElement, useCallback } from 'react';

import { Heading, View } from '@adobe/react-spectrum';

import Pane from 'components/@spectrum/Pane';
import AssetInfo from 'components/DetailView/AssetInfo';
import Loading from 'components/Loading';
import LocalizedField from 'components/LocalizedField';
import useAssetMetadataModel from 'hooks/useAssetMetadataModel';
import { useCCXPrivateConfig, useCCXPublicConfig } from 'hooks/useCCXConfig';
import { CollectionQueryModel } from 'model/acp/CollectionQueryModel';
import { ValidationFieldErrors } from 'model/errors/ValidationError';
import { AssetMetadataModelKey } from 'model/metadata/AssetMetadataModel';
import { CollectionMetadataModel } from 'model/metadata/CollectionMetadataModel';
import { verifyCollectionQueryModel } from 'utils/validation/collections';

import DefaultMetadataFormLayout from './DefaultMetadataFormLayout';
import ApplicableRegionFormField from './fields/ApplicableRegionFormField';
import CollectionNameFormField from './fields/CollectionNameFormField';
import FormLabel from './fields/FormLabel';
import GenericMultipickerFormField from './fields/GenericMultiPickerFormField';
import LanguagesFormField from './fields/LanguagesFormField';
import OptionsFormGroup from './fields/OptionsFormGroup';
import PriorityFormField from './fields/PriorityFormField';

type CollectionMetadataFormProps = {
  editedQueryModel: CollectionQueryModel | undefined;
  hasExternalChanges?: boolean;
  isCollapsed?: boolean;
  isSaving: boolean;
  onCollapse?: () => void;
  onReset?: () => void;
  onSave: (
    model: CollectionMetadataModel,
  ) => Promise<ValidationFieldErrors | undefined>;
  onChangesStaged: (value: boolean) => void;
  path?: string;
};

export default function CollectionMetadataForm({
  editedQueryModel,
  hasExternalChanges = false,
  isCollapsed = false,
  isSaving,
  onCollapse,
  onReset,
  onSave,
  onChangesStaged,
  path,
}: CollectionMetadataFormProps): ReactElement {
  const warnings = verifyCollectionQueryModel(editedQueryModel);
  const { results: ccxPublicConfig } = useCCXPublicConfig();
  const { results: ccxPrivateConfig } = useCCXPrivateConfig();
  const {
    hasChanges,
    isLoading,
    model,
    reset,
    setModelField,
    setValidationErrors,
    validationErrors,
  } = useAssetMetadataModel({ path, type: 'collection' });
  const handleReset = useCallback(async () => {
    reset();
    onChangesStaged(false);

    if (onReset) {
      onReset();
    }
  }, [onReset, reset, onChangesStaged]);
  const handleCollapse = useCallback(() => {
    if (onCollapse) {
      onCollapse();
    }
  }, [onCollapse]);
  const handleSave = useCallback(async () => {
    if (!model) {
      return;
    }

    setValidationErrors();

    const errors = await onSave(model);

    if (errors) {
      setValidationErrors(errors);
    }

    onChangesStaged(false);
  }, [model, onSave, setValidationErrors, onChangesStaged]);

  if (isLoading || !model) {
    return <Loading />;
  }

  const handleChange =
    (...keys: AssetMetadataModelKey[]) =>
    (value: any) => {
      setModelField(...keys)(value);
      onChangesStaged(true);
    };

  return (
    <Pane isSticky isOpen={!isCollapsed}>
      <View padding="10px">
        <AssetInfo />
      </View>
      <DefaultMetadataFormLayout
        hasChanges={hasChanges || hasExternalChanges}
        isCollapsed={isCollapsed}
        isSaving={isSaving}
        onCollapse={handleCollapse}
        onReset={handleReset}
        onSave={handleSave}
        path={path}
        customFixedFields={
          editedQueryModel?.source
            ? [['Collection Source', editedQueryModel.source]]
            : undefined
        }
        warnings={warnings}
      >
        <CollectionNameFormField
          isDisabled={isSaving || path !== undefined}
          isReadOnly={isSaving || path !== undefined}
          error={validationErrors[AssetMetadataModelKey.Name]}
          onChange={handleChange(AssetMetadataModelKey.Name)}
          value={
            path
              ? model[AssetMetadataModelKey.Name].replace('.collection', '')
              : model[AssetMetadataModelKey.Name]
          }
        />

        <LocalizedField
          label="Default Title"
          onChange={handleChange(AssetMetadataModelKey.Title)}
          value={model[AssetMetadataModelKey.Title]}
        />

        <OptionsFormGroup title="Flags" isWrapping>
          <PriorityFormField
            isDisabled={isSaving}
            isReadOnly={isSaving}
            error={validationErrors[AssetMetadataModelKey.Priority]}
            onChange={handleChange(AssetMetadataModelKey.Priority)}
            value={model[AssetMetadataModelKey.Priority]}
          />
        </OptionsFormGroup>

        <ApplicableRegionFormField
          isDisabled={isSaving}
          isReadOnly={isSaving}
          error={validationErrors[AssetMetadataModelKey.ApplicableRegions]}
          options={ccxPublicConfig?.common.applicableRegions.options}
          onChange={handleChange(AssetMetadataModelKey.ApplicableRegions)}
          value={model[AssetMetadataModelKey.ApplicableRegions]}
        />

        <LanguagesFormField
          isDisabled={isSaving}
          isReadOnly={isSaving}
          error={validationErrors[AssetMetadataModelKey.Language]}
          options={ccxPublicConfig?.common.language.options}
          onChange={handleChange(AssetMetadataModelKey.Language)}
          value={model[AssetMetadataModelKey.Language]}
        />

        <FormLabel>Applicable Filters</FormLabel>

        <GenericMultipickerFormField
          label={
            <Heading marginTop="size-100" marginBottom="size-50" level={5}>
              Entitlements
            </Heading>
          }
          isDisabled={isSaving}
          isReadOnly={isSaving}
          error={validationErrors[AssetMetadataModelKey.ApplicableFilters]}
          options={
            ccxPrivateConfig?.common.applicableFilters.entitlements.options
          }
          onChange={handleChange(
            AssetMetadataModelKey.ApplicableFilters,
            AssetMetadataModelKey.Entitlements,
          )}
          textLabel="Entitlements"
          value={
            model[AssetMetadataModelKey.ApplicableFilters][
              AssetMetadataModelKey.Entitlements
            ]
          }
        />

        <GenericMultipickerFormField
          label={
            <Heading marginTop="size-100" marginBottom="size-50" level={5}>
              Interest Groups
            </Heading>
          }
          isDisabled={isSaving}
          isReadOnly={isSaving}
          error={validationErrors[AssetMetadataModelKey.ApplicableFilters]}
          options={
            ccxPrivateConfig?.common.applicableFilters.interestGroups.options
          }
          onChange={handleChange(
            AssetMetadataModelKey.ApplicableFilters,
            AssetMetadataModelKey.InterestGroups,
          )}
          textLabel="Interest Groups"
          value={
            model[AssetMetadataModelKey.ApplicableFilters][
              AssetMetadataModelKey.InterestGroups
            ]
          }
        />

        <GenericMultipickerFormField
          label={
            <Heading marginTop="size-100" marginBottom="size-50" level={5}>
              Restrictions
            </Heading>
          }
          isDisabled={isSaving}
          isReadOnly={isSaving}
          error={validationErrors[AssetMetadataModelKey.ApplicableFilters]}
          options={
            ccxPrivateConfig?.common.applicableFilters.restrictions.options
          }
          onChange={handleChange(
            AssetMetadataModelKey.ApplicableFilters,
            AssetMetadataModelKey.Restrictions,
          )}
          textLabel="Restrictions"
          value={
            model[AssetMetadataModelKey.ApplicableFilters][
              AssetMetadataModelKey.Restrictions
            ]
          }
        />
      </DefaultMetadataFormLayout>
    </Pane>
  );
}

CollectionMetadataForm.defaultProps = {
  hasExternalChanges: false,
  isCollapsed: false,
  onCollapse: undefined,
  onReset: undefined,
  path: undefined,
};
