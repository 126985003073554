/**
 * *****************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2022 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Adobe and its suppliers, if any. The intellectual and technical concepts
 * contained herein are proprietary to Adobe and its suppliers and are
 * protected by all applicable intellectual property laws, including trade
 * secret and copyright laws. Dissemination of this information or reproduction
 * of this material is strictly forbidden unless prior written permission is
 * obtained from Adobe.
 * *****************************************************************************
 */

import React, { useCallback } from 'react';

import { TextField, View } from '@adobe/react-spectrum';

import FormLabel from 'components/AssetMetadataForm/fields/FormLabel';
import LocalizedField from 'components/LocalizedField';
import ScrollPane from 'components/ScrollPane';
import { StockCollectionQuery } from 'model/acp/CollectionQueryModel';
import { Language } from 'model/Language';

type StockCollectionSearchProps = {
  collectionQuery: StockCollectionQuery;
  onChange: (query: StockCollectionQuery) => void;
};

export default function StockCollectionSearch({
  onChange,
  collectionQuery = {},
}: StockCollectionSearchProps) {
  const externalCollectionId = collectionQuery.externalCollectionId ?? '';
  const query = collectionQuery.query ?? new Map<Language, string>();
  const updateValue = useCallback(
    (prop: string) => (value: any) =>
      onChange({
        ...collectionQuery,
        [prop]: value,
      }),
    [onChange, collectionQuery],
  );

  return (
    <ScrollPane>
      <View padding="size-250">
        <View marginBottom="size-250">
          <TextField
            label={<FormLabel>External Collection ID</FormLabel>}
            onChange={updateValue('externalCollectionId')}
            value={externalCollectionId}
            width="100%"
          />
        </View>
        <View marginBottom="size-100">
          <LocalizedField
            label="Query"
            onChange={updateValue('query')}
            value={query}
          />
        </View>
      </View>
    </ScrollPane>
  );
}
