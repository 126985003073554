/**
 * *****************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2022 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Adobe and its suppliers, if any. The intellectual and technical concepts
 * contained herein are proprietary to Adobe and its suppliers and are
 * protected by all applicable intellectual property laws, including trade
 * secret and copyright laws. Dissemination of this information or reproduction
 * of this material is strictly forbidden unless prior written permission is
 * obtained from Adobe.
 * *****************************************************************************
 */

import React, { ReactElement } from 'react';

import { Flex, ProgressCircle, Text, View } from '@adobe/react-spectrum';

import Directory from 'model/acp/Directory';
import { isCollection, isDirectory, isFile } from 'utils/assets';

function pluralize(count: number, singular: string, plural: string) {
  if (count === 1) {
    return `${count} ${singular}`;
  }

  return `${count} ${plural}`;
}

type TotalAssetsProps = {
  directory: Directory | undefined;
  hasNextPage: boolean | undefined;
};

export default function TotalAssets({
  directory,
  hasNextPage,
}: TotalAssetsProps): ReactElement {
  const directoryCount = directory?.children.filter(isDirectory).length ?? 0;
  const fileCount = directory?.children.filter(isFile).length ?? 0;
  const collectionCount = directory?.children.filter(isCollection).length ?? 0;
  const hasAssets = directory ? directory.children.length !== 0 : false;
  const hasOnlyDirectories =
    hasAssets &&
    directoryCount !== 0 &&
    fileCount === 0 &&
    collectionCount === 0;
  const hasOnlyFiles =
    hasAssets &&
    directoryCount === 0 &&
    fileCount !== 0 &&
    collectionCount === 0;
  const hasOnlyCollections =
    hasAssets &&
    directoryCount === 0 &&
    fileCount === 0 &&
    collectionCount !== 0;
  let assetCountText: ReactElement | null = null;

  if (hasOnlyCollections) {
    assetCountText = (
      <Text>{pluralize(collectionCount, 'collection', 'collections')}</Text>
    );
  } else if (hasOnlyDirectories) {
    assetCountText = (
      <Text>{pluralize(directoryCount, 'folder', 'folders')}</Text>
    );
  } else if (hasOnlyFiles) {
    assetCountText = <Text>{pluralize(fileCount, 'file', 'files')}</Text>;
  } else if (hasAssets) {
    let groupedText = `${pluralize(
      directory?.children.length ?? 0,
      'asset',
      'assets',
    )} (`;

    if (directoryCount) {
      groupedText += `${pluralize(directoryCount, 'folder', 'folders')}`;
    }

    if (directoryCount && collectionCount) {
      groupedText += ', ';
    }

    if (collectionCount) {
      groupedText += `${pluralize(
        collectionCount,
        'collection',
        'collections',
      )}`;
    }

    if ((directoryCount || collectionCount) && fileCount) {
      groupedText += ', ';
    }

    if (fileCount) {
      groupedText += `${pluralize(fileCount, 'file', 'files')}`;
    }

    groupedText += ')';
    assetCountText = <Text>{groupedText}</Text>;
  }

  return (
    <View marginStart="size-100">
      <Flex gap="size-100" alignItems="center">
        <Text>{assetCountText}</Text>
        <View paddingTop="size-75">
          <ProgressCircle
            aria-label="Loading"
            size="S"
            isHidden={!hasNextPage}
            isIndeterminate
          />
        </View>
      </Flex>
    </View>
  );
}
