/**
 * *****************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2022 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Adobe and its suppliers, if any. The intellectual and technical concepts
 * contained herein are proprietary to Adobe and its suppliers and are
 * protected by all applicable intellectual property laws, including trade
 * secret and copyright laws. Dissemination of this information or reproduction
 * of this material is strictly forbidden unless prior written permission is
 * obtained from Adobe.
 * *****************************************************************************
 */

import { ChildAsset } from 'model/acp/ChildAsset';
import { RepoMetadataKey } from 'model/acp/RepoMetadataKey';
import ACPBasePath from 'model/constant/ACPBasePath';

export function getParentPath(assetPath: string, { shouldKeepBase = false }) {
  const basePath = new ACPBasePath().assets;

  if (shouldKeepBase) {
    return assetPath.split('/').slice(0, -1).join('/');
  }

  return assetPath.split('/').slice(0, -1).join('/').replace(basePath, '');
}

export function isDirectory(asset: ChildAsset) {
  return asset[RepoMetadataKey.AssetClass] === 'directory';
}

export function isFile(asset: ChildAsset) {
  return (
    asset[RepoMetadataKey.AssetClass] !== 'directory' &&
    !asset[RepoMetadataKey.Name].endsWith('.collection')
  );
}

export function isCollection(asset: ChildAsset) {
  return (
    asset[RepoMetadataKey.AssetClass] !== 'directory' &&
    asset[RepoMetadataKey.Name].endsWith('.collection')
  );
}
