/**
 * *****************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2022 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Adobe and its suppliers, if any. The intellectual and technical concepts
 * contained herein are proprietary to Adobe and its suppliers and are
 * protected by all applicable intellectual property laws, including trade
 * secret and copyright laws. Dissemination of this information or reproduction
 * of this material is strictly forbidden unless prior written permission is
 * obtained from Adobe.
 * *****************************************************************************
 */

import React, {
  Key,
  ReactElement,
  useCallback,
  useEffect,
  useState,
} from 'react';

import {
  ActionGroup,
  Breadcrumbs,
  Button,
  ButtonGroup,
  Content,
  Dialog,
  DialogContainer,
  Divider,
  Flex,
  Heading,
  IllustratedMessage,
  Item,
  View,
} from '@adobe/react-spectrum';
import ChevronRight from '@spectrum-icons/workflow/ChevronRight';
import Folder from '@spectrum-icons/workflow/Folder';
import FolderOpen from '@spectrum-icons/workflow/FolderOpen';

import Loading from 'components/Loading';
import ScrollPane from 'components/ScrollPane';
import useConstant from 'hooks/useConstant';
import useDirectory from 'hooks/useDirectory';
import { AssetMimeType } from 'model/acp/AssetMimeType';
import { ChildAsset } from 'model/acp/ChildAsset';
import { RepoMetadataKey } from 'model/acp/RepoMetadataKey';

import styles from './FolderDialog.module.scss';

const START_INDEX = 0;

type FolderViewProps = {
  assets: ChildAsset[];
  goToDirectory: (newDirectory: Key) => void;
};

function FolderView({ assets, goToDirectory }: FolderViewProps): ReactElement {
  if (assets.length === START_INDEX) {
    return (
      <Flex height="284px" justifyContent="center" alignItems="center">
        <View padding="size-300">
          <IllustratedMessage>
            <FolderOpen size="XXL" />
            <Heading>This folder is empty</Heading>
            <Content>You can select it as a destination for your files</Content>
          </IllustratedMessage>
        </View>
      </Flex>
    );
  }

  return (
    <ActionGroup
      density="compact"
      onAction={goToDirectory}
      isQuiet
      orientation="vertical"
      items={assets}
      UNSAFE_className={styles.folderView}
    >
      {(childAsset: ChildAsset): ReactElement => (
        <Item key={childAsset[RepoMetadataKey.Path]}>
          <View
            UNSAFE_className={styles.folderButton}
            borderBottomColor="gray-300"
            borderBottomWidth="thin"
            height="100%"
            paddingStart="size-150"
            paddingEnd="size-100"
            paddingY="size-150"
            width="100%"
          >
            <Flex alignItems="center" height="100%" justifyContent="start">
              <Folder size="S" />
              <View
                UNSAFE_className={styles.folderLabel}
                paddingStart="size-200"
                flex
              >
                {childAsset[RepoMetadataKey.Name]}
              </View>
              <ChevronRight UNSAFE_className={styles.folderChevron} size="S" />
            </Flex>
          </View>
        </Item>
      )}
    </ActionGroup>
  );
}

type FolderDialogProps = {
  confirm?: string;
  invalidDestinationDirectories?: string[];
  invalidDestinations?: string[];
  initialDirectory: string;
  isOpen: boolean;
  onClose: () => void;
  onConfirm: (targetDirectory: string) => void;
  title?: string;
};

export default function FolderDialog({
  confirm,
  invalidDestinationDirectories = [],
  invalidDestinations = [],
  initialDirectory,
  isOpen,
  onClose,
  onConfirm,
  title,
}: FolderDialogProps): ReactElement {
  const { acpBasePath } = useConstant();
  const [targetDirectory, setTargetDirectory] =
    useState<string>(initialDirectory);
  const { directory } = useDirectory({
    path: targetDirectory,
    type: AssetMimeType.Directory,
  });

  useEffect(() => setTargetDirectory(initialDirectory), [initialDirectory]);

  const [isConfirming, setIsConfirming] = useState<boolean>(false);

  useEffect(() => setIsConfirming(false), [isOpen]);

  const displayDirectory = targetDirectory.replace(acpBasePath.assets, '');
  const pathStack = displayDirectory
    .split('/')
    .filter((dir: string): boolean => !!dir);
  const goToDirectory = useCallback(
    (newDirectory: Key): void => {
      let newTargetDirectory = `${newDirectory}/`;

      if (!newTargetDirectory.startsWith(acpBasePath.assets)) {
        newTargetDirectory = `${acpBasePath.assets}${newTargetDirectory}`;
      }

      setTargetDirectory(newTargetDirectory);
    },
    [setTargetDirectory, acpBasePath],
  );
  const handleConfirm = useCallback(async (): Promise<void> => {
    setIsConfirming(true);

    try {
      onConfirm(
        targetDirectory.endsWith('/')
          ? targetDirectory.slice(0, -1)
          : targetDirectory,
      );
    } catch (error) {
      setIsConfirming(false);
    }
  }, [onConfirm, setIsConfirming, targetDirectory]);
  const handleClose = useCallback((): void => {
    setIsConfirming(true);
    onClose();
    setIsConfirming(false);
    setTargetDirectory(initialDirectory);
  }, [onClose, initialDirectory, setIsConfirming]);

  return (
    <DialogContainer onDismiss={handleClose}>
      {isOpen && (
        <Dialog>
          <Heading>{title}</Heading>
          <Divider />
          <Content>
            <Flex direction="column">
              <View height="size-450" marginStart="-6px">
                <Breadcrumbs size="S" onAction={goToDirectory}>
                  {pathStack.map(
                    (item: string, index: number): ReactElement => {
                      let key = `/${item}`;

                      if (index > START_INDEX) {
                        key = `/${pathStack
                          .slice(START_INDEX, index)
                          .join('/')}${key}`;
                      }

                      return (
                        <Item key={key} textValue={`Go to ${item}`}>
                          {item}
                        </Item>
                      );
                    },
                  )}
                </Breadcrumbs>
              </View>
              <View borderWidth="thin" borderColor="gray-400" width="100%" flex>
                <ScrollPane style={{ height: '300px' }}>
                  {directory !== undefined ? (
                    <FolderView
                      assets={directory.children}
                      goToDirectory={goToDirectory}
                    />
                  ) : (
                    <div style={{ height: '284px' }}>
                      <Loading />
                    </div>
                  )}
                </ScrollPane>
              </View>
              <View height="size-450">
                <Heading
                  width="100%"
                  UNSAFE_style={{
                    textAlign: 'right',
                  }}
                  level={3}
                >
                  {directory?.[RepoMetadataKey.Name] ?? ''}
                </Heading>
              </View>
            </Flex>
          </Content>
          <ButtonGroup isDisabled={isConfirming || !directory}>
            <Button variant="secondary" onPress={handleClose}>
              Cancel
            </Button>
            <Button
              isDisabled={
                invalidDestinationDirectories.some(
                  (invalidDestinationDirectory) =>
                    targetDirectory.includes(invalidDestinationDirectory),
                ) ||
                invalidDestinations.some((invalidDestination) =>
                  targetDirectory.endsWith('/')
                    ? targetDirectory.slice(0, -1) === invalidDestination
                    : targetDirectory === invalidDestination,
                )
              }
              variant="cta"
              onPress={handleConfirm}
            >
              {confirm}
            </Button>
          </ButtonGroup>
        </Dialog>
      )}
    </DialogContainer>
  );
}

FolderDialog.defaultProps = {
  invalidDestinationDirectories: [],
  invalidDestinations: [],
  title: 'Move To',
  confirm: 'Confirm',
};
