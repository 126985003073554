/**
 * *****************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2022 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Adobe and its suppliers, if any. The intellectual and technical concepts
 * contained herein are proprietary to Adobe and its suppliers and are
 * protected by all applicable intellectual property laws, including trade
 * secret and copyright laws. Dissemination of this information or reproduction
 * of this material is strictly forbidden unless prior written permission is
 * obtained from Adobe.
 * *****************************************************************************
 */

import { useCallback, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { AssetStatus } from '__DEPRECATED_DO_NOT_USE_OR_YOU_WILL_BE_FIRED/controllers/constants/StatusUtils';
import { FilterProp } from '__DEPRECATED_DO_NOT_USE_OR_YOU_WILL_BE_FIRED/controllers/SearchParamsBuilder/types';
import {
  ContentType,
  FilterOption,
  FilterTab,
  updateFilters,
} from '__DEPRECATED_DO_NOT_USE_OR_YOU_WILL_BE_FIRED/redux/filter/filter.slice';

import { AssetMimeType } from 'model/acp/AssetMimeType';
import { useAppDispatch } from 'redux/hooks';

enum ReviewTab {
  'Pending',
  'Rejected',
  'All',
}

export enum SearchTab {
  'Templates',
  'Assets',
}

/**
 * @type GetFiltersFunction
 * Returns the new search filter depending on the new tab index
 *
 * @param index The new tab index
 * @param paramsBuilder SearchParamsBuilder instance that holds the current search filter
 *
 * @returns { prop: FilterProp, option: FilterOption }
 */
type GetFiltersFunction = (
  index: any,
) => { prop: FilterProp; option: FilterOption } | undefined;

function getReviewTabFilters(index: number) {
  let selectedFilterValue;

  switch (index) {
    case ReviewTab.Pending:
      selectedFilterValue = AssetStatus.pending;
      break;
    case ReviewTab.Rejected:
      selectedFilterValue = AssetStatus.rejected;
      break;
    default:
      selectedFilterValue = '*';
  }

  return {
    prop: 'status' as FilterProp,
    option: {
      label: 'Status',
      value: selectedFilterValue,
    },
  };
}

function getSearchTabFilters(index: number) {
  switch (index) {
    case SearchTab.Templates:
      return {
        prop: 'type' as FilterProp,
        option: {
          label: 'Type',
          value: AssetMimeType.TemplateDCX,
          isExactMatch: true,
        },
      };
    case SearchTab.Assets:
      return undefined;

    /*
     *return {
     *    prop: 'type' as FilterProp,
     *    option: {
     *        label: 'Type',
     *        value: ''IMAGE_ASSET_FORMAT
     *    }
     *};
     */
    default:
      return undefined;
  }
}

/**
 * Provides the current Grid tab, a list of Grid tabs to display, and a
 * function to update the Grid tab
 *
 * @param tab Filter tab to determine which Grid tabs to use and the filters they change
 */
function useGridTabs(tab: FilterTab) {
  const dispatch = useAppDispatch();
  const [gridTab, setGridTab] = useState(0);
  let getTabFilters: GetFiltersFunction;
  let gridTabList: string[];
  const { contentType } = useParams() as { contentType: ContentType };
  const navigate = useNavigate();

  switch (tab) {
    case FilterTab.review:
      getTabFilters = getReviewTabFilters;
      gridTabList = Object.values(ReviewTab).filter(
        (value: any) => typeof value === 'string',
      ) as string[];
      break;
    default:
      getTabFilters = getSearchTabFilters;
      gridTabList = Object.values(SearchTab).filter(
        (value: any) => typeof value === 'string',
      ) as string[];
  }

  const onGridTabChange = useCallback(
    (index: number) => {
      if (tab === FilterTab.search) {
        const tabContentType = index
          ? ContentType.assets
          : ContentType.templates;

        if (contentType !== tabContentType) {
          setGridTab(index);
          navigate(`/search/${tabContentType}`);

          return;
        }
      }

      const filters = getTabFilters(index);

      if (filters !== undefined) {
        dispatch(
          updateFilters({
            prop: filters.prop,
            newFilters: [filters.option],
          }),
        );
      }

      setGridTab(index);
    },
    [dispatch, getTabFilters, contentType, navigate, tab],
  );

  return { gridTab, gridTabList, onGridTabChange };
}

export default useGridTabs;
