/**
 * *****************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2022 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Adobe and its suppliers, if any. The intellectual and technical concepts
 * contained herein are proprietary to Adobe and its suppliers and are
 * protected by all applicable intellectual property laws, including trade
 * secret and copyright laws. Dissemination of this information or reproduction
 * of this material is strictly forbidden unless prior written permission is
 * obtained from Adobe.
 * *****************************************************************************
 */

import React, { ReactElement } from 'react';

import { Flex, Heading, Image, View } from '@adobe/react-spectrum';

import { BaseCard } from 'components/Card';
import BaseRendition from 'components/Card/BaseRendition';
import { AssetStatus } from 'model/acp/AssetStatus';
import { ZoomLevel } from 'model/ZoomLevel';

import styles from './CollectionSourceCard.module.scss';

type CollectionSourceCardProps = {
  background: string;
  isDisabled?: boolean;
  kind: string;
  label?: string;
  logo: string;
  onClick: () => void;
  title: string;
};

export default function CollectionSourceCard({
  background,
  isDisabled,
  kind,
  label = 'Start New Collection',
  logo,
  onClick,
  title,
}: CollectionSourceCardProps): ReactElement {
  return (
    <View flex flexBasis={300} flexShrink={1} position="relative">
      <BaseCard
        rendition={
          <View marginBottom="size-150">
            <BaseRendition isPremium={false} size="S">
              <>
                <Flex
                  height="100%"
                  width="100%"
                  position="relative"
                  alignItems="end"
                >
                  <View
                    borderTopStartRadius="regular"
                    borderTopEndRadius="regular"
                    backgroundColor="static-white"
                    position="absolute"
                    top="size-0"
                    bottom="size-0"
                    left="size-0"
                    right="size-0"
                    overflow="hidden"
                  >
                    <View overflow="hidden" minWidth="100%">
                      <img
                        style={{
                          display: 'block',
                        }}
                        src={background}
                        width="100%"
                        alt=""
                      />
                    </View>
                  </View>
                </Flex>
                <View
                  padding="size-0"
                  position="absolute"
                  bottom="-20px"
                  left="size-300"
                >
                  <Flex
                    alignItems="center"
                    justifyContent="center"
                    height="size-600"
                    width="size-600"
                  >
                    <Image src={logo} alt="Source" />
                  </Flex>
                </View>
              </>
            </BaseRendition>
          </View>
        }
        actions={[
          {
            label,
            value: 'create',
            variant: 'cta',
          },
        ]}
        canBeSelected={false}
        title={title}
        subtitle={kind}
        metadata={[]}
        size={ZoomLevel.ExtraLarge}
        onAction={onClick}
        status={AssetStatus.Default}
        onClick={() => {}}
        onSelect={() => {}}
      />

      {isDisabled ? (
        <Flex
          UNSAFE_className={styles.disabledCollectionSource}
          bottom="size-0"
          left="size-0"
          position="absolute"
          right="size-0"
          top="size-0"
          alignItems="center"
          justifyContent="center"
        >
          <Heading level={1}>Coming Soon</Heading>
        </Flex>
      ) : null}
    </View>
  );
}

CollectionSourceCard.defaultProps = {
  isDisabled: false,
  label: 'Start New Collection',
};
