/**
 * *****************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2022 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Adobe and its suppliers, if any. The intellectual and technical concepts
 * contained herein are proprietary to Adobe and its suppliers and are
 * protected by all applicable intellectual property laws, including trade
 * secret and copyright laws. Dissemination of this information or reproduction
 * of this material is strictly forbidden unless prior written permission is
 * obtained from Adobe.
 * *****************************************************************************
 */

import Axios from 'axios';
import * as URITemplate from 'uritemplate';

import { ACPLink } from 'model/acp/ACPLink';
import Directory from 'model/acp/Directory';
import { OrderBy } from 'model/acp/OrderBy';
import Url from 'model/constant/Url';
import { getACPLinkUrl } from 'utils/acp/acpLinks';
import parseLinkHeader from 'utils/parseLinkHeader';

type PageMetadataResult = {
  result: Directory;
  linkUrl: string;
};

export async function getPageMetadataForAsset(
  assetPath: string,
  {
    acpRepoId,
    url,
    start,
    limit = 100,
  }: {
    acpRepoId: string;
    url: Url;
    start?: string;
    limit: number | 'all';
  },
): Promise<PageMetadataResult> {
  const acpLinkUrl = getACPLinkUrl(assetPath, acpRepoId, url.platformBase);
  const data = await Axios.head(acpLinkUrl);
  const links = parseLinkHeader(data.headers.link);
  const linkUrl = links[ACPLink.Page]?.url;

  if (linkUrl === undefined) {
    throw new Error('Unable to find ACP link.');
  }

  const requestUrl = URITemplate.parse(linkUrl).expand({
    orderBy: OrderBy.Name,
    start,
    limit: limit === 'all' ? undefined : limit,
  });
  const pageMetadataData = await Axios.get<Directory>(requestUrl);

  if (limit === 'all') {
    while (pageMetadataData.data._links.next !== undefined) {
      // eslint-disable-next-line no-await-in-loop
      const nextPageMetadataData = await Axios.get<Directory>(
        pageMetadataData.data._links.next.href as string,
      );

      pageMetadataData.data.children = pageMetadataData.data.children.concat(
        nextPageMetadataData.data.children,
      );
      pageMetadataData.data._links.next = nextPageMetadataData.data._links.next;
    }
  }

  return {
    result: pageMetadataData.data,
    linkUrl,
  };
}
