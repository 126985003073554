/**
 * *****************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2022 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Adobe and its suppliers, if any. The intellectual and technical concepts
 * contained herein are proprietary to Adobe and its suppliers and are
 * protected by all applicable intellectual property laws, including trade
 * secret and copyright laws. Dissemination of this information or reproduction
 * of this material is strictly forbidden unless prior written permission is
 * obtained from Adobe.
 * *****************************************************************************
 */

import { ElementType } from 'react';

import {
  BaseLayout,
  GridLayout as FixedGridLayout,
} from '@react/react-spectrum/GridView';

import FileGridItem from '__DEPRECATED_DO_NOT_USE_OR_YOU_WILL_BE_FIRED/Grid/components/FileGridItem';
import TemplatesGridItem from '__DEPRECATED_DO_NOT_USE_OR_YOU_WILL_BE_FIRED/Grid/components/TemplatesGridItem';
import WaterfallGridLayout from '__DEPRECATED_DO_NOT_USE_OR_YOU_WILL_BE_FIRED/Grid/components/WaterfallGridLayout';
import {
  TAsset,
  TAssetKind,
} from '__DEPRECATED_DO_NOT_USE_OR_YOU_WILL_BE_FIRED/redux/assets/asset.types';
import { ContentType } from '__DEPRECATED_DO_NOT_USE_OR_YOU_WILL_BE_FIRED/redux/filter/filter.slice';

import { AssetMimeType } from 'model/acp/AssetMimeType';

import { GridItemProps } from './types';

const enum ResourceType {
  File = 'file',
  Folder = 'folder',
  Image = 'image',
}

type GridRegistry = {
  /**
   * Provides the Layout to be used with the GridView spectrum component.
   * It determines whether to display Grid items with fixed height/width or
   * items with variable height/width.
   */
  Layout: (contentType: ContentType) => typeof BaseLayout;

  /**
   * Provides the Grid item component for a specific asset type.
   * Components returned by this function should work well as children of
   * the Card spectrum component.
   * The Grid component will render items in @method Grid.renderItem
   *
   * @see https://react-spectrum.corp.adobe.com/components/Card
   * @see https://react-spectrum.corp.adobe.com/components/GridView
   */
  GridItem: (asset: TAsset) => ElementType<GridItemProps>;

  /**
   * Provides the ResourceType that can be used for the type
   * property of a Spectrum Asset component.
   */
  AssetResourceType: (asset: TAsset) => ResourceType;

  /**
   * Provides the string that can be used to display the
   * MIME type of an asset.
   */
  AssetDisplayType: (mime: string) => string;
};

function Layout(contentType: ContentType) {
  switch (contentType) {
    case ContentType.templates:
      return WaterfallGridLayout;
    case ContentType.assets:
      return FixedGridLayout; // this view is only for search-results for assets
    default:
      return FixedGridLayout;
  }
}

function GridItem(asset: TAsset) {
  switch (asset.kind) {
    case TAssetKind.template:
      return TemplatesGridItem;
    default:
      return FileGridItem;
  }
}

function AssetResourceType(asset: TAsset) {
  switch (asset.kind) {
    case TAssetKind.folder:
      return ResourceType.Folder;
    case TAssetKind.collection:
      return ResourceType.File;
    default:
      return ResourceType.Image;
  }
}

function AssetDisplayType(mime: string) {
  switch (mime) {
    case AssetMimeType.Directory:
      return TAssetKind.folder;
    case AssetMimeType.Collection:
      return TAssetKind.collection;
    case AssetMimeType.TemplateDCX:
      return TAssetKind.template;
    default:
      return mime;
  }
}

const Registry: GridRegistry = {
  Layout,
  GridItem,
  AssetResourceType,
  AssetDisplayType,
};

export default Registry as GridRegistry;
