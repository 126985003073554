/**
 * *****************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2022 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Adobe and its suppliers, if any. The intellectual and technical concepts
 * contained herein are proprietary to Adobe and its suppliers and are
 * protected by all applicable intellectual property laws, including trade
 * secret and copyright laws. Dissemination of this information or reproduction
 * of this material is strictly forbidden unless prior written permission is
 * obtained from Adobe.
 * *****************************************************************************
 */

import { useEffect, useMemo, useState } from 'react';
import { useQuery } from 'react-query';

import Axios from 'axios';
import * as URITemplate from 'uritemplate';

import { ACPLink } from 'model/acp/ACPLink';
import { AssetMimeType } from 'model/acp/AssetMimeType';
import { ChildAsset } from 'model/acp/ChildAsset';
import Directory from 'model/acp/Directory';
import { OrderBy } from 'model/acp/OrderBy';
import { RepoMetadataKey } from 'model/acp/RepoMetadataKey';

import useACPLink from './acp/useACPLink';

type BreadthFirstDirectorySearchHookProps = {
  enabled?: boolean;
  path: string;
  search: string;
  type: AssetMimeType;
};

type BreadthFirstDirectorySearchHookResult = {
  results: ChildAsset[] | undefined;
  isSearching: boolean;
};

export default function useBreadthFirstDirectorySearch({
  enabled = true,
  path,
  search,
  type,
}: BreadthFirstDirectorySearchHookProps): BreadthFirstDirectorySearchHookResult {
  const [currentSearchIndex, setCurrentSearchIndex] = useState<number>(0);
  const [currentAssetPaths, setCurrentAssetPaths] = useState<string[]>([path]);
  const [foundAssets, setFoundAssets] = useState<ChildAsset[]>([]);
  const shouldSearch = enabled && path.length !== 0 && search.length !== 0;

  useEffect(() => {
    setCurrentSearchIndex(0);
    setCurrentAssetPaths([path]);
    setFoundAssets([]);
  }, [search, path, enabled]);

  // Recursive search
  const currentSearchPath = currentAssetPaths[currentSearchIndex];
  const { results, isLoading: isLoadingACPLink } = useACPLink({
    acpLink: ACPLink.Page,
    path: currentSearchPath,
    enabled: shouldSearch,
  });
  let requestUrl = '';

  if (results?.linkUrl !== undefined) {
    requestUrl = URITemplate.parse(results.linkUrl).expand({
      orderBy: OrderBy.Name,
      type,
    });
  }

  const GETQuery = async (): Promise<Directory> => {
    const result = await Axios.get<Directory>(requestUrl);

    return result.data;
  };
  const { data: directory, isLoading: isLoadingDirectory } =
    useQuery<Directory>(
      ['breadthFirstDirectorySearch', currentSearchPath, type],
      GETQuery,
      {
        enabled: shouldSearch && requestUrl !== '',
      },
    );

  useEffect(() => {
    if (directory?.children) {
      setCurrentAssetPaths((c) =>
        c.concat(
          directory.children.map((child) => child[RepoMetadataKey.Path]),
        ),
      );
      setFoundAssets((c) => c.concat(directory.children));
    }

    if (directory) {
      setCurrentSearchIndex((c) => c + 1);
    }
  }, [directory]);

  const matchingAssets = useMemo(
    () =>
      foundAssets.filter((asset) =>
        asset[RepoMetadataKey.Name]
          .toLocaleLowerCase()
          .includes(search.toLocaleLowerCase()),
      ),
    [foundAssets, search],
  );

  return {
    results: matchingAssets.length !== 0 ? matchingAssets : undefined,
    isSearching:
      isLoadingACPLink ||
      isLoadingDirectory ||
      currentSearchIndex + 1 < currentAssetPaths.length,
  };
}
