/**
 * *****************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2022 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Adobe and its suppliers, if any. The intellectual and technical concepts
 * contained herein are proprietary to Adobe and its suppliers and are
 * protected by all applicable intellectual property laws, including trade
 * secret and copyright laws. Dissemination of this information or reproduction
 * of this material is strictly forbidden unless prior written permission is
 * obtained from Adobe.
 * *****************************************************************************
 */

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { OrderBy } from 'model/acp/OrderBy';
import { SortOrder } from 'model/acp/SortOrder';
import { DocumentRailPane } from 'model/DocumentRailPane';
import { DocumentView } from 'model/DocumentView';
import { AppThunk } from 'redux/store';

export interface ExplorerState {
  // View and layout
  detailRailPane: DocumentRailPane;
  documentRailPane: DocumentRailPane;
  documentView: DocumentView;
  // Asset sorting
  orderBy: OrderBy;
  sortOrder: SortOrder;
  // Asset selection
  selectedAssetPaths: Set<string>;
}

const initialState: ExplorerState = {
  detailRailPane: DocumentRailPane.Details,
  documentRailPane: DocumentRailPane.None,
  documentView: DocumentView.Card,
  orderBy: OrderBy.Name,
  selectedAssetPaths: new Set<string>([]),
  sortOrder: SortOrder.Ascending,
};

export const explorerState = createSlice({
  name: 'explorer',
  initialState,
  reducers: {
    setDetailRailPane: (state, action: PayloadAction<DocumentRailPane>) => {
      state.detailRailPane = action.payload;
    },
    clearDocumentRailPane: (state) => {
      state.documentRailPane = DocumentRailPane.None;
    },
    setDocumentRailPane: (state, action: PayloadAction<DocumentRailPane>) => {
      state.documentRailPane = action.payload;
    },
    setDocumentView: (state, action: PayloadAction<DocumentView>) => {
      state.documentView = action.payload;
    },
    setOrderBy: (state, action: PayloadAction<OrderBy>) => {
      state.orderBy = action.payload;
    },
    setSelectedAssetPaths: (state, action: PayloadAction<Set<string>>) => {
      state.selectedAssetPaths = action.payload;
    },
    toggleSortOrder: (state) => {
      if (state.sortOrder === SortOrder.Descending) {
        state.sortOrder = SortOrder.Ascending;
      } else {
        state.sortOrder = SortOrder.Descending;
      }
    },
    clearSelectedAssetPaths: (state) => {
      state.selectedAssetPaths = new Set<string>([]);
    },
  },
});

export const {
  clearDocumentRailPane,
  clearSelectedAssetPaths,
  setDetailRailPane,
  setDocumentRailPane,
  setDocumentView,
  setOrderBy,
  setSelectedAssetPaths,
  toggleSortOrder,
} = explorerState.actions;

export const toggleSelectedAssetsByPath =
  (assetPath: string): AppThunk =>
  (dispatch, getState) => {
    const { selectedAssetPaths } = getState().explorer;

    if (selectedAssetPaths.has(assetPath)) {
      dispatch(
        setSelectedAssetPaths(
          new Set<string>(
            [...selectedAssetPaths].filter(
              (selection) => selection !== assetPath,
            ),
          ),
        ),
      );
    } else {
      dispatch(
        setSelectedAssetPaths(
          new Set<string>([...selectedAssetPaths, assetPath]),
        ),
      );
    }
  };

export default explorerState.reducer;
