/**
 * *****************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2022 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Adobe and its suppliers, if any. The intellectual and technical concepts
 * contained herein are proprietary to Adobe and its suppliers and are
 * protected by all applicable intellectual property laws, including trade
 * secret and copyright laws. Dissemination of this information or reproduction
 * of this material is strictly forbidden unless prior written permission is
 * obtained from Adobe.
 * *****************************************************************************
 */

import Axios from 'axios';

import {
  AccessControlEntry,
  ACLPolicyResult,
  IMSPrincipal,
  Inheritance,
  Modifier,
} from 'hooks/useFolderACL';
import { AccessLevel, Privileges } from 'model/AccessLevel';
import { ACPLink } from 'model/acp/ACPLink';
import Url from 'model/constant/Url';
import parseLinkHeader from 'utils/parseLinkHeader';

import { getACPLinkUrl } from './acpLinks';

export function buildACEForNewUser(
  principalId: string,
  accessLevel: AccessLevel,
  privileges: Privileges[],
) {
  let relations: ACPLink[] = [];

  if (accessLevel !== AccessLevel.Owner) {
    relations = [
      ACPLink.ACPolicy,
      ACPLink.Primary,
      ACPLink.ApplicationMetadata,
      ACPLink.EmbeddedMetadata,
      ACPLink.RepoMetadata,
      ACPLink.Rendition,
    ];
  }

  return {
    'repo:principal': {
      'xdm:provider': {
        '@id': 'https://ims-na1.adobelogin.com/',
      },
      '@id': principalId,
      '@type': 'https://ns.adobe.com/adobecloudplatform/ims/user',
    },
    'repo:modifier': Modifier.Grant,
    'repo:privileges': privileges,
    'repo:relations': relations,
    'repo:inheritance': Inheritance.Deep,
  } as AccessControlEntry;
}

export function isUserPrincipal(principal: IMSPrincipal) {
  const isIMSPrincpal = !!principal['@id'];

  return (
    isIMSPrincpal &&
    principal['@id'].includes('@') &&
    !principal['@id'].includes('@AdobeOrg')
  );
}

export function isUserGroupPrincipal(principal: IMSPrincipal) {
  const isIMSPrinicpal = !!principal['@id'];

  return (
    isIMSPrinicpal &&
    (/^\d+$/.test(principal['@id']) || principal['@id'].includes('@AdobeOrg'))
  );
}

export async function getACLForAsset(
  assetPath: string,
  { acpRepoId, url }: { acpRepoId: string; url: Url },
): Promise<ACLPolicyResult> {
  const acpLinkUrl = getACPLinkUrl(assetPath, acpRepoId, url.platformBase);
  const data = await Axios.head(acpLinkUrl);
  const links = parseLinkHeader(data.headers.link);
  const linkUrl = links[ACPLink.ACPolicy]?.url;

  if (linkUrl === undefined) {
    throw new Error('Unable to find ACP link.');
  }

  const acl = await Axios.get(linkUrl);

  return acl.data as ACLPolicyResult;
}
