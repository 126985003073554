/**
 * *****************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2022 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Adobe and its suppliers, if any. The intellectual and technical concepts
 * contained herein are proprietary to Adobe and its suppliers and are
 * protected by all applicable intellectual property laws, including trade
 * secret and copyright laws. Dissemination of this information or reproduction
 * of this material is strictly forbidden unless prior written permission is
 * obtained from Adobe.
 * *****************************************************************************
 */

import React, {
  ReactElement,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';

import { AnnotationsOverlay } from '@ccx-public/ccx-comments';

import { getFixedWidthRendition } from '__DEPRECATED_DO_NOT_USE_OR_YOU_WILL_BE_FIRED/controllers/api/SparkSearch';
import { TemplateAsset } from '__DEPRECATED_DO_NOT_USE_OR_YOU_WILL_BE_FIRED/controllers/api/SparkSearchTypes';

import styles from './TemplateRendition.module.scss';

type TemplateRenditionProps = {
  asset: TemplateAsset;
  shouldLoadAnnotations: boolean;
};

export default function TemplateRendition({
  asset,
  shouldLoadAnnotations,
}: TemplateRenditionProps): ReactElement {
  const [scaleFactorX, setScaleFactorX] = useState<number>();
  const [scaleFactorY, setScaleFactorY] = useState<number>();
  const imgRef = useRef<HTMLImageElement>(null);
  const rendition = getFixedWidthRendition(
    asset,
    asset.rendition.maxWidth,
    'png',
  );
  const onResize = useCallback(() => {
    if (imgRef.current) {
      setScaleFactorX(imgRef.current.clientWidth / asset.rendition.maxWidth);
      setScaleFactorY(imgRef.current.clientHeight / asset.rendition.maxHeight);
    }
  }, [asset.rendition.maxHeight, asset.rendition.maxWidth]);

  useEffect(() => {
    window.addEventListener('resize', onResize);
    onResize();

    return () => window.removeEventListener('resize', onResize);
  }, [onResize, imgRef.current?.complete]);

  return (
    <div className={styles.rendition}>
      {shouldLoadAnnotations && imgRef.current !== null ? (
        <div
          style={{
            position: 'absolute',
            height: imgRef.current.clientHeight,
            width: imgRef.current.clientWidth,
          }}
        >
          <AnnotationsOverlay
            scaleFactorX={scaleFactorX}
            scaleFactorY={scaleFactorY}
          />
        </div>
      ) : null}
      <img
        key={asset.id}
        alt={asset.title}
        className={styles.image}
        ref={imgRef}
        src={rendition.href}
      />
    </div>
  );
}
