/**
 * *****************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2022 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Adobe and its suppliers, if any. The intellectual and technical concepts
 * contained herein are proprietary to Adobe and its suppliers and are
 * protected by all applicable intellectual property laws, including trade
 * secret and copyright laws. Dissemination of this information or reproduction
 * of this material is strictly forbidden unless prior written permission is
 * obtained from Adobe.
 * *****************************************************************************
 */

import React, { FormEvent, ReactElement, useContext, useState } from 'react';

import { Form, TextField, View } from '@adobe/react-spectrum';

import { Casing } from './Casing';
import { TagInputContext } from './TagInputContext';

export interface IndeterminateInputProps {
  casing?: Casing;
}

export default function IndeterminateInput({
  casing = Casing.LowerCase,
}: IndeterminateInputProps): ReactElement {
  const [inputValue, setInputValue] = useState('');
  const { currentItems, setCurrentItems, singularLabel } =
    useContext(TagInputContext);
  const onChange = (newValue: string): void => {
    const setValue =
      casing === Casing.MixedCase ? newValue : newValue.toLowerCase();
    setInputValue(setValue);
  };
  const onSubmit = (event: FormEvent): void => {
    event.preventDefault();
    const newSelectedItems = new Set([...currentItems, inputValue.trim()]);
    setCurrentItems(Array.from(newSelectedItems));
    setInputValue('');
  };

  return (
    <View UNSAFE_style={{ marginTop: '-8px' }}>
      <Form onSubmit={onSubmit}>
        <TextField
          aria-label={`Add ${singularLabel}`}
          margin={0}
          value={inputValue}
          isQuiet
          autoFocus
          placeholder={`Enter ${singularLabel}`}
          onChange={onChange}
        />
      </Form>
    </View>
  );
}

IndeterminateInput.defaultProps = {
  casing: undefined,
};
