/**
 * *****************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2022 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Adobe and its suppliers, if any. The intellectual and technical concepts
 * contained herein are proprietary to Adobe and its suppliers and are
 * protected by all applicable intellectual property laws, including trade
 * secret and copyright laws. Dissemination of this information or reproduction
 * of this material is strictly forbidden unless prior written permission is
 * obtained from Adobe.
 * *****************************************************************************
 */

import serialize from 'canonicalize';
import Multihashing from 'multihashing';

import ApplicationMetadata from 'model/acp/ApplicationMetadata';
import { ApplicationMetadataKey } from 'model/acp/ApplicationMetadataKey';
import { AssetStatus } from 'model/acp/AssetStatus';
import RepoMetadata from 'model/acp/RepoMetadata';
import { RepoMetadataKey } from 'model/acp/RepoMetadataKey';
import Constant from 'model/constant/Constant';

import useApplicationMetadata from './acp/useApplicationMetadata';
import useRepoMetadata from './acp/useRepoMetadata';

type Props = {
  path?: string;
};
type PublishHashData = ApplicationMetadata & Record<string, any>;
const hashKeys = [
  'path',
  'version',
  ApplicationMetadataKey.Animated,
  ApplicationMetadataKey.ApplicableRegions,
  ApplicationMetadataKey.ApplicableFilters,
  ApplicationMetadataKey.Attribution,
  ApplicationMetadataKey.Audio,
  ApplicationMetadataKey.DCSubject,
  ApplicationMetadataKey.DCTitle,
  ApplicationMetadataKey.Hero,
  ApplicationMetadataKey.Language,
  ApplicationMetadataKey.LicensingCategory,
  ApplicationMetadataKey.Priority,
  ApplicationMetadataKey.Tasks,
  ApplicationMetadataKey.Traits,
];

function computePublishHash(
  applicationMetadata: ApplicationMetadata | undefined,
  repoMetadata: RepoMetadata | undefined,
): string {
  if (applicationMetadata && repoMetadata) {
    const data: PublishHashData = {
      ...applicationMetadata,
      path: repoMetadata[RepoMetadataKey.Path],
      version: repoMetadata[RepoMetadataKey.Version],
    };
    /**
     * @see https://tools.ietf.org/html/rfc8785
     */
    const canoncalJSON: string =
      serialize(
        hashKeys.reduce<PublishHashData>(
          (accumulator: PublishHashData, key: string): PublishHashData => {
            if (data[key] !== undefined) {
              accumulator[key] = data[key];
            }

            return accumulator;
          },
          {},
        ),
      ) || '';
    const buf: Buffer = Multihashing.Buffer.from(canoncalJSON);
    const multihash: Buffer = Multihashing(buf, 'sha2-256');

    /**
     * Convert buffer bytes to base64 encoded string and remove padding
     * '=' characters from the end of the string
     */
    return multihash.toString('base64').replace(/=+/, '');
  }

  return '';
}

export function getAssetStatus(
  applicationMetadata: ApplicationMetadata | undefined,
  repoMetadata: RepoMetadata | undefined,
  publishHash?: string,
) {
  if (!applicationMetadata || !repoMetadata) {
    return AssetStatus.Default;
  }

  /**
   * If the asset has a publishedHash, it should be equal to the locally
   * computed hash if the asset has been published; otherwise there are
   * unpublished changes that are saved, making it "dirty"
   */
  const publishedHash =
    applicationMetadata[ApplicationMetadataKey.PublishedHash];
  publishHash =
    publishHash ?? computePublishHash(applicationMetadata, repoMetadata);

  if (publishedHash && publishHash === publishedHash) {
    return AssetStatus.Published;
  }

  if (publishedHash) {
    return AssetStatus.Dirty;
  }

  /**
   * If an asset has a path that indicates the submit workflow:
   * - return the metadata status if one exists
   * - if one does not exist, this is indicative of an asset
   *   directly uploaded to Tempo; return `Pending` by default
   */
  const { acpBasePath } = new Constant();

  if (repoMetadata[RepoMetadataKey.Path].startsWith(acpBasePath.submit)) {
    const amStatus = applicationMetadata[ApplicationMetadataKey.Status];

    if (amStatus) {
      return amStatus as AssetStatus;
    }

    return AssetStatus.Pending;
  }

  /**
   * If there is no publishedHash then this asset has never been published.
   */
  return AssetStatus.Unpublished;
}

/**
 * External libraries:
 * - Canonicalize: {@external https://github.com/erdtman/canonicalize}
 * - Multihashing: {@external https://github.com/multiformats/js-multihashing}
 */

export default function useAssetStatus({ path = '' }: Props): AssetStatus {
  const {
    results: applicationMetadata,
    isLoading: applicationMetadataIsLoading,
  } = useApplicationMetadata({ path, enabled: !!path });
  const { results: repoMetadata } = useRepoMetadata({ path, enabled: !!path });
  const publishHash = computePublishHash(applicationMetadata, repoMetadata);

  /*
   * When an asset is uploaded directly to the author folder, it has no
   * metadata to determine asset status from. As a result if we are ever
   * missing either application metadata or repo metadata, simply return a
   * status of Unpublished.
   */
  if (!applicationMetadataIsLoading && !applicationMetadata) {
    return AssetStatus.Unpublished;
  }

  return getAssetStatus(applicationMetadata, repoMetadata, publishHash);
}
