/**
 * *****************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2022 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Adobe and its suppliers, if any. The intellectual and technical concepts
 * contained herein are proprietary to Adobe and its suppliers and are
 * protected by all applicable intellectual property laws, including trade
 * secret and copyright laws. Dissemination of this information or reproduction
 * of this material is strictly forbidden unless prior written permission is
 * obtained from Adobe.
 * *****************************************************************************
 */

import React, { Key, ReactElement } from 'react';

import {
  ActionGroup,
  Item,
  Tooltip,
  TooltipTrigger,
} from '@adobe/react-spectrum';

import styles from './BaseCard.module.scss';

export type CardAction = {
  icon?: (props?: any) => JSX.Element;
  variant?: 'cta' | 'primary' | 'overBackground';
  label: string;
  value: string;
};

type BaseCardActionsProps = {
  actions: CardAction[];
  isHidden?: boolean;
  onAction: (key: Key) => void;
};

export default function BaseCardQuickActions({
  actions,
  isHidden,
  onAction,
}: BaseCardActionsProps): ReactElement {
  return (
    <div
      className={`spectrum-QuickActions spectrum-Card-actions ${
        styles.quickActions
      } ${isHidden ? styles.hidden : ''}`}
    >
      <ActionGroup isQuiet density="compact" onAction={onAction}>
        {actions.map((action) => (
          <TooltipTrigger key={action.value} delay={0}>
            <Item key={action.value} aria-label={action.label}>
              {action.icon ? <action.icon /> : null}
            </Item>
            <Tooltip>{action.label}</Tooltip>
          </TooltipTrigger>
        ))}
      </ActionGroup>
    </div>
  );
}

BaseCardQuickActions.defaultProps = {
  isHidden: false,
};
