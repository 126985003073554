/**
 * *****************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2022 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Adobe and its suppliers, if any. The intellectual and technical concepts
 * contained herein are proprietary to Adobe and its suppliers and are
 * protected by all applicable intellectual property laws, including trade
 * secret and copyright laws. Dissemination of this information or reproduction
 * of this material is strictly forbidden unless prior written permission is
 * obtained from Adobe.
 * *****************************************************************************
 */

import { ACPLink } from 'model/acp/ACPLink';
import { HeaderLink } from 'model/acp/HeaderLink';
import { HeaderLinks } from 'model/acp/HeaderLinks';

const parseLink = (link: string): HeaderLink | undefined => {
  let resultRecord: HeaderLink | undefined;

  try {
    const match = link.match(/<?([^>]*)>(.*)/);

    if (match) {
      const url = match[1];
      const parts = match[2].split(';');

      if (Array.isArray(parts)) {
        parts.shift();

        resultRecord = parts.reduce<HeaderLink>(
          (accumulator: HeaderLink, currentValue: string): HeaderLink => {
            const curMatch = currentValue.match(/\s*(.+)\s*=\s*"?([^"]+)"?/);

            if (curMatch) {
              const [, prop, value] = curMatch;

              if (prop === 'templated') {
                accumulator[prop] = JSON.parse(value);
              } else {
                accumulator[prop as 'url' | 'rel'] = value;
              }
            }

            return accumulator;
          },
          {
            url,
          },
        );
      }
    }

    // eslint-disable-next-line no-empty
  } catch (ignore) {}

  return resultRecord;
};

/**
 * @param linkHeader {string} - straight up stringy link headers
 * @returns {Partial<HeaderLinks>} - this is a partial return to force
 * consumers to check the service as nothing from a service is ever gauranteed
 */
export default function parseLinkHeader(
  linkHeader: string,
): Partial<HeaderLinks> {
  const splitLinkHeader = linkHeader.split(/,\s*</);
  const parsed = splitLinkHeader.map(parseLink);
  const filtered = parsed.filter(
    (item: HeaderLink | undefined): boolean => !!item?.rel,
  ) as HeaderLink[];

  return filtered.reduce<Partial<HeaderLinks>>(
    (
      accumulator: Partial<HeaderLinks>,
      currentValue: HeaderLink,
    ): Partial<HeaderLinks> => {
      currentValue.rel?.split(/\s+/).forEach((subRel: string): void => {
        accumulator[subRel as ACPLink] = currentValue;
      });

      return accumulator;
    },
    {},
  );
}
