/**
 * *****************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2022 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Adobe and its suppliers, if any. The intellectual and technical concepts
 * contained herein are proprietary to Adobe and its suppliers and are
 * protected by all applicable intellectual property laws, including trade
 * secret and copyright laws. Dissemination of this information or reproduction
 * of this material is strictly forbidden unless prior written permission is
 * obtained from Adobe.
 * *****************************************************************************
 */

import React, { ReactChild, ReactElement } from 'react';

import { ActionButton, Flex, Text, View } from '@adobe/react-spectrum';
import { PressEvent } from '@react-types/shared';

import styles from './TemplateRailAction.module.scss';

export enum TemplateRailActionVariant {
  Positive = 'positive',
  Default = 'default',
  Negative = 'negative',
}

const variantClassMap: Record<TemplateRailActionVariant, string> = {
  [TemplateRailActionVariant.Positive]: styles.positive,
  [TemplateRailActionVariant.Default]: '',
  [TemplateRailActionVariant.Negative]: styles.negative,
};

export type TemplateRailActionProps = {
  action: (event: PressEvent) => void;
  badge?: ReactChild;
  badgeVariant?: string;
  variant?: TemplateRailActionVariant;
  icon?: ({
    size,
  }: {
    size: 'XXL' | 'XXS' | 'XS' | 'S' | 'M' | 'L' | 'XL' | undefined;
  }) => ReactElement;
  isActive?: boolean;
  isDisabled?: boolean;
  label: string;
};

export default function TemplateRailAction({
  action,
  badge,
  badgeVariant,
  icon: Icon,
  isActive,
  isDisabled,
  label,
  variant = TemplateRailActionVariant.Default,
}: TemplateRailActionProps): ReactElement {
  return (
    <View width="100%">
      <ActionButton
        UNSAFE_className={`${styles.templateRailAction} ${
          isActive ? styles.activeItem : ''
        } ${variantClassMap[variant]}`}
        onPress={action}
        aria-current="location"
        isDisabled={isDisabled}
      >
        {badge ? (
          <Flex
            justifyContent="center"
            alignItems="center"
            position="absolute"
            top="size-50"
            right="size-100"
            height="size-250"
            width="size-250"
            UNSAFE_style={{
              borderRadius: '99px',
              backgroundColor:
                badgeVariant === 'success'
                  ? 'var(--spectrum-global-color-static-green-500)'
                  : 'var(--spectrum-global-color-static-orange-500)',
              color: 'white',
              fontSize: '12px',
              fontWeight: 700,
            }}
          >
            {badge}
          </Flex>
        ) : null}
        <Flex direction="column" alignItems="center">
          {Icon ? (
            <View>
              <Icon size="S" />
            </View>
          ) : null}
          <Text UNSAFE_className={styles.navItemLabel}>{label}</Text>
        </Flex>
      </ActionButton>
    </View>
  );
}

TemplateRailAction.defaultProps = {
  badge: undefined,
  badgeVariant: undefined,
  icon: undefined,
  isActive: false,
  isDisabled: false,
  variant: TemplateRailActionVariant.Default,
};
