/**
 * *****************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2022 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Adobe and its suppliers, if any. The intellectual and technical concepts
 * contained herein are proprietary to Adobe and its suppliers and are
 * protected by all applicable intellectual property laws, including trade
 * secret and copyright laws. Dissemination of this information or reproduction
 * of this material is strictly forbidden unless prior written permission is
 * obtained from Adobe.
 * *****************************************************************************
 */

import React, { ReactElement } from 'react';

import Audio from '@spectrum-icons/workflow/Audio';
import FileData from '@spectrum-icons/workflow/FileData';
import VideoFilled from '@spectrum-icons/workflow/VideoFilled';

import AssetRendition from 'components/AssetRendition';
import Loading from 'components/Loading';
import { AssetMimeType } from 'model/acp/AssetMimeType';
import RepoMetadata from 'model/acp/RepoMetadata';
import { RepoMetadataKey } from 'model/acp/RepoMetadataKey';

type FileRenditionProps = {
  isPremium: boolean;
  repoMetadata: RepoMetadata | undefined;
};

export default function FileRendition({
  isPremium,
  repoMetadata,
}: FileRenditionProps): ReactElement {
  if (repoMetadata === undefined) {
    return <Loading />;
  }

  if (repoMetadata[RepoMetadataKey.DCFormat].startsWith('audio')) {
    return <Audio size="XL" />;
  }

  if (repoMetadata[RepoMetadataKey.DCFormat].startsWith('video')) {
    return <VideoFilled size="XL" />;
  }

  if (repoMetadata[RepoMetadataKey.Name].endsWith('.mlmodel')) {
    return <FileData size="XL" />;
  }

  return (
    <AssetRendition
      isHero={repoMetadata[RepoMetadataKey.DCFormat] === AssetMimeType.Canvas}
      isPremium={isPremium}
      path={repoMetadata[RepoMetadataKey.Path]}
      size={240}
    />
  );
}
