/**
 * *****************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2022 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Adobe and its suppliers, if any. The intellectual and technical concepts
 * contained herein are proprietary to Adobe and its suppliers and are
 * protected by all applicable intellectual property laws, including trade
 * secret and copyright laws. Dissemination of this information or reproduction
 * of this material is strictly forbidden unless prior written permission is
 * obtained from Adobe.
 * *****************************************************************************
 */

import React from 'react';

import TagInputSet from '__DEPRECATED_DO_NOT_USE_OR_YOU_WILL_BE_FIRED/components/TagInputSet';
import { Casing } from '__DEPRECATED_DO_NOT_USE_OR_YOU_WILL_BE_FIRED/components/TagInputSet/Casing';

import { AssetMetadataFormFieldProps } from './AssetMetadataFormFieldProps';

export default function CreatorsFormField({
  onChange,
  value,
}: AssetMetadataFormFieldProps<string[]>) {
  return (
    <TagInputSet
      pluralLabel="Creators"
      singularLabel="Creator"
      currentItems={value}
      setCurrentItems={onChange}
      casing={Casing.MixedCase}
    />
  );
}
