/**
 * *****************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2022 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Adobe and its suppliers, if any. The intellectual and technical concepts
 * contained herein are proprietary to Adobe and its suppliers and are
 * protected by all applicable intellectual property laws, including trade
 * secret and copyright laws. Dissemination of this information or reproduction
 * of this material is strictly forbidden unless prior written permission is
 * obtained from Adobe.
 * *****************************************************************************
 */

import React, { ReactElement } from 'react';

import { ActionButton as SpectrumActionButton } from '@adobe/react-spectrum';
import { SpectrumActionButtonProps } from '@react-types/button';

export default function ActionButton(
  props: SpectrumActionButtonProps,
): ReactElement {
  const addPadAndFixBrokenSpectrum = {
    paddingLeft: 'var(--spectrum-global-dimension-size-125)',
  };
  const newProps = { ...props };
  newProps.UNSAFE_style = {
    ...newProps.UNSAFE_style,
    ...addPadAndFixBrokenSpectrum,
  };

  return (
    <SpectrumActionButton
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...newProps}
    />
  );
}
