/**
 * *****************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2022 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Adobe and its suppliers, if any. The intellectual and technical concepts
 * contained herein are proprietary to Adobe and its suppliers and are
 * protected by all applicable intellectual property laws, including trade
 * secret and copyright laws. Dissemination of this information or reproduction
 * of this material is strictly forbidden unless prior written permission is
 * obtained from Adobe.
 * *****************************************************************************
 */

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from 'redux/store';

export enum TemplateRailActionState {
  TemplateOverview,
  TemplateMetadata,
  Comments,
}

export interface DEPRECATEDTemplateState {
  templateDetailRailState: TemplateRailActionState;
}

export const initialState: DEPRECATEDTemplateState = {
  templateDetailRailState: TemplateRailActionState.TemplateMetadata,
};

export const DEPRECATEDtemplatesSlice = createSlice({
  name: '__DEPRECATED_templates',
  initialState,
  reducers: {
    setTemplateDetailRailState: (
      state,
      action: PayloadAction<TemplateRailActionState>,
    ) => {
      state.templateDetailRailState = action.payload;
    },
  },
});

export const { setTemplateDetailRailState } = DEPRECATEDtemplatesSlice.actions;

export const selectTemplateDetailRailState = (state: RootState) =>
  state.DEPRECATEDtemplates.templateDetailRailState;

export default DEPRECATEDtemplatesSlice.reducer;
