/**
 * *****************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2022 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Adobe and its suppliers, if any. The intellectual and technical concepts
 * contained herein are proprietary to Adobe and its suppliers and are
 * protected by all applicable intellectual property laws, including trade
 * secret and copyright laws. Dissemination of this information or reproduction
 * of this material is strictly forbidden unless prior written permission is
 * obtained from Adobe.
 * *****************************************************************************
 */

import React, { ReactChild, ReactElement } from 'react';

import { Button, Flex, Text, View } from '@adobe/react-spectrum';
import Close from '@spectrum-icons/workflow/Close';

import bulkStyles from '../CardView/WorkflowActionsBar.module.scss';

type BulkActionBarProps = {
  selected: Set<string>;
  onClear: () => void;
  children: ReactChild | ReactChild[];
};

export default function BulkActionBar({
  selected,
  onClear,
  children,
}: BulkActionBarProps): ReactElement {
  return (
    <View
      paddingX="size-300"
      paddingY="size-100"
      position="absolute"
      top="size-0"
      left="size-0"
      right="size-0"
      borderTopStartRadius="small"
      borderTopEndRadius="small"
      zIndex={2}
      UNSAFE_className={`${bulkStyles.base} ${
        selected.size ? bulkStyles.showing : ''
      }`}
    >
      <Flex alignItems="center">
        <Button
          variant="overBackground"
          isQuiet
          minWidth="size-0"
          onPress={onClear}
          UNSAFE_className={bulkStyles.closeIcon}
        >
          <Close size="S" />
        </Button>
        <View flex={1} paddingStart="size-50">
          <Text UNSAFE_className={bulkStyles.selectCount}>
            {selected.size} selected
          </Text>
        </View>
        <View>{children}</View>
      </Flex>
    </View>
  );
}
