/**
 * *****************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2022 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Adobe and its suppliers, if any. The intellectual and technical concepts
 * contained herein are proprietary to Adobe and its suppliers and are
 * protected by all applicable intellectual property laws, including trade
 * secret and copyright laws. Dissemination of this information or reproduction
 * of this material is strictly forbidden unless prior written permission is
 * obtained from Adobe.
 * *****************************************************************************
 */

import { useCallback } from 'react';

import { CCXConfigOption } from 'model/ccxConfig/CCXConfig';

type UseTransformedHookProps<T> = {
  source: T | undefined;
  options: CCXConfigOption[] | undefined;
  onChange: (value: T) => void;
};

type UseTransformedHookResult<T> = {
  transformedOptions: string[] | undefined;
  target: T | undefined;
  onChange: (value: T) => void;
};

function getFormValueFromLabel(
  value: string | undefined,
  options: CCXConfigOption[] | undefined,
): string | undefined {
  const matchingOption = options?.find((option) => option.value === value);

  if (matchingOption) {
    return matchingOption.label;
  }

  return value;
}

function getFormLabelFromValue(
  label: string | undefined,
  options: CCXConfigOption[] | undefined,
): string | undefined {
  const matchingOption = options?.find((option) => option.label === label);

  if (matchingOption) {
    return matchingOption.value;
  }

  return label;
}

export function useTransformedStringValue({
  source,
  options,
  onChange,
}: UseTransformedHookProps<string>): UseTransformedHookResult<string> {
  const transformedValue = getFormValueFromLabel(source, options);
  const handleOnChange = useCallback(
    (label: string) => {
      const newValue = getFormLabelFromValue(label, options);
      onChange(newValue ?? label);
    },
    [onChange, options],
  );

  return {
    transformedOptions: options?.map((option) => option.label),
    target: transformedValue,
    onChange: handleOnChange,
  };
}

export function useTransformedArrayValue({
  source,
  options,
  onChange,
}: UseTransformedHookProps<string[]>): UseTransformedHookResult<string[]> {
  const transformedValues = source?.map((value) => {
    const label = getFormValueFromLabel(value, options);

    return label ?? value;
  });
  const handleOnChange = useCallback(
    (labels: string[]) => {
      const newValue = labels.map((label) => {
        const value = getFormLabelFromValue(label, options);

        return value ?? label;
      });

      onChange(newValue);
    },
    [onChange, options],
  );

  return {
    transformedOptions: options?.map((option) => option.label),
    target: transformedValues,
    onChange: handleOnChange,
  };
}
