/**
 * *****************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2022 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Adobe and its suppliers, if any. The intellectual and technical concepts
 * contained herein are proprietary to Adobe and its suppliers and are
 * protected by all applicable intellectual property laws, including trade
 * secret and copyright laws. Dissemination of this information or reproduction
 * of this material is strictly forbidden unless prior written permission is
 * obtained from Adobe.
 * *****************************************************************************
 */

import React, { ReactChild, ReactElement } from 'react';

import { Flex, View } from '@adobe/react-spectrum';

type PaneProps = {
  children: ReactChild | ReactChild[];
};

export default function Pane({ children }: PaneProps): ReactElement {
  return (
    <View
      backgroundColor="static-white"
      borderStartColor="static-gray-300"
      borderStartWidth="thin"
      borderTopColor="static-gray-400"
      borderTopWidth="thin"
      position="relative"
      padding="size-250"
      width="size-5000"
      zIndex={3}
    >
      <Flex direction="column" height="100%" width="100%">
        <>
          <View
            UNSAFE_style={{
              backgroundImage:
                'linear-gradient(to left, rgba(51, 51, 51, 0.15), rgba(255, 255, 255, 0))',
              pointerEvents: 'none',
              transition: 'opacity 0.125s ease-in-out',
            }}
            position="absolute"
            top="size-0"
            bottom="size-0"
            right="100%"
            width="size-200"
          />
          <View
            backgroundColor="blue-500"
            height="size-50"
            left="size-0"
            position="absolute"
            right="size-0"
            top="size-0"
          />

          {children}
        </>
      </Flex>
    </View>
  );
}
