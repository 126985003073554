/**
 * *****************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2022 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Adobe and its suppliers, if any. The intellectual and technical concepts
 * contained herein are proprietary to Adobe and its suppliers and are
 * protected by all applicable intellectual property laws, including trade
 * secret and copyright laws. Dissemination of this information or reproduction
 * of this material is strictly forbidden unless prior written permission is
 * obtained from Adobe.
 * *****************************************************************************
 */

import React, { ReactElement } from 'react';

import { Flex, Text, View } from '@adobe/react-spectrum';

import styles from './BaseCard.module.scss';

type BaseCardSubtitleProps = {
  subtitle: string;
};

export default function BaseCardSubtitle({
  subtitle,
}: BaseCardSubtitleProps): ReactElement {
  return (
    <Flex UNSAFE_className={styles.content} direction="column">
      <Flex alignItems="center" UNSAFE_className={styles.subtitle}>
        <View
          flex
          overflow="hidden"
          UNSAFE_style={{
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
          }}
        >
          <Text>{subtitle}</Text>
        </View>
      </Flex>
    </Flex>
  );
}
