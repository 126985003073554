/**
 * *****************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2022 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Adobe and its suppliers, if any. The intellectual and technical concepts
 * contained herein are proprietary to Adobe and its suppliers and are
 * protected by all applicable intellectual property laws, including trade
 * secret and copyright laws. Dissemination of this information or reproduction
 * of this material is strictly forbidden unless prior written permission is
 * obtained from Adobe.
 * *****************************************************************************
 */

import csv from 'csv-stringify';

import { AssetMetadata } from 'hooks/useAssetWorkflowActions';
import {
  ApplicationMetadataFlatKey,
  ApplicationMetadataKey,
} from 'model/acp/ApplicationMetadataKey';
import { AssetClass } from 'model/acp/AssetClass';
import DCTitle from 'model/acp/DCTitle';
import LanguageAlternative from 'model/acp/LanguageAlternative';
import LocalizedTitle from 'model/acp/LocalizedTitle';
import { RepoMetadataKey } from 'model/acp/RepoMetadataKey';
import { WorkflowActionJobAsset } from 'redux/workflowActions/workflowActions.slice';

export const columnToMetadataKeyMap: { [key: string]: string } = {
  Pathname: 'path',
  Title: ApplicationMetadataKey.DCTitle,
  Topics: ApplicationMetadataKey.DCSubject,
  Entitlement: ApplicationMetadataKey.LicensingCategory,
  Animated: ApplicationMetadataKey.Animated,
  Language: ApplicationMetadataKey.Language,
  Priority: ApplicationMetadataKey.Priority,
  Hero: ApplicationMetadataKey.Hero,
  Traits: ApplicationMetadataKey.Traits,
};

function getValueForColumn(
  columnName: string,
  asset: WorkflowActionJobAsset<AssetMetadata>,
  assets: WorkflowActionJobAsset<AssetMetadata>[],
) {
  const isFolder =
    asset.body?.repoMetadata?.[RepoMetadataKey.AssetClass] ===
    AssetClass.Directory;
  const isCollection =
    asset.body?.repoMetadata?.[RepoMetadataKey.Name].endsWith('.collection');
  const key = columnToMetadataKeyMap[columnName] as string | undefined;

  // Standard Export Data
  if (key === ApplicationMetadataKey.DCTitle) {
    return `${new LocalizedTitle(asset.body?.applicationMetadata)}`;
  }

  if (
    [
      ApplicationMetadataKey.DCSubject,
      ApplicationMetadataKey.Hero,
      ApplicationMetadataKey.Language,
      ApplicationMetadataKey.Priority,
      ApplicationMetadataKey.Traits,
    ].includes(key as ApplicationMetadataKey)
  ) {
    return `${
      asset.body?.applicationMetadata?.[key as ApplicationMetadataFlatKey] ?? ''
    }`;
  }

  if (!isFolder && !isCollection) {
    if (
      key === ApplicationMetadataKey.Animated ||
      key === ApplicationMetadataKey.LicensingCategory
    ) {
      return `${
        asset.body?.applicationMetadata?.[key as ApplicationMetadataFlatKey] ??
        ''
      }`;
    }
  }

  if (columnName === 'Pathname') {
    return asset.assetPath;
  }

  // Extended Export Data
  if (columnName === 'Content Type') {
    if (isFolder) {
      return 'folder';
    }

    if (isCollection) {
      return 'collection';
    }

    return 'image';
  }

  if (columnName === 'Counts' && isFolder) {
    const directChildFiles = asset.body?.pageMetadata?.children.filter(
      (child) => child[RepoMetadataKey.AssetClass] !== AssetClass.Directory,
    ).length;
    const directChildFolders = asset.body?.pageMetadata?.children.filter(
      (child) => child[RepoMetadataKey.AssetClass] === AssetClass.Directory,
    ).length;
    const counts = [`assets:${directChildFiles}`];

    if (directChildFolders) {
      counts.push(`folders:${directChildFolders}`);
    }

    const childAssets = assets.filter(
      (childAsset) =>
        childAsset.assetPath.startsWith(asset.assetPath) &&
        childAsset !== asset,
    );
    const childFiles = childAssets.filter(
      (childAsset) =>
        childAsset.body?.repoMetadata?.[RepoMetadataKey.AssetClass] !==
        AssetClass.Directory,
    ).length;
    const childFolders = childAssets.filter(
      (childAsset) =>
        childAsset.body?.repoMetadata?.[RepoMetadataKey.AssetClass] ===
        AssetClass.Directory,
    ).length;

    if (childFiles) {
      counts.push(`folder assets:${childFiles}`);
    }

    if (childFolders) {
      counts.push(`subfolders:${childFolders}`);
    }

    return counts.join(', ');
  }

  if (
    columnName === 'Published' &&
    asset.body?.applicationMetadata?.[ApplicationMetadataKey.PublishedAssetId]
  ) {
    return `Yes (v: ${
      asset.body.applicationMetadata[ApplicationMetadataKey.PublishedVersion]
    })`;
  }

  if (columnName === 'Size' && !isFolder && !isCollection) {
    return `${asset.body?.repoMetadata?.[RepoMetadataKey.ImageWidth] ?? '?'}x${
      asset.body?.repoMetadata?.[RepoMetadataKey.ImageLength] ?? '?'
    }`;
  }

  if (
    columnName === 'Title All Languages' &&
    asset.body?.applicationMetadata?.[ApplicationMetadataKey.DCTitle]
  ) {
    return Object.entries(
      asset.body.applicationMetadata[ApplicationMetadataKey.DCTitle] as DCTitle,
    )
      .reduce((titles: string[], [titleKey, titleEntry]) => {
        if (titleKey === '@type') {
          return titles;
        }

        const langAlt = titleEntry as LanguageAlternative;
        const locale = langAlt['@language'];
        const translatedTitle = langAlt['@value'];

        return [...titles, `${locale}-> ${translatedTitle}`];
      }, [])
      .join('\n');
  }

  if (columnName === 'Suggested Title') {
    if (asset.body?.applicationMetadata?.[ApplicationMetadataKey.DCTitle]) {
      return `${new LocalizedTitle(asset.body.applicationMetadata)}`;
    }

    if (asset.body?.repoMetadata?.[RepoMetadataKey.Name]) {
      let suggestedTitle = asset.body.repoMetadata[
        RepoMetadataKey.Name
      ] as string;

      if (isFolder) {
        const lastDot = suggestedTitle.lastIndexOf('.');

        if (lastDot !== -1) {
          suggestedTitle = suggestedTitle.substring(0, lastDot);
        }
      }

      suggestedTitle = suggestedTitle
        .replace(/[-_.]/gi, ' ')
        .replace(/ +/g, ' ')
        .trim();
      suggestedTitle = suggestedTitle
        .split(' ')
        .map((chunk: string) => chunk[0].toUpperCase() + chunk.substring(1))
        .join(' ');

      return suggestedTitle;
    }
  }

  return '';
}

export function buildCSVExport(
  columnNames: string[],
  assets: WorkflowActionJobAsset<AssetMetadata>[],
) {
  return assets.map((asset) =>
    columnNames.reduce(
      (row: string[], column: string) => [
        ...row,
        getValueForColumn(column, asset, assets),
      ],
      [],
    ),
  );
}

export function formatCSV(
  columnNames: string[],
  data: string[][],
): Promise<string> {
  const rows: string[][] = [columnNames, ...data];

  return new Promise((resolve, reject) => {
    csv(rows, (error, output) => {
      if (error) {
        reject(error);
      } else {
        resolve(output);
      }
    });
  });
}
