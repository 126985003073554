/**
 * *****************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2022 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Adobe and its suppliers, if any. The intellectual and technical concepts
 * contained herein are proprietary to Adobe and its suppliers and are
 * protected by all applicable intellectual property laws, including trade
 * secret and copyright laws. Dissemination of this information or reproduction
 * of this material is strictly forbidden unless prior written permission is
 * obtained from Adobe.
 * *****************************************************************************
 */

import { useEffect, useRef, useState } from 'react';

import { AdobeIMS } from '@identity/imslib';

import { fetchCPUserRole } from '__DEPRECATED_DO_NOT_USE_OR_YOU_WILL_BE_FIRED/redux/user.thunks';

import useOrgAdmins from 'hooks/useOrgAdmins';
import { useAppDispatch } from 'redux/hooks';
import { ACPUserRole, setACPUserRole, userSignIn } from 'redux/user/user.slice';

import useConstant from '../useConstant';

export default function useAdobeIMS(): AdobeIMS | undefined {
  const dispatch = useAppDispatch();
  const { clientId, imsEnvironment, defaultLanguage } = useConstant();
  const [isReady, setIsReady] = useState<boolean>(false);
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
  const [isComplete, setIsComplete] = useState<boolean>(false);
  const {
    isLoading: isOrgAdminsLoading,
    result: orgAdmins,
    error: orgAdminsError,
  } = useOrgAdmins({ enabled: isComplete });
  const adobeIMSRef = useRef<AdobeIMS>(
    new AdobeIMS({
      scope: 'AdobeID,openid,creative_cloud,ab.manage',
      client_id: clientId,
      locale: defaultLanguage,
      environment: imsEnvironment,
      onReady: (): void => setIsReady(true),
      onAccessToken: (): void => setIsAuthenticated(true),
      onAccessTokenHasExpired: (): void => setIsAuthenticated(false),
      onReauthAccessToken: (): void => setIsAuthenticated(true),
      onError: (): void => setIsAuthenticated(false),
    }),
  );

  adobeIMSRef.current.initialize();

  if (isReady && !adobeIMSRef.current.isSignedInUser()) {
    adobeIMSRef.current.signIn();
  }

  useEffect(() => {
    if (isComplete && !isOrgAdminsLoading && orgAdmins) {
      (async (): Promise<void> => {
        const profile = await adobeIMSRef.current.getProfile();
        const isAdmin =
          orgAdmins.find((user) => user.id === profile.userId) !== undefined;
        dispatch(
          setACPUserRole(isAdmin ? ACPUserRole.admin : ACPUserRole.member),
        );
      })();
    } else if (isComplete && !isOrgAdminsLoading && orgAdminsError) {
      dispatch(setACPUserRole(ACPUserRole.member));
    } else if (!isComplete) {
      dispatch(setACPUserRole(ACPUserRole.member));
    }
  }, [dispatch, isComplete, isOrgAdminsLoading, orgAdmins, orgAdminsError]);

  useEffect(() => {
    if (isReady && !isComplete) {
      (async (): Promise<void> => {
        dispatch(userSignIn(await adobeIMSRef.current.getProfile()));
        dispatch(fetchCPUserRole());
      })();

      setIsComplete(isAuthenticated);
    }
  }, [
    adobeIMSRef,
    clientId,
    dispatch,
    isComplete,
    isReady,
    isAuthenticated,
    setIsComplete,
  ]);

  if (isComplete) {
    return adobeIMSRef.current;
  }

  return undefined;
}
