/**
 * *****************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2022 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Adobe and its suppliers, if any. The intellectual and technical concepts
 * contained herein are proprietary to Adobe and its suppliers and are
 * protected by all applicable intellectual property laws, including trade
 * secret and copyright laws. Dissemination of this information or reproduction
 * of this material is strictly forbidden unless prior written permission is
 * obtained from Adobe.
 * *****************************************************************************
 */

import React, { useCallback, useEffect, useState } from 'react';

import Search from '@react/react-spectrum/Search';

import { updateQuery } from '__DEPRECATED_DO_NOT_USE_OR_YOU_WILL_BE_FIRED/redux/filter/filter.slice';

import { useAppDispatch } from 'redux/hooks';

import styles from './SearchBar.module.scss';

type SearchBarProps = {
  defaultValue: string;
};

const SearchBar: React.FC<SearchBarProps> = ({
  defaultValue,
}: SearchBarProps) => {
  const dispatch = useAppDispatch();
  const [value, setValue] = useState('');

  useEffect(() => {
    // display an empty string instead of '*'
    setValue(defaultValue === '*' ? '' : defaultValue);
  }, [defaultValue]);

  useEffect(
    () => () => {
      // reset query when component unmounts
      dispatch(updateQuery('*'));
    },
    [dispatch],
  );

  const onSubmit = useCallback(
    (searchValue: string) => dispatch(updateQuery(searchValue)),
    [dispatch],
  );

  function onChange(val: string): void {
    setValue(val);

    if (val === '') {
      onSubmit(val);
    }
  }

  return (
    <div className={styles.searchBar}>
      <Search
        aria-label="Search Bar"
        onSubmit={onSubmit}
        value={value}
        // eslint-disable-next-line react/jsx-no-bind
        onChange={onChange}
        placeholder="Search"
      />
    </div>
  );
};

export default SearchBar;
