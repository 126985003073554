/**
 * *****************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2022 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Adobe and its suppliers, if any. The intellectual and technical concepts
 * contained herein are proprietary to Adobe and its suppliers and are
 * protected by all applicable intellectual property laws, including trade
 * secret and copyright laws. Dissemination of this information or reproduction
 * of this material is strictly forbidden unless prior written permission is
 * obtained from Adobe.
 * *****************************************************************************
 */

import { useEffect, useMemo, useState } from 'react';
import { useQueries, UseQueryResult } from 'react-query';

import Axios, { AxiosError, AxiosResponse } from 'axios';
import { first } from 'lodash';

import { ACPLink } from 'model/acp/ACPLink';
import { useAppSelector } from 'redux/hooks';
import { selectACPRepoId } from 'redux/user/user.slice';
import { getACPLinkUrl } from 'utils/acp/acpLinks';
import parseLinkHeader from 'utils/parseLinkHeader';

import useConstant from '../useConstant';

export type ACPError = {
  code: string;
  message: string;
};

type ACPLinkRecord = {
  linkUrl: string | undefined;
  assetId: string | undefined;
};

type SingleACPLinkHookResult = {
  errors: ACPError[] | undefined;
  isLoading: boolean;
  results: ACPLinkRecord | undefined;
};

type MultipleACPLinkHookResult = {
  errors: ACPError[] | undefined;
  isLoading: boolean;
  results: Record<string, ACPLinkRecord | undefined> | undefined;
};

/**
 * 10 Minutes in milliseconds. This is just a reasonable guess, but I
 * wouldn't expect the link headers for a given asset to change all that
 * often (if ever) on the server.
 */
const QUERY_STALE_TIME = 600000;

type SingleUseACPLinkProps = {
  acpLink: ACPLink;
  path: string;
  paths?: never;
  enabled?: boolean;
  options?: { retries?: number | boolean };
};

type MultipleUseACPLinkProps = {
  acpLink: ACPLink;
  path?: never;
  paths: string[];
  enabled?: boolean;
  options?: { retries?: number | boolean };
};

/**
 * useACPLink - Fetches a specific link header from ACP.
 *
 * @param {ACPLink} acpLink - Desired link to fetch
 * @param {string} path - ACP asset path
 * @param {string[]} paths - ACP asset paths
 * @returns {ACPLinkHookResult}
 */
function useACPLink(args: SingleUseACPLinkProps): SingleACPLinkHookResult;
function useACPLink(args: MultipleUseACPLinkProps): MultipleACPLinkHookResult;
function useACPLink(
  args: SingleUseACPLinkProps | MultipleUseACPLinkProps,
): SingleACPLinkHookResult | MultipleACPLinkHookResult;
function useACPLink({
  acpLink,
  path: acpPath,
  paths: acpPaths,
  enabled = true,
  options: { retries } = {},
}: SingleUseACPLinkProps | MultipleUseACPLinkProps):
  | SingleACPLinkHookResult
  | MultipleACPLinkHookResult {
  const [results, setResults] = useState<
    SingleACPLinkHookResult | MultipleACPLinkHookResult
  >({
    isLoading: false,
    results: undefined,
    errors: undefined,
  });
  const acpRepoId = useAppSelector(selectACPRepoId);
  const { acpBasePath, url } = useConstant();
  const queryPaths: string[] = useMemo(() => {
    let query: string[] = [];

    if (acpPaths !== undefined) {
      query = acpPaths;
    } else if (acpPath !== undefined) {
      query = [acpPath];
    }

    return query.filter((queryPath) => queryPath !== '');
  }, [acpPath, acpPaths]);
  const queries = useQueries(
    queryPaths.map((path) => {
      let assetPath = path;

      if (!path.startsWith(acpBasePath.assets)) {
        const directorySubstring = path.substring(
          path.indexOf(acpBasePath.wolverineAssets) +
            acpBasePath.wolverineAssets.length,
        );

        if (
          !(
            directorySubstring.startsWith('rejected') ||
            directorySubstring.startsWith('approved') ||
            directorySubstring.startsWith('pending')
          )
        ) {
          assetPath = `${acpBasePath.assets}${path}`;
        }
      }

      const requestUrl = getACPLinkUrl(assetPath, acpRepoId, url.platformBase);

      return {
        queryKey: ['ACPLink', assetPath, acpRepoId],
        queryFn: (): Promise<AxiosResponse> => Axios.head(requestUrl),
        staleTime: QUERY_STALE_TIME,
        enabled,
        retry: retries,
      };
    }),
  );
  const hasErrors = queries.some((query) => query.error !== null);
  const isLoading = queries.some((query) => query.isFetching);

  useEffect(() => {
    if (queries.length === 0) {
      return setResults({
        errors: undefined,
        isLoading: false,
        results: undefined,
      });
    }

    // If only one path is provided
    if (acpPath !== undefined) {
      const singleQuery = first(queries) as UseQueryResult<AxiosResponse>;
      const response = singleQuery.data;

      if (singleQuery.isError) {
        const err: AxiosError = singleQuery.error as AxiosError;

        return setResults({
          errors: [
            {
              code: String(err.response?.status),
              message: err.message,
            },
          ],
          isLoading: singleQuery.isFetching,
          results: {
            assetId: singleQuery.data?.headers['asset-id'],
            linkUrl: undefined,
          },
        });
      }

      if (!response?.headers) {
        return setResults({
          errors: undefined,
          isLoading: singleQuery.isFetching,
          results: undefined,
        });
      }

      const links = parseLinkHeader(response.headers.link);

      return setResults({
        errors: undefined,
        isLoading: singleQuery.isFetching,
        results: {
          assetId: singleQuery.data?.headers['asset-id'],
          linkUrl: links[acpLink]?.url,
        },
      });
    }

    const isFetching = queries.some((query) => query.isFetching);
    const hookResult: MultipleACPLinkHookResult = {
      errors: undefined,
      isLoading: isFetching,
      results: undefined,
    };

    if (!isFetching && acpPaths) {
      hookResult.errors = queries.reduce((errors: ACPError[], query) => {
        if (!query.isError) {
          return errors;
        }

        const err: AxiosError = query.error as AxiosError;

        return [
          ...errors,
          {
            code: String(err.response?.status),
            message: err.message,
          },
        ];
      }, []);
      hookResult.results = queries.reduce<Record<string, ACPLinkRecord>>(
        (acc, query, index): Record<string, ACPLinkRecord> => {
          if (!query.data) {
            return acc;
          }

          const response = query.data as AxiosResponse;

          if (!response.headers) {
            return acc;
          }

          const path = acpPaths[index];
          const links = parseLinkHeader(response.headers.link);

          return {
            ...acc,
            [path]: {
              linkUrl: links[acpLink]?.url,
              assetId: response.headers['asset-id'],
            },
          };
        },
        {},
      );
    }

    return setResults(hookResult);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [acpLink, acpPath, acpPaths, hasErrors, isLoading]);

  return results;
}

export default useACPLink;
