/**
 * *****************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2022 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Adobe and its suppliers, if any. The intellectual and technical concepts
 * contained herein are proprietary to Adobe and its suppliers and are
 * protected by all applicable intellectual property laws, including trade
 * secret and copyright laws. Dissemination of this information or reproduction
 * of this material is strictly forbidden unless prior written permission is
 * obtained from Adobe.
 * *****************************************************************************
 */

import {
  error as openErrorToast,
  info as openInfoToast,
  success as openSuccessToast,
} from '@react/react-spectrum/Toast';
import _ from 'lodash';

import SparkSearch from '__DEPRECATED_DO_NOT_USE_OR_YOU_WILL_BE_FIRED/controllers/api/SparkSearch';
import { TemplateAsset } from '__DEPRECATED_DO_NOT_USE_OR_YOU_WILL_BE_FIRED/controllers/api/SparkSearchTypes';
import { replaceAssets } from '__DEPRECATED_DO_NOT_USE_OR_YOU_WILL_BE_FIRED/redux/assets/asset.slice';
import { TAsset } from '__DEPRECATED_DO_NOT_USE_OR_YOU_WILL_BE_FIRED/redux/assets/asset.types';
import { FilterTab } from '__DEPRECATED_DO_NOT_USE_OR_YOU_WILL_BE_FIRED/redux/filter/filter.slice';

import { addAlert, Alert } from 'redux/alert/alert.slice';
import { AppThunk } from 'redux/store';
import { CPUserRole, UserState } from 'redux/user/user.slice';

import {
  addDirtyAssetProp,
  clearDirtyAssetProps,
  removeDirtyAssetProp,
  setAsset,
} from './metadata.slice';
import { TAGS_VALUES_MAP } from './metadata.types';

function isValidTag(key: string, tag: string): boolean {
  return (
    TAGS_VALUES_MAP[key] === undefined ||
    (TAGS_VALUES_MAP[key] as any[]).includes(tag)
  );
}

function addOrRemoveDirtyProp(
  tab: FilterTab,
  key: string,
  updatedAsset: TemplateAsset,
): AppThunk {
  return (dispatch, getState) => {
    const { current } = getState().assets;
    const originalAsset = current.find(
      (asset: TAsset) => asset.id === updatedAsset.id,
    );

    if (!originalAsset) {
      return dispatch(addDirtyAssetProp(key));
    }

    // remove prop if the updatedAsset value is the same as the originalAsset value
    if (_.isEqual(originalAsset[key], updatedAsset[key])) {
      dispatch(removeDirtyAssetProp(key));
    } else {
      dispatch(addDirtyAssetProp(key));
    }

    return undefined;
  };
}

export function canEditMetadata(
  asset: TemplateAsset,
  userState: UserState,
): boolean {
  return (
    userState.cpRole === CPUserRole.curator ||
    (userState.profile !== undefined &&
      asset.owner.id === userState.profile.userId)
  );
}

export function addMetadataTag(
  tab: FilterTab,
  key: string,
  newTag: string,
): AppThunk {
  return (dispatch, getState) => {
    const { asset } = getState().metadata;
    const userState: UserState = getState().user;

    if (!asset || !Object.prototype.hasOwnProperty.call(asset, key)) {
      return;
    }

    if (!canEditMetadata(asset, userState)) {
      return;
    }

    if (!isValidTag(key, newTag)) {
      return;
    }

    // do not add duplicate tags
    if (!asset[key].includes(newTag)) {
      const updatedAsset = {
        ...asset,
        [key]: asset[key].concat(newTag),
      };

      dispatch(setAsset(updatedAsset));
      dispatch(addOrRemoveDirtyProp(tab, key, updatedAsset));
    }
  };
}

export function removeMetadataTag(
  tab: FilterTab,
  key: string,
  removedTag: string,
): AppThunk {
  return (dispatch, getState) => {
    const { asset } = getState().metadata;
    const userState: UserState = getState().user;

    if (!asset || !Object.prototype.hasOwnProperty.call(asset, key)) {
      return;
    }

    if (!canEditMetadata(asset, userState)) {
      return;
    }

    const updatedAsset = {
      ...asset,
      [key]: asset[key].filter((tag: string) => tag !== removedTag),
    };

    dispatch(setAsset(updatedAsset));
    dispatch(addOrRemoveDirtyProp(tab, key, updatedAsset));
  };
}

export function setMetadataValue(
  tab: FilterTab,
  key: string,
  value: string,
): AppThunk {
  return (dispatch, getState) => {
    const { asset } = getState().metadata;
    const userState: UserState = getState().user;

    if (!asset || !Object.prototype.hasOwnProperty.call(asset, key)) {
      return;
    }

    if (!canEditMetadata(asset, userState)) {
      return;
    }

    const updatedAsset = {
      ...asset,
      [key]: value,
    };

    dispatch(setAsset(updatedAsset));
    dispatch(addOrRemoveDirtyProp(tab, key, updatedAsset));
  };
}

export function saveAsset(): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const { asset } = getState().metadata;
    const dirtyProps: string[] = getState().metadata.dirtyAssetProps;
    const userState: UserState = getState().user;

    if (!asset) {
      return;
    }

    // Server can only handle `en-gb` in isolation
    if (asset.locales.length > 1 && asset.locales.includes('en-gb')) {
      openErrorToast(
        'The locale "UK English (en-GB)" must be used in isolation',
      );

      return;
    }

    try {
      // only curators and the asset owner are allowed to save changes
      if (!canEditMetadata(asset, userState)) {
        dispatch(clearDirtyAssetProps());
        throw new Error('You do not have curator access to update this asset');
      }

      // if dirtyProps is empty, we don't have any changes to save
      if (dirtyProps.length === 0) {
        openInfoToast('No new changes', { closable: false, timeout: 1000 });

        return;
      }

      await SparkSearch.instance.updateAssetMetadata(asset);

      // triggers Toast
      openSuccessToast('Saved', { closable: false, timeout: 1000 });

      // save asset in the store so that we always have the latest changes
      dispatch(replaceAssets([asset]));

      // reset dirtyAssetProps since the updated asset has been saved
      dispatch(clearDirtyAssetProps());
    } catch (error) {
      const errorAlert: Alert = {
        type: 'error',
        id: `SparkSearch-updateAssetMetadata-${asset.id}`,
        title: 'Spark Search: Save Asset Failed',
        content: `${(error as Error).message} (id: ${asset.id})`,
      };

      dispatch(addAlert(errorAlert));
    }
  };
}

export function alertUnsavedMetadataChanges(
  tab: FilterTab,
  onContinue: () => void,
): AppThunk {
  return (dispatch, getState) => {
    const updatedAsset: TemplateAsset | undefined = getState().metadata.asset;
    const userState: UserState = getState().user;

    // only curators and the asset owner are allowed to save changes
    if (!updatedAsset || !canEditMetadata(updatedAsset, userState)) {
      return onContinue();
    }

    const { dirtyAssetProps } = getState().metadata;

    // if dirtyAssetProps is empty, we don't have any unsaved changes
    if (dirtyAssetProps.length === 0) {
      return onContinue();
    }

    const confirmationAlert: Alert = {
      type: 'confirmation',
      id: `UnsavedChanges-${updatedAsset.id}`,
      title: 'You have unsaved changes',
      content:
        'Your changes will not be saved if you move away from this form. Do you want to save before continuing?',
      cancelLabel: 'Keep Editing',
      secondaryLabel: 'Discard',
      confirmLabel: 'Save + Continue',
      onConfirm: (label: 'primary' | 'secondary') => {
        if (label === 'primary') {
          dispatch(saveAsset());
        } else {
          dispatch(clearDirtyAssetProps());
        }

        onContinue();
      },
    };

    dispatch(addAlert(confirmationAlert));

    return undefined;
  };
}
