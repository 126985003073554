/**
 * *****************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2022 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Adobe and its suppliers, if any. The intellectual and technical concepts
 * contained herein are proprietary to Adobe and its suppliers and are
 * protected by all applicable intellectual property laws, including trade
 * secret and copyright laws. Dissemination of this information or reproduction
 * of this material is strictly forbidden unless prior written permission is
 * obtained from Adobe.
 * *****************************************************************************
 */

import React, { useCallback, useMemo, useState } from 'react';

import { MenuItem } from '@react/react-spectrum/Menu';

import {
  ContentType,
  FilterOption,
  FilterTab,
  updateOrder,
} from '__DEPRECATED_DO_NOT_USE_OR_YOU_WILL_BE_FIRED/redux/filter/filter.slice';

import { useAppDispatch, useAppSelector } from 'redux/hooks';

import styles from './FilterSelectList.module.scss';

const CREATED_ORDER_OPTIONS: FilterOption[] = [
  {
    label: 'Newest to Oldest',
    value: '-created',
  },
  {
    label: 'Oldest to Newest',
    value: 'created',
  },
];
const CREATED_ORDER_OPTIONS_ASSETS: FilterOption[] = [
  {
    label: 'Newest to Oldest',
    value: '-createDate',
  },
  {
    label: 'Oldest to Newest',
    value: 'createDate',
  },
];
const REMIX_ORDER_OPTIONS: FilterOption[] = [
  {
    label: 'Most Remixed',
    value: '-remixCount',
  },
  {
    label: 'Least Remixed',
    value: 'remixCount',
  },
];
const OrderSelectList: React.FC = () => {
  const dispatch = useAppDispatch();
  // const { contentType } = useParams() as { contentType : ContentType };
  const contentType: ContentType = useAppSelector(
    (state) => state.filters.contentType,
  );
  const mode: FilterTab = useAppSelector((state) => state.filters.mode);
  const [selected, setSelected] = useState({
    [ContentType.templates]: CREATED_ORDER_OPTIONS[0].value,
    [ContentType.assets]: CREATED_ORDER_OPTIONS_ASSETS[0].value,
  });
  const options: FilterOption[] = useMemo(() => {
    const isAssets = contentType === ContentType.assets;

    if (isAssets) {
      return [...CREATED_ORDER_OPTIONS_ASSETS];
    }

    if (mode === FilterTab.review) {
      return [...CREATED_ORDER_OPTIONS];
    }

    return [...CREATED_ORDER_OPTIONS, ...REMIX_ORDER_OPTIONS];
  }, [mode, contentType]);
  /*
   *useEffect(() => {
   *    const value = selected[contentType];
   *    dispatch(updateOrder(value));
   *    const newSelected = {
   *        ...selected,
   *        [contentType]: value
   *    }
   *    setSelected(newSelected);
   *}, [dispatch, contentType, selected]);
   */
  const handleSelect = useCallback(
    (value: string) => {
      dispatch(updateOrder(value));
      const newSelected = {
        ...selected,
        [contentType]: value,
      };
      setSelected(newSelected);
    },
    [dispatch, contentType, selected],
  );

  return (
    <div data-testid="order-select-list" className={styles.filterSelectList}>
      <div data-testid="order-options" className={styles.options}>
        {options.map((option: FilterOption) => (
          <MenuItem
            key={option.label}
            className={styles.option}
            value={option.value}
            onClick={() => handleSelect(option.value)}
            selected={selected[contentType] === option.value}
          >
            {option.label}
          </MenuItem>
        ))}
      </div>
    </div>
  );
};

export default OrderSelectList;
