/**
 * *****************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2022 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Adobe and its suppliers, if any. The intellectual and technical concepts
 * contained herein are proprietary to Adobe and its suppliers and are
 * protected by all applicable intellectual property laws, including trade
 * secret and copyright laws. Dissemination of this information or reproduction
 * of this material is strictly forbidden unless prior written permission is
 * obtained from Adobe.
 * *****************************************************************************
 */

import React, {
  ReactElement,
  SyntheticEvent,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { Helmet } from 'react-helmet';

import {
  Content,
  Flex,
  Heading,
  IllustratedMessage,
  View,
} from '@adobe/react-spectrum';
import NotFound from '@spectrum-icons/illustrations/NotFound';

import Pane from 'components/@spectrum/Pane';
import FolderMetadataForm from 'components/AssetMetadataForm/FolderMetadataForm';
import CardGrid from 'components/CardView/CardGrid';
import AssetInfo from 'components/DetailView/AssetInfo';
import Loading from 'components/Loading';
import useApplicationMetadata from 'hooks/acp/useApplicationMetadata';
import useAssetWorkflowActions from 'hooks/useAssetWorkflowActions';
import useCurrentAssetByPath from 'hooks/useCurrentAssetByPath';
import useWorkflow from 'hooks/useWorkflow';
import { AssetClass } from 'model/acp/AssetClass';
import { ChildAsset } from 'model/acp/ChildAsset';
import Directory from 'model/acp/Directory';
import { RepoMetadataKey } from 'model/acp/RepoMetadataKey';
import { AssetActionDomain } from 'model/app/AssetActionDomain';
import { DocumentRailPane } from 'model/DocumentRailPane';
import { DocumentView } from 'model/DocumentView';
import { ValidationFieldErrors } from 'model/errors/ValidationError';
import { FolderMetadataModel } from 'model/metadata/FolderMetadataModel';
import {
  clearDocumentRailPane,
  clearSelectedAssetPaths,
  toggleSelectedAssetsByPath,
} from 'redux/explorer/explorer.slice';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { mapFromFolderMetadata, validate } from 'utils/metadata/folderMetadata';

import CardViewHeader from './CardViewHeader';
import LoadingCard from './LoadingCard';
import WorkflowActionsBar from './WorkflowActionsBar';

type CardViewProps = {
  directory: Directory | undefined;
  getNextPage: (() => void) | undefined;
  hasNextPage: boolean | undefined;
  isLoadingMore: boolean;
};

export default function CardView({
  directory,
  getNextPage,
  hasNextPage,
  isLoadingMore,
}: CardViewProps): ReactElement {
  // Global state
  const { selectableAssets } = useWorkflow({
    actionDomain: AssetActionDomain.Bulk,
    actionContext: 'folder',
  });
  const assetWorkflowActions = useAssetWorkflowActions();
  const { results: applicationMetadata } = useApplicationMetadata({
    path: directory?.[RepoMetadataKey.Path] ?? '',
    enabled: directory !== undefined,
  });
  // Popover detail
  const { documentRailPane, selectedAssetPaths } = useAppSelector(
    (state) => state.explorer,
  );
  const dispatch = useAppDispatch();
  const closeDetailView = useCallback(
    () => dispatch(clearDocumentRailPane()),
    [dispatch],
  );
  // Asset actions
  const { setCurrentAssetByPath } = useCurrentAssetByPath();
  const unselectAll = useCallback(
    () => dispatch(clearSelectedAssetPaths()),
    [dispatch],
  );
  const onAssetSelect = useCallback(
    (asset: ChildAsset) => {
      if (!selectableAssets.has(asset[RepoMetadataKey.AssetClass])) {
        return;
      }

      const assetPath = asset[RepoMetadataKey.Path];
      dispatch(toggleSelectedAssetsByPath(assetPath));
    },
    [dispatch, selectableAssets],
  );
  const onAssetClick = useCallback(
    (asset: ChildAsset) => {
      const isDirectory =
        asset[RepoMetadataKey.AssetClass] === AssetClass.Directory;
      const assetPath = asset[RepoMetadataKey.Path];

      unselectAll();

      setCurrentAssetByPath(assetPath, {
        documentView: isDirectory ? DocumentView.Card : DocumentView.Detail,
      });
    },
    [setCurrentAssetByPath, unselectAll],
  );
  const canLoadMore = useCallback(
    (isVisible: boolean) => {
      if (isVisible && !isLoadingMore && hasNextPage && getNextPage) {
        getNextPage();
      }
    },
    [isLoadingMore, hasNextPage, getNextPage],
  );
  const onClickOff = useCallback(
    (
      event: SyntheticEvent<HTMLDivElement, MouseEvent | KeyboardEvent>,
    ): void => {
      event.stopPropagation();

      unselectAll();
    },
    [unselectAll],
  );

  useEffect(() => {
    closeDetailView();
  }, [selectedAssetPaths, closeDetailView]);

  // Handle save
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const saveFolderMetadata = useCallback(
    async (
      model: FolderMetadataModel,
    ): Promise<ValidationFieldErrors | undefined> => {
      if (!directory) {
        return undefined;
      }

      setIsSaving(true);

      // Validate model fields
      const validationErrors = validate(model);

      if (validationErrors) {
        setIsSaving(false);

        return validationErrors;
      }

      const updatedMetadata = mapFromFolderMetadata(model, applicationMetadata);
      await assetWorkflowActions.updateMetadata(
        directory[RepoMetadataKey.Path],
        updatedMetadata,
      );

      setIsSaving(false);

      return undefined;
    },
    [assetWorkflowActions, applicationMetadata, directory, setIsSaving],
  );
  const isLoading = directory === undefined;
  const hasChildren = directory?.children.length !== 0;

  return (
    <>
      <Helmet>
        {directory ? (
          <title>{directory[RepoMetadataKey.Name]} | Tempo</title>
        ) : null}
      </Helmet>

      <View height="100%">
        {directory ? (
          <Pane
            isOpen={documentRailPane === DocumentRailPane.Details}
            onClose={closeDetailView}
          >
            <AssetInfo />
            <Flex flex minHeight="size-0">
              <FolderMetadataForm
                isSaving={isSaving}
                onSave={saveFolderMetadata}
                path={directory[RepoMetadataKey.Path]}
              />
            </Flex>
          </Pane>
        ) : null}
        {/* The <div> element has a child Card element that allows keyboard interaction */}
        {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
        <div
          onClick={onClickOff}
          onKeyPress={onClickOff}
          style={{
            height: '100%',
          }}
        >
          <Flex
            width="100%"
            height="100%"
            direction="column"
            justifyContent="start"
          >
            <View position="relative">
              <View paddingEnd="size-300" paddingStart="size-300">
                <CardViewHeader
                  directory={directory}
                  hasNextPage={hasNextPage}
                  isLoading={isLoading}
                />
              </View>
              <WorkflowActionsBar />
            </View>
            <View id="scrollPane" height="100%" overflow="auto">
              {isLoading || (!hasChildren && hasNextPage) ? (
                <View padding="size-300">
                  <Loading />

                  <LoadingCard isEnabled setIsVisible={canLoadMore} />
                </View>
              ) : null}
              {!isLoading && !hasNextPage && !hasChildren ? (
                <Flex justifyContent="space-around" width="100%" height="100%">
                  <View padding="size-300">
                    <IllustratedMessage>
                      <NotFound />
                      <Heading>No Assets</Heading>
                      <Content>This directory is empty</Content>
                    </IllustratedMessage>
                  </View>
                </Flex>
              ) : null}
              {directory && hasChildren ? (
                <CardGrid
                  assets={directory.children}
                  canLoadMore={canLoadMore}
                  onAssetClick={onAssetClick}
                  onAssetSelect={onAssetSelect}
                  selectedAssetPaths={selectedAssetPaths}
                />
              ) : null}
            </View>
          </Flex>
        </div>
      </View>
    </>
  );
}
