/**
 * *****************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2022 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Adobe and its suppliers, if any. The intellectual and technical concepts
 * contained herein are proprietary to Adobe and its suppliers and are
 * protected by all applicable intellectual property laws, including trade
 * secret and copyright laws. Dissemination of this information or reproduction
 * of this material is strictly forbidden unless prior written permission is
 * obtained from Adobe.
 * *****************************************************************************
 */

import React, { ReactElement } from 'react';

import Checkbox from '@react/react-spectrum/Checkbox';
import _ from 'lodash';

import TagInputSet from '__DEPRECATED_DO_NOT_USE_OR_YOU_WILL_BE_FIRED/components/TagInputSet';
import { ActionInfo } from '__DEPRECATED_DO_NOT_USE_OR_YOU_WILL_BE_FIRED/controllers/constants/StatusUtils';
import styles from '__DEPRECATED_DO_NOT_USE_OR_YOU_WILL_BE_FIRED/Detail/styles/Detail.module.scss';

import ApplicationMetadata from 'model/acp/ApplicationMetadata';

import LinkField, { LinkFieldProps } from './LinkField';
import PriorityField from './PriorityField';
import SelectField, { SelectFieldProps } from './SelectField';
import TextField from './TextField';

/**
 * Actions that will update the asset
 */
export interface IAuthorActions {
  checkUnsavedChanges(data: any): boolean;
  save(data: any): Promise<ActionInfo>;
  publish(): Promise<ActionInfo>;
  unpublish(): Promise<ActionInfo>;
}

/**
 * Fields that should not be modified and can link
 * to an external URL
 */
export type InfoField = LinkFieldProps;

/**
 * Editable fields that correspond to a type of field element
 */
export interface EditableField {
  propPath: string[];
  label: string;
  element: 'textfield' | 'tags-input' | 'checkbox' | 'select' | 'priorityfield';
  config?: { [key: string]: any };
  placeholder?: string;
}

type FormProps = {
  editable: EditableField[];
  info: InfoField[];
  metadata: ApplicationMetadata | Record<string, any>;
};

const emptyFunction = (): void => {};

export default function Form({
  metadata,
  info,
  editable,
}: FormProps): ReactElement {
  const getEditableField = (field: EditableField) => {
    const value = _.get(metadata, field.propPath);

    switch (field.element) {
      case 'priorityfield':
        return (
          <PriorityField
            key={field.label}
            label={field.label}
            value={value as number}
            setValue={emptyFunction}
          />
        );
      case 'textfield':
        return (
          <TextField
            key={field.label}
            label={field.label}
            value={(value as string) || ''}
            setValue={emptyFunction}
          />
        );
      case 'tags-input':
        return (
          <TagInputSet
            currentItems={value}
            disabled
            isSingleSelect={field.config?.isSingleSelect}
            key={field.label}
            pluralLabel={field.label}
            possibleItems={field.config?.options}
            setCurrentItems={emptyFunction}
            singularLabel={field.label}
          />
        );
      case 'checkbox':
        return (
          <Checkbox
            key={field.label}
            label={field.label}
            checked={(value as boolean) || false}
            onChange={emptyFunction}
          />
        );
      case 'select':
        return (
          <SelectField
            key={field.label}
            label={field.label}
            value={(value as string) || ''}
            setValue={emptyFunction}
            config={
              (field.config as SelectFieldProps['config'] | undefined) ?? {}
            }
            placeholder={field.placeholder}
          />
        );
      default:
        return null;
    }
  };

  return (
    <>
      <div className={styles.info}>
        {info.map((field: InfoField) => (
          <LinkField
            key={field.label}
            hideIfEmpty={field.hideIfEmpty}
            label={field.label}
            link={field.link}
            onClick={field.onClick}
            value={field.value}
          />
        ))}
      </div>
      <div className={styles.form}>
        {editable.map((field: EditableField) => getEditableField(field))}
      </div>
    </>
  );
}
