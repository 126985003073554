/**
 * *****************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2022 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Adobe and its suppliers, if any. The intellectual and technical concepts
 * contained herein are proprietary to Adobe and its suppliers and are
 * protected by all applicable intellectual property laws, including trade
 * secret and copyright laws. Dissemination of this information or reproduction
 * of this material is strictly forbidden unless prior written permission is
 * obtained from Adobe.
 * *****************************************************************************
 */

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { AppThunk, RootState } from 'redux/store';

const CLIENT_CACHE_KEY = 'tempo:client-cache';

export interface ClientCacheState {
  templateUpdateMap: Record<string, string>;
}

export const initialState: ClientCacheState = {
  templateUpdateMap: {},
};

const saveClientCacheToStorage = (state: ClientCacheState) => {
  window.localStorage.setItem(CLIENT_CACHE_KEY, JSON.stringify(state));
};

export const clientCacheSlice = createSlice({
  name: 'clientCache',
  initialState,
  reducers: {
    setAssetUpdated: (state, action: PayloadAction<string>) => {
      state.templateUpdateMap[action.payload] = new Date().toISOString();

      saveClientCacheToStorage(state);
    },
    setClientCache: (state, action: PayloadAction<ClientCacheState>) => {
      state.templateUpdateMap = action.payload.templateUpdateMap;
    },
  },
});

export const { setAssetUpdated, setClientCache } = clientCacheSlice.actions;

export const selectAssetUpdateDate = (assetId: string) => (state: RootState) =>
  state.clientCache.templateUpdateMap[assetId];

export const loadClientCacheFromStorage = (): AppThunk => async (dispatch) => {
  const possibleClientCache = window.localStorage.getItem(CLIENT_CACHE_KEY);

  if (possibleClientCache === null) {
    return undefined;
  }

  let clientCache;

  try {
    clientCache = JSON.parse(possibleClientCache);
  } catch (error) {
    return undefined;
  }

  return dispatch(setClientCache(clientCache));
};

export default clientCacheSlice.reducer;
