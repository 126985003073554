/**
 * *****************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2022 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Adobe and its suppliers, if any. The intellectual and technical concepts
 * contained herein are proprietary to Adobe and its suppliers and are
 * protected by all applicable intellectual property laws, including trade
 * secret and copyright laws. Dissemination of this information or reproduction
 * of this material is strictly forbidden unless prior written permission is
 * obtained from Adobe.
 * *****************************************************************************
 */

import React, { ReactElement } from 'react';

import BoxImport from '@spectrum-icons/workflow/BoxImport';
import CollectionAdd from '@spectrum-icons/workflow/CollectionAdd';
import FolderAdd from '@spectrum-icons/workflow/FolderAdd';
import UploadToCloud from '@spectrum-icons/workflow/UploadToCloud';

import { WorkflowAction } from './WorkflowAction';

export const WorkflowActionIconMap = new Map<WorkflowAction, ReactElement>([
  [WorkflowAction.CreateCollection, <CollectionAdd size="S" />],
  [WorkflowAction.CreateFolder, <FolderAdd size="S" />],
  [WorkflowAction.UploadFiles, <UploadToCloud size="S" />],
  [WorkflowAction.ImportMetadata, <BoxImport size="S" />],
]);
