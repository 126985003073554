/**
 * *****************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2022 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Adobe and its suppliers, if any. The intellectual and technical concepts
 * contained herein are proprietary to Adobe and its suppliers and are
 * protected by all applicable intellectual property laws, including trade
 * secret and copyright laws. Dissemination of this information or reproduction
 * of this material is strictly forbidden unless prior written permission is
 * obtained from Adobe.
 * *****************************************************************************
 */

export enum AssetMimeType {
  AccessControlPolicy = 'application/vnd.adobecloud.accesscontrolpolicy+json',
  Audio = 'audio/*',
  AudioDCX = 'ingredient/vnd.adobe.audio.content+dcx',
  AudioDCXSnapshot = 'ingredient/vnd.adobe.audio.content+dcxucf',
  Canvas = 'application/vnd.adobe.hz.canvas+dcx',
  Collection = 'application/vnd.adobe.light.collection+json',
  CSV = 'text/csv',
  Directory = 'application/vnd.adobecloud.directory+json',
  HTML = 'text/html',
  Image = 'image/*',
  Javascript = 'text/javascript',
  JPEG = 'image/jpeg',
  JSON = 'application/json',
  PNG = 'image/png',
  SVG = 'image/svg+xml',
  TemplateDCX = 'application/vnd.adobe.theo.document+dcx',
  TemplateDCXSnapshot = 'application/vnd.adobe.theo.document+dcxucf',
  TemplateShadowAsset = 'application/vnd.adobe.theo.shadowasset+json',
}
