/**
 * *****************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2022 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Adobe and its suppliers, if any. The intellectual and technical concepts
 * contained herein are proprietary to Adobe and its suppliers and are
 * protected by all applicable intellectual property laws, including trade
 * secret and copyright laws. Dissemination of this information or reproduction
 * of this material is strictly forbidden unless prior written permission is
 * obtained from Adobe.
 * *****************************************************************************
 */

import React, { ReactChild, ReactElement, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { Provider as ReduxProvider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';

import { lightTheme, Provider as V3Provider } from '@adobe/react-spectrum';
import Provider from '@react/react-spectrum/Provider';
import Axios from 'axios';

import useAdobeIMS from 'hooks/ims/useAdobeIMS';
import { useCCXPrivateConfig, useCCXPublicConfig } from 'hooks/useCCXConfig';
import useConstant from 'hooks/useConstant';
import DefaultLayout from 'layout/DefaultLayout';
import { loadClientCacheFromStorage } from 'redux/clientCache/clientCache.slice';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { store } from 'redux/store';
import { loadPreferencesFromStorage } from 'redux/user/user.slice';
import { NotFoundErrorScreen } from 'screens/errors';

import MainRouter from './MainRouter';

const DEFAULT_PAGE_TITLE = 'Tempo';

type ConfigLoaderProps = {
  children: ReactChild | ReactChild[];
};

function ConfigLoader({ children }: ConfigLoaderProps): ReactElement {
  const {
    isLoading: isLoadingPrivateCCXConfig,
    errors: ccxPrivateConfigErrors,
  } = useCCXPrivateConfig();
  const { isLoading: isLoadingPublicCCXConfig, errors: ccxPublicConfigErrors } =
    useCCXPublicConfig();

  if (ccxPrivateConfigErrors?.length || ccxPublicConfigErrors?.length) {
    return (
      <DefaultLayout shouldShowNavigation={false}>
        <NotFoundErrorScreen shouldShowHomeLink={false} />
      </DefaultLayout>
    );
  }

  if (isLoadingPrivateCCXConfig || isLoadingPublicCCXConfig) {
    return <></>;
  }

  return <>{children}</>;
}

/*
 * The Adobe IMS hook updates Redux meaning it must only be called in the tree
 * beneath a Redux provider. As a result, we move it into a separate component
 * in the same file to enforce this requirement.
 */
function Authenticated(): ReactElement {
  const { clientId } = useConstant();
  const adobeIMS = useAdobeIMS();
  const dispatch = useAppDispatch();
  const hasProfile = useAppSelector(
    (state) => state.user.profile !== undefined,
  );

  useEffect(() => {
    dispatch(loadClientCacheFromStorage());
    dispatch(loadPreferencesFromStorage());
  }, [dispatch]);

  useEffect(() => {
    if (adobeIMS?.isSignedInUser()) {
      const token = adobeIMS.getAccessToken()?.token;

      if (token) {
        Axios.defaults.headers.common.Authorization = `Bearer ${token}`;
        Axios.defaults.headers.common['x-api-key'] = clientId;
      }
    }
  }, [adobeIMS, clientId, dispatch]);

  if (!adobeIMS || !hasProfile) {
    return <></>;
  }

  return (
    <ConfigLoader>
      <BrowserRouter>
        <DefaultLayout shouldShowNavigation>
          <MainRouter />
        </DefaultLayout>
        <ReactQueryDevtools />
      </BrowserRouter>
    </ConfigLoader>
  );
}

export default function App(): ReactElement {
  return (
    <>
      <Helmet>
        <title>{DEFAULT_PAGE_TITLE}</title>
      </Helmet>

      <Provider theme="light" scale="medium">
        <V3Provider theme={lightTheme} colorScheme="light" scale="medium">
          <ReduxProvider store={store}>
            <QueryClientProvider client={new QueryClient()}>
              <Authenticated />
            </QueryClientProvider>
          </ReduxProvider>
        </V3Provider>
      </Provider>
    </>
  );
}
