/**
 * *****************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2022 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Adobe and its suppliers, if any. The intellectual and technical concepts
 * contained herein are proprietary to Adobe and its suppliers and are
 * protected by all applicable intellectual property laws, including trade
 * secret and copyright laws. Dissemination of this information or reproduction
 * of this material is strictly forbidden unless prior written permission is
 * obtained from Adobe.
 * *****************************************************************************
 */

import { TemplateAsset } from '__DEPRECATED_DO_NOT_USE_OR_YOU_WILL_BE_FIRED/controllers/api/SparkSearchTypes';

import { ApplicableRegion } from 'model/ApplicableRegion';

export function transformToTemporaryLocaleAsset(asset: TemplateAsset) {
  if (
    asset.applicableRegions &&
    asset.applicableRegions.length === 1 &&
    asset.applicableRegions[0] === ApplicableRegion.GB &&
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    asset.locales &&
    asset.locales.length === 1 &&
    asset.locales[0] === 'en-us'
  ) {
    return {
      ...asset,
      locales: ['en-gb'],
      applicableRegions: [ApplicableRegion.Global],
    };
  }

  return asset;
}
