/**
 * *****************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2022 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Adobe and its suppliers, if any. The intellectual and technical concepts
 * contained herein are proprietary to Adobe and its suppliers and are
 * protected by all applicable intellectual property laws, including trade
 * secret and copyright laws. Dissemination of this information or reproduction
 * of this material is strictly forbidden unless prior written permission is
 * obtained from Adobe.
 * *****************************************************************************
 */

import React, { useEffect, useMemo } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';

import { View } from '@adobe/react-spectrum';
import { Tab, TabList } from '@react/react-spectrum/TabList';

import FilterBar from '__DEPRECATED_DO_NOT_USE_OR_YOU_WILL_BE_FIRED/components/FilterBar';
import SearchAssetsBrowse from '__DEPRECATED_DO_NOT_USE_OR_YOU_WILL_BE_FIRED/components/SearchAssetsBrowse';
import TemplatesBrowse from '__DEPRECATED_DO_NOT_USE_OR_YOU_WILL_BE_FIRED/components/TemplatesBrowse';
import { SearchGrid } from '__DEPRECATED_DO_NOT_USE_OR_YOU_WILL_BE_FIRED/Grid';
import useGridTabs, {
  SearchTab,
} from '__DEPRECATED_DO_NOT_USE_OR_YOU_WILL_BE_FIRED/hooks/useGridTabs';
import { resetAssets } from '__DEPRECATED_DO_NOT_USE_OR_YOU_WILL_BE_FIRED/redux/assets/asset.slice';
import {
  ContentType,
  FilterTab,
  updateContentTypeAndMode,
  updateQuery,
} from '__DEPRECATED_DO_NOT_USE_OR_YOU_WILL_BE_FIRED/redux/filter/filter.slice';
import { alertUnsavedMetadataChanges } from '__DEPRECATED_DO_NOT_USE_OR_YOU_WILL_BE_FIRED/redux/metadata/metadata.thunks';

import {
  setTemplateDetailRailState,
  TemplateRailActionState,
} from 'redux/__DEPRECATED_templates/templates.slice';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import {
  doRefresh,
  ScreenView,
  setScreenView,
  setSelectionEnabled,
} from 'redux/screen/screen.slice';
import { initializeOwners } from 'redux/tempo/tempo.thunks';
import { selectIsAuthenticated } from 'redux/user/user.slice';

import ReviewWrapper from './ReviewWrapper';
import SearchWrapper from './SearchWrapper';

import styles from './styles/AssetsScreen.module.scss';

type AssetsFilterScreenProps = {
  tab: FilterTab;
  title: string;
};

const AssetsFilterScreen: React.FC<AssetsFilterScreenProps> = ({
  tab,
  title,
}: AssetsFilterScreenProps) => {
  const dispatch = useAppDispatch();
  const { contentType } = useParams() as { contentType: ContentType };
  const filters = useAppSelector((state) => state.filters);
  const view: ScreenView = useAppSelector((state) => state.screen.view);
  const isAuthenticated = useAppSelector(selectIsAuthenticated);
  const { gridTab, gridTabList, onGridTabChange } = useGridTabs(tab);
  const [queryParams] = useSearchParams();
  const queryId: string = queryParams.get('id') ?? '';
  const queryQ: string = queryParams.get('q') ?? ''; // tbd: filters?

  // initialize owners to use in FilterBar
  useEffect(() => {
    if (isAuthenticated) {
      dispatch(initializeOwners());
    }
  }, [isAuthenticated, dispatch]);

  useEffect(() => {
    dispatch(setScreenView(ScreenView.Grid));
    dispatch(setSelectionEnabled(false));
  }, [dispatch, tab, filters]);

  useEffect(() => {
    if (queryId || queryQ) {
      // timeout to avoid getting smushed by the initial on-load search
      window.setTimeout(() => {
        dispatch(updateQuery(queryId || queryQ));

        if (queryId) {
          dispatch(setScreenView(ScreenView.Detail));
        }
      }, 1000);
    }
  }, [dispatch, queryId, queryQ]);

  useEffect(() => {
    dispatch(
      updateContentTypeAndMode({
        newContentType: contentType,
        newMode: tab,
      }),
    );

    if (contentType === ContentType.templates) {
      onGridTabChange(SearchTab.Templates);
    } else {
      onGridTabChange(SearchTab.Assets);
    }

    return () => {
      dispatch(resetAssets());
      dispatch(setScreenView(ScreenView.Grid));
      dispatch(setSelectionEnabled(false));
    };
  }, [dispatch, tab, onGridTabChange, contentType]);

  const onViewChange = (newView: ScreenView) => {
    if (newView === ScreenView.Grid) {
      dispatch(
        alertUnsavedMetadataChanges(tab, () => {
          dispatch(setScreenView(newView));
          dispatch(setSelectionEnabled(false));
        }),
      );
    } else {
      dispatch(
        setTemplateDetailRailState(TemplateRailActionState.TemplateMetadata),
      );
      dispatch(setScreenView(newView));
    }
  };
  const onGridRefresh = () => {
    dispatch(doRefresh());
  };
  const Wrapper = useMemo(() => {
    if (tab === FilterTab.review) {
      return ReviewWrapper;
    }

    return SearchWrapper;
  }, [tab]);

  return (
    <div className={styles.assetsScreen}>
      <div className={styles.left}>
        <FilterBar title={title} />
      </div>
      <div className={styles.right}>
        {view === ScreenView.Grid ? (
          <View paddingX="30px" paddingTop="15px">
            <TabList onChange={onGridTabChange} selectedIndex={gridTab}>
              {gridTabList.map((gridTabListElement: string) => (
                <Tab key={gridTabListElement} label={gridTabListElement} />
              ))}
            </TabList>
          </View>
        ) : null}
        <Wrapper onViewChange={onViewChange} onRefresh={onGridRefresh}>
          {({ isCurator, onViewButtonClick }) => {
            if (view === ScreenView.Grid) {
              return <SearchGrid tab={tab} contentType={contentType} />;
            }

            if (contentType === ContentType.assets) {
              return <SearchAssetsBrowse />;
            }

            return (
              <TemplatesBrowse
                isCurator={isCurator}
                onViewButtonClick={onViewButtonClick}
                tab={tab}
              />
            );
          }}
        </Wrapper>
      </div>
    </div>
  );
};

export default AssetsFilterScreen;
