/**
 * *****************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2022 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Adobe and its suppliers, if any. The intellectual and technical concepts
 * contained herein are proprietary to Adobe and its suppliers and are
 * protected by all applicable intellectual property laws, including trade
 * secret and copyright laws. Dissemination of this information or reproduction
 * of this material is strictly forbidden unless prior written permission is
 * obtained from Adobe.
 * *****************************************************************************
 */

import React, { ReactElement, useCallback } from 'react';

import { Heading } from '@adobe/react-spectrum';

import DetailActionButtons from 'components/DetailActionButtons';
import Loading from 'components/Loading';
import LocalizedField from 'components/LocalizedField';
import useApplicationMetadata from 'hooks/acp/useApplicationMetadata';
import useRepoMetadata from 'hooks/acp/useRepoMetadata';
import useAssetMetadataModel from 'hooks/useAssetMetadataModel';
import { useCCXPrivateConfig, useCCXPublicConfig } from 'hooks/useCCXConfig';
import { ValidationFieldErrors } from 'model/errors/ValidationError';
import { AssetMetadataModelKey } from 'model/metadata/AssetMetadataModel';
import { FolderMetadataModel } from 'model/metadata/FolderMetadataModel';

import DefaultMetadataFormLayout from './DefaultMetadataFormLayout';
import ApplicableRegionFormField from './fields/ApplicableRegionFormField';
import FormLabel from './fields/FormLabel';
import GenericMultipickerFormField from './fields/GenericMultiPickerFormField';
import LanguagesFormField from './fields/LanguagesFormField';
import OptionsFormGroup from './fields/OptionsFormGroup';
import PriorityFormField from './fields/PriorityFormField';
import TasksFormField from './fields/TasksFormField';
import TopicsFormField from './fields/TopicsFormField';
import TraitsFormField from './fields/TraitsFormField';

type FolderMetadataFormProps = {
  isSaving: boolean;
  onReset?: () => void;
  onSave: (
    model: FolderMetadataModel,
  ) => Promise<ValidationFieldErrors | undefined>;
  path?: string;
};

export default function FolderMetadataForm({
  isSaving,
  onReset,
  onSave,
  path,
}: FolderMetadataFormProps): ReactElement {
  const { results: ccxPrivateConfig, isLoading: isLoadingPrivateConfig } =
    useCCXPrivateConfig();
  const { results: ccxPublicConfig, isLoading: isLoadingPublicConfig } =
    useCCXPublicConfig();
  const {
    hasChanges,
    isLoading,
    model,
    reset,
    setModelField,
    setValidationErrors,
    validationErrors,
  } = useAssetMetadataModel({ path, type: 'file' });
  const { results: repoMetadata } = useRepoMetadata({ path: path ?? '' });
  const { results: applicationMetadata } = useApplicationMetadata({
    path: path ?? '',
  });
  const handleReset = useCallback(async () => {
    reset();

    if (onReset) {
      onReset();
    }
  }, [onReset, reset]);
  const handleSave = useCallback(async () => {
    if (!model) {
      return;
    }

    setValidationErrors();

    const errors = await onSave(model);

    if (errors) {
      setValidationErrors(errors);
    }
  }, [model, onSave, setValidationErrors]);

  if (isLoading || isLoadingPrivateConfig || isLoadingPublicConfig || !model) {
    return <Loading />;
  }

  return (
    <DefaultMetadataFormLayout
      hasChanges={hasChanges}
      isCollapsible={false}
      isSaving={isSaving}
      onReset={handleReset}
      onSave={handleSave}
      path={path}
      actions={
        path &&
        applicationMetadata !== undefined &&
        repoMetadata !== undefined ? (
          <DetailActionButtons
            applicationMetadata={applicationMetadata}
            hasStagedChanges={hasChanges}
            path={path}
            repoMetadata={repoMetadata}
          />
        ) : undefined
      }
    >
      <LocalizedField
        label="Default Title"
        onChange={setModelField(AssetMetadataModelKey.Title)}
        value={model[AssetMetadataModelKey.Title]}
      />

      <OptionsFormGroup title="Flags">
        <PriorityFormField
          isDisabled={isSaving}
          isReadOnly={isSaving}
          error={validationErrors[AssetMetadataModelKey.Priority]}
          onChange={setModelField(AssetMetadataModelKey.Priority)}
          value={model[AssetMetadataModelKey.Priority]}
        />
      </OptionsFormGroup>

      <TopicsFormField
        isDisabled={isSaving}
        isReadOnly={isSaving}
        error={validationErrors[AssetMetadataModelKey.Topics]}
        onChange={setModelField(AssetMetadataModelKey.Topics)}
        value={model[AssetMetadataModelKey.Topics]}
      />

      <TasksFormField
        isDisabled={isSaving}
        isReadOnly={isSaving}
        error={validationErrors[AssetMetadataModelKey.Tasks]}
        onChange={setModelField(AssetMetadataModelKey.Tasks)}
        options={ccxPrivateConfig?.common.tasks.options}
        value={model[AssetMetadataModelKey.Tasks]}
      />

      <TraitsFormField
        isDisabled={isSaving}
        isReadOnly={isSaving}
        error={validationErrors[AssetMetadataModelKey.Traits]}
        onChange={setModelField(AssetMetadataModelKey.Traits)}
        options={ccxPrivateConfig?.common.traits.options}
        value={model[AssetMetadataModelKey.Traits]}
      />

      <ApplicableRegionFormField
        isDisabled={isSaving}
        isReadOnly={isSaving}
        error={validationErrors[AssetMetadataModelKey.ApplicableRegions]}
        options={ccxPublicConfig?.common.applicableRegions.options}
        onChange={setModelField(AssetMetadataModelKey.ApplicableRegions)}
        value={model[AssetMetadataModelKey.ApplicableRegions]}
      />

      <LanguagesFormField
        isDisabled={isSaving}
        isReadOnly={isSaving}
        error={validationErrors[AssetMetadataModelKey.Language]}
        options={ccxPublicConfig?.common.language.options}
        onChange={setModelField(AssetMetadataModelKey.Language)}
        value={model[AssetMetadataModelKey.Language]}
      />

      <FormLabel>Applicable Filters</FormLabel>

      <GenericMultipickerFormField
        label={
          <Heading marginTop="size-100" marginBottom="size-50" level={5}>
            Entitlements
          </Heading>
        }
        isDisabled={isSaving}
        isReadOnly={isSaving}
        error={validationErrors[AssetMetadataModelKey.ApplicableFilters]}
        options={
          ccxPrivateConfig?.common.applicableFilters.entitlements.options
        }
        onChange={setModelField(
          AssetMetadataModelKey.ApplicableFilters,
          AssetMetadataModelKey.Entitlements,
        )}
        textLabel="Entitlements"
        value={
          model[AssetMetadataModelKey.ApplicableFilters][
            AssetMetadataModelKey.Entitlements
          ]
        }
      />

      <GenericMultipickerFormField
        label={
          <Heading marginTop="size-100" marginBottom="size-50" level={5}>
            Interest Groups
          </Heading>
        }
        isDisabled={isSaving}
        isReadOnly={isSaving}
        error={validationErrors[AssetMetadataModelKey.ApplicableFilters]}
        options={
          ccxPrivateConfig?.common.applicableFilters.interestGroups.options
        }
        onChange={setModelField(
          AssetMetadataModelKey.ApplicableFilters,
          AssetMetadataModelKey.InterestGroups,
        )}
        textLabel="Interest Groups"
        value={
          model[AssetMetadataModelKey.ApplicableFilters][
            AssetMetadataModelKey.InterestGroups
          ]
        }
      />

      <GenericMultipickerFormField
        label={
          <Heading marginTop="size-100" marginBottom="size-50" level={5}>
            Restrictions
          </Heading>
        }
        isDisabled={isSaving}
        isReadOnly={isSaving}
        error={validationErrors[AssetMetadataModelKey.ApplicableFilters]}
        options={
          ccxPrivateConfig?.common.applicableFilters.restrictions.options
        }
        onChange={setModelField(
          AssetMetadataModelKey.ApplicableFilters,
          AssetMetadataModelKey.Restrictions,
        )}
        textLabel="Restrictions"
        value={
          model[AssetMetadataModelKey.ApplicableFilters][
            AssetMetadataModelKey.Restrictions
          ]
        }
      />
    </DefaultMetadataFormLayout>
  );
}

FolderMetadataForm.defaultProps = {
  onReset: undefined,
  path: undefined,
};
