/**
 * *****************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2022 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Adobe and its suppliers, if any. The intellectual and technical concepts
 * contained herein are proprietary to Adobe and its suppliers and are
 * protected by all applicable intellectual property laws, including trade
 * secret and copyright laws. Dissemination of this information or reproduction
 * of this material is strictly forbidden unless prior written permission is
 * obtained from Adobe.
 * *****************************************************************************
 */

import React, { ReactElement } from 'react';

import Loading from 'components/Loading';
import useRenditionLink from 'hooks/useRenditionLink';

type SearchAssetRenditionProps = {
  link: string;
  size: number;
};

export default function SearchAssetRendition({
  size,
  link,
}: SearchAssetRenditionProps): ReactElement {
  const { renditionLink, isLoading: renditionIsLoading } = useRenditionLink({
    templateParams: {
      size,
    },
    link,
  });
  let imgSrc: string | undefined;

  if (!renditionIsLoading) {
    imgSrc = renditionLink;
  } else {
    return <Loading />;
  }

  if (!imgSrc) {
    return <Loading />;
  }

  return (
    <img
      style={{
        maxHeight: '100%',
        maxWidth: '100%',
      }}
      src={imgSrc}
      alt="Fix me"
    />
  );
}
