/**
 * *****************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2022 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Adobe and its suppliers, if any. The intellectual and technical concepts
 * contained herein are proprietary to Adobe and its suppliers and are
 * protected by all applicable intellectual property laws, including trade
 * secret and copyright laws. Dissemination of this information or reproduction
 * of this material is strictly forbidden unless prior written permission is
 * obtained from Adobe.
 * *****************************************************************************
 */

import React, { ReactElement } from 'react';

import { TemplateAsset } from '__DEPRECATED_DO_NOT_USE_OR_YOU_WILL_BE_FIRED/controllers/api/SparkSearchTypes';

import { AssetStatus } from 'model/acp/AssetStatus';
import { Entitlement } from 'model/acp/Entitlement';
import { ZoomLevel } from 'model/ZoomLevel';

import BaseCard, { BaseCardProps } from './BaseCard';
import BaseRendition from './BaseRendition';
import SearchTemplateRendition from './SearchTemplateRendition';

const RENDITION_SIZE = 160;

type SearchTemplateCardProps = BaseCardProps & {
  asset: TemplateAsset;
  zoomLevel: ZoomLevel;
};

export default function SearchTemplateCard({
  asset,
  onAction,
  onClick,
  onSelect,
  zoomLevel,
}: SearchTemplateCardProps): ReactElement {
  return (
    <BaseCard
      canBeSelected={false}
      isActive={false}
      isSelected={false}
      metadata={[]}
      modifiedDate={asset.created}
      onAction={onAction}
      onClick={onClick}
      onSelect={onSelect}
      rendition={
        <BaseRendition
          isPremium={asset.licensingCategory === Entitlement.Premium}
        >
          <SearchTemplateRendition
            link={asset.rendition.href}
            size={RENDITION_SIZE}
          />
        </BaseRendition>
      }
      size={zoomLevel}
      status={AssetStatus.Default}
      title={asset.title}
    />
  );
}
