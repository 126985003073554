/**
 * *****************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2022 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Adobe and its suppliers, if any. The intellectual and technical concepts
 * contained herein are proprietary to Adobe and its suppliers and are
 * protected by all applicable intellectual property laws, including trade
 * secret and copyright laws. Dissemination of this information or reproduction
 * of this material is strictly forbidden unless prior written permission is
 * obtained from Adobe.
 * *****************************************************************************
 */

import React, { ReactElement, useCallback, useState } from 'react';
import { Link } from 'react-router-dom';

import { Button, ButtonGroup, StatusLight, View } from '@adobe/react-spectrum';
import ApproveReject from '@spectrum-icons/workflow/ApproveReject';
import LinkOutLight from '@spectrum-icons/workflow/LinkOutLight';
import More from '@spectrum-icons/workflow/More';

import useConstant from 'hooks/useConstant';
import { closeMenu } from 'redux/header/header.slice';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import {
  clearQueue,
  rerunCurrentJob,
  runNextQueuedJob,
  selectCurrentJob,
  skipJobInQueue,
  WorkflowActionJob,
  WorkflowActionJobStatus,
} from 'redux/workflowActions/workflowActions.slice';

import styles from './WorkflowActionsMenu.module.scss';

type WorkflowJobQueueCardProps = {
  job: WorkflowActionJob;
  isDefaultOpen?: boolean;
};

export default function WorkflowJobQueueCard({
  job,
  isDefaultOpen,
}: WorkflowJobQueueCardProps): ReactElement {
  const { acpBasePath } = useConstant();
  const currentJob = useAppSelector(selectCurrentJob);
  const isCurrentJob = currentJob === job;
  const [isOpen, setIsOpen] = useState<boolean>(isDefaultOpen ?? false);
  const dispatch = useAppDispatch();
  let jobStatusText: string;
  let isAlwaysOpen: boolean;

  switch (job.status) {
    case WorkflowActionJobStatus.Queued:
      jobStatusText = 'Pending';
      isAlwaysOpen = isCurrentJob;
      break;

    case WorkflowActionJobStatus.Processing:
      jobStatusText = 'Processing';
      isAlwaysOpen = isCurrentJob;
      break;

    case WorkflowActionJobStatus.Rejected:
      jobStatusText = 'Failed';
      isAlwaysOpen = isCurrentJob;
      break;

    case WorkflowActionJobStatus.RejectedAndResolved:
      jobStatusText = 'Failed';
      isAlwaysOpen = false;
      break;

    case WorkflowActionJobStatus.Fulfilled:
      jobStatusText = 'Succeeded';
      isAlwaysOpen = false;
      break;

    case WorkflowActionJobStatus.Skipped:
      jobStatusText = 'Skipped';
      isAlwaysOpen = false;
      break;
  }

  const closeWorkflowJobQueue = useCallback(() => {
    dispatch(closeMenu());
  }, [dispatch]);
  const verifyIsValidAssetPath: (asset: any) => boolean = (asset) => {
    const utf8Regex = /%u[a-zA-Z0-9]{4}/;

    return !asset.assetPath.match(utf8Regex);
  };

  return (
    <div
      className={`spectrum-Card spectrum-Card--small spectrum-Card--horizontal ${
        isOpen ? 'spectrum-Card--expanded' : ''
      }`}
      role="figure"
    >
      <div
        className={`spectrum-Card-preview spectrum-Card-preview--${job.status}`}
      >
        <ApproveReject />
      </div>
      <div className="spectrum-Card-body">
        <div className="spectrum-Card-header">
          <div className="spectrum-Card-title spectrum-Heading spectrum-Heading--sizeXS">
            {job.message}
          </div>

          {isAlwaysOpen || !job.task ? null : (
            <div className="spectrum-Card-actionButton">
              <button
                aria-haspopup="true"
                className="spectrum-ActionButton spectrum-ActionButton--sizeM spectrum-ActionButton--quiet"
                onClick={() => setIsOpen(!isOpen)}
                type="button"
              >
                <More size="M" />
              </button>
            </div>
          )}
        </div>
        <div className="spectrum-Card-content">
          <div className="spectrum-Card-subtitle spectrum-Detail spectrum-Detail--sizeS">
            {jobStatusText}
          </div>
        </div>
        {(isOpen || isAlwaysOpen) &&
        (job.task ||
          job.status === WorkflowActionJobStatus.Queued ||
          job.status === WorkflowActionJobStatus.Rejected) ? (
          <div className="spectrum-Card-footer">
            {!job.task ? null : (
              <table className="spectrum-Table spectrum-Table--quiet">
                <thead className="spectrum-Table-head">
                  <tr>
                    <th className="spectrum-Table-headCell">Asset Name</th>
                    <th className="spectrum-Table-headCell">Status</th>
                    <th
                      className="spectrum-Table-headCell"
                      aria-label="Details"
                    />
                  </tr>
                </thead>
                <tbody className="spectrum-Table-body">
                  {job.task.assets.map((asset) => {
                    let statusVariant:
                      | 'positive'
                      | 'negative'
                      | 'yellow'
                      | 'seafoam';
                    let statusName;

                    switch (asset.status) {
                      case WorkflowActionJobStatus.Fulfilled:
                        statusVariant = 'positive';
                        statusName = 'Succeeded';
                        break;

                      case WorkflowActionJobStatus.Rejected:
                      case WorkflowActionJobStatus.RejectedAndResolved:
                        statusVariant = 'negative';
                        statusName = 'Failed';
                        break;

                      case WorkflowActionJobStatus.Processing:
                        statusVariant = 'yellow';
                        statusName = 'Processing';
                        break;

                      case WorkflowActionJobStatus.Skipped:
                        statusVariant = 'seafoam';
                        statusName = 'Skipped';
                        break;

                      case WorkflowActionJobStatus.Queued:
                      default:
                        statusVariant = 'yellow';
                        statusName = 'Queued';
                        break;
                    }

                    return (
                      <tr className="spectrum-Table-row" key={asset.assetPath}>
                        <td className="spectrum-Table-cell">
                          <StatusLight
                            UNSAFE_className={styles.statusLight}
                            variant={statusVariant}
                          >
                            <span title={asset.assetPath}>
                              {asset.assetPath.split('/').pop()}
                            </span>
                          </StatusLight>

                          {statusName === 'Failed' ? (
                            <View
                              UNSAFE_className={styles.errorMessage}
                              marginBottom="size-150"
                              marginStart="size-400"
                            >
                              {asset.message}
                            </View>
                          ) : null}
                        </td>
                        <td className="spectrum-Table-cell">{statusName}</td>
                        <td className="spectrum-Table-cell">
                          {verifyIsValidAssetPath(asset) && (
                            <Link
                              to={asset.assetPath.replace(
                                acpBasePath.assets,
                                '',
                              )}
                              className={styles.detailLink}
                              onClick={closeWorkflowJobQueue}
                            >
                              <LinkOutLight size="XS" />
                            </Link>
                          )}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            )}
            {job.status === WorkflowActionJobStatus.Rejected ? (
              <ButtonGroup UNSAFE_className={styles.actionGroup}>
                <Button
                  variant="negative"
                  isQuiet
                  onPress={() => dispatch(clearQueue())}
                >
                  Clear Queue
                </Button>
                <Button
                  variant="negative"
                  onPress={() => dispatch(rerunCurrentJob())}
                >
                  {job.task?.assets ? 'Retry Failures' : 'Retry'}
                </Button>
                <Button
                  variant="negative"
                  onPress={() => dispatch(runNextQueuedJob())}
                >
                  Continue
                </Button>
              </ButtonGroup>
            ) : null}
            {job.status === WorkflowActionJobStatus.Queued ? (
              <ButtonGroup UNSAFE_className={styles.actionGroup}>
                <Button
                  variant="primary"
                  onPress={() => dispatch(skipJobInQueue(job))}
                >
                  Skip
                </Button>
              </ButtonGroup>
            ) : null}
          </div>
        ) : null}
      </div>
    </div>
  );
}

WorkflowJobQueueCard.defaultProps = {
  isDefaultOpen: false,
};
