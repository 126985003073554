/**
 * *****************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2022 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Adobe and its suppliers, if any. The intellectual and technical concepts
 * contained herein are proprietary to Adobe and its suppliers and are
 * protected by all applicable intellectual property laws, including trade
 * secret and copyright laws. Dissemination of this information or reproduction
 * of this material is strictly forbidden unless prior written permission is
 * obtained from Adobe.
 * *****************************************************************************
 */

import React, { ReactElement } from 'react';

import { Asset } from '@react/react-spectrum/Asset';
import { CardBody } from '@react/react-spectrum/Card';
import Label from '@react/react-spectrum/Label';
import Axios from 'axios';

import {
  assetDirectoryLabelFromFullPath,
  getFixedWidthRendition,
} from '__DEPRECATED_DO_NOT_USE_OR_YOU_WILL_BE_FIRED/controllers/api/SparkSearch';
import { DesignAsset } from '__DEPRECATED_DO_NOT_USE_OR_YOU_WILL_BE_FIRED/controllers/api/SparkSearchTypes';
import AssetUtils from '__DEPRECATED_DO_NOT_USE_OR_YOU_WILL_BE_FIRED/controllers/constants/AssetUtils';
import GridRegistry from '__DEPRECATED_DO_NOT_USE_OR_YOU_WILL_BE_FIRED/Grid/model/GridRegistry';
import { GridItemProps } from '__DEPRECATED_DO_NOT_USE_OR_YOU_WILL_BE_FIRED/Grid/model/types';

import { Entitlement } from 'model/acp/Entitlement';

import { Constants } from '../constants';

import styles from '../../styles/GridCard.module.scss';

const arrayDisplayFromVal = (
  val: string[] | undefined,
  emptyAllowed: boolean,
): string => {
  if (val?.length) {
    return val.join(', ');
  }

  if (!emptyAllowed) {
    return Constants.NONE;
  }

  return '';
};

export default function FileGridItem({
  selectionEnabled,
  actions,
  asset,
  width,
}: GridItemProps): ReactElement {
  const title: string = asset.title?.['en-US'] || `(NAME: ${asset.name})`;
  const { href } = getFixedWidthRendition(asset as DesignAsset, width);
  const displayFolder = assetDirectoryLabelFromFullPath(asset.directory);
  const resourceType = GridRegistry.AssetResourceType(asset);
  const displayType = GridRegistry.AssetDisplayType(asset.contentType);
  const size = AssetUtils.getSizeForDisplay(asset);
  const onClick = (): void => {
    if (!selectionEnabled) {
      actions.openDetailView(asset.id);
    }
  };

  return (
    <>
      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
      <div
        className={`spectrum-Card-preview ${styles.gridCardPreview}`}
        onClick={onClick}
      >
        <Asset
          type={resourceType}
          cache
          src={href}
          headers={{
            Authorization: Axios.defaults.headers.common.Authorization,
            'x-api-key': Axios.defaults.headers.common['x-api-key'],
          }}
          alt={title}
        />
        {asset.licensingCategory === Entitlement.Premium ? (
          <div className={styles.premiumBadge} />
        ) : null}
      </div>
      <CardBody
        title={title}
        subtitle={displayType}
        description={
          asset.animated || asset.hero || asset.priority ? (
            <>
              {asset.animated ? (
                <Label
                  size="S"
                  aria-label="animated"
                  variant="seafoam"
                  className={styles.tagLabel}
                >
                  Animated
                </Label>
              ) : null}
              {asset.hero ? (
                <Label
                  size="S"
                  aria-label="hero"
                  variant="fuchsia"
                  className={styles.tagLabel}
                >
                  Hero
                </Label>
              ) : null}
              {asset.priority ? (
                <Label
                  size="S"
                  aria-label="priority"
                  variant="orange"
                  className={styles.tagLabel}
                >
                  {`P${asset.priority}`}
                </Label>
              ) : null}
            </>
          ) : null
        }
      />
      <div className={styles.gridCardFieldTable}>
        <span className={styles.label}>Folder:</span>
        <span className={styles.value} title={displayFolder}>
          {displayFolder}
        </span>
        {size ? (
          <>
            <span className={styles.label}>Size:</span>
            <span className={styles.value}>{size}</span>
          </>
        ) : null}
        <span className={styles.label}>Topics:</span>
        <span className={styles.value}>
          {arrayDisplayFromVal(asset.topics, false)}
        </span>

        <span className={styles.label}>Language:</span>
        <span className={styles.value} title={asset.language}>
          {asset.language}
        </span>
      </div>
    </>
  );
}
