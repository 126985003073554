/**
 * *****************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2022 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Adobe and its suppliers, if any. The intellectual and technical concepts
 * contained herein are proprietary to Adobe and its suppliers and are
 * protected by all applicable intellectual property laws, including trade
 * secret and copyright laws. Dissemination of this information or reproduction
 * of this material is strictly forbidden unless prior written permission is
 * obtained from Adobe.
 * *****************************************************************************
 */

import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';

import Axios from 'axios';

import { Privileges } from 'model/AccessLevel';
import { ACPLink } from 'model/acp/ACPLink';
import { HeaderLinks } from 'model/acp/HeaderLinks';

import useACPLink from './acp/useACPLink';
import useCurrentAssetByPath from './useCurrentAssetByPath';

export enum Inheritance {
  Deep = 'deep',
  Self = 'self',
  DeepOverride = 'deep_override',
}

export type IMSPrincipal = {
  'xdm:provider': {
    '@id': string;
  };
  '@id': string;
  '@type': string;
};

/**
 * PredefinedACPPrincipals are Principal types in that Tempo does not consider because Tempo looks
 * for IMS based principals that are defined by end users. However, these predefined types are a type to still be mindful of
 * requests/responses from ACP that may include these principal types.
 * See https://git.corp.adobe.com/pages/CAF/api-spec/chapters/concepts/access.html
 */
export type PredefinedACPPrincipals =
  | 'authenticated'
  | 'unauthenticated'
  | 'all';

export type PrincipalType = IMSPrincipal | PredefinedACPPrincipals;

export enum Modifier {
  Grant = 'grant',
  Deny = 'deny',
}

export type AccessControlEntry = {
  'repo:principal': PrincipalType;
  'repo:modifier': Modifier;
  'repo:privileges': Privileges[];
  'repo:relations': ACPLink[];
  'repo:inheritance': Inheritance;
};

export type ACLPolicyResult = {
  'repo:acl': AccessControlEntry[];
  _links: HeaderLinks;
};

export type FolderACLHookResult = {
  isLoading: boolean;
  data?: ACLPolicyResult;
};

export default function useFolderACL(): FolderACLHookResult {
  const [result, setResult] = useState<FolderACLHookResult>({
    isLoading: true,
  });
  const { currentAssetByPath } = useCurrentAssetByPath({
    shouldIncludeRoot: true,
  });
  const { isLoading: isACPLinkLoading, results } = useACPLink({
    acpLink: ACPLink.ACPolicy,
    path: currentAssetByPath,
  });
  const requestUrl = results?.linkUrl ?? '';
  const GETQuery = async (): Promise<ACLPolicyResult> => {
    const response = await Axios.get(requestUrl);

    return response.data;
  };
  const { data, isLoading: isACLPolicyLoading } = useQuery<ACLPolicyResult>(
    ['acl', currentAssetByPath],
    GETQuery,
    {
      /**
       * Disable the ReactQuery query call if this function is run but the links
       * haven't loaded, because there will be no requestUrl and no reason to
       * make the request.
       */
      enabled: !isACPLinkLoading && !!requestUrl,
    },
  );

  useEffect((): void => {
    if (data) {
      setResult({
        isLoading: isACLPolicyLoading,
        data,
      });
    }
  }, [data, isACLPolicyLoading]);

  return result;
}
