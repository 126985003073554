/**
 * *****************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2022 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Adobe and its suppliers, if any. The intellectual and technical concepts
 * contained herein are proprietary to Adobe and its suppliers and are
 * protected by all applicable intellectual property laws, including trade
 * secret and copyright laws. Dissemination of this information or reproduction
 * of this material is strictly forbidden unless prior written permission is
 * obtained from Adobe.
 * *****************************************************************************
 */

import React from 'react';

import { Text } from '@adobe/react-spectrum';
import BookmarkSingle from '@spectrum-icons/workflow/BookmarkSingle';

import ToggleButton from 'components/ToggleButton';
import { Entitlement } from 'model/acp/Entitlement';

import { AssetMetadataFormFieldProps } from './AssetMetadataFormFieldProps';

export default function LicensingCategoryFormField({
  isReadOnly,
  onChange,
  value,
}: AssetMetadataFormFieldProps<string>) {
  return (
    <ToggleButton
      isDisabled={isReadOnly}
      isSelected={value === Entitlement.Premium}
      onChange={(newIsSelected: boolean) =>
        onChange(newIsSelected ? Entitlement.Premium : Entitlement.Free)
      }
    >
      <BookmarkSingle marginEnd="size-50" size="S" />
      <Text>Premium</Text>
    </ToggleButton>
  );
}
