/**
 * *****************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2022 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Adobe and its suppliers, if any. The intellectual and technical concepts
 * contained herein are proprietary to Adobe and its suppliers and are
 * protected by all applicable intellectual property laws, including trade
 * secret and copyright laws. Dissemination of this information or reproduction
 * of this material is strictly forbidden unless prior written permission is
 * obtained from Adobe.
 * *****************************************************************************
 */

import React, { ReactElement, useCallback, useState } from 'react';

import { Link, TextField, View } from '@adobe/react-spectrum';

import FormLabel from 'components/AssetMetadataForm/fields/FormLabel';
import { Language, TranslatedLanguages } from 'model/Language';

type Props = {
  value: Map<Language, string> | undefined;
  onChange: (newValue: Map<string, string>) => void;
  label: string;
};

export default function LocalizedField({
  onChange,
  value,
  label,
}: Props): ReactElement {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const updateTranslationMap = useCallback(
    (translationText: string, locale: Language): void => {
      if (value !== undefined) {
        const newMap = new Map(value);
        newMap.set(locale, translationText);
        onChange(newMap);
      }
    },
    [onChange, value],
  );

  return (
    <>
      <View position="relative">
        <TextField
          label={
            <>
              <FormLabel>{label}</FormLabel>
              <View marginStart="size-50">
                {value?.get(Language.Default) ? (
                  // eslint-disable-next-line jsx-a11y/anchor-is-valid
                  <Link onPress={(): void => setIsOpen(!isOpen)}>
                    {`${isOpen ? 'Hide' : 'Edit'} translations`}
                  </Link>
                ) : null}
              </View>
            </>
          }
          value={value?.get(Language.Default) ?? ''}
          onChange={(text) => updateTranslationMap(text, Language.Default)}
          width="100%"
        />
      </View>
      {isOpen ? (
        <View paddingTop="size-100" minHeight="size-250" width="100%">
          <FormLabel>Translations</FormLabel>
          {value
            ? TranslatedLanguages.sort().map((language) => (
                <TextField
                  key={language}
                  width="100%"
                  label={language}
                  value={value.get(language) ?? ''}
                  onChange={(text) => updateTranslationMap(text, language)}
                />
              ))
            : null}
        </View>
      ) : (
        <></>
      )}
    </>
  );
}
