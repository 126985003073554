/**
 * *****************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2022 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Adobe and its suppliers, if any. The intellectual and technical concepts
 * contained herein are proprietary to Adobe and its suppliers and are
 * protected by all applicable intellectual property laws, including trade
 * secret and copyright laws. Dissemination of this information or reproduction
 * of this material is strictly forbidden unless prior written permission is
 * obtained from Adobe.
 * *****************************************************************************
 */

import DCTitle from 'model/acp/DCTitle';
import Constant from 'model/constant/Constant';
import { Language } from 'model/Language';

export function mapTranslationsToDCTitle(
  languageMap: Map<Language, string> | undefined,
) {
  const { defaultLanguage } = new Constant();
  let dcTitle: DCTitle | undefined;

  if (languageMap !== undefined) {
    dcTitle = {
      '@type': 'rdf:Alt',
      'rdf:_1': {
        '@language': defaultLanguage,
        '@value': languageMap.get(defaultLanguage) as string,
      },
    };
    let rdfIndex = 0;
    languageMap.forEach((translation, language) => {
      if (language !== defaultLanguage) {
        (dcTitle as DCTitle)[`rdf:_${rdfIndex + 2}`] = {
          '@language': language,
          '@value': translation,
        };
        rdfIndex++;
      }
    });
  }

  return dcTitle;
}
