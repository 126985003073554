/**
 * *****************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2022 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Adobe and its suppliers, if any. The intellectual and technical concepts
 * contained herein are proprietary to Adobe and its suppliers and are
 * protected by all applicable intellectual property laws, including trade
 * secret and copyright laws. Dissemination of this information or reproduction
 * of this material is strictly forbidden unless prior written permission is
 * obtained from Adobe.
 * *****************************************************************************
 */

import React, { ReactChild, ReactElement } from 'react';
import { Navigate, Route, Routes } from 'react-router';

import { FilterTab } from '__DEPRECATED_DO_NOT_USE_OR_YOU_WILL_BE_FIRED/redux/filter/filter.slice';
import AssetsFilterScreen from '__DEPRECATED_DO_NOT_USE_OR_YOU_WILL_BE_FIRED/screens/Filter/AssetsFilterScreen';

import {
  ViewLegacyAuthorAssetsScreen,
  ViewLegacySubmitAssetsScreen,
} from 'screens/assets';
import { CreateCollectionScreen } from 'screens/collections';
import { InternalErrorScreen, NotFoundErrorScreen } from 'screens/errors';
import { ViewPermissionsScreen } from 'screens/permissions';

type ErrorBoundaryProps = {
  children: ReactChild | ReactChild[];
};

type ErrorBoundaryState = {
  error: Error | undefined;
};

class ErrorBoundary extends React.Component<
  ErrorBoundaryProps,
  ErrorBoundaryState
> {
  constructor(props: any) {
    super(props);
    this.state = {
      error: undefined,
    };
  }
  static getDerivedStateFromError(error: Error) {
    return {
      error,
    };
  }
  render() {
    const { children } = this.props;
    const { error } = this.state;

    if (error) {
      return <InternalErrorScreen error={error} />;
    }

    return children;
  }
}

export default function MainRouter(): ReactElement {
  return (
    <ErrorBoundary>
      <Routes>
        <Route index element={<Navigate replace to="/submit/?view=card" />} />

        <Route path="review">
          <Route
            path=":contentType"
            element={
              <AssetsFilterScreen
                tab={FilterTab.review}
                title="Review Templates"
              />
            }
          />
        </Route>

        <Route path="search">
          <Route index element={<Navigate replace to="/search/templates" />} />

          <Route
            path=":contentType"
            element={
              <AssetsFilterScreen
                tab={FilterTab.search}
                title="Search Live Content"
              />
            }
          />
        </Route>

        <Route path="/author/*" element={<ViewLegacyAuthorAssetsScreen />} />

        <Route path="/submit/*" element={<ViewLegacySubmitAssetsScreen />} />

        <Route path="/collections/new/*" element={<CreateCollectionScreen />} />
        <Route path="/permissions/*" element={<ViewPermissionsScreen />} />
        <Route path="*" element={<NotFoundErrorScreen />} />
      </Routes>
    </ErrorBoundary>
  );
}
