/**
 * *****************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2022 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Adobe and its suppliers, if any. The intellectual and technical concepts
 * contained herein are proprietary to Adobe and its suppliers and are
 * protected by all applicable intellectual property laws, including trade
 * secret and copyright laws. Dissemination of this information or reproduction
 * of this material is strictly forbidden unless prior written permission is
 * obtained from Adobe.
 * *****************************************************************************
 */

import React, { ReactElement } from 'react';

import Label from 'components/@spectrum/Label';
import useAssetStatus from 'hooks/useAssetStatus';
import { AssetStatus } from 'model/acp/AssetStatus';
import { SpectrumColor } from 'model/constant/SpectrumColors';

type Props = {
  path?: string;
  status?: AssetStatus;
};

const statusToColorMap = new Map<AssetStatus, SpectrumColor>([
  [AssetStatus.Default, SpectrumColor.Grey],
  [AssetStatus.Dirty, SpectrumColor.Seafoam],
  [AssetStatus.Draft, SpectrumColor.Orange],
  [AssetStatus.Pending, SpectrumColor.Yellow],
  [AssetStatus.Published, SpectrumColor.Green],
  [AssetStatus.Rejected, SpectrumColor.Red],
  [AssetStatus.Unpublished, SpectrumColor.Grey],
]);

export default function StatusLabel({ path, status }: Props): ReactElement {
  let computedStatus: AssetStatus = useAssetStatus({ path });

  if (!path && status) {
    computedStatus = status;
  }

  if (computedStatus === AssetStatus.Default) {
    return <></>;
  }

  return (
    <Label color={statusToColorMap.get(computedStatus) as SpectrumColor}>
      {computedStatus}
    </Label>
  );
}

StatusLabel.defaultProps = {
  path: '',
  status: undefined,
};
