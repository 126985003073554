/**
 * *****************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2022 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Adobe and its suppliers, if any. The intellectual and technical concepts
 * contained herein are proprietary to Adobe and its suppliers and are
 * protected by all applicable intellectual property laws, including trade
 * secret and copyright laws. Dissemination of this information or reproduction
 * of this material is strictly forbidden unless prior written permission is
 * obtained from Adobe.
 * *****************************************************************************
 */

import React, { ReactElement, useCallback, useEffect, useState } from 'react';

import {
  ActionButton,
  Button,
  Flex,
  Heading,
  Item,
  Menu,
  MenuTrigger,
  ProgressCircle,
  Switch,
  Tooltip,
  TooltipTrigger,
  View,
} from '@adobe/react-spectrum';
import {
  error as errorToast,
  success as successToast,
} from '@react/react-spectrum/Toast';
import {
  Cell,
  Column,
  Row,
  TableBody,
  TableHeader,
  TableView,
} from '@react-spectrum/table';
import { SortDescriptor, SortDirection } from '@react-types/shared';
import ChevronDown from '@spectrum-icons/workflow/ChevronDown';
import Info from '@spectrum-icons/workflow/Info';
import { orderBy } from 'lodash';

import Label from 'components/@spectrum/Label';
import ConfirmationDialog from 'components/Dialogs/ConfirmationDialog';
import ShareWithUserDialog from 'components/Dialogs/ShareWithUserDialog';
import useAssetWorkflowActions from 'hooks/useAssetWorkflowActions';
import useCurrentAssetByPath from 'hooks/useCurrentAssetByPath';
import { PrincipalType } from 'hooks/useFolderACL';
import useFolderACLModel, {
  UserACLModel,
  UserGroupACLModel,
} from 'hooks/useFolderACLModel';
import useOrgUserGroups from 'hooks/useOrgUserGroups';
import useOrgUsers from 'hooks/useOrgUsers';
import { AccessLevel } from 'model/AccessLevel';
import { UserGroupProfile } from 'model/acp/UserGroupProfile';
import { UserProfile } from 'model/acp/UserProfile';
import { SpectrumColor } from 'model/constant/SpectrumColors';

import NoUsersMessage from './NoUsersMessage';

const accessToColor = new Map<AccessLevel, SpectrumColor>([
  [AccessLevel.None, SpectrumColor.Red],
  [AccessLevel.Check, SpectrumColor.Grey],
  [AccessLevel.Read, SpectrumColor.Yellow],
  [AccessLevel.Edit, SpectrumColor.Seafoam],
  [AccessLevel.Owner, SpectrumColor.Green],
]);

export type FolderPermissions = {
  name: string;
  type: PrincipalType;
  access: AccessLevel;
  username: string;
};

const columnToUserFieldSortingMap: Record<
  string,
  string | ((user: UserACLModel) => number)
> = {
  '$.0': 'normalizedEmail',
  '$.1': 'name',
  '$.2': (user) =>
    [
      AccessLevel.None,
      AccessLevel.Check,
      AccessLevel.Read,
      AccessLevel.Edit,
      AccessLevel.Owner,
    ].indexOf(user.access),
};
const columnToGroupFieldSortingMap: Record<
  string,
  string | ((group: UserGroupACLModel) => number)
> = {
  '$.0': 'normalizedName',
  '$.1': 'userCount',
  '$.2': (group) =>
    [
      AccessLevel.None,
      AccessLevel.Check,
      AccessLevel.Read,
      AccessLevel.Edit,
      AccessLevel.Owner,
    ].indexOf(group.access),
};
const directionMap: Record<SortDirection, 'desc' | 'asc'> = {
  descending: 'desc',
  ascending: 'asc',
};

export default function FolderPermissionsTable(): ReactElement {
  // Global constants
  const { currentAssetByPath } = useCurrentAssetByPath({
    shouldIncludeRoot: true,
  });
  const assetWorkflowActions = useAssetWorkflowActions();
  // Hooks
  const { isLoading: isOrgUserGroupsLoading, result: orgUserGroups } =
    useOrgUserGroups();
  const { isLoading: isOrgUserLoading, result: orgUsers } = useOrgUsers();
  const {
    isLoading: isFolderACLModelLoading,
    userItems,
    userGroupItems,
  } = useFolderACLModel();
  // Global state
  const [isConfirmationDialogOpen, setIsConfirmationDialogOpen] =
    useState<boolean>(false);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [isShareDialogOpen, setIsShareDialogOpen] = useState<boolean>(false);
  const [isShowingHiddenUsers, setIsShowingHiddenUsers] =
    useState<boolean>(false);
  const [isUserGroupUpdate, setIsUserGroupUpdate] = useState<boolean>(false);
  const [savedUserItems, setSavedUserItems] = useState<UserACLModel[]>();
  const [savedUserGroupItems, setSavedUserGroupItems] =
    useState<UserGroupACLModel[]>();
  const [selectedUserGroupRow, setUserGroupSelectedRow] = useState<Set<string>>(
    new Set(),
  );
  const [selectedUserRow, setUserSelectedRow] = useState<Set<string>>(
    new Set(),
  );
  const [userItemsSort, setUserItemsSort] = useState<SortDescriptor>();
  const [groupItemsSort, setGroupItemsSort] = useState<SortDescriptor>();
  // User actions handlers
  const handleAddClick = useCallback(
    async (isUserGroup: boolean) => {
      setIsShareDialogOpen(true);
      setIsUserGroupUpdate(isUserGroup);
    },
    [setIsShareDialogOpen, setIsUserGroupUpdate],
  );
  const handleRemoveClick = useCallback(
    async (isUserGroup: boolean) => {
      setIsConfirmationDialogOpen(true);
      setIsUserGroupUpdate(isUserGroup);
    },
    [setIsConfirmationDialogOpen, setIsUserGroupUpdate],
  );
  const handleShare = useCallback(
    async (userInput: string, accessLevel: AccessLevel) => {
      setIsSaving(true);
      setIsShareDialogOpen(false);

      let principalId: string | undefined;

      if (isUserGroupUpdate && !isOrgUserGroupsLoading) {
        principalId = orgUserGroups?.find(
          (group: UserGroupProfile) => userInput === group.name,
        )?.id;
      } else if (!isUserGroupUpdate && !isOrgUserLoading) {
        principalId = orgUsers?.find(
          (user: UserProfile) => userInput === user.email,
        )?.id;
      }

      if (!principalId) {
        const err =
          'Principal (ie. user or user group) being added is not in the ACP Org.';
        errorToast(err);
        throw new Error(err);
      }

      await assetWorkflowActions.shareAsset(
        currentAssetByPath,
        principalId,
        accessLevel,
      );

      setIsSaving(false);
      successToast(
        isUserGroupUpdate
          ? 'Shared folder with new user group successfully'
          : 'Shared folder with new user successfully',
      );
    },
    [
      assetWorkflowActions,
      isOrgUserGroupsLoading,
      isOrgUserLoading,
      isUserGroupUpdate,
      orgUserGroups,
      orgUsers,
      currentAssetByPath,
    ],
  );
  const handleUnshare = useCallback(async () => {
    setIsSaving(true);
    setIsConfirmationDialogOpen(false);

    let principalId: string | undefined;

    if (isUserGroupUpdate && !isOrgUserGroupsLoading) {
      principalId = orgUserGroups?.find((u: UserGroupProfile) =>
        selectedUserGroupRow.has(u.id),
      )?.id;
    } else if (!isUserGroupUpdate && !isOrgUserLoading) {
      principalId = orgUsers?.find((u: UserProfile) =>
        selectedUserRow.has(u.id),
      )?.id;
    }

    await assetWorkflowActions.unshareAsset(currentAssetByPath, principalId);

    setIsSaving(false);
    setUserSelectedRow(new Set());
    setUserGroupSelectedRow(new Set());
    successToast(
      isUserGroupUpdate
        ? 'User group removed successfully'
        : 'User removed succcessfully',
    );
  }, [
    assetWorkflowActions,
    isOrgUserGroupsLoading,
    isOrgUserLoading,
    isUserGroupUpdate,
    orgUserGroups,
    orgUsers,
    currentAssetByPath,
    selectedUserGroupRow,
    selectedUserRow,
  ]);
  const handlePrincipalACEUpdate = useCallback(
    async (id, accessLevel, isUserGroup) => {
      setIsSaving(true);

      let principalId: string | undefined;

      if (isUserGroup && !isOrgUserGroupsLoading) {
        principalId = orgUserGroups?.find(
          (u: UserGroupProfile) => u.id === id,
        )?.id;
      } else if (!isUserGroup && !isOrgUserLoading) {
        principalId = orgUsers?.find((u: UserProfile) => u.id === id)?.id;
      }

      await assetWorkflowActions.updateAssetACL(
        currentAssetByPath,
        principalId,
        accessLevel,
      );

      setIsSaving(false);
    },
    [
      assetWorkflowActions,
      isOrgUserGroupsLoading,
      isOrgUserLoading,
      orgUserGroups,
      orgUsers,
      currentAssetByPath,
    ],
  );

  useEffect(() => {
    if (!isFolderACLModelLoading) {
      setSavedUserItems(userItems);
      setSavedUserGroupItems(userGroupItems);
    }
  }, [
    userItems,
    userGroupItems,
    isFolderACLModelLoading,
    setSavedUserItems,
    setSavedUserGroupItems,
  ]);

  const filteredSavedUserGroupItems = orderBy(
    savedUserGroupItems,
    columnToGroupFieldSortingMap[groupItemsSort?.column ?? '$.0'],
    directionMap[groupItemsSort?.direction ?? 'ascending'],
  );
  let filteredSavedUserItems = isShowingHiddenUsers
    ? savedUserItems
    : savedUserItems?.filter((item) => item.type !== 'Unknown');
  filteredSavedUserItems = orderBy(
    filteredSavedUserItems,
    columnToUserFieldSortingMap[userItemsSort?.column ?? '$.0'],
    directionMap[userItemsSort?.direction ?? 'ascending'],
  );

  return (
    <Flex direction="column" gap="size-200" height="100%">
      <Flex width="100%" flex minHeight="size-0" direction="column">
        <Flex alignItems="center" width="100%">
          <Flex alignItems="center">
            <Heading level={3} marginX="size-100">
              Users
            </Heading>
            <Heading level={4}>{userItems?.length}</Heading>
          </Flex>
          <View marginX="size-125">
            <Button
              marginX="size-25"
              variant="secondary"
              onPress={() => handleAddClick(false)}
            >
              Add
            </Button>
            <Button
              marginX="size-25"
              isDisabled={selectedUserRow.size === 0}
              variant="secondary"
              onPress={() => handleRemoveClick(false)}
            >
              Remove
            </Button>
          </View>
          {isSaving ? (
            <ProgressCircle
              marginX="size-100"
              size="S"
              aria-label="loading"
              isIndeterminate
            />
          ) : (
            <></>
          )}
          <View flex />
          <Switch
            UNSAFE_style={{
              marginRight: 0,
            }}
            isEmphasized
            isSelected={isShowingHiddenUsers}
            onChange={() => setIsShowingHiddenUsers((current) => !current)}
          >
            Show hidden users
          </Switch>

          <TooltipTrigger delay={0}>
            <ActionButton
              isQuiet
              height="size-200"
              minWidth="size-0"
              width="size-175"
            >
              <Info height="size-150" />
            </ActionButton>
            <Tooltip placement="start" width="size-2000">
              By default, we hide users who have been removed from the
              organization but still have stale access records.
            </Tooltip>
          </TooltipTrigger>
        </Flex>
        <TableView
          renderEmptyState={() => <NoUsersMessage isUserGroup={false} />}
          selectionStyle="highlight"
          flex
          aria-label="Users table"
          selectionMode="single"
          disallowEmptySelection={false}
          selectedKeys={selectedUserRow}
          onSelectionChange={(id) =>
            selectedUserRow.has(id as string)
              ? setUserSelectedRow(new Set())
              : setUserSelectedRow(new Set(id as string))
          }
          onSortChange={setUserItemsSort}
          sortDescriptor={userItemsSort}
        >
          <TableHeader>
            <Column width={300} align="start" allowsSorting>
              Email
            </Column>
            <Column align="center" allowsSorting>
              Name
            </Column>
            <Column align="start" width={100} allowsSorting>
              Access
            </Column>
          </TableHeader>
          <TableBody
            items={filteredSavedUserItems}
            loadingState={isFolderACLModelLoading ? 'loading' : 'idle'}
          >
            {(item) => (
              <Row key={item.id}>
                <Cell>
                  <span style={{ textTransform: 'lowercase' }}>
                    {item.email}
                  </span>
                </Cell>
                <Cell>{item.name}</Cell>
                <Cell>
                  <Flex>
                    <Label
                      UNSAFE_style={{ height: 'min-content' }}
                      color={accessToColor.get(item.access) as SpectrumColor}
                    >
                      {item.access}
                    </Label>
                    <MenuTrigger>
                      <ActionButton isQuiet>
                        <ChevronDown size="XS" />
                      </ActionButton>
                      <Menu
                        onAction={(key) =>
                          handlePrincipalACEUpdate(
                            item.id,
                            key as AccessLevel,
                            false,
                          )
                        }
                        selectionMode="single"
                      >
                        <Item key={AccessLevel.Read}>{AccessLevel.Read}</Item>
                        <Item key={AccessLevel.Edit}>{AccessLevel.Edit}</Item>
                        <Item key={AccessLevel.Owner}>{AccessLevel.Owner}</Item>
                      </Menu>
                    </MenuTrigger>
                  </Flex>
                </Cell>
              </Row>
            )}
          </TableBody>
        </TableView>
      </Flex>
      <Flex width="100%" flex minHeight="size-0" direction="column">
        <Flex alignItems="center" width="100%">
          <Flex alignItems="center">
            <Heading level={3} marginX="size-100">
              User Groups
            </Heading>
            <Heading level={4}>{userGroupItems?.length}</Heading>
          </Flex>
          <View marginX="size-125">
            <Button
              marginX="size-25"
              variant="secondary"
              onPress={() => handleAddClick(true)}
            >
              Add
            </Button>
            <Button
              marginX="size-25"
              isDisabled={selectedUserGroupRow.size === 0}
              variant="secondary"
              onPress={() => handleRemoveClick(true)}
            >
              Remove
            </Button>
          </View>
          {isSaving ? (
            <ProgressCircle
              marginX="size-100"
              size="S"
              aria-label="loading"
              isIndeterminate
            />
          ) : (
            <></>
          )}
        </Flex>
        <TableView
          renderEmptyState={() => <NoUsersMessage isUserGroup />}
          selectionStyle="highlight"
          flex
          aria-label="User Groups table"
          selectionMode="single"
          disallowEmptySelection={false}
          selectedKeys={selectedUserGroupRow}
          onSelectionChange={(id) =>
            selectedUserGroupRow.has(id as string)
              ? setUserGroupSelectedRow(new Set())
              : setUserGroupSelectedRow(new Set(id as string))
          }
          onSortChange={setGroupItemsSort}
          sortDescriptor={groupItemsSort}
        >
          <TableHeader>
            <Column width={300} align="start" allowsSorting>
              Name
            </Column>
            <Column align="center" allowsSorting>
              Users
            </Column>
            <Column width={100} align="start" allowsSorting>
              Access
            </Column>
          </TableHeader>
          <TableBody
            items={filteredSavedUserGroupItems}
            loadingState={isFolderACLModelLoading ? 'loading' : 'idle'}
          >
            {(item) => (
              <Row key={item.id}>
                <Cell>{item.name}</Cell>
                <Cell>{item.userCount}</Cell>
                <Cell>
                  <Flex>
                    <Label
                      UNSAFE_style={{ height: 'min-content' }}
                      color={accessToColor.get(item.access) as SpectrumColor}
                    >
                      {item.access}
                    </Label>
                    <MenuTrigger>
                      <ActionButton isQuiet>
                        <ChevronDown size="XS" />
                      </ActionButton>
                      <Menu
                        onAction={(key) =>
                          handlePrincipalACEUpdate(
                            item.id,
                            key as AccessLevel,
                            true,
                          )
                        }
                        selectionMode="single"
                      >
                        <Item key={AccessLevel.Read}>{AccessLevel.Read}</Item>
                        <Item key={AccessLevel.Edit}>{AccessLevel.Edit}</Item>
                        <Item key={AccessLevel.Owner}>{AccessLevel.Owner}</Item>
                      </Menu>
                    </MenuTrigger>
                  </Flex>
                </Cell>
              </Row>
            )}
          </TableBody>
        </TableView>
      </Flex>
      <ShareWithUserDialog
        isOpen={isShareDialogOpen}
        onClose={() => setIsShareDialogOpen(false)}
        onConfirm={handleShare}
        isUserGroup={isUserGroupUpdate}
      />
      <ConfirmationDialog
        isOpen={isConfirmationDialogOpen}
        onClose={() => setIsConfirmationDialogOpen(false)}
        onConfirm={handleUnshare}
        title="Remove User"
        content="Are you sure you want to revoke access?"
        confirm="Remove"
      />
    </Flex>
  );
}
