/**
 * *****************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2022 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Adobe and its suppliers, if any. The intellectual and technical concepts
 * contained herein are proprietary to Adobe and its suppliers and are
 * protected by all applicable intellectual property laws, including trade
 * secret and copyright laws. Dissemination of this information or reproduction
 * of this material is strictly forbidden unless prior written permission is
 * obtained from Adobe.
 * *****************************************************************************
 */

import React, { RefObject, useRef } from 'react';

import { View } from '@adobe/react-spectrum';

import useOnScreen from 'hooks/useOnScreen';

type Props = {
  setIsVisible: (isVisible: boolean) => void;
  isEnabled: boolean;
};

export default function LoadingCard({ setIsVisible, isEnabled }: Props) {
  const ref = useRef<HTMLDivElement>();
  const isVisible = useOnScreen({
    ref,
  });

  if (isEnabled) {
    setIsVisible(isVisible);
  }

  return (
    <View width="100%" height="size-300">
      <div ref={ref as RefObject<HTMLDivElement>} />
    </View>
  );
}
