/**
 * *****************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2022 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Adobe and its suppliers, if any. The intellectual and technical concepts
 * contained herein are proprietary to Adobe and its suppliers and are
 * protected by all applicable intellectual property laws, including trade
 * secret and copyright laws. Dissemination of this information or reproduction
 * of this material is strictly forbidden unless prior written permission is
 * obtained from Adobe.
 * *****************************************************************************
 */

import React, { ReactElement } from 'react';

import { Text } from '@adobe/react-spectrum';
import { Item, TabList, TabPanels, Tabs } from '@react-spectrum/tabs';

import { PopoverMenu } from 'redux/header/header.slice';
import { useAppSelector } from 'redux/hooks';
import {
  selectActiveQueue,
  selectCompleteQueue,
} from 'redux/workflowActions/workflowActions.slice';

import EmptyCompletedJobsQueue from './EmptyCompletedJobsQueue';
import EmptyInProgressJobsQueue from './EmptyInProgressJobsQueue';
import WorkflowJobQueueCard from './WorkflowJobQueueCard';

import styles from './WorkflowActionsMenu.module.scss';

type WorkflowActionsMenuProps = {
  initialView: PopoverMenu;
};

export default function WorkflowActionsMenu({
  initialView,
}: WorkflowActionsMenuProps): ReactElement {
  const activeQueue = useAppSelector(selectActiveQueue);
  const completedQueue = useAppSelector(selectCompleteQueue);

  return (
    <div className={`spectrum-Popover is-open ${styles.jobsMenu}`}>
      <Tabs aria-label="Workflow Actions Menu" defaultSelectedKey={initialView}>
        <TabList UNSAFE_className={styles.tabs}>
          <Item key={PopoverMenu.ActionsInProgress}>
            <Text>Queue</Text>
          </Item>
          <Item key={PopoverMenu.ActionsComplete}>
            <Text>Completed</Text>
          </Item>
        </TabList>
        <TabPanels UNSAFE_className={styles.tabPanel}>
          <Item key={PopoverMenu.ActionsInProgress}>
            {activeQueue.length ? (
              <ul className={styles.queue}>
                {activeQueue.map((job) => (
                  <li key={job.key}>
                    <WorkflowJobQueueCard job={job} />
                  </li>
                ))}
              </ul>
            ) : (
              <EmptyInProgressJobsQueue />
            )}
          </Item>
          <Item key={PopoverMenu.ActionsComplete}>
            {completedQueue.length ? (
              <ul className={styles.queue}>
                {completedQueue.map((job) => (
                  <li key={job.key}>
                    <WorkflowJobQueueCard job={job} />
                  </li>
                ))}
              </ul>
            ) : (
              <EmptyCompletedJobsQueue />
            )}
          </Item>
        </TabPanels>
      </Tabs>
    </div>
  );
}
