/**
 * *****************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2022 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Adobe and its suppliers, if any. The intellectual and technical concepts
 * contained herein are proprietary to Adobe and its suppliers and are
 * protected by all applicable intellectual property laws, including trade
 * secret and copyright laws. Dissemination of this information or reproduction
 * of this material is strictly forbidden unless prior written permission is
 * obtained from Adobe.
 * *****************************************************************************
 */

import React, { ReactElement, useCallback, useState } from 'react';

import {
  Divider,
  Heading,
  Item,
  Slider,
  TabList,
  TabPanels,
  Tabs,
  Text,
  Well,
} from '@adobe/react-spectrum';
import { Flex } from '@react-spectrum/layout';
import { View } from '@react-spectrum/view';
import Code from '@spectrum-icons/workflow/Code';
import CollectionAdd from '@spectrum-icons/workflow/CollectionAdd';
import Image from '@spectrum-icons/workflow/Image';

import CollectionFileLivePreview from 'components/CCXAssetCollectionForm/CollectionFileLivePreview';
import {
  CCXTemplateCollectionQuery,
  CollectionQueryModel,
} from 'model/acp/CollectionQueryModel';
import { CollectionSource } from 'model/acp/CollectionSource';
import { ZoomLevel } from 'model/ZoomLevel';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { selectUserPreferences, setUserZoom } from 'redux/user/user.slice';

import CCXTemplateCollectionSearch from './CCXTemplateCollectionSearch';

import overrideStyles from 'styles/spectrum-overrides.module.scss';

type CCXTemplateCollectionFormProps = {
  collectionQueryModel: CollectionQueryModel;
  onChange: (queryModel: CollectionQueryModel) => void;
  initialMode: CCXTemplateCollectionFormMode;
};

export enum CCXTemplateCollectionFormMode {
  Edit = 'edit',
  File = 'file',
}

const templateCollectionFormTabTitles: Record<
  CCXTemplateCollectionFormMode,
  string
> = {
  [CCXTemplateCollectionFormMode.Edit]: 'Update Template Query',
  [CCXTemplateCollectionFormMode.File]: 'Collection File',
};

export default function CCXTemplateCollectionForm({
  collectionQueryModel,
  initialMode,
  onChange,
}: CCXTemplateCollectionFormProps): ReactElement {
  const [mode, setMode] = useState<CCXTemplateCollectionFormMode>(initialMode);
  const { zoom } = useAppSelector(selectUserPreferences);
  const dispatch = useAppDispatch();
  const setZoom = useCallback(
    (zoomLevel: ZoomLevel) => {
      dispatch(setUserZoom(zoomLevel));
    },
    [dispatch],
  );
  const templateQuery = collectionQueryModel[
    CollectionSource.CCXTemplate
  ] as CCXTemplateCollectionQuery;
  const updateTemplateCollectionQuery = useCallback(
    (templateCollectionQuery: CCXTemplateCollectionQuery): void => {
      onChange({
        ...collectionQueryModel,
        [CollectionSource.CCXTemplate]: templateCollectionQuery,
      });
    },
    [onChange, collectionQueryModel],
  );

  return (
    <Flex direction="column" flex marginBottom="size-200" minHeight="size-0">
      <Well
        flex
        marginTop="size-0"
        minHeight="size-0"
        position="relative"
        UNSAFE_style={{
          padding: 0,
        }}
      >
        <Tabs
          height="100%"
          isQuiet
          onSelectionChange={(key) =>
            setMode(key as CCXTemplateCollectionFormMode)
          }
          selectedKey={mode}
        >
          <View borderBottomColor="gray-300" borderBottomWidth="thick">
            <Flex alignItems="center" marginBottom="-2px" marginX="size-300">
              <Heading flex marginY="size-0">
                {templateCollectionFormTabTitles[mode]}
              </Heading>
              <Flex alignItems="center" gap="size-100" marginStart="size-300">
                <Image size="XS" />
                <Slider
                  aria-label="Zoom"
                  isFilled
                  maxValue={5}
                  minValue={1}
                  onChange={setZoom}
                  value={zoom}
                  width="size-800"
                />
                <Image size="M" />
              </Flex>
              <Divider
                marginEnd="size-300"
                marginStart="size-250"
                orientation="vertical"
                size="M"
              />
              <TabList
                UNSAFE_className={overrideStyles.tabList}
                flexBasis="148px"
              >
                <Item key={CCXTemplateCollectionFormMode.Edit}>
                  <CollectionAdd size="S" />
                  <Text>Update Query</Text>
                </Item>
                <Item key={CCXTemplateCollectionFormMode.File}>
                  <Code size="S" />
                </Item>
              </TabList>
            </Flex>
          </View>
          <TabPanels
            UNSAFE_style={{
              border: 'none',
            }}
            minHeight="size-0"
          >
            <Item key={CCXTemplateCollectionFormMode.Edit}>
              <CCXTemplateCollectionSearch
                collectionQuery={templateQuery}
                onChange={updateTemplateCollectionQuery}
                zoomLevel={zoom}
              />
            </Item>
            <Item key={CCXTemplateCollectionFormMode.File}>
              <CollectionFileLivePreview
                collectionQueryModel={collectionQueryModel}
              />
            </Item>
          </TabPanels>
        </Tabs>
      </Well>
    </Flex>
  );
}
