/**
 * *****************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2022 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Adobe and its suppliers, if any. The intellectual and technical concepts
 * contained herein are proprietary to Adobe and its suppliers and are
 * protected by all applicable intellectual property laws, including trade
 * secret and copyright laws. Dissemination of this information or reproduction
 * of this material is strictly forbidden unless prior written permission is
 * obtained from Adobe.
 * *****************************************************************************
 */

import React, {
  ReactChild,
  ReactElement,
  useCallback,
  useEffect,
  useState,
} from 'react';

import {
  Button,
  ButtonGroup,
  Content,
  Dialog,
  DialogContainer,
  Divider,
  Heading,
  Text,
} from '@adobe/react-spectrum';

type DialogProps<T = any> = {
  buttons?: ReactChild | ReactChild[];
  confirm?: string;
  content?: ReactChild;
  isOpen: boolean;
  onClose: () => void;
  onConfirm: (argument?: T) => void;
  title?: string;
};

export default function ConfirmationDialog({
  buttons,
  confirm,
  content,
  isOpen,
  onClose,
  onConfirm,
  title,
}: DialogProps): ReactElement {
  const [isConfirming, setIsConfirming] = useState<boolean>(false);
  const handleClose = useCallback((): void => {
    setIsConfirming(true);
    onClose();
    setIsConfirming(false);
  }, [onClose, setIsConfirming]);
  const handleConfirm = useCallback(async (): Promise<void> => {
    setIsConfirming(true);

    try {
      onConfirm();
    } catch (error) {
      setIsConfirming(false);
    }
  }, [onConfirm, setIsConfirming]);

  useEffect(() => setIsConfirming(false), [isOpen]);

  return (
    <DialogContainer onDismiss={handleClose}>
      {isOpen && (
        <Dialog>
          <Heading>{title}</Heading>
          <Divider />
          <Content>
            <Text>{content}</Text>
          </Content>
          <ButtonGroup isDisabled={isConfirming}>
            <Button variant="secondary" onPress={handleClose}>
              Cancel
            </Button>
            {buttons}
            <Button variant="cta" onPress={handleConfirm}>
              {confirm}
            </Button>
          </ButtonGroup>
        </Dialog>
      )}
    </DialogContainer>
  );
}

ConfirmationDialog.defaultProps = {
  buttons: undefined,
  confirm: 'Confirm',
  content: 'Are you sure you want to do this?',
  title: 'Confirm Action',
};
