/**
 * *****************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2022 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Adobe and its suppliers, if any. The intellectual and technical concepts
 * contained herein are proprietary to Adobe and its suppliers and are
 * protected by all applicable intellectual property laws, including trade
 * secret and copyright laws. Dissemination of this information or reproduction
 * of this material is strictly forbidden unless prior written permission is
 * obtained from Adobe.
 * *****************************************************************************
 */

import React, { ReactChild, ReactElement } from 'react';

import Header from 'components/Header';
import TempoModal from 'components/TempoModal';
import ToastManager from 'components/ToastManager';

import styles from './DefaultLayout.module.scss';

type DefaultLayoutProps = {
  children: ReactChild | ReactChild[];
  shouldShowNavigation: boolean;
};

export default function DefaultLayout({
  children,
  shouldShowNavigation,
}: DefaultLayoutProps): ReactElement {
  return (
    <div className={styles.main}>
      <TempoModal />
      <div className={styles.header}>
        <Header shouldShowNavigation={shouldShowNavigation} />
      </div>
      <div className={styles.content}>{children}</div>

      <ToastManager />
    </div>
  );
}
