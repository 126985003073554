/**
 * *****************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2022 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Adobe and its suppliers, if any. The intellectual and technical concepts
 * contained herein are proprietary to Adobe and its suppliers and are
 * protected by all applicable intellectual property laws, including trade
 * secret and copyright laws. Dissemination of this information or reproduction
 * of this material is strictly forbidden unless prior written permission is
 * obtained from Adobe.
 * *****************************************************************************
 */

import React, { ReactElement, useCallback, useEffect, useState } from 'react';

import { Flex, Text, View } from '@adobe/react-spectrum';
import { IEnvironment } from '@identity/imslib/adobe-id/IEnvironment';
import Data from '@spectrum-icons/workflow/Data';

import Label from 'components/@spectrum/Label';
import Avatar from 'components/Avatar/Avatar';
import WorkflowActionsMenu from 'components/WorkflowActionsMenu';
import useAdobeIMS from 'hooks/ims/useAdobeIMS';
import { useBehanceUserProfile } from 'hooks/ims/useBehanceUser';
import useConstant from 'hooks/useConstant';
import { SpectrumColor } from 'model/constant/SpectrumColors';
import { AdobeIMSProfile } from 'model/ims/AdobeIMSProfile';
import {
  closeMenu,
  PopoverMenu,
  selectOpenMenu,
  toggleMenu,
} from 'redux/header/header.slice';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import {
  selectIsCurrentUserAdmin,
  selectIsUsingExperimentalRepository,
} from 'redux/user/user.slice';
import {
  selectCurrentJob,
  selectQueueSize,
  WorkflowActionJobStatus,
} from 'redux/workflowActions/workflowActions.slice';

import HeaderNavMenu from './HeaderNavMenu';
import HeaderPopoverMenu from './HeaderPopoverMenu';
import ProfileMenu from './ProfileMenu';

import styles from './Header.module.scss';

type HeaderProps = {
  shouldShowNavigation: boolean;
};

export default function Header({
  shouldShowNavigation,
}: HeaderProps): ReactElement {
  // Hooks
  const { imsEnvironment } = useConstant();
  const isCurrentUserAdmin = useAppSelector(selectIsCurrentUserAdmin);
  const isUsingExperimentalRepository = useAppSelector(
    selectIsUsingExperimentalRepository,
  );
  // Adobe IMS profile
  const [profile, setProfile] = useState<AdobeIMSProfile>();
  const adobeIMS = useAdobeIMS();
  const { results: behanceProfile } = useBehanceUserProfile({
    imsProfile: profile,
    enabled: profile !== undefined,
  });
  const avatarUrl = behanceProfile?.images['138px'];
  // Workflow actions menu badging
  const workflowActionsQueueSize = useAppSelector(selectQueueSize);
  const currentJob = useAppSelector(selectCurrentJob);
  const hasRejectedJob =
    currentJob?.status === WorkflowActionJobStatus.Rejected;
  // Popover menu callbacks
  const openMenu = useAppSelector(selectOpenMenu);
  const dispatch = useAppDispatch();
  const toggleWorkflowActionsMenu = useCallback(
    () => dispatch(toggleMenu(PopoverMenu.ActionsInProgress)),
    [dispatch],
  );
  const toggleProfileMenu = useCallback(
    () => dispatch(toggleMenu(PopoverMenu.Profile)),
    [dispatch],
  );
  const closePopoverMenu = useCallback(() => dispatch(closeMenu()), [dispatch]);

  useEffect((): void => {
    if (adobeIMS && !profile) {
      (async (): Promise<void> => {
        setProfile(await adobeIMS.getProfile());
      })();
    }
  }, [adobeIMS, setProfile, profile]);

  const labels = [];

  if (isUsingExperimentalRepository && imsEnvironment === IEnvironment.STAGE) {
    labels.push(
      <Label key="experimental-stage" color={SpectrumColor.Fuchsia}>
        Project X Stage
      </Label>,
    );
  } else if (isUsingExperimentalRepository) {
    labels.push(
      <Label key="experimental-prod" color={SpectrumColor.Fuchsia}>
        Project X Production
      </Label>,
    );
  } else if (imsEnvironment === IEnvironment.STAGE) {
    labels.push(
      <Label key="ccx-stage" color={SpectrumColor.Seafoam}>
        CCX Stage
      </Label>,
    );
  } else {
    labels.push(
      <Label key="ccx-prod" color={SpectrumColor.Seafoam}>
        CCX Production
      </Label>,
    );
  }

  if (isCurrentUserAdmin) {
    labels.push(
      <Label key="admin" color={SpectrumColor.Blue}>
        Admin
      </Label>,
    );
  }

  return (
    <Flex
      UNSAFE_className={styles.base}
      height="100%"
      alignItems="center"
      gap="size-160"
    >
      <>
        <Text UNSAFE_className={styles.title} id="header-title">
          Adobe Tempo
        </Text>
        {labels}
        {shouldShowNavigation ? <HeaderNavMenu /> : null}
        <View flex />
        {hasRejectedJob ? (
          <Label color="red" UNSAFE_className={styles.error}>
            Workflow Error
          </Label>
        ) : null}

        <HeaderPopoverMenu
          badge={workflowActionsQueueSize}
          hasError={currentJob?.status === WorkflowActionJobStatus.Rejected}
          icon={<Data size="S" />}
          isOpen={
            openMenu === PopoverMenu.ActionsInProgress ||
            openMenu === PopoverMenu.ActionsComplete
          }
          onToggle={toggleWorkflowActionsMenu}
        >
          <WorkflowActionsMenu initialView={openMenu} />
        </HeaderPopoverMenu>

        <HeaderPopoverMenu
          badge={undefined}
          hasError={false}
          isOpen={openMenu === PopoverMenu.Profile}
          icon={<Avatar src={avatarUrl} />}
          onToggle={toggleProfileMenu}
        >
          <ProfileMenu
            onSignOut={(): void => adobeIMS?.signOut()}
            profile={behanceProfile}
          />
        </HeaderPopoverMenu>

        {openMenu !== PopoverMenu.None ? (
          <button
            className={styles.overlay}
            onClick={closePopoverMenu}
            type="button"
          >
            Close
          </button>
        ) : null}
      </>
    </Flex>
  );
}
