/**
 * *****************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2022 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Adobe and its suppliers, if any. The intellectual and technical concepts
 * contained herein are proprietary to Adobe and its suppliers and are
 * protected by all applicable intellectual property laws, including trade
 * secret and copyright laws. Dissemination of this information or reproduction
 * of this material is strictly forbidden unless prior written permission is
 * obtained from Adobe.
 * *****************************************************************************
 */

import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';

import Axios from 'axios';
import * as URITemplate from 'uritemplate';

import HALLink from 'model/acp/HALLink';
import RenditionParams from 'model/acp/RenditionParams';

export type RenditionLinkHookResult = {
  isLoading: boolean;
  renditionLink?: string;
};

export type RenditionLinkParams = {
  templateParams?: Partial<RenditionParams>;
  link?: HALLink | string;
  enabled?: boolean;
};

/**
 * 5 Minutes in milliseconds. In most cases, this rendition shouldn't change
 * too often.
 */
const QUERY_STALE_TIME = 300000;

export default function useRenditionLink({
  templateParams = {},
  link,
  enabled = true,
}: RenditionLinkParams): RenditionLinkHookResult {
  const [blobUrl, setBlobUrl] = useState<string>();
  let url = '';

  if (typeof link === 'string') {
    url = URITemplate.parse(link).expand(templateParams);
  } else if (link !== undefined) {
    if (link.href) {
      url = URITemplate.parse(link.href).expand(templateParams);
    }
  }

  const query = async (): Promise<string> => {
    const { data, headers: responseHeaders } = await Axios.get<ArrayBuffer>(
      url,
      {
        responseType: 'arraybuffer',
        headers: {
          Accept: '*/*',
        },
      },
    );
    const uint8Ar = new Uint8Array(data);
    const blob = new Blob([uint8Ar], {
      type: responseHeaders['content-type'],
    });

    return URL.createObjectURL(blob);
  };
  const { data: renditionLink, isLoading: requestIsLoading } = useQuery<string>(
    ['renditionLink', link],
    query,
    {
      enabled: enabled && !!url,
      staleTime: QUERY_STALE_TIME,
      refetchOnWindowFocus: false,
    },
  );

  useEffect((): void => {
    if (!requestIsLoading && renditionLink !== undefined) {
      /**
       * If the request is no longer loading and a renditionLink now exists,
       * save that rendition link as the blob url for potential cleanup later.
       */

      setBlobUrl(renditionLink);
    } else {
      setBlobUrl(undefined);
    }
  }, [renditionLink, requestIsLoading, setBlobUrl]);

  return {
    isLoading: requestIsLoading,
    renditionLink: blobUrl,
  };
}
