/**
 * *****************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2022 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Adobe and its suppliers, if any. The intellectual and technical concepts
 * contained herein are proprietary to Adobe and its suppliers and are
 * protected by all applicable intellectual property laws, including trade
 * secret and copyright laws. Dissemination of this information or reproduction
 * of this material is strictly forbidden unless prior written permission is
 * obtained from Adobe.
 * *****************************************************************************
 */

import React, { useEffect, useState } from 'react';

import Button from '@react/react-spectrum/Button';
import AnchorSelect from '@react/react-spectrum/Icon/AnchorSelect';
import Checkmark from '@react/react-spectrum/Icon/Checkmark';
import Close from '@react/react-spectrum/Icon/Close';

import styles from './styles/ReviewButtonsToggle.module.scss';

type ReviewButtonsProps = {
  isShowingOptions: boolean;
  onToggle: (toggleOption: number) => void;
  onApprove: () => void;
  onReject: () => void;
};

// allows 3 different toggle states for the edit button
const NUM_TOGGLE_STATES = 3;
const ReviewButtonsToggle: React.FC<ReviewButtonsProps> = ({
  isShowingOptions,
  onToggle,
  onApprove,
  onReject,
}: ReviewButtonsProps) => {
  const [toggleOption, setToggleOption] = useState(0);
  const [showReviewOptions, setShowReviewOptions] = useState(isShowingOptions);

  useEffect(() => {
    setShowReviewOptions(isShowingOptions);

    // reset toggleOption if we're hiding all options
    if (!isShowingOptions) {
      setToggleOption(0);
    }
  }, [isShowingOptions]);

  useEffect(() => {
    switch (toggleOption) {
      case 0:
        setShowReviewOptions(false);
        break;
      default:
        setShowReviewOptions(true);
    }
  }, [toggleOption]);

  const onSelectButtonClick = () => {
    const nextToggle = (toggleOption + 1) % NUM_TOGGLE_STATES;
    onToggle(nextToggle);
    setToggleOption(nextToggle);
  };

  return (
    <div className={styles.reviewButtons}>
      <Button
        aria-label="Review Toggle Select"
        variant="tool"
        icon={<AnchorSelect size="S" />}
        onClick={() => onSelectButtonClick()}
        className={`${styles.button} ${styles.edit} ${
          showReviewOptions ? styles.active : null
        }`}
      />
      {showReviewOptions ? (
        <div className={styles.wrapper}>
          <Button
            variant="tool"
            icon={<Checkmark size="S" />}
            onClick={() => onApprove()}
            className={`${styles.button} ${styles.approve}`}
          />
          <Button
            variant="tool"
            icon={<Close size="S" />}
            onClick={() => onReject()}
            className={`${styles.button} ${styles.reject}`}
          />
        </div>
      ) : null}
    </div>
  );
};

export default ReviewButtonsToggle;
