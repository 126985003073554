/**
 * *****************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2022 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Adobe and its suppliers, if any. The intellectual and technical concepts
 * contained herein are proprietary to Adobe and its suppliers and are
 * protected by all applicable intellectual property laws, including trade
 * secret and copyright laws. Dissemination of this information or reproduction
 * of this material is strictly forbidden unless prior written permission is
 * obtained from Adobe.
 * *****************************************************************************
 */

import React, { ReactChild, ReactElement } from 'react';
import { NavLink } from 'react-router-dom';

import { Flex, Text } from '@adobe/react-spectrum';

import styles from './HeaderNavMenu.module.scss';

type NavElementProps = {
  to: string;
  children: ReactChild | ReactChild[];
};

export default function HeaderNavElement({
  to,
  children,
}: NavElementProps): ReactElement {
  return (
    <NavLink
      className={({ isActive }) =>
        `${styles.navElement} ${isActive ? styles.activeElement : ''}`
      }
      to={to}
      aria-current="location"
    >
      <Flex
        direction="column"
        alignItems="center"
        justifyContent="center"
        height="100%"
      >
        <Text>{children}</Text>
      </Flex>
    </NavLink>
  );
}
