/**
 * *****************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2022 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Adobe and its suppliers, if any. The intellectual and technical concepts
 * contained herein are proprietary to Adobe and its suppliers and are
 * protected by all applicable intellectual property laws, including trade
 * secret and copyright laws. Dissemination of this information or reproduction
 * of this material is strictly forbidden unless prior written permission is
 * obtained from Adobe.
 * *****************************************************************************
 */

import React from 'react';

import Button from '@react/react-spectrum/Button';
import FieldLabel from '@react/react-spectrum/FieldLabel';
import LinkOut from '@react/react-spectrum/Icon/LinkOut';
import Textfield from '@react/react-spectrum/Textfield';

import styles from '__DEPRECATED_DO_NOT_USE_OR_YOU_WILL_BE_FIRED/Detail/styles/Fields.module.scss';

export interface LinkFieldProps {
  label: string;
  value: string;
  link?: string;
  onClick?: (link: string) => void;
  hideIfEmpty?: boolean;
}

const onClickDefault = (link: string) => window.open(link, '_blank');
const LinkField: React.FC<LinkFieldProps> = ({
  label,
  value,
  link,
  onClick = onClickDefault,
  hideIfEmpty,
}: LinkFieldProps) => {
  if (hideIfEmpty && !value) return null;

  return (
    <FieldLabel
      label={label}
      labelFor={`${label}-id`}
      position="left"
      className={styles.linkField}
    >
      <Textfield
        readOnly
        quiet
        value={value}
        className={styles.field}
        id={`${label}-id`}
      />
      {link ? (
        <Button
          aria-label="externalLink"
          onClick={() => onClick(link)}
          variant="tool"
          icon={<LinkOut size="S" />}
          quiet
        />
      ) : null}
    </FieldLabel>
  );
};

LinkField.defaultProps = {
  link: undefined,
  onClick: onClickDefault,
  hideIfEmpty: true,
};

export default LinkField;
