/**
 * *****************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2022 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Adobe and its suppliers, if any. The intellectual and technical concepts
 * contained herein are proprietary to Adobe and its suppliers and are
 * protected by all applicable intellectual property laws, including trade
 * secret and copyright laws. Dissemination of this information or reproduction
 * of this material is strictly forbidden unless prior written permission is
 * obtained from Adobe.
 * *****************************************************************************
 */

import React, { ReactElement } from 'react';

import { Flex, Link, Text, View } from '@adobe/react-spectrum';
import LinkOut from '@spectrum-icons/workflow/LinkOut';

import Avatar from 'components/Avatar/Avatar';
import { BehanceProfile } from 'hooks/ims/useBehanceUser';
import useConstant from 'hooks/useConstant';

import packageJson from '../../../package.json';

import styles from './Header.module.scss';

type ProfileMenuProps = {
  profile: BehanceProfile | undefined;
  onSignOut: () => void;
};

export default function ProfileMenu({
  profile,
  onSignOut,
}: ProfileMenuProps): ReactElement {
  const { tempoxUrl } = useConstant();

  return (
    <div className={`spectrum-Popover is-open ${styles.profileMenu}`}>
      <Flex
        marginTop="size-200"
        marginX="size-200"
        marginBottom="size-100"
        alignItems="center"
      >
        <View>
          <Avatar size="size-700" src={profile?.images['138px']} />
        </View>
        <Flex direction="column" flex marginStart="size-150" minWidth="size-0">
          <Text UNSAFE_className={styles.name}>{profile?.displayName}</Text>
          <Text UNSAFE_className={styles.email}>{profile?.email}</Text>
          <Text UNSAFE_className={styles.email}>{profile?.adobeId}</Text>
        </Flex>
      </Flex>
      <ul className="spectrum-Menu" role="menu">
        <li className="spectrum-Menu-divider" role="separator" />
        <li role="presentation">
          <span className="spectrum-Menu-sectionHeading" aria-hidden="true">
            <span style={{ textTransform: 'none' }}>TempoSpark</span> (v
            {packageJson.version})
          </span>
        </li>
        <li className="spectrum-Menu-item" role="menuitem">
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          <Link UNSAFE_className="spectrum-Menu-itemLabel" isQuiet>
            <a href={tempoxUrl} target="_blank" rel="noreferrer noopener">
              Open TempoX preview environment
            </a>
          </Link>
          <LinkOut size="XS" UNSAFE_className="spectrum-Menu-itemIcon" />
        </li>
        <li className="spectrum-Menu-divider" role="separator" />
        <button
          className={`spectrum-Menu-item ${styles.action}`}
          role="option"
          aria-selected="true"
          onClick={onSignOut}
          type="button"
        >
          Sign out
        </button>
      </ul>
    </div>
  );
}
