/**
 * *****************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2022 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Adobe and its suppliers, if any. The intellectual and technical concepts
 * contained herein are proprietary to Adobe and its suppliers and are
 * protected by all applicable intellectual property laws, including trade
 * secret and copyright laws. Dissemination of this information or reproduction
 * of this material is strictly forbidden unless prior written permission is
 * obtained from Adobe.
 * *****************************************************************************
 */

import React from 'react';

import TagInputSet from '__DEPRECATED_DO_NOT_USE_OR_YOU_WILL_BE_FIRED/components/TagInputSet';

import { CCXConfigOption } from 'model/ccxConfig/CCXConfig';

import { AssetMetadataFormFieldProps } from './AssetMetadataFormFieldProps';
import { useTransformedStringValue } from './useTransformedValues';

export default function LanguagesFormField({
  onChange,
  options,
  value,
}: AssetMetadataFormFieldProps<string | undefined, CCXConfigOption[]>) {
  const {
    onChange: transformedOnChange,
    target,
    transformedOptions,
  } = useTransformedStringValue({ source: value, options, onChange });

  return (
    <TagInputSet
      pluralLabel="Applicable Regions"
      singularLabel="Applicable Region"
      isSingleSelect
      possibleItems={transformedOptions}
      currentItems={target ? [target] : undefined}
      setCurrentItems={(currentItems) =>
        currentItems.length !== 0
          ? transformedOnChange(currentItems[0])
          : onChange(undefined)
      }
    />
  );
}
