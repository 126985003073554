/**
 * *****************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2022 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Adobe and its suppliers, if any. The intellectual and technical concepts
 * contained herein are proprietary to Adobe and its suppliers and are
 * protected by all applicable intellectual property laws, including trade
 * secret and copyright laws. Dissemination of this information or reproduction
 * of this material is strictly forbidden unless prior written permission is
 * obtained from Adobe.
 * *****************************************************************************
 */

import React, {
  Key,
  KeyboardEvent,
  ReactElement,
  useCallback,
  useMemo,
  useState,
} from 'react';

import {
  ActionGroup,
  Flex,
  Grid,
  Heading,
  Item,
  Text,
  View,
} from '@adobe/react-spectrum';
import { ComboBox } from '@react-spectrum/combobox';
import Close from '@spectrum-icons/workflow/Close';
import { sortBy } from 'lodash';

import { TemplateAsset } from '__DEPRECATED_DO_NOT_USE_OR_YOU_WILL_BE_FIRED/controllers/api/SparkSearchTypes';

import FormLabel from 'components/AssetMetadataForm/fields/FormLabel';
import { SearchTemplateCard } from 'components/Card';
import Loading from 'components/Loading';
import ScrollPane from 'components/ScrollPane';
import { useCCXPublicConfig } from 'hooks/useCCXConfig';
import useSparkSearch from 'hooks/useSparkSearch';
import { CCXTemplateCollectionQuery } from 'model/acp/CollectionQueryModel';
import { ContentSearchFilter } from 'model/ContentSearch/ContentSearchFilter';
import ContentSearchQuery from 'model/ContentSearch/ContentSearchQuery';
import { ContentSearchSchema } from 'model/ContentSearch/ContentSearchSchema';
import { OrderBy } from 'model/ContentSearch/OrderBy';
import { ZoomLevel, zoomLevelToSizeMapping } from 'model/ZoomLevel';

type CCXTemplateCollectionSearchProps = {
  collectionQuery: CCXTemplateCollectionQuery;
  onChange: (query: CCXTemplateCollectionQuery) => void;
  zoomLevel: ZoomLevel;
};

export default function CCXTemplateCollectionSearch({
  onChange,
  collectionQuery = {},
  zoomLevel,
}: CCXTemplateCollectionSearchProps) {
  const { results: ccxPublicConfig, isLoading } = useCCXPublicConfig();
  /* Setup task state */
  const tasks = useMemo(() => collectionQuery.tasks ?? [], [collectionQuery]);
  const [taskValue, setTaskValue] = useState<string>('');
  const onTaskChange = useCallback(
    (value: Key) => {
      setTaskValue('');

      if (!value) {
        return;
      }

      if (tasks.includes(value as string)) {
        return;
      }

      onChange({
        ...collectionQuery,
        tasks: tasks.concat([value as string]),
      });
    },
    [collectionQuery, onChange, tasks],
  );
  const onTaskRemove = useCallback(
    (value: Key) => {
      const newTasks = tasks.filter((task) => task !== value);

      onChange({
        ...collectionQuery,
        tasks: newTasks.length === 0 ? undefined : newTasks,
      });
    },
    [collectionQuery, onChange, tasks],
  );
  /* Setup topic state */
  const topics = useMemo(() => collectionQuery.topics ?? [], [collectionQuery]);
  const [topicValue, setTopicValue] = useState<string>('');
  const onTopicChange = useCallback(
    (value: Key) => {
      setTopicValue('');

      if (!value) {
        return;
      }

      if (topics.includes(value as string)) {
        return;
      }

      onChange({
        ...collectionQuery,
        topics: topics.concat([value as string]),
      });
    },
    [collectionQuery, onChange, topics],
  );
  const onTopicKeyDown = useCallback(
    (e: KeyboardEvent) => {
      if (e.key === 'Enter') {
        onTopicChange(topicValue);
      }
    },
    [topicValue, onTopicChange],
  );
  const onTopicRemove = useCallback(
    (value: Key) => {
      const newTopics = topics.filter((topic) => topic !== value);

      onChange({
        ...collectionQuery,
        topics: newTopics.length === 0 ? undefined : newTopics,
      });
    },
    [collectionQuery, onChange, topics],
  );
  /* Preview query */
  const csQuery = useMemo(() => {
    const newQuery = new ContentSearchQuery();
    newQuery.schema = ContentSearchSchema.Template;
    newQuery.orderBy = OrderBy.CreatedDesc;
    newQuery.aggs = ['topics'];
    newQuery.aggsLimit = 20;
    newQuery.limit = 100;

    if (tasks.length !== 0) {
      newQuery.filters.set(ContentSearchFilter.Tasks, tasks);
    }

    if (topics.length !== 0) {
      newQuery.filters.set(ContentSearchFilter.Topics, topics);
    }

    if (tasks.length === 0 && topics.length === 0) {
      newQuery.filters.clear();
    }

    return newQuery;
  }, [tasks, topics]);
  const { results: preview } = useSparkSearch({
    query: csQuery,
  });

  if (!ccxPublicConfig || isLoading) {
    return <Loading />;
  }

  return (
    <Flex direction="column" height="100%">
      <View borderBottomColor="gray-300" borderBottomWidth="thick">
        <Flex>
          <View flex padding="size-250">
            <View marginBottom="size-50">
              <FormLabel>Tasks</FormLabel>
            </View>
            <View marginBottom="size-250">
              <ComboBox
                aria-label="Task List"
                placeholder="Select a task..."
                onSelectionChange={onTaskChange}
                inputValue={taskValue}
                onInputChange={setTaskValue}
                width="100%"
              >
                {ccxPublicConfig.template.tasks.options.map((task) => (
                  <Item key={task.value}>{task.label}</Item>
                ))}
              </ComboBox>
            </View>

            <Flex>
              <ActionGroup onAction={onTaskRemove}>
                {tasks.map((task) => (
                  <Item key={task}>
                    <Text
                      UNSAFE_style={{
                        paddingLeft:
                          'var(--spectrum-global-dimension-size-100)',
                        paddingRight:
                          'var(--spectrum-global-dimension-size-25)',
                      }}
                    >
                      {ccxPublicConfig.template.tasks.options.find(
                        (option) => option.label === task,
                      ) ?? task}
                    </Text>
                    <Close
                      UNSAFE_style={{
                        order: 1,
                      }}
                      size="XS"
                    />
                  </Item>
                ))}
              </ActionGroup>
            </Flex>
          </View>

          <View flex padding="size-250">
            <View marginBottom="size-50">
              <FormLabel>
                <>
                  Topics
                  <small
                    style={{
                      marginLeft: '5px',
                      textTransform: 'none',
                    }}
                  >
                    (Select from the list or type your own)
                  </small>
                </>
              </FormLabel>
            </View>
            <View marginBottom="size-250">
              <ComboBox
                allowsCustomValue
                aria-label="Topic List"
                inputValue={topicValue}
                onInputChange={setTopicValue}
                onKeyUp={onTopicKeyDown}
                onSelectionChange={onTopicChange}
                placeholder="Select a topic..."
                width="100%"
              >
                {preview
                  ? sortBy(preview.aggregations?.topics.buckets, 'key').map(
                      (topic) => <Item key={topic.key}>{topic.key}</Item>,
                    )
                  : []}
              </ComboBox>
            </View>

            <Flex>
              <ActionGroup onAction={onTopicRemove}>
                {topics.map((topic) => (
                  <Item key={topic}>
                    <Text
                      UNSAFE_style={{
                        paddingLeft:
                          'var(--spectrum-global-dimension-size-100)',
                        paddingRight:
                          'var(--spectrum-global-dimension-size-25)',
                      }}
                    >
                      {topic}
                    </Text>
                    <Close
                      UNSAFE_style={{
                        order: 1,
                      }}
                      size="XS"
                    />
                  </Item>
                ))}
              </ActionGroup>
            </Flex>
          </View>
        </Flex>
      </View>
      <View flex>
        <ScrollPane>
          <>
            <Heading marginX="size-300" marginY="size-0">
              Preview{' '}
              {preview ? (
                <Text
                  UNSAFE_style={{
                    fontWeight: 500,
                  }}
                >
                  (Showing {preview.count.toLocaleString()} of{' '}
                  {preview.total.toLocaleString()} matching template
                  {preview.total !== 1 ? 's' : ''})
                </Text>
              ) : (
                ''
              )}
            </Heading>
            {preview && preview.results.length !== 0 ? (
              <Grid
                columns={`repeat(auto-fill, minmax(min(var(${zoomLevelToSizeMapping[zoomLevel]}), 100%), 1fr)`}
                gap="size-300"
                marginTop="size-300"
                marginBottom="size-400"
                marginX="size-300"
              >
                {preview.results.map(
                  (asset: TemplateAsset): ReactElement => (
                    <View key={asset.id}>
                      <SearchTemplateCard
                        key={asset.path}
                        asset={asset}
                        onAction={() => {}}
                        onClick={() => {}}
                        onSelect={() => {}}
                        zoomLevel={zoomLevel}
                      />
                    </View>
                  ),
                )}
              </Grid>
            ) : (
              <Flex
                alignItems="center"
                justifyContent="center"
                width="100%"
                height="size-5000"
              >
                <Text>No preview</Text>
              </Flex>
            )}
          </>
        </ScrollPane>
      </View>
    </Flex>
  );
}
