/**
 * *****************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2022 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Adobe and its suppliers, if any. The intellectual and technical concepts
 * contained herein are proprietary to Adobe and its suppliers and are
 * protected by all applicable intellectual property laws, including trade
 * secret and copyright laws. Dissemination of this information or reproduction
 * of this material is strictly forbidden unless prior written permission is
 * obtained from Adobe.
 * *****************************************************************************
 */

import React, { Key, ReactElement, useCallback, useState } from 'react';

import {
  Button,
  Content,
  Flex,
  Grid,
  Heading,
  IllustratedMessage,
  SearchField,
  Text,
  View,
} from '@adobe/react-spectrum';
import AssetsExpired from '@spectrum-icons/workflow/AssetsExpired';
import BookmarkSingle from '@spectrum-icons/workflow/BookmarkSingle';
import CollectionAdd from '@spectrum-icons/workflow/CollectionAdd';
import CollectionCheck from '@spectrum-icons/workflow/CollectionCheck';
import Filter from '@spectrum-icons/workflow/Filter';
import FolderSearch from '@spectrum-icons/workflow/FolderSearch';
import LinkOut from '@spectrum-icons/workflow/LinkOut';

import { mapAssetToDesignAsset } from '__DEPRECATED_DO_NOT_USE_OR_YOU_WILL_BE_FIRED/controllers/api/SparkSearch';
import { DesignAsset } from '__DEPRECATED_DO_NOT_USE_OR_YOU_WILL_BE_FIRED/controllers/api/SparkSearchTypes';

import ActionButton from 'components/ActionButton';
import FormLabel from 'components/AssetMetadataForm/fields/FormLabel';
import { SearchAssetCard } from 'components/Card';
import FolderDialog from 'components/Dialogs/FolderDialog';
import Loading from 'components/Loading';
import ScrollPane from 'components/ScrollPane';
import ToggleButton from 'components/ToggleButton';
import useSparkSearch from 'hooks/useSparkSearch';
import { Entitlement } from 'model/acp/Entitlement';
import Constant from 'model/constant/Constant';
import { ContentSearchFilter } from 'model/ContentSearch/ContentSearchFilter';
import ContentSearchQuery from 'model/ContentSearch/ContentSearchQuery';
import { SemanticFolder } from 'model/SemanticFolder';
import { SparkSearchAsset } from 'model/SparkSearch/SparkSearchAsset';
import { SparkSearchMetadataKey } from 'model/SparkSearch/SparkSearchMetadataKey';
import { ZoomLevel, zoomLevelToSizeMapping } from 'model/ZoomLevel';

import BulkActionBar from './BulkActionBar';

import styles from './CCXAssetCollectionSearch.module.scss';

type Props = {
  addToCollection: (selected: Set<string>) => void;
  collectionAssetIds: Set<string>;
  initialSearchPath?: string;
  path?: string;
  zoomLevel: ZoomLevel;
};

const { acpBasePath } = new Constant();

export default function CollectionAssetSearch({
  addToCollection,
  collectionAssetIds,
  initialSearchPath,
  path = acpBasePath.author,
  zoomLevel,
}: Props): ReactElement {
  const [selected, setSelected] = useState<Set<string>>(new Set<string>());
  const [searchPath, setSearchPath] = useState<string>(path);
  const [isSearchPathDialogOpen, setIsSearchPathDialogOpen] =
    useState<boolean>(false);
  const [csQuery, setCSQuery] = useState<ContentSearchQuery>(
    (): ContentSearchQuery => {
      const result = new ContentSearchQuery();

      if (initialSearchPath) {
        result.filters.set(ContentSearchFilter.Directory, [initialSearchPath]);
      }

      result.filters.set(ContentSearchFilter.Directory, [path]);
      result.filters.set(ContentSearchFilter.ContentType, ['image/*']);

      return result;
    },
  );
  const onClosePathDialog = useCallback(
    () => setIsSearchPathDialogOpen(false),
    [setIsSearchPathDialogOpen],
  );
  const onConfirmPathDialog = useCallback(
    (newSearchPath: string) => {
      const filters = new Map(csQuery.filters);
      filters.set(ContentSearchFilter.Directory, [newSearchPath]);

      const newCSQuery = new ContentSearchQuery(csQuery);
      newCSQuery.filters = filters;

      setCSQuery(newCSQuery);
      setSearchPath(newSearchPath);
      setIsSearchPathDialogOpen(false);
    },
    [csQuery, setSearchPath, setIsSearchPathDialogOpen],
  );
  const onOpenPathDialog = useCallback(
    () => setIsSearchPathDialogOpen(true),
    [setIsSearchPathDialogOpen],
  );
  const onSubmit = useCallback(
    (newValue: Key): void => {
      const submittedSearchString = String(newValue);
      const newCSQuery = new ContentSearchQuery(csQuery);

      newCSQuery.q = submittedSearchString;
      setCSQuery(newCSQuery);
    },
    [csQuery, setCSQuery],
  );
  const changeContentType = useCallback(
    (isSelected: boolean): void => {
      const filters = new Map(csQuery.filters);

      if (isSelected) {
        filters.delete(ContentSearchFilter.ContentType);
      } else {
        filters.set(ContentSearchFilter.ContentType, ['image/*']);
      }

      const newCSQuery = new ContentSearchQuery(csQuery);
      newCSQuery.filters = filters;

      setCSQuery(newCSQuery);
    },
    [csQuery, setCSQuery],
  );
  const changePremium = useCallback(
    (isSelected: boolean): void => {
      const filters = new Map(csQuery.filters);

      if (isSelected) {
        filters.set(ContentSearchFilter.LicensingCategory, [
          Entitlement.Premium,
        ]);
      } else {
        filters.delete(ContentSearchFilter.LicensingCategory);
      }

      const newCSQuery = new ContentSearchQuery(csQuery);
      newCSQuery.filters = filters;

      setCSQuery(newCSQuery);
    },
    [csQuery, setCSQuery],
  );
  const { results: searchResults, isLoading: isLoadingSearchResults } =
    useSparkSearch({ query: csQuery, enabled: true });
  const clearSelected = useCallback(
    () => setSelected(new Set<string>()),
    [setSelected],
  );
  const handleAddToCollection = useCallback(() => {
    addToCollection(selected);

    clearSelected();
  }, [addToCollection, clearSelected, selected]);
  const onAssetAction = useCallback(
    (asset: DesignAsset) => (assetAction: string) => {
      switch (assetAction) {
        case 'search-in-folder':
          onConfirmPathDialog(asset.path.split('/').slice(0, -1).join('/'));
          break;

        case 'view-details':
          window.open(
            asset.path.replace(
              acpBasePath.publish,
              `/${SemanticFolder.Author}`,
            ),
            '_blank',
          );
          break;
      }
    },
    [onConfirmPathDialog],
  );
  const onAssetSelect = useCallback(
    (assetPath: string) => {
      if (selected.has(assetPath)) {
        setSelected(
          new Set<string>(
            [...selected].filter((selection) => selection !== assetPath),
          ),
        );
      } else {
        setSelected(new Set<string>([...selected, assetPath]));
      }
    },
    [selected, setSelected],
  );

  return (
    <>
      <BulkActionBar selected={selected} onClear={clearSelected}>
        <Button
          onPress={handleAddToCollection}
          variant="overBackground"
          isQuiet
        >
          <CollectionAdd size="S" marginEnd="size-75" />
          <Text marginStart="size-75">Add to Collection</Text>
        </Button>
      </BulkActionBar>
      <Flex direction="column" height="100%">
        <>
          <View
            paddingTop="size-100"
            paddingBottom="size-200"
            marginX="size-300"
          >
            <Flex>
              <SearchField
                label={<FormLabel>Search</FormLabel>}
                placeholder="Enter text"
                onSubmit={onSubmit}
              />
              <Flex direction="column" marginStart="size-100">
                <View marginY="size-25" marginTop="1px">
                  <FormLabel>Path</FormLabel>
                </View>
                <ActionButton onPress={onOpenPathDialog}>
                  <FolderSearch marginStart="-8px" />
                  <Text>{searchPath}</Text>
                </ActionButton>
              </Flex>
              <Flex direction="column" marginStart="size-100">
                <View marginY="size-25" marginTop="1px">
                  <FormLabel>Flags</FormLabel>
                </View>
                <Flex gap="size-100">
                  <ToggleButton
                    isSelected={csQuery.filters.has(
                      ContentSearchFilter.LicensingCategory,
                    )}
                    onChange={changePremium}
                  >
                    <BookmarkSingle size="S" />
                    <Text>Premium</Text>
                  </ToggleButton>
                  <ToggleButton
                    isSelected={
                      !csQuery.filters.has(ContentSearchFilter.ContentType)
                    }
                    onChange={changeContentType}
                  >
                    <Filter size="S" />
                    <Text>Show non-image assets</Text>
                  </ToggleButton>
                </Flex>
              </Flex>
            </Flex>
          </View>
          {searchResults && searchResults.count !== 0 ? (
            <View flex position="relative" minHeight="size-0">
              <ScrollPane>
                <Grid
                  columns={`repeat(auto-fill, minmax(min(var(${zoomLevelToSizeMapping[zoomLevel]}), 100%), 1fr)`}
                  gap="size-300"
                  marginBottom="size-200"
                  marginX="size-300"
                >
                  {(searchResults.results as SparkSearchAsset[]).map(
                    (sparkSearchAsset: SparkSearchAsset): ReactElement => {
                      const assetPath =
                        sparkSearchAsset[SparkSearchMetadataKey.Path];
                      const asset: DesignAsset =
                        mapAssetToDesignAsset(sparkSearchAsset);

                      return (
                        <View key={asset.id} UNSAFE_className={styles.cardView}>
                          <SearchAssetCard
                            key={assetPath}
                            asset={asset}
                            isActive={selected.size !== 0}
                            isSelected={selected.has(asset.id)}
                            onAction={onAssetAction(asset)}
                            onClick={() => onAssetSelect(asset.id)}
                            onSelect={() => onAssetSelect(asset.id)}
                            quickActions={
                              zoomLevel > ZoomLevel.Small
                                ? [
                                    {
                                      icon: FolderSearch,
                                      label: 'Search in Folder',
                                      value: 'search-in-folder',
                                    },
                                    {
                                      icon: LinkOut,
                                      label: 'Go to Asset',
                                      value: 'view-details',
                                    },
                                  ]
                                : undefined
                            }
                            zoomLevel={zoomLevel}
                          />
                          {collectionAssetIds.has(asset.id) ? (
                            <div className={styles.cardOverlay}>
                              <CollectionCheck size="S" />
                              <Text>Asset In Collection</Text>
                            </div>
                          ) : null}
                        </View>
                      );
                    },
                  )}
                </Grid>
              </ScrollPane>
            </View>
          ) : (
            <></>
          )}

          {isLoadingSearchResults ? <Loading /> : null}

          {!isLoadingSearchResults && searchResults?.count === 0 ? (
            <IllustratedMessage flex minHeight="size-0">
              <AssetsExpired size="XXL" />
              <Heading>No assets found</Heading>
              <Content>
                Modify your search to find other assets to add to the collection
              </Content>
            </IllustratedMessage>
          ) : null}
        </>
      </Flex>

      <FolderDialog
        title="Search Directory"
        confirm="Search"
        initialDirectory={searchPath}
        isOpen={isSearchPathDialogOpen}
        onClose={onClosePathDialog}
        onConfirm={onConfirmPathDialog}
      />
    </>
  );
}

CollectionAssetSearch.defaultProps = {
  initialSearchPath: undefined,
  path: acpBasePath.publish,
};
