/**
 * *****************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2022 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Adobe and its suppliers, if any. The intellectual and technical concepts
 * contained herein are proprietary to Adobe and its suppliers and are
 * protected by all applicable intellectual property laws, including trade
 * secret and copyright laws. Dissemination of this information or reproduction
 * of this material is strictly forbidden unless prior written permission is
 * obtained from Adobe.
 * *****************************************************************************
 */

import Constant from 'model/constant/Constant';

import { ContentSearchFilter } from './ContentSearchFilter';
import { ContentSearchSchema } from './ContentSearchSchema';
import { OrderBy } from './OrderBy';

const { defaultLanguage } = new Constant();
export default class ContentSearchQuery {
  public aggs: string[] = [];
  public aggsLimit: number;
  public limit: number = 100;
  public orderBy: OrderBy = OrderBy.CreateDateDesc;
  public filters = new Map<ContentSearchFilter, string[]>();
  public schema: ContentSearchSchema = ContentSearchSchema.Asset;
  public q: string;
  public qLoc: string = defaultLanguage;
  public constructor(cloneFrom?: ContentSearchQuery) {
    if (cloneFrom) {
      Object.assign(this, cloneFrom);
    }
  }
  public toString(): string {
    let queryString = `?q=${this.q ? `"${this.q}"` : '*'}`;

    for (const prop of Object.keys(this)) {
      const key = prop as keyof ContentSearchQuery;
      const value = this[key];

      if (
        value &&
        typeof value !== 'function' &&
        key !== 'q' &&
        key !== 'filters'
      ) {
        if ((Array.isArray(value) && value.length) || !Array.isArray(value)) {
          queryString = `${queryString}&${key}=${value}`;
        }
      }

      if (key === 'filters') {
        let queryFilters = '';

        for (const [filter, values] of this.filters) {
          if (queryFilters) {
            queryFilters = `${queryFilters}+AND+`;
          }

          queryFilters = `${queryFilters}${filter}:(${values.map(
            (filterValue: string): string => `"${filterValue}"`,
          )})`;
        }

        if (queryFilters.length !== 0) {
          queryString = `${queryString}&${key}=${queryFilters}`;
        }
      }
    }

    return queryString;
  }
}
