/**
 * *****************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2022 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Adobe and its suppliers, if any. The intellectual and technical concepts
 * contained herein are proprietary to Adobe and its suppliers and are
 * protected by all applicable intellectual property laws, including trade
 * secret and copyright laws. Dissemination of this information or reproduction
 * of this material is strictly forbidden unless prior written permission is
 * obtained from Adobe.
 * *****************************************************************************
 */

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export enum ScreenView {
  Grid = 'grid',
  Detail = 'detail',
}

export interface TempoState {
  view: ScreenView;
  detailViewId: string;
  doRefresh: boolean;
  selectionEnabled: boolean;
}

const initialState: TempoState = {
  view: ScreenView.Grid,
  detailViewId: '',
  doRefresh: false,
  selectionEnabled: false,
};

export const screenSlice = createSlice({
  name: 'screen',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    doRefresh: (state) => {
      state.doRefresh = !state.doRefresh;
    },
    setDetailViewId: (state, action: PayloadAction<string>) => {
      state.detailViewId = action.payload;
    },
    setScreenView: (state, action: PayloadAction<ScreenView>) => {
      state.view = action.payload;
    },
    setSelectionEnabled: (state, action: PayloadAction<boolean>) => {
      state.selectionEnabled = action.payload;
    },
  },
});

export const {
  doRefresh,
  setDetailViewId,
  setScreenView,
  setSelectionEnabled,
} = screenSlice.actions;

export default screenSlice.reducer;
