/**
 * *****************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2022 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Adobe and its suppliers, if any. The intellectual and technical concepts
 * contained herein are proprietary to Adobe and its suppliers and are
 * protected by all applicable intellectual property laws, including trade
 * secret and copyright laws. Dissemination of this information or reproduction
 * of this material is strictly forbidden unless prior written permission is
 * obtained from Adobe.
 * *****************************************************************************
 */

import React, {
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';

import {
  Button,
  ButtonGroup,
  ComboBox,
  Content,
  Dialog,
  DialogContainer,
  Divider,
  Heading,
  Item,
  Text,
  TextField,
  View,
} from '@adobe/react-spectrum';

import useCurrentAssetByPath from 'hooks/useCurrentAssetByPath';
import useFolderACLModel, {
  UserACLModel,
  UserGroupACLModel,
} from 'hooks/useFolderACLModel';
import useOrgUserGroups from 'hooks/useOrgUserGroups';
import useOrgUsers from 'hooks/useOrgUsers';
import { AccessLevel } from 'model/AccessLevel';
import { UserGroupProfile } from 'model/acp/UserGroupProfile';
import { UserProfile } from 'model/acp/UserProfile';

type DialogProps = {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: (user: string, accessInput: AccessLevel) => void;
  isUserGroup: boolean;
};

export default function ShareWithUserDialog({
  isUserGroup,
  isOpen,
  onClose,
  onConfirm,
}: DialogProps): ReactElement {
  const { currentAssetByPath } = useCurrentAssetByPath();
  const [isConfirming, setIsConfirming] = useState<boolean>(false);
  const [userInput, setUserInput] = useState<string>('');
  const [matchedInput, setMatchedInput] = useState<string>('');
  const [accessLevelInput, setAccessLevelInput] = useState<AccessLevel>(
    AccessLevel.Read,
  );
  const [isUserInOrg, setIsUserInOrg] = useState<boolean>(false);
  const [isNotExistingUserOnFolderACL, setIsNotExistingUserOnFolderACL] =
    useState<boolean | undefined>(false);
  const { result: orgUserGroups, isLoading: isUserGroupsLoading } =
    useOrgUserGroups();
  const {
    isLoading: isFolderACLModelLoading,
    userItems,
    userGroupItems,
  } = useFolderACLModel();
  const { result: orgUsers, isLoading: isUsersLoading } = useOrgUsers();
  useEffect(() => {
    if (!userInput) {
      return;
    }

    const normalizedUserInput = userInput.toLowerCase();

    if (isUserGroup) {
      if (!isUserGroupsLoading) {
        const existingUserGroupInOrg = orgUserGroups?.find(
          (group: UserGroupProfile) =>
            group.normalizedName === normalizedUserInput,
        );
        const isUserGroupInExistingACL = userGroupItems?.find(
          (group: UserGroupACLModel) =>
            group.normalizedName === normalizedUserInput,
        );
        setIsUserInOrg(existingUserGroupInOrg !== undefined);
        setIsNotExistingUserOnFolderACL(isUserGroupInExistingACL === undefined);

        setMatchedInput(existingUserGroupInOrg?.name ?? '');
      }
    } else if (!isUsersLoading) {
      const existingUserInOrg = orgUsers?.find(
        (user: UserProfile) => user.normalizedEmail === normalizedUserInput,
      );
      const isUserInExistingACL = userItems?.find(
        (user: UserACLModel) => user.normalizedEmail === normalizedUserInput,
      );

      setIsUserInOrg(existingUserInOrg !== undefined);
      setIsNotExistingUserOnFolderACL(isUserInExistingACL === undefined);

      setMatchedInput(existingUserInOrg?.email ?? '');
    }
  }, [
    setMatchedInput,
    isUserGroup,
    isUserGroupsLoading,
    isUsersLoading,
    userInput,
    isFolderACLModelLoading,
    orgUserGroups,
    userGroupItems,
    orgUsers,
    userItems,
  ]);

  const getValidationState = useMemo(() => {
    if (userInput === '') {
      return undefined;
    }

    return isUserInOrg && isNotExistingUserOnFolderACL ? 'valid' : 'invalid';
  }, [isNotExistingUserOnFolderACL, isUserInOrg, userInput]);
  const handleClose = useCallback(async () => {
    setIsConfirming(true);
    setUserInput('');
    setAccessLevelInput(AccessLevel.Read);
    await onClose();
    setIsConfirming(false);
  }, [onClose, setIsConfirming]);
  const handleConfirm = useCallback(async () => {
    setIsConfirming(true);

    try {
      await onConfirm(matchedInput, accessLevelInput);
      await onClose();
      setUserInput('');
      setAccessLevelInput(AccessLevel.Read);
    } catch (error) {
      setIsConfirming(false);
    }
  }, [accessLevelInput, onClose, onConfirm, matchedInput]);
  const handleAccessLevelChange = useCallback(
    (key) => {
      const accessLevel: AccessLevel = key as AccessLevel;
      setAccessLevelInput(accessLevel);
    },
    [setAccessLevelInput],
  );

  useEffect(() => setIsConfirming(false), [isOpen]);

  const getDialogStringConstants = useMemo(() => {
    const heading = (
      <Text>
        Share <code>{currentAssetByPath}</code>{' '}
        {`with ${isUserGroup ? 'user group' : 'user'}`}
      </Text>
    );

    if (isUserGroup) {
      return {
        heading,
        label: 'Name',
      };
    }

    return {
      heading,
      label: 'Email',
    };
  }, [isUserGroup, currentAssetByPath]);

  return (
    <DialogContainer onDismiss={handleClose}>
      {isOpen && (
        <Dialog>
          <Heading>{getDialogStringConstants.heading}</Heading>
          <Divider />
          <Content>
            <View>
              <TextField
                width="size-6000"
                value={userInput}
                label={getDialogStringConstants.label}
                onChange={setUserInput}
                type="search"
                validationState={getValidationState}
              />
            </View>
            <View>
              <ComboBox
                label="Access level"
                width="size-3000"
                inputValue={accessLevelInput}
                onSelectionChange={handleAccessLevelChange}
              >
                <Item key={AccessLevel.Read}>{AccessLevel.Read}</Item>
                <Item key={AccessLevel.Edit}>{AccessLevel.Edit}</Item>
                <Item key={AccessLevel.Owner}>{AccessLevel.Owner}</Item>
              </ComboBox>
            </View>
          </Content>
          <ButtonGroup isDisabled={isConfirming}>
            <Button variant="secondary" onPress={handleClose}>
              Cancel
            </Button>
            <Button
              variant="cta"
              isDisabled={getValidationState !== 'valid'}
              onPress={handleConfirm}
            >
              Share
            </Button>
          </ButtonGroup>
        </Dialog>
      )}
    </DialogContainer>
  );
}
