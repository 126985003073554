/**
 * *****************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2022 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Adobe and its suppliers, if any. The intellectual and technical concepts
 * contained herein are proprietary to Adobe and its suppliers and are
 * protected by all applicable intellectual property laws, including trade
 * secret and copyright laws. Dissemination of this information or reproduction
 * of this material is strictly forbidden unless prior written permission is
 * obtained from Adobe.
 * *****************************************************************************
 */

import Url from 'model/constant/Url';

export default class StageUrl implements Url {
  public readonly searchServiceBase = 'https://spark-search-stage.adobe.io';
  public readonly cpAPIBase = 'https://cc-api-cp-stage.adobe.io/api/v2';
  public readonly cpCuratorBase = 'https://cp-stage.paas.corp.adobe.com';
  public readonly platformBase = 'https://platform-cs-stage.adobe.io';
  public readonly platformACLPolicyBase =
    'https://platform-cs-stage-va6.adobe.io/content/acl/policy/';
  public readonly platformStorageBase =
    'https://platform-cs-stage-va6.adobe.io/content/storage';
  public readonly ccxBase = 'https://express-stage.adobeprojectm.com';
  public readonly publishServiceBase =
    'https://spark-content-publish-stage.adobe.io';
  public readonly ddamBase = 'https://experience-stage.adobe.com';
  public readonly mtBase = 'https://mt-stage.adobe.io/api/v4/translate';
}
