/**
 * *****************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2022 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Adobe and its suppliers, if any. The intellectual and technical concepts
 * contained herein are proprietary to Adobe and its suppliers and are
 * protected by all applicable intellectual property laws, including trade
 * secret and copyright laws. Dissemination of this information or reproduction
 * of this material is strictly forbidden unless prior written permission is
 * obtained from Adobe.
 * *****************************************************************************
 */

/**
 * Used as the status property for any asset
 */
export enum AssetStatus {
  approved = 'approved',
  pending = 'pending',
  rejected = 'rejected',
  published = 'published',
  unpublished = 'unpublished',
  dirty = 'dirty',
  error = 'error',
  loading = 'loading',
  draft = 'draft',
}

export enum AssetAction {
  move = 'Move',
  save = 'Save',
  publish = 'Publish',
  unpublish = 'Unpublish',
  approve = 'Approve',
  pending = 'Pending',
  reject = 'Reject',
}

export type ActionInfo = {
  name: AssetAction;
  message: string;
  reload?: boolean;
};
