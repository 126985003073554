/**
 * *****************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2022 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Adobe and its suppliers, if any. The intellectual and technical concepts
 * contained herein are proprietary to Adobe and its suppliers and are
 * protected by all applicable intellectual property laws, including trade
 * secret and copyright laws. Dissemination of this information or reproduction
 * of this material is strictly forbidden unless prior written permission is
 * obtained from Adobe.
 * *****************************************************************************
 */

export enum ACPLink {
  ACCheck = 'http://ns.adobe.com/adobecloud/rel/ac/check',
  ACEffective = 'http://ns.adobe.com/adobecloud/rel/ac/effective',
  ACPolicy = 'http://ns.adobe.com/adobecloud/rel/ac/policy',
  ApplicationMetadata = 'http://ns.adobe.com/adobecloud/rel/metadata/application',
  BlockDownload = 'http://ns.adobe.com/adobecloud/rel/download',
  BlockExtend = 'http://ns.adobe.com/adobecloud/rel/block/extend',
  BlockFinalize = 'http://ns.adobe.com/adobecloud/rel/block/finalize',
  BlockTransfer = 'http://ns.adobe.com/adobecloud/rel/block/transfer',
  BlockUploadInit = 'http://ns.adobe.com/adobecloud/rel/block/init',
  Component = 'http://ns.adobe.com/adobecloud/rel/component',
  Create = 'http://ns.adobe.com/adobecloud/rel/create',
  Directory = 'http://ns.adobe.com/adobecloud/rel/directory',
  DirectoryBase = 'http://ns.adobe.com/adobecloud/rel/directory/base',
  Discard = 'http://ns.adobe.com/adobecloud/rel/discard',
  Download = 'http://ns.adobe.com/adobecloud/rel/download',
  EmbeddedMetadata = 'http://ns.adobe.com/adobecloud/rel/metadata/embedded',
  Id = 'http://ns.adobe.com/adobecloud/rel/id',
  Manifest = 'http://ns.adobe.com/adobecloud/rel/manifest',
  Next = 'next',
  Ops = 'http://ns.adobe.com/adobecloud/rel/ops',
  Page = 'http://ns.adobe.com/adobecloud/rel/page',
  Path = 'http://ns.adobe.com/adobecloud/rel/path',
  Primary = 'http://ns.adobe.com/adobecloud/rel/primary',
  Rendition = 'http://ns.adobe.com/adobecloud/rel/rendition',
  RepoMetadata = 'http://ns.adobe.com/adobecloud/rel/metadata/repository',
  Repository = 'http://ns.adobe.com/adobecloud/rel/repository',
  ResolveId = 'http://ns.adobe.com/adobecloud/rel/resolve/id',
  ResolvePath = 'http://ns.adobe.com/adobecloud/rel/resolve/path',
  Restore = 'http://ns.adobe.com/adobecloud/rel/restore',
  VersionHistory = 'version-history',
  Headers = 'acp-headers',
}
