/**
 * *****************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2022 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Adobe and its suppliers, if any. The intellectual and technical concepts
 * contained herein are proprietary to Adobe and its suppliers and are
 * protected by all applicable intellectual property laws, including trade
 * secret and copyright laws. Dissemination of this information or reproduction
 * of this material is strictly forbidden unless prior written permission is
 * obtained from Adobe.
 * *****************************************************************************
 */

import React, {
  ReactElement,
  RefObject,
  useCallback,
  useRef,
  useState,
} from 'react';
import { useNavigate } from 'react-router-dom';

import { Button, Flex, Text, View } from '@adobe/react-spectrum';
import Info from '@spectrum-icons/workflow/Info';
import InfoOutline from '@spectrum-icons/workflow/InfoOutline';
import inflection from 'inflection';

import ConfirmationDialog from 'components/Dialogs/ConfirmationDialog';
import { CreateFolderFormDialog } from 'components/Dialogs/FormDialog';
import useAssetWorkflowActions, {
  RawAssetBody,
} from 'hooks/useAssetWorkflowActions';
import useConstant from 'hooks/useConstant';
import useCurrentAssetByPath from 'hooks/useCurrentAssetByPath';
import useWorkflow from 'hooks/useWorkflow';
import { AssetMimeType } from 'model/acp/AssetMimeType';
import { AssetActionDomain } from 'model/app/AssetActionDomain';
import { Workflow } from 'model/app/Workflow';
import { WorkflowAction } from 'model/app/WorkflowAction';
import { WorkflowActionIconMap } from 'model/app/WorkflowActionIconMap';
import { DocumentRailPane } from 'model/DocumentRailPane';
import { setDocumentRailPane } from 'redux/explorer/explorer.slice';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import {
  queueJob,
  WorkflowActionJobStatus,
} from 'redux/workflowActions/workflowActions.slice';
import { columnToMetadataKeyMap } from 'utils/csv';
import { parseDataToCSV, parseFileAsText } from 'utils/file';

import RailElement from './RailElement';

import styles from './DocumentActionRail.module.scss';

enum UploadType {
  Audio,
  Generic,
  Template,
}

export default function DocumentActionRail(): ReactElement {
  const { acpBasePath } = useConstant();
  const { currentAssetByPath } = useCurrentAssetByPath();
  const { workflow } = useWorkflow({
    actionDomain: AssetActionDomain.Bulk,
    actionContext: 'folder',
  });
  const assetWorkflowActions = useAssetWorkflowActions();
  const dispatch = useAppDispatch();
  // Popover details
  const { documentRailPane } = useAppSelector((state) => state.explorer);
  const openFolderDetails = useCallback(() => {
    dispatch(setDocumentRailPane(DocumentRailPane.Details));
  }, [dispatch]);
  // Collection creation
  const navigate = useNavigate();
  const onCreateCollection = useCallback(
    () => navigate(`/collections/new?path=${currentAssetByPath}`),
    [navigate, currentAssetByPath],
  );
  // Folder creation
  const [isCreatingFolder, setIsCreatingFolder] = useState<boolean>(false);
  const onCreateFolder = useCallback(
    () => setIsCreatingFolder(true),
    [setIsCreatingFolder],
  );
  const onConfirmCreateFolder = useCallback(
    async (folderToCreateName: string) => {
      await assetWorkflowActions.createFolder(
        currentAssetByPath,
        folderToCreateName,
      );

      setIsCreatingFolder(false);
    },
    [assetWorkflowActions, currentAssetByPath, setIsCreatingFolder],
  );
  // Asset creation
  const [filesToUpload, setFilesToUpload] = useState<File[]>();
  const uploadFilesRef: RefObject<HTMLInputElement> = useRef(null);
  const onUploadFiles = useCallback(() => {
    if (uploadFilesRef.current !== null) {
      uploadFilesRef.current.click();
    }
  }, [uploadFilesRef]);
  const onUploadFilesInput = useCallback(
    (event) => {
      event.stopPropagation();

      if (!uploadFilesRef.current) {
        return;
      }

      const files = Array.from(uploadFilesRef.current.files as FileList);

      uploadFilesRef.current.value = '';

      setFilesToUpload(files);
    },
    [uploadFilesRef, setFilesToUpload],
  );
  const onConfirmUpload = useCallback(
    async (fileType: UploadType) => {
      if (!filesToUpload) {
        return;
      }

      let contentType: string | undefined;
      let blockContentType: string | undefined;

      switch (fileType) {
        case UploadType.Audio:
          contentType = AssetMimeType.AudioDCX;
          blockContentType = AssetMimeType.AudioDCXSnapshot;
          break;

        case UploadType.Template:
          contentType = AssetMimeType.TemplateDCX;
          blockContentType = AssetMimeType.TemplateDCXSnapshot;
          break;
      }

      dispatch(
        queueJob({
          task: {
            assets: filesToUpload.map((file) => ({
              body: file,
              assetPath: file.name,
            })),
            action: async (_, body) => {
              try {
                await assetWorkflowActions.createFile(
                  currentAssetByPath,
                  body,
                  {
                    contentType,
                    blockContentType,
                  },
                );

                return `Uploaded "${body.name}" to ${currentAssetByPath}`;
              } catch (error) {
                if ((error as Error).message === 'Failed') {
                  throw new Error(`Failed to upload "${body.name}"`);
                }

                throw new Error(
                  `Failed to upload "${body.name}": ${
                    (error as Error).message
                  }`,
                );
              }
            },
            message: (workflowActionAssets) => {
              if (
                workflowActionAssets.some(
                  (asset) => asset.status !== WorkflowActionJobStatus.Fulfilled,
                )
              ) {
                const failedAssets = workflowActionAssets.filter(
                  (asset) => asset.status !== WorkflowActionJobStatus.Fulfilled,
                );

                throw new Error(
                  `Upload failed for ${failedAssets.length} of ${workflowActionAssets.length} assets.`,
                );
              }

              return `Uploaded ${
                workflowActionAssets.length
              } ${inflection.inflect('asset', workflowActionAssets.length)}.`;
            },
          },
          message: `Uploading ${filesToUpload.length} ${inflection.inflect(
            'asset',
            filesToUpload.length,
          )}...`,
        }),
      );

      setFilesToUpload(undefined);
    },
    [
      assetWorkflowActions,
      filesToUpload,
      setFilesToUpload,
      dispatch,
      currentAssetByPath,
    ],
  );
  // Metadata import
  const uploadFieldRef: RefObject<HTMLInputElement> = useRef(null);
  const [fileToImport, setFileToImport] = useState<File>();
  const onMetadataImport = useCallback(() => {
    if (uploadFieldRef.current !== null) {
      uploadFieldRef.current.click();
    }
  }, [uploadFieldRef]);
  const onMetadataFileInput = useCallback(
    (event) => {
      event.stopPropagation();

      if (!uploadFieldRef.current) {
        return;
      }

      const [file] = uploadFieldRef.current.files as FileList;
      uploadFieldRef.current.value = '';

      setFileToImport(file);
    },
    [uploadFieldRef],
  );
  const onConfirmImport = useCallback(async () => {
    // Process CSV file
    const data = await parseFileAsText(fileToImport as File);

    if (data === undefined) {
      return;
    }

    const assetsToUpdate = await parseDataToCSV<RawAssetBody>(
      data,
      columnToMetadataKeyMap,
    );
    setFileToImport(undefined);

    dispatch(
      queueJob({
        message: `Updating ${assetsToUpdate.length} assets from CSV...`,
        task: {
          assets: assetsToUpdate.map((asset) => ({
            assetPath: asset.path,
          })),
          action: (assetPath) =>
            assetWorkflowActions.updateRaw(
              assetPath,
              assetsToUpdate.find(
                (asset) => asset.path === assetPath,
              ) as RawAssetBody,
            ),
          message: (workflowActionAssets) => {
            if (
              workflowActionAssets.some(
                (asset) => asset.status !== WorkflowActionJobStatus.Fulfilled,
              )
            ) {
              const failedAssets = workflowActionAssets.filter(
                (asset) => asset.status !== WorkflowActionJobStatus.Fulfilled,
              );

              throw new Error(
                `Failed to update ${failedAssets.length} of ${workflowActionAssets.length} assets from CSV.`,
              );
            }

            return `Updated ${workflowActionAssets.length} assets from CSV.`;
          },
        },
      }),
    );
  }, [assetWorkflowActions, fileToImport, dispatch]);
  const buttons = [
    <RailElement
      key="details"
      action={openFolderDetails}
      icon={
        documentRailPane === DocumentRailPane.Details ? (
          <Info size="S" />
        ) : (
          <InfoOutline size="S" />
        )
      }
      isActive={documentRailPane === DocumentRailPane.Details}
      isDisabled={
        currentAssetByPath === acpBasePath.author ||
        currentAssetByPath === acpBasePath.submit
      }
      label="Folder Details"
    />,
    <View
      key="separator"
      borderBottomColor="gray-400"
      borderBottomWidth="thin"
    />,
    <RailElement
      key={WorkflowAction.UploadFiles}
      action={onUploadFiles}
      icon={WorkflowActionIconMap.get(WorkflowAction.UploadFiles) ?? <></>}
      label={WorkflowAction.UploadFiles}
    />,
    <RailElement
      key={WorkflowAction.CreateCollection}
      action={onCreateCollection}
      icon={WorkflowActionIconMap.get(WorkflowAction.CreateCollection) ?? <></>}
      label={WorkflowAction.CreateCollection}
    />,
    <RailElement
      key={WorkflowAction.CreateFolder}
      action={onCreateFolder}
      icon={WorkflowActionIconMap.get(WorkflowAction.CreateFolder) ?? <></>}
      label={WorkflowAction.CreateFolder}
    />,
  ];

  if (workflow === Workflow.Publish) {
    buttons.push(
      <RailElement
        key={WorkflowAction.ImportMetadata}
        action={onMetadataImport}
        icon={WorkflowActionIconMap.get(WorkflowAction.ImportMetadata) ?? <></>}
        label={WorkflowAction.ImportMetadata}
      />,
    );
  }

  return (
    <>
      <div className={styles.actionRail}>
        <input
          ref={uploadFieldRef}
          onInput={onMetadataFileInput}
          className={styles.uploadInput}
          accept=".csv"
          type="file"
          key="uploadMetadata"
        />

        <input
          ref={uploadFilesRef}
          onInput={onUploadFilesInput}
          className={styles.uploadInput}
          type="file"
          key="uploadFile"
          multiple
        />

        <Flex gap="size-100" direction="column">
          {buttons}
        </Flex>
      </div>

      <ConfirmationDialog
        isOpen={fileToImport !== undefined}
        onClose={() => setFileToImport(undefined)}
        onConfirm={onConfirmImport}
        confirm="Import"
        content={
          <Text>
            Are you sure you want to import{' '}
            <code className={styles.fileName}>{fileToImport?.name}</code> for
            metadata processing?
          </Text>
        }
        title={WorkflowAction.ImportMetadata}
      />

      <ConfirmationDialog
        buttons={
          <>
            <Button
              variant="primary"
              onPress={() => onConfirmUpload(UploadType.Template)}
            >
              Upload as Template
            </Button>
            <Button
              variant="primary"
              onPress={() => onConfirmUpload(UploadType.Audio)}
            >
              Upload as Audio DCX
            </Button>
          </>
        }
        isOpen={filesToUpload !== undefined}
        onClose={() => setFilesToUpload(undefined)}
        onConfirm={() => onConfirmUpload(UploadType.Generic)}
        confirm={`Upload as ${inflection.inflect(
          'Asset',
          filesToUpload?.length ?? 1,
        )}`}
        content={
          <Text>
            Are you sure you want to upload:
            <ul>
              {filesToUpload?.map((file) => (
                <li className={styles.fileNameItem} key={file.name}>
                  <code className={styles.fileName}>{file.name}</code>
                </li>
              ))}
            </ul>
            to <code className={styles.fileName}>{currentAssetByPath}</code>?
          </Text>
        }
        title={WorkflowAction.UploadFiles}
      />

      <CreateFolderFormDialog
        isDisabled={false}
        isOpen={isCreatingFolder}
        onClose={() => setIsCreatingFolder(false)}
        onCreate={onConfirmCreateFolder}
      />
    </>
  );
}
