/**
 * *****************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2022 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Adobe and its suppliers, if any. The intellectual and technical concepts
 * contained herein are proprietary to Adobe and its suppliers and are
 * protected by all applicable intellectual property laws, including trade
 * secret and copyright laws. Dissemination of this information or reproduction
 * of this material is strictly forbidden unless prior written permission is
 * obtained from Adobe.
 * *****************************************************************************
 */

import React, {
  JSXElementConstructor,
  ReactElement,
  useCallback,
  useState,
} from 'react';

import { Button, Flex, Text, View } from '@adobe/react-spectrum';
import Close from '@spectrum-icons/workflow/Close';

import ConfirmationDialog from 'components/Dialogs/ConfirmationDialog';
import FolderDialog from 'components/Dialogs/FolderDialog';
import { RenameAssetFormDialog } from 'components/Dialogs/FormDialog';
import useAssetWorkflowActions, {
  AssetMetadata,
} from 'hooks/useAssetWorkflowActions';
import useConstant from 'hooks/useConstant';
import useCurrentAssetByPath from 'hooks/useCurrentAssetByPath';
import useWorkflow from 'hooks/useWorkflow';
import { CollectionFile } from 'model/acp/CollectionFile';
import RepoMetadata from 'model/acp/RepoMetadata';
import { RepoMetadataKey } from 'model/acp/RepoMetadataKey';
import { AssetAction } from 'model/app/AssetAction';
import { AssetActionDomain } from 'model/app/AssetActionDomain';
import { AssetActionIconMap } from 'model/app/AssetActionIconMap';
import { clearSelectedAssetPaths } from 'redux/explorer/explorer.slice';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { selectACPRepoId } from 'redux/user/user.slice';
import {
  addAssetsToCurrentJob,
  queueJob,
  WorkflowActionJobAsset,
  WorkflowActionJobStatus,
} from 'redux/workflowActions/workflowActions.slice';
import { isCollection, isDirectory } from 'utils/assets';
import { buildCSVExport, columnToMetadataKeyMap, formatCSV } from 'utils/csv';
import { downloadCSV } from 'utils/file';
import { getRepoMetadataForAsset } from 'utils/metadata/repoMetadata';
import { verifyCollectionFile } from 'utils/validation/collections';

import MetadataExportDialog from './MetadataExportDialog';

import styles from './WorkflowActionsBar.module.scss';

export default function WorkflowActionsBar(): ReactElement {
  // Set app hooks
  const { acpBasePath, url } = useConstant();
  const acpRepoId = useAppSelector(selectACPRepoId);
  const assetWorkflowActions = useAssetWorkflowActions();
  const dispatch = useAppDispatch();
  // Set workflow hooks
  const { assetActions, individualActions } = useWorkflow({
    actionDomain: AssetActionDomain.Bulk,
    actionContext: 'file',
  });
  const { currentAssetByPath } = useCurrentAssetByPath();
  // Set state hooks
  const [assetsToApprove, setAssetsToApprove] =
    useState<WorkflowActionJobAsset<unknown>[]>();
  const [assetsToMove, setAssetsToMove] =
    useState<WorkflowActionJobAsset<unknown>[]>();
  const [assetsToExport, setAssetsToExport] =
    useState<WorkflowActionJobAsset<AssetMetadata>[]>();
  const [assetToRename, setAssetToRename] = useState<RepoMetadata>();
  const [assetsToDelete, setAssetsToDelete] =
    useState<WorkflowActionJobAsset<unknown>[]>();
  // Set callbacks
  const { selectedAssetPaths } = useAppSelector((state) => state.explorer);
  const hideFolderDialog = useCallback(() => {
    setAssetsToApprove(undefined);
    setAssetsToMove(undefined);
    setAssetsToDelete(undefined);
  }, [setAssetsToApprove, setAssetsToMove]);
  const clearSelectedAssets = useCallback(
    () => dispatch(clearSelectedAssetPaths()),
    [dispatch],
  );
  const handleBulkAction = useCallback(
    (action: AssetAction) => async () => {
      const assets = Array.from(selectedAssetPaths).map((path) => ({
        assetPath: path,
      }));
      let repoMetadata: RepoMetadata | undefined;

      switch (action) {
        case AssetAction.Approve:
          setAssetsToApprove(assets);
          break;

        case AssetAction.MetadataExport:
          setAssetsToExport(assets);
          break;

        case AssetAction.Move:
          setAssetsToMove(assets);
          break;

        case AssetAction.Download:
          ({ result: repoMetadata } = await getRepoMetadataForAsset(
            assets[0].assetPath,
            {
              acpRepoId,
              url,
            },
          ));

          dispatch(
            queueJob({
              message: `Generating asset "${
                repoMetadata[RepoMetadataKey.Name]
              }" for download...`,
              action: async (): Promise<string> => {
                if (!repoMetadata) {
                  throw new Error(
                    'Failed to generate asset for download: no reference to asset',
                  );
                }

                try {
                  await assetWorkflowActions.download(
                    repoMetadata[RepoMetadataKey.Path],
                  );

                  return `Generated asset "${
                    repoMetadata[RepoMetadataKey.Name]
                  }" for download`;
                } catch (error) {
                  if ((error as Error).message === 'Failed') {
                    throw new Error(
                      `Failed to generate asset "${
                        repoMetadata[RepoMetadataKey.Name]
                      }" for download`,
                    );
                  }

                  throw new Error(
                    `Failed to generate asset "${
                      repoMetadata[RepoMetadataKey.Name]
                    }" for download: ${(error as Error).message}`,
                  );
                }
              },
              options: {
                isIndeterminate: true,
              },
            }),
          );
          break;

        case AssetAction.Publish:
          dispatch(
            queueJob({
              message: `Publishing ${assets.length} assets...`,
              task: {
                assets,
                action: assetWorkflowActions.publish,
                message: (workflowActionAssets) => {
                  if (
                    workflowActionAssets.every(
                      (asset) =>
                        asset.status === WorkflowActionJobStatus.Fulfilled,
                    )
                  ) {
                    return `Published ${workflowActionAssets.length} assets.`;
                  }

                  const failedAssetCount = workflowActionAssets.filter(
                    (asset) =>
                      asset.status === WorkflowActionJobStatus.Rejected ||
                      asset.status ===
                        WorkflowActionJobStatus.RejectedAndResolved,
                  ).length;
                  const publishedAssetCount = workflowActionAssets.filter(
                    (asset) =>
                      asset.status === WorkflowActionJobStatus.Fulfilled,
                  ).length;
                  const skippedAssetCount = workflowActionAssets.filter(
                    (asset) => asset.status === WorkflowActionJobStatus.Skipped,
                  ).length;

                  if (failedAssetCount !== 0) {
                    throw new Error(
                      `Failed to publish ${failedAssetCount} of ${workflowActionAssets.length} assets.`,
                    );
                  }

                  if (publishedAssetCount !== 0) {
                    return `Skipped ${skippedAssetCount} and published ${publishedAssetCount} assets.`;
                  }

                  return `Skipped ${skippedAssetCount} assets.`;
                },
              },
            }),
          );
          clearSelectedAssets();
          break;

        case AssetAction.Rename:
          setAssetToRename({} as RepoMetadata);
          ({ result: repoMetadata } = await getRepoMetadataForAsset(
            assets[0].assetPath,
            {
              acpRepoId,
              url,
            },
          ));
          setAssetToRename(repoMetadata);
          break;

        case AssetAction.Delete:
          setAssetsToDelete(assets);
          break;

        case AssetAction.Unpublish:
          dispatch(
            queueJob({
              message: `Unpublishing ${assets.length} assets...`,
              task: {
                assets,
                action: assetWorkflowActions.unpublish,
                message: (workflowActionAssets) => {
                  if (
                    workflowActionAssets.some(
                      (asset) =>
                        asset.status !== WorkflowActionJobStatus.Fulfilled,
                    )
                  ) {
                    const failedAssets = workflowActionAssets.filter(
                      (asset) =>
                        asset.status !== WorkflowActionJobStatus.Fulfilled,
                    );

                    throw new Error(
                      `Failed to unpublish ${failedAssets.length} of ${workflowActionAssets.length} assets.`,
                    );
                  }

                  return `Unpublished ${workflowActionAssets.length} assets.`;
                },
              },
            }),
          );
          clearSelectedAssets();
          break;

        case AssetAction.Reject:
          dispatch(
            queueJob({
              message: `Rejecting ${assets.length} assets...`,
              task: {
                assets,
                action: assetWorkflowActions.reject,
                message: (workflowActionAssets) => {
                  if (
                    workflowActionAssets.some(
                      (asset) =>
                        asset.status !== WorkflowActionJobStatus.Fulfilled,
                    )
                  ) {
                    const failedAssets = workflowActionAssets.filter(
                      (asset) =>
                        asset.status !== WorkflowActionJobStatus.Fulfilled,
                    );

                    throw new Error(
                      `Failed to reject ${failedAssets.length} of ${workflowActionAssets.length} assets.`,
                    );
                  }

                  return `Rejected ${workflowActionAssets.length} assets.`;
                },
              },
            }),
          );
          clearSelectedAssets();
          break;

        case AssetAction.Verify:
          dispatch(
            queueJob({
              message: `Verifying ${assets.length.toLocaleString()} assets...`,
              task: {
                assets,
                action: (assetPath) =>
                  assetWorkflowActions
                    .fetchAsWorkflowActionJobAsset(assetPath)
                    .then((result) => {
                      if (result.body?.pageMetadata) {
                        dispatch(
                          addAssetsToCurrentJob({
                            assets: result.body.pageMetadata.children
                              .filter(
                                (child) =>
                                  isCollection(child) || isDirectory(child),
                              )
                              .map((child) => ({
                                assetPath: child[RepoMetadataKey.Path],
                              })),
                            message: (workflowActionAssets) =>
                              `Verifying ${workflowActionAssets.length.toLocaleString()} assets...`,
                          }),
                        );
                      }

                      if (
                        result.body?.repoMetadata &&
                        isCollection(result.body.repoMetadata)
                      ) {
                        const errors = verifyCollectionFile(
                          result.body.fileContents as CollectionFile,
                        );

                        if (errors.length !== 0) {
                          throw new Error(errors[0]);
                        }
                      }
                    }),
                message: (workflowActionAssets) => {
                  if (
                    workflowActionAssets.some(
                      (asset) =>
                        asset.status !== WorkflowActionJobStatus.Fulfilled,
                    )
                  ) {
                    const failedAssets = workflowActionAssets.filter(
                      (asset) =>
                        asset.status !== WorkflowActionJobStatus.Fulfilled,
                    );

                    throw new Error(
                      `Verification failed for ${failedAssets.length} of ${workflowActionAssets.length} assets.`,
                    );
                  }

                  return `Verified ${workflowActionAssets.length} assets.`;
                },
              },
            }),
          );
          clearSelectedAssets();
          break;
      }
    },
    [
      selectedAssetPaths,
      dispatch,
      assetWorkflowActions,
      clearSelectedAssets,
      acpRepoId,
      url,
    ],
  );
  const handleApprove = useCallback(
    (targetDestination: string) => {
      if (!assetsToApprove || assetsToApprove.length === 0) {
        return;
      }

      dispatch(
        queueJob({
          message: `Approving ${assetsToApprove.length} assets for "${targetDestination}"...`,
          task: {
            assets: assetsToApprove,
            action: assetWorkflowActions.approve(targetDestination),
            message: (workflowActionAssets) => {
              if (
                workflowActionAssets.some(
                  (asset) => asset.status !== WorkflowActionJobStatus.Fulfilled,
                )
              ) {
                const failedAssets = workflowActionAssets.filter(
                  (asset) => asset.status !== WorkflowActionJobStatus.Fulfilled,
                );

                throw new Error(
                  `Failed to approve ${failedAssets.length} of ${workflowActionAssets.length} assets.`,
                );
              }

              return `Approved ${workflowActionAssets.length} assets for "${targetDestination}".`;
            },
          },
        }),
      );

      clearSelectedAssets();
      setAssetsToApprove(undefined);
    },
    [
      assetsToApprove,
      dispatch,
      assetWorkflowActions,
      clearSelectedAssets,
      setAssetsToApprove,
    ],
  );
  const handleMove = useCallback(
    (targetDestination: string) => {
      if (!assetsToMove || assetsToMove.length === 0) {
        return;
      }

      dispatch(
        queueJob({
          message: `Moving ${assetsToMove.length} assets to ${targetDestination}...`,
          task: {
            assets: assetsToMove,
            action: (assetPath) =>
              assetWorkflowActions.move(assetPath, {
                targetDestination,
              }),
            message: (workflowActionAssets) => {
              if (
                workflowActionAssets.some(
                  (asset) => asset.status !== WorkflowActionJobStatus.Fulfilled,
                )
              ) {
                const failedAssets = workflowActionAssets.filter(
                  (asset) => asset.status !== WorkflowActionJobStatus.Fulfilled,
                );

                throw new Error(
                  `Failed to move ${failedAssets.length} of ${workflowActionAssets.length} assets.`,
                );
              }

              return `Moved ${workflowActionAssets.length} assets to ${targetDestination}.`;
            },
          },
        }),
      );

      clearSelectedAssets();
      setAssetsToMove(undefined);
    },
    [
      assetsToMove,
      dispatch,
      assetWorkflowActions,
      clearSelectedAssets,
      setAssetsToMove,
    ],
  );
  const handleDelete = useCallback(async () => {
    if (!assetsToDelete) {
      return;
    }

    dispatch(
      queueJob({
        message: `Deleting ${assetsToDelete.length} assets...`,
        task: {
          assets: assetsToDelete,
          action: assetWorkflowActions.delete,
          message: (workflowActionAssets) => {
            if (
              workflowActionAssets.some(
                (asset) => asset.status !== WorkflowActionJobStatus.Fulfilled,
              )
            ) {
              const failedAssets = workflowActionAssets.filter(
                (asset) => asset.status !== WorkflowActionJobStatus.Fulfilled,
              );

              throw new Error(
                `Failed to delete ${failedAssets.length} of ${workflowActionAssets.length} assets.`,
              );
            }

            return `Deleted ${workflowActionAssets.length} assets.`;
          },
        },
      }),
    );
    clearSelectedAssets();
    setAssetsToDelete(undefined);
  }, [
    assetsToDelete,
    dispatch,
    assetWorkflowActions.delete,
    clearSelectedAssets,
    setAssetsToDelete,
  ]);
  const handleRename = useCallback(
    async (filename: string) => {
      if (!assetToRename) {
        return;
      }

      dispatch(
        queueJob({
          message: `Renaming asset "${
            assetToRename[RepoMetadataKey.Name]
          }" to "${filename}"`,
          action: async (): Promise<string> => {
            try {
              await assetWorkflowActions.rename(
                assetToRename[RepoMetadataKey.Path],
                filename,
              );

              return `Renamed asset to "${filename}"`;
            } catch (error) {
              if ((error as Error).message === 'Failed') {
                throw new Error(
                  `Failed to rename "${assetToRename[RepoMetadataKey.Name]}"`,
                );
              }

              throw new Error(
                `Failed to rename "${assetToRename[RepoMetadataKey.Name]}": ${
                  (error as Error).message
                }`,
              );
            }
          },
          options: {
            isIndeterminate: true,
          },
        }),
      );

      clearSelectedAssets();
      setAssetToRename(undefined);
    },
    [
      assetToRename,
      assetWorkflowActions,
      clearSelectedAssets,
      dispatch,
      setAssetToRename,
    ],
  );
  const handleExport = useCallback(
    (isExtended: boolean) => {
      if (!assetsToExport || assetsToExport.length === 0) {
        return;
      }

      dispatch(
        queueJob({
          message: `Exporting ${assetsToExport.length.toLocaleString()} assets...`,
          task: {
            assets: assetsToExport,
            action: (assetPath) =>
              assetWorkflowActions
                .fetchAsWorkflowActionJobAsset(assetPath)
                .then((result) => {
                  if (result.body?.pageMetadata) {
                    dispatch(
                      addAssetsToCurrentJob({
                        assets: result.body.pageMetadata.children.map(
                          (child) => ({
                            assetPath: child[RepoMetadataKey.Path],
                          }),
                        ),
                        message: (assets) =>
                          `Exporting ${assets.length.toLocaleString()} assets...`,
                      }),
                    );
                  }

                  return result;
                }),
            onFinish: async (workflowActionAssets) => {
              // Build CSV file data
              let headerColumns = Object.keys(columnToMetadataKeyMap);

              if (isExtended) {
                headerColumns = headerColumns.concat([
                  'Content Type',
                  'Counts',
                  'Published',
                  'Size',
                  'Title All Languages',
                  'Suggested Title',
                ]);
              }

              // Build and format CSV
              const data = buildCSVExport(headerColumns, workflowActionAssets);
              const csvContent = await formatCSV(headerColumns, data);
              // Generate file name
              let parentPath = workflowActionAssets[0].assetPath
                .split('/')
                .slice(0, -1)
                .join('/');

              if (parentPath.startsWith(`${acpBasePath.author}`)) {
                parentPath =
                  parentPath.slice(acpBasePath.author.length + 1) || 'root';
              }

              parentPath = parentPath.split('/').join('.');

              downloadCSV(`${parentPath}.csv`, csvContent);
            },
            message: (workflowActionAssets) => {
              if (
                workflowActionAssets.some(
                  (asset) => asset.status !== WorkflowActionJobStatus.Fulfilled,
                )
              ) {
                const failedAssets = workflowActionAssets.filter(
                  (asset) => asset.status !== WorkflowActionJobStatus.Fulfilled,
                );

                throw new Error(
                  `Failed to export ${failedAssets.length} of ${workflowActionAssets.length} assets.`,
                );
              }

              return `Exported ${workflowActionAssets.length} assets.`;
            },
          },
        }),
      );

      clearSelectedAssets();
      setAssetsToExport(undefined);
    },
    [
      assetsToExport,
      acpBasePath.author,
      assetWorkflowActions,
      dispatch,
      setAssetsToExport,
      clearSelectedAssets,
    ],
  );
  let actions: AssetAction[];

  if (selectedAssetPaths.size === 1) {
    actions = [...individualActions, ...assetActions];
  } else {
    actions = assetActions;
  }

  return (
    <>
      <View
        paddingX="size-300"
        paddingY="size-50"
        position="absolute"
        bottom="0"
        left="size-0"
        right="size-0"
        zIndex={2}
        UNSAFE_className={`${styles.base} ${
          selectedAssetPaths.size ? styles.showing : ''
        }`}
      >
        <Flex alignItems="center">
          <Button
            variant="overBackground"
            isQuiet
            minWidth="size-0"
            onPress={clearSelectedAssets}
            UNSAFE_className={styles.closeIcon}
          >
            <Close size="S" />
          </Button>
          <View flex={1} paddingStart="size-50">
            <Text UNSAFE_className={styles.selectCount}>
              {selectedAssetPaths.size} selected
            </Text>
          </View>
          <View>
            {actions.map((action: AssetAction): ReactElement => {
              const Icon = AssetActionIconMap.get(
                action,
              ) as JSXElementConstructor<any>;

              return (
                <Button
                  UNSAFE_style={{
                    padding:
                      'var(--spectrum-global-dimension-size-25) var(--spectrum-global-dimension-size-150)',
                  }}
                  onPress={handleBulkAction(action)}
                  key={action}
                  variant="overBackground"
                  isQuiet
                >
                  <Icon size="M" />
                  <Text marginStart="size-50">{action}</Text>
                </Button>
              );
            })}
          </View>
        </Flex>
      </View>

      <FolderDialog
        confirm={
          assetsToMove
            ? `Move ${assetsToMove.length} assets`
            : `Approve ${assetsToApprove?.length} assets`
        }
        invalidDestinationDirectories={
          assetsToMove
            ? assetsToMove.map((asset) => asset.assetPath)
            : undefined
        }
        initialDirectory={
          assetsToMove ? currentAssetByPath : acpBasePath.author
        }
        isOpen={assetsToMove !== undefined || assetsToApprove !== undefined}
        onClose={hideFolderDialog}
        onConfirm={assetsToMove ? handleMove : handleApprove}
        title={assetsToApprove ? 'Select Destination' : undefined}
      />

      <RenameAssetFormDialog
        filename={assetToRename?.[RepoMetadataKey.Name] ?? ''}
        isFolder={assetToRename ? isDirectory(assetToRename) : false}
        isLoading={assetToRename?.[RepoMetadataKey.Name] === undefined}
        isOpen={assetToRename !== undefined}
        onClose={() => setAssetToRename(undefined)}
        onRename={handleRename}
      />

      <ConfirmationDialog
        isOpen={assetsToDelete !== undefined}
        onClose={hideFolderDialog}
        onConfirm={handleDelete}
        title="Are you sure?"
        confirm="Delete"
        content={
          <>
            This action will unpublish these assets and then remove them from
            storage.
          </>
        }
      />

      <MetadataExportDialog
        isOpen={assetsToExport !== undefined}
        onClose={() => setAssetsToExport(undefined)}
        onConfirm={handleExport}
      />
    </>
  );
}
