/**
 * *****************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2022 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Adobe and its suppliers, if any. The intellectual and technical concepts
 * contained herein are proprietary to Adobe and its suppliers and are
 * protected by all applicable intellectual property laws, including trade
 * secret and copyright laws. Dissemination of this information or reproduction
 * of this material is strictly forbidden unless prior written permission is
 * obtained from Adobe.
 * *****************************************************************************
 */

import React, {
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { ActionButton, Flex, Heading, Text, View } from '@adobe/react-spectrum';
import ChevronLeft from '@spectrum-icons/workflow/ChevronLeft';
import { isEqual } from 'lodash';

import CollectionMetadataForm from 'components/AssetMetadataForm/LEGACYCollectionMetadataForm';
import CCXAssetCollectionForm from 'components/CCXAssetCollectionForm';
import { CCXAssetCollectionFormMode } from 'components/CCXAssetCollectionForm/CCXAssetCollectionForm';
import CCXTemplateCollectionForm, {
  CCXTemplateCollectionFormMode,
} from 'components/CCXTemplateCollectionForm';
import StockCollectionForm from 'components/StockCollectionForm';
import { StockCollectionFormMode } from 'components/StockCollectionForm/StockCollectionForm';
import useAssetWorkflowActions from 'hooks/useAssetWorkflowActions';
import useConstant from 'hooks/useConstant';
import useLocationQueryParams from 'hooks/useLocationQueryParams';
import { CollectionQueryModel } from 'model/acp/CollectionQueryModel';
import { CollectionSource } from 'model/acp/CollectionSource';
import { ValidationFieldErrors } from 'model/errors/ValidationError';
import { AssetMetadataModelKey } from 'model/metadata/AssetMetadataModel';
import { CollectionMetadataModel } from 'model/metadata/CollectionMetadataModel';
import { useAppSelector } from 'redux/hooks';
import { selectIsUsingExperimentalRepository } from 'redux/user/user.slice';
import { NotFoundErrorScreen } from 'screens/errors';
import { buildCollectionJSON } from 'utils/file';
import {
  mapFromCollectionMetadata,
  validate,
} from 'utils/metadata/collectionMetadata';

import stockBackground from 'images/AdobeStock_249296688.jpg';
import assetBackground from 'images/AdobeStock_259217764.jpg';
import templateBackground from 'images/AdobeStock_275237519.jpg';
import aecLogo from 'images/experience-cloud-logo.svg';
import stockLogo from 'images/stock-logo.svg';

import CollectionSourceCard from './CollectionSourceCard';

export default function CreateCollectionScreen(): ReactElement {
  // Load external configurations and path information
  const { acpBasePath } = useConstant();
  const { search } = useLocation();
  const { path, source } = useLocationQueryParams();
  const navigate = useNavigate();
  const assetWorkflowActions = useAssetWorkflowActions();
  // Metadata form collapse logic
  const [isMetadataFormCollapsed, setIsMetadataFormCollapsed] =
    useState<boolean>(false);
  const toggleCollapse = useCallback(
    () => setIsMetadataFormCollapsed(!isMetadataFormCollapsed),
    [isMetadataFormCollapsed, setIsMetadataFormCollapsed],
  );
  // Save path logic
  const [savePath, setSavePath] = useState<string>(path);
  const isUsingExperimentalRepository = useAppSelector(
    selectIsUsingExperimentalRepository,
  );
  const basePath = useMemo(
    () =>
      isUsingExperimentalRepository
        ? acpBasePath.wolverineAssets
        : acpBasePath.assets,
    [
      acpBasePath.assets,
      acpBasePath.wolverineAssets,
      isUsingExperimentalRepository,
    ],
  );

  useEffect((): void => {
    setSavePath((currentPath) =>
      currentPath ? decodeURIComponent(currentPath) : basePath,
    );
  }, [basePath, setSavePath, search]);

  const [newQueryModel, setNewQueryModel] = useState<CollectionQueryModel>({
    source,
  } as CollectionQueryModel);
  const setCollectionSource = useCallback(
    (newSource: CollectionSource | undefined) => {
      let url = `/collections/new?path=${path}`;

      if (newSource) {
        url += `&source=${newSource}`;
      }

      navigate(url);
    },
    [navigate, path],
  );
  // Collection preview actions
  const addToCollectionPreview = useCallback(
    (selectedToAdd: Set<string>) => {
      const currAssetIds =
        newQueryModel[CollectionSource.CCXAsset]?.assetIds ?? [];
      const updatedQueryModel: CollectionQueryModel = {
        source: CollectionSource.CCXAsset,
        [CollectionSource.CCXAsset]: {
          assetIds: new Set([...currAssetIds, ...selectedToAdd]),
        },
      };
      setNewQueryModel(updatedQueryModel);
    },
    [newQueryModel, setNewQueryModel],
  );
  const removeFromCollectionPreview = useCallback(
    (selectedToRemove: Set<string>) => {
      const currAssetIds =
        newQueryModel[CollectionSource.CCXAsset]?.assetIds ?? [];
      const updatedSelected = new Set([...currAssetIds]);

      selectedToRemove.forEach((selectedId) => {
        if (updatedSelected.has(selectedId)) {
          updatedSelected.delete(selectedId);
        }
      });

      setNewQueryModel({
        source: CollectionSource.CCXAsset,
        [CollectionSource.CCXAsset]: {
          assetIds: updatedSelected,
        },
      } as CollectionQueryModel);
    },
    [newQueryModel, setNewQueryModel],
  );
  const resetCollectionPreview = useCallback(
    () =>
      setNewQueryModel({
        source,
      } as CollectionQueryModel),
    [source, setNewQueryModel],
  );
  // Save logic
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const handleSave = useCallback(
    async (
      model: CollectionMetadataModel,
    ): Promise<ValidationFieldErrors | undefined> => {
      setIsSaving(true);

      // Validate model fields
      const errors = validate(model);

      if (errors) {
        setIsSaving(false);

        return errors;
      }

      // Setup target file destination
      const filename = `${model[AssetMetadataModelKey.Name]}.collection`;
      let targetDestination = savePath;

      if (!targetDestination.startsWith(basePath)) {
        targetDestination = basePath + targetDestination;
      }

      if (targetDestination.endsWith('/')) {
        targetDestination = targetDestination.slice(0, -1);
      }

      // Attempt to create collection file
      try {
        await assetWorkflowActions.createCollection(
          targetDestination,
          filename,
          buildCollectionJSON(newQueryModel),
        );
      } catch (error) {
        const e: Error = error as Error;

        if (!e.message.includes('File with same name already exists')) {
          throw error;
        }

        setIsSaving(false);

        return {
          [AssetMetadataModelKey.Name]:
            'A collection with this name already exists.',
        };
      }

      // Save application metadata for new collection
      const assetPath = `${targetDestination}/${filename}`;
      const { applicationMetadata } = await assetWorkflowActions.fetchMetadata(
        assetPath,
      );
      const updatedMetadata = mapFromCollectionMetadata(
        model,
        applicationMetadata,
      );

      await assetWorkflowActions.updateMetadata(assetPath, updatedMetadata);

      // @TODO: REDIRECT TO EDIT PAGE
      navigate(`${assetPath.replace(basePath, '')}?view=detail`);
      setIsSaving(false);

      return undefined;
    },
    [assetWorkflowActions, basePath, navigate, savePath, newQueryModel],
  );

  useEffect(() => {
    resetCollectionPreview();
  }, [resetCollectionPreview, source]);

  let collectionForm;

  switch (source) {
    case CollectionSource.CCXAsset:
      collectionForm = (
        <CCXAssetCollectionForm
          addToCollection={addToCollectionPreview}
          collectionQueryModel={newQueryModel}
          initialMode={CCXAssetCollectionFormMode.Add}
          removeFromCollection={removeFromCollectionPreview}
        />
      );
      break;

    case CollectionSource.CCXTemplate:
      collectionForm = (
        <CCXTemplateCollectionForm
          collectionQueryModel={newQueryModel}
          initialMode={CCXTemplateCollectionFormMode.Edit}
          onChange={setNewQueryModel}
        />
      );
      break;

    case CollectionSource.Stock:
      collectionForm = (
        <StockCollectionForm
          collectionQueryModel={newQueryModel}
          onChange={setNewQueryModel}
          initialMode={StockCollectionFormMode.Edit}
        />
      );
      break;

    default:
      collectionForm = (
        <Flex
          direction="column"
          flex
          marginBottom="size-200"
          minHeight="size-0"
        >
          <Flex gap="size-150" wrap="wrap">
            <CollectionSourceCard
              title="Adobe Stock"
              kind="Dynamic Collection"
              background={stockBackground}
              logo={stockLogo}
              onClick={() => setCollectionSource(CollectionSource.Stock)}
            />

            <CollectionSourceCard
              title="CCX Assets"
              kind="Curated Collection"
              background={assetBackground}
              logo={aecLogo}
              onClick={() => setCollectionSource(CollectionSource.CCXAsset)}
            />

            <CollectionSourceCard
              title="CCX Templates"
              kind="Dynamic Collection"
              background={templateBackground}
              logo={aecLogo}
              onClick={() => setCollectionSource(CollectionSource.CCXTemplate)}
            />
          </Flex>
        </Flex>
      );
      break;
  }

  const hasSelectedSource = source !== '';
  const isSupportedSource = Object.values(CollectionSource).includes(
    source as CollectionSource,
  );

  if (hasSelectedSource && !isSupportedSource) {
    return <NotFoundErrorScreen />;
  }

  return (
    <Flex direction="column" width="100%" height="100%">
      <Flex flex minHeight="size-0" marginX="size-1000">
        <Flex
          direction="column"
          marginTop="size-400"
          marginBottom="size-200"
          flex
        >
          <Heading level={3} marginTop="size-0" marginBottom="size-130">
            <Flex
              height="size-400"
              alignContent="center"
              justifyContent="center"
            >
              {source !== '' ? (
                <>
                  <ActionButton
                    isQuiet
                    onPress={() => setCollectionSource(undefined)}
                    marginBottom="size-10"
                  >
                    <ChevronLeft />
                  </ActionButton>

                  <Text marginTop="size-40" flex>
                    Create {source} Collection
                  </Text>
                </>
              ) : (
                <Text marginTop="size-40" flex>
                  Select Collection Source
                </Text>
              )}
            </Flex>
          </Heading>
          <Flex direction="column" flex minHeight="size-0">
            {collectionForm}
          </Flex>
        </Flex>
        <View
          width={
            isMetadataFormCollapsed || !hasSelectedSource
              ? 'size-0'
              : 'size-6000'
          }
          paddingTop="size-300"
          paddingEnd="size-200"
          paddingBottom="size-200"
          paddingStart={
            isMetadataFormCollapsed || !hasSelectedSource
              ? 'size-0'
              : 'size-200'
          }
        >
          <Flex width="100%" gap="size-200" height="100%">
            <Flex width="100%" flex>
              <CollectionMetadataForm
                editedQueryModel={newQueryModel}
                hasExternalChanges={
                  (newQueryModel[CollectionSource.CCXAsset] !== undefined &&
                    newQueryModel[CollectionSource.CCXAsset]?.assetIds.size !==
                      0) ||
                  isEqual(newQueryModel[CollectionSource.Stock], {}) ||
                  newQueryModel[CollectionSource.Stock] !== undefined
                }
                isCollapsed={isMetadataFormCollapsed || !hasSelectedSource}
                isSaving={isSaving}
                onCollapse={toggleCollapse}
                onReset={resetCollectionPreview}
                onSave={handleSave}
              />
            </Flex>
          </Flex>
        </View>
      </Flex>
    </Flex>
  );
}
