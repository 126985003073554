/**
 * *****************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2022 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Adobe and its suppliers, if any. The intellectual and technical concepts
 * contained herein are proprietary to Adobe and its suppliers and are
 * protected by all applicable intellectual property laws, including trade
 * secret and copyright laws. Dissemination of this information or reproduction
 * of this material is strictly forbidden unless prior written permission is
 * obtained from Adobe.
 * *****************************************************************************
 */

import { useLocation } from 'react-router-dom';

import { AssetClass } from 'model/acp/AssetClass';
import { AssetAction } from 'model/app/AssetAction';
import { AssetActionDomain } from 'model/app/AssetActionDomain';
import { Workflow } from 'model/app/Workflow';

import useConstant from './useConstant';
import useCurrentAssetByPath from './useCurrentAssetByPath';

type WorkflowProps = {
  workflow: Workflow;
  assetActions: AssetAction[];
  individualActions: AssetAction[];
  selectableAssets: Set<AssetClass>;
  rootPath: string;
};
type Props = {
  actionDomain: AssetActionDomain;
  actionContext: 'file' | 'collection' | 'folder';
};

export default function useWorkflow({
  actionDomain,
  actionContext,
}: Props): WorkflowProps {
  let result: WorkflowProps = {
    workflow: Workflow.CreateCollection,
    rootPath: '',
    assetActions: [],
    individualActions: [],
    selectableAssets: new Set<AssetClass>(Object.values(AssetClass)),
  };
  const { currentAssetByPath } = useCurrentAssetByPath();
  const { acpBasePath } = useConstant();
  const { pathname } = useLocation();

  if (pathname.startsWith('/permissions')) {
    result = {
      workflow: Workflow.Permissions,
      rootPath: '',
      assetActions: [],
      individualActions: [],
      selectableAssets: new Set(),
    };
  }

  if (currentAssetByPath.startsWith(acpBasePath.submit)) {
    result = {
      workflow: Workflow.Review,
      rootPath: acpBasePath.submit,
      assetActions:
        actionContext !== 'folder'
          ? [
              AssetAction.Reject,
              AssetAction.Approve,
              AssetAction.Move,
              AssetAction.Delete,
            ]
          : [AssetAction.Move, AssetAction.Delete],
      individualActions: [AssetAction.Download],
      selectableAssets: new Set<AssetClass>([
        AssetClass.File,
        AssetClass.Composite,
      ]),
    };

    if (actionDomain === AssetActionDomain.Single) {
      result.assetActions.push(AssetAction.Rename);
    } else {
      result.individualActions.push(AssetAction.Rename);
    }
  }

  if (currentAssetByPath.startsWith(acpBasePath.author)) {
    result = {
      workflow: Workflow.Publish,
      rootPath: acpBasePath.author,
      assetActions: [
        AssetAction.Publish,
        AssetAction.Unpublish,
        AssetAction.Move,
        AssetAction.Delete,
      ],
      individualActions: [AssetAction.Download],
      selectableAssets: new Set<AssetClass>(Object.values(AssetClass)),
    };

    if (actionDomain === AssetActionDomain.Bulk) {
      result.assetActions.push(AssetAction.MetadataExport);
      result.assetActions.push(AssetAction.Verify);

      result.individualActions.push(AssetAction.Rename);
    } else {
      result.assetActions.push(AssetAction.Rename);
    }
  }

  if (pathname.startsWith('/collections/new')) {
    result = {
      workflow: Workflow.CreateCollection,
      rootPath: '',
      assetActions: [],
      individualActions: [],
      selectableAssets: new Set<AssetClass>([
        AssetClass.File,
        AssetClass.Composite,
      ]),
    };
  }

  return result;
}
