/**
 * *****************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2022 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Adobe and its suppliers, if any. The intellectual and technical concepts
 * contained herein are proprietary to Adobe and its suppliers and are
 * protected by all applicable intellectual property laws, including trade
 * secret and copyright laws. Dissemination of this information or reproduction
 * of this material is strictly forbidden unless prior written permission is
 * obtained from Adobe.
 * *****************************************************************************
 */

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from 'redux/store';

export type TempoOwnersMap = {
  designers: Record<string, { key: string; docCount: number } | undefined>;
  others: Record<string, { key: string; docCount: number } | undefined>;
};

export interface TempoState {
  ownersMap: TempoOwnersMap;
}

const initialState: TempoState = {
  ownersMap: {
    designers: {},
    others: {},
  },
};

export const tempoSlice = createSlice({
  name: 'tempo',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    setOwnersMap: (state, action: PayloadAction<TempoOwnersMap>) => {
      /*
       * Redux Toolkit allows us to write "mutating" logic in reducers. It
       * doesn't actually mutate the state because it uses the Immer library,
       * which detects changes to a "draft state" and produces a brand new
       * immutable state based off those changes
       */
      state.ownersMap = action.payload;
    },
  },
});

export const { setOwnersMap } = tempoSlice.actions;

export const selectOwnersMap = (state: RootState) => state.tempo.ownersMap;

export default tempoSlice.reducer;
