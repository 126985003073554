/**
 * *****************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2022 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Adobe and its suppliers, if any. The intellectual and technical concepts
 * contained herein are proprietary to Adobe and its suppliers and are
 * protected by all applicable intellectual property laws, including trade
 * secret and copyright laws. Dissemination of this information or reproduction
 * of this material is strictly forbidden unless prior written permission is
 * obtained from Adobe.
 * *****************************************************************************
 */

import { DependencyList, EffectCallback, useEffect, useRef } from 'react';

/**
 * useEffect wrapper that runs the effect after every render
 * EXCEPT the initial render.
 */
function useEffectDidUpdate(callback: EffectCallback, deps: DependencyList) {
  const didUpdate = useRef(false);

  useEffect(() => {
    if (didUpdate.current) {
      callback();
    } else {
      didUpdate.current = true;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);
}

export default useEffectDidUpdate;
