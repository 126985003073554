/**
 * *****************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2022 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Adobe and its suppliers, if any. The intellectual and technical concepts
 * contained herein are proprietary to Adobe and its suppliers and are
 * protected by all applicable intellectual property laws, including trade
 * secret and copyright laws. Dissemination of this information or reproduction
 * of this material is strictly forbidden unless prior written permission is
 * obtained from Adobe.
 * *****************************************************************************
 */

import { useEffect, useMemo, useState } from 'react';
import { useQuery } from 'react-query';

import Axios, { AxiosError } from 'axios';
import * as URITemplate from 'uritemplate';

import useACPLink from 'hooks/acp/useACPLink';
import useConstant from 'hooks/useConstant';
import { ACPLink } from 'model/acp/ACPLink';
import { AssetMimeType } from 'model/acp/AssetMimeType';

const SHADOW_ASSET_FOLDER = '__DEPRECATED_templates_comments';

type UseShadowAssetHookProps = {
  currentAssetId: string;
  options?: {
    enabled?: boolean;
    shouldCreate?: boolean;
  };
};

type UseShadowAssetHookResult = {
  result: string | undefined;
  isLoading: boolean;
};

export default function useShadowAsset({
  currentAssetId,
  options: { shouldCreate = true, enabled = true } = {},
}: UseShadowAssetHookProps): UseShadowAssetHookResult {
  // Results hooks
  const [results, setResults] = useState<UseShadowAssetHookResult>({
    result: undefined,
    isLoading: true,
  });

  useEffect(() => {
    setResults({
      result: undefined,
      isLoading: true,
    });
  }, [currentAssetId]);

  const currentAssetPathId = useMemo(() => {
    const [folder] = currentAssetId.split('-');

    return `${folder.slice(0, 2)}/${currentAssetId}`;
  }, [currentAssetId]);
  // Shadow asset API operation paths
  const { acpBasePath } = useConstant();
  const shadowAssetForTemplateCommentsBaseUrl = useMemo(
    () => `${acpBasePath.submit}/${SHADOW_ASSET_FOLDER}`,
    [acpBasePath],
  );
  const shadowAssetForTemplateCommentsPath = useMemo(
    () => `${shadowAssetForTemplateCommentsBaseUrl}/${currentAssetPathId}`,
    [shadowAssetForTemplateCommentsBaseUrl, currentAssetPathId],
  );
  // Get asset ID
  const {
    results: acpLinkResults,
    errors,
    isLoading: isLoadingShadowAsset,
  } = useACPLink({
    acpLink: ACPLink.Primary,
    path: shadowAssetForTemplateCommentsPath,
    enabled,
    options: {
      retries: false,
    },
  });

  useEffect(() => {
    if (acpLinkResults?.assetId !== undefined) {
      setResults({
        result: acpLinkResults.assetId,
        isLoading: false,
      });
    } else if (!isLoadingShadowAsset && !shouldCreate) {
      setResults({
        result: undefined,
        isLoading: false,
      });
    }
  }, [acpLinkResults?.assetId, isLoadingShadowAsset, shouldCreate]);

  // Get create link
  const { isLoading: isACPLinkLoading, results: shadowAssetResults } =
    useACPLink({
      acpLink: ACPLink.Create,
      path: shadowAssetForTemplateCommentsBaseUrl,
      options: {
        retries: 0,
      },
      enabled:
        enabled &&
        shouldCreate &&
        !isLoadingShadowAsset &&
        errors !== undefined &&
        errors.length !== 0,
    });
  // Attempt to create shadow asset
  const requestUrl = shadowAssetResults?.linkUrl
    ? URITemplate.parse(shadowAssetResults.linkUrl).expand({
        path: currentAssetPathId,
        intermediates: true,
      })
    : '';
  const CreateQuery = async (): Promise<string> => {
    try {
      const response = await Axios.post(requestUrl, '', {
        headers: {
          'Content-Type': AssetMimeType.TemplateShadowAsset,
        },
      });

      return response.headers['asset-id'];
    } catch (error) {
      const axiosError = error as AxiosError;

      if (axiosError.response?.status === 409) {
        return '';
      }

      throw error;
    }
  };
  const { data: newAssetId, error } = useQuery<string>(
    ['shadow-assets', requestUrl],
    CreateQuery,
    {
      retry: false,
      /**
       * Disable the ReactQuery query call if this function is run but the links
       * haven't loaded, because there will be no requestUrl and no reason to
       * make the request.
       */
      enabled:
        enabled && shouldCreate && !isACPLinkLoading && requestUrl.length !== 0,
    },
  );

  useEffect(() => {
    if (newAssetId !== undefined && newAssetId.length !== 0) {
      setResults({
        result: newAssetId,
        isLoading: false,
      });
    }
  }, [newAssetId, error]);

  return results;
}
