/**
 * *****************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2022 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Adobe and its suppliers, if any. The intellectual and technical concepts
 * contained herein are proprietary to Adobe and its suppliers and are
 * protected by all applicable intellectual property laws, including trade
 * secret and copyright laws. Dissemination of this information or reproduction
 * of this material is strictly forbidden unless prior written permission is
 * obtained from Adobe.
 * *****************************************************************************
 */

import { useCallback, useEffect, useState } from 'react';

import { cloneDeep, isEqual, set } from 'lodash';

import useApplicationMetadata from 'hooks/acp/useApplicationMetadata';
import useRepoMetadata from 'hooks/acp/useRepoMetadata';
import { ValidationFieldErrors } from 'model/errors/ValidationError';
import { AssetMetadataModelKey } from 'model/metadata/AssetMetadataModel';
import { CollectionMetadataModel } from 'model/metadata/CollectionMetadataModel';
import DefaultCollectionMetadataModel from 'model/metadata/DefaultCollectionMetadataModel';
import DefaultFileMetadataModel from 'model/metadata/DefaultFileMetadataModel';
import DefaultFolderMetadataModel from 'model/metadata/DefaultFolderMetadataModel';
import { FileMetadataModel } from 'model/metadata/FileMetadataModel';
import { FolderMetadataModel } from 'model/metadata/FolderMetadataModel';
import { mapToCollectionMetadata } from 'utils/metadata/collectionMetadata';
import { mapToFileMetadata } from 'utils/metadata/fileMetadata';
import { mapToFolderMetadata } from 'utils/metadata/folderMetadata';

type UseAssetMetadataModelHookResult<T> = {
  hasChanges: boolean;
  isLoading: boolean;
  model?: T;
  reset: () => void;
  setModelField: (...fields: AssetMetadataModelKey[]) => (value: any) => void;
  setValidationErrors: (errors?: ValidationFieldErrors) => void;
  validationErrors: ValidationFieldErrors;
};

type UseAssetMetadataModelHookProps = {
  path?: string;
  type: 'collection' | 'file' | 'folder';
};

type UseCollectionMetadataModelHookProps = {
  path?: string;
  type: 'collection';
};

type UseFileMetadataModelHookProps = {
  path?: string;
  type: 'file';
};

type UseFolderMetadataModelHookProps = {
  path?: string;
  type: 'folder';
};

function useAssetMetadataModel(
  args: UseFileMetadataModelHookProps,
): UseAssetMetadataModelHookResult<FileMetadataModel>;
function useAssetMetadataModel(
  args: UseFolderMetadataModelHookProps,
): UseAssetMetadataModelHookResult<FolderMetadataModel>;
function useAssetMetadataModel(
  args: UseCollectionMetadataModelHookProps,
): UseAssetMetadataModelHookResult<CollectionMetadataModel>;
function useAssetMetadataModel({
  path = '',
  type,
}: UseAssetMetadataModelHookProps): UseAssetMetadataModelHookResult<
  FileMetadataModel | FolderMetadataModel | CollectionMetadataModel
> {
  const [model, setModel] = useState<
    FileMetadataModel | FolderMetadataModel | CollectionMetadataModel
  >();
  const [errors, setErrors] = useState<ValidationFieldErrors>();
  const [initialModel, setInitialModel] = useState<
    FileMetadataModel | FolderMetadataModel | CollectionMetadataModel
  >();
  const {
    results: applicationMetadata,
    isLoading: isApplicationMetadataLoading,
  } = useApplicationMetadata({ path });
  const { results: repoMetadata, isLoading: isRepoMetadataLoading } =
    useRepoMetadata({ path });

  useEffect(() => {
    if (isApplicationMetadataLoading || isRepoMetadataLoading) {
      return;
    }

    if (applicationMetadata && repoMetadata) {
      let assetMetadataModel:
        | FileMetadataModel
        | FolderMetadataModel
        | CollectionMetadataModel;

      switch (type) {
        case 'collection':
          assetMetadataModel = mapToCollectionMetadata(
            applicationMetadata,
            repoMetadata,
          );
          break;

        case 'file':
          assetMetadataModel = mapToFileMetadata(
            applicationMetadata,
            repoMetadata,
          );
          break;

        case 'folder':
          assetMetadataModel = mapToFolderMetadata(
            applicationMetadata,
            repoMetadata,
          );
          break;
      }

      setModel(cloneDeep(assetMetadataModel));
      setInitialModel(cloneDeep(assetMetadataModel));
    } else {
      let defaultModel:
        | FileMetadataModel
        | FolderMetadataModel
        | CollectionMetadataModel;

      switch (type) {
        case 'collection':
          defaultModel = new DefaultCollectionMetadataModel();
          break;

        case 'file':
          defaultModel = new DefaultFileMetadataModel();
          break;

        case 'folder':
          defaultModel = new DefaultFolderMetadataModel();
          break;
      }

      setModel(cloneDeep(defaultModel));
      setInitialModel(cloneDeep(defaultModel));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isApplicationMetadataLoading,
    isRepoMetadataLoading,
    setModel,
    setInitialModel,
    type,
  ]);

  const reset = useCallback(() => {
    setModel(initialModel);
    setErrors({});
  }, [setModel, initialModel]);
  const setModelField = useCallback(
    (...fields: AssetMetadataModelKey[]) => {
      if (!model) {
        return () => {};
      }

      return (value: any) => {
        setModel(set(cloneDeep(model), fields, value));
      };
    },
    [model],
  );

  return {
    hasChanges: !isEqual(model, initialModel),
    isLoading: model === undefined,
    validationErrors: errors ?? {},
    model,
    reset,
    setModelField,
    setValidationErrors: setErrors,
  };
}

export default useAssetMetadataModel;
