/**
 * *****************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2022 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Adobe and its suppliers, if any. The intellectual and technical concepts
 * contained herein are proprietary to Adobe and its suppliers and are
 * protected by all applicable intellectual property laws, including trade
 * secret and copyright laws. Dissemination of this information or reproduction
 * of this material is strictly forbidden unless prior written permission is
 * obtained from Adobe.
 * *****************************************************************************
 */

import IndexPath from '@react/collection-view/src/IndexPath';
import IndexPathSet from '@react/collection-view/src/IndexPathSet';
import Range from '@react/collection-view/src/Range';
import ListDataSource from '@react/react-spectrum/ListDataSource';
import _ from 'lodash';

import { TAsset } from '__DEPRECATED_DO_NOT_USE_OR_YOU_WILL_BE_FIRED/redux/assets/asset.types';
import {
  ContentType,
  FilterTab,
} from '__DEPRECATED_DO_NOT_USE_OR_YOU_WILL_BE_FIRED/redux/filter/filter.slice';

import { observeStore, RootState, store } from 'redux/store';

import { ReduxStoreDispatchProps } from '../model/types';

export const NUM_APPROVED_ASSETS_PER_SECTION = 50;

type GridDataSourceActions = ReduxStoreDispatchProps;

class GridDataSource extends ListDataSource {
  tab: FilterTab;
  contentType: ContentType;
  actions: GridDataSourceActions;
  assetsStack: TAsset[][];
  prevRanges: Range[];
  unsubscribeStore: () => void;
  forceFetch: boolean;
  constructor(
    tab: FilterTab,
    contentType: ContentType,
    actions: GridDataSourceActions,
  ) {
    super();

    this.tab = tab;
    this.contentType = contentType;
    this.actions = actions;
    this.assetsStack = [];
    this.prevRanges = [];
    this.forceFetch = false;

    const unsubscribeRefresh = observeStore(
      store,
      (state: RootState) => state.screen.doRefresh,
      this.refresh.bind(this),
    );

    this.unsubscribeStore = () => {
      unsubscribeRefresh();
    };
  }
  reset(
    tab: FilterTab,
    contentType: ContentType,
    actions: GridDataSourceActions,
  ) {
    this.tab = tab;
    this.contentType = contentType;
    this.actions = actions;
  }
  remove() {
    this.unsubscribeStore();
  }
  /**
   * Reset instance variables and reload asset data
   */
  refresh() {
    this.assetsStack = [];
    this.prevRanges = [];
    this.sections[0] = [];
    this.forceFetch = true;

    this.reloadData();
  }
  /**
   * Returns index of asset using its id
   * @param assetId - id of asset we're getting
   */
  getAssetIndex(assetId: string): number {
    const assets = this.sections[0];

    if (!assets) {
      return -1;
    }

    return assets.findIndex((asset: TAsset) => asset.id === assetId);
  }
  /**
   * Converts an array of asset ids into an @type {IndexPath} array.
   * If an asset index cannot be found, it will not be included in the resulting array
   * @param assetIds - array of assetIds that should be currently displayed with this dataSource
   */
  getAssetIndexPaths(assetIds: string[]): typeof IndexPath[] {
    const indexPaths: typeof IndexPath[] = assetIds.reduce(
      (result: typeof IndexPath[], id: string) => {
        const index = this.getAssetIndex(id);

        if (index >= 0) {
          result.push(new IndexPath(0, index));
        }

        return result;
      },
      [],
    );

    return indexPaths;
  }
  /**
   * Returns all the index paths in the section
   */
  getAllIndexPaths(): typeof IndexPath[] {
    const assets = this.sections[0];

    if (!assets) {
      return [];
    }

    const indexPaths: typeof IndexPath[] = [...assets.keys()].map(
      (index: number) => new IndexPath(0, index),
    );

    return indexPaths;
  }
  getAssetsInRange(range: typeof Range): TAsset[] {
    const currentAssets: TAsset[] = this.sections[0];
    let assetsInRange: TAsset[] = [];

    if (currentAssets[range.start] && currentAssets[range.end]) {
      // end index for Array.slice() is not inclusive
      assetsInRange = currentAssets.slice(range.start, range.end + 1);
    }

    return assetsInRange;
  }
  /**
   * Update this dataSource by finding which assets have been
   * removed or updated in the store.
   *
   * Does not handle adding new assets.
   * Clears the list of selected assets after updating.
   *
   * @param assets - assets to be used to update this dataSource
   */
  updateAssets(assets: TAsset[]) {
    const section = this.sections[0];

    // section must be initialized before updating
    if (!section) {
      return;
    }

    // nothing to update if current and new assets are empty
    if (section.length === 0 && assets.length === 0) {
      return;
    }

    const indexPathsToRemove = new IndexPathSet();

    section.forEach((item: TAsset, index: number) => {
      const storeIndex: number = assets.findIndex(
        (asset: TAsset) => item.id === asset.id,
      );

      // item is not in the store so it should be removed from this dataSource
      if (storeIndex === -1) {
        indexPathsToRemove.addIndexPath(new IndexPath(0, index));
      } else if (!_.isEqual(assets[storeIndex], item)) {
        this.replaceItem(new IndexPath(0, index), assets[storeIndex], false);
      }
    });

    this.removeItems(indexPathsToRemove, true);

    // clear selection
    this.actions.setSelectedAssetIds([]);
  }
  /**
   * Review: reload data to apply new filters on previously fetched assets
   * Search: refresh dataSource in order to fetch new assets with filters
   */
  updateFilters() {
    if (this.tab === FilterTab.review) {
      this.sections[0] = [];

      return this.reloadData();
    }

    return this.refresh();
  }
  async load() {
    const assets: TAsset[] = await this.actions.fetchAssets(this.tab, {
      contentType: this.contentType,
      forceFetch: this.forceFetch,
    });

    // update count
    this.actions.updateAssetsData({ count: assets.length });

    this.forceFetch = false;

    return assets;
  }
  async loadMore() {
    if (this.tab !== FilterTab.search) {
      return undefined;
    }

    const assets = await this.actions.fetchNextAssets(this.tab);

    return assets;
  }
  async loadNext() {
    let nextAssets: TAsset[] = this.assetsStack.pop() ?? [];

    if (nextAssets.length === 0) {
      const fetchedAssets = await this.actions.fetchNextAssets(this.tab);

      if (fetchedAssets.length === 0) {
        return false;
      }

      nextAssets = fetchedAssets;
    }

    const insertIndex: number = this.sections[0].length;
    const endIndex: number = insertIndex + (nextAssets.length - 1);

    // save the index range of the next set of assets
    this.prevRanges.push(new Range(insertIndex, endIndex));

    // add nextAssets to the end of the grid section
    const insertPath = new IndexPath(0, insertIndex);
    this.insertItems(insertPath, nextAssets, true);

    // update count
    this.actions.updateAssetsData({ count: this.sections[0].length });

    return true;
  }
  loadPrev() {
    // get Range of last set of assets
    const lastRange: Range | undefined = this.prevRanges.pop();

    if (!lastRange) {
      return false;
    }

    const indexPathSet = new IndexPathSet();
    indexPathSet.addRangeInSection(0, lastRange);

    const assetsToRemove: TAsset[] = this.getAssetsInRange(lastRange);

    // remove items only if we have assets to remove
    if (assetsToRemove.length > 0) {
      this.removeItems(indexPathSet, true);
      this.assetsStack.push(assetsToRemove);

      // update count
      this.actions.updateAssetsData({ count: this.sections[0].length });
    }

    return true;
  }
}

export default GridDataSource;
