/**
 * *****************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2022 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Adobe and its suppliers, if any. The intellectual and technical concepts
 * contained herein are proprietary to Adobe and its suppliers and are
 * protected by all applicable intellectual property laws, including trade
 * secret and copyright laws. Dissemination of this information or reproduction
 * of this material is strictly forbidden unless prior written permission is
 * obtained from Adobe.
 * *****************************************************************************
 */

import React, { ReactElement, useCallback, useEffect, useState } from 'react';

import {
  Button,
  ButtonGroup,
  Content,
  Dialog,
  DialogContainer,
  Divider,
  Heading,
  Text,
} from '@adobe/react-spectrum';

type MetadataExportDialogProps = {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: (isExtended: boolean) => void;
};

export default function MetadataExportDialog({
  isOpen,
  onClose,
  onConfirm,
}: MetadataExportDialogProps): ReactElement {
  const [isConfirming, setIsConfirming] = useState<boolean>(false);

  useEffect(() => setIsConfirming(false), [isOpen]);

  const handleConfirm = useCallback(
    async (isExtended: boolean): Promise<void> => {
      setIsConfirming(true);

      try {
        onConfirm(isExtended);
      } catch (error) {
        setIsConfirming(false);
      }
    },
    [onConfirm, setIsConfirming],
  );
  const handleClose = useCallback((): void => {
    setIsConfirming(true);
    onClose();
  }, [onClose, setIsConfirming]);

  return (
    <DialogContainer onDismiss={handleClose}>
      {isOpen && (
        <Dialog>
          <Heading>Export Metadata</Heading>
          <Divider />
          <Content>
            <Text>
              This may take awhile if there are a lot of subfolders. The
              metadata CSV will automatically download when it is complete.
            </Text>
          </Content>
          <ButtonGroup isDisabled={isConfirming}>
            <Button variant="secondary" onPress={handleClose}>
              Cancel
            </Button>
            <Button variant="secondary" onPress={() => handleConfirm(true)}>
              Export with extended data
            </Button>
            <Button variant="cta" onPress={() => handleConfirm(false)}>
              Export
            </Button>
          </ButtonGroup>
        </Dialog>
      )}
    </DialogContainer>
  );
}
