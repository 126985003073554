/**
 * *****************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2022 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Adobe and its suppliers, if any. The intellectual and technical concepts
 * contained herein are proprietary to Adobe and its suppliers and are
 * protected by all applicable intellectual property laws, including trade
 * secret and copyright laws. Dissemination of this information or reproduction
 * of this material is strictly forbidden unless prior written permission is
 * obtained from Adobe.
 * *****************************************************************************
 */

import React, { ReactElement } from 'react';

import { Flex } from '@adobe/react-spectrum';

import { ScreenTitle } from 'model/ScreenTitle';
import { useAppSelector } from 'redux/hooks';
import {
  selectIsCurrentUserAdmin,
  selectIsUsingExperimentalRepository,
} from 'redux/user/user.slice';

import HeaderNavElement from './HeaderNavElement';

import styles from './HeaderNavMenu.module.scss';

export default function HeaderNavMenu(): ReactElement {
  const isCurrentUserAdmin = useAppSelector(selectIsCurrentUserAdmin);
  const isUsingExperimentalRepository = useAppSelector(
    selectIsUsingExperimentalRepository,
  );

  return (
    <nav className={styles.navMenu}>
      <Flex height="100%" gap="size-250">
        {isUsingExperimentalRepository ? (
          <>
            <HeaderNavElement to="/pending">
              {ScreenTitle.PendingAssets}
            </HeaderNavElement>
            <HeaderNavElement to="/approved">
              {ScreenTitle.ApprovedAssets}
            </HeaderNavElement>
            <HeaderNavElement to="/rejected">
              {ScreenTitle.RejectedAssets}
            </HeaderNavElement>
          </>
        ) : (
          <>
            <HeaderNavElement to="/review/templates">
              {ScreenTitle.TemplateReview}
            </HeaderNavElement>

            <HeaderNavElement to="/submit?view=card">
              {ScreenTitle.AssetReview}
            </HeaderNavElement>

            <HeaderNavElement to="/author?view=card">
              {ScreenTitle.AssetPublish}
            </HeaderNavElement>
          </>
        )}

        {isCurrentUserAdmin ? (
          <HeaderNavElement to="/permissions/author">
            {ScreenTitle.Permissions}
          </HeaderNavElement>
        ) : (
          <></>
        )}

        {!isUsingExperimentalRepository ? (
          <HeaderNavElement to="/search">Search</HeaderNavElement>
        ) : null}
      </Flex>
    </nav>
  );
}
