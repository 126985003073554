/**
 * *****************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2022 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Adobe and its suppliers, if any. The intellectual and technical concepts
 * contained herein are proprietary to Adobe and its suppliers and are
 * protected by all applicable intellectual property laws, including trade
 * secret and copyright laws. Dissemination of this information or reproduction
 * of this material is strictly forbidden unless prior written permission is
 * obtained from Adobe.
 * *****************************************************************************
 */

import React, { ReactChild, ReactElement } from 'react';

import { Flex, View } from '@adobe/react-spectrum';

import TemplateRailAction from './TemplateRailAction';

import styles from './TemplateRail.module.scss';

type TemplateRailProps = {
  children: ReactChild | ReactChild[];
};

export default function TemplateRail({
  children,
}: TemplateRailProps): ReactElement {
  return (
    <View UNSAFE_className={styles.templateRail}>
      <Flex gap="size-100" direction="column">
        {children}
      </Flex>
    </View>
  );
}

TemplateRail.Action = TemplateRailAction;
