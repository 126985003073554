/**
 * *****************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2022 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Adobe and its suppliers, if any. The intellectual and technical concepts
 * contained herein are proprietary to Adobe and its suppliers and are
 * protected by all applicable intellectual property laws, including trade
 * secret and copyright laws. Dissemination of this information or reproduction
 * of this material is strictly forbidden unless prior written permission is
 * obtained from Adobe.
 * *****************************************************************************
 */

import React, { ReactElement, useCallback, useState } from 'react';

import Copy from '@spectrum-icons/workflow/Copy';
import Color from 'color';

import ExtractedColor from 'model/acp/ExtractedColor';

import styles from './ColorBar.module.scss';

interface TransformedColor extends ExtractedColor {
  hex: string;
  isLight: boolean;
}

type Props = {
  colors: ExtractedColor[];
};

export default function Colors({ colors = [] }: Props): ReactElement {
  const [isCopying, setIsCopying] = useState(false);
  const copyColor = useCallback(
    async (color: TransformedColor) => {
      setIsCopying(true);
      await navigator.clipboard.writeText(color.hex);
      setTimeout(() => {
        setIsCopying(false);
      }, 1000);
    },
    [setIsCopying],
  );
  const transformedColors = colors.map((c) => {
    const colorInstance = Color.rgb(c.value.r, c.value.g, c.value.b);

    return {
      ...c,
      hex: colorInstance.hex(),
      isLight: colorInstance.isLight(),
    };
  }) as TransformedColor[];

  return (
    <div className={styles.colors}>
      {transformedColors.map((color) => (
        <button
          key={color.name}
          type="button"
          className={`${styles.color} ${color.isLight ? styles.light : ''}`}
          style={{ background: color.hex }}
          onClick={() => copyColor(color)}
          onMouseLeave={() => setIsCopying(false)}
        >
          <div className={styles.hex}>{color.hex}</div>
          <div className={styles.copy}>
            <Copy />
            {isCopying ? 'Copied' : 'Copy'}
          </div>
        </button>
      ))}
    </div>
  );
}
