/**
 * *****************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2022 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Adobe and its suppliers, if any. The intellectual and technical concepts
 * contained herein are proprietary to Adobe and its suppliers and are
 * protected by all applicable intellectual property laws, including trade
 * secret and copyright laws. Dissemination of this information or reproduction
 * of this material is strictly forbidden unless prior written permission is
 * obtained from Adobe.
 * *****************************************************************************
 */

import React, { ReactElement } from 'react';

import { Button } from '@adobe/react-spectrum';
import ChevronLeft from '@react/react-spectrum/Icon/ChevronLeft';
import ChevronRight from '@react/react-spectrum/Icon/ChevronRight';

import Loading from 'components/Loading';
import { ScreenView } from 'redux/screen/screen.slice';

import styles from './TemplatesBrowse.module.scss';

type LoadingBrowseProps = {
  onBrowseNext: () => void;
  onBrowsePrevious: () => void;
  onViewButtonClick: (screen: ScreenView) => void;
};

export default function LoadingBrowse({
  onBrowseNext,
  onBrowsePrevious,
  onViewButtonClick,
}: LoadingBrowseProps): ReactElement {
  return (
    <div className={styles.templatesBrowse}>
      <div className={styles.browse}>
        <div className={styles.navigation}>
          <Button
            variant="primary"
            isQuiet
            onPress={() => onViewButtonClick(ScreenView.Grid)}
          >
            <ChevronLeft /> Return to Templates
          </Button>
        </div>
        <Button
          onPress={onBrowsePrevious}
          UNSAFE_className={styles.prev}
          variant="secondary"
          isQuiet
          aria-label="Previous Template"
        >
          <ChevronLeft size="XL" />
        </Button>
        <Loading />
        <Button
          onPress={onBrowseNext}
          UNSAFE_className={styles.next}
          variant="secondary"
          isQuiet
          aria-label="Next Template"
        >
          <ChevronRight size="XL" />
        </Button>
      </div>
    </div>
  );
}
