/**
 * *****************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2022 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Adobe and its suppliers, if any. The intellectual and technical concepts
 * contained herein are proprietary to Adobe and its suppliers and are
 * protected by all applicable intellectual property laws, including trade
 * secret and copyright laws. Dissemination of this information or reproduction
 * of this material is strictly forbidden unless prior written permission is
 * obtained from Adobe.
 * *****************************************************************************
 */

import { JSXElementConstructor } from 'react';

import BoxExport from '@spectrum-icons/workflow/BoxExport';
import CheckmarkCircle from '@spectrum-icons/workflow/CheckmarkCircle';
import CloseCircle from '@spectrum-icons/workflow/CloseCircle';
import Delete from '@spectrum-icons/workflow/Delete';
import Download from '@spectrum-icons/workflow/Download';
import FolderAddTo from '@spectrum-icons/workflow/FolderAddTo';
import Gears from '@spectrum-icons/workflow/Gears';
import PublishCheck from '@spectrum-icons/workflow/PublishCheck';
import PublishRemove from '@spectrum-icons/workflow/PublishRemove';
import Rename from '@spectrum-icons/workflow/Rename';

import { AssetAction } from './AssetAction';

export const AssetActionIconMap = new Map<
  AssetAction,
  JSXElementConstructor<any>
>([
  [AssetAction.Approve, CheckmarkCircle],
  [AssetAction.MetadataExport, BoxExport],
  [AssetAction.Delete, Delete],
  [AssetAction.Download, Download],
  [AssetAction.Move, FolderAddTo],
  [AssetAction.Publish, PublishCheck],
  [AssetAction.Reject, CloseCircle],
  [AssetAction.Rename, Rename],
  [AssetAction.Unpublish, PublishRemove],
  [AssetAction.Verify, Gears],
]);
