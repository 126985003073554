/**
 * *****************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2022 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Adobe and its suppliers, if any. The intellectual and technical concepts
 * contained herein are proprietary to Adobe and its suppliers and are
 * protected by all applicable intellectual property laws, including trade
 * secret and copyright laws. Dissemination of this information or reproduction
 * of this material is strictly forbidden unless prior written permission is
 * obtained from Adobe.
 * *****************************************************************************
 */

import { SemanticFolder } from 'model/SemanticFolder';

const acpAssetBasePath = '/content/assets';
const acpDirectoryBasePath = '/content/directory';
const wolverineBasePath = '/content/assets/content';

export default class ACPBasePath {
  public readonly assets: string = acpAssetBasePath;
  public readonly assetsOps: string = `${acpAssetBasePath}/ops`;
  public readonly directory: string = acpDirectoryBasePath;
  public readonly directoryOps: string = `${acpDirectoryBasePath}/ops`;
  public readonly author: string = `${acpAssetBasePath}/${SemanticFolder.Author}`;
  public readonly publish: string = `${acpAssetBasePath}/${SemanticFolder.Publish}`;
  public readonly unpublish: string = `${acpAssetBasePath}/published`;
  public readonly submit: string = `${acpAssetBasePath}/${SemanticFolder.Submit}`;
  public readonly approved: string = `${wolverineBasePath}/approved`;
  public readonly pending: string = `${wolverineBasePath}/pending`;
  public readonly rejected: string = `${wolverineBasePath}/rejected`;
  public readonly wolverineAssets: string = wolverineBasePath;
}
