/**
 * *****************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2022 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Adobe and its suppliers, if any. The intellectual and technical concepts
 * contained herein are proprietary to Adobe and its suppliers and are
 * protected by all applicable intellectual property laws, including trade
 * secret and copyright laws. Dissemination of this information or reproduction
 * of this material is strictly forbidden unless prior written permission is
 * obtained from Adobe.
 * *****************************************************************************
 */

// tbd: shouldn't this be two files?
/* eslint-disable max-classes-per-file */

import Fraction from 'fraction.js';

import { SearchAsset } from '__DEPRECATED_DO_NOT_USE_OR_YOU_WILL_BE_FIRED/controllers/api/SparkSearch';
import { TemplateAsset } from '__DEPRECATED_DO_NOT_USE_OR_YOU_WILL_BE_FIRED/controllers/api/SparkSearchTypes';
import { SearchSchema } from '__DEPRECATED_DO_NOT_USE_OR_YOU_WILL_BE_FIRED/controllers/SearchParamsBuilder/types';
import {
  TAsset,
  TAssetKind,
} from '__DEPRECATED_DO_NOT_USE_OR_YOU_WILL_BE_FIRED/redux/assets/asset.types';

import { AssetMimeType } from 'model/acp/AssetMimeType';
import { Entitlement } from 'model/acp/Entitlement';

const getSizeForDisplay = (width: number, height: number, ratio: boolean) => {
  const xSep = ratio ? ':' : ' x ';

  if (ratio) {
    const f = new Fraction(width / height).simplify(0.008);
    // eslint-disable-next-line no-param-reassign
    width = f.n;
    // eslint-disable-next-line no-param-reassign
    height = f.d;
  }

  if (width) {
    return `${width}${xSep}${height}`;
  }

  return `?${xSep}?`;
};

class SearchAssetUtils {
  static getTitle(asset: SearchAsset): string {
    if (!asset.title) {
      return `Unknown ${asset.kind} title`;
    }

    if (typeof asset.title === 'object') {
      return asset.title['en-US'] || asset.name;
    }

    return asset.title;
  }
  static isPremium(asset: SearchAsset): boolean {
    if (asset.schema === SearchSchema.asset) {
      // default to premium if licensingCategory doesn't exist
      if (!asset.licensingCategory) {
        return true;
      }

      return asset.licensingCategory === Entitlement.Premium;
    }

    return asset.premium;
  }
  static getEntitlement(asset: SearchAsset): string {
    if (asset.schema === SearchSchema.template) {
      return asset.premium ? Entitlement.Premium : Entitlement.Free;
    }

    return asset.licensingCategory || Entitlement.Premium;
  }
  static getContentType(asset: SearchAsset): string {
    return asset.contentType || asset.type;
  }
}

/**
 * Helper class used to identify different kinds of assets.
 */
export default class AssetUtils {
  static isFolderOrCollection(asset: TAsset): boolean {
    return (
      asset.kind === TAssetKind.collection || asset.kind === TAssetKind.folder
    );
  }
  static isTemplateAsset(asset: SearchAsset): asset is TemplateAsset {
    return asset.schema === SearchSchema.template;
  }
  static getSizeForDisplay(asset: TAsset) {
    let height;
    let width;

    if (AssetUtils.isTemplateAsset(asset as SearchAsset)) {
      width = asset.rendition.maxWidth || 0;
      height = asset.rendition.maxHeight || 0;
      const ratio = getSizeForDisplay(width, height, true);
      const dims = getSizeForDisplay(width, height, false);

      return `${ratio} (${dims})`;
    }

    if (asset.kind === TAssetKind.image) {
      let contentType;

      if (asset.schema) {
        width = asset.width || 0;
        height = asset.height || 0;
        contentType = asset.contentType;
      } else {
        width = asset.buildJson['tiff:imageWidth'] || 0;
        height = asset.buildJson['tiff:imageLength'] || 0;
        contentType = asset.mime;
      }

      return getSizeForDisplay(
        width,
        height,
        contentType === AssetMimeType.SVG,
      );
    }

    return '';
  }
  static SearchAsset = SearchAssetUtils;
}
