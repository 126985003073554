/**
 * *****************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2022 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Adobe and its suppliers, if any. The intellectual and technical concepts
 * contained herein are proprietary to Adobe and its suppliers and are
 * protected by all applicable intellectual property laws, including trade
 * secret and copyright laws. Dissemination of this information or reproduction
 * of this material is strictly forbidden unless prior written permission is
 * obtained from Adobe.
 * *****************************************************************************
 */

import React, { ReactElement } from 'react';
import { Link } from 'react-router-dom';

import {
  Button,
  Content,
  Flex,
  Heading,
  IllustratedMessage,
} from '@adobe/react-spectrum';
import NotFound from '@spectrum-icons/illustrations/NotFound';

import styles from '../errors.module.scss';

const HomeLink = React.forwardRef<HTMLAnchorElement>((props, ref) => (
  <Link
    to="/"
    ref={ref}
    // eslint-disable-next-line react/jsx-props-no-spreading
    {...props}
  />
));

type NotFoundErrorScreenProps = {
  shouldShowHomeLink?: boolean;
};

export default function NotFoundErrorScreen({
  shouldShowHomeLink = true,
}: NotFoundErrorScreenProps): ReactElement {
  return (
    <Flex
      alignItems="center"
      direction="column"
      height="100%"
      justifyContent="center"
      width="100%"
    >
      <IllustratedMessage UNSAFE_className={styles.error} width="size-6000">
        <NotFound />
        <Heading level={1}>We couldn&apos;t find your page</Heading>
        <Content UNSAFE_className={styles.content}>
          <p>
            The requested page is missing from our system. It might have moved
            or been removed from our supported features.
          </p>

          {shouldShowHomeLink ? (
            <Button elementType={HomeLink} marginTop="size-50" variant="cta">
              Back to Home
            </Button>
          ) : null}
        </Content>
      </IllustratedMessage>
    </Flex>
  );
}

NotFoundErrorScreen.defaultProps = {
  shouldShowHomeLink: true,
};
