/**
 * *****************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2022 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Adobe and its suppliers, if any. The intellectual and technical concepts
 * contained herein are proprietary to Adobe and its suppliers and are
 * protected by all applicable intellectual property laws, including trade
 * secret and copyright laws. Dissemination of this information or reproduction
 * of this material is strictly forbidden unless prior written permission is
 * obtained from Adobe.
 * *****************************************************************************
 */

import React from 'react';

import { TextArea, View } from '@adobe/react-spectrum';

import { AssetMetadataFormFieldProps } from './AssetMetadataFormFieldProps';
import FormLabel from './FormLabel';

export default function GenericTextAreaField({
  label,
  isReadOnly,
  isDisabled,
  onChange,
  textLabel,
  value,
}: AssetMetadataFormFieldProps<string>) {
  return (
    <>
      <View marginTop="size-250">
        <FormLabel>{label}</FormLabel>
      </View>
      <TextArea
        isDisabled={isReadOnly || isDisabled}
        value={value}
        onChange={onChange}
        width="100%"
        aria-label={textLabel ?? (typeof label === 'string' ? label : '')}
      />
    </>
  );
}
