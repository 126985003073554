/**
 * *****************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2022 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Adobe and its suppliers, if any. The intellectual and technical concepts
 * contained herein are proprietary to Adobe and its suppliers and are
 * protected by all applicable intellectual property laws, including trade
 * secret and copyright laws. Dissemination of this information or reproduction
 * of this material is strictly forbidden unless prior written permission is
 * obtained from Adobe.
 * *****************************************************************************
 */

import React, { ReactElement, useCallback } from 'react';

import { NumberField } from '@adobe/react-spectrum';

import ToggleButton from 'components/ToggleButton';

type Props = {
  'aria-label': string;
  value?: number;
  isReadOnly: boolean;
  onChange: (newValue?: number) => void;
  children: ReactElement | ReactElement[];
  maxValue?: number;
};

export default function ToggleNumberField({
  'aria-label': ariaLabel,
  isReadOnly,
  value,
  onChange,
  children,
  maxValue,
}: Props): ReactElement {
  const hasValue = value !== undefined;
  const toggleEnabled = useCallback(
    (isEnabled: boolean): void => {
      if (isEnabled) {
        onChange(0);
      } else {
        onChange();
      }
    },
    [onChange],
  );

  return (
    <>
      <ToggleButton
        isDisabled={isReadOnly}
        isSelected={hasValue}
        onChange={toggleEnabled}
      >
        {children}
      </ToggleButton>
      {hasValue ? (
        <NumberField
          isReadOnly={isReadOnly}
          aria-label={ariaLabel}
          isQuiet
          width="size-200"
          marginStart="size-100"
          minValue={0}
          maxValue={maxValue}
          onChange={onChange}
          value={value}
        />
      ) : (
        <div />
      )}
    </>
  );
}

ToggleNumberField.defaultProps = {
  value: undefined,
  maxValue: undefined,
};
