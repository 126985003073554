/**
 * *****************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2022 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Adobe and its suppliers, if any. The intellectual and technical concepts
 * contained herein are proprietary to Adobe and its suppliers and are
 * protected by all applicable intellectual property laws, including trade
 * secret and copyright laws. Dissemination of this information or reproduction
 * of this material is strictly forbidden unless prior written permission is
 * obtained from Adobe.
 * *****************************************************************************
 */

import React, { ReactElement } from 'react';

import { Flex, Image, Text } from '@adobe/react-spectrum';
import DocumentOutline from '@spectrum-icons/workflow/DocumentOutline';
import FileCode from '@spectrum-icons/workflow/FileCode';
import FileCSV from '@spectrum-icons/workflow/FileCSV';
import FileHTML from '@spectrum-icons/workflow/FileHTML';
import FileJson from '@spectrum-icons/workflow/FileJson';
import FolderOutline from '@spectrum-icons/workflow/FolderOutline';

import Loading from 'components/Loading';
import useRepoMetadata from 'hooks/acp/useRepoMetadata';
import useHeroRenditionLink from 'hooks/useHeroRenditionLink';
import useRenditionLink from 'hooks/useRenditionLink';
import { ACPLink } from 'model/acp/ACPLink';
import { AssetClass } from 'model/acp/AssetClass';
import { AssetMimeType } from 'model/acp/AssetMimeType';
import HALLink from 'model/acp/HALLink';
import { RepoMetadataKey } from 'model/acp/RepoMetadataKey';

import styles from './AssetRendition.module.scss';

type Props = {
  path: string;
  isHero?: boolean;
  isPremium?: boolean;
  isVisible?: boolean;
  size: number;
  kind?: 'asset' | 'collection' | 'directory' | 'minimal';
};

export default function AssetRendition({
  kind,
  size,
  path,
  isHero = false,
  isPremium = false,
  isVisible = true,
}: Props): ReactElement {
  const { results: repoMetadata } = useRepoMetadata({ path });
  const assetClass = repoMetadata?.[RepoMetadataKey.AssetClass];
  const name = repoMetadata?.[RepoMetadataKey.Name];
  const links = repoMetadata?.[RepoMetadataKey.Links];
  const iconStyle = {
    height: `${Math.floor(size * 0.45)}px`,
    fill: 'var(--spectrum-alias-border-color-down)',
    width: 'auto',
  };
  let link: HALLink = links?.[ACPLink.Rendition] as HALLink;

  if (Array.isArray(link)) {
    [link] = link;
  }

  const { renditionLink: heroRenditionLink, isLoading: heroIsLoading } =
    useHeroRenditionLink({
      templateParams: {
        size,
      },
      link,
      enabled: isVisible && isHero,
    });
  const { renditionLink, isLoading: renditionIsLoading } = useRenditionLink({
    templateParams: {
      size,
    },
    link,
    enabled: isVisible,
  });
  let imgSrc: string | undefined;

  if (isHero && !heroIsLoading && !renditionIsLoading) {
    imgSrc = heroRenditionLink ?? renditionLink;
  } else {
    imgSrc = renditionLink;
  }

  if (kind === 'directory' || assetClass === AssetClass.Directory) {
    return <FolderOutline UNSAFE_style={iconStyle} />;
  }

  if (kind === 'collection' || path.endsWith('.collection')) {
    return <DocumentOutline UNSAFE_style={iconStyle} />;
  }

  if (!imgSrc && !renditionIsLoading && !heroIsLoading) {
    const dcFormat = repoMetadata?.[RepoMetadataKey.DCFormat];

    switch (dcFormat) {
      case AssetMimeType.CSV:
        return <FileCSV UNSAFE_style={iconStyle} />;

      case AssetMimeType.HTML:
        return <FileHTML UNSAFE_style={iconStyle} />;

      case AssetMimeType.Javascript:
        return <FileCode UNSAFE_style={iconStyle} />;

      case AssetMimeType.JSON:
        return <FileJson UNSAFE_style={iconStyle} />;

      default:
        return <DocumentOutline UNSAFE_style={iconStyle} />;
    }
  }

  if (!imgSrc || !isVisible) {
    return <Loading />;
  }

  return (
    <Flex
      alignItems="center"
      direction="column"
      height="100%"
      justifyContent="center"
      position="relative"
      width="100%"
    >
      <>
        <Image
          UNSAFE_className={styles.image}
          src={imgSrc || ''}
          alt={name ?? ''}
        />
        {isHero ? (
          <div
            className={`${styles.fallback} ${isPremium ? styles.premium : ''}`}
          >
            <Text UNSAFE_style={{ fontWeight: 900 }}>H</Text>
          </div>
        ) : null}
      </>
    </Flex>
  );
}

AssetRendition.defaultProps = {
  isHero: false,
  isPremium: false,
  isVisible: true,
  kind: undefined,
};
