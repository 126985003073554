/**
 * *****************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2022 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Adobe and its suppliers, if any. The intellectual and technical concepts
 * contained herein are proprietary to Adobe and its suppliers and are
 * protected by all applicable intellectual property laws, including trade
 * secret and copyright laws. Dissemination of this information or reproduction
 * of this material is strictly forbidden unless prior written permission is
 * obtained from Adobe.
 * *****************************************************************************
 */

import DCTitle from 'model/acp/DCTitle';
import { Entitlement } from 'model/acp/Entitlement';

import { Language } from '../model/Language';

export function stringToBoolean(
  data: string,
  propName: string,
): boolean | undefined {
  if (!data) {
    return undefined;
  }

  switch (data.toLowerCase().trim()) {
    case 'true':
      return true;

    case 'false':
      return false;

    default:
      throw new Error(`${propName} should be true or false`);
  }
}

export function stringToLicensingCategory(
  data: string,
): Entitlement | undefined {
  if (!data) {
    return undefined;
  }

  switch (data.toLowerCase().trim()) {
    case 'premium':
      return Entitlement.Premium;

    case 'free':
      return Entitlement.Free;

    default:
      throw new Error('Entitlement must be premium or free');
  }
}

export function stringToLanguage(data: string): string | undefined {
  if (!data) {
    return undefined;
  }

  const isValidLanguage: boolean = Object.values(Language).includes(
    data.trim() as Language,
  );

  if (!isValidLanguage) {
    throw new Error('Language must be one of the allowed 16 Spark locales');
  }

  return data.trim();
}

export function stringToPriority(data: string): number | undefined {
  if (!data) {
    return undefined;
  }

  const priority = parseInt(data.trim(), 10);

  if (priority < 1 || data.includes('.')) {
    throw new Error('Priority must be an integer and greater than 0');
  }

  return priority;
}

export function stringToTitle(data: string): DCTitle | undefined {
  if (!data) {
    return undefined;
  }

  return {
    'rdf:_1': {
      '@language': 'en-US',
      '@value': data.trim(),
    },
  };
}

export function stringToArray(data: string): string[] | undefined {
  if (!data) {
    return undefined;
  }

  return data.split(',').map((str) => str.toLowerCase().trim());
}

function capitalizeFirstLetter(str: string) {
  return str[0].toUpperCase() + str.slice(1);
}

export function stringToTraits(
  data: string | undefined,
  allowedTraits: string[],
): string[] | undefined {
  if (data === undefined) {
    return undefined;
  }

  if (data.trim().length === 0) {
    return [];
  }

  const traits: string[] = [];
  const rawTraits = data.split(',').map((str) => str.toLowerCase().trim());
  const invalidTraits: string[] = [];
  rawTraits.forEach((trait) => {
    if (!allowedTraits.includes(trait.toLowerCase())) {
      invalidTraits.push(trait);
    } else {
      traits.push(capitalizeFirstLetter(trait));
    }
  });

  if (invalidTraits.length > 0) {
    throw new Error(`Invalid traits: ${invalidTraits.join(', ')}`);
  }

  if (traits.length > 0) {
    return traits;
  }

  return undefined;
}
