/**
 * *****************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2022 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Adobe and its suppliers, if any. The intellectual and technical concepts
 * contained herein are proprietary to Adobe and its suppliers and are
 * protected by all applicable intellectual property laws, including trade
 * secret and copyright laws. Dissemination of this information or reproduction
 * of this material is strictly forbidden unless prior written permission is
 * obtained from Adobe.
 * *****************************************************************************
 */

import React, { ReactElement, useCallback } from 'react';

import { Button, ProgressCircle } from '@adobe/react-spectrum';
import Alert from '@spectrum-icons/workflow/Alert';
import Checkmark from '@spectrum-icons/workflow/Checkmark';
import Close from '@spectrum-icons/workflow/Close';

import { openMenu, PopoverMenu } from 'redux/header/header.slice';
import { useAppDispatch } from 'redux/hooks';
import {
  WorkflowActionJob,
  WorkflowActionJobStatus,
} from 'redux/workflowActions/workflowActions.slice';

import styles from './Toast.module.scss';

enum ToastType {
  Success = 'success',
  Error = 'error',
  Default = 'default',
}

type ToastProps = {
  isAutoDismiss: boolean;
  job: WorkflowActionJob;
  onClose: () => void;
  progress: number | undefined;
};

export default function ToastComponent({
  isAutoDismiss,
  onClose,
  job,
  progress,
}: ToastProps): ReactElement {
  const dispatch = useAppDispatch();
  const { status } = job;
  let type: ToastType;

  switch (status) {
    case WorkflowActionJobStatus.Fulfilled:
      type = ToastType.Success;
      break;

    case WorkflowActionJobStatus.Rejected:
    case WorkflowActionJobStatus.RejectedAndResolved:
      type = ToastType.Error;
      break;

    case WorkflowActionJobStatus.Processing:
    case WorkflowActionJobStatus.Queued:
    default:
      type = ToastType.Default;
      break;
  }

  const openDispatchMenu = useCallback(
    () =>
      dispatch(
        openMenu(
          type === ToastType.Success
            ? PopoverMenu.ActionsComplete
            : PopoverMenu.ActionsInProgress,
        ),
      ),
    [dispatch, type],
  );

  return (
    <div className={`spectrum-Toast spectrum-Toast--${type} ${styles.toast}`}>
      {isAutoDismiss ? <div className={styles.autoDismiss} /> : null}
      {type === ToastType.Default ? (
        <ProgressCircle
          UNSAFE_className={styles.typeIcon}
          aria-label="Processing"
          isIndeterminate={job.options?.isIndeterminate}
          maxValue={job.task?.assets.length}
          size="S"
          value={progress}
          variant="overBackground"
        />
      ) : null}
      {type === ToastType.Error ? (
        <Alert UNSAFE_className={styles.typeIcon} size="S" />
      ) : null}
      {type === ToastType.Success ? (
        <Checkmark UNSAFE_className={styles.typeIcon} size="S" />
      ) : null}
      <div className="spectrum-Toast-body">
        <div className="spectrum-Toast-content">{job.message}</div>
        <Button
          UNSAFE_className={styles.action}
          variant="overBackground"
          isQuiet
          onPress={openDispatchMenu}
        >
          <span className="spectrum-Button-label">
            {type === ToastType.Error ? 'Show' : 'View Queue'}
          </span>
        </Button>
      </div>
      <div className="spectrum-Toast-buttons">
        <button
          className="spectrum-ClearButton spectrum-ClearButton--medium spectrum-ClearButton--overBackground"
          type="button"
          onClick={onClose}
        >
          <Close size="S" />
        </button>
      </div>
    </div>
  );
}
