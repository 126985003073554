/**
 * *****************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2022 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Adobe and its suppliers, if any. The intellectual and technical concepts
 * contained herein are proprietary to Adobe and its suppliers and are
 * protected by all applicable intellectual property laws, including trade
 * secret and copyright laws. Dissemination of this information or reproduction
 * of this material is strictly forbidden unless prior written permission is
 * obtained from Adobe.
 * *****************************************************************************
 */

import React, { ReactElement } from 'react';

import { View } from '@adobe/react-spectrum';

import BaseCardModifiedDate from './BaseCardModifiedDate';
import BaseCardSubtitle from './BaseCardSubtitle';

import styles from './BaseCard.module.scss';

type BaseCardBodyProps = {
  isMinimal: boolean;
  modifiedDate: string | undefined;
  subtitle: string | undefined;
  title: string | undefined;
};

export default function BaseCardBody({
  isMinimal,
  modifiedDate,
  subtitle,
  title,
}: BaseCardBodyProps): ReactElement {
  return (
    <View UNSAFE_className={isMinimal ? styles.minimal : 'spectrum-Card-body'}>
      <View flex>
        <View UNSAFE_className="spectrum-Card-header">
          <View UNSAFE_className={styles.title}>
            {title ? (
              <span title={title}>{title}</span>
            ) : (
              <View UNSAFE_className={styles.placeholder} />
            )}
          </View>
        </View>

        {subtitle !== undefined ? (
          <BaseCardSubtitle subtitle={subtitle} />
        ) : (
          <BaseCardModifiedDate modifiedDate={modifiedDate} />
        )}
      </View>
    </View>
  );
}
