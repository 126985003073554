/**
 * *****************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2022 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Adobe and its suppliers, if any. The intellectual and technical concepts
 * contained herein are proprietary to Adobe and its suppliers and are
 * protected by all applicable intellectual property laws, including trade
 * secret and copyright laws. Dissemination of this information or reproduction
 * of this material is strictly forbidden unless prior written permission is
 * obtained from Adobe.
 * *****************************************************************************
 */

import { IEnvironment } from '@identity/imslib/adobe-id/IEnvironment';

import ProdUrl from 'model/constant/ProdUrl';
import StageUrl from 'model/constant/StageUrl';
import Url from 'model/constant/Url';
import { Language } from 'model/Language';

import ACPBasePath from './ACPBasePath';
import ACPRepositoryId from './ACPRepositoryId';
import CCXConfigPath from './CCXConfigPath';
import JILBasePaths from './JILBasePath';
import SparkSearch from './SparkSearch';

declare global {
  interface Window {
    TEMPO_ENV_NAME: string;
  }
}

const enum CommentsEnvironment {
  Production = 'prod',
  Stage = 'stage',
}

export default class Constant {
  public readonly acpBasePath = new ACPBasePath();
  public readonly acpRepositoryId = new ACPRepositoryId();
  public readonly airtableBase: string;
  public readonly airtableDesignerTable = 'Designers';
  public readonly behanceBaseUrl: string;
  public readonly ccxConfigPath = new CCXConfigPath();
  public readonly commentsBaseUrl: string;
  public readonly commentsEnvironment: CommentsEnvironment;
  public readonly clientId = 'SparkTempo';
  public readonly defaultLanguage = Language.Default;
  public readonly heroRenditionFragment = 'horizon-hero-thumbnail';
  public readonly imsEnvironment: IEnvironment;
  public readonly jilBasePaths = new JILBasePaths();
  public readonly jilBaseUrl: string;
  public readonly languagesExcludedFromStock: string[] = [
    Language['Chinese (Simplified)'],
    Language['Chinese (Traditional)'],
    Language.Finnish,
    Language.Default,
  ];
  public readonly orgName = '@adobespark';
  public readonly sparkSearch = new SparkSearch();
  public readonly stockBaseUrl: string;
  public readonly stockStaticSearchFilters =
    '/search?filters[content_type:photo]=1&price[$]=1';
  public readonly tempoxUrl: string;
  public readonly url: Url;
  public constructor() {
    if (window.TEMPO_ENV_NAME.toLowerCase() === 'production') {
      this.imsEnvironment = IEnvironment.PROD;
      this.commentsEnvironment = CommentsEnvironment.Production;
      this.url = new ProdUrl();
      this.airtableBase = 'appfG9fT1fKjhqMg5';
      this.behanceBaseUrl = 'https://cc-api-behance.adobe.io/v2';
      this.commentsBaseUrl = 'https://comments.adobe.io';
      this.jilBaseUrl = 'https://bps-il.adobe.io/jil-api';
      this.stockBaseUrl = 'https://stock.adobe.com';
      this.tempoxUrl = 'https://tempo.corp.ethos51-prod-va6.ethos.adobe.net/';
    } else {
      this.imsEnvironment = IEnvironment.STAGE;
      this.commentsEnvironment = CommentsEnvironment.Stage;
      this.url = new StageUrl();
      this.airtableBase = 'appoDSkiU5cdX7o1B';
      this.behanceBaseUrl = 'https://cc-api-behance-stage.adobe.io/v2';
      this.commentsBaseUrl = 'https://comments-stage.adobe.io';
      this.jilBaseUrl = 'https://bps-il-stage.adobe.io/jil-api';
      this.stockBaseUrl = 'https://primary.stock.stage.adobe.com';
      this.tempoxUrl =
        'https://tempo-stage.corp.ethos51-stage-va6.ethos.adobe.net/';
    }
  }
}
