/**
 * *****************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2022 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Adobe and its suppliers, if any. The intellectual and technical concepts
 * contained herein are proprietary to Adobe and its suppliers and are
 * protected by all applicable intellectual property laws, including trade
 * secret and copyright laws. Dissemination of this information or reproduction
 * of this material is strictly forbidden unless prior written permission is
 * obtained from Adobe.
 * *****************************************************************************
 */

import React, { ReactElement, useMemo } from 'react';

import { Divider, Flex, Heading } from '@adobe/react-spectrum';
import { formatDistanceToNowStrict } from 'date-fns';

import { TemplateAsset } from '__DEPRECATED_DO_NOT_USE_OR_YOU_WILL_BE_FIRED/controllers/api/SparkSearchTypes';
import AssetUtils from '__DEPRECATED_DO_NOT_USE_OR_YOU_WILL_BE_FIRED/controllers/constants/AssetUtils';

import GenericTextFormField from 'components/AssetMetadataForm/fields/GenericTextFormField';
import useConstant from 'hooks/useConstant';
import { useAppSelector } from 'redux/hooks';

import Pane from '../Pane';

type TemplateOverviewPanelProps = {
  currentAsset: TemplateAsset;
};

export default function TemplateOverviewPanel({
  currentAsset,
}: TemplateOverviewPanelProps): ReactElement {
  const { url } = useConstant();
  const sparkRemixLink = useMemo(
    () => `${url.ccxBase}/post/${currentAsset.id}`,
    [currentAsset, url],
  );
  const ownersMap = useAppSelector((state) => state.tempo.ownersMap);
  const designer =
    ownersMap.designers[currentAsset.owner.id]?.key ??
    ownersMap.others[currentAsset.owner.id]?.key ??
    currentAsset.owner.id;

  return (
    <Pane>
      <Flex justifyContent="space-between">
        <Heading level={2} marginY="size-0" UNSAFE_style={{ fontWeight: 900 }}>
          Template Overview
        </Heading>
      </Flex>

      <GenericTextFormField
        error={undefined}
        isDisabled={false}
        isReadOnly
        onChange={() => {}}
        label="Designer"
        value={designer}
      />

      <Divider size="S" marginTop="size-400" />

      <GenericTextFormField
        error={undefined}
        isDisabled={false}
        isReadOnly
        onChange={() => {}}
        label="ID"
        value={currentAsset.id}
      />

      <GenericTextFormField
        error={undefined}
        isDisabled={false}
        isReadOnly
        onChange={() => {}}
        label="Remix Link"
        value={sparkRemixLink}
      />

      <GenericTextFormField
        error={undefined}
        isDisabled={false}
        isReadOnly
        onChange={() => {}}
        label="Branch URL"
        value={currentAsset.branchURL}
      />

      <GenericTextFormField
        error={undefined}
        isDisabled={false}
        isReadOnly
        onChange={() => {}}
        label="Created"
        value={formatDistanceToNowStrict(new Date(currentAsset.created), {
          addSuffix: true,
        })}
      />

      <GenericTextFormField
        error={undefined}
        isDisabled={false}
        isReadOnly
        onChange={() => {}}
        label="Size"
        value={AssetUtils.getSizeForDisplay(currentAsset)}
      />
    </Pane>
  );
}
