/**
 * *****************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2022 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Adobe and its suppliers, if any. The intellectual and technical concepts
 * contained herein are proprietary to Adobe and its suppliers and are
 * protected by all applicable intellectual property laws, including trade
 * secret and copyright laws. Dissemination of this information or reproduction
 * of this material is strictly forbidden unless prior written permission is
 * obtained from Adobe.
 * *****************************************************************************
 */

import React, {
  Key,
  ReactElement,
  RefObject,
  SyntheticEvent,
  useCallback,
  useRef,
} from 'react';

import { View } from '@adobe/react-spectrum';
import CheckmarkSmall from '@spectrum-icons/ui/CheckmarkSmall';
import DashSmall from '@spectrum-icons/ui/DashSmall';

import StatusLabel from 'components/StatusLabel';
import { AssetStatus } from 'model/acp/AssetStatus';
import { ZoomLevel } from 'model/ZoomLevel';

import BaseCardActions from './BaseCardActions';
import BaseCardBody from './BaseCardBody';
import BaseCardMetadata from './BaseCardMetadata';
import BaseCardQuickActions, { CardAction } from './BaseCardQuickActions';

import styles from './BaseCard.module.scss';

type DisplayCardProps = {
  actions?: CardAction[];
  canBeSelected: boolean;
  isActive?: boolean;
  isInactive?: boolean;
  isMinimal?: boolean;
  isSelected?: boolean;
  metadata: (string | undefined)[][];
  modifiedDate?: string;
  onAction?: (action: string) => void;
  onClick: () => void;
  onSelect: () => void;
  quickActions?: CardAction[];
  rendition?: JSX.Element;
  shouldShowStatus?: boolean;
  size: ZoomLevel;
  status?: AssetStatus;
  subtitle?: string;
  title: string | undefined;
};

export type BaseCardProps = Omit<
  DisplayCardProps,
  | 'canBeSelected'
  | 'metadata'
  | 'modifiedDate'
  | 'rendition'
  | 'shouldShowStatus'
  | 'size'
  | 'subtitle'
  | 'status'
  | 'title'
>;

export default function BaseCard({
  actions,
  canBeSelected,
  isActive = false,
  isInactive = false,
  isSelected = false,
  isMinimal = false,
  metadata,
  modifiedDate,
  onAction,
  onClick,
  onSelect,
  quickActions,
  rendition,
  shouldShowStatus,
  size,
  subtitle,
  status,
  title,
}: DisplayCardProps): ReactElement {
  const checkboxRef = useRef<HTMLInputElement>();
  const handleAction = useCallback(
    (event: SyntheticEvent | undefined, action: Key) => {
      event?.stopPropagation();

      if (checkboxRef.current && event?.target === checkboxRef.current) {
        onSelect();

        return;
      }

      switch (action) {
        case 'select':
          onSelect();
          break;

        case 'open':
          onClick();
          break;

        default:
          if (onAction) {
            onAction(action.toString());
          }

          break;
      }
    },
    [onAction, onClick, onSelect],
  );

  return (
    // eslint-disable-next-line jsx-a11y/interactive-supports-focus
    <div
      className={`spectrum-Card ${actions ? styles.actionDriven : ''} ${
        isMinimal
          ? `spectrum-Card--small spectrum-Card--horizontal ${styles.minimal}`
          : ''
      } ${styles.card} ${isInactive ? styles.inactive : ''} ${
        canBeSelected ? styles.selectable : ''
      } ${isActive ? styles.active : ''} ${isSelected ? styles.selected : ''}`}
      role="button"
      onClick={(event) => handleAction(event, 'select')}
      onDoubleClick={(event) => handleAction(event, 'open')}
      onKeyPress={(event) => handleAction(event, 'open')}
      tabIndex={actions ? undefined : 0}
    >
      {rendition}

      <BaseCardBody
        isMinimal={isMinimal}
        modifiedDate={modifiedDate}
        title={title}
        subtitle={subtitle}
      />

      {!isMinimal && quickActions !== undefined ? (
        <BaseCardQuickActions
          actions={quickActions}
          isHidden={isActive}
          onAction={(key) => handleAction(undefined, key)}
        />
      ) : null}

      {size > ZoomLevel.Small && metadata.length !== 0 ? (
        <BaseCardMetadata metadata={metadata} />
      ) : null}

      {actions ? (
        <BaseCardActions
          actions={actions}
          onAction={(key) => handleAction(undefined, key)}
        />
      ) : null}

      {shouldShowStatus ? (
        <View position="absolute" right="-12px" top="-12px" zIndex={1}>
          <StatusLabel status={status} />
        </View>
      ) : null}

      {canBeSelected ? (
        <div
          className={`spectrum-QuickActions spectrum-Card-quickActions ${
            styles.quickActions
          } ${isActive ? 'is-open' : ''}`}
        >
          <div className="spectrum-Checkbox spectrum-Checkbox--sizeM">
            <input
              type="checkbox"
              className="spectrum-Checkbox-input"
              ref={checkboxRef as RefObject<HTMLInputElement>}
              checked={isSelected}
              readOnly
              title="Select"
              value=""
            />
            <span className="spectrum-Checkbox-box">
              <CheckmarkSmall UNSAFE_className="spectrum-Checkbox-checkmark" />
              <DashSmall UNSAFE_className="spectrum-Checkbox-partialCheckmark" />
            </span>
          </div>
        </div>
      ) : null}
    </div>
  );
}

BaseCard.defaultProps = {
  actions: undefined,
  isActive: false,
  isInactive: false,
  isMinimal: false,
  isSelected: false,
  modifiedDate: undefined,
  onAction: undefined,
  quickActions: undefined,
  rendition: undefined,
  shouldShowStatus: true,
  status: undefined,
  subtitle: undefined,
};
