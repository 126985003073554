/**
 * *****************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2022 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Adobe and its suppliers, if any. The intellectual and technical concepts
 * contained herein are proprietary to Adobe and its suppliers and are
 * protected by all applicable intellectual property laws, including trade
 * secret and copyright laws. Dissemination of this information or reproduction
 * of this material is strictly forbidden unless prior written permission is
 * obtained from Adobe.
 * *****************************************************************************
 */

/* eslint-disable react/boolean-prop-naming */
import React, { ReactChild, ReactElement, useCallback } from 'react';

import { Flex, View } from '@adobe/react-spectrum';
import { Size } from '@react/collection-view';
import { Asset } from '@react/react-spectrum/Asset';
import Clock from '@react/react-spectrum/Icon/Clock';
import { Tag, TagList } from '@react/react-spectrum/TagList';
import Checkmark from '@spectrum-icons/workflow/Checkmark';
import ResolvedComment from '@spectrum-icons/workflow/Comment';
import Visibility from '@spectrum-icons/workflow/Visibility';
import formatDistanceToNowStrict from 'date-fns/formatDistanceToNowStrict';

import { TemplateAsset } from '__DEPRECATED_DO_NOT_USE_OR_YOU_WILL_BE_FIRED/controllers/api/SparkSearchTypes';
import AssetUtils from '__DEPRECATED_DO_NOT_USE_OR_YOU_WILL_BE_FIRED/controllers/constants/AssetUtils';
import { AssetStatus } from '__DEPRECATED_DO_NOT_USE_OR_YOU_WILL_BE_FIRED/controllers/constants/StatusUtils';
import styles from '__DEPRECATED_DO_NOT_USE_OR_YOU_WILL_BE_FIRED/Grid/styles/TemplateCard.module.scss';
import useAssetCommentCount from '__DEPRECATED_DO_NOT_USE_OR_YOU_WILL_BE_FIRED/hooks/useAssetCommentCount';

import { TempoOwnersMap } from 'redux/tempo/tempo.slice';

type CreatedDateProps = {
  created: string;
};

function TemplateCreatedDate({ created }: CreatedDateProps): ReactElement {
  return (
    <div className={styles.date}>
      <Clock size="XS" />
      <div>
        {formatDistanceToNowStrict(new Date(created), { addSuffix: true })}
      </div>
    </div>
  );
}

type TemplateDetailsProps = {
  asset: TemplateAsset;
};

function TemplateDetails({ asset }: TemplateDetailsProps): ReactElement {
  const DetailComponents: JSX.Element[] = [];
  const none = 'None';
  const detailOptions = [
    { label: 'Title', prop: 'title' },
    { label: 'Category', prop: 'categories' },
    { label: 'Keywords', prop: 'topics' },
    { label: 'Use Case', prop: 'tasks' },
    { label: 'Size', prop: 'displaySize' },
  ];

  detailOptions.forEach((option: { label: string; prop: string }) => {
    let value = asset[option.prop];

    if (!value && option.prop === 'displaySize') {
      value = AssetUtils.getSizeForDisplay(asset);
    }

    if (typeof value !== 'string') {
      value = (value as string[]).join(', ');
    }

    DetailComponents.push(
      <div className={styles.detail} key={option.label}>
        <span className={styles.label}>{`${option.label}: `}</span>
        {value || none}
      </div>,
    );
  });

  return <>{DetailComponents}</>;
}

type TemplateTagsProps = {
  asset: TemplateAsset;
  showStatus: boolean;
  showTag: boolean;
};

function TemplateTags({
  asset,
  showTag,
  showStatus,
}: TemplateTagsProps): ReactElement {
  const TagComponents: JSX.Element[] = [];

  if (showTag) {
    const tag = asset.tasks[0];

    if (tag) {
      TagComponents.push(
        <Tag key={tag} className={`${styles.cardTag} ${styles.task}`}>
          {tag}
        </Tag>,
      );
    }
  }

  if (showStatus) {
    const status: AssetStatus = asset.status
      ? (asset.status.toLowerCase() as AssetStatus)
      : AssetStatus.pending;

    TagComponents.push(
      <Tag
        key={status}
        className={`${styles.cardTag} ${styles.status} ${styles[status]}`}
      >
        {status}
      </Tag>,
    );
  }

  return <>{TagComponents}</>;
}

export type TemplateCardProps = {
  asset: TemplateAsset;
  onClick?: (assetId: string) => void;
  ownersMap: TempoOwnersMap;
  showBadges?: boolean;
  showDate?: boolean;
  showDetails?: boolean;
  showRemixCount?: boolean;
  showStatus?: boolean;
  showTag?: boolean;
  thumbnailHref: string;
  thumbnailSize: Size;
};

export default function TemplateCard({
  asset,
  onClick,
  ownersMap,
  showBadges,
  showDate,
  showDetails,
  showRemixCount,
  showStatus = false,
  showTag = false,
  thumbnailHref,
  thumbnailSize,
}: TemplateCardProps): ReactElement {
  const { result: openCommentCount, isLoading: isLoadingOpenCommentCount } =
    useAssetCommentCount({
      currentAssetId: asset.id,
      options: {
        status: 'open',
      },
    });
  const {
    result: resolvedCommentCount,
    isLoading: isLoadingResolvedCommentCount,
  } = useAssetCommentCount({
    currentAssetId: asset.id,
    options: {
      status: 'resolved',
    },
  });
  const placeHolderStyle = {
    width: thumbnailSize.width,
    height: thumbnailSize.height,
    backgroundColor: 'transparent',
  };
  const ownerName =
    ownersMap.designers[asset.owner.id]?.key ??
    ownersMap.others[asset.owner.id]?.key ??
    asset.owner.id;
  const handleClick = useCallback(() => {
    if (onClick) {
      onClick(asset.id);
    }
  }, [asset, onClick]);
  const hasLoadedComments =
    !isLoadingOpenCommentCount && !isLoadingResolvedCommentCount;
  const hasOpenComments = hasLoadedComments && openCommentCount !== 0;
  const hasResolvedComments = hasLoadedComments && resolvedCommentCount !== 0;
  let commentCount: ReactChild | undefined;
  let backgroundColor: string;

  if (hasOpenComments) {
    commentCount = (
      <Flex position="relative" alignItems="center" justifyContent="center">
        <ResolvedComment size="M" />
        <View
          UNSAFE_style={{
            color: 'var(--spectrum-global-color-static-orange-500)',
            fontSize: '14px',
            fontWeight: 700,
          }}
          marginBottom="size-50"
          position="absolute"
        >
          {openCommentCount}
        </View>
      </Flex>
    );
    backgroundColor = styles.notice;
  } else if (hasResolvedComments) {
    commentCount = (
      <Flex position="relative" alignItems="center" justifyContent="center">
        <ResolvedComment size="M" />
        <Checkmark
          size="XS"
          position="absolute"
          marginBottom="size-25"
          color="positive"
        />
      </Flex>
    );
    backgroundColor = styles.positive;
  } else {
    commentCount = (
      <Flex position="relative" alignItems="center" justifyContent="center">
        <ResolvedComment size="M" />
        <Visibility
          size="XS"
          position="absolute"
          marginBottom="size-40"
          color="informative"
        />
      </Flex>
    );
    backgroundColor = styles.informative;
  }

  return (
    <>
      <View position="relative">
        {hasLoadedComments ? (
          <Flex
            UNSAFE_className={`${styles.commentBadge} ${backgroundColor}`}
            justifyContent="center"
            alignItems="center"
            position="absolute"
            top="-20px"
            right="-20px"
            height="size-500"
            width="size-500"
          >
            {commentCount}
          </Flex>
        ) : null}
        <Asset
          type="image"
          src={thumbnailHref}
          alt={asset.title}
          style={{ ...placeHolderStyle, cursor: 'pointer' }}
          onClick={handleClick}
          smartness={0}
        />

        {showBadges && asset.premium ? (
          <div className={styles.premiumBadge} />
        ) : (
          ''
        )}
      </View>

      <div
        className={styles.cardItemInfo}
        style={{ width: placeHolderStyle.width }}
      >
        <div className={styles.top}>
          <div className={styles.creator}>{ownerName}</div>
          <TagList readOnly>
            <TemplateTags
              asset={asset}
              showStatus={showStatus}
              showTag={showTag}
            />
          </TagList>
        </div>
        {showDetails ? (
          <div className={styles.details}>
            <TemplateDetails asset={asset} />
          </div>
        ) : (
          ''
        )}
        {showRemixCount ? (
          <div className={styles.remixCount}>
            Remix Count:
            {asset.remixCount}
          </div>
        ) : (
          ''
        )}
        {showDate ? <TemplateCreatedDate created={asset.created} /> : null}
      </div>
    </>
  );
}

TemplateCard.defaultProps = {
  onClick: undefined,
  showBadges: false,
  showDate: false,
  showDetails: false,
  showRemixCount: false,
  showStatus: false,
  showTag: false,
};
