/**
 * *****************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2022 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Adobe and its suppliers, if any. The intellectual and technical concepts
 * contained herein are proprietary to Adobe and its suppliers and are
 * protected by all applicable intellectual property laws, including trade
 * secret and copyright laws. Dissemination of this information or reproduction
 * of this material is strictly forbidden unless prior written permission is
 * obtained from Adobe.
 * *****************************************************************************
 */

import React, { ReactElement, useCallback, useState } from 'react';

import { Flex, View } from '@adobe/react-spectrum';
import { Tag, TagList } from '@react/react-spectrum/TagList';
import AddCircle from '@spectrum-icons/workflow/AddCircle';
import Circle from '@spectrum-icons/workflow/Circle';
import RemoveCircle from '@spectrum-icons/workflow/RemoveCircle';

import { Casing } from './Casing';
import DeterminateInput from './DeterminateInput';
import IndeterminateInput from './IndeterminateInput';
import { TagInputContext } from './TagInputContext';
import TagInputSetProps from './TagInputSetProps';

import styles from './index.module.scss';

const allInBoth = (arr1: string[], arr2: string[]): boolean => {
  let result = arr1.length === arr2.length;

  if (result) {
    const setified = new Set(arr1);

    for (let i = 0; i < arr2.length && result; i++) {
      result = setified.has(arr2[i]);
    }
  }

  return result;
};

/**
 * This component can either enable a user to select from a predefined set of
 * items – possibleItems – or be an open list of items.  If there is a
 * possibleItems array set then a combo box will be used, otherwise an input
 * text will be used.
 */
export default function TagInputSet({
  disabled,
  singularLabel,
  pluralLabel,
  setCurrentItems,
  isSingleSelect = false,
  currentItems = [],
  possibleItems = [],
  casing = Casing.LowerCase,
}: TagInputSetProps): ReactElement {
  const isDeterminate = !!possibleItems.length;
  const [isInputMode, setIsInputMode] = useState(false);
  const [isAllSelected, setIsAllSelected] = useState(
    isDeterminate && allInBoth(currentItems, possibleItems),
  );
  const onRemove = (item: string): void => {
    const isNotItem = (selectedItem: string): boolean => item !== selectedItem;
    setIsAllSelected(false);
    setCurrentItems(Array.from(new Set(currentItems.filter(isNotItem))));
  };
  const toggleInputMode = useCallback(() => {
    if (disabled) {
      return;
    }

    setIsInputMode(!isInputMode);
  }, [setIsInputMode, isInputMode, disabled]);
  const tagInputSetProps: TagInputSetProps = {
    setCurrentItems,
    currentItems,
    isSingleSelect,
    possibleItems,
    pluralLabel,
    singularLabel,
    casing,
  };

  if (isDeterminate) {
    tagInputSetProps.possibleItems = possibleItems.filter(
      (item: string): boolean => currentItems.indexOf(item) < 0,
    );
  }

  return (
    <View minHeight="size-900" marginTop="size-150">
      <Flex direction="column" gap="size-0">
        <View>
          <Flex>
            <View>
              <Flex gap="size-100">
                {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events */}
                <div
                  tabIndex={0}
                  role="button"
                  className={styles.clickable}
                  onClick={toggleInputMode}
                >
                  <Flex gap="size-100">
                    <View>
                      <div className={styles.label}>{pluralLabel}</div>
                    </View>
                    <View>
                      {
                        // eslint-disable-next-line no-nested-ternary
                        !disabled ? (
                          // eslint-disable-next-line no-nested-ternary
                          !isAllSelected ? (
                            isInputMode ? (
                              <RemoveCircle color="informative" size="S" />
                            ) : (
                              <AddCircle color="informative" size="S" />
                            )
                          ) : (
                            <Circle color="informative" size="S" />
                          )
                        ) : (
                          <></>
                        )
                      }
                    </View>
                  </Flex>
                </div>
                {isInputMode ? (
                  <View>
                    <TagInputContext.Provider value={tagInputSetProps}>
                      {isDeterminate ? (
                        <DeterminateInput
                          onSelectAll={(): void => {
                            setIsAllSelected(true);
                          }}
                        />
                      ) : (
                        <IndeterminateInput casing={casing} />
                      )}
                    </TagInputContext.Provider>
                  </View>
                ) : (
                  <div />
                )}
              </Flex>
            </View>
          </Flex>
        </View>
        <View>
          <TagList aria-label={`${pluralLabel} Tag List`} onClose={onRemove}>
            {currentItems.map(
              (item: string): ReactElement => (
                <Tag closeable className={styles.tag} key={item}>
                  {item}
                </Tag>
              ),
            )}
          </TagList>
        </View>
      </Flex>
    </View>
  );
}

TagInputSet.defaultProps = {
  possibleItems: [],
  currentItems: [],
};
