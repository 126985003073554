/**
 * *****************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2022 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Adobe and its suppliers, if any. The intellectual and technical concepts
 * contained herein are proprietary to Adobe and its suppliers and are
 * protected by all applicable intellectual property laws, including trade
 * secret and copyright laws. Dissemination of this information or reproduction
 * of this material is strictly forbidden unless prior written permission is
 * obtained from Adobe.
 * *****************************************************************************
 */

import { useMemo } from 'react';
import { useInfiniteQuery, useQueryClient } from 'react-query';

import Axios from 'axios';
import * as URITemplate from 'uritemplate';

import useACPLink, { ACPError } from 'hooks/acp/useACPLink';
import useRepoMetadata from 'hooks/acp/useRepoMetadata';
import { ACPLink } from 'model/acp/ACPLink';
import { AssetClass } from 'model/acp/AssetClass';
import { AssetMimeType } from 'model/acp/AssetMimeType';
import { ChildAsset } from 'model/acp/ChildAsset';
import Directory from 'model/acp/Directory';
import { OrderBy } from 'model/acp/OrderBy';
import { RepoMetadataKey } from 'model/acp/RepoMetadataKey';
import { SortOrder } from 'model/acp/SortOrder';

type DirectoryHookParams = {
  enabled?: boolean;
  sortOrder?: SortOrder;
  limit?: number;
  page?: number;
  path: string;
  orderBy?: OrderBy;
  type?: AssetMimeType;
};

type DirectoryHookResult = {
  directory?: Directory;
  errors?: ACPError[];
  getNextPage?: () => void;
  hasNextPage?: boolean;
  invalidate?: () => Promise<void>;
  isLoadingMore: boolean;
};

const L1_DIR_LEN = 3;
/*
 * When loading a directory, we first attempt to load directories, then
 * collections. After that, we can't attempt to load a negative filter so
 * instead we just load everything (using `null`) and drop what we've already
 * loaded.
 */
const AssetMimeTypeOrder = [
  AssetMimeType.Directory,
  AssetMimeType.Collection,
  null,
];

type DirectoryResponse = {
  data: Directory;
  pageParam: string;
};

export default function useDirectory({
  path,
  limit,
  type,
  orderBy = OrderBy.Name,
  sortOrder = SortOrder.Ascending,
  enabled = true,
}: DirectoryHookParams): DirectoryHookResult {
  let requestUrl = '';
  let directoryPath = '';
  const queryClient = useQueryClient();
  const { results: repoMetadata, errors } = useRepoMetadata({
    path,
    enabled,
  });

  if (repoMetadata) {
    const assetPath = repoMetadata[RepoMetadataKey.Path];

    if (repoMetadata[RepoMetadataKey.AssetClass] === AssetClass.Directory) {
      directoryPath = assetPath;
    } else {
      const dirs: string[] = assetPath.split('/');

      dirs.pop();

      directoryPath =
        dirs.length > L1_DIR_LEN ? String(dirs.join('/')) : assetPath;
    }
  }

  const { results, isLoading: isLoadingACP } = useACPLink({
    acpLink: ACPLink.Page,
    path: directoryPath,
    enabled: enabled && !!repoMetadata,
  });

  if (results?.linkUrl) {
    requestUrl = URITemplate.parse(results.linkUrl).expand({
      orderBy: sortOrder === SortOrder.Descending ? `-${orderBy}` : orderBy,
      type: type ?? AssetMimeTypeOrder[0],
      limit,
    });
  }

  const query = async (url: string): Promise<Directory> => {
    const result = await Axios.get<Directory>(url);

    return result.data;
  };
  const { isLoading, hasNextPage, fetchNextPage, isFetchingNextPage, data } =
    useInfiniteQuery<DirectoryResponse>(['directory', requestUrl], {
      queryFn: async ({ pageParam = requestUrl }) => ({
        data: await query(pageParam),
        pageParam,
      }),
      /**
       * Disable the ReactQuery query call if this function is run but the
       * links haven't loaded, or the repoMetadata hasn't loaded, or it isn't a
       * directory or if the requestUrl isn't defined because there will be no
       * reason to make the request because we will never or can't ever get the
       * children.
       */
      enabled: enabled && results?.linkUrl !== undefined && requestUrl !== '',
      /**
       * Due to restrictions on the Repository API, we can't actually ask for
       * results sorted by asset type. Instead, if no type is specified we query
       * the API incrementally for each of the kinds we want in order. We keep
       * track of where we are in the mime type list in state to persist between
       * page requests.
       */
      getNextPageParam: (lastPage: DirectoryResponse): string | undefined => {
        if (lastPage.data._links.next?.href) {
          return lastPage.data._links.next.href;
        }

        // If we have a type, we aren't attempting to page in format order
        if (type) {
          return undefined;
        }

        const searchParams = new URLSearchParams(
          lastPage.pageParam.substring(lastPage.pageParam.indexOf('?')),
        );
        const assetType = searchParams.get('type');

        if (assetType === null) {
          return undefined;
        }

        const assetOrder = AssetMimeTypeOrder.findIndex(
          (value) => value === assetType,
        );

        /*
         * If we are at the end of the asset mime type order list, we shouldn't
         * try to load the next non-existent mime type.
         */
        if (assetOrder + 1 === AssetMimeTypeOrder.length) {
          return undefined;
        }

        /*
         * Generate a URL from template using the next kind of mime type in the
         * list. Unfortunately, we also can't filter by inverse type, i.e. not
         * matching one of our chosen types, meaning we eventually page back over
         * already loaded assets when we get back to generic assets. This is then
         * filtered out using the page combination logic.
         */

        if (results?.linkUrl === undefined) {
          return undefined;
        }

        const url = URITemplate.parse(results.linkUrl).expand({
          orderBy: sortOrder === SortOrder.Descending ? `-${orderBy}` : orderBy,
          type: AssetMimeTypeOrder[assetOrder + 1],
          limit,
        });

        return url;
      },
    });
  const directory = useMemo(
    () =>
      data?.pages.reduce(
        (prevChunk: Directory, curChunk: DirectoryResponse): Directory => {
          const result = {
            ...prevChunk,
            ...curChunk.data,
            children: [
              ...prevChunk.children,
              ...curChunk.data.children.filter(
                (child) =>
                  !prevChunk.children.find(
                    (prevChild) =>
                      child[RepoMetadataKey.AssetId] ===
                      prevChild[RepoMetadataKey.AssetId],
                  ),
              ),
            ],
          };

          return result;
        },
        {
          children: [] as ChildAsset[],
        } as Directory,
      ),
    [data],
  );

  return {
    directory,
    errors,
    getNextPage: fetchNextPage,
    hasNextPage,
    invalidate: requestUrl
      ? (): Promise<void> => queryClient.invalidateQueries(requestUrl)
      : undefined,
    isLoadingMore:
      isLoading || isLoadingACP || isFetchingNextPage || !results?.linkUrl,
  };
}
