/**
 * *****************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2022 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Adobe and its suppliers, if any. The intellectual and technical concepts
 * contained herein are proprietary to Adobe and its suppliers and are
 * protected by all applicable intellectual property laws, including trade
 * secret and copyright laws. Dissemination of this information or reproduction
 * of this material is strictly forbidden unless prior written permission is
 * obtained from Adobe.
 * *****************************************************************************
 */

import React, { ReactElement, useEffect, useState } from 'react';
import { useQueryClient } from 'react-query';

import { Flex, ToggleButton, View } from '@adobe/react-spectrum';
import { CommentsFull, CommentsProvider } from '@ccx-public/ccx-comments';
import RailRightClose from '@spectrum-icons/workflow/RailRightClose';
import RailRightOpen from '@spectrum-icons/workflow/RailRightOpen';

import Pane from 'components/@spectrum/Pane';
import AssetInfo from 'components/DetailView/AssetInfo';
import Loading from 'components/Loading';
import useAdobeIMS from 'hooks/ims/useAdobeIMS';
import useAssetMetadataModel from 'hooks/useAssetMetadataModel';
import useConstant from 'hooks/useConstant';
import useCurrentAssetByPath from 'hooks/useCurrentAssetByPath';
import { AdobeIMSProfile } from 'model/ims/AdobeIMSProfile';

import Styles from './CommentsMetadataForm.module.scss';

type CommentsMetadataFormProps = {
  isCollapsed?: boolean;
  onCollapse?: () => void;
};

export default function CommentsMetadataForm({
  isCollapsed = false,
  onCollapse,
}: CommentsMetadataFormProps): ReactElement {
  const { commentsEnvironment } = useConstant();
  const queryClient = useQueryClient();
  const { currentAssetByPath } = useCurrentAssetByPath();
  const { isLoading, model } = useAssetMetadataModel({
    path: currentAssetByPath,
    type: 'file',
  });
  const [profile, setProfile] = useState<AdobeIMSProfile>();
  const adobeIMS = useAdobeIMS();

  useEffect((): void => {
    if (adobeIMS && !profile) {
      (async (): Promise<void> => {
        setProfile(await adobeIMS.getProfile());
      })();
    }
  }, [adobeIMS, setProfile, profile]);

  if (isLoading || !model) {
    return <Loading />;
  }

  return (
    <Pane isOpen={!isCollapsed} isSticky>
      <View padding="10px">
        <AssetInfo />
      </View>
      <Flex
        justifyContent="end"
        width="100%"
        UNSAFE_className={Styles.closeButton}
      >
        <ToggleButton onPress={onCollapse} marginStart="size-100" isQuiet>
          {isCollapsed ? (
            <RailRightOpen size="S" />
          ) : (
            <RailRightClose size="S" />
          )}
        </ToggleButton>
      </Flex>
      <View
        flex="1 1 100%"
        position="relative"
        marginX={isCollapsed ? 'size-0' : 'size-200'}
        overflow="scroll"
        UNSAFE_className={Styles.comments}
      >
        <Flex
          height="100%"
          width="100%"
          UNSAFE_className={Styles.commentsWrapper}
          justifyContent="space-between"
        >
          <View width="100%">
            {profile && model.assetId ? (
              <CommentsProvider
                appKey="SparkTempo"
                appName="Spark Tempo"
                assetData={{
                  id: model.assetId,
                  ownerid: model.createdBy ?? '',
                  validNodes: [],
                }}
                disableNetworkIndicator
                environment={commentsEnvironment}
                globalCommentsViewAll={false}
                imsData={{
                  id: profile.userId,
                  token: adobeIMS?.getAccessToken()?.token,
                }}
                onCommentsUpdate={() => {
                  queryClient.invalidateQueries([
                    'comment-counts',
                    model.assetId,
                  ]);
                }}
                peopleGraphAPIKey="SparkTempo"
                theme="lightest"
              >
                <CommentsFull />
              </CommentsProvider>
            ) : null}
          </View>
        </Flex>
      </View>
    </Pane>
  );
}

CommentsMetadataForm.defaultProps = {
  isCollapsed: false,
  onCollapse: undefined,
};
