/**
 * *****************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2022 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Adobe and its suppliers, if any. The intellectual and technical concepts
 * contained herein are proprietary to Adobe and its suppliers and are
 * protected by all applicable intellectual property laws, including trade
 * secret and copyright laws. Dissemination of this information or reproduction
 * of this material is strictly forbidden unless prior written permission is
 * obtained from Adobe.
 * *****************************************************************************
 */

import React, { ReactChild, ReactElement } from 'react';

import { Text, View } from '@adobe/react-spectrum';

import styles from './AssetMetadataFormField.module.scss';

type HelpTextProps = {
  children: ReactChild;
  validationState?: 'valid' | 'invalid';
};

export function HelpText({
  children,
  validationState,
}: HelpTextProps): ReactElement {
  let rootClassName = styles.helpText;

  if (validationState === 'invalid') {
    rootClassName += ` ${styles.invalid}`;
  }

  return (
    <View UNSAFE_className={rootClassName} marginTop="-8px" marginBottom="12px">
      <Text>{children}</Text>
    </View>
  );
}

HelpText.defaultProps = {
  validationState: undefined,
};
