/**
 * *****************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2022 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Adobe and its suppliers, if any. The intellectual and technical concepts
 * contained herein are proprietary to Adobe and its suppliers and are
 * protected by all applicable intellectual property laws, including trade
 * secret and copyright laws. Dissemination of this information or reproduction
 * of this material is strictly forbidden unless prior written permission is
 * obtained from Adobe.
 * *****************************************************************************
 */

import React, { Key, ReactElement, useCallback } from 'react';

import { Breadcrumbs, Item } from '@adobe/react-spectrum';

import useConstant from 'hooks/useConstant';
import useCurrentAssetByPath from 'hooks/useCurrentAssetByPath';
import { DocumentView } from 'model/DocumentView';
import {
  clearSelectedAssetPaths,
  setDocumentView,
} from 'redux/explorer/explorer.slice';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { selectIsUsingExperimentalRepository } from 'redux/user/user.slice';

const START_INDEX = 0;

type BreadcrumbNavProps = {
  hasPathPrefix?: boolean;
};

export default function BreadcrumbNav({
  hasPathPrefix = false,
}: BreadcrumbNavProps): ReactElement {
  const { acpBasePath } = useConstant();
  const { currentAssetByPath, setCurrentAssetByPath } = useCurrentAssetByPath({
    shouldIncludeRoot: hasPathPrefix,
  });
  const dispatch = useAppDispatch();
  const isUsingExperimentalRepository = useAppSelector(
    selectIsUsingExperimentalRepository,
  );
  const basePath =
    isUsingExperimentalRepository && !hasPathPrefix
      ? acpBasePath.wolverineAssets
      : acpBasePath.assets;
  const pathname = currentAssetByPath.replace(basePath, '');
  const pathStack = pathname.split('/').filter((dir: string): boolean => !!dir);
  const onAction = useCallback(
    (path: Key) => {
      dispatch(setDocumentView(DocumentView.Card));

      dispatch(clearSelectedAssetPaths());
      setCurrentAssetByPath(basePath + path);
    },
    [basePath, dispatch, setCurrentAssetByPath],
  );

  return (
    <Breadcrumbs flex size="L" onAction={onAction}>
      {pathStack.map((item: string, index: number): ReactElement => {
        let linkLocation = `/${item}`;

        if (index > START_INDEX) {
          linkLocation = `/${pathStack
            .slice(START_INDEX, index)
            .join('/')}${linkLocation}`;
        }

        return (
          <Item key={linkLocation} textValue={`Go to ${item}`}>
            {item}
          </Item>
        );
      })}
    </Breadcrumbs>
  );
}

BreadcrumbNav.defaultProps = {
  hasPathPrefix: false,
};
