/**
 * *****************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2022 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Adobe and its suppliers, if any. The intellectual and technical concepts
 * contained herein are proprietary to Adobe and its suppliers and are
 * protected by all applicable intellectual property laws, including trade
 * secret and copyright laws. Dissemination of this information or reproduction
 * of this material is strictly forbidden unless prior written permission is
 * obtained from Adobe.
 * *****************************************************************************
 */

import React, { ReactElement } from 'react';

import { Content, Heading, IllustratedMessage } from '@adobe/react-spectrum';
import NotFound from '@spectrum-icons/illustrations/NotFound';

export type NoUsersMessageProps = {
  isUserGroup: boolean;
};

export default function NoUsersMessage({
  isUserGroup,
}: NoUsersMessageProps): ReactElement {
  return (
    <IllustratedMessage>
      <NotFound />
      <Heading>{isUserGroup ? 'No User Groups' : 'No Users'}</Heading>
      <Content>No results found</Content>
    </IllustratedMessage>
  );
}
